import React, { useState, useEffect } from 'react'
import {
  Icon,
} from 'konsys-ui'
import {
  Title,
  Select,
  SelectBoxContainer,
} from './styled'
import { theme } from '../../../../../styles/_variables'


const SelectBox = ({
  title,
  placeholder,
  options = [],
  value,
  onChange,
  disabled,
  thin = false,
  centerTitle,
  white,
}) => {
  const [optionItems, setOptionItems] = useState(options)
  useEffect(() => {
    if (options && options.length > 0) {
      setOptionItems(options)
    }
  }, [options])
  return (
  <SelectBoxContainer>
    <Title centerTitle={centerTitle}>{title}</Title>
    <Select
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      defaultValue={value}
      thin={thin}
      suffixIcon={<Icon style={{ color: white ? '#d1d1d1' : theme.color.primaryColor, fontSize: '20px', fontWeight: '500' }} icon='fal fa-chevron-down'/>}
      white={white}
    >
      {
        optionItems && optionItems.map((item, index) => (
          <Select.Option
            key={item.index}
            value={item.value}
            title={item.title}
            disabled={item.disabled}
          >
            {item.title}
          </Select.Option>
        ))
      }
    </Select>
  </SelectBoxContainer>
  )
}


export default SelectBox
