import styled from 'styled-components'
import { Button } from 'konsys-ui'
import {
  Button as ButtonAnt,
  Table as TableAnt, InputNumber as InputNumberAnt,
  Select as SelectAnt,
} from 'antd'
import { theme } from '../../../styles/_variables'

export const DivToolbar = styled.div`
  display: flex;
`

export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`

export const LabelColumn = styled.h4`
  font-weight: 600;
  font-size: 24px;
  color: ${theme.color.primaryColor};
  text-align: center !important;
`

export const LabelCell = styled.p`
  font-weight: 600;
  font-size: ${theme.fonts.size.normal};
  margin-bottom: 0rem !important;
`

export const DivInput = styled.div`
  width: 100px;
`

export const DivCell = styled.div`
  display: flex;
  justify-content: left;
  min-width: 90px;
  ${props => props.alignCell && `
    justify-content: ${props.alignCell};
  `}
`

export const DivRowActionSize = styled.div`
  display: block;
`

export const DivRowCellActionSize = styled.div`
  display: flex;
  justify-content: center;
`

export const ButtonAction = styled(Button)`
  padding: 2px 6px !important;
  border-radius: 20px;
  min-width: 80px;
`

export const ButtonActionColumn = styled(Button)`
  padding: 2px 12px !important;
  border-radius: 30px;
  height: fit-content;
  min-width: 110px;
`

export const ButtonDelete = styled(ButtonAnt)`
  color: ${theme.color.error};
  border-color: ${theme.color.error};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DivActionColumn = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
`

export const DivTitle = styled.div`
  width: 100%;
`

export const DivEditColumn = styled.div`
  display: flex;
  align-items: center;
`

export const Table = styled(TableAnt)`
  border: ${theme.color.primaryColor} solid 1px;
  border-radius: 5px;
  .ant-table-column-title {
    color: ${theme.color.primaryColor};
    font-size: ${theme.fonts.size.subTitle};
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    border-bottom: 0px;
  }
  .ant-table-row {
    font-size: ${theme.fonts.size.extraNormal};
    color: ${theme.color.textColor};
    vertical-align: middle;
  }
  tr:nth-child(even) {
    background-color: ${theme.color.offWhite};
  }
  .ant-table-tbody > tr:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 4px;
  }
  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 4px;
  }
`

export const DivRowSize = styled.div`
  display: flex;
`

export const DivItemCell = styled.div`
  margin: 4px;
  max-width: fit-content;
`

export const InputNumber = styled(InputNumberAnt)`
  .ant-input-number-input {
    text-align: center;
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
  }
`

export const TextColumn = styled.p`
  font-weight: 600;
  font-size: ${theme.fonts.size.extraNormal};
  display: contents;
  justify-content: center;
  align-items: center;
`

export const Select = styled(SelectAnt)`
  .ant-select .ant-select-enabled {
    text-align: center;
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
  }
`

export const ButtonDefault = styled(ButtonAnt)`
  color: ${theme.color.primaryColor};
  border-color: ${theme.color.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-left: 8px;
`

export default DivToolbar
