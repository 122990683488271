import React, { useState } from 'react'
import { Query } from 'react-apollo'
import {
  message,
} from 'antd'
import {
  Div, LoadingIcon,
} from 'konsys-ui'
import { theme } from '../../styles/_variables'
import { GET_QUOTATION_SUBMITTED } from '../../constants/graphql/query'
import PATH from '../../constants/path'
import Description from './components/description'
import CoverUpload from './components/cover-upload'


const QuotationSubmitted = () => {
  const [formValues, setFormValues] = useState({
    confirmText: '',
    confirmFile: [],
  })
  return (
    <Div display='flex' flexDirection='column'>
      <Query
          query={GET_QUOTATION_SUBMITTED}
          fetchPolicy='network-only'
          notifyOnNetworkStatusChange
          onCompleted={(data) => {
            if (data.quotationSubmitted) {
              const quotationSubmittedData = data.quotationSubmitted
              setFormValues({
                confirmFile: quotationSubmittedData.confirmFileUri ? [{ quotationSubmittedFileUri: quotationSubmittedData.confirmFileUri, type: 'cover' }] : [],
                confirmText: quotationSubmittedData.confirmText.replace(' ', '&nbsp;'),
              })
            }
          }}
        >
          {
            ({ loading, data, refetch }) => {
              if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
              return (
                <>
                  <Description
                    data={formValues}
                    onInputChange={setFormValues}
                  />
                  <CoverUpload
                    data={formValues}
                    newFileList={formValues.confirmFile}
                    setNewFileList={setFormValues}
                    refetch={refetch}
                  />
                </>
              )
            }
          }
        </Query>
    </Div>
  )
}

export default QuotationSubmitted
