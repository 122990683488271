import React, { useState } from 'react'
import {
  Div, Icon,
} from 'konsys-ui'
import {
  Label, Content, Select,
} from './styled'
import { theme } from '../../../../styles/_variables'

const UNIT_OF_MEASURE = [{
  value: 1,
  text: 'เซนติเมตร (ซม.)',
  key: 'cm',
  label: 'ซม.',
}, {
  value: 2,
  text: 'นิ้ว',
  key: 'inches',
  label: 'นิ้ว',
}]

const UnitOfMeasure = ({
  data,
  onInputChange,
}) => {
  const [unitOptions] = useState(UNIT_OF_MEASURE)
  return (
    <Content flexDirection='row' justifyContent='space-between' alignItems='baseline'>
      <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-start'>
        <Label>หน่วยการวัด</Label>
      </Div>
      <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
        <Select
          suffixIcon={<Icon style={{ color: theme.color.primaryColor, fontSize: '20px', fontWeight: '500' }} icon='fal fa-chevron-down'/>}
          dropdown
          onChange={(e) => {
            onInputChange(unitOptions.find(v => v.value === e))
          }}
          style={{
            width: '130px',
            fontSize: theme.fonts.size.small,
            fontWeight: theme.fonts.style.medium,
          }}
          value={data.value}
        >
        {
          UNIT_OF_MEASURE.map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)
        }
        </Select>
      </Div>
    </Content>
  )
}

export default UnitOfMeasure
