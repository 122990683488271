import styled, { css } from 'styled-components'
import { theme, media } from '../../../../../styles/_variables'


export const DescriptionContainer = styled.div`
width: 100%;
border-radius: 5px;
text-align: left;
> div{
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
    line-height: normal;
    color: ${theme.color.lightGrey};
    margin-bottom: 2px;
}
padding-top: 8px;
padding-bottom: 8px;
`

export const ButtonWrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
`

export const TitleWrapper = styled.div`
display: flex;
align-content: center;
align-items: center;
justify-content: space-between;
> span{
    font-size: ${theme.fonts.size.normal};
    height: fit-content;
    padding: 5px 15px;
    border-radius: 15px;
    background: ${theme.color.textColor};
    color: ${theme.color.offWhite};
}
> p{
    margin-bottom: 0;
}
`

export const Title = styled.p`
font-size: ${theme.fonts.size.extraNormal};
color: ${theme.color.greyNormal}
`

export const ImageWrapper = styled.div`
  height: 100%;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  position: relative;
  ${props => props.img && css`
    background-image: url(${props.img});
    background-size: cover;
    background-position: center;
  `}
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.5s ease;
  }
  &:after {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    opacity: 0;
    transition: all 0.5s ease;
  }
  > i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    transition: all 0.5s ease;
  }
  &:hover {
    &:before {
      background-color: rgba(0, 0, 0, 0.7);
    }
    &:after {
      opacity: 1;
    }
    > i {
      opacity: 1;
    }
  }
`

export const ImagesContainer = styled.div`
display: grid;
grid-template-columns: repeat(${props => props.gridColumn || 4}, minmax(0, 1fr));
grid-auto-rows: 1fr;
grid-column-gap: 4px;
grid-row-gap: 4px;
padding-top: 4px;
`

export default DescriptionContainer
