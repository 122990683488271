import React from 'react'
import { Div } from 'konsys-ui'
import { TagDivActive, TagDivInActive, ButtonClear } from './styled'

const TagFilter = ({
  data = [],
  onClear,
  onChange,
}) => (
    <Div>
      <Div>
        <ButtonClear text={'ลบตัวเลือกทั้งหมด'} icon='far fa-times' inverse rightIcon onClick={onClear}/>
      </Div>
      <Div>
        { data.reduce((result, item) => {
          if (item.active) {
            result.push(
              <TagDivActive
                text={item.title}
                icon='fa fa-tag fa-rotate-90'
                inverse
                onClick={() => onChange(item.key, false)}
              />,
            )
          }
          return result
        }, [])
      }
      </Div>
      <Div style={{ paddingTop: '26px' }}>
        { data.reduce((result, item) => {
          if (!item.active) {
            result.push(
              <TagDivInActive
                text={item.title}
                icon='fa fa-tag fa-rotate-90'
                inverse
                onClick={() => onChange(item.key, true)}
              />,
            )
          }
          return result
        }, [])
        }
      </Div>
    </Div>
)

export default TagFilter
