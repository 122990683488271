import React, {
  useState,
  useEffect,
} from 'react'
import _ from 'lodash'
import {
  Div, Input, Button, P,
} from 'konsys-ui'
import {
  Row,
  Col,
  message,
  Select,
} from 'antd'
import { addStyles, EditableMathField } from 'react-mathquill'
import {
  PreviewImage, PartSection, PartLabel, PartTitleLabel,
  DivImage, DivImageAction, MathField, FormulaDiv, PreviewImageBox,
  VariableLabel,
} from './styled'
import UploadFile from '../../../../components/common/upload'
import { theme } from '../../../../styles/_variables'
import Paper from '../paper'
import Gluing from '../gluing'
import DieCut from '../dieCut'
import AdditionalService from '../additional-service'

addStyles()

const MAXIMUM_FILE_LENGTH = 20

const Formulas = [{
  text: '^',
  value: '{ }^2',
}, {
  text: '√',
  value: '\\sqrt{}',
}, {
  text: 'sin',
  value: '\\sin\\left(\\right)',
}, {
  text: 'cos',
  value: '\\cos\\left(\\right)',
}, {
  text: 'tan',
  value: '\\tan\\left(\\right)',
}, {
  text: 'x',
  value: '\\cdot',
}, {
  text: '/',
  value: '\\frac{ }{ }',
}]
const FormulaToolbar = ({
  state,
  handleClick,
  number,
}) => (
  <FormulaDiv>
    {
      Formulas.map((item, index) => (
        <Button
          key={index}
          style={{
            borderRadius: '8px',
            padding: '4px 8px',
            width: '60px',
            justifyContent: 'center',
            display: 'flex',
            marginRight: '8px',
            fontFamily: 'Symbola',
          }}
          color={theme.color.paleBlack}
          buttonStyle='rectangle'
          text={item.text}
          onClick={() => handleClick(state, item.value, number)}
        />
      ))
    }
  </FormulaDiv>
)

const PartsItemSection = ({
  number,
  data,
  setPartData,
  formValues,
  packagePart,
}) => {
  // Preview File Image of Part
  const [previewFileList, setPreviewFileList] = useState([])
  const [editableMathFieldWidth, setEditableMathFieldWidth] = useState(null)
  const [editableMathFieldLength, setEditableMathFieldLength] = useState(null)
  const removePreviewFile = (index) => {
    const tmpData = formValues.partData
    const newPreviewFileList = [...tmpData[number].image]
    _.remove(newPreviewFileList, (v, i) => i === index)
    setPreviewFileList(newPreviewFileList)
    tmpData[number].image = [...newPreviewFileList]
    setPartData({ ...formValues, partData: tmpData })
  }

  useEffect(() => {
    if (data[number] && data[number].image) {
      setPreviewFileList(data[number].image.map(file => ({
        type: 'gallery',
        src: file.src,
      })))
    }
  }, [data[number]])

  const amountVariablePart = [{
    label: '1 ตัวแปร',
    value: 1,
  }, {
    label: '2 ตัวแปร',
    value: 2,
  }, {
    label: '3 ตัวแปร',
    value: 3,
  }, {
    label: '4 ตัวแปร',
    value: 4,
  }, {
    label: '5 ตัวแปร',
    value: 5,
  }, {
    label: '6 ตัวแปร',
    value: 6,
  }, {
    label: '7 ตัวแปร',
    value: 7,
  }, {
    label: '8 ตัวแปร',
    value: 8,
  }, {
    label: '9 ตัวแปร',
    value: 9,
  }, {
    label: '10 ตัวแปร',
    value: 10,
  }]

  const [amountVariable, setAmountVariable] = useState(1)
  useEffect(() => {
    if (data[number]) {
      setAmountVariable(data[number].amountVariable)
    }
  }, [data[number]])

  const handleChangeTitle = async (value) => {
    const tmpData = data
    tmpData[number].name = value.target.value
    await setPartData({ ...formValues, partData: tmpData })
  }

  const handleChangeAmountVariable = async (value) => {
    await setAmountVariable(value)
    const tmpData = data
    tmpData[number].amountVariable = value
    await setPartData({ ...formValues, partData: tmpData })
  }

  // State Form Width and Legth
  const [width, setWidth] = useState('a')
  const [length, setLength] = useState('a')

  useEffect(() => {
    if (packagePart) {
      const tmpData = packagePart
      setWidth(tmpData.width)
      setLength(tmpData.length)
      const tmpFormvalue = formValues.partData
      tmpFormvalue[number].width = tmpData.width
      tmpFormvalue[number].length = tmpData.length
      setPartData({ ...formValues, partData: tmpFormvalue })
    }
  }, [packagePart])

  const handleChangeWidth = (result) => {
    // change width value and set in item array of partdata
    const value = result.latex()
    const getValue = value || ''
    const tmpPartData = formValues.partData
    tmpPartData[number].width = getValue
    setPreviewFileList(tmpPartData[number].image)
    setPartData({ ...formValues, partData: tmpPartData })
    setWidth(value || '')
  }

  const handleChangeLength = (result) => {
    // change length value and set in item array of partdata
    if (formValues.partData && formValues.partData[number]) {
      const value = result.latex()
      const getValue = value || ''
      const tmpPartData = formValues.partData
      tmpPartData[number].length = getValue
      setPreviewFileList(tmpPartData[number].image)
      setPartData({ ...formValues, partData: tmpPartData })
      setLength(value || '')
    }
  }

  const handleClickFormulaWidth = (state, value, index) => {
    setWidth(editableMathFieldWidth.write(value).latex())
    document.getElementById(`math-width-${index}`).getElementsByClassName('mq-textarea')[0].firstChild.focus()
  }

  const handleClickFormulaLength = (state, value, index) => {
    setLength(editableMathFieldLength.write(value).latex())
    document.getElementById(`math-length-${index}`).getElementsByClassName('mq-textarea')[0].firstChild.focus()
  }

  return (
    <PartSection>
      <Div display='flex' flexDirection='column'>
        <PartLabel>ชิ้นส่วนที่ {number + 1}</PartLabel>
        <PartTitleLabel>ชื่อชิ้นส่วน</PartTitleLabel>
        <Input
          style={{ width: '100%', fontSize: '18px' }}
          placeholder="กรุณาใส่ชื่อชิ้นส่วน"
          onChange={value => handleChangeTitle(value)}
          defaultValue={data[number] && data[number].name}
          state={formValues.partData[number] && formValues.partData[number].requiredName && 'error'}
          onBlur={() => {
            if (data[number].name === '' || data[number].name.match(new RegExp(/^\s/)) !== null) {
              const tmpPartData = formValues.partData
              // tmpPartData[number].requiredName = true
              setPartData({ ...formValues, partData: tmpPartData })
            } else {
              const tmpPartData = formValues.partData
              tmpPartData[number].requiredName = false
              setPartData({ ...formValues, partData: tmpPartData })
            }
          }}
        />
        <PartTitleLabel>รูปชิ้นส่วน</PartTitleLabel>
        <Div display='flex' flexDirection='column'>
          <Row
            type='flex'
            gutter={12}
          >
            { (formValues.partData && formValues.partData[number].image && formValues.partData[number].image.length) > 0 ? <Col span={12}>
                  <Row
                    align='middle'
                    type='flex'
                    gutter={12}
                  >
                  {
                      formValues.partData && formValues.partData[number].image.map((v, i) => (
                      <Col key={i} span={8}>
                          <DivImage>
                            <PreviewImageBox>
                              <PreviewImage src={v.type === 'gallery' ? v.src : URL.createObjectURL(v.src)}/>
                            </PreviewImageBox>
                            <DivImageAction>
                              <Button
                                style={{
                                  borderRadius: '8px',
                                  padding: '4px 16px',
                                  width: '80px',
                                  justifyContent: 'center',
                                  display: 'flex',
                                }}
                                color={theme.color.paleBlack}
                                buttonStyle='rectangle'
                                text='ลบรูปนี้'
                                onClick={() => removePreviewFile(i)}
                              />
                            </DivImageAction>
                          </DivImage>
                      </Col>
                      ))
                  }
                  </Row>
                </Col> : null
              }
            <Col span={12}>
              <UploadFile
                  list={[]}
                  id='uploadPackagePartFileId'
                  multiple
                  onChange={(fileList) => {
                    let upload = false
                    let maximumFileLength = false
                    const tmpFile = []
                    const allowFileType = ['jpg', 'jpeg', 'png']
                    for (let i = 0; i < fileList.length; i += 1) {
                      if (fileList.length + previewFileList.length > MAXIMUM_FILE_LENGTH) {
                        maximumFileLength = true
                        break
                      }
                      const splitName = fileList[i].name.split('.')
                      if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
                      || (previewFileList.length > 0 && !_.isUndefined(_.find(previewFileList.filter(x => x.type === 'upload'), d => d.name === fileList[i].name)))) {
                        upload = false
                        break
                      } else {
                        upload = true
                        tmpFile.push({ type: 'upload', src: fileList[i] })
                      }
                    }
                    if (upload || !maximumFileLength) {
                      // Set only one image to state
                      setPreviewFileList([...previewFileList, ...tmpFile])
                      const tmpData = data
                      tmpData[number].image = [...previewFileList, ...tmpFile]
                      setPartData({ ...formValues, partData: tmpData })
                    } else if (maximumFileLength) {
                      message.error(`ไม่สามารถอัพโหลดไฟล์ได้มากกว่า ${MAXIMUM_FILE_LENGTH} รูป`)
                    } else {
                      message.error('สามารถอัพโหลดได้เฉพาะไฟล์ jpg, jpeg, png และไม่สามารถอัพรูปซ้ำได้')
                    }
                  }}
                  fileType="image/*"
              />
            </Col>
          </Row>
        </Div>
        <PartTitleLabel>จำนวนตัวแปรขนาดชิ้นส่วน</PartTitleLabel>
        <Select
          style={{ width: '50%', fontSize: theme.fonts.size.normal }}
          placeholder="กรุณาเลือกจำนวนตัวแปรขนาดชิ้นส่วน"
          value={amountVariable}
          onChange={value => handleChangeAmountVariable(value)}
        >
          {
          amountVariablePart.map(v => (
              <Select.Option key={v.value} value={v.value}>{v.label}</Select.Option>
          ))
          }
        </Select>
        <VariableLabel>
          {
            Array.from(Array(amountVariable), (e, i) => (
              <>
                { String.fromCharCode('a'.charCodeAt() + i).concat(amountVariable === (i + 1) ? '' : ', ') }
              </>
            ))
          }
        </VariableLabel>
        <PartTitleLabel>ด้านกว้าง (นิ้ว)</PartTitleLabel>
        <FormulaToolbar
          state={width}
          handleClick={handleClickFormulaWidth}
          number={number}
        />
        <MathField statusColor={formValues.partData[number] && formValues.partData[number].requiredWidth ? theme.color.error : 'rgba(192, 191, 193, 0.7)'}>
          <EditableMathField
            id={`math-width-${number}`}
            style={{
              display: 'flex',
              border: '0px',
              flexDirection: 'column',
              height: '120px',
              width: '100%',
            }}
            latex={width}
            onChange={value => handleChangeWidth(value)}
            mathquillDidMount={mathquill => setEditableMathFieldWidth(mathquill)}
          />
        </MathField>
        <PartTitleLabel>ด้านยาว (นิ้ว)</PartTitleLabel>
        <FormulaToolbar
          state={length}
          handleClick={handleClickFormulaLength}
          number={number}
        />
        <MathField statusColor={formValues.partData[number] && formValues.partData[number].requiredLength ? theme.color.error : 'rgba(192, 191, 193, 0.7)'}>
          <EditableMathField
            id={`math-length-${number}`}
            style={{
              display: 'flex',
              border: '0px',
              flexDirection: 'column',
              height: '120px',
              width: '100%',
              fontFamily: 'Symbola',
            }}
            latex={length}
            onChange={value => handleChangeLength(value)}
            mathquillDidMount={mathquill => setEditableMathFieldLength(mathquill)}
          />
        </MathField>
        {
          formValues.partData && <Paper
            formValues={formValues}
            setPartData={setPartData}
            number={number}
          />
        }
        <Row
          type='flex'
          gutter={16}
        >
          {
          formValues.partData && <Col md={12}><Gluing
            formValues={formValues}
            setPartData={setPartData}
            number={number}
          />
          </Col>
          }
          {
          formValues.partData && <Col md={12}><DieCut
            formValues={formValues}
            setPartData={setPartData}
            number={number}
          />
          </Col>
          }
        </Row>
        {
          formValues.partData && <AdditionalService
            formValues={formValues}
            setPartData={setPartData}
            number={number}
          />
        }
      </Div>
    </PartSection>
  )
}

const Parts = ({
  amountPart,
  partData,
  setPartData,
  packageParts,
  data,
}) => (
  <Div display='flex' flexDirection='column'>
    {
      Array(amountPart).fill(0).map((_item, i) => (
        <PartsItemSection
          key={i}
          number={i}
          data={partData}
          formValues={data}
          setPartData={setPartData}
          packagePart={packageParts && packageParts[i]}
        />
      ))
    }
  </Div>
)


export default Parts
