import styled from 'styled-components'
import { Button } from 'konsys-ui'
import { theme } from '../../../../../styles/_variables'


export const NewButton = styled(Button)`
  border-radius: 5px;
  padding: 0px 3px;
  width: 120px;
  i {
    font-size: 16px !important;
    font-weight: 200;
  }
  span {
    font-size: 16px;
    font-weight: 200;
  }
`

export default NewButton
