import React, { useState } from 'react'
import { message } from 'antd'
import { Mutation } from 'react-apollo'
import {
  Div, H5, Input,
} from 'konsys-ui'
import {
  LoginContainerWrapper, LoginContentWrapper, LogoWrapper, LoginFormWrapper,
  LoginButton, ButtonText,
} from './styled'
import { theme } from '../../styles/_variables'
import Logo from '../../images/logo/Boxsuit-Logo-01.png'
import { LOGIN } from '../../constants/graphql/mutation'
import { setAccessToken, setRefreshToken } from '../../utils/local-data-service'
import PATH from '../../constants/path'
import history from '../../history'

const DoLogin = (func, email, password, cb) => {
  func({
    variables: {
      username: email,
      password,
      grant_type: 'password',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    },
  }).then((resp) => {
    if (resp.data.login.success) {
      setAccessToken(resp.data.login.createdAccessToken.access_token)
      setRefreshToken(resp.data.login.createdAccessToken.refresh_token)
      window.location = PATH.quotationList
    } else {
      message.error(`ไม่สามารถเข้าสู่ระบบได้: ${resp.data.login.message || 'เกิดข้อผิดพลาด'}`)
    }
    if (cb) {
      cb()
    }
  })
}

export const LoginModal = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [disabledButton, setDisabledButton] = useState(false)
  const validateEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  return (
    <LoginContentWrapper>
      <Div textAlign='center'>
        <LogoWrapper src={Logo} />
        <H5
          color='#565757'
          textAlign='center'
          display='block'
        >คุณสามารถเข้าสู่ระบบเพื่อติดตาม หรือ ตรวจสอบ คำร้องขอใบเสนอราคา และคำสั่งพิมพ์ได้</H5>
      </Div>
      <Mutation
        mutation={LOGIN}
      >
      {
        doLogin => (
          <LoginFormWrapper>
            <Input
              width='100%'
              placeholder='Email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              state={email !== '' && (!validateEmail.test(email) ? 'error' : 'success')}
              color={theme.color.primaryColor}
              style={{
                fontSize: '24px',
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13 && email !== '' && validateEmail.test(email) && password !== '') {
                  setDisabledButton(true)
                  DoLogin(doLogin, email, password, () => {
                    setDisabledButton(false)
                  })
                }
              }}
            />
            <Input
              type='password'
              margin='8px 0'
              width='100%'
              placeholder='Password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              color={theme.color.primaryColor}
              style={{
                fontSize: '24px',
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13 && email !== '' && validateEmail.test(email) && password !== '') {
                  setDisabledButton(true)
                  DoLogin(doLogin, email, password)
                } else {
                  setDisabledButton(false)
                }
              }}
            />
            <Div textAlign='right'>
              <ButtonText color={theme.color.grey} onClick={() => history.push(PATH.forgetPassword)}>ลืมรหัสผ่าน?</ButtonText>
            </Div>
            <LoginButton
              margin='16px 0'
              inverse fullWidth
              buttonStyle="rectangle"
              text='เข้าสู่ระบบ'
              disabled={!(email !== '' && validateEmail.test(email)) || !password || disabledButton}
              onClick={() => {
                setDisabledButton(true)
                DoLogin(doLogin, email, password, () => {
                  setDisabledButton(false)
                })
              }}
            />
          </LoginFormWrapper>
        )
      }
      </Mutation>
    </LoginContentWrapper>
  )
}

const Login = () => (
  <LoginContainerWrapper>
    <LoginModal />
  </LoginContainerWrapper>
)

export default Login
