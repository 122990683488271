import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
  SmallText, H3, Icon, Div, Button,
} from 'konsys-ui'
import OPT from 'options'
import CONFIG from '../../constants/menu-config'
import {
  Menu, Wrapper, MainContainer, SubMenuAntd, ItemAntd, Drawer,
} from './styled'
import { theme } from '../../styles/_variables'
import history from '../../history'
import * as size from '../../constants/screen-size'
import { removeAccessToken, removeRefreshToken, getURL } from '../../utils/local-data-service'

const isLightTheme = OPT.theme === 'light'
const version = '2.0'

const Logout = () => {
  removeAccessToken()
  removeRefreshToken()
  window.location = `${getURL().WEB_CMS_SERVER}/login`
}

const SideBar = (props) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [drawer, setDrawer] = useState(false)
  const onMobile = width < size.IPAD
  const onIpad = width < size.DESKTOP && width >= size.IPAD

  const screenSizeHandler = () => {
    setWidth(window.innerWidth)
    setDrawer(false)
  }

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    return () => window.removeEventListener('resize', screenSizeHandler)
  })

  return (
    <MainContainer>
      {
        onIpad
          ? <IpadMenuWrapper />
          : onMobile
            ? <Div position='absolute' top='16px' right='24px'>
              <Button icon="fas fa-bars" onlyIcon inverse onClick={() => setDrawer(true)}/>
              <Drawer
                width={250}
                placement="right"
                closable={false}
                onClose={() => setDrawer(false)}
                visible={drawer}
              >
                <MenuWrapper onMobile={onMobile} />
              </Drawer>
            </Div>
            : <MenuWrapper onMobile={onMobile} />
      }
      { props.children }
    </MainContainer>
  )
}

// ======================= DESKTOP MENU ======================= //

const MenuWrapper = ({ onMobile }) => (
  <Wrapper key='sidebar'>
    <MenuContainer key='menu' handleContent={next => this.contentHandler(next)} onMobile={onMobile}/>
    <Div display='flex' flexDirection='column' alignItems='center' margin='50px'>
    { onMobile
    && <div>
        <SmallText color={isLightTheme ? '#333' : '#eee'}>ยินดีต้อนรับ</SmallText>
        <br/>
        <span style={{ cursor: 'pointer' }}>
          <Icon icon='far fa-sign-out' margin='0 8px 0 0' color={theme.color.coolGrey}/>
          <SmallText onClick={() => Logout()} color={isLightTheme ? '#333' : '#eee'}>ออกจากระบบ</SmallText>
        </span>
      </div>
    }
      <span>
        <SmallText color={isLightTheme ? '#333' : '#eee'}>v. {version}</SmallText>
      </span>
    </Div>
  </Wrapper>
)

const MenuContainer = ({ onMobile }) => {
  const getPath = window.location.pathname
  const splitUrl = (getPath.split('/'))
  const currentParentUrl = splitUrl.length > 0 ? splitUrl[1] : 'landing'

  return (
    <div>
      { onMobile && <H3 padding='20px 0 0 0' display='block' textAlign='center' color={isLightTheme ? '#333' : '#eee'}>{CONFIG.title}</H3>}
      <Menu
        onClick={next => history.push(`/${next.key}`)}
        defaultSelectedKeys={[currentParentUrl]}
        mode="inline"
        theme={isLightTheme ? 'light' : 'dark'}
        inlineCollapsed={false}
      >
      {
        CONFIG.menu.map(value => (_.isEmpty(value.child)
          ? (
              <ItemAntd key={value.key}>
                <Div margin='0 8px 0 0' display='inline-block' width='30px'>
                  <Icon color={theme.color.primaryColor} className={value.icon} />
                </Div>
                <SmallText color={isLightTheme ? '#333' : '#eee'}>{value.label}</SmallText>
              </ItemAntd>
          )
          : (
            <SubMenuAntd
              key={value.key}
              title={
                <span>
                  <Div margin='0 8px 0 0' display='inline-block' width='30px'>
                    <Icon color={theme.color.primaryColor} className={value.icon}/>
                  </Div>
                  <SmallText color={isLightTheme ? '#333' : '#eee'}>{value.label}</SmallText>
                </span>
              }
            >
            {
              value.child.map(childValue => (_.isEmpty(childValue.child)
                ? (
                  <ItemAntd key={childValue.key}>{childValue.label}</ItemAntd>
                )
                : (
                  <SubMenuAntd key={childValue.key} title={childValue.label}>
                  {
                    childValue.child.map(subChildValue => (
                      <ItemAntd key={subChildValue.key}>{subChildValue.label}</ItemAntd>
                    ))
                  }
                  </SubMenuAntd>
                )
              ))
            }
            </SubMenuAntd>
          )))
      }
      </Menu>
    </div>
  )
}

// ======================= IPAD MENU ======================= //

const IpadMenuWrapper = () => (
  <Wrapper key='sidebar' ipad>
    <IpadMenuContainer key='menu' handleContent={next => this.contentHandler(next)} />
    <Div display='flex' flexDirection='column' alignItems='center' margin='50px 0'>
      <span style={{ cursor: 'pointer' }} onClick={() => Logout()}>
        <Icon icon='far fa-sign-out' color={theme.color.coolGrey}/>
      </span>
    </Div>
  </Wrapper>
)

const IpadMenuContainer = () => {
  const getPath = window.location.pathname
  const checkIsLandingPage = RegExp('landing')
  const checkIsUsersPage = RegExp('users')
  const CurrentPath = (checkIsLandingPage.test(getPath) === true)
    ? 'landing'
    : (checkIsUsersPage.test(getPath) === true)
        && 'users'

  return (
    <div>
      <Menu
        onClick={next => history.push(`/${next.key}`)}
        defaultSelectedKeys={[CurrentPath]}
        mode="inline"
        theme={isLightTheme ? 'light' : 'dark'}
        inlineCollapsed={true}
        ipad
      >
      {
        CONFIG.menu.map(value => (_.isEmpty(value.child)
          ? (
              <ItemAntd key={value.key}>
                <Div display='flex' justifyContent='center' width='50px'>
                  <Icon color={theme.color.primaryColor} className={value.icon} />
                </Div>
              </ItemAntd>
          )
          : (
            <SubMenuAntd
              key={value.key}
              title={
                <span>
                  <Div display='flex' justifyContent='center' width='50px'>
                    <Icon color={theme.color.primaryColor} className={value.icon}/>
                  </Div>
                </span>
              }
            >
            {
              value.child.map(childValue => (_.isEmpty(childValue.child)
                ? (
                  <ItemAntd key={childValue.key}>{childValue.label}</ItemAntd>
                )
                : (
                  <SubMenuAntd key={childValue.key} title={childValue.label}>
                  {
                    childValue.child.map(subChildValue => (
                      <ItemAntd key={subChildValue.key}>{subChildValue.label}</ItemAntd>
                    ))
                  }
                  </SubMenuAntd>
                )
              ))
            }
            </SubMenuAntd>
          )))
      }
      </Menu>
    </div>
  )
}

export default SideBar
