import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Query, Mutation } from 'react-apollo'
import {
  Div, H3, Button, LoadingIcon,
} from 'konsys-ui'
import {
  message, Switch, Popconfirm,
  Row, Col,
} from 'antd'
import parseHtml from 'html-react-parser'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import PATH from '../../../constants/path'
import { LIST_PROMOTION } from '../../../constants/graphql/query'
import { EDIT_PROMOTION_STATUS, DELETE_PROMOTION, REARRANGE_PROMOTION } from '../../../constants/graphql/mutation'
import { theme } from '../../../styles/_variables'
import {
  ImageWrapper, ImageLabel, DivPromotionList, DivAction,
  ImageDescription, StyledItem, StyledContainer, Container, StyledHandle,
  DivPromotion, Column,
} from './styled'
import FreePriceTagsIcon from '../../../images/icon/FreePriceTagsIcon.svg'


const Index = ({ history }) => {
  const [promotionItems, setPromotionItems] = useState([])
  const [isRearrange, setIsRearrange] = useState(false)
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setPromotionItems(arrayMoveImmutable(promotionItems, oldIndex, newIndex))
  }
  const handleToggleRearrange = () => {
    setIsRearrange(!isRearrange)
  }
  return (
    <Div display='flex' flexDirection='column'>
      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
        <H3 bold color={theme.color.primaryColor} margin='32px 0'>Promotion</H3>
        <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
          {
            isRearrange ? <ConfirmRearrangeButton
              promotions={promotionItems}
              handleToggleRearrange={handleToggleRearrange}
            />
              : <Button
              text={'Rearrange'}
              onClick={handleToggleRearrange}
              ghost
              icon='fas fa-arrows-alt'
              margin='0 16px 0 0'
            />
          }
          <Link to={PATH.promotionCreate}><Button text='New Promotion' icon='fal fa-plus' inverse /></Link>
        </Div>
      </Div>
      <DivPromotionList>
        <Query
          query={LIST_PROMOTION}
          fetchPolicy='network-only'
          variables={{
            limit: 10000,
            offset: 0,
            order: 'ordering',
            sort: 'ASC',
          }}
          onCompleted={(data) => {
            const promotionData = data.listPromotion.promotion
            setPromotionItems(promotionData)
          }}
        >
            {
              ({ loading, data, refetch }) => {
                if (loading) {
                  return <Div margin='24px 0' display='flex' moreStyle={{ width: '100%', justifyContent: 'center' }}>
                      <LoadingIcon color={theme.color.grey} />
                    </Div>
                }
                if (!data || !data.listPromotion.success) return <p>error something</p>
                return (
                  <Container>
                    <SortableList
                      shouldUseDragHandle={isRearrange}
                      useDragHandle
                      axis="xy"
                      items={promotionItems}
                      onSortEnd={onSortEnd}
                      history={history}
                      refetch={refetch}
                    />
                  </Container>
                )
              }
            }
        </Query>
      </DivPromotionList>
    </Div>
  )
}

const SortableList = SortableContainer((props) => {
  const { items, ...restProps } = props
  return (
    <StyledContainer>
      <Row type="flex" gutter={16}>
        {items.map((item, index) => (
          <Column
            key={`item-${item.promotionId}`}
          >
            <SortableItem
              index={index}
              value={item}
              {...restProps}
            />
          </Column>
        ))}
      </Row>
    </StyledContainer>
  )
})

const SortableItem = SortableElement((props) => {
  const { value: item } = props
  return (
    <StyledItem>
      <ImageWithToggle
        promotionId={item.promotionId}
        img={item.cover_uri || ''}
        status={item.status}
        refetch={props.refetch}
      />
      <DivPromotion>
        <ImageLabel isFree={item.isFree}>
          {
            item.isFree && <Div margin='0 5px'>
              <img src={FreePriceTagsIcon} height='30px'/>
            </Div>
          }
          {item.name || '-'}
        </ImageLabel>
        <ImageDescription>{item.description ? parseHtml(item.description) : '-'}</ImageDescription>
      </DivPromotion>
      {
        item.promotionId && props.shouldUseDragHandle ? <Handle />
          : <DivAction>
              <ButtonPromotionAction onClick={() => props.history.push(`${PATH.promotionDetail}/${item.promotionId}`)} text='แก้ไข'/>
              <DeleteButton promotionId={item.promotionId} />
            </DivAction>
      }
    </StyledItem>
  )
})

const ImageWithToggle = ({
  img,
  status,
  promotionId,
  refetch,
}) => {
  const [callMutation, setCallMutation] = useState(false)

  const onToggleImg = doUpdateStatusPromotion => (checked) => {
    doUpdateStatusPromotion({
      variables: {
        promotionId,
        status: checked ? 'active' : 'inactive',
      },
    }).then(({ data }) => {
      if (data.editPromotionStatusByPromotionId) {
        if (data.editPromotionStatusByPromotionId.success) {
          setCallMutation(false)
          refetch()
          message.success(data.editPromotionStatusByPromotionId.message)
        } else {
          message.error(data.editPromotionStatusByPromotionId.message)
        }
      }
    })
  }
  return (
    <Div moreStyle={{ position: 'relative', flex: '0 1 auto' }}>
      <Div moreStyle={{
        position: 'absolute',
        zIndex: 10,
        right: 0,
        padding: 8,
      }}>
        <Mutation
          mutation={EDIT_PROMOTION_STATUS}
        >
          {
            doUpdateStatusPromotion => (
              <Switch
                checked={status === 'active'}
                onChange={onToggleImg(doUpdateStatusPromotion)}
                isRearrange={callMutation}
              />
            )
          }
        </Mutation>
      </Div>
      <Image
        img={img}
      />
    </Div>
  )
}

const Image = ({ img }) => (
  <ImageWrapper img={img}/>
)

const Handle = SortableHandle(({ tabIndex }) => (
  <StyledHandle tabIndex={tabIndex}>
    <>
      <svg viewBox="0 0 50 50">
        <path
          d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 L 0 7.5 z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 L 0 22.5 z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 L 0 37.5 z"
          color="#000"
        />
      </svg>
    </>
  </StyledHandle>
))


const ButtonPromotionAction = ({
  text,
  onClick,
}) => (
  <Button
    style={{
      padding: '4px 16px',
      width: '70px',
      justifyContent: 'center',
      display: 'flex',
      marginRight: '8px',
      backgroundColor: theme.color.offWhite,
    }}
    text={text}
    onClick={onClick}
    ghost
  />
)

const ConfirmRearrangeButton = ({ promotions, handleToggleRearrange }) => (
  <Mutation mutation={REARRANGE_PROMOTION}>
    {
      doRearrangePromotion => (
        <Button
          text={'Confirm Rearrange'}
          onClick={() => {
            doRearrangePromotion({
              variables: {
                input: {
                  promotion: promotions.map((item, index) => ({
                    promotionId: item.promotionId,
                    ordering: index + 1,
                  })),
                },
              },
            }).then((res) => {
              if (res.data.rearrangePromotion) {
                if (res.data.rearrangePromotion.success) {
                  message.success(res.data.rearrangePromotion.message)
                } else {
                  message.error(res.data.rearrangePromotion.message)
                }
              }
            })
            handleToggleRearrange()
          }}
          ghost
          icon='fas fa-arrows-alt'
          margin='0 16px 0 0'
        />
      )
    }
  </Mutation>
)

const DeleteButton = ({ promotionId }) => {
  const [visible, setVisible] = useState(false)
  const [count, setCount] = useState(0)
  const onVisibleChange = () => {
    // To ignore first click on popover
    if (count !== 0) {
      setCount(count + 1)
      setVisible(!visible)
    } else {
      setVisible(true)
    }
  }
  return (
  <Mutation mutation={DELETE_PROMOTION}>
    {
      doDeletePromotion => (
        <Popconfirm
          id={`${promotionId}-popconfirm-deleted`}
          title="ยืนยันการลบโปรโมชั่นหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก"
          onVisibleChange={onVisibleChange}
          visible={visible}
          onConfirm={() => {
            doDeletePromotion({
              variables: {
                promotionId,
              },
            }).then((res) => {
              if (res.data.deletePromotion) {
                if (res.data.deletePromotion.success) {
                  message.success(res.data.deletePromotion.message)
                  setTimeout(() => {
                    window.location = PATH.promotionList
                  }, 100)
                } else {
                  message.error(res.data.deletePromotion.message)
                }
              }
              setVisible(!visible)
            })
          }}
          okText='ยืนยัน'
          cancelText='ยกเลิก'
        >
          <ButtonPromotionAction text='ลบ' color={theme.color.error}/>
        </Popconfirm>
      )
    }
  </Mutation>
  )
}

export default Index
