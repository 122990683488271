import React, { useState } from 'react'
import {
  Div,
} from 'konsys-ui'
import {
  Select,
} from 'antd'
import { theme } from '../../../../styles/_variables'
import {
  Label,
} from './styled'


const DieCut = ({
  number,
  formValues,
  setPartData,
}) => {
  const [listDiecut, setListGluing] = useState([{ id: 0, name: 'ไม่ใช้บริการ' }, { id: 1, name: 'ใช้บริการ' }])
  const handleChange = (value) => {
    if (formValues.partData) {
      const tmpPartData = formValues.partData
      tmpPartData[number].isDieCut = value
      setPartData({
        ...formValues,
        partData: tmpPartData,
      })
    }
  }
  return (
    <Div>
      <Label>บริการปั๊มตัด</Label>
      <Select
        style={{ width: '100%', fontSize: theme.fonts.size.normal }}
        value={formValues.partData && formValues.partData[number].isDieCut}
        onChange={value => handleChange(value)}
      >
      {
        listDiecut.length > 0 && listDiecut.map(v => (
          <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>
        ))
      }
      </Select>
    </Div>
  )
}
export default DieCut
