import styled from 'styled-components'
import { Button, P } from 'konsys-ui'
import { theme, media } from '../../styles/_variables'

export const LoginContainerWrapper = styled.div`
  position: relative;
  background-color: #a5dad4;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 1;
  }
  text-align: center;
  padding: 40px;
  ${media.tablet`
    padding: 24px;
  `}
`

export const LoginContentWrapper = styled.div`
  display: inline-block;
  padding: 48px 144px;
  max-width: 655px;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 2;
  ${media.tablet`
    padding: 32px 24px;
  `}
  > div {
    > h4 {
      padding: 0 32px;
      margin: 32px auto 0;
      ${media.mobileL`
        padding: 0 16px;
      `}
    }
  }
`

export const LogoWrapper = styled.img`
  width: 209px;
`

export const LoginFormWrapper = styled.div`
  margin: 40px 0 48px;
  ${media.tablet`
    margin: 24px 0 32px;
  `}
`

export const Divider = styled.hr`
  border-top: 1px solid ${theme.color.grey};
  margin: 48px -80px;
  ${media.tablet`
    margin: 32px -24px;
  `}
`

export const LoginButton = styled(Button)`
  padding: 16px;
  ${media.mobileM`
    padding: 8px;
  `}
  > p {
    font-size: ${theme.fonts.size.subTitle};
  }
`

export const SocialButtonWrapper = styled.button`
  font-size: ${theme.fonts.size.normal};
  padding: 0;
  width: 100%;
  text-align: left;
  background-color: ${props => (props.type === 'facebook' ? '#3B5797' : '#DF4E3A')};
  border: none;
  margin: 4px 0;
  > div {
    width: 54px;
    padding: 20px 0;
    background-color: ${props => (props.type === 'facebook' ? '#345089' : '#C84735')};
    ${media.mobileM`
      padding: 16px;
      font-size: ${theme.fonts.size.label};
    `}
  }
  > h4 {
    ${media.mobileM`
      padding: 8px 0 8px 16px;
      font-size: ${theme.fonts.size.small};
    `}
  }
`

export const ButtonText = styled(P)`
  cursor: pointer;
`

export default LoginContentWrapper
