import React, { PureComponent, useState, useEffect } from 'react'
import {
  Div, SmallText, Button, H4,
} from 'konsys-ui'
import history from '../../history'
import { Container, Main } from './styled'
import {
  getAccessToken, removeAccessToken, removeRefreshToken, getURL, getLocalStorage,
} from '../../utils/local-data-service'
import * as size from '../../constants/screen-size'
import { theme } from '../../styles/_variables'

const { color } = theme

export const ContentWrapper = (props) => {
  const [width, setWidth] = useState(window.innerWidth)
  const onMobile = width < size.IPAD

  const screenSizeHandler = () => {
    setWidth(window.innerWidth)
  }

  const logout = () => {
    removeAccessToken()
    removeRefreshToken()
    window.location = `${getURL().WEB_CMS_SERVER}/login`
  }

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    return () => window.removeEventListener('resize', screenSizeHandler)
  })

  return (
    <Container id='container' noScrollX={props.history.location && props.history.location.pathname === '/price-calculator'}>
      { !onMobile
        && <Div display='flex' justifyContent='space-between' alignItems='center' margin='0 -60px' padding='0 60px 24px' style={{ borderBottom: `1px solid ${color.paleGrey}` }}>
          <H4 bold>Boxsuit</H4>
          <Div>
            <SmallText>ยินดีต้อนรับ</SmallText>
            <SmallText bold>{getLocalStorage('fullname')}</SmallText>
            <Button text='ออกจากระบบ' icon='far fa-sign-out' small margin='0 0 0 16px' onClick={() => logout()}/>
          </Div>
        </Div>
      }
      { props.children }
    </Container>
  )
}

export class MainContainer extends PureComponent {
  static getDerivedStateFromProps() {
    if (!getAccessToken()) {
      history.push('/login')
    }
  }

  render() {
    // return (
    //   <Query
    //     query={CHECK_TOKEN}
    //   >{({ data, error, loading }) => {
    //     if (loading) return <LoadingPage />
    //     if (error || !data.tokenIsExpires.success) {
    //       removeAccessToken()
    //     }
    return (
      <Main>
        { this.props.children }
      </Main>
    )
    //   }}
    //   </Query>
    // )
  }
}

export default ContentWrapper
