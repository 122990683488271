export const isEveryIndex = (arr, value) => (
  arr.every(v => v === value)
)

export const passwordValidator = password => /([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]){6,18}/.test(password)

export const generateRowsIncrementStepTable = (boundaries = [
  { start: 1, end: 1, step: 0 },
  { start: 500, end: 2000, step: 500 },
  { start: 3000, end: 10000, step: 1000 },
]) => {
  const rows = []
  let n = 0
  let index = 0
  for (let i = 0; i < boundaries.length; i += 1) {
    const { start, end, step } = boundaries[i]
    let isStart = false
    while (n < end) {
      if (!isStart) {
        n = start
        isStart = true
      } else {
        n += step
      }
      rows.push({
        key: index,
        quantity: n,
        column1: 0,
        column2: 0,
        column3: 0,
        column4: 0,
        column5: 0,
      })
      index += 1
    }
  }
  return rows
}

export default isEveryIndex
