/* eslint-disable dot-notation */
import React, { useState, useEffect } from 'react'
import {
  message, Row, Col,
} from 'antd'
import {
  RowsContainer, SizeInputWrapper, InputNumber,
  SelectBox, LabelPosition, Span,
  ButtonAction, ButtonAddRow,
} from './styled'


const MultiSizeInput = ({
  values,
  thicknessList = [],
  unit = { key: 'cm', label: 'ซม.' },
  onChange,
  disabled,
}) => {
  const initialRowValues = {
    width: null,
    lengths: null,
    thickness: thicknessList.length > 0 ? thicknessList[0].thickness : null,
    unit: unit.key,
  }
  const [rows, setRows] = useState([initialRowValues])
  const [thicknessOptions, setThicknessOptions] = useState([])

  const onClickPlus = () => {
    const cloneRows = JSON.parse(JSON.stringify(rows))
    const tmp = [...cloneRows]
    if (tmp.length < 20) {
      tmp.push(initialRowValues)
      setRows(tmp)
    } else {
      message.warning('สามารถเพิ่มตัวเลือกได้ 20 ตัวเลือกเท่านั้น')
    }
  }
  const onClickMinus = (index) => {
    const cloneRows = JSON.parse(JSON.stringify(rows))
    const tmp = [...cloneRows]
    tmp.splice(index, 1)
    setRows(tmp)
    onChange(tmp)
  }
  const handleOnChange = (value, index) => {
    const tmp = [...rows]
    tmp[index] = value
    setRows(tmp)
    onChange(tmp)
  }

  useEffect(() => {
    if (thicknessList.length > 0) {
      setThicknessOptions([...thicknessList])
    }
  }, [thicknessList])

  useEffect(() => {
    if (values.length > 0) {
      const tmp = [...values]
      setRows(tmp)
    }
  }, [values])

  // useEffect(() => {
  //   onChange(rows)
  // }, [])

  return (
    <RowsContainer>
      {
        rows.map((item, index, arr) => (
          <SizeInputWrapper key={index}>
            <SizeInput
              values={rows}
              item={item}
              length={arr.length}
              index={index}
              unitLabel={unit.label}
              isThickness={thicknessOptions.length > 0}
              thicknessOptions={thicknessOptions}
              onChange={v => handleOnChange(v, index)}
              onClickMinus={() => onClickMinus(index)}
              disabled={disabled}
            />
          </SizeInputWrapper>
        ))
      }
      <ButtonAddRow
        icon="fa fa-plus"
        onlyIcon
        small
        // disabled={rows.length === thicknessOptions.length || disabled}
        disabled={disabled}
        onClick={onClickPlus}
      />
    </RowsContainer>
  )
}

const SizeInput = ({
  values,
  item,
  index,
  length,
  thicknessOptions,
  isThickness = false,
  unitLabel = 'ซ.ม.',
  onChange,
  disabledFirstItem = true,
  onClickMinus,
  disabled,
}) => {
  const handleOnChange = (changeKey, value) => {
    const tmp = values[index]
    tmp[changeKey] = value
    onChange(tmp, index)
  }
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '8px',
      padding: '8px',
      borderRadius: '5px',
      justifyContent: 'space-between',
    }}>
      <Row type='flex' justify='space-between' style={{ alignItems: 'center', marginBottom: '2px' }}>
        <Col span={12} style={{ justifyContent: 'left' }}>
          <LabelPosition>ตำแหน่งที่ {index + 1}</LabelPosition>
        </Col>
        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonAction
            icon="fa fa-minus"
            onlyIcon
            inverse
            small
            buttonStyle="round"
            disabled={disabledFirstItem ? (index >= 0 && length <= 1) : (index > 0 && length < 1)}
            onClick={() => onClickMinus(item.value, index)}
          />
        </Col>
      </Row>
      <Row type='flex' justify='space-between' style={{ alignItems: 'center' }}>
        <Col lg={8} span={12}>
          <Span>ขนาด</Span>
        </Col>
        <Col lg={6} span={4}>
          <InputNumber
            type='number'
            value={values[index].width}
            required
            min={0}
            max={500}
            precision={2}
            placeholder='กว้าง'
            onChange={v => handleOnChange('width', v)}
            style={{ width: '100%' }}
            onKeyPress={(e) => {
              if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault()
              }
            }}
            disabled={disabled}
          />
        </Col>
        <Col span={1} style={{ textAlign: 'center' }}>
          <Span>x</Span>
        </Col>
        <Col lg={6} span={4}>
          <InputNumber
            type='number'
            value={values[index].lengths}
            min={0}
            max={500}
            precision={2}
            placeholder='ยาว'
            onChange={v => handleOnChange('lengths', v)}
            style={{ width: '100%' }}
            onKeyPress={(e) => {
              if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault()
              }
            }}
            disabled={disabled}
          />
        </Col>
        <Col span={2} style={{ textAlign: 'center' }}>
          <Span>{unitLabel}</Span>
        </Col>
      </Row>
      {
        isThickness ? <Row type='flex' justify='space-between' style={{ alignItems: 'center' }}>
          <Col lg={8} span={12}>
            <Span> หนา</Span>
          </Col>
          <Col lg={16} span={12}>
            <SelectBox
              placeholder='หนา'
              onChange={v => handleOnChange('thickness', v)}
              value={values[index].thickness}
              disabled={disabled}
            >
              {
                thicknessOptions && thicknessOptions.length > 0 && thicknessOptions.map(option => (
                  <SelectBox.Option
                    key={option.thickness}
                    value={option.thickness}
                  >
                    {option.thickness}
                  </SelectBox.Option>
                ))
              }
            </SelectBox>
          </Col>
        </Row> : null
      }
    </div>
  )
}

export default MultiSizeInput
