import React from 'react'
import _ from 'lodash'
import {
  message,
} from 'antd'
import {
  Div,
} from 'konsys-ui'
import UploadFile from '../../../../components/common/upload'
import {
  PreviewCoverImage, Label,
} from './styled'


const CoverUpload = ({
  newFileList,
  setNewFileList,
  data,
}) => (
  <Div display='flex' flexDirection='column'>
      <Label>รูปหน้าปก</Label>
      <PreviewCoverImage src={newFileList.length === 1 ? newFileList[0].promotionFileUri ? newFileList[0].promotionFileUri : URL.createObjectURL(newFileList[0]) : null } />
      <UploadFile
          list={newFileList}
          id='uploadCoverFileId'
          multiple={false}
          onChange={(fileList) => {
            let upload = false
            const allowFileType = ['jpg', 'jpeg', 'png']
            for (let i = 0; i < fileList.length; i += 1) {
              const splitName = fileList[i].name.split('.')
              if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
            || (newFileList.length > 0 && !_.isUndefined(_.find(newFileList, d => d.name === fileList[i].name)))) {
                upload = false
                break
              } else {
                upload = true
              }
            }
            if (upload) {
            // Set only one image to state
              setNewFileList({ ...data, coverPhoto: [...fileList] })
            } else {
              message.error('สามารถอัพโหลดได้เฉพาะไฟล์ jpg, jpeg, png และไม่สามารถอัพรูปซ้ำได้')
            }
          }}
          onRemoveFile={(fileName) => {
            const newUploadFileList = [...newFileList]
            _.remove(newUploadFileList, v => v.name === fileName)
            setNewFileList({ ...data, coverPhoto: newUploadFileList })
          }}
          fileType="image/*"
          confirm={true}
          confirmText={'ยืนยันการลบรูปภาพหน้าปกหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'}
          fileKey={'promotionFileUri'}
      />
  </Div>
)

export default CoverUpload
