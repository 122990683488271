import styled from 'styled-components'
import { InputNumber as AntInputNumber } from 'antd'
import { Button } from 'konsys-ui'
import { theme, media } from '../../../../styles/_variables'

export const Input = styled(AntInputNumber)`
width: 100%;
border-radius: 8px;
font-size: ${theme.fonts.size.extraNormal};
font-weight: ${theme.fonts.style.medium};
background-color: rgba(${theme.color.primaryColorRGB}, 0.1)
}    
`

export const InputWrapper = styled.div`
> p{
    width: 40%;
    text-align: left;
    color: ${theme.color.greyNormal};
    ${media.mobileL`
        width: 80%;
    `}
}
> span{
    width: 20%;
    text-align: center;
    color: ${theme.color.greyNormal};
    margin-left: 12px;
    margin-right: 12px;
}
width: 70%;
${media.mobileL`
    width: 90%;
`}
font-size: ${theme.fonts.size.normal}
font-weight: ${theme.fonts.style.medium};
display: flex;
height: fit-content;
`

export const FormWrapper = styled.div`
width: 100%;
display: flex;
height: fit-content;
justify-content: center;
`

export const CenterWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
`

export const ButtonConfirm = styled(Button)`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 6px 16px 4px 10px;
background-color: ${theme.color.greenPrimary};
box-shadow: 2px 2px 0px 1px rgb(110, 201, 190, 0.4) !important;
& > p {
    font-size: ${theme.fonts.size.extraNormal};
    line-height: 24px;
    margin: 0px !important;
  }
${media.mobileL`
    margin-bottom: 12px;
`}
`

export const IconConfirm = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-top: -4px;
`

export default Input
