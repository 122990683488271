import styled from 'styled-components'
import { Div } from 'konsys-ui'
import { Select as AntSelect } from 'antd'
import { theme } from '../../../../styles/_variables'


export const Label = styled.h4`
  font-weight: 700;
  font-size: 22px;
  margin: 8px 0;
  color: ${theme.color.offWhite};
`

export const Content = styled(Div)`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  width: 100%;
  background-color: ${theme.color.greenPrimary};
  border-radius: 4px;
  box-shadow: 2px 2px 0px 1px rgb(110, 201, 190, 0.4) !important;
`

export const Select = styled(AntSelect)`
  .ant-select-selection {
    border: 1px solid #EFFFFE !important;
    background-color: #EFFFFE !important;
  }
  .ant-select-selection--single {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-select-selection-selected-value {
    color: ${theme.color.greyNormal};
  }
  .ant-select-arrow {
    color: rgba(${theme.color.primaryColorRGB}, 1);
    top: 40% !important;
    opacity: 0.6;
  }
`

export default Label
