import React from 'react'
import { Div } from 'konsys-ui'
import {
  Title, Label, DivRadioOrderType, DivCenter, RadioOrderType,
  ImgIcon, DivImage,
} from './styled'
import NewOrder from '../../../../../images/icon/New-order Icon.png'
import RepeatOrder from '../../../../../images/icon/Repeat Icon.png'


const OrderTypeSelect = ({
  data,
  onChange,
  disabled = false,
}) => (
  <Div style={{ width: '100%' }}>
    <DivCenter>
      <Title>{'เลือกรูปแบบการสั่งผลิต'}</Title>
    </DivCenter>
    <Div style={{ display: 'flex' }}>
      <DivRadioOrderType
        checked={data === 'newOrder'}
        onClick={!disabled && (() => onChange('newOrder'))}
      >
        <DivCenter>
          <DivImage>
            <ImgIcon
              src={NewOrder}
            />
          </DivImage>
        </DivCenter>
        <DivCenter style={{ padding: '8px' }}>
          <Label checked={data === 'newOrder'}>{'สั่งผลิตใหม่'}</Label>
        </DivCenter>
        <DivCenter>
          <RadioOrderType
            onChange={() => onChange('newOrder')}
            checked={data === 'newOrder'}
            disabled={disabled}
          />
        </DivCenter>
      </DivRadioOrderType>
      <DivRadioOrderType
        checked={data === 'repeatOrder'}
        onClick={!disabled && (() => onChange('repeatOrder'))}
      >
        <DivCenter>
          <DivImage>
            <ImgIcon
              src={RepeatOrder}
            />
          </DivImage>
        </DivCenter>
        <DivCenter style={{ padding: '8px' }}>
          <Label checked={data === 'repeatOrder'}>{'สั่งผลิตซ้ำ'}</Label>
        </DivCenter>
        <DivCenter>
          <RadioOrderType
            onChange={() => onChange('repeatOrder')}
            checked={data === 'repeatOrder'}
            disabled={disabled}
          />
        </DivCenter>
      </DivRadioOrderType>
    </Div>
  </Div>
)

export default OrderTypeSelect
