import styled from 'styled-components'
import { theme, media } from '../../../styles/_variables'

export const RadioWrapper = styled.div`
  display: inline-block;
  padding: 14px 32px 8px;
  background-color: ${theme.color.secondaryColor};
  box-shadow: 3px 3px 0 1px rgba(${theme.color.primaryColorRGB}, 0.7) !important;
  border-radius: 30px;
  cursor: pointer;
  label > span {
    border: 1px solid ${theme.color.offWhite};
    background-color: ${theme.color.offWhite};
    &:before {
      background-color: ${theme.color.tertiaryColor};
    }
  }
  p {
    color: ${theme.color.offWhite};
    font-size: ${theme.fonts.size.subTitle};
    font-family: ${theme.fonts.family.normal};
    padding-left: 16px;
    white-space: nowrap;
  }
  ${media.tablet`
    padding: 8px 19px 4px;
    border-radius: 20px;
    p {
      font-size: ${theme.fonts.size.extraNormal};
      padding-left: 8px;
    }
  `}
`

export const CheckboxWrapper = styled(RadioWrapper)`
  label > span {
    &:before {
      background-color: transparent;
    }
  }
`

export const RadioCusWrapper = styled.div`
  position: relative;
  > label {
    cursor: pointer;
    display: flex;
    align-items: center;
    > span {
      width: 16px;
      height: 16px;
      background-color: ${props => (props.disabled ? '#F2F2F2' : 'white')};
      border-radius: 50%;
      position: relative;
      display: inline-block;
      box-shadow: 1px 1px 0 1px rgba(${theme.color.primaryColorRGB}, 0.2);
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${theme.color.lightBlack};
        opacity: 0;
      }
    }
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
  }
  input:checked + label {
    > span {
      &::before {
        opacity: 1;
      }
    }
  }
`

export default RadioWrapper
