import React, { useState, useEffect } from 'react'
import {
  Div, Input,
} from 'konsys-ui'
import {
  ButtonAction, LabelCell, DivCell,
  Table, DivRowSize, DivToolbar, DivTitle,
  DivItemCell, Label, InputNumber,
} from './styled'

const EditableCell = ({
  editing,
  children,
  inputType,
  record,
  dataIndex,
  title,
  onChangeCell,
  onAddCellPrice,
  onRemoveCellPrice,
  alignCell = 'left',
  style,
  ...restProps
}) => {
  const [value, setValue] = useState(record ? record[dataIndex] : '')
  useEffect(() => {
    if (record && record[dataIndex]) {
      setValue(record[dataIndex])
    }
  }, [record])

  let inputNode = <DivItemCell>
    <Input
      type="text"
      value={value}
      width="100px"
      onChange={(e) => {
        setValue(e.target.value)
        onChangeCell(e.target.value, dataIndex)
      }}
    />
  </DivItemCell>
  let cellNode = <LabelCell>{children}</LabelCell>
  if (dataIndex === 'size') {
    inputNode = (<Div>
      <DivRowSize>
        <DivItemCell>
          <InputNumber
            type="number"
            width="60px"
            min={0}
            max={9999}
            value={value.width}
            onChange={(e) => {
              if (e > 0 && e < 9999) {
                const tmpValue = { ...value }
                tmpValue.width = Math.round(e * 100) / 100
                setValue(tmpValue)
                onChangeCell(tmpValue, dataIndex)
              }
            }}
            onKeyPress={(e) => {
              if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault()
              }
            }}
          />
        </DivItemCell>
        <DivItemCell>
        X
        </DivItemCell>
        <DivItemCell>
        <InputNumber
          type="number"
          width="60px"
          min={0}
          max={9999}
          value={value.height}
          onChange={(e) => {
            if (e > 0 && e < 9999) {
              const tmpValue = { ...value }
              tmpValue.height = Math.round(e * 100) / 100
              setValue(tmpValue)
              onChangeCell(tmpValue, dataIndex)
            }
          }}
          onKeyPress={(e) => {
            if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
              e.preventDefault()
            }
          }}
        />
        </DivItemCell>
      </DivRowSize>
    </Div>)
    cellNode = (<Div>
      <LabelCell>{value.width} X {value.height}</LabelCell>
    </Div>)
  }
  return (
    <td {...restProps}>
      <DivCell alignCell={alignCell}>
        {editing ? inputNode : cellNode}
      </DivCell>
    </td>
  )
}

const PrintingTypeTable = ({
  pagination = false,
  data = [],
  onSubmitEditRow,
  title,
}) => {
  const [columns] = useState([{
    title: 'ชื่อประเภทเครื่องพิมพ์',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    alignCell: 'center',
    editable: false,
  }, {
    title: 'ขนาดพื้นที่พิมพ์ (นิ้ว)',
    dataIndex: 'size',
    key: 'size',
    align: 'center',
    alignCell: 'center',
    editable: true,
  }])
  const [rows, setRows] = useState(data)
  const [editingKey, setEditingKey] = useState('')
  const [dataRowEditing, setDataRowEditing] = useState({})

  useEffect(() => {
    setRows(data)
  }, [data])

  const isEditingRow = record => record.key === editingKey

  const renderColumn = () => {
    let tmp = [...columns]
    tmp = columns.map((column) => {
      let onCell = {
        onCell: record => ({
          record,
          dataIndex: column.dataIndex,
          alignCell: column.alignCell,
          title: column.title,
        }),
      }
      if (column.editable) {
        onCell = {
          onCell: record => ({
            record,
            dataIndex: column.dataIndex,
            alignCell: column.alignCell,
            title: column.title,
            editing: isEditingRow(record),
            onChangeCell: (value, dataIndex) => {
              setDataRowEditing({
                ...dataRowEditing,
                [dataIndex]: value,
              })
            },
          }),
        }
      }
      return {
        title: column.title,
        dataIndex: column.dataIndex,
        key: column.key,
        align: 'center',
        // width: '49.95%',
        ...onCell,
      }
    })
    tmp.push({
      title: 'คำสั่ง',
      dataIndex: 'action',
      key: 'action',
      width: '120px',
      align: 'center',
      onCell: () => ({
        alignCell: 'center',
      }),
      render: (text, record) => {
        const editable = isEditingRow(record)
        return editable ? (
          <Div>
            <Div>
              <ButtonAction
                text='ยืนยัน'
                inverse
                icon='fa fa-floppy-o'
                onClick={() => {
                  const result = [...rows]
                  result[record.key] = { ...dataRowEditing }
                  setRows(result)
                  onSubmitEditRow(record.key, dataRowEditing)
                  setDataRowEditing({})
                  setEditingKey('')
                }}
              />
            </Div>
          </Div>
        ) : (
          <ButtonAction
            disabled={editingKey !== ''}
            text='แก้ไข'
            inverse
            icon='fa fa-pencil-square-o'
            onClick={() => {
              setDataRowEditing(record)
              setEditingKey(record.key)
            }}
          />
        )
      },
    })
    return tmp
  }

  return (
    <Div>
      <DivToolbar>
        <DivTitle>
          <Label>{title}</Label>
        </DivTitle>
      </DivToolbar>
      <Div>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          pagination={pagination}
          columns={renderColumn()}
          dataSource={rows}
        />
      </Div>
    </Div>
  )
}

export default PrintingTypeTable
