import React from 'react'
import { Link } from 'react-router-dom'
import {
  Div, SmallText,
} from 'konsys-ui'
import { theme } from '../../../styles/_variables'

const BreadCrumb = ({ link }) => (
  <Div margin='0 0 0 -6px'>
  {
    link.parent.map((v, index) => (
      <Link key={v.text} to={v.link}><SmallText margin='0 0 0 6px' color={theme.color.primaryColor}>{index !== 0 && '/ '}{v.text}</SmallText></Link>
    ))
  }
    <SmallText margin='0 0 0 6px' color={theme.color.lightGrey}>/ {link.current.text}</SmallText>
  </Div>
)

export default BreadCrumb
