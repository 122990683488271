import React from 'react'
import { Select, Title } from './styled'


const { Option } = Select

const DieCutSelector = ({
  formValues,
  setFormValues,
  activeTab,
  disabled,
}) => {
  const handleOnChange = (value) => {
    const tmpFormValues = formValues.partData
    tmpFormValues[activeTab].dieCut = value
    setFormValues({ ...formValues, partData: tmpFormValues })
  }
  return (
    <div>
      <Title>บริการปั้มตัด</Title>
      <Select
        onChange={handleOnChange}
        defaultValue={formValues.partData[activeTab].dieCut}
        disabled={disabled}
        value={formValues.partData[activeTab].dieCut}
      >
        <Option key={0} value={false}>ไม่ใช้บริการ</Option>
        <Option key={1} value={true}>ใช้บริการ</Option>
      </Select>
    </div>
  )
}

export default DieCutSelector
