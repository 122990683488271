import React, { useState, useRef, useEffect } from 'react'
import { Row, Col } from 'antd'
import parseHtml from 'html-react-parser'
import videojs from 'video.js'
import _ from 'lodash'
import {
  DescriptionContainer, Title, ButtonWrapper,
  TitleWrapper, Modal, DescriptionContent,
  VideoButton, TitleSpan, SpanButton,
} from './styled'
import SampleVideo from '../sample-video'
import VideoIcon from '../../../../../images/icon/VideoIcon.png'


const OptionDescription = ({
  title = 'title',
  secondTitle,
  description,
  videoSrc,
}) => {
  const [videoLink, setVideoLink] = useState(null)
  const videoSrcRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const onToggleModel = () => {
    setVisible(!visible)
  }
  const prevVidRef = useRef()
  useEffect(() => {
    if (videoSrc) {
      videoSrcRef.current = videoSrc
      setVideoLink(videoSrc)
    }
  }, [videoSrc])

  useEffect(() => {
    prevVidRef.current = videoLink
  }, [videoLink])

  return (
    <DescriptionContainer>
      <TitleWrapper>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} span={16}>
            <Title>{title}</Title>
          </Col>
          {secondTitle && <Col xs={24} sm={24} md={24} span={16}>
             <TitleSpan>{secondTitle}</TitleSpan>
          </Col>}
        </Row>
      </TitleWrapper>
      {
        description && <DescriptionContent moreStyle={{ lineBreak: 'anywhere' }}>
          {description && parseHtml(description)}
        </DescriptionContent>
      }
      { videoSrc ? <ButtonWrapper>
        <VideoButton
          text={<SpanButton><img style={{ width: '20px', marginRight: '8px' }} src={VideoIcon} />ดูวิดีโอตัวอย่าง</SpanButton>}
          inverse
          onClick={onToggleModel}
        />
      </ButtonWrapper> : ''}
      <VideoModal
        videoSrc={videoSrcRef}
        visible={visible}
        onOk={onToggleModel}
        onCancel={onToggleModel}
        prevVideo={prevVidRef.current}
      />
    </DescriptionContainer>
  )
}

const VideoModal = ({
  videoSrc,
  visible,
  onOk,
  onCancel,
  prevVideo,
}) => {
  const playerRef = useRef(null)
  const [videoLink, setVideoLink] = useState(prevVideo)
  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: videoSrc.current,
      type: 'video/mp4',
    }],
  }
  const prevVidRef = useRef()
  useEffect(() => {
    prevVidRef.current = videoLink
  }, [videoLink])
  useEffect(() => {
    setVideoLink(prevVideo)
  }, [prevVideo])
  const handlePlayerReady = (player) => {
    playerRef.current = player

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting')
    })

    player.on('dispose', () => {
      videojs.log('player will dispose')
    })
  }
  return (
    <Modal
      title='วิดีโอตัวอย่าง'
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      className={'video-modal'}
    >
      <SampleVideo
        options={videoJsOptions} onReady={handlePlayerReady} visible={visible} prevVidRef={prevVidRef.current}
      />
    </Modal>
  )
}

export default OptionDescription
