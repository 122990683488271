import OPT from 'options'

export default {
  development: {
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
    GRANT_TYPE: process.env.REACT_APP_GRANT_TYPE,
    GRAPH_PRIVATE: process.env.REACT_APP_GRAPH_PRIVATE,
    GRAPH_PUBLIC: process.env.REACT_APP_GRAPH_PUBLIC,
    // GRAPH_PRIVATE_WS: 'ws://192.168.1.38:8111/subscriptions',
    // WEB_SERVER: `http://localhost:${OPT.port}`,
    // API_HOST: 'http://192.168.1.25:3333',
    // FILE_SERVER: 'http://192.168.1.25:3333',
  },
  staging: {
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
    GRANT_TYPE: process.env.REACT_APP_GRANT_TYPE,
    GRAPH_PRIVATE: process.env.REACT_APP_GRAPH_PRIVATE,
    GRAPH_PUBLIC: process.env.REACT_APP_GRAPH_PUBLIC,
    // GRAPH_PRIVATE_WS: 'ws://192.168.1.38:8111/subscriptions',
    // WEB_SERVER: `http://localhost:${OPT.port}`,
    // API_HOST: 'http://192.168.1.25:3333',
    // FILE_SERVER: 'http://192.168.1.25:3333',
  },
  production: {
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
    GRANT_TYPE: process.env.REACT_APP_GRANT_TYPE,
    GRAPH_PRIVATE: process.env.REACT_APP_GRAPH_PRIVATE,
    GRAPH_PUBLIC: process.env.REACT_APP_GRAPH_PUBLIC,
    // GRAPH_PRIVATE_WS: 'ws://192.168.1.38:8111/subscriptions',
    // WEB_SERVER: `http://localhost:${OPT.port}`,
    // API_HOST: 'http://192.168.1.25:3333',
    // FILE_SERVER: 'http://192.168.1.25:3333',
  },
}
