import React from 'react'
import { Div } from 'konsys-ui'
import {
  OptionLabel, OptionContentWrapper,
  OptionText, CoverImage,
} from './styled'
import ForbiddenRed from '../../../../../images/icon/Forbidden-red Icon.png'

const CircleOptionItem = ({
  value,
  checked,
  onChecked,
  icon,
  label,
  textOverlay = '',
  disabled,
  backgroundRed,
}) => (
  <Div>
    <OptionLabel disabled={disabled} backgroundRed={backgroundRed}>
      <input
        type='radio'
        id={value}
        value={value}
        checked={checked}
        onChange={e => onChecked(e.target.value)}
        disabled={disabled}
      />
      <OptionContentWrapper>
        {(checked && backgroundRed) ? <CoverImage src={ForbiddenRed} /> : icon}
        <p>{textOverlay}</p>
      </OptionContentWrapper>
      <OptionText checked={checked}>{label}</OptionText>
    </OptionLabel>
  </Div>
)

export default CircleOptionItem
