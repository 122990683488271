import styled from 'styled-components'
import { Div } from 'konsys-ui'
import { theme, media } from '../../../../styles/_variables'


export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.extraNormal};
  margin: 8px 0;
  color: ${theme.color.offWhite};
`

export const Content = styled(Div)`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  width: 480px;
  background-color: ${theme.color.greenPrimary};
  border-radius: 4px;
  ${media.tablet`
    width: 240px;
    column-gap: 12px;
  `}
  ${media.mobileL`
    width: 100%;
    column-gap: 12px;
  `}
`

export default Label
