import styled from 'styled-components'
import { theme } from '../../../styles/_variables'

export const TransferListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: solid 1px #C0BFC1;
  border-radius: 5px;
  width: 250px;
  height: 300px;
  row-gap: 5px;
  background-color: ${theme.color.offWhite};
  overflow-y: auto;
`
export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: rgb(${props => (props.backgroundColorRGB)});
  border-radius: 1.5vh;
  > p {
    padding: 0px 0px 0px 10px;
    font-weight: ${theme.fonts.style.regular};
    font-size: ${theme.fonts.size.extraNormal}
    color: ${theme.color.offWhite}
  }
  &:hover {
    background-color: rgba(${props => props.backgroundColorRGB || theme.color.primaryColorRGB}, 0.85); 

  }
`

export const FlipIconWrapper = styled.div`
  cursor: pointer;
  i:active {
    color: #317ACACC;
  }
`

export default TransferListWrapper
