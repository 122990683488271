import React from 'react'
import {
  message,
} from 'antd'
import { Mutation } from 'react-apollo'
import {
  Div,
} from 'konsys-ui'
import ReactQuill from 'react-quill'
import {
  Label, DivToolbarEditor, SaveButton,
} from './styled'
import 'react-quill/dist/quill.snow.css'
import {
  UPDATE_QUOTATION_SUBMITTED_CONFIRM_TEXT,
} from '../../../../constants/graphql/mutation'
import { theme } from '../../../../styles/_variables'


const editorModules = {
  toolbar: {
    container: '#toolbar',
  },
}

const editorFormats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'link', 'image', 'video',
]

const CustomToolbar = () => (
    <DivToolbarEditor id="toolbar">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-strike"></button>
    </DivToolbarEditor>
)
const Description = ({
  onInputChange,
  data,
}) => (
  <Div>
    <Label>ข้อความยืนยันการขอใบเสนอราคา</Label>
    <CustomToolbar/>
    <Div className='custom-editor'>
      <ReactQuill
        theme="snow"
        defaultValue={data.confirmText}
        value={data.confirmText}
        formats={editorFormats}
        modules={editorModules}
        onChange={v => onInputChange({ ...data, confirmText: v })}
      />
    </Div>
    <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
      <Mutation mutation={UPDATE_QUOTATION_SUBMITTED_CONFIRM_TEXT}>
        {
          doUpdateQuotationSubmittedConfirmText => (
            <SaveButton
              buttonStyle='rectangle'
              text='บันทึก'
              onClick={() => {
                doUpdateQuotationSubmittedConfirmText({
                  variables: {
                    confirmText: data.confirmText,
                  },
                }).then((res) => {
                  if (res.data.editQuotationSubmittedConfirmText) {
                    if (res.data.editQuotationSubmittedConfirmText.success) {
                      message.success(res.data.editQuotationSubmittedConfirmText.message)
                    } else {
                      message.error(res.data.editQuotationSubmittedConfirmText.message)
                    }
                  }
                })
              }}
            />
          )
        }
      </Mutation>
    </Div>
  </Div>
)

export default Description
