import styled from 'styled-components'
import { InputNumber as InputNumberAnt } from 'antd'
import { theme } from '../../../../../styles/_variables'


export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`

export const InputNumber = styled(InputNumberAnt)`
  .ant-input-number-input {
    text-align: center;
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
  }
`

export default Label
