const PROVINCE = [
  {
    pid: 10,
    name: 'กรุงเทพมหานคร',
  },
  {
    pid: 11,
    name: 'สมุทรปราการ',
  },
  {
    pid: 12,
    name: 'นนทบุรี',
  },
  {
    pid: 13,
    name: 'ปทุมธานี',
  },
  {
    pid: 14,
    name: 'พระนครศรีอยุธยา',
  },
  {
    pid: 15,
    name: 'อ่างทอง',
  },
  {
    pid: 16,
    name: 'ลพบุรี',
  },
  {
    pid: 17,
    name: 'สิงห์บุรี',
  },
  {
    pid: 18,
    name: 'ชัยนาท',
  },
  {
    pid: 19,
    name: 'สระบุรี',
  },
  {
    pid: 20,
    name: 'ชลบุรี',
  },
  {
    pid: 21,
    name: 'ระยอง',
  },
  {
    pid: 22,
    name: 'จันทบุรี',
  },
  {
    pid: 23,
    name: 'ตราด',
  },
  {
    pid: 24,
    name: 'ฉะเชิงเทรา',
  },
  {
    pid: 25,
    name: 'ปราจีนบุรี',
  },
  {
    pid: 26,
    name: 'นครนายก',
  },
  {
    pid: 27,
    name: 'สระแก้ว',
  },
  {
    pid: 30,
    name: 'นครราชสีมา',
  },
  {
    pid: 31,
    name: 'บุรีรัมย์',
  },
  {
    pid: 32,
    name: 'สุรินทร์',
  },
  {
    pid: 33,
    name: 'ศรีสะเกษ',
  },
  {
    pid: 34,
    name: 'อุบลราชธานี',
  },
  {
    pid: 35,
    name: 'ยโสธร',
  },
  {
    pid: 36,
    name: 'ชัยภูมิ',
  },
  {
    pid: 37,
    name: 'อำนาจเจริญ',
  },
  {
    pid: 38,
    name: 'บึงกาฬ',
  },
  {
    pid: 39,
    name: 'หนองบัวลำภู',
  },
  {
    pid: 40,
    name: 'ขอนแก่น',
  },
  {
    pid: 41,
    name: 'อุดรธานี',
  },
  {
    pid: 42,
    name: 'เลย',
  },
  {
    pid: 43,
    name: 'หนองคาย',
  },
  {
    pid: 44,
    name: 'มหาสารคาม',
  },
  {
    pid: 45,
    name: 'ร้อยเอ็ด',
  },
  {
    pid: 46,
    name: 'กาฬสินธุ์',
  },
  {
    pid: 47,
    name: 'สกลนคร',
  },
  {
    pid: 48,
    name: 'นครพนม',
  },
  {
    pid: 49,
    name: 'มุกดาหาร',
  },
  {
    pid: 50,
    name: 'เชียงใหม่',
  },
  {
    pid: 51,
    name: 'ลำพูน',
  },
  {
    pid: 52,
    name: 'ลำปาง',
  },
  {
    pid: 53,
    name: 'อุตรดิตถ์',
  },
  {
    pid: 54,
    name: 'แพร่',
  },
  {
    pid: 55,
    name: 'น่าน',
  },
  {
    pid: 56,
    name: 'พะเยา',
  },
  {
    pid: 57,
    name: 'เชียงราย',
  },
  {
    pid: 58,
    name: 'แม่ฮ่องสอน',
  },
  {
    pid: 60,
    name: 'นครสวรรค์',
  },
  {
    pid: 61,
    name: 'อุทัยธานี',
  },
  {
    pid: 62,
    name: 'กำแพงเพชร',
  },
  {
    pid: 63,
    name: 'ตาก',
  },
  {
    pid: 64,
    name: 'สุโขทัย',
  },
  {
    pid: 65,
    name: 'พิษณุโลก',
  },
  {
    pid: 66,
    name: 'พิจิตร',
  },
  {
    pid: 67,
    name: 'เพชรบูรณ์',
  },
  {
    pid: 70,
    name: 'ราชบุรี',
  },
  {
    pid: 71,
    name: 'กาญจนบุรี',
  },
  {
    pid: 72,
    name: 'สุพรรณบุรี',
  },
  {
    pid: 73,
    name: 'นครปฐม',
  },
  {
    pid: 74,
    name: 'สมุทรสาคร',
  },
  {
    pid: 75,
    name: 'สมุทรสงคราม',
  },
  {
    pid: 76,
    name: 'เพชรบุรี',
  },
  {
    pid: 77,
    name: 'ประจวบคีรีขันธ์',
  },
  {
    pid: 80,
    name: 'นครศรีธรรมราช',
  },
  {
    pid: 81,
    name: 'กระบี่',
  },
  {
    pid: 82,
    name: 'พังงา',
  },
  {
    pid: 83,
    name: 'ภูเก็ต',
  },
  {
    pid: 84,
    name: 'สุราษฎร์ธานี',
  },
  {
    pid: 85,
    name: 'ระนอง',
  },
  {
    pid: 86,
    name: 'ชุมพร',
  },
  {
    pid: 90,
    name: 'สงขลา',
  },
  {
    pid: 91,
    name: 'สตูล',
  },
  {
    pid: 92,
    name: 'ตรัง',
  },
  {
    pid: 93,
    name: 'พัทลุง',
  },
  {
    pid: 94,
    name: 'ปัตตานี',
  },
  {
    pid: 95,
    name: 'ยะลา',
  },
  {
    pid: 96,
    name: 'นราธิวาส',
  },
]

export default PROVINCE
