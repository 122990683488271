import styled from 'styled-components'
import { media } from '../../styles/_variables'

export const Container = styled.div`
  padding: 28px 60px 72px 60px;
  ${media.laptop`
      padding: 28px 30px 72px 30px;
  `}
  ${media.tabletS`
      overflow-x: ${props => (props.noScrollX && 'hidden')};
  `}
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #f6f5f5;
  /* background-color: #fff; */
`

export const Main = styled.div`
  display: flex;
`

export default Container
