import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import { Div, Button } from 'konsys-ui'
import {
  InputWithPlaceHolder, StatusFilterDropdown, HeaderLabel, CellText, StatusChangeDropdown,
  StatusDropdown,
} from '../../../components/quotation-components'
import { status } from '../constants'
import { Image } from '../styled'
import PATH from '../../../constants/path'

export default {
  columns: [{
    Header: null,
    accessor: 'selector',
    filterable: false,
    sortable: false,
    width: 40,
  }, {
    Header: () => <HeaderLabel>หมายเลขอ้างอิง</HeaderLabel>,
    accessor: 'refId',
    sortable: false,
    filterable: true,
    Cell: props => <Link to={`${PATH.quotationDetail}/${props.original.quotationId}`}><CellText bold>{props.value}</CellText></Link>,
    Filter: ({ onChange }) => <InputWithPlaceHolder handleChange={e => onChange(e)}/>,
    filterMethod: (filter, row) => _.includes(row.id, filter.value),
    width: 160,
  }, {
    Header: () => <HeaderLabel>วันที่สร้าง</HeaderLabel>,
    accessor: 'createdAt',
    Cell: props => <CellText bold>{moment(props.value).format('DD/MM/YYYY')}</CellText>,
    Filter: ({ onChange }) => <InputWithPlaceHolder handleChange={e => onChange(e)}/>,
    filterMethod: (filter, row) => _.includes(row.createdDate, filter.value),
    sortable: false,
    filterable: false,
    width: 100,
  }, {
    Header: () => <HeaderLabel>ชื่อลูกค้า</HeaderLabel>,
    accessor: 'address.name',
    sortable: false,
    filterable: true,
    Cell: props => <CellText bold>{`${props.value} ${props.original.address.surname}`}</CellText>,
    Filter: ({ onChange }) => <InputWithPlaceHolder handleChange={e => onChange(e)}/>,
    filterMethod: (filter, row) => _.includes(row.clientId, filter.value),
    width: 250,
  }, {
    Header: <HeaderLabel>รูปสถานะ</HeaderLabel>,
    accessor: 'status',
    sortable: false,
    filterable: false,
    Cell: props => (
      <Div width='fit-content' margin='auto'><Image src={_.find(status, o => o.key === props.value).icon} /></Div>
    ),
    Filter: ({ onChange }) => <InputWithPlaceHolder handleChange={e => onChange(e)}/>,
    filterMethod: (filter, row) => _.includes(row.clientName, filter.value),
    width: 140,
  }, {
    Header: <HeaderLabel>สถานะ</HeaderLabel>,
    accessor: 'status',
    sortable: false,
    filterable: true,
    Cell: props => <StatusDropdown key={props.original.quotationId} listPage width={147} id={props.original.quotationId} presentStatus={props.original.status} />,
    Filter: ({ onChange }) => <StatusFilterDropdown handleChange={e => onChange(e)} statusAttr='all' filter={true}/>,
    filterMethod: (filter, row) => _.includes(row.from, filter.value),
    width: 160,
    // ----------------------------------------------------------------------------------------------------------------
  }, {
    Header: null,
    accessor: 'action',
    sortable: false,
    filterable: false,
    Cell: props => <Link to={`${PATH.quotationDetail}/${props.original.quotationId}`}><Button icon="fas fa-arrow-right" onlyIcon /></Link>,
    width: 70,
  }],
}
