import Konsys from 'modules/konsys-ui' // eslint-disable-line
import styled, { withTheme, css } from 'styled-components'
import { theme, media } from '../../styles/_variables'

// export const Button = withTheme(Konsys.Button)
const ButtonCus = styled(Konsys.Button)`
  > p {
    font-family: 'printAble4u';
  }
`
export const Button = withTheme(ButtonCus)
export const ButtonForm = styled(Button)`
  padding: 10px 32px 8px;
  box-shadow: 3px 3px 0 1px rgba(${theme.color.primaryColorRGB}, 0.7) !important;
  margin: 0 56px;
  ${props => props.disabled && css`
    box-shadow: 3px 3px 0 1px rgba(${theme.color.primaryColorRGB}, 0.2) !important;
  `}
  ${media.tablet`
    margin: 0;
    padding: 8px 24px 4px;
  `}
  i {
    font-size: ${theme.fonts.size.extraNormal} !important;
    line-height: 1;
    opacity: 0.7;
    margin: ${props => (props.rightIcon ? '0 0 0 16px' : '0 16px 0 0')} !important;
    ${props => props.disabled && css`
      color: ${theme.color.primaryColor} !important;
      opacity: 0.2;
    `}
    ${media.tablet`
      font-size: ${theme.fonts.size.small} !important;
    `}
  }
  p,
  span {
    font-size: ${theme.fonts.size.subTitle};
    line-height: 1;
    ${media.tablet`
      font-size: ${theme.fonts.size.extraNormal};
    `}
  }
`
export const Icon = withTheme(Konsys.Icon)
export const TextLink = withTheme(Konsys.TextLink)
export const P = withTheme(Konsys.P)
export const H1 = withTheme(Konsys.H1)
export const H2 = withTheme(Konsys.H2)
export const H3 = withTheme(Konsys.H3)
export const H4 = styled(withTheme(Konsys.H4))`
  font-weight: ${props => (props.bold ? '600' : '300')};
  line-height: 1;
`
export const H5 = styled(Konsys.H4)`
  font-size: 20px;
  font-family: 'printAble4u' !important;
`
export const Label = withTheme(Konsys.Label)
export const SmallText = withTheme(Konsys.SmallText)
export const CheckboxGroup = withTheme(Konsys.CheckboxGroup)
export const Checkbox = withTheme(Konsys.Checkbox)
export const RadioGroup = withTheme(Konsys.RadioGroup)
export const Radio = withTheme(Konsys.Radio)
export const Loading = withTheme(Konsys.Loading)
export const Select = withTheme(Konsys.Select)
export const Collapse = withTheme(Konsys.Collapse)
export const LoadingIcon = withTheme(Konsys.LoadingIcon)

export const InputWithLabel = withTheme(Konsys.InputWithLabel)
export const Input = withTheme(Konsys.Input)

export const Div = withTheme(Konsys.Div)

export const RadioCus = withTheme(Konsys.Radio)
export const RadioDefault = styled(RadioCus)`
  > label {
    > span {
    background-color: #FFFFFF;
    box-shadow: 1px 1px 0 1px rgba(${theme.color.primaryColorRGB}, 0.2);
    border: none;
      &:before {
        background-color: ${theme.color.lightBlack}
      }
    }
    > p {
      font-family: ${theme.fonts.family.normal};
      font-size: ${theme.fonts.size.extraNormal};
    }
  }
`
export const CheckboxCus = withTheme(Konsys.Checkbox)
export const CheckboxDefault = styled(CheckboxCus)`
  > label {
    > span {
      &::before {
        font-family: "Font Awesome 5 Pro";
      }
    }
  }
`

export default Button
