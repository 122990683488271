import styled from 'styled-components'
import { theme } from '../../../../styles/_variables'

export const WidthContainer = styled.div`
  width: 60%;
  min-width: 300px;
`
export const Label = styled.h4`
  font-weight: 700;
  font-size: 24px;
  margin-top: 16px;
  margin-bottom: 8px;
  color: ${theme.color.primaryColor};
`


export default WidthContainer
