import React, { useState, useEffect } from 'react'
import { Div, LoadingIcon } from 'konsys-ui'
import { Query } from 'react-apollo'
import { Row, Col } from 'antd'
import { LIST_SPOT_UV } from '../../../../constants/graphql/query'
import { theme } from '../../../../styles/_variables'
import NotchedContainer from '../../../common/notched-containner'
import OptionsList from '../common/options-list'
import OptionDescription from '../common/option-description'
import SampleImageList from '../common/sample-image-list'
import SelectBox from '../common/select-box'
import Recommendation from '../common/recommendation'
import { CoverImage, DetailWrapper } from './styled'
import spotUVIconImage from '../../../../images/icon/Spot-uvIcon.png'


const MockIcon = () => <div style={{
  padding: '10px',
  background: 'gray',
}}></div>

const SpotUvSelector = ({
  formValues,
  setFormValues,
  activeTab,
  disabled,
}) => {
  const spotUVInitialValues = {
    spotUVIdFront: null,
    spotUVIdBehind: null,
  }
  const sideOptions = [
    { value: 0, title: 'ไม่เคลือบ' },
    { value: 1, title: 'เคลือบด้านหน้า' },
    { value: -1, title: 'เคลือบด้านหลัง' },
    { value: 2, title: 'เคลือบสองหน้า' },
  ]
  const [spotUVFormValues, setSpotUVFormValue] = useState(spotUVInitialValues)
  const [side, setSide] = useState(formValues.partData[activeTab].spotUV.side)
  const [selectedFrontSpotUV, setSelectedFrontSpotUV] = useState(null)
  const [selectedBackSpotUV, setSelectedBackSpotUV] = useState(null)
  const handleSelectSideOption = (value) => {
    if (value === 0) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].spotUV.spotUVType = 'ไม่เคลือบ'
      tmpFormValues[activeTab].spotUV.spotUVFrontId = null
      tmpFormValues[activeTab].spotUV.spotUVFrontName = ''
      tmpFormValues[activeTab].spotUV.spotUVBackId = null
      tmpFormValues[activeTab].spotUV.spotUVBackName = ''
      tmpFormValues[activeTab].spotUV.side = value
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
    if (value === 1) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].spotUV.spotUVType = 'เคลือบด้านหน้า'
      tmpFormValues[activeTab].spotUV.spotUVBackId = null
      tmpFormValues[activeTab].spotUV.spotUVFrontId = formValues.partData[activeTab].spotUV.spotUVFrontId
      tmpFormValues[activeTab].spotUV.spotUVFrontName = formValues.partData[activeTab].spotUV.spotUVFrontName
      tmpFormValues[activeTab].spotUV.side = value
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
    if (value === -1) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].spotUV.spotUVType = 'เคลือบด้านหลัง'
      tmpFormValues[activeTab].spotUV.spotUVFrontId = null
      tmpFormValues[activeTab].spotUV.spotUVBackId = formValues.partData[activeTab].spotUV.spotUVBackId
      tmpFormValues[activeTab].spotUV.spotUVBackName = formValues.partData[activeTab].spotUV.spotUVBackName
      tmpFormValues[activeTab].spotUV.side = value
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
    if (value === 2) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].spotUV.spotUVType = 'เคลือบสองหน้า'
      tmpFormValues[activeTab].spotUV.spotUVFrontId = formValues.partData[activeTab].spotUV.spotUVFrontId
      tmpFormValues[activeTab].spotUV.spotUVFrontName = formValues.partData[activeTab].spotUV.spotUVFrontName
      tmpFormValues[activeTab].spotUV.spotUVBackId = formValues.partData[activeTab].spotUV.spotUVBackId
      tmpFormValues[activeTab].spotUV.spotUVBackName = formValues.partData[activeTab].spotUV.spotUVBackName
      tmpFormValues[activeTab].spotUV.side = value
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
    setSide(value)
    setSpotUVFormValue(spotUVInitialValues)
  }
  const handleOnSelectFrontSpotUV = (value) => {
    if (value) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].spotUV.spotUVFrontId = value.spotUVId
      tmpFormValues[activeTab].spotUV.spotUVFrontName = value.name
      setFormValues({ ...formValues, partData: tmpFormValues })
      setSelectedFrontSpotUV(value)
      setSpotUVFormValue({ ...spotUVFormValues, spotUVIdFront: value.spotUVId })
    }
  }
  const handleOnSelectBackSpotUV = (value) => {
    if (value) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].spotUV.spotUVBackId = value.spotUVId
      tmpFormValues[activeTab].spotUV.spotUVBackName = value.name
      setFormValues({ ...formValues, partData: tmpFormValues })
      setSelectedBackSpotUV(value)
      setSpotUVFormValue({ ...spotUVFormValues, spotUVIdBehind: value.spotUVId })
    }
  }

  useEffect(() => {
    setFormValues({ ...formValues, spotUV: spotUVFormValues })
  }, [spotUVFormValues])

  useEffect(() => {
    handleSelectSideOption(formValues.partData[activeTab].spotUV.side)
  }, [activeTab])

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 375)
  const [isMobileXL, setIsMobileXL] = useState(window.innerWidth <= 570)

  const screenSizeHandler = () => setIsMobileView(window.innerWidth <= 375)
  const screenSizeHandler2 = () => setIsMobileXL(window.innerWidth <= 570)

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    window.addEventListener('resize', screenSizeHandler2)
    return () => {
      window.removeEventListener('resize', screenSizeHandler)
      window.removeEventListener('resize', screenSizeHandler2)
    }
  }, [])

  return <Div display='flex' flexDirection='column' moreStyle={{ rowGap: '5px' }}>
    <NotchedContainer
      title={isMobileXL ? 'การเคลือบยูวีเฉพาะจุด' : 'การเคลือบยูวีเฉพาะจุด (Spot UV)'}
      titleIcon={spotUVIconImage}
    >
      <SelectBox
        title='ลักษณะการเคลือบ'
        placeholder='เลือกลักษณะการเคลือบ'
        value={side}
        options={sideOptions}
        onChange={handleSelectSideOption}
        centerTitle
      />
    </NotchedContainer>
    {
      formValues.partData[activeTab].spotUV.side === 1 ? <FrontSpotUVSelector
        value={selectedFrontSpotUV}
        handleOnSelect={handleOnSelectFrontSpotUV}
        activeTab={activeTab}
        formValues={formValues}
        setFormValues={setFormValues}
        disabled={disabled}
        setSelectedFrontSpotUV={setSelectedFrontSpotUV}
      />
        : formValues.partData[activeTab].spotUV.side === -1 ? <BackSpotUVSelector
          value={selectedBackSpotUV}
          handleOnSelect={handleOnSelectBackSpotUV}
          activeTab={activeTab}
          formValues={formValues}
          setFormValues={setFormValues}
          disabled={disabled}
          setSelectedBackSpotUV={setSelectedBackSpotUV}
        />
          : formValues.partData[activeTab].spotUV.side === 2 ? <Div display='flex' flexDirection='column' moreStyle={{ rowGap: '5px' }}>
              <FrontSpotUVSelector
                value={selectedFrontSpotUV}
                handleOnSelect={handleOnSelectFrontSpotUV}
                activeTab={activeTab}
                formValues={formValues}
                setFormValues={setFormValues}
                disabled={disabled}
                setSelectedFrontSpotUV={setSelectedFrontSpotUV}
              />
              <BackSpotUVSelector
                value={selectedBackSpotUV}
                handleOnSelect={handleOnSelectBackSpotUV}
                activeTab={activeTab}
                formValues={formValues}
                setFormValues={setFormValues}
                disabled={disabled}
                setSelectedBackSpotUV={setSelectedBackSpotUV}
              />
            </Div>
            : ''
    }
  </Div>
}

const SelectorFront = ({
  title,
  secondTitle,
  value,
  handleOnSelect,
  filterOptions = [],
  textOverlay,
  activeTab,
  formValues,
  setFormValues,
  disabled,
  setSelectedFrontSpotUV,
}) => {
  const [spotUVOptions, setSpotUVOption] = useState([])
  const [remark, setRemark] = useState('')

  useEffect(() => {
    if (!formValues.partData[activeTab].spotUV.spotUVFrontId && spotUVOptions.length > 0) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].spotUV.spotUVFrontId = spotUVOptions[0].spotUVId
      tmpFormValues[activeTab].spotUV.spotUVFrontName = spotUVOptions[0].name
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
  }, [spotUVOptions, formValues.partData[activeTab].spotUV.spotUVFrontId])

  useEffect(() => {
    if (spotUVOptions) {
      const spotUVList = spotUVOptions
        .map((item, index) => {
          const coverImg = item.spotUVFiles.find(file => file.type === 'cover')
          const sampleImages = item.spotUVFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
          const sampleVideo = item.spotUVFiles.filter(file => file.type === 'video')
          return {
            ...item,
            label: item.name,
            value: item.spotUVId,
            icon: coverImg ? <CoverImage imgUrl={coverImg.spotUVFileUri} /> : <MockIcon />,
            textOverlay,
            sampleImages,
            sampleVideo,
            checked: formValues.partData[activeTab].spotUV.spotUVFrontId === item.spotUVId,
          }
        })
      setSpotUVOption(spotUVList)
      handleOnSelect(spotUVList.find(item => item.spotUVId === formValues.partData[activeTab].spotUV.spotUVFrontId))
    }
  }, [activeTab])
  return <NotchedContainer noTitle>
    <Row type='flex' gutter={16}>
      <Col xs={24} sm={spotUVOptions.length === 0 ? 24 : 11} md={spotUVOptions.length === 0 ? 24 : 11} span={16}>
        <Query
          query={LIST_SPOT_UV}
          notifyOnNetworkStatusChange
          fetchPolicy='network-only'
          variables={{
            limit: 10000,
            offset: 0,
          }}
          onCompleted={(data) => {
            if (data.listSpotUV.spotUV) {
              const spotUVList = data.listSpotUV.spotUV
                .map((item, index) => {
                  const coverImg = item.spotUVFiles.find(file => file.type === 'cover')
                  const sampleImages = item.spotUVFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
                  const sampleVideo = item.spotUVFiles.filter(file => file.type === 'video')
                  return {
                    ...item,
                    label: item.name,
                    value: item.spotUVId,
                    icon: coverImg ? <CoverImage imgUrl={coverImg.spotUVFileUri} /> : <MockIcon />,
                    textOverlay,
                    sampleImages,
                    sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].spotUVFileUri : null,
                    checked: !formValues.partData[activeTab].spotUV.spotUVFrontId ? index === 0 : formValues.partData[activeTab].spotUV.spotUVFrontId === item.spotUVId,
                  }
                })
              setSpotUVOption(spotUVList)
              if (!formValues.partData[activeTab].spotUV.spotUVFrontId) {
                if (spotUVList.length > 0) setSelectedFrontSpotUV(spotUVList[0])
              } else {
                handleOnSelect(spotUVList.find(item => item.spotUVId === formValues.partData[activeTab].spotUV.spotUVFrontId))
              }
              setRemark(data.listSpotUV.remark)
            }
          }}
        >
          {
            ({ loading, data, refetch }) => {
              if (loading) {
                return <Div moreStyle={{ width: '100%' }}>
                  <LoadingIcon color={theme.color.grey} />
                </Div>
              }
              return (
                <OptionsList
                  title={title}
                  options={spotUVOptions}
                  onSelect={handleOnSelect}
                  circle
                  gridColumn={3}
                  black
                  disabled={disabled}
                />
              )
            }
          }
        </Query>
      </Col>
      {
        spotUVOptions.length > 0 && <Col xs={24} sm={13} md={13} span={16}>
          <DetailWrapper>
            <OptionDescription
              title={value && value.name}
              secondTitle={secondTitle}
              description={value && value.description}
              videoSrc={value && value.sampleVideoUri}
            />
            {
              value && value.sampleImages.length > 0 && <SampleImageList
                title='รูปภาพตัวอย่าง'
                data={value.sampleImages.map(item => ({ fileUrl: item.spotUVFileUri, ordering: item.ordering }))}
              />
            }
            {
              remark && <Recommendation
                value={remark.description}
              />
            }
          </DetailWrapper>
        </Col>
      }
    </Row>
  </NotchedContainer>
}

const SelectorBack = ({
  title,
  secondTitle,
  value,
  handleOnSelect,
  filterOptions = [],
  textOverlay,
  activeTab,
  formValues,
  setFormValues,
  disabled,
  setSelectedBackSpotUV,
}) => {
  const [spotUVOptions, setSpotUVOption] = useState([])
  const [remark, setRemark] = useState('')

  useEffect(() => {
    if (!formValues.partData[activeTab].spotUV.spotUVBackId && spotUVOptions.length > 0) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].spotUV.spotUVBackId = spotUVOptions[0].spotUVId
      tmpFormValues[activeTab].spotUV.spotUVBackName = spotUVOptions[0].name
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
  }, [spotUVOptions, formValues.partData[activeTab].spotUV.spotUVBackId])

  useEffect(() => {
    if (spotUVOptions) {
      const spotUVList = spotUVOptions
        .map((item, index) => {
          const coverImg = item.spotUVFiles.find(file => file.type === 'cover')
          const sampleImages = item.spotUVFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
          const sampleVideo = item.spotUVFiles.filter(file => file.type === 'video')
          return {
            ...item,
            label: item.name,
            value: item.spotUVId,
            icon: coverImg ? <CoverImage imgUrl={coverImg.spotUVFileUri} /> : <MockIcon />,
            textOverlay,
            sampleImages,
            sampleVideo,
            checked: formValues.partData[activeTab].spotUV.spotUVBackId === item.spotUVId,
          }
        })
      setSpotUVOption(spotUVList)
      handleOnSelect(spotUVList.find(item => item.spotUVId === formValues.partData[activeTab].spotUV.spotUVBackId))
    }
  }, [activeTab])
  return <NotchedContainer noTitle>
    <Row type='flex' gutter={16}>
      <Col xs={24} sm={spotUVOptions.length === 0 ? 24 : 11} md={spotUVOptions.length === 0 ? 24 : 11} span={16}>
        <Query
          query={LIST_SPOT_UV}
          notifyOnNetworkStatusChange
          fetchPolicy='network-only'
          variables={{
            limit: 10000,
            offset: 0,
          }}
          onCompleted={(data) => {
            if (data.listSpotUV.spotUV) {
              const spotUVList = data.listSpotUV.spotUV
                .map((item, index) => {
                  const coverImg = item.spotUVFiles.find(file => file.type === 'cover')
                  const sampleImages = item.spotUVFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
                  const sampleVideo = item.spotUVFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'video')
                  return {
                    ...item,
                    label: item.name,
                    value: item.spotUVId,
                    icon: coverImg ? <CoverImage imgUrl={coverImg.spotUVFileUri} /> : <MockIcon />,
                    textOverlay,
                    sampleImages,
                    sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].spotUVFileUri : null,
                    checked: !formValues.partData[activeTab].spotUV.spotUVBackId ? index === 0 : formValues.partData[activeTab].spotUV.spotUVBackId === item.spotUVId,
                  }
                })
              setSpotUVOption(spotUVList)
              if (!formValues.partData[activeTab].spotUV.spotUVBackId) {
                if (spotUVList.length > 0) setSelectedBackSpotUV(spotUVList[0])
              } else {
                handleOnSelect(spotUVList.find(item => item.spotUVId === formValues.partData[activeTab].spotUV.spotUVBackId))
              }
              setRemark(data.listSpotUV.remark)
            }
          }}
        >
          {
            ({ loading, data, refetch }) => {
              if (loading) {
                return <Div moreStyle={{ width: '100%' }}>
                  <LoadingIcon color={theme.color.grey} />
                </Div>
              }
              return (
                <OptionsList
                  title={title}
                  options={spotUVOptions}
                  onSelect={handleOnSelect}
                  circle
                  gridColumn={3}
                  black
                  disabled={disabled}
                />
              )
            }
          }
        </Query>
      </Col>
      {
        spotUVOptions.length > 0 && <Col xs={24} sm={13} md={13} span={16}>
          <DetailWrapper>
            <OptionDescription
              title={value && value.name}
              secondTitle={secondTitle}
              description={value && value.description}
              videoSrc={value && value.sampleVideoUri}
            />
            {
              value && value.sampleImages.length > 0 && <SampleImageList
                title='รูปภาพตัวอย่าง'
                data={value.sampleImages.map(item => ({ fileUrl: item.spotUVFileUri, ordering: item.ordering }))}
              />
            }
            {
              remark && <Recommendation
                value={remark.description}
              />
            }
          </DetailWrapper>
        </Col>
      }
    </Row>
  </NotchedContainer>
}

const FrontSpotUVSelector = ({
  value,
  filterOptions,
  handleOnSelect,
  activeTab,
  formValues,
  setFormValues,
  disabled,
  setSelectedFrontSpotUV,
}) => (
  <SelectorFront
    title='เลือกวิธีการเคลือบด้านหน้า'
    secondTitle='การเคลือบด้านหน้า'
    value={value}
    filterOptions={filterOptions}
    handleOnSelect={handleOnSelect}
    activeTab={activeTab}
    formValues={formValues}
    setFormValues={setFormValues}
    disabled={disabled}
    setSelectedFrontSpotUV={setSelectedFrontSpotUV}
  />
)

const BackSpotUVSelector = ({
  value,
  filterOptions,
  handleOnSelect,
  activeTab,
  formValues,
  setFormValues,
  disabled,
  setSelectedBackSpotUV,
}) => (
  <SelectorBack
    title='เลือกวิธีการเคลือบด้านหลัง'
    secondTitle='การเคลือบด้านหลัง'
    value={value}
    filterOptions={filterOptions}
    handleOnSelect={handleOnSelect}
    activeTab={activeTab}
    formValues={formValues}
    setFormValues={setFormValues}
    disabled={disabled}
    setSelectedBackSpotUV={setSelectedBackSpotUV}
  />
)


export default SpotUvSelector
