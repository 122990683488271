import styled, { css } from 'styled-components'
import { Input as InputAnt, Select as SelectAnt } from 'antd'
import {
  Button,
  Div,
  H4,
  ButtonForm,
} from 'konsys-ui'
import { theme, media, minMedia } from '../../../styles/_variables'

export const ButtonToolbar = styled(Button)`
  width: ${props => props.width || 200};
  padding: 3px 0px;
  font-size: 24px;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  span {
    font-size: 24px;
  }
  box-shadow: 2px 2px 0px 1px rgb(110, 201, 190, 0.4) !important;
  i {
    font-size: ${theme.fonts.size.small} !important;
    font-weight: ${theme.fonts.style.regular} !important;
  }
  ${media.laptopL`
    width: 150px;
    padding: 0px 0px !important;
  `}
  ${media.mobileL`
    width: 100%;
  `}
`

export const ButtonSearch = styled(Button)`
  border-radius: 20px;
  padding: 0px 8px 0px 8px;
  margin: 6px 0px 6px 6px;
  width: auto;
  height: initial;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: center;
  i {
    font-size: ${theme.fonts.size.label} !important;
  }
  span {
    font-size: ${theme.fonts.size.small} !important;
    font-weight: ${theme.fonts.style.semiBold}
  }
  ${media.mobileL`
    width: fit-content;
    i {
      font-size: 16px !important;
    }
  `}
`

export const InputSearch = styled(InputAnt)`
  font-size: ${theme.fonts.size.normal};
  font-wight: ${theme.fonts.style.medium};
  border: 0px;
  display: flex;
  justify-content: center;
  box-shadow: unset !important;
`

export const SelectSort = styled(SelectAnt)`
  width: 100%;
  font-size: 18px;
  div.ant-select-selection {
    background-color: rgb(${theme.color.primaryColorRGB}, 0.15);
    border: 1px solid rgb(${theme.color.primaryColorRGB}, 0);
    border-radius: 8px;
  }
  div.ant-select-selection-selected-value {
    width: 100%;
    text-align: center;
  }
  span.ant-select-arrow {
    top: 40% !important;
  }
  i {
    color: ${theme.color.secondaryColor};
  }
`

export const LabelOptionSort = styled.div`
  text-align: center !important;
`


export const ImageLabel = styled.h4`
  font-weight: 500;
  font-size: 24px;
  padding: 4px 16px;
  color: ${props => (props.errorStatus ? theme.color.error : theme.color.primaryColor)};
  background-color: ${theme.color.offWhite};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${media.laptop`
    font-size: 20px;
  `}
  ${media.tablet`
    font-size: 18px;
  `}
  ${media.tabletS`
    font-size: 16px;
  `}
`

export const ImageLabelOther = styled.h4`
  font-weight: 500;
  font-size: 28px;
  color: ${theme.color.offWhite};
  background-color: ${theme.color.primaryColor};
  text-align: center;
  ${media.laptop`
    font-size: 20px;
  `}
  ${media.tablet`
    font-size: 18px;
  `}
  ${media.tabletS`
    font-size: 16px;
  `}
`

export const DivToolbar = styled.div`
  display: flex;
  width: 100%;
  ${media.laptop`
    display: block;
  `}
`

export const DivToolbarAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  ${media.laptopL`
    width: 50%;
  `}
  ${media.laptop`
    padding-top: 24px;
    width: 100%;
    justify-content: normal;
  `}
  ${media.mobileL`
    padding-top: 0px;
    width: 100%;
    display: block;
    button {
      margin: 16px 0 0 0;
      width: 100% !important;
    }
  `}
`

export const DivPackageList = styled.div`
  display: flex;
  margin: 24px 0px;
  flex-wrap: wrap;
  ${media.mobileL`
    justify-content: center;
  `}
`

export const DivAction = styled.div`
  display: flex;
  justify-content: center;
`

export const DivSearch = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  padding-right: 26px;
  background-color: ${theme.color.offWhite};
  border-radius: 25px;
  box-shadow: 2px 2px 0px 1px rgb(56, 53, 48, 0.06);
  align-items: center;
`


export const ImageWithButtonWrapper = styled.div`
  width: calc((100% - 72px) / 3);
  margin: 12px;
  text-align: center;
`

export const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  cursor: pointer;
  position: relative;
  ${props => props.img && css`
    background-image: url(${props.img});
    background-size: cover;
    background-position: center;
  `}
`

export const Label = styled.h4`
  font-weight: 700;
  font-size: 24px;
  margin: 8px 0;
  color: ${props => props.color || theme.color.primaryColor}
`

export const TagDivActive = styled(Button)`
  border-radius: 1.4rem;
  padding: 0px 12px;
  width: fit-content !important;
  max-width: 300px;
  text-align: left;
  margin: 4px;
  i {
    font-size: ${theme.fonts.size.small} !important;
    font-weight: ${theme.fonts.style.medium};
  }
  span {
    word-break: break-all;
    font-size: ${theme.fonts.size.normal};
    font-weight: ${theme.fonts.style.bold};
  }
  &:hover {
    background-color: ${theme.color.paleBlack} !important;
    border-color: ${theme.color.paleBlack} !important;
  }
  ${media.tablet`
    &:hover {
      background-color: ${theme.color.primaryColor} !important;
      border-color: ${theme.color.primaryColor} !important;
    }
  `}
`

export const DivPackage = styled.div`
  width: calc((100%) / 4);
  padding: 16px;
  ${minMedia.laptopL`
    width: calc((100%) / 5);
  `}
  ${minMedia.desktop`
    width: calc((100%) / 8);
  `}
  ${media.tablet`
    margin: 4px;
    width: calc((100% - 24px) / 3);
  `}
  ${media.tabletS`
    margin: 2px;
    width: calc((100% - 12px) / 2);
  `}
`

export const StyledItem = styled.div`
float: left;
padding: 12px;
width: calc(25%);
${minMedia.laptopL`
  width: calc(20%);
`}
${minMedia.desktop`
  width: calc(12.5%);
`}
${media.laptop`
  width: calc(25%);
`}
${media.tablet`
  width: calc(100% / 3);
`}
${media.tabletS`
  width: calc(100% / 3);
`}
.content {
  filter: drop-shadow(0 2px 3px rgb(0 0 0 / 0.01)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.02));
}
& {
    > div {
      -webkit-transition-duration: 400ms;
      transition-duration: 400ms;
      transition-delay: 50ms;
    }
  }
  &:hover {
    > .content {
      transform: scale(1.02);
      transition-property: all;
      transition-duration: 400ms;
      transition-delay: 50ms;
      transition-timing-function: ease;
      filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
    }
  }
`

export const StyledContainer = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`

export const StyledHandle = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  overflow: hidden;

  > svg {
    fill: ${theme.color.primaryColor};
    opacity: 0.5;
  }

  cursor: grab;
`
export const ButtonDiv = styled(Button)`
  font-size: ${theme.fonts.size.normal};
  font-weight: ${theme.fonts.style.medium};
  padding: 8px 24px;
  ${media.laptopL`
    font-size: 12px;
    padding: 8px 24px;
  `}
  ${media.laptop`
    font-size: 12px;
    padding: 6px 18px;
  `}
  ${media.tablet`
    font-size: 10px;
    padding: 6px 16px;
    margin-top: 84px;
  `}
  ${media.mobileL`
    font-size: 10px;
  `}
`

export const ActionZone = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
`

export const ActionZone2 = styled.div`
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0px 40px 0px;
    ${media.laptop`
      justify-content: space-between;
      width: 100%;
    `}
    ${media.tablet`
      justify-content: space-between;
      width: 100%;
    `}
`

export const ButtonCalculator = styled(Button)`
  width: 300px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: ${theme.fonts.size.title};
  border-radius: 25px;
  padding: 2px 8px !important;
  background-color: ${theme.color.greenPrimary};
  box-shadow: 2px 2px 0px 1px rgb(110, 201, 190, 0.4) !important;
  & > p {
    margin: 0px !important;
    font-size: ${theme.fonts.size.title};
  }
`

export const IconCalculate = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`

export const WidthContainer = styled.div`
  width: 100%;
  margin-left: 50%;
  max-width: 64vw;
  transform: translateX(-50%);
  margin-top: -60px;
  margin-bottom: -60px;
  ${props => props.bgColor && css`
    background-color: ${props.bgColor};
  `}
  ${minMedia.laptopL`
    max-width: 64vw;
  `}
  ${media.desktop`
    max-width: 100%;
    padding: 0px 60px;
  `}
  ${media.tablet`
    width: 100%;
    max-width: 100%;
  `}
`

export const FullWidthContainer = styled.div`
  // width: fit-content;
  margin: 0 auto;
  margin-left: -60px;
  margin-right: -60px;
`

export const Content = styled.div`
  width: 100%;
  border-top: 60px solid ${theme.color.greenPrimary};
  border-bottom: ${props => (props.step === 2 || props.data === 1 ? '0px' : `60px solid ${theme.color.greenPrimary}`)};
`

export const SummaryDiv = styled.div`
  width: 100%;
  margin-left: 12px;
  position: relative;
`

export const ContentPart = styled.div`
  display: inline-flex;
`

export const QuotationCreateDiv = styled.div`
  width: 100%;
  max-width: 64vw;
  ${media.desktop`
    max-width: 100%;
    padding: 0px 60px;
  `}
  margin: 0 auto;
`

export const QuotationCreateDivDetail = styled.div`
  width: 100%;
  margin-bottom: 60px;
`

export const SummaryDivMobile = styled.div`
  width: 100%;
  padding: 8px 24px;
  position: relative;
  margin-top: 30px;
  margin-bottom: 40px;
`

export const Bar = styled(Div)`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled(H4)`
  color: ${theme.color.offWhite};
  ${media.mobileL`
    margin-left: -60px;
    font-size: ${theme.fonts.size.extraNormal};
  `}
  ${media.mobileM`
    margin-left: -60px;
    font-size: ${theme.fonts.size.normal};
  `}
`

export const ButtonBack = styled(Button)`
  padding: 8px 0px;
  background-color: ${theme.color.greenPrimary};
  border-color: ${theme.color.greenPrimary};
  ${media.mobileL`
    margin-left: 0px;
  `}
  > p {
    ${media.mobileM`
      font-size: ${theme.fonts.size.label};
    `}
  }
`

export const ButtonFormAction = styled(ButtonForm)`
  margin: 0px;
  padding: 8px 24px;
  white-space: nowrap;
  align-items: center;
  display: flex;
  & > span {
    font-size: ${theme.fonts.size.subTitle} !important;
    ${media.mobileL`
      font-size: ${theme.fonts.size.small} !important;
    `}
  }
  & > i {
    font-size: ${theme.fonts.size.subTitle} !important;
    width: 30px !important;
    height: 30px !important;
    ${media.mobileL`
      width: 12px !important;
      height: 12px !important;
      font-size: 12px !important;
      margin: 0px 4px 0px 0px !important;
    `}
  }
  ${media.mobileL`
    margin-left: 0px;
    padding: 8px 12px;
  `}
  
`

export default ImageWrapper
