import React, { useState } from 'react'
import { Query, Mutation } from 'react-apollo'
import {
  Divider, message, Menu, Dropdown,
} from 'antd'
import { Collapse } from 'reactstrap'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import ReactTable from 'react-table'
import {
  Div, H3, Button, Loading, P, Input, H4,
} from 'konsys-ui'
import config from './config'
import { theme } from '../../../styles/_variables'
import { GET_QUOTATIONS, GET_TAX_RATE } from '../../../constants/graphql/query'
import { UPDATE_TAX_RATE } from '../../../constants/graphql/mutation'
import PATH from '../../../constants/path'
import './style-css.css'
import { Wrapper } from '../../../components/quotation-components'

const { color } = theme

const TaxSetting = ({ closeTax }) => {
  const [taxRate, settaxRate] = useState(0)
  const [withHoldingTaxRate, setwithHoldingTaxRate] = useState(0)
  return (
  <Query
    query={GET_TAX_RATE}
    fetchPolicy={'network-only'}
    onCompleted={(data) => {
      if (data.taxRate) {
        settaxRate(data.taxRate.taxRate)
        setwithHoldingTaxRate(data.taxRate.withHoldingTaxRate)
      }
    }}
    notifyOnNetworkStatusChange
  >{({ data, loading, error }) => {
    if (loading) return <Loading />
    if (error) return <H4>error</H4>
    return (
      <Div display='flex' flexDirection='column' alignItems='flex-start'>
        <H4 margin='0 0 24px 0' bold color={color.primaryColor}>Tax Rate</H4>
        <Div display='flex' alignItems='center' margin='0 0 32px 0'>
          <P bold margin='0 117px 0 0'>อัตราภาษีมูลค่าเพิ่ม (VAT)</P>
          <Input margin='0 8px 0 0' defaultValue={data.taxRate.taxRate} onChange={e => settaxRate(e.target.value)}/>
          <P bold>%</P>
        </Div>
        <Div display='flex' alignItems='center' margin='0 0 32px 0'>
          <P bold margin='0 40px 0 0'>อัตราภาษี ณ ที่จ่าย (WithHolding Tax)</P>
          <Input margin='0 8px 0 0' defaultValue={data.taxRate.withHoldingTaxRate} onChange={e => setwithHoldingTaxRate(e.target.value)}/>
          <P bold>%</P>
        </Div>
        <Mutation
          mutation={UPDATE_TAX_RATE}
        >{doUpdate => (
          <Button
            style={{ alignSelf: 'center' }}
            text='อัพเดต Tax'
            icon='fal fa-cog'
            small
            ghost
            onClick={() => {
              doUpdate({
                variables: {
                  taxRate: parseFloat(taxRate),
                  withHoldingTaxRate: parseFloat(withHoldingTaxRate),
                },
              }).then((resp) => {
                if (resp.data.editTaxRate.success) {
                  message.success(resp.data.editTaxRate.message)
                  closeTax()
                } else message.error(resp.data.editTaxRate.message)
              })
            }}
          />
        )}
        </Mutation>
        <Divider />
      </Div>
    )
  }}
  </Query>
  )
}

const newOrderMenu = (
  <Menu>
    <Menu.Item>
      <Link to={PATH.quotationCreate}>Freeform Packaging</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={PATH.quotationPreDefinedCreate}>Pre-Defined Packaging</Link>
    </Menu.Item>
  </Menu>
)

const Index = () => {
  const [collapse, setcollapse] = useState(false)
  return (
  <Div display='flex' flexDirection='column'>
    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
      <H3 bold color={color.primaryColor} margin='32px 0'>รายละเอียด Orders</H3>
      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
        <Button text='Settings' icon='fal fa-cog' ghost margin='0 16px 0 0' onClick={() => setcollapse(!collapse)}/>
        <Dropdown overlay={newOrderMenu}>
          <Button text='New Order' icon='fal fa-plus' inverse/>
        </Dropdown>
      </Div>
    </Div>
    <Collapse isOpen={collapse}>
      <TaxSetting closeTax={() => setcollapse(false)}/>
    </Collapse>
    <List />
  </Div>
  )
}


export const List = () => {
  const [current, setCurrent] = useState(0)
  const [sorted, setSorted] = useState([{ id: 'id', desc: false }])
  const [filtered, setFiltered] = useState({ status: 'all' })
  const limit = 20

  return (
    <Wrapper overflow padding='0 0 40px 0'>
      <Query
        query={GET_QUOTATIONS}
        fetchPolicy='cache-and-network'
        variables={{
          limit,
          offset: current * limit,
          // orderBy: sorted[0].desc ? 'DESC' : 'ASC',
          // orderField: sorted[0].id,
          status: 'all',
          ...filtered,
        }}
      >{({ data, loading, error }) => {
        if (loading && _.isEmpty(data)) return <Loading fullWidth center />
        if (error) return <P>error</P>

        return (
          <Div>
            <ReactTable
              className='table'
              data={data.requestList.list} // *
              resizable={false}
              columns={config.columns} // *
              minRows={5}
              manual
              // defaultPageSize={10}
              showPageSizeOptions={false}
              getTdProps={() => ({ disabled: true })} // *
              getTrProps={(state, rowInfo) => { // *
                if (rowInfo) {
                  return {
                    style: {
                      padding: '8px 0',
                      backgroundColor: '#ffffff',
                      borderLeft: `3px solid ${color.primaryColor}`,
                    },
                  }
                } return {}
              }}
              page={current}
              pages={Math.ceil(data.requestList.filterCount / limit)}
              onPageChange={page => setCurrent(page)}
              onFilteredChange={async (newFiltered) => {
                const newOjb = {}
                await newFiltered.forEach((v) => {
                  Object.assign(newOjb, { [v.id === 'address.name' ? 'fullName' : v.id]: v.value })
                })
                setFiltered(newOjb)
                setCurrent(0)
              }}
              onSortedChange={async (newSorted) => {
                setSorted(newSorted)
                setCurrent(0)
              }}
            />
            {
              loading && <Div margin='32px 0 0'><Loading fullWidth center /></Div>
            }
          </Div>
        )
      }}
      </Query>
    </Wrapper>
  )
}

export default Index
