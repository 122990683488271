import React, { useState, useEffect } from 'react'
import {
  Div, Input,
} from 'konsys-ui'
import {
  DivColumn, Divider, DivSpacing, LabelColumn,
  DivHeadColumn,
  DivQuantity, DivCenter, ColumnDataQuantity,
  RowCellQuantity, LabelQuantity, ColumnDataManufacture,
  RowCellManufacture, LabelUnit, LabelManufactureNew, LabelManufactureOld,
  ImgIcon, DivImage,
} from './styled'
import NewOrder from '../../../../../images/icon/New-order Icon.png'
import RepeatOrder from '../../../../../images/icon/Repeat Icon.png'


const PriceCalculateTable = ({
  data = [],
  orderType,
}) => {
  const [rowsQuantity, setRowsQuantity] = useState([])
  const [rowsNewOrder, setRowsNewOrder] = useState([])
  const [rowsRepeatOrder, setRowsRepeatOrder] = useState([])

  useEffect(() => {
    setRowsQuantity(data.map(item => item.quantity))
    setRowsNewOrder(data.map(item => item.totalCost))
    setRowsRepeatOrder(data.map(item => item.totalRepeatCost))
  }, [data])

  const [isMobileS, setIsMobileS] = useState(window.innerWidth <= 320)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 375)
  const [isMobileL, setIsMobileL] = useState(window.innerWidth <= 425)
  const [isMobileXL, setIsMobileXL] = useState(window.innerWidth <= 570)
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 768)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 2048)
  const [isDesktopL, setIsDesktopL] = useState(window.innerWidth >= 2348)
  const [isTabletM, setIsTabletM] = useState(window.innerWidth > 570 && window.innerWidth <= 768)
  const [isMobileML, setIsMobileML] = useState(window.innerWidth < 425)
  const [is425, setIs425] = useState(window.innerWidth === 425)

  const screenSizeHandler = () => setIsMobileView(window.innerWidth <= 375)
  const screenSizeHandler1 = () => setIsMobileS(window.innerWidth <= 320)
  const screenSizeHandler2 = () => setIsMobileXL(window.innerWidth <= 570)
  const screenSizeHandler5 = () => setIsMobileL(window.innerWidth <= 425)
  const screenSizeHandler3 = () => setIsDesktop(window.innerWidth >= 2048)
  const screenSizeHandler4 = () => setIsDesktopL(window.innerWidth >= 2348)
  const screenSizeHandler6 = () => setIsTablet(window.innerWidth <= 768)
  const screenSizeHandler7 = () => setIsTabletM(window.innerWidth > 570 && window.innerWidth <= 768)
  const screenSizeHandler8 = () => setIsMobileML(window.innerWidth < 425)
  const screenSizeHandler9 = () => setIs425(window.innerWidth === 425)

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    window.addEventListener('resize', screenSizeHandler1)
    window.addEventListener('resize', screenSizeHandler2)
    window.addEventListener('resize', screenSizeHandler3)
    window.addEventListener('resize', screenSizeHandler4)
    window.addEventListener('resize', screenSizeHandler5)
    window.addEventListener('resize', screenSizeHandler6)
    window.addEventListener('resize', screenSizeHandler7)
    window.addEventListener('resize', screenSizeHandler8)
    window.addEventListener('resize', screenSizeHandler9)
    return () => {
      window.removeEventListener('resize', screenSizeHandler)
      window.removeEventListener('resize', screenSizeHandler1)
      window.removeEventListener('resize', screenSizeHandler2)
      window.removeEventListener('resize', screenSizeHandler3)
      window.removeEventListener('resize', screenSizeHandler4)
      window.removeEventListener('resize', screenSizeHandler5)
      window.removeEventListener('resize', screenSizeHandler6)
      window.removeEventListener('resize', screenSizeHandler7)
      window.removeEventListener('resize', screenSizeHandler8)
      window.removeEventListener('resize', screenSizeHandler9)
    }
  }, [])

  return (
    <Div
      style={{
        width: isMobileXL ? '100%' : '100%',
        display: 'flex',
      }}
    >
      <Div style={{ padding: '12px 0px', width: isMobileXL ? '70px' : '140px', flexBasis: '20%' }}>
        <DivHeadColumn padding="16px 0px 4px 0px">
          <Div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Div style={{ width: isMobileML ? '24px' : '52px', height: is425 ? '50px' : isMobileML ? '48px' : '52px' }}/>
          </Div>
          <DivQuantity>{ isMobileXL ? 'จำนวน' : 'จำนวนผลิต' }</DivQuantity>
        </DivHeadColumn>
        <Div style={{ height: is425 ? '1px' : isMobileS ? '9px' : isMobileView ? '2px' : isMobileML ? '2.5px' : isMobileXL ? '4px' : isTabletM ? '4.5px' : '16px' }}/>
        <ColumnDataQuantity>
          {rowsQuantity.map((item, index) => (
            <RowCellQuantity key={index}><LabelQuantity>{item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} { !isMobileXL && 'ชิ้น' }</LabelQuantity></RowCellQuantity>
          ))}
        </ColumnDataQuantity>
      </Div>
      <Div
        style={{
          display: 'flex',
          flexBasis: '80%',
        }}>
        <DivColumn>
          <Div>
            <DivHeadColumn>
              <Div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <DivImage>
                  <ImgIcon src={NewOrder}/>
                </DivImage>
              </Div>
              <LabelColumn>สั่งผลิตใหม่</LabelColumn>
            </DivHeadColumn>
            <DivCenter>
              <Divider/>
            </DivCenter>
            <ColumnDataManufacture>
              {rowsNewOrder.map((item, index) => (
                <RowCellManufacture className="row-cell-manufacture" key={index}>
                  <LabelManufactureNew active={orderType === 'newOrder'}>{(Math.round(item * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</LabelManufactureNew>
                  <LabelUnit>บาท/ชิ้น</LabelUnit>
                </RowCellManufacture>
              ))}
            </ColumnDataManufacture>
          </Div>
        </DivColumn>
        <DivSpacing/>
        <DivColumn>
          <Div>
            <DivHeadColumn>
              <Div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <DivImage>
                  <ImgIcon src={RepeatOrder}/>
                </DivImage>
              </Div>
              <LabelColumn>สั่งผลิตซ้ำ</LabelColumn>
            </DivHeadColumn>
            <DivCenter>
              <Divider/>
            </DivCenter>
            <ColumnDataManufacture>
              {rowsRepeatOrder.map((item, index) => (
                  <RowCellManufacture className="row-cell-manufacture" key={index}>
                    <LabelManufactureOld active={orderType === 'repeatOrder'}>{(Math.round(item * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</LabelManufactureOld>
                    <LabelUnit>บาท/ชิ้น</LabelUnit>
                  </RowCellManufacture>
              ))}
            </ColumnDataManufacture>
          </Div>
        </DivColumn>
      </Div>
  </Div>
  )
}

export default PriceCalculateTable
