import React from 'react'
import {
  Div, Input,
} from 'konsys-ui'
import {
  Label,
} from './styled'
import { theme } from '../../../../../styles/_variables'


const TitleInput = ({
  label,
  data,
  onInputChange,
  setDisabledButton,
  setIsValidate,
  isValidate,
}) => (
  <Div>
    {label && <Label display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>{label}</Label>}
    <Div>
      <Input
        width='100%'
        value={data.name}
        onChange={(e) => {
          onInputChange({ ...data, name: e.target.value })
          if (e.target.value !== '') {
            setDisabledButton(false)
            setIsValidate(true)
          }
        }}
        onBlur={() => {
          if (data.name === '') {
            setIsValidate(false)
            setDisabledButton(true)
          }
        }}
        state={!isValidate && 'error'}
      />
    </Div>
  </Div>
)

export default TitleInput
