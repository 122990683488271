import React from 'react'
import {
  Div,
} from 'konsys-ui'
import {
  Switch,
} from 'antd'
import {
  Label,
} from './styled'
import { theme } from '../../../../styles/_variables'
import FreePriceTagsIcon from '../../../../images/icon/FreePriceTagsIcon.svg'


const SwitchFreeOption = ({
  label,
  data,
  onInputChange,
}) => (
  <Div display={'flex'} flexDirection='row' alignItems='center' moreStyle={{ marginTop: '12px' }}>
    <Label display='block' margin='0 16px' bold color={theme.color.primaryColor}>แสดงสัญลักษณ์ <img src={FreePriceTagsIcon} height='30px'/></Label>
    <Switch
      onChange={(e) => {
        onInputChange({ ...data, isFree: e })
      }}
      defaultChecked={data.isFree}
    />
    <Div>
    </Div>
  </Div>
)

export default SwitchFreeOption
