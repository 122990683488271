import styled, { css } from 'styled-components'
import { Div, Button } from 'konsys-ui'
import { Modal as AntModal } from 'antd'
import { theme, media } from '../../../../../styles/_variables'


export const DescriptionContainer = styled.div`
width: 100%;
height: 100%;
flex: 1 1 auto;
background: ${theme.color.offWhite};
border-radius: 5px;
padding: 15px;
text-align: left;
border: 1px solid ${theme.color.offGray};
height: 100%;
> div{
    ${media.tablet`
      margin-bottom: 0px;
    `}
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
    line-height: normal;
    color: ${theme.color.textColor};
}
`

export const ButtonWrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-top: 12px;
`

export const TitleWrapper = styled.div`
display: flex;
align-content: center;
align-items: center;
justify-content: space-between;
> p {
    margin-bottom: 0px;
    font-size: ${theme.fonts.size.subTitle};
}
`
export const SpanButton = styled.span`
  font-weight: ${theme.fonts.style.medium};
  font-size: ${theme.fonts.size.extraNormal};
`

export const TitleSpan = styled.div`
  font-size: ${theme.fonts.size.label} !important;
  height: fit-content;
  padding: 4px 12px;
  border-radius: 15px;
  background: ${theme.color.textColor};
  color: ${theme.color.offWhite};
  width: fit-content;
  margin-top: 8px !important;
`

export const Title = styled.p`
font-size: ${theme.fonts.size.subTitle};
color: ${theme.color.greenPrimary}
margin-bottom: 0px !important;
`

export const DescriptionContent = styled(Div)`
  font-size: ${theme.fonts.size.small};
  margin-top: 16px;
  & > p {
    margin: 0px !important;
    font-family: 'printAble4u', sans-serif;
    font-size: ${theme.fonts.size.small};
    color: ${theme.color.greyNormal}
  }
`

export const Modal = styled(AntModal)`
top: 27%;
.ant-modal-title {
    font-size: ${theme.fonts.size.subTitle}
    color: ${theme.color.primaryColor}
    margin: 10px 0px 20px 0px;
}
`

export const VideoButton = styled(Button)`
  padding: 4px 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: ${theme.color.greenPrimary};
  i {
    font-size: ${theme.fonts.size.normal} !important;
    font-weight: ${theme.fonts.style.medium};
  }
  span {
    line-height: ${theme.fonts.size.normal} !important;
    font-size: ${theme.fonts.size.small};
    font-weight: ${theme.fonts.style.medium};
  }
`

export default DescriptionContainer
