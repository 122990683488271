import React, { useState } from 'react'
import {
  WidthContainer, FullWidthContainer,
} from './styled'
import GluingTitleInput from '../title'
import GluingDescriptionInput from '../description'
import GluingCostTable from '../cost-table'
import GluingCostMinimumInput from '../cost-minimum'

const GluingForm = ({
  formValues,
  setFormValues,
  isValidate,
  setIsValidate,
  action,
}) => {
  const [, setDisabledButton] = useState(true)
  return (
    <>
      <WidthContainer>
        <GluingTitleInput
          label={'ชื่อวิธีการการติดกาว'}
          data={formValues}
          onInputChange={setFormValues}
          setDisabledButton={setDisabledButton}
          setIsValidate={setIsValidate}
          isValidate={isValidate}
        />
        <GluingDescriptionInput
          data={formValues}
          onInputChange={setFormValues}
        />
      </WidthContainer>
      <FullWidthContainer>
        <GluingCostTable
          data={formValues}
          onChange={setFormValues}
          formValues={formValues}
        />
        <GluingCostMinimumInput
          data={formValues}
          onInputChange={setFormValues}
          action={action}
        />
      </FullWidthContainer>
    </>
  )
}

export default GluingForm
