import React from 'react'
import { Link } from 'react-router-dom'
import { Popconfirm, message } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'

import PATH from '../../../constants/path'
import { LIST_FOILING } from '../../../constants/graphql/query'
import { DELETE_FOILING } from '../../../constants/graphql/mutation'
import RecommendationSetting from '../recommendation-setting'
import {
  ButtonAction,
  SimpleTable, ActionGroupWrapper,
  Column,
} from './styled'
import { theme } from '../../../styles/_variables'
import BreadCrumb from '../../../components/common/breadcrumb'


const { color } = theme

const columns = [{
  title: 'ตัวเลือกการปั๊มฟอยล์-ทองเค',
  dataIndex: 'name',
  render: text => <Column>{text}</Column>,
  align: 'center',
},
{
  title: 'คำสั่ง',
  dataIndex: 'action',
  align: 'center',
  width: '120px',
},
]

const FoilingSetting = props => (
  <Div>
    <BreadCrumb link={{
      parent: [
        {
          text: 'การตั้งค่าบรรจุภัณฑ์',
          link: PATH.packageSetting,
        },
      ],
      current: {
        text: 'การตั้งค่าการปั๊มฟอยล์-ทองเค',
      },
    }} />
    <Div display='flex' flexDirection='row' justifyContent='space-between' margin='32px 0px 0px 0px' alignItems='center'>
      <Div display='flex' flexDirection='column'>
        <H4 bold color={color.primaryColor}>
          <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
            <H4 bold color={color.primaryColor}>Catalog Settings</H4>
          </Link> {' > '} การปั๊มฟอยล์-ทองเค
        </H4>
        </Div>
        <Link to='/package-setting/foiling-create' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
          <Button
            htmlType="submit"
            text='New'
            icon='fal fa-plus'
            inverse
          />
        </Link>
    </Div>
    <TableListFoiling history={props.history} />
  </Div>
)

const TableListFoiling = ({
  history,
}) => (
  <Div>
    <Query
      query={LIST_FOILING}
      fetchPolicy='cache-and-network'
      variables={{
        limit: 10000,
        offset: 0,
      }}
    >
      {
        ({ loading, data, refetch }) => {
          let foilingList = []
          let foilingRemark = null
          if (!loading) {
            foilingList = data.listFoiling.foiling.map(item => ({
              name: item.name,
              action: <ActionGroup rowData={item} onEdit={() => history.push(`${PATH.foilingDetail}/${item.foilingId}`)}/>,
            }))
            foilingRemark = data.listFoiling.remark
          }
          return (
            <>
              <RecommendationSetting
                moduleName='foiling'
                data={foilingRemark}
                refetch={() => refetch()}
              />
              <SimpleTable
                loading={loading}
                columns={columns}
                dataSource={foilingList}
                pagination={false}
              />
            </>
          )
        }
      }
    </Query>
  </Div>
)

const ActionGroup = ({ rowData, onEdit }) => (
  <ActionGroupWrapper>
    <ButtonAction small inverse text='แก้ไข' icon='fa fa-pencil-square-o' onClick={onEdit} />
    <Mutation mutation={DELETE_FOILING}>
      {
        doDeleteCoating => (
          <Popconfirm
            title='ยืนยันการลบการปั๊มฟอยล์-ทองเค หรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'
            onConfirm={() => {
              doDeleteCoating({
                variables: {
                  id: parseInt(rowData.foilingId, 10),
                },
              }).then((res) => {
                if (res.data.deleteFoiling) {
                  if (res.data.deleteFoiling.success) {
                    message.success(res.data.deleteFoiling.message)
                    setTimeout(() => {
                      window.location = PATH.foilingSetting
                    }, 100)
                  } else {
                    message.error(res.data.deleteFoiling.message)
                  }
                }
              })
            }}
            okText='ยืนยัน'
            cancelText='ยกเลิก'
          >
            <ButtonAction small text='ลบ' icon='fa fa-trash-o fa-lg' color={theme.color.error} />
          </Popconfirm>
        )
      }
    </Mutation>
  </ActionGroupWrapper>
)

export default FoilingSetting
