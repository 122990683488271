import styled, { css } from 'styled-components'
import {
  Div,
} from 'konsys-ui'
import { Col } from 'antd'
import { theme, media } from '../../../../styles/_variables'

export const StyledItem = styled.div`
float: left;
width: 100%;
height: 100%;
display: flex;
flex-flow: column;
.content {
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}
& {
    > div {
      -webkit-transition-duration: 400ms;
      transition-duration: 400ms;
      transition-delay: 50ms;
    }
  }
  &:hover {
    > div {
      transform: scale(1.02);
      transition-property: all;
      transition-duration: 400ms;
      transition-delay: 50ms;
      transition-timing-function: ease;
      filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
    }
  }
`

export const Column = styled(Col)`
  flexBasis: 33%;
  width: 33%;
  padding: 8px;
  display: flex;
  ${media.mobileL`
    flexBasis: 50%;
    width: 50%;
  `}
`

export const StyledContainer = styled.div`
  margin: 0px;
`

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`

export const StyledHandle = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  overflow: hidden;

  > svg {
    fill: ${theme.color.primaryColor};
    opacity: 0.5;
  }

  cursor: grab;
`

export const ImageLabel = styled.h4`
  display: flex;
  height: fit-content;
  font-weight: ${theme.fonts.style.semiBold};
  font-size: ${theme.fonts.size.extraNormal};
  margin-bottom: 0;
  padding-left: ${props => (props.isFree ? '8px' : '16px')};
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: ${theme.color.secondaryColor};
  background-color: ${theme.color.offWhite};
  word-break: break-all;
`

export const ImageDescription = styled.div`
  font-weight: 500;
  font-size: ${theme.fonts.size.small};
  padding: 4px 16px 16px 16px;
  color: ${theme.color.textColor};
  word-break: break-word;
  p {
    margin-bottom: 0px;
    line-height: 1.2;
  }
`

export const DivPromotionList = styled.div`
  display: flex;
  margin: 24px -12px;
  flex-wrap: wrap;
  ${media.mobileL`
    justify-content: center;
  `}
`

export const DivAction = styled.div`
  display: flex;
  justify-content: center;
`

export const ImageWithButtonWrapper = styled.div`
  width: calc((100% - 72px) / 3);
  margin: 12px;
  text-align: center;
`

export const ImageWrapper = styled.div`
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-top: 100%;
  cursor: pointer;
  position: relative;
  ${props => props.img && css`
    background-image: url(${props.img});
    background-size: cover;
    background-position: center;
  `}
  flex: 0 1 auto;
`

export const DivPromotion = styled(Div)`
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: ${theme.color.offWhite};
  flex: 1 1 auto;
`

export const LabelPromotion = styled(Div)`
  font-weight: ${theme.fonts.style.semiBold};
  font-size: ${theme.fonts.size.normal};
  border-radius: 22px;
  background-color: ${theme.color.greenPrimary};
  color: ${theme.color.offWhite};
  width: fit-content;
  padding: 0px 12px;
  margin: 0;
  display: inline-flex;
`

export default ImageWrapper
