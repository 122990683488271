import React, { useState } from 'react'
import {
  WidthContainer, FullWidthContainer,
} from './styled'
import CoverUpload from '../cover-upload'
import SampleImageUpload from '../sample-image-upload'
import Title from '../title'
import Description from '../description'
import Manufacturer from '../manufacturer'
import AmountPart from '../amount-part'
import Parts from '../parts'
import Tag from '../tag'


const PackageForm = ({
  packagingData,
  formValues,
  setFormValues,
  isValidate,
  setIsValidate,
}) => {
  const [, setDisabledButton] = useState(true)
  return (
    <div>
        <WidthContainer>
          <CoverUpload
            data={formValues}
            newFileList={formValues.coverPhoto}
            setNewFileList={setFormValues}
          />
          <SampleImageUpload
            data={formValues}
            newFileList={formValues.imagePhoto}
            setNewFileList={setFormValues}
          />
          <Title
            label={'ชื่อบรรจุภัณฑ์'}
            data={formValues}
            onInputChange={setFormValues}
            setDisabledButton={setDisabledButton}
            setIsValidate={setIsValidate}
            isValidate={isValidate}
          />
          <Description
            data={formValues}
            onInputChange={setFormValues}
          />
          <Tag
            formValues={formValues}
            setFormValues={setFormValues}
          />
          <AmountPart
            data={formValues}
            onInputChange={setFormValues}
            partData={formValues.partData}
          />
          <Manufacturer
            data={formValues}
            setData={setFormValues}
          />
        </WidthContainer>
        <FullWidthContainer>
          {
            formValues.partData.length > 0 && <Parts
              amountPart={formValues.amountPart}
              data={formValues}
              partData={formValues.partData}
              setPartData={setFormValues}
              packageParts={packagingData && packagingData.packageParts}
            />
          }
        </FullWidthContainer>
    </div>
  )
}

export default PackageForm
