/* eslint-disable no-restricted-globals */
import React, { useState, useMemo } from 'react'
import {
  WidthContainer, FullWidthContainer, QuaterFullWidthContainer,
} from './styled'
import PlasticTitleInput from '../title'
import PlasticDescriptionInput from '../description'
import PlasticSampleVideoInput from '../sample-video'
import PlasticSampleImageInput from '../sample-image'
import PlasticTable from '../thickness-table'


const PlasticForm = ({
  formValues,
  setFormValues,
  isValidate,
  setIsValidate,
  action,
  history,
}) => {
  const [, setDisabledButton] = useState(true)

  const handleOnSubmitEditRow = (key, value) => {
    const tmp = [...formValues.thickness]
    const tmpValue = value
    if (tmpValue.column1 === null || isNaN(tmpValue.column1)) tmpValue.column1 = 0
    if (tmpValue.column2 === null || isNaN(tmpValue.column2)) tmpValue.column2 = 0
    if (tmpValue.column3 === null || isNaN(tmpValue.column3)) tmpValue.column3 = 0
    if (tmpValue.column4 === null || isNaN(tmpValue.column4)) tmpValue.column4 = 0
    if (tmpValue.column5 === null || isNaN(tmpValue.column5)) tmpValue.column5 = 0
    tmp[key] = tmpValue
    setFormValues({ ...formValues, thickness: tmp })
  }

  const handleOnSubmitDeleteRow = (value) => {
    setFormValues({ ...formValues, thickness: value })
  }

  const handleOnAddRow = (value) => {
    setFormValues({ ...formValues, thickness: value })
  }
  return (
    <>
      <WidthContainer>
        <PlasticTitleInput
          label={'ชื่อการเจาะหน้าต่างและติดแผ่นใส'}
          data={formValues}
          onInputChange={setFormValues}
          setDisabledButton={setDisabledButton}
          setIsValidate={setIsValidate}
          isValidate={isValidate}
        />
        <PlasticDescriptionInput
          data={formValues}
          onInputChange={setFormValues}
        />
        <PlasticSampleVideoInput
          // data={formValues}
          newFileList={formValues.sampleVideo}
          setNewFileList={(value) => {
            setFormValues({ ...formValues, sampleVideo: value })
          }}
        />
        <PlasticSampleImageInput
          data={formValues}
          newFileList={formValues.imagePhoto || []}
          setNewFileList={setFormValues}
        />
      </WidthContainer>
      <QuaterFullWidthContainer>
        <PlasticTable
          data={formValues.thickness}
          onSubmitEditRow={handleOnSubmitEditRow}
          onSubmitDeleteRow={handleOnSubmitDeleteRow}
          onAddRow={handleOnAddRow}
          formValues={formValues}
          action={action}
          history={history}
        />
      </QuaterFullWidthContainer>
    </>
  )
}

export default PlasticForm
