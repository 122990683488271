/* eslint-disable radix */
import React, { useState, useEffect, useRef } from 'react'
import {
  Div, H4,
} from 'konsys-ui'
import { StickyContainer, Sticky } from 'react-sticky'
import {
  ContentTabPane,
  Tabs,
  TrapezoidTab,
} from './styled'


const { TabPane } = Tabs

const renderTabBar = (props, DefaultTabBar, sticky) => {
  if (sticky) {
    return (
      <Sticky bottomOffset={20}>
      {
        ({ style }) => (<Div>
          <DefaultTabBar {...props} style={{ ...style, zIndex: 1 }} />
        </Div>
        )
      }
    </Sticky>
    )
  }
  return (
    <Div>
      <DefaultTabBar
        {...props} style={{
          borderBottom: '0px',
          height: '40px',
        }}
      />
    </Div>
  )
}

const TabPart = ({
  onChange,
  data,
  children,
  activeTab,
  handleChangeTap,
  sticky,
  ContentRef,
  isTop180,
}) => {
  const [tabPanes, setTabPanes] = useState([
    { name: '1', key: 0, content: 'content 1' },
    { name: '2', key: 1, content: 'content 2' },
    { name: '3', key: 2, content: 'content 3' },
  ])
  const ContentTab = useRef(null)

  useEffect(() => {
    if (data) {
      setTabPanes(data)
    }
  }, [data])

  useEffect(() => {
    if (data) {
      setTabPanes(data)
    }
  }, [data])
  const [top, setTop] = useState(0)
  useEffect(() => {
    const scrollFun = () => {
      if (sticky && ContentRef) {
        if (!isTop180) {
          const extraHeight = 200
          const bottom = ContentRef.current.offsetTop + ContentRef.current.offsetHeight - (ContentTab.current.offsetHeight + extraHeight)
          if (parseFloat(document.getElementById('container').scrollTop) > parseFloat(ContentRef.current.offsetTop) && parseFloat(document.getElementById('container').scrollTop) < bottom) {
            setTop(document.getElementById('container').scrollTop - ContentRef.current.offsetTop)
          } else if (document.getElementById('container').scrollTop >= bottom) {
            setTop(bottom - ContentRef.current.offsetTop)
          } else {
            setTop(0)
          }
        } else {
          const extraHeight = 200
          const bottom = (ContentRef.current.offsetTop + ContentRef.current.offsetHeight) - (ContentTab.current.offsetHeight + extraHeight)
          if (parseFloat(document.getElementById('container').scrollTop) > parseFloat(ContentRef.current.offsetTop) && parseFloat(document.getElementById('container').scrollTop) < bottom) {
            setTop(document.getElementById('container').scrollTop - ContentRef.current.offsetTop)
          } else if (document.getElementById('container').scrollTop >= bottom) {
            setTop(bottom - ContentRef.current.offsetTop)
          } else {
            setTop(0)
          }
        }
      }
    }

    document.getElementById('container').addEventListener('scroll', scrollFun)

    return () => {
      document.getElementById('container').removeEventListener('scroll', scrollFun)
    }
  }, [document.getElementById('container').offsetHeight])
  if (sticky) {
    return (
      <StickyContainer style={{ height: '100%', overflow: 'visible' }}>
        <div
        ref={ContentTab}
        style={{
          width: '100%',
          position: 'absolute',
          top,
          transition: 'all 0.2s linear',
        }}>
          <Tabs
            defaultActiveKey={activeTab}
            activeKey={activeTab.toString()}
            onChange={handleChangeTap}
            renderTabBar={renderTabBar}
            onTabClick={handleChangeTap}
            animated={false}
          >
            {
              tabPanes.map((tab, index) => (
                <TabPane
                  style={{ background: 'white' }}
                  tab={
                    <TrapezoidTab>
                      <CustomTab name={index + 1} activeTab={parseInt(activeTab) === parseInt(index)} />
                    </TrapezoidTab>
                  }
                  key={index}
                >
                  <ContentTabPane>
                    {children}
                  </ContentTabPane>
                </TabPane>
              ))
            }
          </Tabs>
        </div>
      </StickyContainer>
    )
  }
  return (
    <Div>
      <Tabs
          defaultActiveKey={activeTab}
          onChange={handleChangeTap}
          activeKey={activeTab.toString()}
          renderTabBar={(props, DefaultTabBar) => renderTabBar(props, DefaultTabBar, sticky)}
          onTabClick={handleChangeTap}
          animated={false}
        >
          {
            tabPanes.map((tab, index) => (
              <TabPane
                style={{ background: 'white' }}
                tab={
                  <TrapezoidTab>
                    <CustomTab name={index + 1} activeTab={parseInt(activeTab) === parseInt(index)} />
                  </TrapezoidTab>
                }
                key={index}
              >
                <ContentTabPane>
                  {children}
                </ContentTabPane>
              </TabPane>
            ))
          }
        </Tabs>
    </Div>
  )
}

const CustomTab = ({ name, activeTab }) => (
  <div className={`tab ${activeTab ? 'tab-active' : 'tab-inactive'}`}>
    <div className={`label ${activeTab ? 'label-active' : 'label-inactive'}`}>
      <div className={activeTab ? 'circle-number-active' : 'circle-number'}>
        {name}
      </div>
    </div>
  </div>
)

export default TabPart
