import styled from 'styled-components'
import {
  Icon, Div, H4,
} from 'konsys-ui'
import { Tabs } from 'antd'
import { theme, media, minMedia } from '../../../styles/_variables'


export const FullWidthContainer = styled.div`
  width: 100%;
`

export const NumberLabel = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${theme.fonts.size.small};
  background-color:${props => (props.activeTab ? theme.color.greenPrimary : theme.color.offGray)};
  width: 24px;
  height: 24px;
  color: ${props => (props.activeTab ? theme.color.offGray : theme.color.greenPrimary)};
  border-radius: 50%;
  font-style: normal;
  font-weight: bold;
  font-weight: ${theme.fonts.style.medium};
  ${media.mobileL`
    font-size: ${theme.fonts.size.small};
    width: 16px;
    height: 16px;
    margin-top: 2px;
  `}
`

export const ContentTabPane = styled(Div)`
  padding: ${props => (props.isOne ? '40px 0px 0px 0px' : '40px 0px 40px 0px')};
`

export const Title = styled(H4)`
  margin-left: 0px;
  margin-right: 20px;
  margin-top: 20px;
  font-size: ${theme.fonts.size.extraNormal};
  ${media.mobileL`
    font-size: ${theme.fonts.size.extraNormal};
    margin-top: 18px;
    margin-right: 0px;
  `}
`

export const TitlePart = styled(H4)`
  font-size: ${theme.fonts.size.extraNormal};
  font-weight: ${theme.fonts.style.medium};
  ${media.mobileL`
    font-size: ${theme.fonts.size.small};
  `}
`

export const TabTitleDiv = styled(Div)`
  padding: 12px 4px;
  ${media.mobileL`
    padding: 15px 4px;
  `}
`

export const BottomTabBar = styled(Div)`
.ant-tabs-bar > .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div > .ant-tabs-tab.ant-tabs-tab-active{
  height: 54px;
  // border: 0px !important;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: #f6f5f5 !important;
  padding-left: 6px;
  padding-right: 10px;
  border-top: 0px !important;
  border-color: ${theme.color.greenPrimary} !important;
}

.ant-tabs-bar > .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div > .ant-tabs-tab{
  height: 52px !important;
  // border: 0px !important;
  border-top: 0px !important;
  border-color: transparent !important;
  margin-top: 7.6px !important;
  background-color: transparent !important;
  padding-left: 6px !important;
  padding-right: 10px !important;
  padding-top: 7px !important;
  @media only screen and (min-height: 1980px) {
    margin-top: 0.750rem !important;
  }
  ${media.tablet`
    margin-top: 7.1 !important;
  `}
}

.ant-tabs-bar > .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div{
  margin-top: -8px !important;
}

.ant-tabs-bar > .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div > .ant-tabs-tab > .tab-title{
  padding: 12px 4px 0px 4px !important;
}

.ant-tabs-bar > .ant-tabs-nav-scroll {
  overflow: hidden !important;
}

.ant-tabs-nav-wrap {
  overflow-y: hidden !important;
  overflow-x: overlay !important;
}

.ant-tabs-nav-scroll {
  overflow-y: hidden !important;
  overflow-x: auto !important;
  height: 60px;
}

.ant-tabs-bar > .ant-tabs-extra-content {
  margin-top: 0px !important;
}

.ant-tabs-nav-scroll::-webkit-scrollbar {
  height: 6px !important;
}

.ant-tabs-nav-scroll::-webkit-scrollbar:vertical {
  width: 6px !important;
}

.ant-tabs-nav-scroll::-webkit-scrollbar:scrollbar:horizontal {
  height: 6px !important;
}

.ant-tabs-nav-scroll::-webkit-scrollbar-track {
  background: transparent; 
}

.ant-tabs-nav-scroll::-webkit-scrollbar-thumb {
  background: ${theme.color.primaryColor}; 
}

.ant-tabs-nav-scroll::-webkit-scrollbar-thumb:window-inactive {
  background: ${theme.color.primaryColor}; 
}

.ant-tabs-nav-scroll::-webkit-scrollbar-thumb:hover {
  background: ${theme.color.lightGrey}; 
}

.ant-tabs-nav.ant-tabs-nav-animated {
  transform: unset !important;
}
`

export const TopTabBar = styled(Div)`
  .ant-tabs-bar > .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div > .ant-tabs-tab.ant-tabs-tab-active{
    height: 54px !important;
    // border: 0px !important;
    // margin-top: 7px !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    background-color: #f6f5f5 !important;
    padding-left: 6px !important;
    padding-right: 10px !important;
    border-bottom: 0px !important;
    display: ${props => (props.hideOne && 'none;')}
    // ${media.tablet`
    //   border-bottom: 1px solid #f6f5f5 !important;
    // `}
  }

  .ant-tabs-bar > .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div > .ant-tabs-tab{
    height: 53px !important;
    border: 0px !important;
    margin-top: 7px !important;
    background-color: ${theme.color.greenPrimary} !important;
    padding-left: 6px !important;
    padding-right: 10px !important;
  }

  .ant-tabs-bar > .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div{
    margin-top: 1px !important;
  }

  .ant-tabs-bar > .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div > .ant-tabs-tab > .tab-title{
    padding: 12px 4px 0px 4px !important;
  }

  .ant-tabs-bar > .ant-tabs-nav-scroll {
    overflow: hidden !important;
  }

  .ant-tabs-nav-wrap {
    overflow-y: hidden !important;
    overflow-x: auto !important;
  }

  .ant-tabs-nav-scroll {
    overflow-y: hidden !important;
    overflow-x: overlay !important;
  }

  .ant-tabs-nav-scroll::-webkit-scrollbar {
    height: 6px !important;
  }

  .ant-tabs-nav-scroll::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  .ant-tabs-nav-scroll::-webkit-scrollbar:scrollbar:horizontal {
    height: 6px !important;
  }

  .ant-tabs-nav-scroll::-webkit-scrollbar-track {
    background: transparent; 
  }

  .ant-tabs-nav-scroll::-webkit-scrollbar-thumb {
    background: ${theme.color.primaryColor}; 
  }

  .ant-tabs-nav-scroll::-webkit-scrollbar-thumb:window-inactive {
    background: ${theme.color.primaryColor}; 
  }

  .ant-tabs-nav-scroll::-webkit-scrollbar-thumb:hover {
    background: ${theme.color.lightGrey}; 
  }
  .ant-tabs-nav.ant-tabs-nav-animated {
    transform: unset !important;
  }
`

export const TabsAnt = styled(Tabs)`
  .ant-tabs-nav-container{
    height: 60px !important;
  }
  .ant-tabs-nav-container {
    padding-left: 10px !important;
    padding-right: 0px !important;
    margin-bottom: 0px !important;
  }
  .ant-tabs-tab-prev.ant-tabs-tab-arrow-show, .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
    pointer-events: none !important;
    width: 0 !important;
    opacity: 0 !important;
  }
  .ant-tabs-tab-prev-icon-target, .ant-tabs-tab-next-icon-target {
    font-size: 24px !important;
  }
  .ant-tabs-tab-btn-disabled, .ant-tabs-tab-btn-disabled:hover {
    color: rgba(255, 255, 255, 0);
  }
  .ant-tabs-tab-prev, .ant-tabs-tab-next {
    color: rgba(255, 255, 255, 0);
  }
  .ant-tabs-nav-wrap {
    margin-top: -0.3px !important;
    ${minMedia.tablet`
      margin-top: -0.3px !important;
    `}
  }
`

export default FullWidthContainer
