import styled from 'styled-components'
import { Div, Button } from 'konsys-ui'
import { theme } from '../../../../../styles/_variables'


export const Area = styled(Div)`
  margin-top: 48px;
  width: 100%;
  min-width: 460px;
  padding: 24px;
  background-color: ${theme.color.offWhite};
  border: 1px solid ${theme.color.paleGray};
  border-radius: 10px;
`

export const TagDiv = styled(Button)`
  border-radius: 1.4rem;
  padding: 0px 8px;
  width: fit-content;
  max-width: 350px;
  text-align: left;
  background-color: ${theme.color.primaryColor};
  color: ${theme.color.offWhite};
  i {
    font-size: ${theme.fonts.size.label} !important;
    font-weight: ${theme.fonts.style.medium};
  }
  span {
    word-break: break-all;
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
  }
`

export const DeleteButton = styled(Button)`
  border-radius: 4px;
  padding: 0px 10px;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  i {
    font-size: ${theme.fonts.size.label} !important;
    font-weight: ${theme.fonts.style.medium};
  }
  span {
    font-size: ${theme.fonts.size.small};
    font-weight: ${theme.fonts.style.medium};
  }
`

export default Area
