import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Query, Mutation } from 'react-apollo'
import { Link } from 'react-router-dom'
import { message, Popconfirm } from 'antd'
import {
  Div, H4, Button, LoadingIcon,
} from 'konsys-ui'
import PATH from '../../../../constants/path'
import { theme } from '../../../../styles/_variables'
import ThicknessForm from '../components/thickness-form'
import { GET_PLASTIC_WINDOW_THICKNESS_DETAIL } from '../../../../constants/graphql/query'
import { EDIT_PLASTIC_WINDOW_THICKNESS, DELETE_PLASTIC_WINDOW_THICKNESS } from '../../../../constants/graphql/mutation'
import BreadCrumb from '../../../../components/common/breadcrumb'


const { color } = theme

const PlasticWindownThicknessDetail = ({
  history,
  match,
}) => {
  const [callMutation, setCallMutation] = useState(false)
  const [isValidate, setIsValidate] = useState(true)
  const [formValues, setFormValues] = useState({
    plasticWindowId: 0,
    thickness: 0,
    minimumCost: 0,
    minimumOperationCost: 0,
    plasticWindowThicknessTypeCost: {
      plasticWindowThicknessCost: [],
      plasticWindowThicknessCostColumnName: [{
        title: 'จำนวนผลิต (ชิ้น)',
        dataIndex: 'quantity',
        key: 'quantity',
        inputType: 'number',
        editable: false,
        editColumn: false,
        alignCell: 'center',
      }],
    },
    plasticWindowThicknessTypeOperationCost: {
      plasticWindowThicknessCost: [],
      plasticWindowThicknessCostColumnName: [{
        title: 'จำนวนผลิต (ชิ้น)',
        dataIndex: 'quantity',
        key: 'quantity',
        inputType: 'number',
        editable: false,
        editColumn: false,
        alignCell: 'center',
      }],
    },
  })

  return (
    <Div>
      <Query
        query={GET_PLASTIC_WINDOW_THICKNESS_DETAIL}
        variables={{
          id: parseInt(match.params.id, 10),
        }}
        notifyOnNetworkStatusChange
        onCompleted={(data) => {
          if (data.getPlasticWindowThicknessDetail.success) {
            const plasticWindowThicknessData = data.getPlasticWindowThicknessDetail.plasticWindowThickness
            const plasticWindowThicknessCostColumnName = plasticWindowThicknessData.plasticWindowThicknessTypeCost.plasticWindowThicknessCostColumnName.filter(item => item.key !== 'quantity' && item.key !== 'price').map(item => ({
              title: item.title || item.name,
              dataIndex: item.dataIndex || item.type,
              key: item.dataIndex || item.type,
              inputType: 'number',
              editable: true,
              editColumn: item.type !== 'column1',
              alignCell: 'center',
            }))
            const plasticWindowThicknessOperationCostColumnName = plasticWindowThicknessData.plasticWindowThicknessTypeOperationCost.plasticWindowThicknessCostColumnName.filter(item => item.key !== 'quantity' && item.key !== 'price').map(item => ({
              title: item.title || item.name,
              dataIndex: item.dataIndex || item.type,
              key: item.dataIndex || item.type,
              inputType: 'number',
              editable: true,
              editColumn: item.type !== 'column1',
              alignCell: 'center',
            }))
            setFormValues({
              plasticWindowId: plasticWindowThicknessData.plasticWindowId,
              thickness: plasticWindowThicknessData.thickness,
              minimumCost: plasticWindowThicknessData.minimumCost,
              minimumOperationCost: plasticWindowThicknessData.minimumOperationCost,
              plasticWindowThicknessTypeCost: {
                plasticWindowThicknessCost: plasticWindowThicknessData.plasticWindowThicknessTypeCost.plasticWindowThicknessCost.map((item, index) => ({
                  key: index,
                  quantity: item.quantity,
                  column1: item.column1,
                  column2: item.column2,
                  column3: item.column3,
                  column4: item.column4,
                  column5: item.column5,
                })) || [],
                plasticWindowThicknessCostColumnName: [...formValues.plasticWindowThicknessTypeCost.plasticWindowThicknessCostColumnName, ...plasticWindowThicknessCostColumnName],
              },
              plasticWindowThicknessTypeOperationCost: {
                plasticWindowThicknessCost: plasticWindowThicknessData.plasticWindowThicknessTypeOperationCost.plasticWindowThicknessCost.map((item, index) => ({
                  key: index,
                  quantity: item.quantity,
                  column1: item.column1,
                  column2: item.column2,
                  column3: item.column3,
                  column4: item.column4,
                  column5: item.column5,
                })) || [],
                plasticWindowThicknessCostColumnName: [...formValues.plasticWindowThicknessTypeOperationCost.plasticWindowThicknessCostColumnName, ...plasticWindowThicknessOperationCostColumnName],
              },
            })
          } else {
            message.error(data.getPlasticWindowThicknessDetail.message)
            setTimeout(() => {
              window.location = PATH.plasticSetting
            }, 1000)
          }
        }}
      >
        {
          ({ loading, data }) => {
            if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
            if (!data.getPlasticWindowThicknessDetail.success) return <p>error something</p>
            const { plasticWindowThicknessId, thickness } = data.getPlasticWindowThicknessDetail.plasticWindowThickness
            return (
              <>
                <Div display='flex' flexDirection='row' justifyContent='space-between' margin='32px 0px 0px 0px' alignItems='center'>
                  <Div display='flex' flexDirection='column'>
                    <H4 bold color={color.primaryColor}>
                      <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                        <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                      </Link> {' > '}
                      <Link to='/package-setting/plastic-window' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                        <H4 bold color={color.primaryColor}>การเจาะหน้าต่างและติดแผ่นใส</H4>
                      </Link> {' > '} แก้ไขข้อมูล
                    </H4>
                  </Div>
                  <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                      <DeleteButton plasticWindowThicknessId={plasticWindowThicknessId} />
                      <SaveButton
                        plasticWindowThicknessId={plasticWindowThicknessId}
                        formValues={formValues}
                        callMutation={callMutation}
                        setCallMutation={setCallMutation}
                        setIsValidate={setIsValidate}
                      />
                    </Div>
                  </Div>
                </Div>
                <ThicknessForm
                  formValues={formValues}
                  setFormValues={setFormValues}
                  isValidate={isValidate}
                  setIsValidate={setIsValidate}
                  action={'detail'}
                />
              </>
            )
          }
        }
      </Query>
    </Div>
  )
}

const DeleteButton = ({ plasticWindowThicknessId }) => (
  <Mutation mutation={DELETE_PLASTIC_WINDOW_THICKNESS}>
    {
      doDeletePlasticWindowThickness => (
        <Popconfirm
          title="ยืนยันการลบความหนาหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก"
          onConfirm={() => {
            doDeletePlasticWindowThickness({
              variables: {
                id: parseInt(plasticWindowThicknessId, 10),
              },
            }).then((res) => {
              if (res.data.deletePlasticWindowThickness) {
                if (res.data.deletePlasticWindowThickness.success) {
                  message.success(res.data.deletePlasticWindowThickness.message)
                  setTimeout(() => {
                    window.location = PATH.plasticSetting
                  }, 350)
                } else {
                  message.error(res.data.deletePlasticWindowThickness.message)
                }
              }
            })
          }}
          okText='ยืนยัน'
          cancelText='ยกเลิก'
        >
          <Button text='ลบ' icon='fa fa-trash' ghost margin='0 16px 0 0' color={color.error}/>
        </Popconfirm>
      )
    }
  </Mutation>
)

const SaveButton = ({
  plasticWindowThicknessId,
  formValues,
  callMutation,
  setCallMutation,
  setIsValidate,
}) => (
  <Mutation mutation={EDIT_PLASTIC_WINDOW_THICKNESS}>
    {
      doEditPlasticWindowThickness => (
        <Button
          text='บันทึก'
          icon='fa fa-save'
          inverse
          margin='0 16px 0 0'
          onClick={() => {
            setCallMutation(true)
            if (!formValues.thickness) {
              message.warning('กรุณากรอกข้อมูลความหนา')
              setCallMutation(false)
              setIsValidate(false)
              return
            }
            const plasticWindowThicknessCost = {
              plasticWindowThicknessCostColumnName: formValues.plasticWindowThicknessTypeCost.plasticWindowThicknessCostColumnName.filter(column => /column*/.test(column.key)).map(column => ({
                name: column.title,
                type: column.key,
                plasticWindowThicknessId: column.plasticWindowThicknessId,
              })),
              plasticWindowThicknessCost: formValues.plasticWindowThicknessTypeCost.plasticWindowThicknessCost.map(row => ({
                plasticWindowThicknessId: row.plasticWindowThicknessId,
                quantity: row.quantity,
                column1: parseFloat(row.column1) || 0,
                column2: parseFloat(row.column2) || 0,
                column3: parseFloat(row.column3) || 0,
                column4: parseFloat(row.column4) || 0,
                column5: parseFloat(row.column5) || 0,
              })),
            }
            const plasticWindowThicknessOperationCost = {
              plasticWindowThicknessCostColumnName: formValues.plasticWindowThicknessTypeOperationCost.plasticWindowThicknessCostColumnName.filter(column => /column*/.test(column.key)).map(column => ({
                name: column.title,
                type: column.key,
                plasticWindowThicknessId: column.plasticWindowThicknessId || null,
              })),
              plasticWindowThicknessCost: formValues.plasticWindowThicknessTypeOperationCost.plasticWindowThicknessCost.map(row => ({
                plasticWindowThicknessId: row.plasticWindowThicknessId || null,
                quantity: row.quantity,
                column1: parseFloat(row.column1) || 0,
                column2: parseFloat(row.column2) || 0,
                column3: parseFloat(row.column3) || 0,
                column4: parseFloat(row.column4) || 0,
                column5: parseFloat(row.column5) || 0,
              })),
            }
            const input = {
              plasticWindowThicknessId,
              plasticWindowId: formValues.plasticWindowId,
              thickness: formValues.thickness,
              minimumCost: parseFloat(formValues.minimumCost),
              minimumOperationCost: parseFloat(formValues.minimumOperationCost),
              plasticWindowThicknessCost: [plasticWindowThicknessCost],
              plasticWindowThicknessOperationCost: [plasticWindowThicknessOperationCost],
            }
            doEditPlasticWindowThickness({
              variables: {
                input,
              },
            }).then(({ data }) => {
              if (data.editPlasticWindowThickness.success) {
                setCallMutation(false)
                message.success(data.editPlasticWindowThickness.message)
                setTimeout(() => {
                  window.location = PATH.plasticSetting
                }, 350)
              } else {
                setCallMutation(false)
                if (data.editPlasticWindowThickness.message === 'ความหนาการเจาะหน้าต่างและติดแผ่นใส นี้มีอยู่ในระบบแล้ว') {
                  setIsValidate(false)
                  setCallMutation(false)
                }
                message.error(data.editPlasticWindowThickness.message)
                setCallMutation(false)
              }
            })
          }}
          disabled={callMutation}
        />
      )
    }
  </Mutation>
)

export default PlasticWindownThicknessDetail
