import React from 'react'
import {
  Select,
} from 'antd'
import {
  Label, DivContentAmountPart,
} from './styled'


const AMOUNT_PART = [{
  value: 1,
  text: '1 ชิ้น',
}, {
  value: 2,
  text: '2 ชิ้น',
}, {
  value: 3,
  text: '3 ชิ้น',
}, {
  value: 4,
  text: '4 ชิ้น',
}, {
  value: 5,
  text: '5 ชิ้น',
}]
const AmountPart = ({
  data,
  onInputChange,
  partData,
}) => (
  <DivContentAmountPart id="amount-part">
    <Label style={{ paddingRight: '24px' }}>จำนวนชิ้นส่วน</Label>
    <Select
      dropdown
      onChange={(e) => {
        if (partData) {
          const partDataValue = Array(e).fill(0).map((item, i) => {
            if (partData[i]) {
              return partData[i]
            }
            return {
              id: 0 - i,
              name: '',
              image: [],
              amountVariable: 1,
              width: 'a',
              length: 'a',
              requiredName: false,
              requiredWidth: false,
              requiredLength: false,
              gluing: 0,
              paper: [],
              defaultPaper: [],
              additionalService: [],
              isDieCut: 0,
            }
          })
          onInputChange({ ...data, partData: partDataValue, amountPart: e })
        } else {
          onInputChange({ ...data, amountPart: e })
        }
      }}
      style={{ width: '120px', fontSize: '18px' }}
      value={data.amountPart}
    >
    {
      AMOUNT_PART.map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)
    }
    </Select>
  </DivContentAmountPart>
)

export default AmountPart
