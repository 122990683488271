/* eslint-disable radix */
import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Query, Mutation } from 'react-apollo'
import {
  Div, Button, H4, LoadingIcon,
} from 'konsys-ui'
import {
  Row,
  Col,
  Modal,
  message,
  Popconfirm,
} from 'antd'
import parseHtml from 'html-react-parser'
import evaluatex from 'evaluatex/dist/evaluatex'
import {
  InputAmount, DivAmount, LabelAmount, DivCenter,
  SpanButton, TextLabel, TextValue, DivPricing,
  MoreTextLabel, MoreText, TextTitle, ModalTitle,
  DescriptionContent, DivInput,
} from './styled'
import NotchedContainer from '../../../common/notched-containner'
import PriceDetails from '../../../../images/icon/Price-detailsIcon.png'
import PriceList from '../../../../images/icon/Price-listIcon.png'
import PriceCalculateTable from '../common/price-calculate-table'
import OrderTypeSelect from '../common/order-type-select'
import { PRICE_CALCULATOR_QUANTITY, GET_PRICE_NOTE } from '../../../../constants/graphql/query'
import { UPDATE_BOX_DETAIL_QUOTATION } from '../../../../constants/graphql/mutation'
import PATH from '../../../../constants/path'


const mockTable = [{
  quantity: 1,
  totalCost: 5000.00,
  totalRepeatCost: 2700.00,
}, {
  quantity: 500,
  totalCost: 45.00,
  totalRepeatCost: 25.00,
}, {
  quantity: 1000,
  totalCost: 35.00,
  totalRepeatCost: 22.00,
}, {
  quantity: 1500,
  totalCost: 32.00,
  totalRepeatCost: 20.00,
}, {
  quantity: 2000,
  totalCost: 30.00,
  totalRepeatCost: 18.00,
}, {
  quantity: 3000,
  totalCost: 28.00,
  totalRepeatCost: 17.00,
}, {
  quantity: 4000,
  totalCost: 27.00,
  totalRepeatCost: 16.50,
}, {
  quantity: 6000,
  totalCost: 26.00,
  totalRepeatCost: 16.00,
}, {
  quantity: 7000,
  totalCost: 24.00,
  totalRepeatCost: 15.50,
}, {
  quantity: 8000,
  totalCost: 22.00,
  totalRepeatCost: 14.00,
}, {
  quantity: 9000,
  totalCost: 19.00,
  totalRepeatCost: 13.00,
}, {
  quantity: 10000,
  totalCost: 18.00,
  totalRepeatCost: 11.50,
}]
const quantity = [1, 500, 1000, 1500, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000]
const TablePrice = ({
  totalCost,
  formValues,
  setFormValues,
  showEditIcon,
  handleEdit = () => {},
  setDisabledRequestQuot = () => {},
  isQuotationEdit,
  packageValues,
}) => {
  const [orderType, setOrderType] = useState(formValues.productionType || 'newOrder')
  const [dataTable, setDataTable] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [amountProduction, setAmountProduction] = useState(formValues.amountProduction)
  const [totalCostDefault, setTotalCostDefault] = useState(null)
  const [previousAmountProduction, setPreviousAmountProduction] = useState(parseInt(formValues.minimumManufacture) || 0)
  const [firstTimeLoad, setFirstTimeLoad] = useState(true)

  const handleCalulate = async (amount, refetch) => {
    setDisabledRequestQuot(true)
    setDisabledButton(true)
    const part = formValues.partData.map((item, index) => ({
      order: index + 1,
      packagePartId: item.packagePartId > 0 ? item.packagePartId : null,
      unit: item.measurementUnit.key ? item.measurementUnit.key : item.measurementUnit.label === 'ซม.' ? 'cm' : item.measurementUnit.label === 'นิ้ว' ? 'inches' : '',
      operator: item.packagePartId > 0 ? Object.values(item.sizeList).map(x => (parseFloat(x))) : [],
      printSizeWidth: formValues.partDataCalculate && formValues.partDataCalculate.length > 0 ? formValues.partDataCalculate[index].printSizeWidth : item.printSizeWidth ? item.printSizeWidth : 0.0,
      printSizeLength: formValues.partDataCalculate && formValues.partDataCalculate.length > 0 ? formValues.partDataCalculate[index].printSizeLength : item.printSizeLength ? item.printSizeLength : 0.0,
      paper: {
        paperId: item.paper.paperId,
        thickness: parseFloat(item.paper.selectedThickness),
      },
      printing: (item.printing.printingName && item.printing.printingBasicColorId) ? {
        side: item.printing.printingTypeName === 'พิมพ์หน้าเดียว' ? 1 : item.printing.printingTypeName === 'พิมพ์สองหน้า' ? 2 : null,
        printingBasicColorId: item.printing.printingBasicColorId,
        printingExtraColorId: item.printing.printingExtraColorId.filter(x => x.printingExtraColorId).map(x => x.printingExtraColorId),
      } : null,
      coating: {
        coatingIdFront: item.coating.side === 1 || item.coating.side === 2 ? item.coating.coatingFrontId : null,
        coatingIdBehind: item.coating.side === -1 || item.coating.side === 2 ? item.coating.coatingBackId : null,
      },
      spotUV: {
        spotUVIdFront: item.spotUV.spotUVFrontId,
        spotUVIdBehind: item.spotUV.spotUVBackId,
      },
      embossing: item.embossing.map((x, i) => ({
        order: i + 1,
        width: parseFloat(x.width),
        length: parseFloat(x.lengths),
        unit: item.measurementUnit.key ? item.measurementUnit.key : item.measurementUnit.label === 'ซม.' ? 'cm' : item.measurementUnit.label === 'นิ้ว' ? 'inches' : '',
      })),
      foiling: {
        foilingId: item.foilingId !== 0 ? item.foilingId : null,
        foilingPart: item.foiling.map((x, i) => ({
          order: i + 1,
          width: parseFloat(x.width),
          length: parseFloat(x.lengths),
          unit: item.measurementUnit.key ? item.measurementUnit.key : item.measurementUnit.label === 'ซม.' ? 'cm' : item.measurementUnit.label === 'นิ้ว' ? 'inches' : '',
        })),
      },
      dieCut: item.dieCut,
      plasticWindow: item.plasticWindow.map((x, i) => ({
        order: i + 1,
        width: parseFloat(x.width),
        length: parseFloat(x.lengths),
        thickness: x.thickness,
        unit: item.measurementUnit.key ? item.measurementUnit.key : item.measurementUnit.label === 'ซม.' ? 'cm' : item.measurementUnit.label === 'นิ้ว' ? 'inches' : '',
      })),
      gluingId: item.gluingId ? item.gluingId : typeof item.gluing === 'number' ? item.gluing === 0 ? null : item.gluing : typeof item.gluing === 'object' && item.gluing ? item.gluing.gluingId : item.gluing ? item.gluing : null,
      additionalServiceId: (item.additionalServiceId && item.additionalServiceId.length > 0) ? item.additionalServiceId.map(x => x.additionalServiceId || x.value) || [] : item.additionalService ? item.additionalService.map(x => x.additionalServiceId || x.value) || [] : [],
    }))
    const input = {
      part,
      quantity: parseInt(amount),
    }
    refetch({
      input,
    }).then((res) => {
      if (res && res.data.priceCalculatorQuantity.success) {
        setDisabledRequestQuot(false)
        setDisabledButton(false)
        const tmpForm = formValues
        const costDetail = tmpForm.costDetail.map((item, index) => ({
          additionalServiceCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].additionalServiceCost, ...res.data.priceCalculatorQuantity.costDetail[index].additionalServiceCost] : [...res.data.priceCalculatorQuantity.costDetail[index].additionalServiceCost],
          coatingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].coatingCost, ...res.data.priceCalculatorQuantity.costDetail[index].coatingCost] : [...res.data.priceCalculatorQuantity.costDetail[index].coatingCost],
          dieCutCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].dieCutCost, ...res.data.priceCalculatorQuantity.costDetail[index].dieCutCost] : [...res.data.priceCalculatorQuantity.costDetail[index].dieCutCost],
          embossingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].embossingCost, ...res.data.priceCalculatorQuantity.costDetail[index].embossingCost] : [...res.data.priceCalculatorQuantity.costDetail[index].embossingCost],
          foilingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].foilingCost, ...res.data.priceCalculatorQuantity.costDetail[index].foilingCost] : [...res.data.priceCalculatorQuantity.costDetail[index].foilingCost],
          gluingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].gluingCost, ...res.data.priceCalculatorQuantity.costDetail[index].gluingCost] : [...res.data.priceCalculatorQuantity.costDetail[index].gluingCost],
          paperCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].paperCost, ...res.data.priceCalculatorQuantity.costDetail[index].paperCost] : [...res.data.priceCalculatorQuantity.costDetail[index].paperCost],
          plasticWindowCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].plasticWindowCost, ...res.data.priceCalculatorQuantity.costDetail[index].plasticWindowCost] : [...res.data.priceCalculatorQuantity.costDetail[index].plasticWindowCost],
          printingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].printingCost, ...res.data.priceCalculatorQuantity.costDetail[index].printingCost] : [...res.data.priceCalculatorQuantity.costDetail[index].printingCost],
          shippingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].shippingCost, ...res.data.priceCalculatorQuantity.costDetail[index].shippingCost] : [...res.data.priceCalculatorQuantity.costDetail[index].shippingCost],
          spotUVCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].spotUVCost, ...res.data.priceCalculatorQuantity.costDetail[index].spotUVCost] : [...res.data.priceCalculatorQuantity.costDetail[index].spotUVCost],
        }))
        setFormValues({
          ...tmpForm,
          amountProduction: parseInt(amount),
          partData: formValues.partData,
          partDetail: res.data.priceCalculatorQuantity.partDetail,
          costDetail,
          totalCost: [...formValues.totalCost, ...res.data.priceCalculatorQuantity.totalCost],
          pricePerPiece: formValues.productionType === 'newOrder' ? Math.round(res.data.priceCalculatorQuantity.totalCost[0].totalCost * 100) / 100 : Math.round(res.data.priceCalculatorQuantity.totalCost[0].totalRepeatCost * 100) / 100,
          priceNewOrder: Math.round(res.data.priceCalculatorQuantity.totalCost[0].totalCost * 100) / 100,
          priceRepeat: Math.round(res.data.priceCalculatorQuantity.totalCost[0].totalRepeatCost * 100) / 100,
        })
      }
      setDisabledRequestQuot(false)
      setDisabledButton(false)
    }).catch((e) => {
      setDisabledRequestQuot(false)
      setDisabledButton(false)
    })
  }

  const handleChange = (e, refetch) => {
    const { value } = e.target
    const tmpForm = formValues
    let result
    setDisabledButton(true)
    if (parseInt(amountProduction) + 1 === parseInt(value)) {
      if (formValues.incrementalSteps === 50) {
        result = parseInt(value) - 1
      } else {
        result = Math.round(parseInt(value) / 100) * 100
      }
      setAmountProduction(result + formValues.incrementalSteps)
    } else if (parseInt(amountProduction) - 1 === parseInt(value)) {
      if (formValues.incrementalSteps === 50) {
        result = parseInt(value) + 1
      } else {
        result = Math.round(parseInt(value) / 100) * 100
      }
      setAmountProduction(result - formValues.incrementalSteps)
    } else if (parseInt(value) === formValues.minimumManufacture) {
      setFormValues({ ...tmpForm, productionType: orderType, amountProduction: formValues.minimumManufacture })
      setAmountProduction(parseInt(formValues.minimumManufacture))
      setPreviousAmountProduction(parseInt(formValues.minimumManufacture))
      handleCalulate(parseInt(formValues.minimumManufacture), refetch)
    } else {
      setAmountProduction(parseInt(value))
    }
  }

  const handleBlur = (e, refetch) => {
    const { value } = e.target
    const tmpForm = formValues
    if (parseInt(previousAmountProduction) === parseInt(value)) {
      setPreviousAmountProduction(parseInt(value))
      if (parseInt(previousAmountProduction) === formValues.minimumManufacture) {
        handleCalulate(parseInt(previousAmountProduction), refetch)
      }
      return
    }
    if (parseInt(value) < formValues.minimumManufacture) {
      setFormValues({ ...tmpForm, amountProduction: formValues.minimumManufacture })
      setAmountProduction(parseInt(formValues.minimumManufacture))
      setPreviousAmountProduction(parseInt(formValues.minimumManufacture))
      handleCalulate(parseInt(formValues.minimumManufacture), refetch)
    } else if (parseInt(value) === formValues.minimumManufacture) {
      setFormValues({ ...tmpForm, productionType: orderType, amountProduction: formValues.minimumManufacture })
      setAmountProduction(parseInt(formValues.minimumManufacture))
      setPreviousAmountProduction(parseInt(formValues.minimumManufacture))
      handleCalulate(parseInt(formValues.minimumManufacture), refetch)
    } else if (parseInt(value) >= 1000000000) {
      setFormValues({ ...tmpForm, productionType: orderType, amountProduction: 1000000000 })
      setAmountProduction(1000000000)
      setPreviousAmountProduction(1000000000)
      handleCalulate(1000000000, refetch)
    } else if (value === '') {
      setFormValues({ ...tmpForm, productionType: orderType, amountProduction: formValues.minimumManufacture })
      setAmountProduction(parseInt(formValues.minimumManufacture))
      setPreviousAmountProduction(parseInt(formValues.minimumManufacture))
      handleCalulate(parseInt(formValues.minimumManufacture), refetch)
    } else {
      const length = (1000000000 - formValues.minimumManufacture) / formValues.incrementalSteps
      const arr = Array.from({ length }, (v, i) => formValues.minimumManufacture + i * formValues.incrementalSteps)
      setFormValues({ ...tmpForm, productionType: orderType, amountProduction: parseInt(arr.find(t => t >= parseInt(value))) })
      setAmountProduction(parseInt(arr.find(t => t >= parseInt(value))))
      setPreviousAmountProduction(parseInt(arr.find(t => t >= parseInt(value))))
      handleCalulate(parseInt(arr.find(t => t >= parseInt(value))), refetch)
    }
  }

  useEffect(() => {
    setAmountProduction(formValues.amountProduction)
    const indexMinimum = quantity.reverse().find(e => e <= formValues.minimumManufacture)
    const index = quantity.reverse().indexOf(indexMinimum)
    if (totalCostDefault) {
      setDataTable([totalCostDefault, ...totalCost.slice(index + 1, totalCost.length)])
    } else {
      setDataTable(totalCost.slice(index, totalCost.length))
    }
  }, [formValues.incrementalSteps, formValues.minimumManufacture, totalCostDefault])

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 375)
  const [isMobileXL, setIsMobileXL] = useState(window.innerWidth <= 570)

  const screenSizeHandler = () => setIsMobileView(window.innerWidth <= 375)
  const screenSizeHandler2 = () => setIsMobileXL(window.innerWidth <= 570)

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    window.addEventListener('resize', screenSizeHandler2)
    return () => {
      window.removeEventListener('resize', screenSizeHandler)
      window.removeEventListener('resize', screenSizeHandler2)
    }
  }, [])

  const handleChangeProductType = (e) => {
    setOrderType(e)
    const tmpForm = formValues
    setFormValues({ ...tmpForm, productionType: e })
  }

  return (
    <NotchedContainer
      title='ตารางราคา'
      titleIcon={PriceList}
      showEditIcon={showEditIcon}
      handleEdit={handleEdit}
    >
      <Div style={{ width: '100%' }}>
        {
          firstTimeLoad ? <Div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height:
            '200px',
          }}>
            <LoadingIcon />
          </Div> : <PriceCalculateTable
            data={dataTable}
            orderType={orderType}
          />
        }
        <Div style={{ display: 'flex', paddingTop: '14px' }}>
          <Query
            query={GET_PRICE_NOTE}
            notifyOnNetworkStatusChange
          >
            {
              ({ loading, data }) => {
                if (loading) return <Div></Div>
                return (
                  <>
                    {
                      data.priceNote && data.priceNote.description && <DescriptionContent moreStyle={{ lineBreak: 'anywhere' }}>
                        {parseHtml(data.priceNote.description)}
                    </DescriptionContent>
                    }
                  </>
                )
              }
            }
          </Query>
        </Div>
        <Div style={{ paddingTop: '14px' }}>
          <Row gutter={16}>
            <Col xs={24} sm={14} md={12} span={16}>
              <OrderTypeSelect
                data={orderType}
                onChange={handleChangeProductType}
                disabled={disabledButton}
              />
            </Col>
            <Col xs={24} sm={14} md={12} span={16}>
              <Div
                style={{
                  width: '100%',
                  paddingTop: isMobileXL ? '0px' : '8px',
                  paddingLeft: isMobileXL ? '0px' : '0px',
                }}
                >
                  <DivAmount>
                    <LabelAmount>{'ระบุจำนวนการผลิต'}</LabelAmount>
                    <DivCenter style={{ padding: '16px' }}>
                    <Query
                        query={PRICE_CALCULATOR_QUANTITY}
                        fetchPolicy='network-only'
                        variables={!firstTimeLoad && {
                          input: {
                            part: formValues.partData.map((item, index) => ({
                              order: index + 1,
                              packagePartId: item.packagePartId > 0 ? item.packagePartId : null,
                              unit: item.measurementUnit.key ? item.measurementUnit.key : item.measurementUnit.label === 'ซม.' ? 'cm' : item.measurementUnit.label === 'นิ้ว' ? 'inches' : '',
                              operator: item.packagePartId > 0 ? Object.values(item.sizeList).map(x => (parseFloat(x))) : [],
                              printSizeWidth: formValues.partDataCalculate && formValues.partDataCalculate.length > 0 ? formValues.partDataCalculate[index].printSizeWidth : item.printSizeWidth ? item.printSizeWidth : 0.0,
                              printSizeLength: formValues.partDataCalculate && formValues.partDataCalculate.length > 0 ? formValues.partDataCalculate[index].printSizeLength : item.printSizeLength ? item.printSizeLength : 0.0,
                              paper: {
                                paperId: item.paper.paperId,
                                thickness: parseFloat(item.paper.selectedThickness),
                              },
                              printing: (item.printing.printingName && item.printing.printingBasicColorId) ? {
                                side: item.printing.printingTypeName === 'พิมพ์หน้าเดียว' ? 1 : item.printing.printingTypeName === 'พิมพ์สองหน้า' ? 2 : null,
                                printingBasicColorId: item.printing.printingBasicColorId,
                                printingExtraColorId: item.printing.printingExtraColorId.filter(x => x.printingExtraColorId).map(x => x.printingExtraColorId),
                              } : null,
                              coating: {
                                coatingIdFront: item.coating.side === 1 || item.coating.side === 2 ? item.coating.coatingFrontId : null,
                                coatingIdBehind: item.coating.side === -1 || item.coating.side === 2 ? item.coating.coatingBackId : null,
                              },
                              spotUV: {
                                spotUVIdFront: item.spotUV.spotUVFrontId,
                                spotUVIdBehind: item.spotUV.spotUVBackId,
                              },
                              embossing: item.embossing.map((x, i) => ({
                                order: i + 1,
                                width: parseFloat(x.width),
                                length: parseFloat(x.lengths),
                                unit: item.measurementUnit.key ? item.measurementUnit.key : item.measurementUnit.label === 'ซม.' ? 'cm' : item.measurementUnit.label === 'นิ้ว' ? 'inches' : '',
                              })),
                              foiling: {
                                foilingId: item.foilingId !== 0 ? item.foilingId : null,
                                foilingPart: item.foiling.map((x, i) => ({
                                  order: i + 1,
                                  width: parseFloat(x.width),
                                  length: parseFloat(x.lengths),
                                  unit: item.measurementUnit.key ? item.measurementUnit.key : item.measurementUnit.label === 'ซม.' ? 'cm' : item.measurementUnit.label === 'นิ้ว' ? 'inches' : '',
                                })),
                              },
                              dieCut: item.dieCut,
                              plasticWindow: item.plasticWindow.map((x, i) => ({
                                order: i + 1,
                                width: parseFloat(x.width),
                                length: parseFloat(x.lengths),
                                thickness: x.thickness,
                                unit: item.measurementUnit.key ? item.measurementUnit.key : item.measurementUnit.label === 'ซม.' ? 'cm' : item.measurementUnit.label === 'นิ้ว' ? 'inches' : '',
                              })),
                              gluingId: item.gluingId ? item.gluingId : typeof item.gluing === 'number' ? item.gluing === 0 ? null : item.gluing : typeof item.gluing === 'object' && item.gluing ? item.gluing.gluingId : item.gluing ? item.gluing : null,
                              additionalServiceId: (item.additionalServiceId && item.additionalServiceId.length > 0) ? item.additionalServiceId.filter(x => x).map(x => x.additionalServiceId || x.value) || [] : item.additionalService ? item.additionalService.map(x => x.additionalServiceId || x.value) || [] : [],
                            })),
                            // quantity: parseInt(formValues.minimumManufacture),
                            quantity: parseInt(formValues.minimumManufacture) !== parseInt(formValues.amountProduction) ? parseInt(formValues.amountProduction) : parseInt(formValues.minimumManufacture),
                          },
                        }}
                        onCompleted={(data) => {
                          if (!firstTimeLoad && data) {
                            const tmpForm = formValues
                            const costDetail = tmpForm.costDetail.map((item, index) => ({
                              additionalServiceCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].additionalServiceCost, ...data.priceCalculatorQuantity.costDetail[index].additionalServiceCost] : [...data.priceCalculatorQuantity.costDetail[index].additionalServiceCost],
                              coatingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].coatingCost, ...data.priceCalculatorQuantity.costDetail[index].coatingCost] : [...data.priceCalculatorQuantity.costDetail[index].coatingCost],
                              dieCutCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].dieCutCost, ...data.priceCalculatorQuantity.costDetail[index].dieCutCost] : [...data.priceCalculatorQuantity.costDetail[index].dieCutCost],
                              embossingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].embossingCost, ...data.priceCalculatorQuantity.costDetail[index].embossingCost] : [...data.priceCalculatorQuantity.costDetail[index].embossingCost],
                              foilingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].foilingCost, ...data.priceCalculatorQuantity.costDetail[index].foilingCost] : [...data.priceCalculatorQuantity.costDetail[index].foilingCost],
                              gluingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].gluingCost, ...data.priceCalculatorQuantity.costDetail[index].gluingCost] : [...data.priceCalculatorQuantity.costDetail[index].gluingCost],
                              paperCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].paperCost, ...data.priceCalculatorQuantity.costDetail[index].paperCost] : [...data.priceCalculatorQuantity.costDetail[index].paperCost],
                              plasticWindowCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].plasticWindowCost, ...data.priceCalculatorQuantity.costDetail[index].plasticWindowCost] : [...data.priceCalculatorQuantity.costDetail[index].plasticWindowCost],
                              printingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].printingCost, ...data.priceCalculatorQuantity.costDetail[index].printingCost] : [...data.priceCalculatorQuantity.costDetail[index].printingCost],
                              shippingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].shippingCost, ...data.priceCalculatorQuantity.costDetail[index].shippingCost] : [...data.priceCalculatorQuantity.costDetail[index].shippingCost],
                              spotUVCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].spotUVCost, ...data.priceCalculatorQuantity.costDetail[index].spotUVCost] : [...data.priceCalculatorQuantity.costDetail[index].spotUVCost],
                            }))
                            setFormValues({
                              ...tmpForm,
                              partData: formValues.partData,
                              partDetail: data.priceCalculatorQuantity.partDetail,
                              costDetail,
                              totalCost: [...formValues.totalCost, ...data.priceCalculatorQuantity.totalCost],
                              pricePerPiece: formValues.productionType === 'newOrder' ? Math.round(data.priceCalculatorQuantity.totalCost[0].totalCost * 100) / 100 : Math.round(data.priceCalculatorQuantity.totalCost[0].totalRepeatCost * 100) / 100,
                              priceNewOrder: Math.round(data.priceCalculatorQuantity.totalCost[0].totalCost * 100) / 100,
                              priceRepeat: Math.round(data.priceCalculatorQuantity.totalCost[0].totalRepeatCost * 100) / 100,
                            })
                            // setFirstTimeLoad(false)
                          }
                        }}
                      >
                        {
                          () => (
                            <>
                            </>
                          )
                        }
                    </Query>
                      <Query
                        query={PRICE_CALCULATOR_QUANTITY}
                        fetchPolicy='network-only'
                        variables={firstTimeLoad && {
                          input: {
                            part: formValues.partData.map((item, index) => ({
                              order: index + 1,
                              packagePartId: item.packagePartId > 0 ? item.packagePartId : null,
                              unit: item.measurementUnit.key ? item.measurementUnit.key : item.measurementUnit.label === 'ซม.' ? 'cm' : item.measurementUnit.label === 'นิ้ว' ? 'inches' : '',
                              operator: item.packagePartId > 0 ? Object.values(item.sizeList).map(x => (parseFloat(x))) : [],
                              printSizeWidth: formValues.partDataCalculate && formValues.partDataCalculate.length > 0 ? formValues.partDataCalculate[index].printSizeWidth : item.printSizeWidth ? item.printSizeWidth : 0.0,
                              printSizeLength: formValues.partDataCalculate && formValues.partDataCalculate.length > 0 ? formValues.partDataCalculate[index].printSizeLength : item.printSizeLength ? item.printSizeLength : 0.0,
                              paper: {
                                paperId: item.paper.paperId,
                                thickness: parseFloat(item.paper.selectedThickness),
                              },
                              printing: (item.printing.printingName && item.printing.printingBasicColorId) ? {
                                side: item.printing.printingTypeName === 'พิมพ์หน้าเดียว' ? 1 : item.printing.printingTypeName === 'พิมพ์สองหน้า' ? 2 : null,
                                printingBasicColorId: item.printing.printingBasicColorId,
                                printingExtraColorId: item.printing.printingExtraColorId.filter(x => x.printingExtraColorId).map(x => x.printingExtraColorId),
                              } : null,
                              coating: {
                                coatingIdFront: item.coating.side === 1 || item.coating.side === 2 ? item.coating.coatingFrontId : null,
                                coatingIdBehind: item.coating.side === -1 || item.coating.side === 2 ? item.coating.coatingBackId : null,
                              },
                              spotUV: {
                                spotUVIdFront: item.spotUV.spotUVFrontId,
                                spotUVIdBehind: item.spotUV.spotUVBackId,
                              },
                              embossing: item.embossing.map((x, i) => ({
                                order: i + 1,
                                width: parseFloat(x.width),
                                length: parseFloat(x.lengths),
                                unit: item.measurementUnit.key ? item.measurementUnit.key : item.measurementUnit.label === 'ซม.' ? 'cm' : item.measurementUnit.label === 'นิ้ว' ? 'inches' : '',
                              })),
                              foiling: {
                                foilingId: item.foilingId !== 0 ? item.foilingId : null,
                                foilingPart: item.foiling.map((x, i) => ({
                                  order: i + 1,
                                  width: parseFloat(x.width),
                                  length: parseFloat(x.lengths),
                                  unit: item.measurementUnit.key ? item.measurementUnit.key : item.measurementUnit.label === 'ซม.' ? 'cm' : item.measurementUnit.label === 'นิ้ว' ? 'inches' : '',
                                })),
                              },
                              dieCut: item.dieCut,
                              plasticWindow: item.plasticWindow.map((x, i) => ({
                                order: i + 1,
                                width: parseFloat(x.width),
                                length: parseFloat(x.lengths),
                                thickness: x.thickness,
                                unit: item.measurementUnit.key ? item.measurementUnit.key : item.measurementUnit.label === 'ซม.' ? 'cm' : item.measurementUnit.label === 'นิ้ว' ? 'inches' : '',
                              })),
                              gluingId: item.gluingId ? item.gluingId : typeof item.gluing === 'number' ? item.gluing === 0 ? null : item.gluing : typeof item.gluing === 'object' && item.gluing ? item.gluing.gluingId : item.gluing ? item.gluing : null,
                              additionalServiceId: (item.additionalServiceId && item.additionalServiceId.length > 0) ? item.additionalServiceId.filter(x => x).map(x => x.additionalServiceId || x.value) || [] : item.additionalService ? item.additionalService.map(x => x.additionalServiceId || x.value) || [] : [],
                            })),
                            quantity: parseInt(formValues.minimumManufacture),
                            // quantity: parseInt(formValues.minimumManufacture) !== parseInt(formValues.amountProduction) ? parseInt(formValues.amountProduction) : parseInt(formValues.minimumManufacture),
                          },
                        }}
                        onCompleted={(data) => {
                          if (firstTimeLoad && data) {
                            if (parseInt(formValues.minimumManufacture) !== parseInt(formValues.amountProduction)) {
                              // setTotalCostDefault(formValues.totalCost.find(x => x.quantity === parseInt(formValues.minimumManufacture)))
                              setTotalCostDefault(data.priceCalculatorQuantity.totalCost[0])
                            } else {
                              setTotalCostDefault(data.priceCalculatorQuantity.totalCost[0])
                            }
                            const tmpForm = formValues
                            const costDetail = tmpForm.costDetail.map((item, index) => ({
                              additionalServiceCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].additionalServiceCost, ...data.priceCalculatorQuantity.costDetail[index].additionalServiceCost] : [...data.priceCalculatorQuantity.costDetail[index].additionalServiceCost],
                              coatingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].coatingCost, ...data.priceCalculatorQuantity.costDetail[index].coatingCost] : [...data.priceCalculatorQuantity.costDetail[index].coatingCost],
                              dieCutCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].dieCutCost, ...data.priceCalculatorQuantity.costDetail[index].dieCutCost] : [...data.priceCalculatorQuantity.costDetail[index].dieCutCost],
                              embossingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].embossingCost, ...data.priceCalculatorQuantity.costDetail[index].embossingCost] : [...data.priceCalculatorQuantity.costDetail[index].embossingCost],
                              foilingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].foilingCost, ...data.priceCalculatorQuantity.costDetail[index].foilingCost] : [...data.priceCalculatorQuantity.costDetail[index].foilingCost],
                              gluingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].gluingCost, ...data.priceCalculatorQuantity.costDetail[index].gluingCost] : [...data.priceCalculatorQuantity.costDetail[index].gluingCost],
                              paperCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].paperCost, ...data.priceCalculatorQuantity.costDetail[index].paperCost] : [...data.priceCalculatorQuantity.costDetail[index].paperCost],
                              plasticWindowCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].plasticWindowCost, ...data.priceCalculatorQuantity.costDetail[index].plasticWindowCost] : [...data.priceCalculatorQuantity.costDetail[index].plasticWindowCost],
                              printingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].printingCost, ...data.priceCalculatorQuantity.costDetail[index].printingCost] : [...data.priceCalculatorQuantity.costDetail[index].printingCost],
                              shippingCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].shippingCost, ...data.priceCalculatorQuantity.costDetail[index].shippingCost] : [...data.priceCalculatorQuantity.costDetail[index].shippingCost],
                              spotUVCost: tmpForm.costDetail.length > 0 ? [...tmpForm.costDetail[index].spotUVCost, ...data.priceCalculatorQuantity.costDetail[index].spotUVCost] : [...data.priceCalculatorQuantity.costDetail[index].spotUVCost],
                            }))
                            setFormValues({
                              ...tmpForm,
                              partData: formValues.partData,
                              partDetail: data.priceCalculatorQuantity.partDetail,
                              costDetail,
                              totalCost: [...formValues.totalCost, ...data.priceCalculatorQuantity.totalCost],
                              pricePerPiece: formValues.productionType === 'newOrder' ? Math.round(data.priceCalculatorQuantity.totalCost[0].totalCost * 100) / 100 : Math.round(data.priceCalculatorQuantity.totalCost[0].totalRepeatCost * 100) / 100,
                              priceNewOrder: Math.round(data.priceCalculatorQuantity.totalCost[0].totalCost * 100) / 100,
                              priceRepeat: Math.round(data.priceCalculatorQuantity.totalCost[0].totalRepeatCost * 100) / 100,
                            })
                            setFirstTimeLoad(false)
                          }
                        }}
                      >
                        {
                          ({ refetch }) => (
                            <DivInput>
                              <InputAmount
                                type="number"
                                value={amountProduction}
                                // suffix="ชิ้น"
                                suffix={<span>ชิ้น</span>}
                                onChange={e => handleChange(e, refetch)}
                                onBlur={e => handleBlur(e, refetch)}
                                min={formValues.minimumManufacture}
                                max={1000000000}
                                pattern="[0-9]"
                              />
                            </DivInput>
                          )
                        }
                    </Query>
                    </DivCenter>
                  </DivAmount>
                  <DivCenter style={{ paddingTop: '16px' }}>
                    {
                      isQuotationEdit ? <Mutation
                        mutation={UPDATE_BOX_DETAIL_QUOTATION}
                      >
                        {
                            editBoxDetail => (
                              <Popconfirm
                                title='ต้องการบันทึกข้อมูลนี้ใช้หรือไม่'
                                onConfirm={() => {
                                  // EDIT QUOTATION
                                  const input = {
                                    name: packageValues.name,
                                  }
                                  input.isPreDefine = true
                                  input.partDetail = formValues.partDetail.map(item => _.omit(item, ['__typename'])).map((item, index) => ({
                                    ...item,
                                    packagePartId: formValues.partData[index].packagePartId,
                                    unitSize: formValues.partData[index].measurementUnit.key,
                                    sizeA: formValues.partData[index].sizeList.a,
                                    sizeB: formValues.partData[index].sizeList.b,
                                    sizeC: formValues.partData[index].sizeList.c,
                                    sizeD: formValues.partData[index].sizeList.d,
                                    sizeE: formValues.partData[index].sizeList.e,
                                    sizeF: formValues.partData[index].sizeList.f,
                                    sizeG: formValues.partData[index].sizeList.g,
                                    sizeH: formValues.partData[index].sizeList.h,
                                    sizeI: formValues.partData[index].sizeList.i,
                                    sizeJ: formValues.partData[index].sizeList.j,
                                    detailActualPrinter: {
                                      ..._.omit(item.detailActualPrinter, ['__typename', 'maxYield']),
                                      paperId: formValues.partData[index].paper && formValues.partData[index].paper.paperId ? formValues.partData[index].paper.paperId : null,
                                    },
                                    detailPrintingCost: item.detailPrintingCost && {
                                      ..._.omit(item.detailPrintingCost, ['__typename']),
                                      printingBasicColorId: formValues.partData[index].printing && formValues.partData[index].printing.printingBasicColorId ? formValues.partData[index].printing.printingBasicColorId : null,
                                      printingExtraColor: formValues.partData[index].printing && formValues.partData[index].printing.printingExtraColorId.length > 0 ? formValues.partData[index].printing.printingExtraColorId.map(x => ({ id: x.printingExtraColorId, name: x.printingExtraColorName })) : [],
                                    },
                                    detailCoatingCost: {
                                      ..._.omit(item.detailCoatingCost, ['__typename']),
                                      coatingFrontId: formValues.partData[index].coating && formValues.partData[index].coating.coatingFrontId ? formValues.partData[index].coating.coatingFrontId : null,
                                      coatingBehindId: formValues.partData[index].coating && formValues.partData[index].coating.coatingBackId ? formValues.partData[index].coating.coatingBackId : null,
                                    },
                                    detailSpotUVCost: {
                                      ..._.omit(item.detailSpotUVCost, ['__typename']),
                                      spotUVFrontId: formValues.partData[index].spotUV && formValues.partData[index].spotUV.spotUVFrontId ? formValues.partData[index].spotUV.spotUVFrontId : null,
                                      spotUVBehindId: formValues.partData[index].spotUV && formValues.partData[index].spotUV.spotUVBackId ? formValues.partData[index].spotUV.spotUVBackId : null,
                                    },
                                    detailFoilingCost: item.detailFoilingCost ? {
                                      foiling: item.detailFoilingCost.foiling,
                                      foilingPart: item.detailFoilingCost.foilingPart.map(v => _.omit(v, ['__typename'])),
                                      foilingId: formValues.partData[index].foilingId ? formValues.partData[index].foilingId : null,
                                    } : null,
                                    detailGluingCost: item.detailGluingCost && {
                                      gluingId: formValues.partData[index].gluing ? formValues.partData[index].gluing.gluingId : null,
                                      gluing: formValues.partData[index].gluing ? formValues.partData[index].gluing.name : null,
                                    },
                                    detailPrinter: _.omit(item.detailPrinter, ['__typename']),
                                    detailSize: _.omit(item.detailSize, ['__typename']),
                                    detailEmbossingCost: item.detailEmbossingCost.map(v => _.omit(v, ['__typename'])),
                                    detailPlasticWindowCost: item.detailPlasticWindowCost.map(v => _.omit(v, ['__typename'])),
                                    detailAdditionalServiceCost: formValues.partData[index].additionalService.length > 0 ? formValues.partData[index].additionalService.map(x => ({ additionalServiceId: x.additionalServiceId, name: x.name })) : [],
                                  }))
                                  const indexMinimum = quantity.reverse().find(e => e <= formValues.amountProduction)
                                  const index = quantity.reverse().indexOf(indexMinimum)
                                  input.totalCost = [totalCostDefault, ...totalCost.slice(index + 1, totalCost.length)].filter(x => x).map(item => _.omit(item, ['__typename', 'marginRate', 'reserveQuantity', 'solution', 'textSolution', 'repeatSolution', 'rawCost', 'rawRepeatCost']))
                                  const priceData = [totalCostDefault, ...totalCost.slice(index + 1, totalCost.length)].filter(x => x).find(x => x.quantity === formValues.amountProduction)
                                  input.price = formValues.productionType === 'newOrder' ? Math.round(priceData.totalCost * 100) / 100 : Math.round(priceData.totalRepeatCost * 100) / 100
                                  input.sampleFiles = formValues.graphicFile
                                  input.produceType = orderType === 'newOrder' ? 'สั่งผลิตใหม่' : 'สั่งผลิตซ้ำ'
                                  input.produceQuantity = formValues.amountProduction || 0
                                  input.printingDescription = formValues.moreDetail
                                  input.isGraphic = formValues.graphicName || 'ไม่ใช้บริการ'
                                  editBoxDetail({
                                    variables: {
                                      input,
                                      id: packageValues.quotationId,
                                    },
                                  }).then((res) => {
                                    if (res.data) {
                                      if (res.data.editBoxDetail.success) {
                                        message.success(res.data.editBoxDetail.message)
                                        setTimeout(() => {
                                          window.location = `${PATH.quotationDetail}/${packageValues.quotationId}`
                                        }, 1000)
                                      } else {
                                        message.error(res.data.editBoxDetail.message)
                                      }
                                    }
                                    setDisabledButton(false)
                                  })
                                }}
                                okText='ใช่'
                                cancelText='ไม่ใช่'
                              >
                                <Button
                                  text={'ยืนยัน'}
                                  disabled={disabledButton}
                                  loading={disabledButton}
                                  width='100%'
                                />
                              </Popconfirm>
                            )
                        }
                    </Mutation> : <Button
                        text={!disabledButton ? <SpanButton><img style={{ width: '20px', opacity: disabledButton ? '0.2' : '1' }} src={PriceDetails} /> แจกแจงข้อมูลราคา</SpanButton> : 'แจกแจงข้อมูลราคา'}
                        onClick={() => setIsModalVisible(true)}
                        disabled={disabledButton}
                        loading={disabledButton}
                        style={{ minWidth: '189px' }}
                      />
                    }
                  </DivCenter>
                </Div>
            </Col>
          </Row>
        </Div>
      </Div>
      <Modal
        title={<ModalTitle>ข้อมูลแจกแจงราคา</ModalTitle>}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        okText="ปิด"
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <DivPricing>
          {
            formValues.partDetail.map((item, index) => {
              const totalCostList = formValues.totalCost.sort((a, b) => a.quantity - b.quantity)
              const indexMinimum = totalCostList.reverse().find(e => e.quantity <= formValues.amountProduction)
              const qIndex = totalCostList.reverse().indexOf(indexMinimum)
              const produceAmount = totalCostList[qIndex].quantity
              return (
              <Div key={index}>
                <H4 bold>{item.packagePartName || `ชิ้นส่วนที่ ${index + 1}` }</H4>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextTitle>กระดาษ</TextTitle>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>ขนาดชิ้นส่วน กว้างxยาว</TextLabel>
                  <TextValue>{item.detailSize.width || 0} x {item.detailSize.length || 0} {item.detailSize.unit === 'cm' ? 'ซม.' : 'นิ้ว' }</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>ขนาดกระดาษ กว้างxยาว</TextLabel>
                  <TextValue>{item.detailActualPrinter.paperWidth || 0} x {item.detailActualPrinter.paperLength || 0} {item.detailSize.unit === 'cm' ? 'ซม.' : 'นิ้ว' }</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>Maximum Yield</TextLabel>
                  <TextValue>{item.detailActualPrinter.maxYield || 0} ชิ้น</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>ขนาดใบพิมพ์ (Actual printing size)</TextLabel>
                  <TextValue>{item.detailActualPrinter.actualWidth || 0} x {item.detailActualPrinter.actualLength || 0} {item.detailSize.unit === 'cm' ? 'ซม.' : 'นิ้ว' }</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>ประเภทกระดาษ</TextLabel>
                  <TextValue>{item.detailActualPrinter.paper || '-'}</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>ความหนากระดาษ</TextLabel>
                  <TextValue>{item.detailActualPrinter.paperThickness || 0} แกรม</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>จำนวนสำรอง</TextLabel>
                  <TextValue>{formValues.totalCost ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                    ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).reserveQuantity : 0 : 0} ชิ้น</TextValue>
                </Div>
                {
                  orderType === 'newOrder' ? <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].paperCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].paperCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].paperCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].paperCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).solution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคากระดาษต่อจำนวนการผลิตใหม่ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].paperCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].paperCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).paperCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div> : <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].paperCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].paperCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].paperCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].paperCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).repeatSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคากระดาษต่อจำนวนการผลิตซ้ำ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].paperCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].paperCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).paperRepeatCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                }
                <br/>

                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextTitle>การพิมพ์</TextTitle>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>ลักษณะการพิมพ์</TextLabel>
                  <TextValue>{item.detailPrintingCost ? item.detailPrintingCost.side === 1 ? 'การพิมพ์หน้าเดียว' : 'การพิมพ์สองหน้า' : 'ไม่พิมพ์ลวดลาย'}</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>รูปแบบสีการพิมพ์</TextLabel>
                  <TextValue>{item.detailPrintingCost ? item.detailPrintingCost.printingBasicColor ? item.detailPrintingCost.printingBasicColor : '-' : '-'}</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>สีพิเศษ</TextLabel>
                  <TextValue>{item.detailPrintingCost ? item.detailPrintingCost.printingExtraColor.length > 0 ? item.detailPrintingCost.printingExtraColor.map(x => `${x} `) : '-' : '-'}</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>เครื่องพิมพ์</TextLabel>
                  <TextValue>{item.detailPrinter.printer === 'printer 5' ? 'เครื่องพิมพ์ตัด 5' : 'เครื่องพิมพ์ตัด 3'}</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>ขนาดเครื่องพิมพ์</TextLabel>
                  <TextValue>{item.detailPrinter.width || 0} x {item.detailPrinter.length || 0} นิ้ว</TextValue>
                </Div>
                {
                  orderType === 'newOrder' ? <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].printingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].printingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].printingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].printingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).solution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการพิมพ์ต่อจำนวนการผลิตใหม่ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].printingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].printingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).printingCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                    : <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].printingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].printingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].printingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].printingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).repeatSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการพิมพ์ต่อจำนวนการผลิตซ้ำ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].printingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].printingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).printingRepeatCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                }
                <br/>

                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextTitle>การเคลือบผิว</TextTitle>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>การเคลือบผิวด้านหน้า</TextLabel>
                  <TextValue>{item.detailCoatingCost.coatingFront || 'ไม่ใช้บริการ' }</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>ต้นทุนขั้นต่ำการเคลือบผิวด้านหน้า</TextLabel>
                  <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                    ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).coatingFrontMinimumCost : '-' : '-'} บาท</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>การเคลือบผิวด้านหลัง</TextLabel>
                  <TextValue>{item.detailCoatingCost.coatingBehind || 'ไม่ใช้บริการ' }</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>ต้นทุนขั้นต่ำการเคลือบผิวด้านหลัง</TextLabel>
                  <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                    ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).coatingBehindMinimumCost : '-' : '-'} บาท</TextValue>
                </Div>
                {
                  orderType === 'newOrder' ? <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).solution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการเคลือบผิวต่อจำนวนการผลิตใหม่ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).coatingCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                    : <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).repeatSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการเคลือบผิวต่อจำนวนการผลิตซ้ำ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].coatingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).coatingRepeatCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                }
                <br/>

                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextTitle>การเคลือบยูวีเฉพาะจุด</TextTitle>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>การเคลือบยูวีเฉพาะจุดด้านหน้า</TextLabel>
                  <TextValue>{item.detailSpotUVCost.spotUVFront || 'ไม่ใช้บริการ' }</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>ต้นทุนขั้นต่ำการเคลือบยูวีเฉพาะจุดด้านหน้า</TextLabel>
                  <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                    ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).spotUVFrontMinimumCost : '-' : '-'} บาท</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>การเคลือบยูวีเฉพาะจุดด้านหลัง</TextLabel>
                  <TextValue>{item.detailSpotUVCost.spotUVBehind || 'ไม่ใช้บริการ' }</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>ต้นทุนขั้นต่ำการเคลือบยูวีเฉพาะจุดด้านหลัง</TextLabel>
                  <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                    ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).spotUVBehindMinimumCost : '-' : '-'} บาท</TextValue>
                </Div>
                {
                  orderType === 'newOrder' ? <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).solution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการเคลือบยูวีเฉพาะจุดต่อจำนวนการผลิตใหม่ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).spotUVCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                    : <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).repeatSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการเคลือบยูวีเฉพาะจุดต่อจำนวนการผลิตซ้ำ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].spotUVCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).spotUVRepeatCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                }
                <br/>

                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextTitle>การปั๊มนูน</TextTitle>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>การปั้มนูน</TextLabel>
                  <TextValue>{item.detailEmbossingCost.length > 0 ? 'ใช้บริการ' : 'ไม่ใช้บริการ' }</TextValue>
                </Div>
                {
                  item.detailEmbossingCost.length > 0 && <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                    <TextLabel>ต้นทุนขั้นต่ำของแม่พิมพ์ปั๊มนูน</TextLabel>
                    <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].embossingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                      ? formValues.costDetail[index].embossingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).minimumCost : '-' : '-'} บาท</TextValue>
                  </Div>
                }
                {
                  item.detailEmbossingCost.map((v, idx) => (
                    <Div key={idx} display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ตำแหน่งที่ {v.order} (กว้างxยาว)</TextLabel>
                      <TextValue>{v.unit === 'cm' && item.detailSize.unit === 'inches' ? (parseFloat(v.width) / 2.54) : v.width}x{v.unit === 'cm' && item.detailSize.unit === 'inches' ? (parseFloat(v.length) / 2.54) : v.length} {item.detailSize.unit === 'cm' ? 'ซม.' : 'นิ้ว' }</TextValue>
                    </Div>
                  ))
                }
                {
                  orderType === 'newOrder' ? <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].embossingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].embossingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].embossingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].embossingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).solution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการปั้มนูนต่อจำนวนการผลิตใหม่ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].embossingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].embossingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).embossingCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                    : <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].embossingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].embossingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].embossingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].embossingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).repeatSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการปั้มนูนต่อจำนวนการผลิตซ้ำ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].embossingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].embossingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).embossingRepeatCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                }
                <br/>

                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextTitle>การปั๊มฟอยล์-ทองเค</TextTitle>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>การปั๊มฟอยล์-ทองเค</TextLabel>
                  <TextValue>{item.detailFoilingCost ? item.detailFoilingCost.foiling : 'ไม่ใช้บริการ' }</TextValue>
                </Div>
                {
                  item.detailFoilingCost && <><Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ต้นทุนขั้นต่ำของแม่พิมพ์ปั๊มฟอยล์-ทองเค</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).minimumBlockCost : '-' : '-'} บาท</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ต้นทุนขั้นต่ำของฟอยล์-ทองเค</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).minimumCost : '-' : '-'} บาท</TextValue>
                    </Div>
                  </>
                }
                {
                  item.detailFoilingCost && item.detailFoilingCost.foilingPart.map((v, idx) => (
                    <Div key={idx} display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ตำแหน่งที่ {v.order}</TextLabel>
                      <TextValue>{v.unit === 'cm' && item.detailSize.unit === 'inches' ? (parseFloat(v.width) / 2.54) : v.width}x{v.unit === 'cm' && item.detailSize.unit === 'inches' ? (parseFloat(v.length) / 2.54) : v.length} {item.detailSize.unit === 'cm' ? 'ซม.' : 'นิ้ว' }</TextValue>
                    </Div>
                  ))
                }
                {
                  orderType === 'newOrder' ? <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).solution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการปั้มฟอยล์-ทองเคต่อจำนวนการผลิตใหม่ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).foilingCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                    : <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).repeatSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการปั้มฟอยล์-ทองเคต่อจำนวนการผลิตซ้ำ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].foilingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).foilingRepeatCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                }
                <br/>

                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextTitle>การเจาะหน้าต่างและติดแผ่นใส</TextTitle>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>การเจาะหน้าต่างและติดแผ่นใส</TextLabel>
                  <TextValue>{item.detailPlasticWindowCost.length > 0 ? 'ใช้บริการ' : 'ไม่ใช้บริการ' }</TextValue>
                </Div>
                {
                  item.detailPlasticWindowCost.map((v, idx) => (<Div key={idx}>
                      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>ตำแหน่งที่ {v.order} (กว้างxยาว)</TextLabel>
                        <TextValue>{v.width ? v.unit === 'cm' && item.detailSize.unit === 'inches' ? (parseFloat(v.width) / 2.54) : v.width : '-'}x{v.length ? v.unit === 'cm' && item.detailSize.unit === 'inches' ? (parseFloat(v.length) / 2.54) : v.length : '-'} {item.detailSize.unit === 'cm' ? 'ซม.' : 'นิ้ว' } หนา {v.thickness}</TextValue>
                      </Div>
                      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>ต้นทุนขั้นต่ำของแผ่นใส ตำแหน่งที่ {v.order} ({v.thickness})</TextLabel>
                        <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                          ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).minimumPlasticCost[idx] : '-' : '-'} บาท</TextValue>
                      </Div>
                      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>ต้นทุนขั้นต่ำของการติดแผ่นใส ตำแหน่งที่ {v.order} ({v.thickness})</TextLabel>
                        <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                          ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).minimumAttachmentCost[idx] : '-' : '-'} บาท</TextValue>
                      </Div>
                    </Div>
                  ))
                }
                {
                  orderType === 'newOrder' ? <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).solution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการเจาะหน้าต่างและติดแผ่นใสต่อจำนวนการผลิตใหม่ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).plasticWindowCost : 0 : 0} บาท</TextValue>
                    </Div>
                </Div>
                    : <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).repeatSlution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการเจาะหน้าต่างและติดแผ่นใสต่อจำนวนการผลิตซ้ำ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].plasticWindowCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).plasticWindowRepeatCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                }
                <br/>

                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextTitle>การติดกาว</TextTitle>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>การติดกาว</TextLabel>
                  <TextValue>{item.detailGluingCost ? item.detailGluingCost : 'ไม่ติดกาว' }</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>ต้นทุนขั้นต่ำการติดกาว</TextLabel>
                  <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].gluingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                    ? formValues.costDetail[index].gluingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).minimumCost : '-' : '-'} บาท</TextValue>
                </Div>
                {
                  orderType === 'newOrder' ? <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].gluingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].gluingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].gluingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].gluingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).solution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการติดกาวต่อจำนวนการผลิตใหม่ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].gluingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].gluingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).gluingCost : 0 : 0} บาท</TextValue>
                    </Div>
                </Div>
                    : <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].gluingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].gluingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].gluingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].gluingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).repeatSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการติดกาวต่อจำนวนการผลิตซ้ำ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].gluingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].gluingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).gluingRepeatCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                }
                <br/>

                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextTitle>การปั๊มตัด</TextTitle>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>การปั้มตัด</TextLabel>
                  <TextValue>{item.detailDieCutCost ? 'ใช้บริการ' : 'ไม่ใช้บริการ' }</TextValue>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>ต้นทุนขั้นต่ำของแม่พิมพ์ใบมีด</TextLabel>
                  <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].dieCutCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                    ? formValues.costDetail[index].dieCutCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).minimumCost : '-' : '-'} บาท</TextValue>
                </Div>
                {
                  orderType === 'newOrder' ? <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].dieCutCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].dieCutCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].dieCutCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].dieCutCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).solution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการปั้มตัดต่อจำนวนการผลิตใหม่ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].dieCutCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].dieCutCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).dieCutCost : 0 : 0} บาท</TextValue>
                    </Div>
                </Div>
                    : <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].dieCutCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].dieCutCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].dieCutCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].dieCutCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).repeatSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการปั้มตัดต่อจำนวนการผลิตซ้ำ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].dieCutCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].dieCutCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).dieCutRepeatCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                }
                <br/>

                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextTitle>บริการเสริม</TextTitle>
                </Div>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>บริการเสริม</TextLabel>
                  <TextValue>{item.detailAdditionalServiceCost.length > 0 ? 'ใช้บริการ' : 'ไม่ใช้บริการ' }</TextValue>
                </Div>
                {
                  item.detailAdditionalServiceCost.map((v, idx) => (<Div key={idx}>
                      <Div key={idx} display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>บริการเสริมที่ {idx + 1}</TextLabel>
                        <TextValue>{v}</TextValue>
                      </Div>
                      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>ต้นทุนขั้นต่ำของ บริการเสริมที่ {idx + 1}</TextLabel>
                        <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].additionalServiceCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                          ? formValues.costDetail[index].additionalServiceCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).minimumCost[idx] : '-' : '-'} บาท</TextValue>
                      </Div>
                    </Div>
                  ))
                }

                {
                  orderType === 'newOrder' ? <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].additionalServiceCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].additionalServiceCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].additionalServiceCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].additionalServiceCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).solution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการบริการเสริมจำนวนการผลิตใหม่ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].additionalServiceCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].additionalServiceCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).additionalServiceCost : 0 : 0} บาท</TextValue>
                    </Div>
                </Div>
                    : <Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].additionalServiceCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].additionalServiceCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].additionalServiceCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].additionalServiceCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).repeatSolution : '-' : '-'}</TextValue>
                    </Div>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการบริการเสริมจำนวนการผลิตซ้ำ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].additionalServiceCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].additionalServiceCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).additionalServiceRepeatCost : 0 : 0} บาท</TextValue>
                    </Div>
                  </Div>
                }
                <br/>

                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextTitle>บริการจัดส่ง</TextTitle>
                </Div>
                {
                  orderType === 'newOrder' ? <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                  <TextLabel>ราคาการบริการจัดส่งจำนวนการผลิตใหม่ {formValues.amountProduction} ชิ้น</TextLabel>
                  <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].shippingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                    ? formValues.costDetail[index].shippingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).shippingCost : 0 : 0} บาท</TextValue>
                </Div>
                    : <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                      <TextLabel>ราคาการบริการจัดส่งจำนวนการผลิตซ้ำ {formValues.amountProduction} ชิ้น</TextLabel>
                      <TextValue>{formValues.costDetail[index] ? formValues.costDetail[index].shippingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                        ? formValues.costDetail[index].shippingCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).shippingRepeatCost : 0 : 0} บาท</TextValue>
                    </Div>
                }
                <br/>
                <hr/>
                {
                  formValues.partDetail.length - 1 === index && <Div>
                  <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                    <TextTitle>สรุปการคำนวณราคาต่อจำนวน{orderType === 'newOrder' ? 'การผลิตใหม่' : 'การผลิตซ้ำ'} {formValues.amountProduction} ชิ้น</TextTitle>
                  </Div>
                  <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                    <TextLabel>Margin Rate</TextLabel>
                    <TextValue>{formValues.totalCost ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                      ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).marginRate : 0 : 0}</TextValue>
                  </Div>
                  {
                    orderType === 'newOrder' ? <Div>
                      <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>สูตรการคำนวณ</TextLabel>
                        <TextValue>{formValues.totalCost ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                          ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                      </Div>
                      <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                        <TextValue>{formValues.totalCost ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                          ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).solution : '-' : '-'}</TextValue>
                      </Div>
                      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>ต้นทุนทั้งหมด</TextLabel>
                        <TextValue>{formValues.totalCost ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                          ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).rawCost : '-' : '-'} บาท</TextValue>
                      </Div>
                      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>ราคารวมสำหรับการผลิตใหม่ {formValues.amountProduction} ชิ้น</TextLabel>
                        <TextValue>{formValues.totalCost ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                          ? (parseFloat(formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).totalCost) * parseFloat(formValues.amountProduction)) : 0 : 0} บาท</TextValue>
                      </Div>
                      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>ราคารวมสำหรับการผลิตใหม่ต่อ 1 ชิ้น</TextLabel>
                        <TextValue>{formValues.totalCost ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                          ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).totalCost : 0 : 0} บาท/ชิ้น</TextValue>
                      </Div>
                  </Div>
                      : <Div>
                      <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>สูตรการคำนวณ</TextLabel>
                        <TextValue>{formValues.totalCost ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                          ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).textSolution : '-' : '-'}</TextValue>
                      </Div>
                      <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>ผลลัพธ์สูตรการคำนวณ</TextLabel>
                        <TextValue>{formValues.totalCost ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                          ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).repeatSolution : '-' : '-'}</TextValue>
                      </Div>
                      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>ต้นทุนทั้งหมด</TextLabel>
                        <TextValue>{formValues.totalCost ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                          ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).rawRepeatCost : '-' : '-'} บาท</TextValue>
                      </Div>
                      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>ราคารวมสำหรับการผลิตซ้ำ {formValues.amountProduction} ชิ้น</TextLabel>
                        <TextValue>{formValues.totalCost ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                          ? (parseFloat(formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).totalRepeatCost) * parseFloat(formValues.amountProduction)) : 0 : 0} บาท</TextValue>
                      </Div>
                      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                        <TextLabel>ราคารวมสำหรับการผลิตซ้ำต่อ 1 ชิ้น</TextLabel>
                        <TextValue>{formValues.totalCost ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount))
                          ? formValues.totalCost.find(x => parseInt(x.quantity) === parseInt(produceAmount)).totalRepeatCost : 0 : 0} บาท/ชิ้น</TextValue>
                      </Div>
                    </Div>
                  }
                  </Div>
                }
              </Div>
              )
            })
          }
        </DivPricing>
      </Modal>
    </NotchedContainer>
  )
}

export default TablePrice
