import Icon01 from '../../images/status-icon/01.png'
import Icon02 from '../../images/status-icon/02.png'
import Icon03 from '../../images/status-icon/03.png'
import Icon04 from '../../images/status-icon/04.png'
import Icon05 from '../../images/status-icon/05.png'
import Icon06 from '../../images/status-icon/06.png'
import Icon07 from '../../images/status-icon/07.png'
import Icon08 from '../../images/status-icon/08.png'
import Icon09 from '../../images/status-icon/09.png'
import Icon10 from '../../images/status-icon/10.png'
import Icon11 from '../../images/status-icon/11.png'
import Icon12 from '../../images/status-icon/12.png'
import Icon13 from '../../images/status-icon/13.png'
import Icon14 from '../../images/status-icon/14.png'

export const statusKey = {
  QUOTATION_REQUEST: 'quotationRequest',
  QUOTATION_REVIEW: 'quotationReview',
  WAITING_FOR_PAYMENT: 'waitingForPayment',
  PAYMENT_VERIFYING: 'paymentVerifying',
  REVISING_PAYMENT: 'revisingPayment',
  PAYMENT_VERIFIED: 'paymentVerified',
  DESIGNING: 'designing',
  ARTWORK_CREATING: 'artworkCreating',
  PRINTING: 'printing',
  SHIPPED: 'shipped',
  REFUNDING: 'refunding',
  REFUNDED: 'refunded',
  PAUSED: 'paused',
  CANCELLED: 'cancelled',
}

export const statusText = {
  QUOTATION_REQUEST: 'ขอใบเสนอราคา',
  QUOTATION_REVIEW: 'ตรวจสอบใบเสนอราคา',
  WAITING_FOR_PAYMENT: 'รอการชำระเงิน',
  PAYMENT_VERIFYING: 'ตรวจสอบการชำระเงิน',
  REVISING_PAYMENT: 'แก้ไขการชำระเงิน',
  PAYMENT_VERIFIED: 'ยืนยันการชำระเงิน',
  DESIGNING: 'ดำเนินการออกแบบ',
  ARTWORK_CREATING: 'ดำเนินการสร้างไฟล์งาน',
  PRINTING: 'ดำเนินการผลิต',
  SHIPPED: 'จัดส่งเรียบร้อย',
  REFUNDING: 'ดำเนินการคืนเงิน',
  REFUNDED: 'คืนเงินเรียบร้อย',
  PAUSED: 'หยุดชั่วคราว',
  CANCELLED: 'ยกเลิก',
}

export const statusColor = {
  QUOTATION_REQUEST: '#1155CC',
  QUOTATION_REVIEW: '#6D9EEB',
  WAITING_FOR_PAYMENT: '#FFD966',
  PAYMENT_VERIFYING: '#FF9900',
  REVISING_PAYMENT: '#B45F06',
  PAYMENT_VERIFIED: '#6AA84F',
  DESIGNING: '#A64D79',
  ARTWORK_CREATING: '#E06666',
  PRINTING: '#38761D',
  SHIPPED: '#65D4CC',
  REFUNDING: '#434343',
  REFUNDED: '#000000',
  PAUSED: '#B7B7B7',
  CANCELLED: '#CC0000',
}

export const statusIcon = {
  QUOTATION_REQUEST: Icon01,
  QUOTATION_REVIEW: Icon02,
  WAITING_FOR_PAYMENT: Icon03,
  PAYMENT_VERIFYING: Icon04,
  REVISING_PAYMENT: Icon05,
  PAYMENT_VERIFIED: Icon06,
  DESIGNING: Icon07,
  ARTWORK_CREATING: Icon08,
  PRINTING: Icon09,
  SHIPPED: Icon10,
  REFUNDING: Icon11,
  REFUNDED: Icon12,
  PAUSED: Icon13,
  CANCELLED: Icon14,
}

export const status = Object.keys(statusKey).map(v => (
  {
    key: statusKey[v], text: statusText[v], color: statusColor[v], icon: statusIcon[v],
  }
))

// export const status = [
//   {
//     key: statusKey.QUOTATION_REQUEST, text: statusText.QUOTATION_REQUEST, color: statusColor.QUOTATION_REQUEST, icon: Icon01,
//   }, {
//     key: statusKey.QUOTATION_REVIEW, text: statusText.QUOTATION_REVIEW, color: statusColor.QUOTATION_REVIEW, icon: Icon02,
//   }, {
//     key: statusKey.WAITING_FOR_PAYMENT, text: statusText.WAITING_FOR_PAYMENT, color: statusColor.WAITING_FOR_PAYMENT, icon: Icon03,
//   }, {
//     key: statusKey.PAYMENT_VERIFYING, text: statusText.PAYMENT_VERIFYING, color: statusColor.PAYMENT_VERIFYING, icon: Icon04,
//   }, {
//     key: statusKey.REVISING_PAYMENT, text: statusText.REVISING_PAYMENT, color: statusColor.REVISING_PAYMENT, icon: Icon05,
//   }, {
//     key: statusKey.PAYMENT_VERIFIED, text: statusText.PAYMENT_VERIFIED, color: statusColor.PAYMENT_VERIFIED, icon: Icon06,
//   }, {
//     key: statusKey.DESIGNING, text: statusText.DESIGNING, color: statusColor.DESIGNING, icon: Icon07,
//   }, {
//     key: statusKey.ARTWORK_CREATING, text: statusText.ARTWORK_CREATING, color: statusColor.ARTWORK_CREATING, icon: Icon08,
//   }, {
//     key: statusKey.PRINTING, text: statusText.PRINTING, color: statusColor.PRINTING, icon: Icon09,
//   }, {
//     key: statusKey.SHIPPED, text: statusText.SHIPPED, color: statusColor.SHIPPED, icon: Icon10,
//   }, {
//     key: statusKey.REFUNDING, text: statusText.REFUNDING, color: statusColor.REFUNDING, icon: Icon11,
//   }, {
//     key: statusKey.REFUNDED, text: statusText.REFUNDED, color: statusColor.REFUNDED, icon: Icon12,
//   }, {
//     key: statusKey.PAUSED, text: statusText.PAUSED, color: statusColor.PAUSED, icon: Icon13,
//   }, {
//     key: statusKey.CANCELLED, text: statusText.CANCELLED, color: statusColor.CANCELLED, icon: Icon14,
//   },
// ]

export const detailMode = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
}

export const step1TitleInput = {
  boxSuitDesignService: 'บริการออกแบบ',
  name: 'ชื่อ หรือ จุดประสงค์ของกล่องบรรจุภัณฑ์',
  boxSize: 'ขนาดกล่องบรรจุภัณฑ์',
  quantity: 'จำนวน',
  productQuantityPerPiece: 'จำนวนมากสุดของผลิตภัณฑ์ที่จะถูกบรรจุในกล่อง',
  paperType: 'ประเภทกระดาษ',
  printingTechnique: 'รูปแบบการพิมพ์',
  printingDescription: 'รายละเอียดและเทคนิคงานพิมพ์ที่ต้องการ',
  designServiceChoice: 'บริการทำแบบ',
  productSize: 'ขนาดผลิตภัณฑ์',
  designDescription: 'รายละเอียดแนวทางการออกแบบที่ต้องการ',
  isGraphic: 'บริการด้านกราฟฟิก',
  paperTypeText: 'ประเภทกระดาษ',
  printingTechniqueText: 'รูปแบบการพิมพ์',
}

export const userDataInput = {
  address: 'ที่อยู่',
  name: 'ชื่อ - นามสกุล',
  email: 'Email',
  telephoneNumber: 'เบอร์โทรศัพท์',
  mobileNumber: 'เบอร์โทรศัพท์มือถือ',
  companyName: 'บริษัท (นิติบุคคล)',
  companyType: 'สาขา',
  branch: 'สาขา',
  taxId: 'หมายเลขประจำตัวผู้เสียภาษี',
  detail: 'รายละเอียดเพิ่มเติม',
}
