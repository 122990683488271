/* eslint-disable radix */
import React, { useState, useEffect, useRef } from 'react'
import {
  Div, H4, Button, LoadingIcon,
} from 'konsys-ui'
import { Query } from 'react-apollo'
import {
  message,
} from 'antd'
import { theme } from '../../styles/_variables'
import TabPart from '../../components/common/tab-part'
import AmountPart from './components/amount-part'
import UnitOfMeasure from './components/unit-of-measure'
import PriceCalculationForm from '../../components/package-calculator/components/price-calculator-form'
import Summary from '../../components/package-calculator/components/summary'
import SummaryContent from '../../components/package-calculator/components/summary-content'
import { PRICE_CALCULATOR, LIST_PAPER } from '../../constants/graphql/query'
import {
  ContentParts,
  ActionZone,
  ButtonCalculator,
  IconCalculate,
  ContentPart,
  SummaryDivMobile,
  WidthContainer,
  ButtonFormAction,
  ActionZone2,
} from './styled'
import CalculationIcon from '../../images/icon/CalculationIcon.png'
import SummaryToggleComponent from '../../components/common/summary-toggle'


const { color } = theme

const PriceCalculator = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [measurementUnit, setMeasurementUnit] = useState({
    value: 2,
    text: 'นิ้ว',
    key: 'inches',
    label: 'นิ้ว',
  })
  const [step, setStep] = useState(1)
  const [formValues, setFormValues] = useState({
    minimumManufacture: 500,
    incrementalSteps: 100,
    showPromotion: true,
    amountProduction: 500,
    productionType: 'newOrder',
    widthHoldingTax: false,
    graphicId: 0,
    moreDetail: '',
    graphicFile: [],
    partDetail: [],
    totalCost: [],
    partData: [{
      packagePartId: null,
      packageData: {
        name: 'ชิ้นส่วนที่ 1',
      },
      name: 'ชิ้นส่วนที่ 1',
      measurementUnit: {
        value: 2,
        text: 'นิ้ว',
        key: 'inches',
        label: 'นิ้ว',
      },
      printSizeWidth: null,
      printSizeLength: null,
      printing: {
        selectedPrinting: 0,
        side: null,
        printingId: null,
        printingName: '',
        printingType: 1,
        printingTypeName: 'พิมพ์หน้าเดียว',
        printingBasicColorId: 0,
        printingBasicColorName: '',
        checkExtraColor: false,
        printingExtraColorId: [],
      },
      paperList: [],
      paper: {
        paperId: null,
        name: '',
        thickness: 0,
        selectedThickness: 0,
        coatingFrontIds: [],
        coatingBackIds: [],
      },
      coating: {
        side: 0,
        coatingType: 'ไม่เคลือบ',
        coatingFrontId: null,
        coatingFrontName: '',
        coatingBackId: null,
        coatingBackName: '',
      },
      spotUV: {
        side: 0,
        spotUVType: 'ไม่เคลือบ',
        spotUVFrontId: null,
        spotUVFrontName: '',
        spotUVBackId: null,
        spotUVBackName: '',
      },
      selectedEmbossing: 0,
      embossing: [],
      foilingId: 0,
      foilingName: '',
      foiling: [],
      selectedPlasticWindow: 0,
      plasticWindow: [],
      gluingId: null,
      gluingName: '',
      gluing: null,
      additionalServiceId: [],
      additionalService: [],
      dieCut: false,
      addtionalRows: [],
      extraServiceList: [],
    }],
    amountPart: 1,
  })

  const [paperList, setPaperList] = useState([])
  const [callCalculation, setCallCalculation] = useState(false)

  const onChangeUnit = (value) => {
    const tmpFormValues = formValues.partData
    tmpFormValues[activeTab].measurementUnit = value
    setFormValues({ ...formValues, partData: tmpFormValues })
    setMeasurementUnit(value)
  }

  const onChangeTab = (key) => {
    setActiveTab(key)
  }

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 375)
  const [isMobileXL, setIsMobileXL] = useState(window.innerWidth <= 768)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth <= 1024)

  const screenSizeHandler = () => setIsMobileView(window.innerWidth <= 375)
  const screenSizeHandler2 = () => setIsMobileXL(window.innerWidth <= 768)
  const screenSizeHandler3 = () => setIsDesktop(window.innerWidth <= 1024)

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    window.addEventListener('resize', screenSizeHandler2)
    window.addEventListener('resize', screenSizeHandler3)
    return () => {
      window.removeEventListener('resize', screenSizeHandler)
      window.removeEventListener('resize', screenSizeHandler2)
      window.removeEventListener('resize', screenSizeHandler3)
    }
  }, [])

  const [disabled, setDisabled] = useState(false)
  const ContentRef = useRef(null)
  const ContentSummary = useRef(null)
  const [summaryActive, setSummaryActive] = useState(false)
  return (
    <>
    <Query
      query={LIST_PAPER}
      notifyOnNetworkStatusChange
      fetchPolicy='network-only'
      variables={{
        limit: 10000,
        offset: 0,
      }}
      onCompleted={(data) => {
        if (data.listPaper.success) {
          setPaperList(data.listPaper.paper.sort((a, b) => a.paperId - b.paperId))
          const tmpFormPartdata = formValues.partData
          const tmpResult = tmpFormPartdata.map(item => ({
            ...item,
            paperList: data.listPaper.paper.sort((a, b) => a.paperId - b.paperId),
          }))
          setFormValues({ ...formValues, partData: tmpResult })
        }
      }}
      >
        {
          ({ loading, data }) => {
            if (loading) return <Div><LoadingIcon/></Div>
            if (!data.listPaper) return <p>error something</p>
            return (
              <>
                {
                  isMobileXL && <SummaryToggleComponent
                    setSummaryActive={setSummaryActive}
                    summaryActive={summaryActive}
                  >
                    <SummaryDivMobile>
                      <Summary
                        formValues={formValues}
                        activeTab={activeTab}
                        onChangeTab={onChangeTab}
                        defaultPrinting={true}
                        sticky={false}
                        step={step}
                        setStep={setStep}
                        isNotTop
                        style={{ maxWidth: '100%', top: 0 }}
                        setSummaryActive={setSummaryActive}
                      />
                    </SummaryDivMobile>
                </SummaryToggleComponent>
                }
                <Div display='flex' flexDirection='column'>
                  <H4 bold color={color.primaryColor} margin='32px 0'>
                    คำนวณราคา (Price Calculator)
                  </H4>
                  {
                    step === 1 && <>
                      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
                        <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-start'>
                          <UnitOfMeasure
                            data={formValues.partData[activeTab].measurementUnit}
                            onInputChange={onChangeUnit}
                            isMobileXL={isMobileXL}
                          />
                        </Div>
                      </Div>
                      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
                        {/* <H4 bold color={color.primaryColor} margin='2px 0'>
                          จำนวนชิ้นส่วน
                        </H4> */}
                        <AmountPart
                          data={formValues}
                          onInputChange={setFormValues}
                          partData={formValues.partData}
                          paperList={paperList}
                          setActiveTab={setActiveTab}
                        />
                      </Div>
                    </>
                  }
                </Div>
                <ContentParts id="content-package" step={step} data={formValues.partData && formValues.partData.length}>
                  <WidthContainer step={step} data={formValues.partData && formValues.partData.length}>
                    {
                      step === 1 && formValues.partData.length > 0 && <ContentPart ref={ContentRef}>
                        <TabPart
                          onChange={onChangeTab}
                          data={formValues.partData && formValues.partData}
                          keyTab={activeTab}
                        >
                          <Div display='flex' alignItems='center' justifyContent='center'>
                            <Div flexBasis={isMobileXL ? '100%' : '65%'}>
                              <PriceCalculationForm
                                measurementUnit={formValues.partData[activeTab].measurementUnit}
                                activeTab={activeTab}
                                formValues={formValues}
                                setFormValues={setFormValues}
                                defaultPrinting={true}
                                tested={'calculated'}
                                disabled={disabled}
                                setDisabled={setDisabled}
                              />
                            </Div>
                            {
                              !isMobileXL && <Div flexBasis='35%'>
                                <Summary
                                  formValues={formValues}
                                  activeTab={activeTab}
                                  onChangeTab={onChangeTab}
                                  defaultPrinting={true}
                                  sticky={true}
                                  step={step}
                                  setStep={setStep}
                                  ContentRef={ContentRef}
                                  setSummaryActive={setSummaryActive}
                                  isShowGluing
                                />
                              </Div>
                            }
                          </Div>
                        </TabPart>
                      </ContentPart>
                    }
                    {
                      step === 2 && <ContentPart ref={ContentSummary}>
                        <TabPart
                          onChange={onChangeTab}
                          data={formValues.partData && formValues.partData}
                          noShow
                        >
                          <Div display='flex' alignItems='center' justifyContent='center'>
                            <Div flexBasis={isMobileXL ? '100%' : isDesktop ? '60%' : '70%'}>
                              <SummaryContent
                                setFormValues={setFormValues}
                                formValues={formValues}
                                setStep={setStep}
                                showEditIcon
                              />
                              {
                                step === 2 && <ActionZone2><ButtonFormAction inverse buttonStyle='round' text={'ย้อนกลับ'} onClick={async () => {
                                  const element = document.getElementById('content-package')
                                  if (element) {
                                    await setStep(1)
                                    await element.scrollIntoView({
                                      behavior: 'smooth',
                                    })
                                  }
                                  setActiveTab(0)
                                }} /></ActionZone2>
                              }
                              <Div moreStyle={{ height: step === 2 ? '20px' : '0px' }}>
                              </Div>
                            </Div>
                            {
                              !isMobileXL && <Div flexBasis={isDesktop ? '40%' : '30%'}>
                                <Summary
                                  formValues={formValues}
                                  activeTab={activeTab}
                                  onChangeTab={onChangeTab}
                                  defaultPrinting={true}
                                  sticky={true}
                                  step={step}
                                  setStep={setStep}
                                  ContentRef={ContentSummary}
                                  setSummaryActive={setSummaryActive}
                                  isShowGluing
                                />
                              </Div>
                            }
                          </Div>
                        </TabPart>
                      </ContentPart>
                    }
                  </WidthContainer>
                </ContentParts>
                {
                  step === 1 && <ActionZone>
                    <Query
                      query={PRICE_CALCULATOR}
                    >
                      {
                        ({ refetch }) => (
                          <ButtonCalculator
                            inverse
                            buttonStyle='round'
                            text={<React.Fragment><IconCalculate src={CalculationIcon} />คำนวณราคา</React.Fragment>}
                            disabled={callCalculation || disabled}
                            loading={callCalculation}
                            onClick={async () => {
                              const findNullSize = formValues.partData.filter(item => item.printSizeWidth === null || item.printSizeLength === null || item.printSizeWidth === 0 || item.printSizeLength === 0)
                              const findEmptyEmbossing = formValues.partData.filter(item => (item.selectedEmbossing === 1 && item.embossing.length === 0) || (item.embossing.length > 0 && item.embossing.filter(x => x.width === null || x.lengths === null).length > 0))
                              const findEmptyFoiling = formValues.partData.filter(item => (item.foilingId > 0 && item.foiling.length === 0) || (item.foiling.length > 0 && item.foiling.filter(x => x.width === null || x.lengths === null).length > 0))
                              const findEmptyPlastic = formValues.partData.filter(item => (item.selectedPlasticWindow === 1 && item.plasticWindow.length === 0) || (item.plasticWindow.length > 0 && item.plasticWindow.filter(x => x.width === null || x.lengths === null).length > 0))
                              if (findNullSize.length > 0) {
                                message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                                setCallCalculation(false)
                                return
                              }
                              if (findNullSize.length > 0 || findEmptyEmbossing.length > 0 || findEmptyFoiling.length > 0 || findEmptyPlastic.length > 0) {
                                message.warning(`กรุณากรอกข้อมูล ${findNullSize.length > 0 ? 'ขนาดบรรจุภัณฑ์' : ''} ${findEmptyEmbossing.length > 0 ? 'ตำแหน่งการปั๊มนูน' : ''} ${findEmptyFoiling.length > 0 ? 'ตำแหน่งปั๊มฟอยล์-ทองเค' : ''} ${findEmptyPlastic.length > 0 ? 'ตำแหน่งการเจาะหน้าต่างและติดแผ่นใส' : ''} ให้ครบถ้วน`)
                                setCallCalculation(false)
                                return
                              }
                              setCallCalculation(true)
                              const part = formValues.partData.map((item, index) => ({
                                order: index + 1,
                                packagePartId: item.packagePartId,
                                unit: item.measurementUnit.key,
                                operator: [],
                                printSizeWidth: item.printSizeWidth,
                                printSizeLength: item.printSizeLength,
                                paper: {
                                  paperId: item.paper.paperId,
                                  thickness: parseFloat(item.paper.selectedThickness),
                                },
                                printing: item.printing.side && {
                                  side: item.printing.printingType,
                                  printingBasicColorId: item.printing.printingBasicColorId,
                                  printingExtraColorId: item.printing.printingExtraColorId.filter(x => x.printingExtraColorId).map(x => x.printingExtraColorId),
                                },
                                coating: {
                                  coatingIdFront: item.coating.side === 1 || item.coating.side === 2 ? item.coating.coatingFrontId : null,
                                  coatingIdBehind: item.coating.side === -1 || item.coating.side === 2 ? item.coating.coatingBackId : null,
                                },
                                spotUV: {
                                  spotUVIdFront: item.spotUV.spotUVFrontId,
                                  spotUVIdBehind: item.spotUV.spotUVBackId,
                                },
                                embossing: item.embossing.map((x, i) => ({
                                  order: i + 1,
                                  width: parseFloat(x.width),
                                  length: parseFloat(x.lengths),
                                  unit: item.measurementUnit.key,
                                })),
                                foiling: {
                                  foilingId: item.foilingId !== 0 ? item.foilingId : null,
                                  foilingPart: item.foiling.map((x, i) => ({
                                    order: i + 1,
                                    width: parseFloat(x.width),
                                    length: parseFloat(x.lengths),
                                    unit: item.measurementUnit.key,
                                  })),
                                },
                                dieCut: item.dieCut,
                                plasticWindow: item.plasticWindow.map((x, i) => ({
                                  order: i + 1,
                                  width: parseFloat(x.width),
                                  length: parseFloat(x.lengths),
                                  thickness: x.thickness,
                                  unit: item.measurementUnit.key,
                                })),
                                gluingId: item.gluingId,
                                additionalServiceId: item.additionalServiceId.filter(x => x).map(x => x.value),
                              }))
                              const input = {
                                part,
                              }
                              await refetch({
                                input,
                              }).then((res) => {
                                setCallCalculation(false)
                                if (res.data.priceCalculator.success) {
                                  setFormValues({
                                    ...formValues,
                                    partData: formValues.partData,
                                    partDetail: res.data.priceCalculator.partDetail,
                                    totalCost: res.data.priceCalculator.totalCost,
                                    costDetail: res.data.priceCalculator.costDetail,
                                  })
                                  document.getElementById('container').scrollTo({
                                    top: 0,
                                    behavior: 'smooth',
                                  })
                                  setStep(2)
                                } else if (res.data.priceCalculator.message === 'oversize') {
                                  message.warning('ไม่สามารถให้บริการผลิตขนาดดังกล่าวได้ เนื่องจากมีขนาดเกินเครื่องพิมพ์ ของทางเรา ขออภัยในความไม่สะดวก')
                                } else if (res.data.priceCalculator.message === 'ขออภัย ขนาดที่ระบุ ไม่สามารถผลิตบนกระดาษชนิดนี้ได้') {
                                  message.warning('ขออภัย ขนาดที่ระบุ ไม่สามารถผลิตบนกระดาษชนิดนี้ได้')
                                } else if (res.data.priceCalculator.message === 'ไม่สามารถคำนวณ Width ได้' || res.data.priceCalculator.message === 'ไม่สามารถคำนวณ Length ได้') {
                                  message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                                } else {
                                  message.success('สามารถให้บริการผลิตขนาดดังกล่าวได้')
                                }
                              }).catch((e) => {
                                if (e.toString().includes('status code 400')) {
                                  setCallCalculation(false)
                                  message.warning('กรุณากรอกข้อมูลให้ครบถ้วน')
                                } else {
                                  setCallCalculation(false)
                                  message.error('ไม่สามารถคำนวนราคาได้')
                                  setStep(1)
                                  setActiveTab(0)
                                }
                              })
                            }}
                          />
                        )
                      }
                    </Query>
                  </ActionZone>
                }
              </>
            )
          }
        }
      </Query>
    </>
  )
}

export default PriceCalculator
