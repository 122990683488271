import React, { useState } from 'react'
import {
  WidthContainer,
} from './styled'
import GraphingTitleInput from '../title'
import GraphingDescriptionInput from '../description'
import IconUpload from '../icon-upload'

const GraphingForm = ({
  formValues,
  setFormValues,
  isValidate,
  setIsValidate,
  action,
}) => {
  const [, setDisabledButton] = useState(true)
  return (
    <>
      <WidthContainer>
        <IconUpload
            data={formValues}
            newFileList={formValues.coverPhoto}
            setNewFileList={setFormValues}
          />
        <GraphingTitleInput
          label={'ชื่อตัวเลือกบริการด้านกราฟฟิก'}
          data={formValues}
          onInputChange={setFormValues}
          setDisabledButton={setDisabledButton}
          setIsValidate={setIsValidate}
          isValidate={isValidate}
        />
        <GraphingDescriptionInput
          data={formValues}
          onInputChange={setFormValues}
        />
      </WidthContainer>
    </>
  )
}

export default GraphingForm
