/* eslint-disable no-restricted-globals */
import React, { useState } from 'react'
import {
  WidthContainer, FullWidthContainer, QuaterFullWidthContainer,
} from './styled'
import EmbossingTitleInput from '../title'
import EmbossingDescriptionInput from '../description'
import EmbossingSampleVideoInput from '../sample-video'
import EmbossingSampleImageInput from '../sample-image'
import EmbossingCostTable from '../cost-table'
import EmbossingCostMinimumInput from '../cost-minimum'
import EmbossingTable from '../block-cost-table'


const EmbossingForm = ({
  formValues,
  setFormValues,
  isValidate,
  setIsValidate,
  action,
}) => {
  const [, setDisabledButton] = useState(true)

  const handleOnSubmitEditRow = (key, value) => {
    const tmp = [...formValues.embossingBlockCost]
    const tmpValue = value
    if (tmpValue.column1 === null || isNaN(tmpValue.column1)) tmpValue.column1 = 0
    if (tmpValue.column2 === null || isNaN(tmpValue.column2)) tmpValue.column2 = 0
    if (tmpValue.column3 === null || isNaN(tmpValue.column3)) tmpValue.column3 = 0
    if (tmpValue.column4 === null || isNaN(tmpValue.column4)) tmpValue.column4 = 0
    if (tmpValue.column5 === null || isNaN(tmpValue.column5)) tmpValue.column5 = 0
    tmp[key] = tmpValue
    setFormValues({ ...formValues, embossingBlockCost: tmp })
  }

  const handleOnSubmitDeleteRow = (value) => {
    setFormValues({ ...formValues, embossingBlockCost: value })
  }

  const handleOnAddRow = (value) => {
    setFormValues({ ...formValues, embossingBlockCost: value })
  }
  return (
    <>
      <WidthContainer>
        <EmbossingTitleInput
          label={'ชื่อวิธีการการปั๊มนูน'}
          data={formValues}
          onInputChange={setFormValues}
          setDisabledButton={setDisabledButton}
          setIsValidate={setIsValidate}
          isValidate={isValidate}
        />
        <EmbossingDescriptionInput
          data={formValues}
          onInputChange={setFormValues}
        />
        <EmbossingSampleVideoInput
          data={formValues}
          newFileList={formValues.sampleVideo || []}
          setNewFileList={setFormValues}
        />
        <EmbossingSampleImageInput
          data={formValues}
          newFileList={formValues.imagePhoto || []}
          setNewFileList={setFormValues}
        />
      </WidthContainer>
      <QuaterFullWidthContainer>
        <EmbossingTable
          data={formValues.embossingBlockCost}
          onSubmitEditRow={handleOnSubmitEditRow}
          onSubmitDeleteRow={handleOnSubmitDeleteRow}
          onAddRow={handleOnAddRow}
        />
        <EmbossingCostMinimumInput
          data={formValues}
          onInputChange={setFormValues}
          action={action}
        />
      </QuaterFullWidthContainer>
      <FullWidthContainer>
        <EmbossingCostTable
          data={formValues}
          onChange={setFormValues}
          formValues={formValues}
        />
      </FullWidthContainer>
    </>
  )
}

export default EmbossingForm
