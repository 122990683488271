import styled from 'styled-components'

export const WidthContainer = styled.div`
  width: 45%;
  min-width: 300px;
`
export const FullWidthContainer = styled.div`
  margin-top: 32px;
  width: 100%;
`

export default WidthContainer
