import styled from 'styled-components'
import { theme } from '../../../../styles/_variables'


export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`

export const DivContentAmountPart = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 0;
    width: auto;
`

export default Label
