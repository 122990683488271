import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
  message, Switch, Modal, Select,
} from 'antd'
import {
  Div, H3, Button, LoadingIcon, Icon,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import TagFilter from '../components/tag-filter'
import { theme } from '../../../styles/_variables'
import PATH from '../../../constants/path'
import {
  ImageWrapper, ImageLabel, DivPackageList, DivAction,
  DivSearch, ImageLabelOther, InputSearch, Label, DivToolbarAction,
  DivToolbar, ButtonToolbar, ButtonSearch, SelectSort, LabelOptionSort,
  TagDivActive, StyledItem, StyledContainer, Container, StyledHandle,
  WidthContainer,
} from './styled'
import { SHOW_PACKAGE, LIST_TAG } from '../../../constants/graphql/query'
import { EDIT_PACKAGE_STATUS, REARRANGE_PACKAGE } from '../../../constants/graphql/mutation'

const { color } = theme

const Index = (props) => {
  const [textSearch, setTextSearch] = useState('')
  const [settingVisible, setSettingVisible] = useState(false)
  const [tmpSortBy, setTmpSortBy] = useState({
    order: 'ordering',
    sort: 'ASC',
  })
  const [sortBy, setSortBy] = useState({
    order: 'ordering',
    sort: 'ASC',
  })
  const [settingFilter, setSettingFilter] = useState(false)
  const [tmpFilterBy, setTmpFilterBy] = useState([])
  const [filterBy, setFilterBy] = useState([])
  const [packageItems, setPackageItems] = useState([])

  const handleSort = (value) => {
    const result = value.split('_')
    setTmpSortBy({
      order: result[0],
      sort: result[1],
    })
    // setSettingVisible(false)
  }

  const handleCloseSort = (refetch) => {
    setSortBy({
      order: tmpSortBy.order,
      sort: tmpSortBy.sort,
    })
    setSettingVisible(false)
  }

  const handleOnChangeFilter = (index, isActive) => {
    const tmp = [...tmpFilterBy]
    tmp[index].active = isActive
    setTmpFilterBy(tmp)
  }

  const handleOnClearFilter = () => {
    let tmp = [...tmpFilterBy]
    tmp = tmp.map(item => ({
      ...item,
      active: false,
    }))
    setTmpFilterBy(tmp)
  }

  const handleOnCloseFilter = () => {
    setFilterBy(JSON.parse(JSON.stringify(tmpFilterBy)))
    setSettingFilter(false)
  }

  const [isRearrange, setIsRearrange] = useState(false)
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setPackageItems(arrayMoveImmutable(packageItems, oldIndex, newIndex))
  }

  const handleToggleRearrange = (event, refetch) => {
    setIsRearrange(!isRearrange)
    if (event === 'confirm') {
      if (tmpSortBy.order === 'createdAt' && tmpSortBy.sort === 'ASC') {
        const tmpPackageItems = packageItems.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))
        setPackageItems(tmpPackageItems)
      } else if (tmpSortBy.order === 'createdAt' && tmpSortBy.sort === 'DESC') {
        const tmpPackageItems = packageItems.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
        setPackageItems(tmpPackageItems)
      } else if (tmpSortBy.order === 'name' && tmpSortBy.sort === 'ASC') {
        const tmpPackageItems = packageItems.sort((a, b) => a.name - b.name)
        setPackageItems(tmpPackageItems)
      } else if (tmpSortBy.order === 'name' && tmpSortBy.sort === 'DESC') {
        const tmpPackageItems = packageItems.sort((a, b) => b.name - a.name)
        setPackageItems(tmpPackageItems)
      } else if (tmpSortBy.order === 'ordering' && tmpSortBy.sort === 'ASC') {
        const tmpPackageItems = packageItems.sort((a, b) => a.ordering - b.ordering)
        setPackageItems(tmpPackageItems)
      } else if (tmpSortBy.order === 'ordering' && tmpSortBy.sort === 'DESC') {
        const tmpPackageItems = packageItems.sort((a, b) => b.ordering - a.ordering)
        setPackageItems(tmpPackageItems)
      }
      refetch()
      return
    }
    const tmpPackageItems = packageItems.sort((a, b) => a.ordering - b.ordering)
    setPackageItems(tmpPackageItems)
  }

  return (
    <WidthContainer>
      <Div display='flex' flexDirection='column'>
        <Query
          query={SHOW_PACKAGE}
          fetchPolicy={'network-only'}
          notifyOnNetworkStatusChange
          variables={{
            limit: 10000,
            offset: 0,
            name: textSearch,
            order: (sortBy && sortBy.order) || 'ordering',
            sort: (sortBy && sortBy.sort) || 'ASC',
            tag: filterBy.reduce((result, item) => {
              if (item.active) result.push(item.value)
              return result
            }, []),
          }}
          onCompleted={(data) => {
            const packageData = data.showPackaging.packaging.filter(item => item.ordering !== -1)
            if (tmpSortBy.order === 'ordering' && tmpSortBy.sort === 'ASC') {
              setPackageItems(packageData.sort((a, b) => a.ordering - b.ordering))
            } else {
              setPackageItems(packageData)
            }
          }}
        >
          {
            ({ loading, data, refetch }) => {
              if (!data || loading) {
                return <Div margin='24px 0' display='flex' moreStyle={{ width: '100%', justifyContent: 'center' }}>
                  <LoadingIcon color={theme.color.grey} />
                </Div>
              }
              if (data && data.showPackaging && !data.showPackaging.success) return <p>error something</p>
              const packagingOther = data.showPackaging && data.showPackaging.packaging.filter(item => item.ordering === -1)
              return (
                <>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                  <H3 bold color={color.primaryColor} margin='32px 0'>Packaging Catalog</H3>
                  <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                    <Link to={PATH.packageSetting}><Button text='Settings' icon='fal fa-cog' ghost margin='0 16px 0 0'/></Link>
                    {
                      isRearrange ? <ConfirmRearrangeButton
                        packages={packageItems}
                        handleToggleRearrange={handleToggleRearrange}
                        refetch={refetch}
                      />
                        : <Button
                          text={'Rearrange'}
                          onClick={handleToggleRearrange}
                          ghost
                          icon='fas fa-arrows-alt'
                          margin='0 16px 0 0'
                        />
                    }
                    <Link to={PATH.packageCreate}><Button text='New Packaging' icon='fal fa-plus' inverse/></Link>
                  </Div>
                </Div>
                <DivToolbar>
                  <Search value={textSearch} onSummit={value => setTextSearch(value)}/>
                  <DivToolbarAction>
                    <ButtonToolbar
                      text='ตัวเลือก'
                      icon='fas fa-tasks'
                      inverse
                      width='200px'
                      onClick={() => setSettingFilter(true)}
                    />
                    <ButtonToolbar
                      text='การจัดเรียง'
                      icon='fas fa-sort-amount-down'
                      inverse
                      onClick={() => setSettingVisible(true)}
                      style={{
                        width: '224px',
                      }}
                    />
                  </DivToolbarAction>
                </DivToolbar>
                <Div style={{ padding: '8px 0px' }}>
                  {
                  filterBy.reduce((result, item) => {
                    if (item.active) {
                      result.push(<TagDivActive
                        text={item.title}
                        icon='fa fa-tag fa-rotate-90'
                        inverse
                        onClick={async () => {
                          const tmp = [...tmpFilterBy]
                          tmp[item.key].active = false
                          setTmpFilterBy(tmp)
                          setFilterBy(JSON.parse(JSON.stringify(tmp)))
                        }}
                      />)
                    }
                    return result
                  }, [])
                  }
                </Div>
                <DivPackageList>
                  {
                    loading ? <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div> : <Container>
                      <SortableList
                        shouldUseDragHandle={isRearrange}
                        useDragHandle
                        axis="xy"
                        items={packageItems}
                        onSortEnd={onSortEnd}
                        history={props.history}
                        refetch={refetch}
                        packagingOther={packagingOther}
                      />
                    </Container>
                  }
                </DivPackageList>
                </>
              )
            }
          }
        </Query>
        <Modal
          title={
            <Label color={theme.color.textColor} style={{ fontWeight: theme.fonts.style.regular }}>
              <Icon icon='fas fa-sliders-h' margin='0px 8px 0px 0px' color={theme.color.primaryColor} style={{ fontWeight: theme.fonts.style.regular }} />
              เลือกตัวเลือกการค้นหาที่ต้องการ
            </Label>
          }
          visible={settingFilter}
          onCancel={handleOnCloseFilter}
          footer={null}
          closable={false}
          centered={true}
        >
          <Div position='absolute' top='16px' right='16px' cursor='pointer' onClick={handleOnCloseFilter}>
            <Icon fontSize={theme.fonts.size.extraNormal} color={theme.color.grey} icon='far fa-times' />
          </Div>
          <Query
            query={LIST_TAG}
            variables={{
              limit: 1000,
              offset: 0,
              order: 'createdAt',
              sort: 'ASC',
            }}
            notifyOnNetworkStatusChange
            onCompleted={(data) => {
              setTmpFilterBy(data.listTag.tag.map((item, index) => ({
                key: index,
                value: item.tagId,
                title: item.name,
                active: false,
              })))
            }}
          >
          {
            ({ loading, data }) => {
              if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
              if (!data.listTag.success) return <p>error something</p>
              return (
                <TagFilter
                  data={tmpFilterBy}
                  onChange={handleOnChangeFilter}
                  onClear={handleOnClearFilter}
                />
              )
            }
          }
          </Query>
        </Modal>
        <Modal
          title={
            <Label color={theme.color.textColor}>
              <Icon icon='fas fa-sort-amount-down' margin='0 15px 0 0' color={theme.color.primaryColor}/>
              เลือกวิธีจัดเรียง
            </Label>
          }
          visible={settingVisible}
          onCancel={handleCloseSort}
          footer={null}
          centered={true}
        >
          <SelectSort
            dropdown
            optionFilterProp="children"
            size="large"
            menuItemSelectedIcon
            suffixIcon={
              <Icon fontSize={theme.fonts.size.extraNormal} icon='far fa-angle-down' />
            }
            value={tmpSortBy.order !== 'ordering' ? `${tmpSortBy.order}_${tmpSortBy.sort}` : 'กรุณาเลือกวิธีจัดเรียง'}
            onChange={handleSort}
          >
            <Select.Option key={'createdAtDESC'} value={'createdAt_DESC'}><LabelOptionSort>{'ตามวันเวลา (ใหม่ -> เก่า)'}</LabelOptionSort></Select.Option>
            <Select.Option key={'createdAtASC'} value={'createdAt_ASC'}><LabelOptionSort>{'ตามวันเวลา (เก่า -> ใหม่)'}</LabelOptionSort></Select.Option>
            <Select.Option key={'nameASC'} value={'name_ASC'}><LabelOptionSort>{'ตามชื่อ (A(ก) -> Z(ฮ))'}</LabelOptionSort></Select.Option>
            <Select.Option key={'nameDESC'} value={'name_DESC'}><LabelOptionSort>{'ตามชื่อ (Z(ฮ) -> A(ก))'}</LabelOptionSort></Select.Option>
          </SelectSort>
        </Modal>
      </Div>
    </WidthContainer>
  )
}


const Search = ({
  value,
  onSummit,
}) => {
  const [textSearch, setTextSearch] = useState(value)

  const onSummitSearch = (e) => {
    e.preventDefault()
    onSummit(textSearch)
  }

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={onSummitSearch}
    >
      <DivSearch>
        <ButtonSearch
          htmlType="submit"
          text='ค้นหา'
          icon='fa fa-search'
          inverse
        />
        <InputSearch
          value={textSearch}
          onChange={ e => setTextSearch(e.target.value)}
        />
      </DivSearch>
    </form>
  )
}


const ConfirmRearrangeButton = ({ packages, handleToggleRearrange, refetch }) => (
  <Mutation mutation={REARRANGE_PACKAGE}>
    {
      doRearrangePackage => (
        <Button
          text={'Confirm Rearrange'}
          onClick={() => {
            doRearrangePackage({
              variables: {
                input: {
                  package: packages.map((item, index) => ({
                    packageId: item.packageId,
                    ordering: index,
                  })),
                },
              },
            }).then((res) => {
              if (res.data.rearrangePackage) {
                if (res.data.rearrangePackage.success) {
                  message.success(res.data.rearrangePackage.message)
                } else {
                  message.error(res.data.rearrangePackage.message)
                }
              }
            })
            handleToggleRearrange('confirm', refetch)
          }}
          ghost
          icon='fas fa-arrows-alt'
          margin='0 16px 0 0'
        />
      )
    }
  </Mutation>
)

const ButtonPackageAction = ({
  text,
  onClick,
}) => (
  <Button
    style={{
      padding: '4px 16px',
      width: '70px',
      justifyContent: 'center',
      display: 'flex',
      marginRight: '8px',
      backgroundColor: color.offWhite,
    }}
    text={text}
    onClick={onClick}
    ghost
  />
)

const Image = ({ img }) => (
  <ImageWrapper img={img}/>
)

const ImageWithToggle = ({
  img,
  status,
  packageId,
  refetch,
}) => {
  const [callMutation, setCallMutation] = useState(false)

  const onToggleImg = doUpdateStatusPackage => (checked) => {
    doUpdateStatusPackage({
      variables: {
        packageId,
        status: checked ? 'active' : 'inactive',
      },
    }).then(({ data }) => {
      if (data.editPackageStatusByPackageId) {
        if (data.editPackageStatusByPackageId.success) {
          setCallMutation(false)
          refetch()
          message.success(data.editPackageStatusByPackageId.message)
        } else {
          message.error(data.editPackageStatusByPackageId.message)
        }
      }
    })
  }

  return (
    <Div moreStyle={{ position: 'relative' }}>
      <Div moreStyle={{
        position: 'absolute',
        zIndex: 10,
        right: 0,
        padding: 8,
      }}>
        <Mutation
          mutation={EDIT_PACKAGE_STATUS}
        >
          {
            doUpdateStatusPackage => (
              <Switch
                checked={status === 'active'}
                onChange={onToggleImg(doUpdateStatusPackage)}
                disabled={callMutation}
              />
            )
          }
        </Mutation>
      </Div>
      <Image
        img={img}
      />
    </Div>
  )
}

const SortableList = SortableContainer((props) => {
  const {
    items,
    refetch,
    history,
    packagingOther,
    ...restProps
  } = props
  return (
    <StyledContainer>
      {items.map((item, index) => (
        <SortableItem
          key={`item-${item.packageId}`}
          index={index}
          value={item}
          data={item}
          history={history}
          refetch={refetch}
          {...restProps}
        />
      ))}
      {
        packagingOther && packagingOther.length > 0 && <Package data={packagingOther[0]} key={packagingOther[0].packageId} history={props.history} refetch={refetch}/>
      }
    </StyledContainer>
  )
})

const Package = ({
  data,
  history,
  refetch,
}) => {
  const file = data.packageFiles.filter(item => item.type === 'cover')
  const {
    name,
    status,
    packageId,
    ordering,
  } = data

  return (
    <StyledItem>
      <div className="content">
        <ImageWithToggle
          packageId={packageId}
          img={file.length > 0 ? file[0].packageFileUri : ''}
          status={status}
          refetch={refetch}
        />
        { ordering === -1 ? (
          <ImageLabelOther>{name}</ImageLabelOther>
        ) : (
          <>
            <ImageLabel>{name || 'Unknown'}</ImageLabel>
          </>
        )}
      </div>
    </StyledItem>
  )
}

const SortableItem = SortableElement(({
  data,
  history,
  refetch,
  shouldUseDragHandle,
}) => {
  const file = data.packageFiles.filter(item => item.type === 'cover')
  const {
    name,
    status,
    packageId,
    ordering,
    errorStatus,
  } = data

  return (
    <StyledItem>
      <div className="content">
        <ImageWithToggle
          packageId={packageId}
          img={file.length > 0 ? file[0].packageFileUri : ''}
          status={status}
          refetch={refetch}
        />
        { ordering === -1 ? (
          <ImageLabelOther>{name}</ImageLabelOther>
        ) : (
          <>
            <ImageLabel errorStatus={errorStatus}>{name || 'Unknown'}</ImageLabel>
          </>
        )}
      </div>
      {
        shouldUseDragHandle ? <Handle />
          : <DivAction>
              <ButtonPackageAction onClick={() => history.push(`${PATH.packageDetail}/${packageId}`)} text='แก้ไข'/>
              <ButtonPackageAction onClick={() => history.push(`${PATH.packagePreview}/${packageId}`)} text='ทดสอบ'/>
            </DivAction>
      }
    </StyledItem>
  )
})

const Handle = SortableHandle(({ tabIndex }) => (
  <StyledHandle tabIndex={tabIndex}>
    <>
      <svg viewBox="0 0 50 50">
        <path
          d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 L 0 7.5 z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 L 0 22.5 z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 L 0 37.5 z"
          color="#000"
        />
      </svg>
    </>
  </StyledHandle>
))

export default Index
