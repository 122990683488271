/* eslint-disable radix */
import React, { useState, useEffect, useCallback } from 'react'
import { Div, LoadingIcon } from 'konsys-ui'
import { Query } from 'react-apollo'
import { Row, Col } from 'antd'
import { LIST_PAPER } from '../../../../constants/graphql/query'
import { theme } from '../../../../styles/_variables'
import NotchedContainer from '../../../common/notched-containner'
import OptionsList from '../common/options-list'
import OptionDescription from '../common/option-description'
import SampleImageList from '../common/sample-image-list'
import SelectBox from '../common/select-box'
import Recommendation from '../common/recommendation'
import { CoverImage, DetailWrapper } from './styled'
import paperIconImage from '../../../../images/icon/PaperIcon.png'


const MockIcon = () => <div style={{
  padding: '10px',
  background: 'gray',
}}></div>

const PaperSelector = ({
  formValues,
  setFormValues,
  activeTab,
  disabled,
}) => {
  const [paperFormValues, setPaperFormValue] = useState({
    paperId: null,
    thickness: undefined,
  })
  const [paperItems, setPaperItems] = useState([])
  const [remark, setRemark] = useState('')
  const [selectedPaper, setSelectedPaper] = useState(null)

  const handleOnSelect = (value) => {
    if (value) {
      setSelectedPaper(value)
      const tmpFormValue = formValues.partData
      tmpFormValue[activeTab].paper.paperId = value.paperId
      tmpFormValue[activeTab].paper.name = value.name
      tmpFormValue[activeTab].paper.coatingFrontIds = value.coatingFront
      tmpFormValue[activeTab].paper.coatingBackIds = value.coatingBehind
      tmpFormValue[activeTab].paper.thickness = value.paperThickness.length > 0 ? value.paperThickness[0].thickness : []
      tmpFormValue[activeTab].paper.selectedThickness = value.paperThickness.find(x => x.thickness === formValues.partData[activeTab].paper.selectedThickness)
        ? value.paperThickness.find(x => x.thickness === formValues.partData[activeTab].paper.selectedThickness).thickness : value.paperThickness.length > 0 ? value.paperThickness[0].thickness : []
      setFormValues({ ...formValues, partData: tmpFormValue })
      setPaperFormValue({
        ...paperFormValues,
        paperId: value.paperId,
        thickness: undefined,
        coatingFrontIds: value.coatingFront,
        coatingBackIds: value.coatingBehind,
      })
    }
  }

  const handleSelectThickness = (value) => {
    const tmpFormValues = formValues.partData
    tmpFormValues[activeTab].paper.thickness = value
    tmpFormValues[activeTab].paper.selectedThickness = value
    setFormValues({ ...formValues, partData: tmpFormValues })
    setPaperFormValue({ ...paperFormValues, thickness: value })
  }

  useEffect(() => {
    if (formValues.partData[activeTab].paperList.length > 0) {
      const paperList = formValues.partData[activeTab].paperList.map((item, index) => {
        const thicknessOptions = [...new Set(item.paperThickness.map(paper => paper.thickness))]
        const thickness = thicknessOptions.map(value => ({
          value,
          title: `${value} แกรม`,
        }))
        const coverImg = item.paperFiles.find(file => file.type === 'cover')
        const sampleImages = item.paperFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
        const sampleVideo = item.paperFiles.filter(file => file.type === 'video')
        return {
          ...item,
          // eslint-disable-next-line radix
          key: `${activeTab}-${index}`,
          activeTab: parseInt(activeTab),
          label: item.name,
          value: item.paperId,
          icon: coverImg ? <CoverImage imgUrl={coverImg.paperFileUri} /> : <MockIcon />,
          sampleImages,
          sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].paperFileUri : null,
          checked: formValues.partData[activeTab].paper.paperId === item.paperId,
          thickness,
        }
      })
      setPaperItems(paperList)
      if (formValues.partData[activeTab].paper.paperId) {
        handleOnSelect(paperList.find(item => item.paperId === formValues.partData[activeTab].paper.paperId) || paperList[0])
      } else if (paperList.length > 0) {
        handleOnSelect(paperList[0])
      }
      setFormValues({ ...formValues, paper: paperFormValues })
    }
  }, [activeTab])

  useEffect(() => {
    if (formValues.partData[activeTab].paperList.length > 0) {
      const paperList = formValues.partData[activeTab].paperList.map((item, index) => {
        const thicknessOptions = [...new Set(item.paperThickness.map(paper => paper.thickness))]
        const thickness = thicknessOptions.map(value => ({
          value,
          title: `${value} แกรม`,
        }))
        const coverImg = item.paperFiles.find(file => file.type === 'cover')
        const sampleImages = item.paperFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
        const sampleVideo = item.paperFiles.filter(file => file.type === 'video')
        return {
          ...item,
          // eslint-disable-next-line radix
          key: `${activeTab}-${index}`,
          activeTab: parseInt(activeTab),
          label: item.name,
          value: item.paperId,
          icon: coverImg ? <CoverImage imgUrl={coverImg.paperFileUri} /> : <MockIcon />,
          sampleImages,
          sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].paperFileUri : null,
          checked: formValues.partData[activeTab].paper.paperId === item.paperId,
          thickness,
        }
      })
      setPaperItems(paperList)
      // if (formValues.partData[activeTab].paper.paperId) {
      //   handleOnSelect(paperList.find(item => item.paperId === formValues.partData[activeTab].paper.paperId) || paperList[0])
      // } else if (paperList.length > 0) {
      //   handleOnSelect(paperList[0])
      // }
      setFormValues({ ...formValues, paper: paperFormValues })
    }
  }, [formValues.partData[activeTab].paper.paperId])

  useEffect(() => {
    setFormValues({ ...formValues, paper: paperFormValues })
  }, [paperFormValues])

  return <Div display='flex' flexDirection='column' moreStyle={{ rowGap: '20px' }}>
    <NotchedContainer
      title='ประเภทกระดาษ'
      titleIcon={paperIconImage}
    >
      <Row type='flex' gutter={16}>
        <Col xs={24} sm={11} md={11} span={16}>
          <Query
            query={LIST_PAPER}
            notifyOnNetworkStatusChange
            fetchPolicy='network-only'
            variables={{
              limit: 10000,
              offset: 0,
            }}
            onCompleted={(data) => {
              const paperList = formValues.partData[activeTab].paperList.map((item, index) => {
                const thicknessOptions = [...new Set(item.paperThickness.map(paper => paper.thickness))]
                const thickness = thicknessOptions.map(value => ({
                  value,
                  title: `${value} แกรม`,
                }))
                const coverImg = item.paperFiles.find(file => file.type === 'cover')
                const sampleImages = item.paperFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
                const sampleVideo = item.paperFiles.filter(file => file.type === 'video')
                return {
                  ...item,
                  activeTab,
                  label: item.name,
                  value: item.paperId,
                  icon: coverImg ? <CoverImage imgUrl={coverImg.paperFileUri} /> : <MockIcon />,
                  sampleImages,
                  sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].paperFileUri : null,
                  checked: formValues.partData[activeTab].paper.paperId === item.paperId,
                  thickness,
                }
              })
              setPaperItems(paperList)
              if (formValues.partData[activeTab].paper.paperId) {
                handleOnSelect(paperList.find(item => item.paperId === formValues.partData[activeTab].paper.paperId) || paperList[0])
              } else if (paperList.length > 0) {
                handleOnSelect(paperList[0])
              }
              setRemark(data.listPaper.remark)
            }}
            >
              {
                ({ loading, data, refetch }) => {
                  if (loading) {
                    return <Div moreStyle={{ width: '100%' }}>
                      <LoadingIcon color={theme.color.grey} />
                    </Div>
                  }
                  return (
                    <OptionsList
                      title='เลือกตัวเลือกที่ต้องการ'
                      options={paperItems}
                      circle
                      onSelect={handleOnSelect}
                      formValues={formValues}
                      disabled={disabled}
                    />
                  )
                }
              }
          </Query>
        </Col>
        <Col xs={24} sm={13} md={13} span={16}>
          <DetailWrapper>
            <OptionDescription
              title={selectedPaper && selectedPaper.name}
              description={selectedPaper && selectedPaper.description}
              videoSrc={selectedPaper && selectedPaper.sampleVideoUri}
            />
            {
              selectedPaper && selectedPaper.sampleImages.length > 0 && <SampleImageList
                title='รูปภาพตัวอย่าง'
                data={selectedPaper.sampleImages.map(item => ({ fileUrl: item.paperFileUri, ordering: item.ordering }))}
              />
            }
            <SelectBox
              title='ความหนากระดาษ'
              placeholder='เลือกความหนา'
              value={formValues.partData[activeTab].paper.selectedThickness}
              options={selectedPaper && selectedPaper.thickness}
              onChange={handleSelectThickness}
              thin={true}
              disabled={disabled || (selectedPaper && selectedPaper.thickness.length === 0)}
            />
            {
              remark && <Recommendation
                value={remark.description}
              />
            }
          </DetailWrapper>
        </Col>
      </Row>
    </NotchedContainer>
</Div>
}

export default PaperSelector
