import styled from 'styled-components'
import { theme } from '../../../../styles/_variables'


export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`

export const DivToolbarEditor = styled.div`
    width: fit-content;
    background-color: ${theme.color.offWhite};
    margin-bottom: 16px;
    border-radius: 3px;
`

export const Text = styled.p`
  font-size: ${theme.fonts.size.extraNormal};
  margin-bottom: 0px;
`

export const Span = styled.span`
  color: ${props => (props.error ? theme.color.error : theme.color.textColor)};
`

export default Label
