import React from 'react'
import {
  WidthContainer, FullWidthContainer,
} from './styled'
import ExtraServiceTitleInput from '../title'
import ExtraServiceDescriptionInput from '../description'
import ExtraServiceCostTable from '../cost-table'
import ExtraServiceCostMinimumInput from '../cost-minimum'

const ExtraServiceForm = ({
  formValues,
  setFormValues,
  isValidate,
  setIsValidate,
  action,
}) => (
  <>
    <WidthContainer>
      <ExtraServiceTitleInput
        label={'ชื่อบริการเสริม'}
        data={formValues}
        onInputChange={setFormValues}
        setIsValidate={setIsValidate}
        isValidate={isValidate}
      />
      <ExtraServiceDescriptionInput
        data={formValues}
        onInputChange={setFormValues}
      />
    </WidthContainer>
    <FullWidthContainer>
      <ExtraServiceCostTable
        data={formValues}
        onChange={setFormValues}
        formValues={formValues}
      />
      <ExtraServiceCostMinimumInput
        data={formValues}
        onInputChange={setFormValues}
        action={action}
      />
    </FullWidthContainer>
  </>
)


export default ExtraServiceForm
