import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Query, Mutation } from 'react-apollo'
import {
  Div, H4, Button, LoadingIcon,
} from 'konsys-ui'
import { message, Popconfirm } from 'antd'
import { theme } from '../../../../styles/_variables'
import PATH from '../../../../constants/path'
import { GET_ADDITIONAL_SERVICE_DETAIL } from '../../../../constants/graphql/query'
import { DELETE_ADDITIONAL_SERVICE, EDIT_ADDITIONAL_SERVICE } from '../../../../constants/graphql/mutation'
import ExtraServiceForm from '../components/extra-service-form'
import BreadCrumb from '../../../../components/common/breadcrumb'


const { color } = theme


const ExtraServiceDetail = ({ match }) => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    costMinimum: 0,
    headerExtraService: [{
      title: 'จำนวนผลิต (ชิ้น)',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: 'number',
      editable: false,
      editColumn: false,
      alignCell: 'center',
    }],
    dataExtraService: [],
  })
  return (
    <Div>
      <Query
        query={GET_ADDITIONAL_SERVICE_DETAIL}
        variables={{
          id: parseInt(match.params.id, 10),
        }}
        notifyOnNetworkStatusChange
        onCompleted={(data) => {
          const extraServiceData = data.getAdditionalServiceDetail.additionalService
          if (data.getAdditionalServiceDetail.success) {
            const extraServiceDataColumn = extraServiceData.additionalServiceCostColumnName.map(item => ({
              title: item.name,
              dataIndex: item.type,
              key: item.type,
              inputType: 'number',
              editable: true,
              editColumn: item.type !== 'column1',
              alignCell: 'center',
            }))
            setFormValues({
              ...formValues,
              name: extraServiceData.name,
              description: extraServiceData.description,
              headerExtraService: [...formValues.headerExtraService, ...extraServiceDataColumn],
              dataExtraService: extraServiceData.additionalServiceCost.map((item, index) => ({
                key: index,
                quantity: item.quantity,
                column1: item.column1,
                column2: item.column2,
                column3: item.column3,
                column4: item.column4,
                column5: item.column5,
              })),
              costMinimum: extraServiceData.minimumCost,
            })
          } else {
            message.error(data.getAdditionalServiceDetail.message)
            setTimeout(() => {
              window.location = PATH.extraServiceSetting
            }, 1000)
          }
        }}
      >
        {
          ({ loading, data }) => {
            if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
            const { additionalServiceId, name } = data.getAdditionalServiceDetail.additionalService
            return (
              <>
                <Div display='flex' flexDirection='column'>
                  <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
                    <H4 bold color={color.primaryColor} margin='32px 0'>
                      <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                        <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                      </Link> {' > '}
                      <Link to='/package-setting/extra-service' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                        <H4 bold color={color.primaryColor}>บริการเสริม</H4>
                      </Link> {' > '} แก้ไขข้อมูล
                    </H4>
                    <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
                      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                        <DeleteButton additionalServiceId={additionalServiceId} />
                        <SaveButton
                          additionalServiceId={additionalServiceId}
                          formValues={formValues}
                          callMutation={callMutation}
                          setCallMutation={setCallMutation}
                          setIsValidate={setIsValidate}
                        />
                      </Div>
                    </Div>
                  </Div>
                  {
                    data.getAdditionalServiceDetail.additionalService && <ExtraServiceForm
                      formValues={formValues}
                      setFormValues={setFormValues}
                      isValidate={isValidate}
                      setIsValidate={setIsValidate}
                      action={'detail'}
                    />
                  }
                </Div>
              </>
            )
          }
        }
      </Query>
    </Div>
  )
}

const DeleteButton = ({ additionalServiceId }) => (
  <Mutation mutation={DELETE_ADDITIONAL_SERVICE}>
    {
      doDeleteAdditionalService => (
        <Popconfirm
          title="ยืนยันการลบบริการเสริมหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก"
          onConfirm={() => {
            doDeleteAdditionalService({
              variables: {
                id: parseInt(additionalServiceId, 10),
              },
            }).then((res) => {
              if (res.data.deleteAdditionalService) {
                if (res.data.deleteAdditionalService.success) {
                  message.success(res.data.deleteAdditionalService.message)
                  setTimeout(() => {
                    window.location = PATH.extraServiceSetting
                  }, 100)
                } else {
                  message.error(res.data.deleteAdditionalService.message)
                }
              }
            })
          }}
          okText='ยืนยัน'
          cancelText='ยกเลิก'
        >
          <Button text='ลบ' icon='fa fa-trash' ghost margin='0 16px 0 0' color={color.error}/>
        </Popconfirm>
      )
    }
  </Mutation>
)

const SaveButton = ({
  additionalServiceId,
  formValues,
  callMutation,
  setCallMutation,
  setIsValidate,
}) => (
  <Mutation mutation={EDIT_ADDITIONAL_SERVICE}>
    {
      doEditAdditionalService => (
        <Button
          text='บันทึก'
          icon='fa fa-save'
          inverse
          margin='0 16px 0 0'
          onClick={() => {
            doEditAdditionalService(true)
            if (formValues.name === '') {
              message.warning('กรุณากรอกข้อมูลชื่อบรรจุภัณฑ์')
              setCallMutation(false)
              setIsValidate(false)
              return
            }

            if (formValues.name.match(new RegExp(/^\s/)) !== null) {
              message.warning('กรุณากรอกข้อมูลชื่อบรรจุภัณฑ์ให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
              setCallMutation(false)
              setIsValidate(false)
              return
            }
            const input = {
              additionalServiceId,
              name: formValues.name,
              description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
              minimumCost: parseFloat(formValues.costMinimum),
              additionalServiceCostColumnName: formValues.headerExtraService.filter(column => /column*/.test(column.key)).map(column => ({
                additionalServiceId,
                name: column.title,
                type: column.key,
              })),
              additionalServiceCost: formValues.dataExtraService.map(row => ({
                additionalServiceId,
                quantity: parseInt(row.quantity, 10),
                column1: parseFloat(row.column1),
                column2: parseFloat(row.column2),
                column3: parseFloat(row.column3),
                column4: parseFloat(row.column4),
                column5: parseFloat(row.column5),
              })),
            }
            doEditAdditionalService({
              variables: {
                input,
              },
            }).then(({ data }) => {
              if (data.editAdditionalService) {
                setCallMutation(false)
                message.success(data.editAdditionalService.message)
                setTimeout(() => {
                  window.location = PATH.extraServiceSetting
                }, 350)
              } else {
                setCallMutation(false)
                if (data.editAdditionalService.message === 'ชื่อ บริการเสริม นี้มีอยู่ในระบบแล้ว') {
                  setIsValidate(false)
                  setCallMutation(false)
                }
                message.error(data.editAdditionalService.message)
              }
            })
          }}
          disabled={callMutation}
        />
      )
    }
  </Mutation>
)

export default ExtraServiceDetail
