import styled from 'styled-components'

export const FormWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
row-gap: 10px;
`

export default FormWrapper
