/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react'
import { message, Popconfirm } from 'antd'
import {
  Div, Input,
} from 'konsys-ui'
import {
  DivToolbar, Label, LabelColumn, ButtonAction, LabelCell, DivCell,
  DivActionColumn, DivTitle, ButtonActionColumn, DivEditColumn, ButtonDelete,
  Table, DivRowSize, DivItemCell, InputNumber, TextColumn, Select, ButtonDefault,
  DivRowActionSize, DivRowCellActionSize,
} from './styled'

const EditableCell = ({
  editing,
  children,
  inputType,
  record,
  dataIndex,
  title,
  onChangeCell,
  alignCell = 'left',
  style,
  ...restProps
}) => {
  const [value, setValue] = useState(record ? record[dataIndex] : '')

  useEffect(() => {
    if (record && record[dataIndex]) {
      setValue(record[dataIndex])
    }
  }, [record])

  const inputNode = inputType === 'number'
    ? <InputNumber
        type="number"
        min={0}
        max={9999}
        value={value}
        width="100px"
        defaultValue={0}
        onChange={(e) => {
          setValue(parseFloat(e))
          onChangeCell(parseFloat(e), dataIndex)
        }}
        onKeyPress={(e) => {
          if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
            e.preventDefault()
          }
        }}
      /> : <Input
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          onChangeCell(e.target.value, dataIndex)
        }}
      />
  return <td {...restProps}><DivCell alignCell={alignCell}>{editing ? inputNode : <LabelCell>{children}</LabelCell>}</DivCell></td>
}

export const TableIncrementalStep = ({
  title,
  pagination = false,
  headers = [],
  data = [],
  onSubmitEditRow,
  onSubmitEditColumn,
  onSubmitNewColumn,
  maxColumns = 6,
  unit = 'บาท/ตร.นิ้ว',
}) => {
  const [columns, setColumns] = useState(headers)
  const [rows, setRows] = useState(data)
  const [isEditColumn, setIsEditColumn] = useState(false)
  const [isNewColumn, setIsNewColumn] = useState(false)
  const [editingKey, setEditingKey] = useState('')
  const [dataNewColumn, setDataNewColumn] = useState(`>= 0x0 นิ้ว (${unit})`)
  const [dataRowEditing, setDataRowEditing] = useState({})
  const [dataColumnEditing, setDataColumnEditing] = useState([])
  const isEditingRow = record => record.key === editingKey

  useEffect(() => {
    setColumns(headers)
  }, [headers])

  useEffect(() => {
    setRows(data)
  }, [data])

  const renderColumn = () => {
    let tmp = []
    if (isNewColumn) {
      tmp = [...columns].map((column, index) => {
        let colDataIndex
        if (/column*/.test(column.dataIndex)) {
          colDataIndex = `column${index}`
        } else {
          colDataIndex = column.dataIndex
        }
        return {
          title: <LabelColumn>{column.title.split('(')[0]}<br/>{column.title.split('(')[1] && '('}{column.title.split('(')[1]}</LabelColumn>,
          dataIndex: colDataIndex,
          key: colDataIndex,
          align: 'center',
          onCell: () => ({
            alignCell: column.alignCell,
          }),
        }
      })
      tmp.push({
        title: <DivRowActionSize>
          <DivRowCellActionSize>
            <DivItemCell>
              <TextColumn>{'>='}</TextColumn>
            </DivItemCell>
            <DivItemCell>
              <InputNumber
                type="number"
                min={0}
                max={9999}
                defaultValue={0}
                style={{ width: '60px' }}
                value={dataNewColumn.split(' ')[1].split('x')[0]}
                onChange={(e) => {
                  if ((parseFloat(e, 10) >= 0)) {
                    setDataNewColumn(`>= ${e}x${dataNewColumn.split(' ')[1].split('x')[1]} นิ้ว ${dataNewColumn.split(' ')[3]}`)
                  }
                }}
                onKeyPress={(e) => {
                  if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                    e.preventDefault()
                  }
                }}
              />
            </DivItemCell>
            <DivItemCell>
              <TextColumn>x</TextColumn>
            </DivItemCell>
            <DivItemCell>
              <InputNumber
                type="number"
                min={0}
                max={9999}
                defaultValue={0}
                style={{ width: '60px' }}
                value={dataNewColumn.split(' ')[1].split('x')[1]}
                onChange={(e) => {
                  if ((e === '' || parseFloat(e, 10) >= 0)) {
                    setDataNewColumn(`>= ${dataNewColumn.split(' ')[1].split('x')[0]}x${e} นิ้ว ${dataNewColumn.split(' ')[3]}`)
                  }
                }}
                onKeyPress={(e) => {
                  if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                    e.preventDefault()
                  }
                }}
              />
            </DivItemCell>
            <DivItemCell>
              <TextColumn>นิ้ว</TextColumn>
            </DivItemCell>
          </DivRowCellActionSize>
          <DivRowCellActionSize>
            <DivItemCell>
              <TextColumn>{`(${unit})`}</TextColumn>
            </DivItemCell>
          </DivRowCellActionSize>
        </DivRowActionSize>,
        dataIndex: `column${(columns.length)}`,
        key: `column${(columns.length)}`,
        align: 'center',
        alignCell: 'center',
        onCell: () => ({
          alignCell: 'center',
        }),
      })
    } else {
      tmp = columns.map((column, index) => {
        let onCell = {
          onCell: () => ({
            alignCell: column.alignCell,
          }),
        }
        if (column.editable) {
          onCell = {
            onCell: record => ({
              record,
              inputType: column.inputType,
              dataIndex: column.dataIndex,
              alignCell: column.alignCell,
              title: column.title,
              editing: isEditingRow(record),
              onChangeCell: (e, dataIndex) => {
                setDataRowEditing({
                  ...dataRowEditing,
                  [dataIndex]: e,
                })
              },
            }),
          }
        }
        return {
          title: (isEditColumn && column.editColumn)
            ? (
              <DivEditColumn>
                {
                  (dataColumnEditing[index].title.split(' ').length === 4
                  && dataColumnEditing[index].title.split(' ')[0] === '>='
                  && dataColumnEditing[index].title.split(' ')[2] === 'นิ้ว'
                  && dataColumnEditing[index].title.split(' ')[3].includes('(')
                  && dataColumnEditing[index].title.split(' ')[3].includes(')')) ? <DivRowActionSize>
                    <DivRowCellActionSize>
                      <DivItemCell>
                        <TextColumn>{'>='}</TextColumn>
                      </DivItemCell>
                      <DivItemCell>
                        <InputNumber
                          type="number"
                          min={0}
                          max={9999}
                          defaultValue={0}
                          style={{ width: '60px' }}
                          value={dataColumnEditing[index].title.split(' ')[1].split('x')[0]}
                          onChange={(e) => {
                            if ((parseFloat(e, 10) >= 0)) {
                              const tmpData = [...dataColumnEditing]
                              tmpData[index].title = `>= ${e}x${dataColumnEditing[index].title.split(' ')[1].split('x')[1]} นิ้ว ${dataColumnEditing[index].title.split(' ')[3]}`
                              setDataColumnEditing(tmpData)
                            }
                          }}
                          onKeyPress={(e) => {
                            if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                              e.preventDefault()
                            }
                          }}
                        />
                      </DivItemCell>
                      <DivItemCell>
                        <TextColumn>x</TextColumn>
                      </DivItemCell>
                      <DivItemCell>
                        <InputNumber
                          type="number"
                          min={0}
                          max={9999}
                          defaultValue={0}
                          style={{ width: '60px' }}
                          value={dataColumnEditing[index].title.split(' ')[1].split('x')[1]}
                          onChange={(e) => {
                            if ((parseFloat(e, 10) >= 0)) {
                              const tmpData = [...dataColumnEditing]
                              tmpData[index].title = `>= ${dataColumnEditing[index].title.split(' ')[1].split('x')[0]}x${e} นิ้ว ${dataColumnEditing[index].title.split(' ')[3]}`
                              setDataColumnEditing(tmpData)
                            }
                          }}
                          onKeyPress={(e) => {
                            if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                              e.preventDefault()
                            }
                          }}
                        />
                      </DivItemCell>
                      <DivItemCell>
                        <TextColumn>นิ้ว</TextColumn>
                      </DivItemCell>
                    </DivRowCellActionSize>
                    <DivRowCellActionSize>
                      <DivItemCell>
                        <TextColumn>{`(${unit})`}</TextColumn>
                      </DivItemCell>
                    </DivRowCellActionSize>
                    {
                      index === 1 && <DivItemCell>
                        <ButtonDefault
                          size="small"
                          onClick={() => {
                            const tmpData = [...dataColumnEditing]
                            tmpData[index].title = `ราคาเริ่มต้น (${unit})`
                            setDataColumnEditing(tmpData)
                          }
                          }
                        >
                        ค่าเริ่มต้น
                        </ButtonDefault>
                      </DivItemCell>
                    }
                  </DivRowActionSize> : <DivRowActionSize>
                  <DivRowCellActionSize>
                      <DivItemCell>
                        {/* <TextColumn>{dataColumnEditing[index].title}</TextColumn> */}
                        <Input
                          style={{ marginRight: '10px' }}
                          disabled
                          value={dataColumnEditing[index].title.split(' (บาท/ตร.นิ้ว)')[0]}
                          onChange={(e) => {
                            const tmpData = [...dataColumnEditing]
                            tmpData[index].title = e.target.value
                            setDataColumnEditing(tmpData)
                          }}
                        />
                      </DivItemCell>
                    </DivRowCellActionSize>
                    <DivRowCellActionSize>
                      <DivItemCell>
                        <TextColumn>{`(${unit})`}</TextColumn>
                      </DivItemCell>
                    </DivRowCellActionSize>
                  </DivRowActionSize>
                }
                {
                  index !== 1 ? <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => {
                      let resultColumns = [...columns]
                      resultColumns.splice(index, 1)
                      let resultRows = [...rows]
                      if (resultColumns.length > 2) {
                        resultColumns = resultColumns.map((item, indexColumn) => {
                          if (indexColumn > 1) {
                            const OldKey = item.dataIndex
                            const NewKey = `column${(indexColumn)}`
                            resultRows = resultRows.map((resultRow) => {
                              const tmpItem = { ...resultRow }
                              const valueOld = resultRow[OldKey]
                              return {
                                ...tmpItem,
                                [NewKey]: valueOld,
                              }
                            })
                            return {
                              ...item,
                              dataIndex: NewKey,
                              key: NewKey,
                            }
                          }
                          return {
                            ...item,
                          }
                        })
                      }
                      resultRows = resultRows.map((resultRow) => {
                        const tmpRow = { ...resultRow }
                        // delete tmpRow[`column${(resultColumns.length)}`]
                        return {
                          ...tmpRow,
                          [`column${(resultColumns.length)}`]: 0,
                        }
                      })
                      setColumns(resultColumns)
                      setDataColumnEditing(resultColumns)
                      setRows(resultRows)
                    }}
                  >
                    <ButtonDelete style={{ marginLeft: '8px' }} shape="circle" icon="delete" size="small"/>
                  </Popconfirm> : ''
                }
              </DivEditColumn>
            )
            : <LabelColumn>{column.title.split('(')[0]}<br/>{column.title.split('(')[1] && '('}{column.title.split('(')[1]}</LabelColumn>,
          dataIndex: column.dataIndex,
          key: column.key,
          align: 'center',
          // width: '10%',
          ...onCell,
        }
      })
    }
    tmp.push({
      title: <LabelColumn>{'คำสั่ง'}</LabelColumn>,
      dataIndex: 'action',
      key: 'action',
      width: '120px',
      align: 'center',
      onCell: () => ({
        alignCell: 'center',
      }),
      render: (text, record) => {
        const editable = isEditingRow(record)
        return editable ? (
          <ButtonAction
            text='ยืนยัน'
            inverse
            icon='fa fa-floppy-o'
            onClick={() => {
              const result = [...rows]
              result[record.key] = { ...dataRowEditing }
              setRows(result)
              onSubmitEditRow(record.key, dataRowEditing)
              setDataRowEditing({})
              setEditingKey('')
            }}
          />
        ) : (
          <ButtonAction
            disabled={editingKey !== '' || isNewColumn || isEditColumn}
            text='แก้ไข'
            inverse
            icon='fa fa-pencil-square-o'
            onClick={() => {
              setDataRowEditing(record)
              setEditingKey(record.key)
            }}
          />
        )
      },
    })
    return tmp
  }

  return (
    <Div>
      <DivToolbar>
        <DivTitle>
          <Label>{title}</Label>
        </DivTitle>
        <DivActionColumn>
          { isEditColumn || isNewColumn
            ? (
              <>
              <ButtonActionColumn
                text='Save'
                icon='fa fa-floppy-o'
                onClick={() => {
                  if (isEditColumn) {
                    const tmp = dataColumnEditing
                    // if (tmp[1]) tmp[1].title = `${tmp[1].title} (${unit})`
                    onSubmitEditColumn(tmp, rows)
                    setDataColumnEditing([])
                    setIsEditColumn(false)
                  } else {
                    onSubmitNewColumn(dataNewColumn, `column${(columns.length)}`)
                    setDataNewColumn(`>= 0x0 นิ้ว (${unit})`)
                    setIsNewColumn(false)
                  }
                }}
              />
              <ButtonActionColumn
                text='Cancel'
                icon='fa fa-ban'
                style={{ marginLeft: '10px' }}
                inverse
                onClick={() => {
                  if (isEditColumn) {
                    setDataColumnEditing([])
                    setColumns(headers)
                    setRows(data)
                    setIsEditColumn(false)
                  } else {
                    setDataNewColumn(`>= 0x0 นิ้ว (${unit})`)
                    setIsNewColumn(false)
                  }
                }}
              />
              </>
            )
            : (
              <>
              <ButtonActionColumn
                disabled={editingKey !== ''}
                text='Edit Column'
                icon='fa fa-pencil-square-o'
                onClick={() => {
                  setDataColumnEditing([...columns])
                  setIsEditColumn(true)
                }}
              />
              <ButtonActionColumn
                disabled={editingKey !== ''}
                style={{ marginLeft: '10px' }}
                text='New Column'
                icon='far fa-plus'
                inverse
                onClick={() => {
                  if (columns.length < maxColumns) {
                    onSubmitNewColumn(dataNewColumn, `column${(columns.length)}`)
                    setDataNewColumn(`>= 0x0 นิ้ว (${unit})`)
                    // setIsNewColumn(true)
                  } else {
                    message.warning('ไม่สามารถเพิ่ม column ได้')
                  }
                }
              }
              />
              </>
            )}
        </DivActionColumn>
      </DivToolbar>
      <Div>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          pagination={pagination}
          columns={renderColumn()}
          dataSource={rows}
        />
      </Div>
    </Div>
  )
}

export default TableIncrementalStep
