import React from 'react'
import { Link } from 'react-router-dom'
import { Popconfirm, message } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import parseHtml from 'html-react-parser'

import PATH from '../../../constants/path'
import { LIST_ADDITIONAL_SERVICE } from '../../../constants/graphql/query'
import { DELETE_ADDITIONAL_SERVICE } from '../../../constants/graphql/mutation'
import {
  ButtonAction,
  SimpleTable, ActionGroupWrapper,
  WidthContainer, Column, Description,
} from './styled'
import { theme } from '../../../styles/_variables'
import RecommendationSetting from '../recommendation-setting'
import BreadCrumb from '../../../components/common/breadcrumb'


const { color } = theme

const columns = [{
  title: 'ตัวเลือกบริการเสริม',
  dataIndex: 'name',
  render: text => <Column>{text}</Column>,
  align: 'center',
},
{
  title: 'คำอธิบาย',
  dataIndex: 'description',
  render: text => <Column>{text}</Column>,
  align: 'center',
},
{
  title: 'คำสั่ง',
  dataIndex: 'action',
  align: 'center',
  width: '120px',
},
]

const ExtraServiceSetting = props => (
  <Div>
    <Div display='flex' flexDirection='row' justifyContent='space-between' margin='32px 0px 0px 0px' alignItems='center'>
        <Div display='flex' flexDirection='column'>
        <H4 bold color={color.primaryColor}>
          <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
            <H4 bold color={color.primaryColor}>Catalog Settings</H4>
          </Link> {' > '} บริการเสริม
        </H4>
        </Div>
        <Link to='/package-setting/extra-service-create' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
          <Button
            htmlType="submit"
            text='New'
            icon='fal fa-plus'
            inverse
          />
        </Link>
    </Div>
    <TableListExtraService history={props.history} />
  </Div>
)

const TableListExtraService = ({
  history,
}) => (
  <WidthContainer>
    <Query
      query={LIST_ADDITIONAL_SERVICE}
      fetchPolicy='cache-and-network'
      variables={{
        limit: 10000,
        offset: 0,
      }}
    >
      {
        ({ loading, data, refetch }) => {
          let extraServiceList = []
          let extraServiceRemark = null
          if (!loading) {
            extraServiceList = data.listAdditionalService.additionalService.map(item => ({
              name: item.name,
              description: <Description>{item.description ? parseHtml(item.description) : '-'}</Description>,
              action: <ActionGroup rowData={item} onEdit={() => history.push(`${PATH.extraServiceDetail}/${item.additionalServiceId}`)}/>,
            }))
            extraServiceRemark = data.listAdditionalService.remark
          }
          return (
            <>
              {/* <RecommendationSetting
                moduleName='additional_service'
                data={extraServiceRemark}
                refetch={() => refetch()}
              /> */}
              <SimpleTable
                loading={loading}
                columns={columns}
                dataSource={extraServiceList}
                pagination={false}
              />
            </>
          )
        }
      }
    </Query>
  </WidthContainer>
)

const ActionGroup = ({ rowData, onEdit }) => (
  <ActionGroupWrapper>
    <ButtonAction small inverse text='แก้ไข' icon='fa fa-pencil-square-o' onClick={onEdit} />
    <Mutation mutation={DELETE_ADDITIONAL_SERVICE}>
      {
        doDeleteExtraService => (
          <Popconfirm
            title='ยืนยันการลบบริการเสริมหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'
            onConfirm={() => {
              doDeleteExtraService({
                variables: {
                  id: parseInt(rowData.additionalServiceId, 10),
                },
              }).then((res) => {
                if (res.data.deleteAdditionalService) {
                  if (res.data.deleteAdditionalService.success) {
                    message.success(res.data.deleteAdditionalService.message)
                    setTimeout(() => {
                      window.location = PATH.extraServiceSetting
                    }, 100)
                  } else {
                    message.error(res.data.deleteAdditionalService.message)
                  }
                }
              })
            }}
            okText='ยืนยัน'
            cancelText='ยกเลิก'
          >
            <ButtonAction small text='ลบ' icon='fa fa-trash-o fa-lg' color={theme.color.error} />
          </Popconfirm>
        )
      }
    </Mutation>
  </ActionGroupWrapper>
)

export default ExtraServiceSetting
