import styled from 'styled-components'
import {
  Button, Div, H4,
} from 'konsys-ui'
import { theme, media, minMedia } from '../../../../styles/_variables'


export const FullWidthContainer = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  margin: 0 auto;
  padding-bottom: 24px;
  ${minMedia.laptopL`
    max-width:  ${props => (!props.isOrder && '59vw')};
    padding: ${props => (props.isOrder ? '0px 60px 24px 60px' : '24px 0px 24px 0px')};
  `}
  ${minMedia.desktop`
    width: 100%;
    max-width:  ${props => (props.isOrder ? '64vw' : '38vw')};
    padding: ${props => (props.isOrder ? '0px 0px 24px 0px' : '24px 0px 24px 0px')};
  `}
  ${media.laptopL`
    max-width:  ${props => (!props.isOrder && '100%')};
    padding: 24px 60px 24px 60px; !important;
  `}
  ${media.mobileL`
    max-width: 100%;
  `}
`

export const TagDiv = styled(Button)`
  border-radius: 32px;
  margin: 4px 8px 4px 0px;
  padding: 4px 8px;
  width: fit-content !important;
  max-width: 300px;
  text-align: left;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  word-break: break-word;
  background-color: ${theme.color.greenPrimary};
  border-color: ${theme.color.greenPrimary};
  i {
    font-size: ${theme.fonts.size.label} !important;
    font-weight: ${theme.fonts.style.medium};
    line-height: ${theme.fonts.size.small};
  }
  span {
    font-size: ${theme.fonts.size.small};
    font-weight: ${theme.fonts.style.medium};
    line-height: ${theme.fonts.size.small};
  }
`

export const Content = styled(Div)`
  margin-top: 20px;
`

export const ItemContent = styled(Div)`
  padding: 6px 0px;
  ${media.mobileL`
    padding: 8px 0px;
  `}
`

export const Description = styled(Div)`
  & > p {
    margin: 0px !important;
    font-family: 'printAble4u', sans-serif;
    font-size: 24px;
    line-height: 1.25;
  }
  margin-top: 12px;
  margin-bottom: 16px;
`

export const LabelTitle = styled(H4)`
  font-size: 48px;
  font-weight: ${theme.fonts.style.medium};
  line-height: 1;
`


export const LabelText = styled.p`
  font-size: 24px;
  font-weight: ${theme.fonts.style.medium};
  margin-bottom: 0px;
  line-height: 1.25;
`

export const LabelSpan = styled.span`
  font-size: 24px;
  font-weight: ${theme.fonts.style.regular};
  margin-bottom: 0px;
`


export default FullWidthContainer
