import styled from 'styled-components'
import { theme, media } from '../../../styles/_variables'


export const SummaryPanel = styled.div`
  width: 100%;
  box-shadow: 0 16px 32px 0px rgba(51, 3, 0, 0.7);
  position: fixed;
  z-index: ${props => (props.active ? '9999' : '9')};
  bottom: 0;
  max-height: ${props => (props.active ? '100vh' : '40px')};
  height: ${props => (props.active ? '100%' : '40px')};
  background: #FFF;
  transition: ${props => (props.active ? 'all 0.3s ease-in' : 'all 0.3s ease-out')};
  touch-action: none;
  -ms-touch-action: none;
  ${media.tablet`
    left: 0;
  `}
`

export const SummaryToggle = styled.div`
  padding: 0px 19px;
  background-color: ${theme.color.greenPrimary};
  height: 40px;
  & > h4 {
    color: ${theme.color.offWhite};
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0px;
    ${media.tablet`
      margin-left: 65px;
    `}
  }
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export default SummaryPanel
