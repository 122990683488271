import React from 'react'
import { Input } from 'antd'
import {
  Div, H4,
} from 'konsys-ui'
import { theme } from '../../../../styles/_variables'

const { color } = theme

const { TextArea } = Input
const MoreDetailInput = ({
  formValues,
  setFormValues,
}) => {
  const handleChange = (e) => {
    const tmpForm = formValues
    tmpForm.moreDetail = e
    setFormValues({ ...tmpForm })
  }
  return (
    <Div>
      <H4 color={color.primaryColor}>รายละเอียดและเทคนิคงานพิมพ์เพิ่มเติม</H4>
      <TextArea
        style={{ fontSize: '22px' }}
        rows={4}
        value={formValues.moreDetail}
        defaultValue={(formValues && formValues.moreDetail) && formValues.moreDetail}
        onChange={e => handleChange(e.target.value)}
      />
    </Div>
  )
}


export default MoreDetailInput
