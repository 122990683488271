import styled from 'styled-components'
import {
  Button,
} from 'konsys-ui'
import { theme, media } from '../../../../styles/_variables'


export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`

export const DivImage = styled.div`
  width: 108px;
  ${media.desktop`
  width: 96px;
  `}
  ${media.laptopL`
    width: 80px;
  `} 
  ${media.laptop`
    width: 64px;
  `}
`

export const DivImageAction = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px 0px;
  width: 108px;
  ${media.desktop`
  width: 96px;
  `}
  ${media.laptopL`
    width: 80px;
  `} 
  ${media.laptop`
    width: 64px;
  `}
`

export const PreviewImageBox = styled.div`
  width: 86px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.desktop`
    width: 96px;
    height: 96px;
  `}
  ${media.laptopL`
    width: 80px;
    height: 80px;
  `}
  ${media.laptop`
    width: 64px;
    height: 64px;
  `}
`

export const PreviewImage = styled.img`
  max-height: 86px;
  max-width 86px;
  border-radius: 1px;
`

export const DeleteImageButton = styled(Button)`
  font-size: ${theme.fonts.size.subTitle};
  padding: 4px 16px;
  width: 80px;
  justify-content: center;
  display: flex;
  border-radius: 8px;
  ${media.desktop`
    font-size: ${theme.fonts.size.extraNormal};
    padding: 2px 8px;
    width: 80px;
  `}
  ${media.laptopL`
    font-size: ${theme.fonts.size.extraNormal};
    padding: 2px 8px;
    width: 80px;
  `}
  ${media.laptop`
    font-size: ${theme.fonts.size.extraNormal};
    padding: 2px 8px;
    width: 80px;
  `}
`

export const FilePanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const DivideText = styled.h4`
  font-weight: 700;
  font-size: 32px;
  margin: 8px 0;
  color: ${theme.color.primaryColor};
  padding: 0px 16px;
`

export default Label
