import styled from 'styled-components'
import { theme } from '../../../../styles/_variables'


export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`

export const DivToolbarEditor = styled.div`
    width: fit-content;
    background-color: ${theme.color.offWhite};
    margin-bottom: 16px;
    border-radius: 3px;
`

export default Label
