import React, { useState, useEffect } from 'react'
import { Popconfirm } from 'antd'
import {
  Div, Input, H4,
} from 'konsys-ui'
import {
  DivToolbar, ButtonAction, LabelCell, DivCell,
  DivActionColumn, ButtonActionNew,
  Table, DivPrice, ButtonActionPrice, DivRowSize,
  DivItemCell, InputNumber,
} from './styled'
import { theme } from '../../../../../styles/_variables'

const { color } = theme


const EditableCell = ({
  editing,
  children,
  inputType,
  record,
  dataIndex,
  title,
  onChangeCell,
  onAddCellPrice,
  onRemoveCellPrice,
  alignCell = 'left',
  style,
  ...restProps
}) => {
  const [value, setValue] = useState(record ? record[dataIndex] : '')
  useEffect(() => {
    if (record && record[dataIndex]) {
      setValue(record[dataIndex])
    }
  }, [record])

  let inputNode = <DivItemCell>
    <InputNumber
      type="number"
      value={value}
      width="100px"
      min={0}
      max={9999}
      step="1"
      onChange={(e) => {
        setValue(e)
        onChangeCell(e, dataIndex)
      }}
      onKeyPress={(e) => {
        if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
          e.preventDefault()
        }
      }}
    />
  </DivItemCell>
  let cellNode = <LabelCell>{children}</LabelCell>
  if (dataIndex === 'size') {
    inputNode = (<Div>
      {value.map((item, index) => (
        <DivRowSize key={index}>
          <DivItemCell>
            <InputNumber
              type="number"
              width="60px"
              min={0}
              max={9999}
              value={item.width}
              onChange={(e) => {
                const tmpValue = [...value]
                tmpValue[index].width = e
                setValue(tmpValue)
                onChangeCell(tmpValue, dataIndex)
              }}
              onKeyPress={(e) => {
                if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                  e.preventDefault()
                }
              }}
            />
          </DivItemCell>
          <DivItemCell>
          X
          </DivItemCell>
          <DivItemCell>
          <InputNumber
            type="number"
            width="60px"
            value={item.height}
            min={0}
            max={9999}
            onChange={(e) => {
              const tmpValue = [...value]
              tmpValue[index].height = e
              setValue(tmpValue)
              onChangeCell(tmpValue, dataIndex)
            }}
            onKeyPress={(e) => {
              if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault()
              }
            }}
          />
          </DivItemCell>
        </DivRowSize>
      ))}
    </Div>)
    cellNode = (<Div>
      {value.map(item => (<LabelCell key={item.key}>{item.width} X {item.height}</LabelCell>))}
    </Div>)
  } else if (dataIndex === 'price') {
    inputNode = (
      <Div>{value.map((item, index) => (
        <DivPrice
          key={index}
        >
          <DivItemCell>
            <InputNumber
              type="number"
              value={item}
              width="100px"
              min={0}
              max={9999}
              onChange={(e) => {
                const tmpValue = [...value]
                tmpValue[index] = e
                setValue(tmpValue)
                onChangeCell(tmpValue, dataIndex)
              }}
              onKeyPress={(e) => {
                if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                  e.preventDefault()
                }
              }}
            />
          </DivItemCell>
        </DivPrice>))}
      </Div>
    )

    cellNode = (
      <Div>{record[dataIndex].map(item => (<LabelCell key={item.key}>{item}</LabelCell>))}</Div>
    )
  }
  return (
    <td {...restProps}>
      <DivCell alignCell={alignCell}>
        {editing ? inputNode : cellNode}
      </DivCell>
    </td>
  )
}

export const BlockCostTable = ({
  pagination = false,
  data = [],
  onSubmitEditRow,
  onSubmitDeleteRow,
  onAddRow,
}) => {
  const [columns] = useState([{
    title: 'ขนาดแม่พิมพ์ปั๊มฟอยล์-ทองเค (นิ้ว)',
    dataIndex: 'size',
    key: 'size',
    align: 'center',
    alignCell: 'center',
    editable: true,
  }, {
    title: 'ราคา (บาท/ตร.นิ้ว)',
    dataIndex: 'price',
    key: 'price',
    align: 'center',
    alignCell: 'center',
    editable: true,
  }])
  const [rows, setRows] = useState(data)
  const [editingKey, setEditingKey] = useState('')
  const [dataRowEditing, setDataRowEditing] = useState({})

  useEffect(() => {
    setRows(data)
  }, [data])

  const isEditingRow = record => record.key === editingKey
  const renderColumn = () => {
    let tmp = [...columns]
    tmp = columns.map((column) => {
      let onCell = {
        onCell: record => ({
          record,
          dataIndex: column.dataIndex,
          alignCell: column.alignCell,
          title: column.title,
        }),
      }
      if (column.editable) {
        onCell = {
          onCell: record => ({
            record,
            dataIndex: column.dataIndex,
            alignCell: column.alignCell,
            title: column.title,
            editing: isEditingRow(record),
            onChangeCell: (value, dataIndex) => {
              setDataRowEditing({
                ...dataRowEditing,
                [dataIndex]: value,
              })
            },
            onRemoveCellPrice: (indexRemove) => {
              const tmpRow = { ...dataRowEditing }
              tmpRow.size.splice(indexRemove, 1).map((item, indexItem) => ({ ...item, key: indexItem }))
              tmpRow.price.splice(indexRemove, 1)
              setDataRowEditing({
                ...tmpRow,
              })
            },
            onAddCellPrice: () => {
              const tmpRow = { ...dataRowEditing }
              tmpRow.size.push({ width: 0, height: 0, key: tmpRow.size.length })
              tmpRow.price.push(0)
              setDataRowEditing({
                ...tmpRow,
              })
            },
          }),
        }
      }
      return {
        title: column.title,
        dataIndex: column.dataIndex,
        key: column.key,
        align: 'center',
        // width: '10%',
        ...onCell,
      }
    })
    tmp.push({
      title: 'คำสั่ง',
      dataIndex: 'action',
      key: 'action',
      width: '120px',
      align: 'center',
      onCell: () => ({
        alignCell: 'center',
      }),
      render: (text, record) => {
        const editable = isEditingRow(record)
        return editable ? (
          <Div>
            <Div>
              <ButtonAction
                text='ยืนยัน'
                inverse
                icon='fa fa-floppy-o'
                onClick={() => {
                  const result = [...rows]
                  result[record.key] = { ...dataRowEditing }
                  setRows(result)
                  onSubmitEditRow(record.key, dataRowEditing)
                  setDataRowEditing({})
                  setEditingKey('')
                }}
              />
            </Div>
            <Div>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={async () => {
                  let result = JSON.parse(JSON.stringify([...rows]))
                  await result.splice(record.key, 1)
                  result = await result.map((item, index) => ({ ...item, key: index }))
                  await setRows(result)
                  await onSubmitDeleteRow(result)
                  await setDataRowEditing({})
                  await setEditingKey('')
                }}
              >
                <ButtonAction
                  text='ลบ'
                  icon='fa fa-trash-o'
                  color={color.error}
                />
              </Popconfirm>
            </Div>
          </Div>
        ) : (
          <ButtonAction
            disabled={editingKey !== ''}
            text='แก้ไข'
            inverse
            icon='fa fa-pencil-square-o'
            onClick={() => {
              setDataRowEditing(record)
              setEditingKey(record.key)
            }}
          />
        )
      },
    })
    return tmp
  }

  return (
    <Div>
      <DivToolbar>
        <DivActionColumn>
          <H4 bold color={color.primaryColor}>ต้นทุนแม่พิมพ์ปั๊มฟอยล์-ทองเค (Foil Blocks)</H4>
          <ButtonActionNew
            text='New'
            disabled={editingKey !== ''}
            icon='fal fa-plus'
            inverse
            onClick={() => {
              const tmpRows = [...rows]
              const newRow = {
                key: tmpRows.length,
                price: [0],
                size: [{ width: 0, height: 0 }],
                foilingBlockCostId: null,
              }
              tmpRows.push(newRow)
              onAddRow(tmpRows)
              setRows(tmpRows)
              setDataRowEditing(newRow)
              setEditingKey(newRow.key)
            }}
          />
        </DivActionColumn>
      </DivToolbar>
      <Div>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          pagination={pagination}
          columns={renderColumn()}
          dataSource={rows}
        />
      </Div>
    </Div>
  )
}

export default BlockCostTable
