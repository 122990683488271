import React from 'react'
import _ from 'lodash'
import {
  message,
  Row,
  Col,
  Popconfirm,
} from 'antd'
import {
  Div,
} from 'konsys-ui'
import {
  Label, DivImage, DivImageAction, PreviewImage, FilePanel,
  DivideText, PreviewImageBox, DeleteImageButton,
} from './styled'
import SelectGallery from '../../../../../components/common/gallery'
import UploadFile from '../../../../../components/common/upload'
import { theme } from '../../../../../styles/_variables'


const MAXIMUM_FILE_LENGTH = 20
const PaperSampleImageInput = ({
  newFileList,
  setNewFileList,
  data,
}) => {
  const selectGallery = (item) => {
    if (_.isUndefined(_.find(newFileList.filter(x => x.type === 'gallery'), d => d.src === item))) {
      setNewFileList({ ...data, imagePhoto: [...newFileList, { type: 'gallery', src: item }] })
    } else {
      message.error('ไม่สามารถเลือกรูปซ้ำได้')
    }
  }
  const removePreviewFile = (index) => {
    const newPreviewFileList = [...newFileList]
    _.remove(newPreviewFileList, (v, i) => i === index)
    setNewFileList({ ...data, imagePhoto: newPreviewFileList })
  }
  return (
    <Div display='flex' flexDirection='column'>
      <Label>รูปภาพตัวอย่าง</Label>
      {/* Show Preview Image */}
      <Row
        align='middle'
        type='flex'
        gutter={[12, 12]}
      >
        {
          newFileList.map((v, i) => (
            <Col key={i} span={4}>
              <DivImage>
                <PreviewImageBox>
                  <PreviewImage src={v.type === 'sample' ? v.paperFileUri : v.type === 'upload' ? URL.createObjectURL(v.src) : v.src}/>
                </PreviewImageBox>
                <DivImageAction>
                  <Popconfirm
                    placement='top'
                    title={'ยืนยันการลบรูปภาพนี้หรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'}
                    onConfirm={() => removePreviewFile(i)}
                    okText='ยืนยัน'
                    cancelText='ยกเลิก'
                  >
                    <DeleteImageButton
                      color={theme.color.paleBlack}
                      buttonStyle='rectangle'
                      text='ลบรูปนี้'
                    />
                  </Popconfirm>
                </DivImageAction>
              </DivImage>
            </Col>
          ))
        }

      </Row>
      {/* Panel Select from gallery or Upload Files */}
      <FilePanel>
        <SelectGallery
          style={{ marginTop: '-8px' }}
          id='selectGalleryId'
          selectImage={selectGallery}
        />
        <DivideText>หรือ</DivideText>
        <UploadFile
          list={[]}
          id='uploadCoverFileId'
          multiple
          onChange={(fileList) => {
            let upload = false
            let maximumFileLength = false
            const tmpFile = []
            const allowFileType = ['jpg', 'jpeg', 'png']
            for (let i = 0; i < fileList.length; i += 1) {
              if (fileList.length + newFileList.length > MAXIMUM_FILE_LENGTH) {
                maximumFileLength = true
                break
              }
              const splitName = fileList[i].name.split('.')
              if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
              || (newFileList.length > 0 && !_.isUndefined(_.find(newFileList.filter(x => x.type === 'upload'), d => d.name === fileList[i].name)))) {
                upload = false
                break
              } else {
                upload = true
                tmpFile.push({ type: 'upload', src: fileList[i] })
              }
            }
            if (upload || !maximumFileLength) {
              // Set only one image to state
              setNewFileList({ ...data, imagePhoto: [...newFileList, ...tmpFile] })
            } else if (maximumFileLength) {
              message.error(`ไม่สามารถอัพโหลดไฟล์ได้มากกว่า ${MAXIMUM_FILE_LENGTH} รูป`)
            } else {
              message.error('สามารถอัพโหลดได้เฉพาะไฟล์ jpg, jpeg, png และไม่สามารถอัพรูปซ้ำได้')
            }
          }}
          fileType="image/*"
        />
      </FilePanel>
  </Div>
  )
}

export default PaperSampleImageInput
