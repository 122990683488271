import React from 'react'
import { Link } from 'react-router-dom'
import { Popconfirm, message } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import PATH from '../../../constants/path'

import {
  ButtonAction, SimpleTable, ActionGroupWrapper, LabeColumn,
} from './styled'
import { theme } from '../../../styles/_variables'
import { DELETE_COATING } from '../../../constants/graphql/mutation'
import { LIST_COATING } from '../../../constants/graphql/query'
import RecommendationSetting from '../recommendation-setting'
import BreadCrumb from '../../../components/common/breadcrumb'


const { color } = theme

const columns = [
  {
    title: 'วิธีการเคลือบ',
    dataIndex: 'name',
    render: text => <LabeColumn>{text}</LabeColumn>,
    align: 'center',
  },
  {
    title: 'คำสั่ง',
    dataIndex: 'action',
    align: 'center',
    width: '120px',
  },
]

const CoatingSetting = props => (
  <Div>
    <Div display='flex' flexDirection='row' justifyContent='space-between' margin='32px 0px 0px 0px' alignItems='center'>
      <Div display='flex' flexDirection='column'>
      <H4 bold color={color.primaryColor}>
          <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
            <H4 bold color={color.primaryColor}>Catalog Settings</H4>
          </Link> {' > '} การเคลือบผิว
        </H4>
      </Div>
      <Link to='/package-setting/coating-create' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
        <Button
          htmlType="submit"
          text='New'
          icon='fal fa-plus'
          inverse
        />
      </Link>
    </Div>
    <TableListCoating history={props.history} />
  </Div>
)

const TableListCoating = ({
  history,
}) => (
  <Div>
    <Query
      query={LIST_COATING}
      fetchPolicy='cache-and-network'
      variables={{
        limit: 10000,
        offset: 0,
      }}
    >
      {
        ({ loading, data, refetch }) => {
          let coatingList = []
          let coatingRemark = null
          if (!loading) {
            coatingList = data.listCoating.coating.map(item => ({
              name: item.name,
              action: <ActionGroup rowData={item} onEdit={() => history.push(`${PATH.coatingDetail}/${item.coatingId}`)}/>,
            }))
            coatingRemark = data.listCoating.remark
          }
          return (
          <>
            <RecommendationSetting
              moduleName='coating'
              data={coatingRemark}
              refetch={() => refetch()}
            />
            <SimpleTable
              loading={loading}
              columns={columns}
              dataSource={coatingList}
              pagination={false}
            />
          </>
          )
        }
      }
    </Query>
  </Div>
)

const ActionGroup = ({ rowData, onEdit }) => (
  <ActionGroupWrapper>
    <ButtonAction small inverse text='แก้ไข' icon='fa fa-pencil-square-o' onClick={onEdit} />
    <Mutation mutation={DELETE_COATING}>
      {
        doDeleteCoating => (
          <Popconfirm
            title='ยืนยันการรลบการเคลือบผิวหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'
            onConfirm={() => {
              doDeleteCoating({
                variables: {
                  id: parseInt(rowData.coatingId, 10),
                },
              }).then((res) => {
                if (res.data.deleteCoating) {
                  if (res.data.deleteCoating.success) {
                    message.success(res.data.deleteCoating.message)
                    setTimeout(() => {
                      window.location = PATH.coatingSetting
                    }, 100)
                  } else {
                    message.error(res.data.deleteCoating.message)
                  }
                }
              })
            }}
            okText='ยืนยัน'
            cancelText='ยกเลิก'
          >
            <ButtonAction small text='ลบ' icon='fa fa-trash-o fa-lg' color={theme.color.error} />
          </Popconfirm>
        )
      }
    </Mutation>
  </ActionGroupWrapper>
)

export default CoatingSetting
