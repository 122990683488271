
import styled from 'styled-components'
import { Checkbox as AntCheckbox } from 'antd'
import { theme } from '../../../../styles/_variables'


export const Checkbox = styled(AntCheckbox)`
font-size: ${theme.fonts.size.small};
font-weight: ${theme.fonts.style.semiBold};
color: ${theme.color.greyNormal};
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.color.greenPrimary};
    border-color: ${theme.color.greenPrimary};
}
`

export const DetailWrapper = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    height: calc(100% - 5px);
    // height: fit-content;
    // height: -moz-fit-content;
    row-gap: 10px;
`

export default Checkbox
