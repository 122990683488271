import styled from 'styled-components'
import {
  Div, H4,
} from 'konsys-ui'
import { theme, media } from '../../../../styles/_variables'

export const FullWidthContainer = styled.div`
  width: fit-content;
  min-height: 200px
`

export const Bar = styled(Div)`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const Content = styled(Div)`
  padding-top: 12px;
  width: fit-content;
`

export const Title = styled(H4)`
  color: ${theme.color.offWhite};
  margin-left: 100px;
`

export default FullWidthContainer
