import React from 'react'
import { Popconfirm } from 'antd'
import {
  H4, H5, Button, Div, P, Icon,
} from 'konsys-ui'
import {
  UploadWrapper,
} from './styled'
import { theme } from '../../../styles/_variables'

const UploadFile = props => (
  <Div style={{ width: '100%' }}>
    <UploadWrapper method="post" action="" enctype="multipart/form-data">
      <input
        id={props.id}
        type='file'
        multiple={props.multiple}
        alt=""
        onClick={(e) => {
          e.target.value = null
        }}
        onChange={e => props.onChange(e.target.files)}
        accept={props.fileType}
        onDrop={(e) => {
          e.target.value = null
        }}
        // ref={(el) => { this.inputFile = el }}
      />
      <label htmlFor={props.id}>
        <H4 display='block' textAlign='center' color={theme.color.grey}>ลากไฟล์ลงที่นี่</H4>
        <H5 display='block' textAlign='center' margin='0 0 8px' color={theme.color.paleGray}>- หรือ -</H5>
        <Button style={{ fontSize: theme.fonts.size.subTitle }} fontSize={theme.fonts.size.subTitle} color={theme.color.paleBlack} buttonStyle='rectangle' text='คลิกเพื่อค้นหาไฟล์' />
      </label>
    </UploadWrapper>
    <Div margin='8px 0'>
    {
      props.list.length >= 1 && props.list.map((v, index) => (
        <Div margin='8px 0' key={v.name} display='flex' justifyContent='space-between'>
          <P color='rgba(0, 0, 0, 0.65)'>ไฟล์ที่ {index + 1}. {v.name || (v[props.fileKey] && v[props.fileKey].split('/').pop())}</P>
          {
            props.confirm ? <Popconfirm
              title={props.confirmText || 'ยืนยันการลบรูปภาพนี้หรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'}
              onConfirm={() => props.onRemoveFile(v.name)}
              okText='ยืนยัน'
              cancelText='ยกเลิก'
            >
              <Icon style={{ cursor: 'pointer' }} color='rgba(0, 0, 0, 0.65)' icon='far fa-times' />
            </Popconfirm> : <Icon onClick={() => props.onRemoveFile(v.name)} style={{ cursor: 'pointer' }} color='rgba(0, 0, 0, 0.65)' icon='far fa-times' />
          }
        </Div>
      ))
    }
    </Div>
  </Div>
)

export default UploadFile
