import styled from 'styled-components'
import {
  Div, Button, Icon,
} from 'konsys-ui'
import { theme } from '../../../../styles/_variables'

export const WidthContainer = styled.div`
  width: 60%;
  min-width: 300px;
`
export const Label = styled.h4`
  font-weight: 700;
  font-size: 24px;
  margin-top: 16px;
  margin-bottom: 8px;
  color: ${theme.color.primaryColor};
`

export const Box = styled(Div)`
  border-radius: 6px;
  border: 1px solid ${theme.color.primaryColor}
  width: 85%;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const LabelExtraService = styled.span`
  font-weight: ${theme.fonts.style.medium};
  color: ${theme.color.lightBlack};
  font-size: ${theme.fonts.size.extraNormal};
  display: flex;
  align-items: center;
`

export const NewButton = styled(Button)`
  border-radius: 5px;
  padding: 0px 16px;
  width: fit-content;
  i {
    font-size: 1px !important;
    font-weight: ${theme.fonts.style.medium};
  }
  span {
    font-size: 22px;
    font-weight: ${theme.fonts.style.medium};
  }
`

export const ActionBox = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 12%;
`

// export const ActionButton = styled(Icon)`
//   display: flex;
//   align-items: center;
//   justify-content: space-around;
//   font-size: ${theme.fonts.size.normal};
//   background-color: ${theme.color.primaryColor};
//   width: 36px;
//   height: 36px;
//   color: ${theme.color.offWhite};
//   border-radius: 50%;
//   cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
//   opacity: ${props => (props.disabled ? '0.2' : '1')};
//   i {
//     margin: 4px 0px 0px 0px !important;
//     font-size: 16px !important;
//   }
// `

export const ActionButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 36px;
  height: 36px;
  border-color: ${theme.color.primaryColor};
  background-color: ${theme.color.primaryColor};
  i {
    margin: 2px 0px 0px 0px !important;
    font-size: 16px !important;
  }
`


export default WidthContainer
