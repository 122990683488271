import React from 'react'
import {
  Div,
} from 'konsys-ui'
import {
  OptionLabel, OptionContentWrapper, ImageDiv,
  Label,
} from './styled'


const RectangleOptionItem = ({
  value,
  checked,
  onChecked,
  icon,
  label,
  disabled,
}) => (
  <OptionLabel disabled={disabled}>
    <input
      type='radio'
      id={value}
      value={value}
      checked={checked}
      onChange={e => onChecked(e.target.value)}
      disabled={disabled}
    />
    <OptionContentWrapper>
      <ImageDiv>
        {icon}
      </ImageDiv>
      <Label>
        {label}
      </Label>
    </OptionContentWrapper>
  </OptionLabel>
)

export default RectangleOptionItem
