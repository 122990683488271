import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Div, H4, P, LoadingIcon,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import { message } from 'antd'
import {
  NewButton,
} from './styled'
import { theme } from '../../../styles/_variables'
import TagForm from './components/tag-form'
import PATH from '../../../constants/path'
import { LIST_TAG } from '../../../constants/graphql/query'
import { UPDATE_TAG } from '../../../constants/graphql/mutation'
import BreadCrumb from '../../../components/common/breadcrumb'


const { color } = theme

const TagSetting = () => {
  const [formValues, setFormValues] = useState({
    tag: [],
  })
  const [callMutation, setCallMutation] = useState(false)
  return (
    <Div>
      <BreadCrumb link={{
        parent: [
          {
            text: 'การตั้งค่าบรรจุภัณฑ์',
            link: PATH.packageSetting,
          },
        ],
        current: {
          text: 'การตั้งค่า Tag',
        },
      }} />
      <Div display='flex' flexDirection='row' justifyContent='space-between' margin='32px 0px 0px 0px' alignItems='center'>
          <Div display='flex' flexDirection='column'>
          <H4 bold color={color.primaryColor}>
              <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                <H4 bold color={color.primaryColor}>Catalog Settings</H4>
              </Link> {' > '} Tag Management
          </H4>
          </Div>
          <Mutation mutation={UPDATE_TAG}>
            {
              doUpdateTag => (
                <NewButton
                  text='บันทึก'
                  icon='fa fa-floppy-o'
                  inverse
                  disabled={callMutation}
                  onClick={() => {
                    const input = formValues.tag
                    setCallMutation(true)
                    doUpdateTag({
                      variables: {
                        input,
                      },
                    }).then((res) => {
                      if (res.data.updateTag) {
                        if (res.data.updateTag.success) {
                          message.success(res.data.updateTag.message)
                          setTimeout(() => {
                            window.location = PATH.tagSetting
                            setCallMutation(false)
                          }, 100)
                        } else {
                          message.error(res.data.updateTag.message)
                          setCallMutation(false)
                        }
                      }
                    })
                  }}
                />
              )
            }
          </Mutation>
      </Div>
      <Query
        query={LIST_TAG}
        variables={{
          limit: 10000,
          offset: 0,
        }}
        fetchPolicy='network-only'
        notifyOnNetworkStatusChange
        onCompleted={(data) => {
          if (data.listTag && data.listTag.success) {
            const tmpTag = data.listTag.tag.map(item => ({
              tagId: item.tagId,
              name: item.name,
            }))
            setFormValues({ tag: tmpTag })
          }
        }}
      >
        {
          ({ loading, data }) => {
            if (!data || loading) {
              return <Div margin='24px 0' display='flex' moreStyle={{ width: '100%', justifyContent: 'center' }}>
                <LoadingIcon color={theme.color.grey} />
              </Div>
            }
            if (!loading) {
              if (!data.listTag && !data.listTag.success) {
                return (<P>error something</P>)
              }
            }
            return (
              <>
                {
                  (data.listTag && data.listTag.success) && <TagForm
                    formValues={formValues}
                    setFormValues={setFormValues}
                  />
                }
              </>
            )
          }
        }
      </Query>

    </Div>
  )
}

export default TagSetting
