import styled from 'styled-components'
import { Button } from 'konsys-ui'
import { theme, media } from '../../../../../styles/_variables'


export const Container = styled.div`
  flex: 0 1 auto;
  margin: 12px 0px 4px 0px;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-content: center;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  > p{
    margin-bottom: 0;
  }
`

export const Label = styled.p`
  font-weight: ${theme.fonts.style.medium};
  font-size: ${theme.fonts.size.normal};
  color: ${theme.color.tertiaryColor};
`

export const DescriptionWrapper = styled.div`
  font-weight: ${theme.fonts.style.regular};
  font-size: ${theme.fonts.size.small};
  color: ${theme.color.tertiaryColor};
  line-height: normal;
  > p {
    margin-bottom: 2px;
  }
`

export default Label
