import React, { useState } from 'react'
import {
  Icon,
} from 'konsys-ui'
import {
  SummaryPanel,
  SummaryToggle,
} from './styled'
import { theme, media } from '../../../styles/_variables'

const SummaryToggleComponent = ({ children, setSummaryActive, summaryActive }) => (
  <SummaryPanel active={summaryActive}>
    <SummaryToggle onClick={() => setSummaryActive(!summaryActive)}>
      <h4>สรุปรายละเอียด</h4>
      <Icon
        style={{
          color: theme.color.offWhite,
          fontSize: '20px',
          fontWeight: '500',
        }}
        icon={summaryActive ? 'fal fa-chevron-down' : 'fal fa-chevron-up'}
      />
    </SummaryToggle>
    {
      summaryActive && children
    }
  </SummaryPanel>
)

export default SummaryToggleComponent
