export default {
  title: 'Boxsuit',
  menu: [
    // {
    //   key: 'landing', // link
    //   label: 'หน้าแรก',
    //   icon: 'far fa-home-alt',
    //   child: [],
    // },
    {
      key: 'order-list',
      label: 'Orders',
      icon: 'far fa-file-contract',
    }, {
      key: 'home-setting',
      label: 'Home Setting',
      icon: 'fal fa-home-lg',
    }, {
      key: 'popup-setting',
      label: 'Popup Setting',
      icon: 'fal fa-copy',
    }, {
      key: 'gallery',
      label: 'Gallery',
      icon: 'fal fa-images',
    }, {
      key: 'quotation-submitted',
      label: 'Quotation Submitted',
      icon: 'fal fa-file-check',
    }, {
      key: 'promotion-list',
      label: 'Promotion',
      icon: 'far fa-bullhorn',
    }, {
      key: 'package-list',
      label: 'Packaging Catalog',
      icon: 'fal fa-box',
    }, {
      key: 'price-calculator',
      label: 'Price Calculator',
      icon: 'fal fa-calculator',
    }, {
      key: 'package-setting',
      label: 'Catalog Settings',
      icon: 'fal fa-toolbox',
    },
  ],
  theme: 0, // 0 for Dark, 1 for light
}
