import React from 'react'
import {
  Div, Button,
} from 'konsys-ui'
import { Col, Row } from 'antd'
import {
  FullWidthContainer,
  Bar,
  Content,
  Title,
} from './styled'
import TablePrice from '../table-price'
import Promotion from '../promotion'
import GraphicSelector from '../graphic-selector'
import MoreDetailInput from '../more-detail-input'
import ImageInput from '../cover-image'

const SummaryContent = ({
  formValues,
  setFormValues,
  setStep,
  isShowMore,
  showEditIcon,
  setDisabledButton,
  isQuotationEdit,
  packageValues,
}) => (
  <FullWidthContainer>
    <Content>
      {
        isShowMore && <Promotion
          formValues={formValues}
          setFormValues={setFormValues}
        />
      }
      <TablePrice
        totalCost={formValues.totalCost || []}
        partDetail={formValues.partDetail || []}
        formValues={formValues}
        setFormValues={setFormValues}
        handleEdit={() => setStep(1)}
        showEditIcon={showEditIcon}
        setDisabledRequestQuot={setDisabledButton}
        isQuotationEdit={isQuotationEdit}
        packageValues={packageValues}
      />
      <br/>
      {
        isShowMore && <>
          <GraphicSelector
            formValues={formValues}
            setFormValues={setFormValues}
          />
          <br/>
          <MoreDetailInput
            formValues={formValues}
            setFormValues={setFormValues}
          />
          <br/>
          <ImageInput
            newFileList={formValues.graphicFile}
            setNewFileList={setFormValues}
            data={formValues.graphicFile}
            formValues={formValues}
          />
        </>
      }
    </Content>
  </FullWidthContainer>
)


export default SummaryContent
