import styled, { css } from 'styled-components'
import { Div } from 'konsys-ui'
import { Link } from 'react-router-dom'
import { theme, media, minMedia } from '../../../styles/_variables'

export const Line = styled.hr`
  border-top: solid 1px rgba(192, 191, 193, 0.3);
  margin: 40px 0;
`

export const BgContainer = styled.div`
  background-color: ${theme.color.lightPaleGrey};
  padding: 20px 0;
  ${media.tablet`
    padding: 40px 0;
  `}
`

export const WidthContainer = styled.div`
  width: 100%;
  max-width: ${theme.maxWidth};
  ${media.tablet`
    width: 100%;
    max-width: 100%;
  `}
  h3 {
    font-weight: 600;
    ${media.tablet`
      font-size: ${theme.fonts.size.subTitle};
    `}
  }
  h4 {
    ${media.tablet`
      font-size: ${theme.fonts.size.extraNormal};
    `}
  }
`

export const StepWithTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in;
  position: relative;
  cursor: ${props => (props.active ? 'pointer' : 'default')};
  > p {
    width: 115px;
    color: ${props => (props.active ? theme.color.lightGrey : theme.color.paleGray)};
  }
  > h3 {
    width: 56px;
    height: 56px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => (props.active ? theme.color.primaryColor : theme.color.paleGray)};
    color: ${theme.color.offWhite};
    border-radius: 50%;
  }
  ${minMedia.tablet`
    margin: 0 64px;
    &:after {
      content: '';
      position: absolute;
      height: 8px;
      width: 100px;
      top: 72%;
      left: 80px;
      background-color: ${props => (props.active ? theme.color.primaryColor : theme.color.paleGray)};
    }
    &:before {
      content: '';
      position: absolute;
      height: 8px;
      width: 100px;
      top: 72%;
      right: 80px;
      background-color: ${props => (props.active ? theme.color.primaryColor : theme.color.paleGray)};
    }
    &:first-child {
      margin: 0 64px 0 0;
      &:before {
        display: none;
      }
    }
    &:last-child {
      margin: 0 0 0 64px;
      &:after {
        display: none;
      }
    }
  `}
  ${media.tablet`
    flex-direction: row;
    padding: 0 4px;
    &:first-child {
      padding: 0 4px 0 0;
    }
    &:last-child {
      padding: 0 0 0 4px;
    }
    > p {
      width: auto;
      margin: 0;
      order: 2;
      max-width: 100%;
      margin-left: 8px;
      display: ${props => (props.active ? 'inline-block' : 'none')};
    }
    > h3 {
      font-size: ${theme.fonts.size.extraNormal};
      width: 30px;
      height: 30px;
    }
  `}
  ${media.mobileM`
    > p {
      font-size: ${theme.fonts.size.small};
    }
  `}
`

export const StepWrapper = styled.div`
  display: inline-flex;
  padding: 56px 0;
  max-width: ${theme.maxWidth};
  margin-left: 50%;
  transform: translateX(-50%);
  ${props => props.current && minMedia.tablet`
    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: ${theme.color.primaryColor};
      transition: left 0.8s ease;
      top: 133px;
      left: ${props.current === 1 || props.current === 3 ? `calc((169px * ${props.current}))` : `calc((169px * ${props.current}) + 78px)`};
      opacity: ${props.current < 3 ? 1 : 0};
    }
  `}
  ${media.tablet`
    display: flex;
    padding: 16px;
    position: relative;
    max-width: 100%;
    margin: 0;
    transform: translateX(0);
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0px;
      height: 2px;
      transition: all 0.8s ease;
      width: ${props => props.current && `calc((100%/3)*${props.current})`};
      background-color: ${theme.color.primaryColor};
    }
    ${props => props.current && css`
      > ${StepWithTitleWrapper} {
        &:not(:nth-child(${props.current})) {
          > p {
            display: none;
          }
        }
        &:nth-child(${parseInt(props.current, 10) + 1}) {
          justify-content: flex-end;
          flex-grow: 1;
        }
      }
    `}
  `}
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 48px 0;
  ${media.tablet`
    justify-content: space-between;
    margin: 56px 16px 40px;
  `}
`

/* STEP 1 */

export const RadioSelectWithDetailWrapper = styled.div``

export const ChoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0 0;
  position: relative;
  > ${RadioSelectWithDetailWrapper} {
    margin: 0 0 16px;
    transition: all 0.8s ease;
  }
  ${media.tablet`
    flex-direction: row;
    margin: 8px 0 0;
    padding: 0;
    > ${RadioSelectWithDetailWrapper} {
      &:first-child {
        margin: 0 16px 0 0;
      }
      > div {
        margin-top: 8px;
      }
    }
    &#step1-2 {
      position: relative;
      padding-bottom: 285px;
      > ${RadioSelectWithDetailWrapper} > div:last-child {
        margin: 0;
        position: absolute;
        top: 63px;
        left: 0;
      }
    }
    &#step1-1 {
      position: relative;
      padding-bottom: 96px;
      > ${RadioSelectWithDetailWrapper} > div:last-child {
        margin: 0;
        position: absolute;
        top: 63px;
        left: 0;
      }
    }
    &#step2 {
      flex-wrap: wrap;
      > div {
        margin: 0;
      }
    }
  `}
  ${media.mobileL`
    flex-direction: column;
    padding: 0;
    padding-bottom: 0 !important;
    > ${RadioSelectWithDetailWrapper} {
      &:first-child {
        margin: 8px 0 0;
      }
      > div {
        width: 100%;
        position: relative !important;
        top: 0 !important;
        margin: 8px 0 0 !important;
      }
    }
  `}
`

export const ChoiceContent = styled.div`
  background-color: #B8C9C7;
  transition: max-height 0.8s ease;
  opacity: ${props => (props.checked ? 1 : 0)};
  max-height: ${props => (props.checked ? '2000px' : 0)};
  overflow: hidden;
  padding: ${props => (props.checked ? '56px 32px 32px' : 0)};
  margin: ${props => (props.checked ? '-24px 0 0 72px' : '0 0 0 72px')};
  ${media.tablet`
    position: relative;
    top: 0;
    padding: ${props => (props.checked ? '16px' : 0)};
    margin: ${props => (props.checked ? '8px 0 16px' : 0)};
    width: 100%;
  `}
`

export const DetailWrapper = styled(Link)`
  box-shadow: 3px 3px 0 1px rgba(${theme.color.primaryColorRGB}, 0.7) !important;
  display: block;
  background-color: ${theme.color.primaryColor};
  background-position: top right;
  background-size: cover;
  padding: 16px;
  margin: 24px 0;
  text-align: center;
`

export const DetailWrapperA = styled.a`
  box-shadow: 3px 3px 0 1px rgba(${theme.color.primaryColorRGB}, 0.7) !important;
  display: block;
  background-color: ${theme.color.primaryColor};
  background-position: top right;
  background-size: cover;
  padding: 16px;
  margin: 24px 0;
  text-align: center;
`

export const CustomLink = styled(Link)`
  color: ${theme.color.tertiaryColor};
  text-decoration: underline;
  font-size: 24px;
  &:hover {
    color: ${theme.color.primaryColor};
    text-decoration: underline;
  }
`

export const CustomLinkA = styled.a`
  color: ${theme.color.tertiaryColor};
  text-decoration: underline;
  font-size: 24px;
  &:hover {
    color: ${theme.color.primaryColor};
    text-decoration: underline;
  }
`

export const MutationResultDetailWrapper = styled.div`
  button {
    > p {
      font-size: ${theme.fonts.size.subTitle};
    }
  }
  ${media.tablet`
    > * {
      text-align: center;
    }
  `}
`

export const ImgBox = styled.img`
  width: 85%;
  ${media.tablet`
    width: 70%;
  `}
`

export const MessageInputWrapper = styled(Div)`
  > div > div:last-child {
    text-align: left;
    margin: 4px 0 0;
    > span {
      font-size: ${theme.fonts.size.small};
      color: ${theme.color.lightGrey};
      font-family: ${theme.fonts.family.normal};
    }
  }
`

export const MutationResultWrapper = styled(Div)`
  ${media.tablet`
    flex-direction: column;
    justify-content: center;
    > div:first-child {
      text-align: center;
      margin: 0 0 16px;
    }
  `}
`

export const CheckboxLabel = styled.span`
  font-weight: ${theme.fonts.style.medium};
  font-size: ${theme.fonts.size.normal};
`

export const CheckboxSpan = styled.span`
  font-weight: ${theme.fonts.style.regular};
  font-size: ${theme.fonts.size.small};
  margin-left: 24px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
`

export default Line
