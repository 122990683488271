import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Div, H4, Button, LoadingIcon,
} from 'konsys-ui'
import { message, Popconfirm } from 'antd'
import { Query, Mutation } from 'react-apollo'

import { theme } from '../../../../styles/_variables'
import PATH from '../../../../constants/path'
import { GET_FOILING_DETAIL } from '../../../../constants/graphql/query'
import { DELETE_FOILING, EDIT_FOILING } from '../../../../constants/graphql/mutation'
import FoilingForm from '../components/foiling-form'
import BreadCrumb from '../../../../components/common/breadcrumb'


const { color } = theme


const FoilingDetail = ({ match }) => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    costMinimum: 0,
    minimumBlockCost: 0,
    coverPhoto: [],
    sampleVideo: [],
    imagePhoto: [],
    foilingBlockCost: [],
    headerFoilingCosts: [{
      title: 'จำนวนผลิต (ชิ้น)',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: 'number',
      editable: false,
      editColumn: false,
      alignCell: 'center',
    }],
    dataFoilingCosts: [],
    headerFoilingOperationCosts: [{
      title: 'จำนวนผลิต (ชิ้น)',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: 'number',
      editable: false,
      editColumn: false,
      alignCell: 'center',
    }],
    dataFoilingOperationCosts: [],
  })
  return (
    <Div>
      <Query
        query={GET_FOILING_DETAIL}
        variables={{
          id: parseInt(match.params.id, 10),
        }}
        notifyOnNetworkStatusChange
        onCompleted={(data) => {
          const foilingData = data.getFoilingDetail.foiling
          if (data.getFoilingDetail.success) {
            const foilingCostDataColumn = foilingData.foilingCostColumnName.map(item => ({
              title: item.name,
              dataIndex: item.type,
              key: item.type,
              inputType: 'number',
              editable: true,
              editColumn: item.type !== 'column1',
              alignCell: 'center',
            }))
            const foilingOperationCostDataColumn = foilingData.foilingOperationCostColumnName.map(item => ({
              title: item.name,
              dataIndex: item.type,
              key: item.type,
              inputType: 'number',
              editable: true,
              editColumn: item.type !== 'column1',
              alignCell: 'center',
            }))
            const foilingBlockCost = foilingData.foilingBlockCost.reduce((result, item) => {
              result.push({
                key: result.length,
                size: [{
                  width: item.width,
                  height: item.height,
                }],
                price: [item.price],
                foilingBlockCostId: item.foilingBlockCostId,
              })
              return result
            }, [])
            setFormValues({
              ...formValues,
              name: foilingData.name,
              description: foilingData.description,
              coverPhoto: foilingData.foilingFiles.filter(file => file.type === 'cover'),
              sampleVideo: foilingData.foilingFiles.filter(file => file.type === 'video'),
              imagePhoto: foilingData.foilingFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample'),
              foilingBlockCost,
              headerFoilingCosts: [...formValues.headerFoilingCosts, ...foilingCostDataColumn],
              dataFoilingCosts: foilingData.foilingCost.map((item, index) => ({
                key: index,
                quantity: item.quantity,
                column1: item.column1,
                column2: item.column2,
                column3: item.column3,
                column4: item.column4,
                column5: item.column5,
              })),
              headerFoilingOperationCosts: [...formValues.headerFoilingOperationCosts, ...foilingOperationCostDataColumn],
              dataFoilingOperationCosts: foilingData.foilingOperationCost.map((item, index) => ({
                key: index,
                quantity: item.quantity,
                column1: item.column1,
                column2: item.column2,
                column3: item.column3,
                column4: item.column4,
                column5: item.column5,
              })),
              costMinimum: foilingData.minimumCost,
              minimumBlockCost: foilingData.minimumBlockCost,
            })
          } else {
            message.error(data.getFoilingDetail.message)
            setTimeout(() => {
              window.location = PATH.foilingSetting
            }, 1000)
          }
        }}
      >
        {
          ({ loading, data }) => {
            if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
            const { foilingId, name } = data.getFoilingDetail.foiling
            return (
              <>
                <Div display='flex' flexDirection='column'>
                  <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
                    <H4 bold color={color.primaryColor} margin='32px 0'>
                      <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                        <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                      </Link> {' > '}
                      <Link to='/package-setting/foiling' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                        <H4 bold color={color.primaryColor}>การปั๊มฟอยล์-ทองเค</H4>
                      </Link> {' > '} แก้ไขข้อมูล
                    </H4>
                    <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
                      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                        <DeleteButton foilingId={foilingId} />
                        <SaveButton
                          foilingId={foilingId}
                          formValues={formValues}
                          callMutation={callMutation}
                          setCallMutation={setCallMutation}
                          setIsValidate={setIsValidate}
                        />
                      </Div>
                    </Div>
                  </Div>
                  {
                    data.getFoilingDetail.foiling && <FoilingForm
                      formValues={formValues}
                      setFormValues={setFormValues}
                      isValidate={isValidate}
                      setIsValidate={setIsValidate}
                      action={'detail'}
                    />
                  }
                </Div>
              </>
            )
          }
        }
      </Query>
    </Div>
  )
}

const DeleteButton = ({ foilingId }) => (
  <Mutation mutation={DELETE_FOILING}>
    {
      doDeleteFoiling => (
        <Popconfirm
          title="ยืนยันการลบการปั๊มฟอยล์-ทองเคหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก"
          onConfirm={() => {
            doDeleteFoiling({
              variables: {
                id: parseInt(foilingId, 10),
              },
            }).then((res) => {
              if (res.data.deleteFoiling) {
                if (res.data.deleteFoiling.success) {
                  message.success(res.data.deleteFoiling.message)
                  setTimeout(() => {
                    window.location = PATH.foilingSetting
                  }, 350)
                } else {
                  message.error(res.data.deleteFoiling.message)
                }
              }
            })
          }}
          okText='ยืนยัน'
          cancelText='ยกเลิก'
        >
          <Button text='ลบ' icon='fa fa-trash' ghost margin='0 16px 0 0' color={color.error}/>
        </Popconfirm>
      )
    }
  </Mutation>
)

const SaveButton = ({
  foilingId,
  formValues,
  callMutation,
  setCallMutation,
  setIsValidate,
}) => (
  <Mutation mutation={EDIT_FOILING}>
    {
      doEditFoiling => (
        <Button
          text='บันทึก'
          icon='fa fa-save'
          inverse
          margin='0 16px 0 0'
          onClick={() => {
            setCallMutation(true)
            if (formValues.name === '') {
              message.warning('กรุณากรอกข้อมูลชื่อการปั๊มฟอยล์-ทองเค')
              setCallMutation(false)
              setIsValidate(false)
              return
            }

            if (formValues.name.match(new RegExp(/^\s/)) !== null) {
              message.warning('กรุณากรอกข้อมูลชื่อการปั๊มฟอยล์-ทองเคให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
              setCallMutation(false)
              setIsValidate(false)
              return
            }
            const input = {
              foilingId,
              name: formValues.name,
              description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
              minimumCost: parseFloat(formValues.costMinimum) || 0,
              minimumBlockCost: parseFloat(formValues.minimumBlockCost) || 0,
              coverPhoto: formValues.coverPhoto,
              video: formValues.sampleVideo,
              imagePhoto: formValues.imagePhoto,
              foilingCostColumnName: formValues.headerFoilingCosts.filter(column => /column*/.test(column.key)).map(column => ({
                name: column.title,
                type: column.key,
                foilingId,
              })),
              foilingCost: formValues.dataFoilingCosts.map(row => ({
                foilingId,
                quantity: parseInt(row.quantity, 10),
                column1: parseFloat(row.column1),
                column2: parseFloat(row.column2),
                column3: parseFloat(row.column3),
                column4: parseFloat(row.column4),
                column5: parseFloat(row.column5),
              })),
              foilingBlockCost: formValues.foilingBlockCost.reduce((result, item) => {
                for (let index = 0; index < item.size.length; index += 1) {
                  result.push({
                    foilingId,
                    foilingBlockCostId: item.foilingBlockCostId,
                    width: parseFloat(item.size[index].width),
                    height: parseFloat(item.size[index].height),
                    price: parseFloat(item.price[index]),
                  })
                }
                return result
              }, []),
              foilingOperationCostColumnName: formValues.headerFoilingOperationCosts.filter(column => /column*/.test(column.key)).map(column => ({
                name: column.title,
                type: column.key,
                foilingId,
              })),
              foilingOperationCost: formValues.dataFoilingOperationCosts.map(row => ({
                foilingId,
                quantity: parseInt(row.quantity, 10),
                column1: parseFloat(row.column1),
                column2: parseFloat(row.column2),
                column3: parseFloat(row.column3),
                column4: parseFloat(row.column4),
                column5: parseFloat(row.column5),
              })),
            }
            doEditFoiling({
              variables: {
                input,
              },
            }).then(({ data }) => {
              if (data.editFoiling.success) {
                setCallMutation(false)
                message.success(data.editFoiling.message)
                setTimeout(() => {
                  window.location = PATH.foilingSetting
                }, 350)
              } else {
                setCallMutation(false)
                if (data.editFoiling.message === 'ชื่อ การปั๊มฟอยล์-ทองเค นี้มีอยู่ในระบบแล้ว') {
                  setIsValidate(false)
                  setCallMutation(false)
                }
                message.error(data.editFoiling.message)
              }
            })
          }}
          disabled={callMutation}
        />
      )
    }
  </Mutation>
)

export default FoilingDetail
