import React, { useState } from 'react'
import { Query } from 'react-apollo'
import { LIST_GLUING } from '../../../../constants/graphql/query'
import { Select, Title } from './styled'


const { Option } = Select

const GluingSelector = ({
  formValues,
  setFormValues,
  activeTab,
  disabled,
}) => {
  const [gluinOptions, setGluingOptions] = useState([])
  const handleOnChange = (e) => {
    const tmpFormValues = formValues.partData
    const value = gluinOptions.find(x => x.gluingId === e)
    tmpFormValues[activeTab].gluingId = value.gluingId
    tmpFormValues[activeTab].gluingName = value.name
    setFormValues({ ...formValues, partData: tmpFormValues })
  }
  return (
    <div>
      <Title>วิธีการติดกาว</Title>
      <Query
        query={LIST_GLUING}
        fetchPolicy='network-only'
        variables={{
          limit: 10000,
          offset: 0,
        }}
        onCompleted={(data) => {
          if (data.listGluing) {
            const gluingList = [{ gluingId: null, name: 'ไม่ติดกาว' }, ...data.listGluing.gluing.map(item => ({
              gluingId: item.gluingId,
              name: item.name,
            }))]
            setGluingOptions(gluingList)
          }
        }}
      >
        {
          () => (
              <Select onChange={handleOnChange} value={formValues.partData[activeTab] && formValues.partData[activeTab].gluingId} disabled={disabled}>
                {
                  gluinOptions.map((item, index) => (
                    <Option key={index} value={item.gluingId}>{item.name}</Option>
                  ))
                }
              </Select>
          )
        }
      </Query>
    </div>
  )
}

export default GluingSelector
