import React, { useState, useEffect } from 'react'
import { Mutation, Query } from 'react-apollo'
import _ from 'lodash'
import {
  Row, Col, Select, message, Checkbox as CheckboxAnt,
} from 'antd'
import {
  Div, P, H4, ButtonForm, Input,
  Icon, SmallText, H5,
} from 'konsys-ui'
import { Radio, Checkbox, RadioCus } from '../../../components/common/choice'
import UploadFile from '../../../components/common/upload'
import { CreateQuotation } from '../../../components/quotation-components'
import * as quotationType from '../../../constants/quotation-order-type'
import { QUOTATION_REQUEST_CREATE } from '../../../constants/graphql/mutation'
import {
  LIST_PAPER,
  GET_PRINTING_FORM,
  GET_TAX_RATE,
} from '../../../constants/graphql/query'
import { PRINT_TECHNIQUE, PAPER } from '../../../constants/detail'
import VALIDATE from '../../../constants/validate'
import PATH from '../../../constants/path'
import PROVINCE from '../../../constants/province'
import {
  Line, WidthContainer, BgContainer, ButtonWrapper,
  RadioSelectWithDetailWrapper, ChoiceWrapper, ChoiceContent,
  MessageInputWrapper, CustomLinkA, DetailWrapperA,
  CheckboxLabel, CheckboxSpan,
} from './styled'
import { theme } from '../../../styles/_variables'
import GraphicSelector from '../../../components/package-calculator/components/graphic-selector'
import BreadCrumb from '../../../components/common/breadcrumb'


const RadioSelectWithDetail = ({
  data, setDefaultChecked, defaultChecked, children,
}) => (
  <RadioSelectWithDetailWrapper>
    <Radio
      data={data}
      setDefaultChecked={value => setDefaultChecked(value)}
      defaultChecked={defaultChecked}
    />
    {
      children && <ChoiceContent checked={defaultChecked === data.value}>
      {
        children
      }
      </ChoiceContent>
    }
  </RadioSelectWithDetailWrapper>
)

const CheckboxSelectWithDetail = ({
  data, setDefaultChecked, checked, children,
}) => (
  <RadioSelectWithDetailWrapper>
    <Checkbox
      data={data}
      setDefaultChecked={value => setDefaultChecked(value)}
      forceChecked={checked}
    />
    {
      children && <ChoiceContent checked={checked}>
      {
        children
      }
      </ChoiceContent>
    }
  </RadioSelectWithDetailWrapper>
)

const step1TitleInput = {
  boxSuitDesignService: 'บริการออกแบบ',
  name: 'ชื่อ หรือ จุดประสงค์ของกล่องบรรจุภัณฑ์',
  boxSize: 'ขนาดกล่องบรรจุภัณฑ์',
  quantity: 'จำนวน',
  productQuantityPerPiece: 'จำนวนมากสุดของผลิตภัณฑ์ที่จะถูกบรรจุในกล่อง',
  paperType: 'ประเภทกระดาษ',
  printingTechnique: 'รูปแบบการพิมพ์',
  printingDescription: 'รายละเอียดและเทคนิคงานพิมพ์ที่ต้องการ',
  designServiceChoice: 'บริการทำแบบ',
  productSize: 'ขนาดผลิตภัณฑ์',
  designDescription: 'รายละเอียดแนวทางการออกแบบที่ต้องการ',
}
const step2TitleInput = {
  address: 'ที่อยู่',
  name: 'ชื่อ - นามสกุล',
  email: 'Email',
  telephoneNumber: 'เบอร์โทรศัพท์',
  mobileNumber: 'เบอร์โทรศัพท์มือถือ',
  companyName: 'บริษัท (นิติบุคคล)',
  taxId: 'หมายเลขประจำตัวผู้เสียภาษี',
  detail: 'รายละเอียดเพิ่มเติม',
  withHoldingTax: 'หักภาษี ณ ที่จ่าย',
}

/* =============================== STEP 1 ================================== */

const Step1 = ({
  data, onInputChange, onUploadFileList, onRemoveFileList,
  setDisabledButton, onDesignChoiceChange,
}) => {
  const [boxSize, setBoxSize] = useState({})
  const [productSize, setProductSize] = useState({})
  const [isValidate, setIsValidate] = useState(true)
  const paperType = PAPER.map(v => v.paper)
  return (
    <WidthContainer>
      <BreadCrumb link={{
        parent: [
          {
            text: 'Order ทั้งหมด',
            link: PATH.quotationList,
          },
        ],
        current: {
          text: 'สร้างใบเสนอราคา',
        },
      }} />
      <H4 display='block' bold margin='0 0 32px' color={theme.color.tertiaryColor}>รายละเอียดกล่องบรรจุภัณฑ์</H4>
      <H5 display='block' color={theme.color.secondaryColor}>{step1TitleInput.design}</H5>
      {/* <ChoiceWrapper id='step1-1'>
        <RadioSelectWithDetail
          data={{ value: 'notUseDesign', text: 'ไม่ต้องการใช้บริการออกแบบ', name: 'design' }}
          setDefaultChecked={value => onInputChange(value, 'boxSuitDesignService')}
          defaultChecked={data.boxSuitDesignService}
        >
          <P>สำหรับลูกค้าที่ออกแบบกล่องบรรจุภัณฑ์ด้วยตนเอง มีไฟล์แบบ หรือลวดลายพร้อมสำหรับการสั่งพิมพ์
          รูปแบบและลวดลายบนกล่องจะไม่สามารถปรับแต่งหรือแก้ไขได้</P>
        </RadioSelectWithDetail>
        <RadioSelectWithDetail
          data={{ value: 'useDesign', text: 'ต้องการใช้บริการออกแบบ', name: 'design' }}
          setDefaultChecked={value => onInputChange(value, 'boxSuitDesignService')}
          defaultChecked={data.boxSuitDesignService}
        >
          <P>สำหรับลูกค้าที่ต้องการใช้บริการออกแบบ เพื่อสร้างสรรค์กล่องบรรจุภัณฑ์ที่เหมาะสมกับผลิตภัณฑ์ของลูกค้า
          กรุณาระบุรายละเอียด และแนบไฟล์ที่ต้องการใช้ในการอ้างอิง รวมถึงรูปภาพและสัญลักษณ์ที่ต้องการใช้พิมพ์
          ลงบนกล่องผลิตภัณฑ์</P>
        </RadioSelectWithDetail>
      </ChoiceWrapper> */}
      <Div margin='24px 0'>
        <H5 display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.name} *</H5>
        <Input
          width='100%'
          value={data.name}
          onChange={(e) => {
            onInputChange(e.target.value, 'name')
            if (e.target.value !== '') {
              setDisabledButton(false)
              setIsValidate(true)
            }
          }}
          onBlur={() => {
            if (data.name === '') {
              setIsValidate(false)
              setDisabledButton(true)
            }
          }}
          state={!isValidate && 'error'}
        />
      </Div>
      <Div margin='24px 0'>
        <H5 margin='0 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.boxSize}</H5>
        <Div display='flex'>
          <MessageInputWrapper width='calc((100% - 32px)/3)'>
            <Input width='100%' type='number' value={!_.isEmpty(data.boxSize) && data.boxSize.width} message='กว้าง (ซม.)'
            onKeyPress={(e) => {
              if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault()
              }
            }}
            onChange={(e) => {
              const newBoxSize = Object.assign({}, boxSize)
              newBoxSize.w = e.target.value
              setBoxSize(newBoxSize)
              onInputChange(newBoxSize, 'boxSize')
            }} />
          </MessageInputWrapper>
          <MessageInputWrapper margin='0 16px' width='calc((100% - 32px)/3)'>
            <Input type='number' value={!_.isEmpty(data.boxSize) && data.boxSize.length} width='100%' message='ยาว (ซม.)'
            onKeyPress={(e) => {
              if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault()
              }
            }}
            onChange={(e) => {
              const newBoxSize = Object.assign({}, boxSize)
              newBoxSize.l = e.target.value
              setBoxSize(newBoxSize)
              onInputChange(newBoxSize, 'boxSize')
            }} />
          </MessageInputWrapper>
          <MessageInputWrapper width='calc((100% - 32px)/3)'>
            <Input type='number' value={!_.isEmpty(data.boxSize) && data.boxSize.height} width='100%' message='สูง (ซม.)'
            onKeyPress={(e) => {
              if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault()
              }
            }}
            onChange={(e) => {
              const newBoxSize = Object.assign({}, boxSize)
              newBoxSize.h = e.target.value
              setBoxSize(newBoxSize)
              onInputChange(newBoxSize, 'boxSize')
            }} />
          </MessageInputWrapper>
        </Div>
      </Div>
      <Div margin='24px 0'>
        <H5 display='block' bold color={theme.color.primaryColor}>{step1TitleInput.quantity}</H5>
        <P display='block' margin='0 0 8px' color={theme.color.paleBlack}>ราคาต่อ 1 ชิ้นลดลง เมื่อจำนวนมากขึ้น</P>
        <Row gutter={16}>
          <Col md={8}>
            <MessageInputWrapper>
              <Input
                type='number'
                width='100%'
                message='ชิ้น'
                value={data.quantity}
                onChange={e => onInputChange(parseInt(e.target.value, 10), 'quantity')}
              />
            </MessageInputWrapper>
          </Col>
        </Row>
      </Div>
      {
        data.boxSuitDesignService === 'useDesign'
        && [
          <Div key='productSize'>
            <Div margin='24px 0'>
              <H5 margin='0 16px 8px 0' bold color={theme.color.primaryColor}>{step1TitleInput.productSize}</H5>
              <P color={theme.color.paleBlack}>( กรุณาข้าม กรณีขนาดสินค้าไม่แน่นอน )</P>
              <Div display='flex'>
                <MessageInputWrapper width='calc((100% - 32px)/3)'>
                  <Input width='100%' type='number' value={!_.isEmpty(data.productSize) && data.productSize.width} message='กว้าง (ซม.)'
                  onKeyPress={(e) => {
                    if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                      e.preventDefault()
                    }
                  }}
                  onChange={(e) => {
                    const newBoxSize = Object.assign({}, productSize)
                    newBoxSize.w = e.target.value
                    setProductSize(newBoxSize)
                    onInputChange(newBoxSize, 'productSize')
                  }} />
                </MessageInputWrapper>
                <MessageInputWrapper margin='0 16px' width='calc((100% - 32px)/3)'>
                  <Input type='number' value={!_.isEmpty(data.productSize) && data.productSize.length} width='100%' message='ยาว (ซม.)'
                  onKeyPress={(e) => {
                    if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                      e.preventDefault()
                    }
                  }}
                  onChange={(e) => {
                    const newBoxSize = Object.assign({}, productSize)
                    newBoxSize.l = e.target.value
                    setProductSize(newBoxSize)
                    onInputChange(newBoxSize, 'productSize')
                  }} />
                </MessageInputWrapper>
                <MessageInputWrapper width='calc((100% - 32px)/3)'>
                  <Input type='number' value={!_.isEmpty(data.productSize) && data.productSize.height} width='100%' message='สูง (ซม.)'
                  onKeyPress={(e) => {
                    if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                      e.preventDefault()
                    }
                  }}
                  onChange={(e) => {
                    const newBoxSize = Object.assign({}, productSize)
                    newBoxSize.h = e.target.value
                    setProductSize(newBoxSize)
                    onInputChange(newBoxSize, 'productSize')
                  }} />
                </MessageInputWrapper>
              </Div>
            </Div>
          </Div>,
          <Div key='productTotal' margin='24px 0'>
            <H5 margin='0 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.productQuantityPerPiece}</H5>
            <Row gutter={16}>
              <Col md={8}>
                <MessageInputWrapper>
                  <Input
                    type='number'
                    width='100%'
                    message='ชิ้น'
                    value={data.productQuantityPerPiece}
                    onChange={e => onInputChange(parseInt(e.target.value, 10), 'productQuantityPerPiece')}
                  />
                </MessageInputWrapper>
              </Col>
            </Row>
          </Div>,
        ]
      }
      <DetailWrapperA href={`${process.env.REACT_APP_SITE}/printing-information`} target='_blank' rel="noopener noreferrer">
        <Icon color={theme.color.offWhite} icon='fas fa-book-open' />
        <P margin='0 24px' color={theme.color.offWhite}>สามารถศึกษาข้อมูลและเทคนิคงานพิมพ์ก่อนกรอกข้อมูลด้านล่าง</P>
        <CustomLinkA href={`${process.env.REACT_APP_SITE}/printing-information`} target='_blank' rel="noopener noreferrer">คลิก</CustomLinkA>
      </DetailWrapperA>
      <Query
        query={LIST_PAPER}
        variables={{
          limit: 10000,
          offset: 0,
        }}
      >
        {
          (props) => {
            if (props && props.data && props.data.listPaper && props.data.listPaper.success) {
              const paperData = props.data.listPaper.paper
              const paperTypes = [...paperData.map(v => ({ text: v.name, value: v.name })), { text: 'อื่นๆ', value: 'อื่นๆ' }, { text: 'ไม่ทราบ', value: 'ไม่ทราบ' }]
              const paper = [...paperData.map(v => ({
                paper: {
                  text: v.name,
                  value: v.name,
                },
                gram: v.paperThickness.map(x => x.thickness.toString()).filter((e, i, a) => a.indexOf(e) === i),
              })), { paper: { text: 'อื่นๆ', value: 'อื่นๆ' }, gram: [0] }, { paper: { text: 'ไม่ทราบ', value: 'ไม่ทราบ' }, gram: [0] }]
              return (
                <Row gutter={16}>
                  <Col sm={12} md={8}>
                    <H5 margin='24px 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.paperType}</H5>
                    <Select
                      showSearch
                      style={{ width: '100%', fontSize: '18px' }}
                      placeholder="กรุณาเลือกกระดาษ"
                      optionFilterProp="children"
                      value={data.paperTypeText}
                      onChange={value => onInputChange(value, 'paperTypeText')}
                      onBlur={() => onInputChange(((_.isNumber(data.paperTypeText) && data.paperTypeText > 0) || !_.isEmpty(data.paperTypeText))
                        && _.filter(paper, v => v.paper.value === data.paperTypeText)[0].gram[0], 'paperThickness')}
                    >
                    {
                      paperTypes.map(v => (
                        <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>
                      ))
                    }
                    </Select>
                  </Col>
                  <Col sm={12} md={8}>
                    <H5 margin='24px 0 8px' bold color={theme.color.primaryColor}>ความหนากระดาษ</H5>
                    <Select
                      showSearch
                      style={{ width: '100%', fontSize: '18px' }}
                      placeholder="กรุณาเลือกความหนา"
                      optionFilterProp="children"
                      value={data.paperThickness}
                      onChange={value => onInputChange(value, 'paperThickness')}
                      disabled={!(data.paperTypeText && data.paperTypeText !== '') || data.paperTypeText === 'อื่นๆ' || data.paperTypeText === 'ไม่ทราบ'}
                    >
                    {
                      _.filter(paper, v => v.paper.value === data.paperTypeText).length === 1 && _.filter(paper, v => v.paper.value === data.paperTypeText)[0].gram.map(v => (
                        <Select.Option key={v} value={v}>{v}</Select.Option>
                      ))
                    }
                    </Select>
                  </Col>
                  <Col sm={24} md={8}>
                    <H5 margin='24px 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.printingTechnique}</H5>
                    <Query
                      query={GET_PRINTING_FORM}
                    >
                      {
                        (printingProps) => {
                          if (printingProps.data.getPrintingDetail) {
                            const printingData = printingProps.data.getPrintingDetail.printing
                            const resultBasicColor = printingData.printingBasicColor.map(v => ({
                              text: `พิมพ์ ${v.name}`,
                              value: `พิมพ์ ${v.name}`,
                            }))
                            const resultExtraColor = printingData.printingBasicColor.map(b => (
                              printingData.printingExtraColor.map(v => v.name).map(color => ({
                                text: `พิมพ์ ${b.name} สี + ${color}`,
                                value: `พิมพ์ ${b.name} สี + ${color}`,
                              }))
                            ))
                            const printingTechnique = [...resultBasicColor, ...resultExtraColor.reduce((a, b) => a.concat(b), []), { text: 'อื่นๆ', value: 'อื่นๆ' }, { text: 'ไม่ทราบ', value: 'ไม่ทราบ' }]
                            return (
                              <Select
                                showSearch
                                style={{ width: '100%', fontSize: '18px' }}
                                placeholder="กรุณาเลือกจำนวนสี"
                                optionFilterProp="children"
                                value={data.printingTechniqueText}
                                onChange={value => onInputChange(value, 'printingTechniqueText')}
                              >
                              {
                                printingTechnique.map(v => (
                                  <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>
                                ))
                              }
                              </Select>
                            )
                          }
                          return (
                            <Select
                              showSearch
                              style={{ width: '100%', fontSize: '18px' }}
                              placeholder="กรุณาเลือกจำนวนสี"
                              optionFilterProp="children"
                              value={data.printingTechnique}
                              onChange={value => onInputChange(value, 'printingTechnique')}
                            >
                            {
                              PRINT_TECHNIQUE.map(v => (
                                <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>
                              ))
                            }
                            </Select>
                          )
                        }
                      }
                    </Query>
                  </Col>
                </Row>
              )
            }
            return (<p>something when wrong</p>)
          }
        }
      </Query>
      <Div margin='24px 0'>
        <H5 display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.printingDescription}</H5>
        <Input as='textarea' value={data.printingDescription} rows={4} width='100%' onChange={e => onInputChange(e.target.value, 'printingDescription')} style={{ fontSize: '22px' }} />
      </Div>
      <Div margin='24px 0'>
        <GraphicSelector
          onChange={e => onInputChange(e, 'graphicType')}
          custom
          maxWidth="100%"
          fullWidth
        />
      </Div>
      <Div margin='24px 0'>
        <H5 display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>แนบไฟล์</H5>
        <UploadFile
          list={data.sampleFiles}
          id='uploadFileId'
          multiple
          onChange={(fileList) => {
            let upload = false
            const allowFileType = ['pdf', 'zip', '7z', 'rar', 'jpeg', 'jpg', 'png', 'psd', 'ai', 'eps', 'ppt', 'pptx', 'ppt', 'ppsx', 'ppm', 'pps', 'ppa', 'doc', 'docm', 'docx', 'dot', 'dotx']
            for (let i = 0; i < fileList.length; i += 1) {
              const splitName = fileList[i].name.split('.')
              if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
              || (data.sampleFiles.length > 0 && !_.isUndefined(_.find(data.sampleFiles, d => d.name === fileList[i].name)))) {
                upload = false
                break
              } else {
                upload = true
              }
            }
            let sameFile = false
            for (let i = 0; i < fileList.length; i += 1) {
              if (_.find(data.sampleFiles, d => d.name === fileList[i].name)) {
                sameFile = true
              }
            }
            if (sameFile) {
              message.error('ไม่สามารถอัพโหลดไฟล์ซ้ำกันได้')
            } else if (upload) {
              onUploadFileList(fileList, 'sampleFiles')
            } else {
              message.error('ไม่สามารถอัพโหลดไฟล์ชนิดนี้ได้')
            }
          }}
          onRemoveFile={fileName => onRemoveFileList(fileName, 'sampleFiles')}
          fileType="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, , application/pdf, image/*, .ai, .eps, .zip, .7z, .doc, .docx, .ppt, .pptx"
        />
      </Div>
      {/* {
        data.boxSuitDesignService === 'useDesign'
          ? <Div margin='24px 0'>
            <H5 display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.designDescription}</H5>
            <Input as='textarea' value={data.designDescription} rows={4} width='100%' onChange={e => onInputChange(e.target.value, 'designDescription')} />
          </Div>
          : <Div margin='24px 0'>
            <H5 display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>แนบไฟล์ตัวอย่าง</H5>
            <UploadFile
              list={data.sampleFiles}
              id='uploadFileId'
              multiple
              onChange={(fileList) => {
                let upload = false
                const allowFileType = ['jpg', 'jpeg', 'png', 'pdf']
                for (let i = 0; i < fileList.length; i += 1) {
                  const splitName = fileList[i].name.split('.')
                  if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
                  || (data.sampleFiles.length > 0 && !_.isUndefined(_.find(data.sampleFiles, d => d.name === fileList[i].name)))) {
                    upload = false
                    break
                  } else {
                    upload = true
                  }
                }
                if (upload) {
                  onUploadFileList(fileList, 'sampleFiles')
                } else {
                  message.error('สามารถอัพโหลดได้เฉพาะไฟล์ jpg, jpeg, png, pdf และไม่สามารถอัพรูปซ้ำได้')
                }
              }}
              onRemoveFile={fileName => onRemoveFileList(fileName, 'sampleFiles')}
              fileType="image/*, .pdf"
            />
          </Div>
      }
      <Div margin='24px 0'>
        <H5 display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.printingDescription}</H5>
        <Input as='textarea' value={data.printingDescription} rows={4} width='100%' onChange={e => onInputChange(e.target.value, 'printingDescription')} />
      </Div>
      <GraphicSelector />
      {
        data.boxSuitDesignService === 'useDesign'
          ? <Div margin='24px 0'>
            <H5 display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>แนบไฟล์</H5>
            <UploadFile
              list={data.sampleFiles}
              id='uploadFileId'
              multiple
              onChange={(fileList) => {
                let upload = false
                const allowFileType = ['jpg', 'jpeg', 'png', 'pdf']
                for (let i = 0; i < fileList.length; i += 1) {
                  const splitName = fileList[i].name.split('.')
                  if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
                  || (data.sampleFiles.length > 0 && !_.isUndefined(_.find(data.sampleFiles, d => d.name === fileList[i].name)))) {
                    upload = false
                    break
                  } else {
                    upload = true
                  }
                }
                if (upload) {
                  onUploadFileList(fileList, 'sampleFiles')
                } else {
                  message.error('สามารถอัพโหลดได้เฉพาะไฟล์ jpg, jpeg, png, pdf และไม่สามารถอัพรูปซ้ำได้')
                }
              }}
              onRemoveFile={fileName => onRemoveFileList(fileName, 'sampleFiles')}
              fileType="image/*, .pdf"
            />
          </Div>
          : <Div>
            <ChoiceWrapper id='step1-2'>
              <RadioSelectWithDetail
                data={{ value: 'notUseDesignService', text: 'ไม่ต้องการใช้บริการทำแบบ', name: 'designService' }}
                setDefaultChecked={value => onDesignChoiceChange(value, 'designServiceChoice')}
                defaultChecked={data.designServiceChoice}
              >
                <Div>
                  <P>สำหรับลูกค้าที่มีไฟล์แบบงานในรูปแบบนามสกุล .AI หรือ .EPS โดยภายในไฟล์แบบงานจะต้องประกอบด้วย
                  เส้นสำหรับขึ้นรูปกล่อง และลวดลายบนกล่องที่ลูกค้าออกแบบ ที่ถูกจัดวางคนละ Layer ซึ่งจะสามารถนำไปใช้ใน
                  การพิมพ์ได้โดยตรง</P>
                  <Div margin='16px 0 0'>
                    <UploadFile
                      list={data.designFiles}
                      id='uploadFileService'
                      multiple
                      onChange={(fileList) => {
                        let upload = false
                        const allowFileType = ['ai', 'eps']
                        for (let i = 0; i < fileList.length; i += 1) {
                          const splitName = fileList[i].name.split('.')
                          if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
                          || (data.designFiles.length > 0 && !_.isUndefined(_.find(data.designFiles, d => d.name === fileList[i].name)))) {
                            upload = false
                            break
                          } else {
                            upload = true
                          }
                        }
                        if (upload) {
                          onUploadFileList(fileList, 'designFiles')
                        } else {
                          message.error('สามารถอัพโหลดได้เฉพาะไฟล์ ai, eps และไม่สามารถอัพรูปซ้ำได้')
                        }
                      }}
                      onRemoveFile={fileName => onRemoveFileList(fileName, 'designFiles')}
                      fileType=".ai, .eps"
                    />
                  </Div>
                </Div>
              </RadioSelectWithDetail>
              <RadioSelectWithDetail
                data={{ value: 'useDesignService', text: 'ต้องการใช้บริการทำแบบ', name: 'designService' }}
                setDefaultChecked={value => onDesignChoiceChange(value, 'designServiceChoice')}
                defaultChecked={data.designServiceChoice}
              >
                <Div>
                  <P>สำหรับลูกค้าที่ไม่มีไฟล์แบบงานในรูปแบบนามสกุล .AI หรือ .EPS ซึ่งบริการทำแบบงานจะเป็นการสรา้งไฟล์งานที่
                  ประกอบด้วย เส้นสำหรับขึ้นรูปกล่อง และลวดลายบนกล่องตามที่ลูกค้าออกแบบ เพื่อนำไปใช้ในการพิมพ์กล่อง
                  บรรจุภัณฑ์ของลูกค้าได้
                  </P>
                  <Div margin='16px 0 0'>
                    <UploadFile
                      list={data.designFiles}
                      id='uploadFileService'
                      multiple
                      onChange={(fileList) => {
                        let upload = false
                        const allowFileType = ['png', 'jpg', 'jpeg', 'pdf']
                        for (let i = 0; i < fileList.length; i += 1) {
                          const splitName = fileList[i].name.split('.')
                          if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
                          || (data.designFiles.length > 0 && !_.isUndefined(_.find(data.designFiles, d => d.name === fileList[i].name)))) {
                            upload = false
                            break
                          } else {
                            upload = true
                          }
                        }
                        if (upload) {
                          onUploadFileList(fileList, 'designFiles')
                        } else {
                          message.error('สามารถอัพโหลดได้เฉพาะไฟล์ png, jpg, jpeg, pdf และไม่สามารถอัพรูปซ้ำได้')
                        }
                      }}
                      onRemoveFile={fileName => onRemoveFileList(fileName, 'designFiles')}
                      fileType="image/*, .pdf"
                    />
                  </Div>
                </Div>
              </RadioSelectWithDetail>
            </ChoiceWrapper>
          </Div>
      } */}
    </WidthContainer>
  )
}

/* =============================== END - STEP 1 ================================== */


/* =============================== STEP 2 ================================== */

const BoxRow = ({ children }) => (
  <Div margin='24px 0'>
    <Row gutter={16}>
    {
      children
    }
    </Row>
  </Div>
)

const TaxForm = ({
  data, shipAddress, onChangeData, setWithHoldingTaxRate, setWithHoldingTax, withHoldingTax,
}) => (
  <Div>
    <BoxRow>
      <Col xs={24}>
        <H5 display='block' margin='0 0 8px' bold color={shipAddress ? theme.color.tertiaryColor : theme.color.primaryColor}>{step2TitleInput.name} *</H5>
      </Col>
      <Col xs={24} md={12}>
        <MessageInputWrapper>
          <Input
            width='100%'
            message='ชื่อ'
            value={data.userName}
            onChange={e => onChangeData(e.target.value, 'userName')}
            state={data.userName === '' && 'error'}
          />
        </MessageInputWrapper>
      </Col>
      <Col xs={24} md={12}>
        <MessageInputWrapper>
          <Input
            width='100%'
            message='นามสกุล'
            value={data.userSurname}
            onChange={e => onChangeData(e.target.value, 'userSurname')}
          />
        </MessageInputWrapper>
      </Col>
    </BoxRow>
    <Div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <H5 display='block' margin='24px 0 8px' bold color={shipAddress ? theme.color.tertiaryColor : theme.color.primaryColor}>{step2TitleInput.companyName}</H5>
          <Input
            width='100%'
            value={data.companyName}
            onChange={e => onChangeData(e.target.value, 'companyName')}
          />
          {
            !shipAddress
            && <Div margin='24px 0'>
              <RadioCus
                id={shipAddress ? 'shipCompanyTypeHeadquarter1' : 'companyTypeHeadquarter1'}
                name={shipAddress ? 'shipCompanyType' : 'companyType'}
                data={{ value: 'headquarter', text: 'สำนักงานใหญ่' }}
                checked={data.companyType === 'headquarter' && data.companyName}
                onChange={() => onChangeData('headquarter', 'companyType')}
                disabled={!data.companyName}
              />
              <Div alignItems='center' margin='8px 0 0'>
                <RadioCus
                  id={shipAddress ? 'shipCompanyTypeHeadquarter2' : 'companyTypeHeadquarter2'}
                  name={shipAddress ? 'shipCompanyType' : 'companyType'}
                  data={{ value: 'branch', text: 'สาขาที่' }}
                  checked={data.companyType === 'branch' && data.companyName}
                  onChange={() => onChangeData('branch', 'companyType')}
                  disabled={!data.companyName}
                />
                <Input
                  margin='0 0 0 24px'
                  value={data.branch}
                  disabled={data.companyType !== 'branch' || !data.companyName}
                  onChange={e => onChangeData(e.target.value, 'branch')}
                />
              </Div>
            </Div>
          }
        </Col>
        {
          !shipAddress
          && <Col xs={24} md={12}>
            <H5 display='block' margin='24px 0 8px' bold color={theme.color.primaryColor}>{step2TitleInput.taxId}</H5>
            <Input
              width='100%'
              value={data.taxId}
              onChange={e => onChangeData(e.target.value, 'taxId')}
            />
            <Div margin='24px 0'>
              <Query
                query={GET_TAX_RATE}
                fetchPolicy={'network-only'}
                notifyOnNetworkStatusChange
                onCompleted={(taxProps) => {
                  if (taxProps.taxRate.withHoldingTaxRate) {
                    setWithHoldingTaxRate(taxProps.taxRate.withHoldingTaxRate)
                  }
                }}
              >
                {
                  taxPros => (
                    <Div>
                      <CheckboxAnt
                        checked={withHoldingTax}
                        onChange={(e) => {
                          onChangeData(e.target.checked, 'withHoldingTax')
                          setWithHoldingTax(e.target.checked)
                        }}
                      >
                      </CheckboxAnt>
                      <span>
                        <CheckboxLabel>ต้องการดำเนินการหักภาษี ณ ที่จ่าย {taxPros.data.taxRate && taxPros.data.taxRate.withHoldingTaxRate} % </CheckboxLabel><br/><CheckboxSpan>ลูกค้าจะต้องส่งหนังสือรับรองการหัก ณ ที่จ่ายตัวจริงมาให้ หลังการชำระเงิน</CheckboxSpan>
                      </span>
                    </Div>
                  )
                }
              </Query>
            </Div>
          </Col>
        }
      </Row>
    </Div>
    <Div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <H5 display='block' margin='24px 0 8px' bold color={shipAddress ? theme.color.tertiaryColor : theme.color.primaryColor}>{step2TitleInput.telephoneNumber}</H5>
          <Input
            width='100%'
            value={data.telephoneNumber}
            onChange={e => onChangeData(e.target.value, 'telephoneNumber')}
          />
        </Col>
        <Col xs={24} md={12}>
          <H5 display='block' margin='24px 0 8px' bold color={shipAddress ? theme.color.tertiaryColor : theme.color.primaryColor}>{step2TitleInput.mobileNumber} *</H5>
          <Input
            width='100%'
            value={data.mobileNumber}
            placeholder='กรอกเฉพาะตัวเลข เช่น 0912345678'
            state={data.mobileNumber && data.mobileNumber.length !== 10 && 'error'}
            onKeyPress={(e) => {
              if (!(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault()
              }
            }}
            onChange={e => onChangeData(e.target.value, 'mobileNumber')}
          />
        </Col>
      </Row>
    </Div>
    <Div>
      <Row gutter={16}>
        <Col xs={24} md={24}>
          <H5 display='block' margin='24px 0 8px' bold color={shipAddress ? theme.color.tertiaryColor : theme.color.primaryColor}>{step2TitleInput.email} { !shipAddress && '*'}</H5>
          {
            !shipAddress ? <Input
              width='100%'
              value={data.email}
              onChange={e => onChangeData(e.target.value, 'email')}
              state={data.email && (data.email === '' || !VALIDATE.email.test(data.email)) && 'error'}
            /> : <Input
              width='100%'
              value={data.email}
              onChange={e => onChangeData(e.target.value, 'email')}
              state={data.email && !VALIDATE.email.test(data.email) && 'error'}
            />
          }
        </Col>
      </Row>
    </Div>
    <BoxRow>
      <Col xs={24}>
        <H5
          display='block'
          margin='0 0 8px'
          bold
          color={shipAddress ? theme.color.tertiaryColor : theme.color.primaryColor}
        >{shipAddress ? 'ที่อยู่สำหรับจัดส่ง *' : `${step2TitleInput.address} *`}</H5>
      </Col>
      <Col xs={24}>
        <MessageInputWrapper>
          <Input
            width='100%'
            message='เลขที่อยู่'
            value={data.addressNumber}
            onChange={e => onChangeData(e.target.value, 'addressNumber')}
            state={data.addressNumber === '' && 'error'}
          />
        </MessageInputWrapper>
      </Col>
      <Col xs={24} md={12}>
        <MessageInputWrapper>
          <Input
            width='100%'
            message='แขวง / ตำบล'
            margin='16px 0 0'
            value={data.subDistrict}
            onChange={e => onChangeData(e.target.value, 'subDistrict')}
            state={data.subDistrict === '' && 'error'}
          />
        </MessageInputWrapper>
      </Col>
      <Col xs={24} md={12}>
        <MessageInputWrapper>
          <Input
            width='100%'
            message='เขต / อำเภอ'
            margin='16px 0 0'
            value={data.district}
            onChange={e => onChangeData(e.target.value, 'district')}
            state={data.district === '' && 'error'}
          />
        </MessageInputWrapper>
      </Col>
      <Col xs={24} md={12}>
        <Div margin='16px 0 0'>
          <Select
            showSearch
            style={{ width: '100%', fontSize: '18px' }}
            placeholder="กรุณาเลือกจังหวัด"
            optionFilterProp="children"
            value={data.province}
            onChange={value => onChangeData(value, 'province')}
          >
          {
            PROVINCE.map(v => (
              <Select.Option key={v.pid} value={v.name}>{v.name}</Select.Option>
            ))
          }
          </Select>
          <SmallText color={theme.color.lightGrey} margin='8px 0 0'>จังหวัด</SmallText>
        </Div>
      </Col>
      <Col xs={24} md={12}>
        <MessageInputWrapper>
          <Input
            type='number'
            width='100%'
            message='รหัสไปรษณีย์'
            margin='16px 0 0'
            value={data.postalCode}
            state={data.postalCode && data.postalCode.length !== 5 && 'error'}
            onChange={(e) => {
              if (e.target.value.length <= 5) {
                onChangeData(e.target.value, 'postalCode')
              }
            }}
          />
        </MessageInputWrapper>
      </Col>
    </BoxRow>
  </Div>
)

export const Step2 = ({
  data,
  onChangeData,
  setWithHoldingTaxRate,
  setWithHoldingTax,
  withHoldingTax,
}) => (
  <WidthContainer>
    <H4 display='block' bold margin='32px 0' color={theme.color.tertiaryColor}>รายละเอียดการออกใบกำกับภาษี</H4>
    <TaxForm
      data={data.taxData}
      onChangeData={(value, name) => onChangeData(value, name, 'taxData')}
      withHoldingTax={withHoldingTax}
      setWithHoldingTaxRate={setWithHoldingTaxRate}
      setWithHoldingTax={setWithHoldingTax}
    />
    <Div margin='24px 0'>
      <ChoiceWrapper id='step2'>
        <CheckboxSelectWithDetail
          data={{ value: 'newShipData', text: 'ที่อยู่สำหรับจัดส่งแตกต่างจากที่อยู่ด้านบน', name: 'newShipData' }}
          setDefaultChecked={value => onChangeData(value, 'newShipData')}
          checked={data.newShipData}
        >
          <Div>
            <TaxForm
              data={data.shipData}
              shipAddress
              onChangeData={(value, name) => onChangeData(value, name, 'shipData')}
            />
          </Div>
        </CheckboxSelectWithDetail>
      </ChoiceWrapper>
    </Div>
    <BoxRow>
      <Col xs={24}>
        <H5 display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>รายละเอียดเพิ่มเติม</H5>
      </Col>
      <Col xs={24}>
        <Input
          as='textarea'
          width='100%'
          value={data.detail}
          onChange={e => onChangeData(e.target.value, 'detail')}
        />
      </Col>
    </BoxRow>
  </WidthContainer>
)

/* =============================== END - STEP 2 ================================== */

const QuotationCreate = () => {
  const [products, setProducts] = useState([{
    id: 0,
    type: quotationType.ITEM,
    title: '',
    price: '',
    amount: '',
  }])
  const [taxRate, setTaxRate] = useState(undefined)
  const [withHoldingTax, setwithHoldingTax] = useState(false)
  const [callMutation, setCallMutation] = useState(false)
  const [disabledButton, setDisabledButton] = useState(true)
  const [withHoldingTaxRate, setWithHoldingTaxRate] = useState(0)
  const [stepOneData, setStepOneData] = useState({
    boxSuitDesignService: 'notUseDesign',
    name: '',
    boxSize: {},
    quantity: 0,
    productSize: {},
    productQuantityPerPiece: 0,
    designDescription: '',
    printingDescription: '',
    sampleFiles: [],
    designServiceChoice: 'notUseDesignService',
    designFiles: [],
    graphicType: null,
  })
  const [stepTwoData, setStepTwoData] = useState({
    newShipData: false,
    taxData: {},
    shipData: {},
    detail: '',
  })
  const step2RequireField = [
    'userName',
    'userSurname',
    'email',
    'addressNumber',
    'district',
    'subDistrict',
    'province',
    'postalCode',
    'mobileNumber',
  ]

  useEffect(() => {
    if (callMutation) {
      setDisabledButton(true)
    } else {
      const objectData = stepTwoData.newShipData ? ['taxData', 'shipData'] : ['taxData']
      for (let y = 0; y < objectData.length; y += 1) {
        const parentName = objectData[y]
        if (!_.isEmpty(stepTwoData[objectData[y]])) {
          for (let i = 0; i < step2RequireField.length; i += 1) {
            if (
              _.filter(Object.keys(stepTwoData[parentName]), d => d === step2RequireField[i]).length === 0
              || ((step2RequireField[i] !== 'postalCode' || step2RequireField[i] !== 'email') && stepTwoData[parentName][step2RequireField[i]] === '')
              || (step2RequireField[i] === 'postalCode' && (stepTwoData[parentName].postalCode === 0 || stepTwoData[parentName].postalCode.length !== 5))
              || (step2RequireField[i] === 'email' && !VALIDATE.email.test(stepTwoData[parentName].email))
              || (step2RequireField[i] === 'mobileNumber' && stepTwoData[parentName].mobileNumber.length !== 10)
              || stepOneData === ''
              // || _.filter(products, v => (v.type === quotationType.ITEM ? !_.isEmpty(v.title) && v.amount > 0 && !_.isEmpty(v.price) : !_.isEmpty(v.title) && !_.isEmpty(v.price))).length <= 0
            ) {
              if (parentName === 'shipData' && step2RequireField[i] === 'email' && !stepTwoData[parentName].email) {
                setDisabledButton(false)
                break
              }
              setDisabledButton(true)
              break
            } else {
              setDisabledButton(false)
            }
          }
        } else {
          setDisabledButton(true)
          break
        }
      }
    }
  })

  return (
    <Mutation
      mutation={QUOTATION_REQUEST_CREATE}
    >
    {
      doCreateQuotationRequest => (
        <Div>
          <BgContainer>
            <Step1
              data={stepOneData}
              setStepOneData={setStepOneData}
              onInputChange={(data, name) => {
                const newData = Object.assign({}, stepOneData)
                if (name === 'boxSize') {
                  newData.boxSize = {
                    width: data.w,
                    height: data.h,
                    length: data.l,
                  }
                } else if (name === 'productSize') {
                  newData.productSize = {
                    width: data.w,
                    height: data.h,
                    length: data.l,
                  }
                } else if (name === 'boxSuitDesignService') {
                  newData[name] = data
                  if (data === 'notUseDesign') {
                    newData.productSize = {}
                    newData.productQuantityPerPiece = 0
                    newData.designDescription = ''
                  } else {
                    newData.designFiles = []
                  }
                } else {
                  newData[name] = data
                }
                setStepOneData(newData)
              }}
              onDesignChoiceChange={(data, name) => {
                const newData = Object.assign({}, stepOneData)
                newData[name] = data
                newData.designFiles = []
                setStepOneData(newData)
              }}
              onUploadFileList={(fileList, name) => {
                const newData = Object.assign({}, stepOneData)
                const newFileList = [...stepOneData[name]]
                if (fileList.length >= 1) {
                  Object.keys(fileList).forEach((v) => {
                    if (v !== 'length') {
                      newFileList.push(fileList[v])
                    }
                  })
                }
                newData[name] = newFileList
                setStepOneData(newData)
              }}
              onRemoveFileList={(fileName, name) => {
                const newData = Object.assign({}, stepOneData)
                const newFileList = [...stepOneData[name]]
                _.remove(newFileList, v => v.name === fileName)
                newData[name] = newFileList
                setStepOneData(newData)
              }}
              setDisabledButton={value => setDisabledButton(value)}
            />
            <Step2
              data={stepTwoData}
              onChangeData={(data, name, parentName) => {
                const newData = Object.assign({}, stepTwoData)
                if (parentName) {
                  newData[parentName][name] = data
                } else {
                  newData[name] = data
                }
                if (parentName === 'taxData' && name === 'companyName' && (data === '' || data === null)) {
                  newData.taxData.branch = ''
                }
                if (parentName === 'shipData' && name === 'companyName' && (data === '' || data === null)) {
                  newData.shipData.branch = ''
                }
                setStepTwoData(newData)
              }}
              withHoldingTax={withHoldingTax}
              setWithHoldingTax={setwithHoldingTax}
              setWithHoldingTaxRate={setWithHoldingTaxRate}
            />
            <Line />
            <CreateQuotation
              key='quotationCreate'
              hideButton
              getData={(productsValue, taxRateValue) => {
                setProducts(productsValue)
                setTaxRate(taxRateValue)
                // setwithHoldingTax(withHoldingTaxValue)
              }}
              onChangeWithHoldingTax={setwithHoldingTax}
              withHoldingTax={withHoldingTax}
            />
            {
              <ButtonWrapper>
                <ButtonForm
                  onClick={() => {
                    setCallMutation(true)
                    const input = {}
                    Object.keys(stepOneData).forEach((v) => {
                      if (v !== 'boxSuitDesignService') {
                        if (v === 'boxSize' || v === 'productSize') {
                          if (!_.isEmpty(stepOneData[v])) {
                            input[v === 'boxSize' ? 'width' : 'productWidth'] = parseFloat(stepOneData[v].width, 10)
                            input[v === 'boxSize' ? 'length' : 'productLength'] = parseFloat(stepOneData[v].length, 10)
                            input[v === 'boxSize' ? 'height' : 'productHeight'] = parseFloat(stepOneData[v].height, 10)
                          }
                        } else if (v === 'designServiceChoice') {
                          input.useCompleteDesign = stepOneData[v] === 'useDesignService'
                        } else if ((typeof stepOneData[v] === 'number' && stepOneData[v] > 0) || !_.isEmpty(stepOneData[v])) {
                          input[v] = stepOneData[v]
                        }
                      }
                    })
                    input.isSameAddress = !stepTwoData.newShipData
                    const stepTwoDataList = input.isSameAddress ? ['taxData'] : ['taxData', 'shipData']
                    stepTwoDataList.forEach((d) => {
                      Object.keys(stepTwoData[d]).forEach((v) => {
                        let inputName = v
                        if (d === 'shipData') {
                          inputName = `shipping${v.charAt(0).toUpperCase()}${v.slice(1)}`
                        }
                        input[inputName] = stepTwoData[d][v]
                      })
                    })
                    input.taxRate = taxRate
                    input.withHoldingTax = withHoldingTax
                    input.withHoldingTaxRate = withHoldingTaxRate
                    input.addressDescription = stepTwoData.detail

                    const filterProduct = products
                    // _.filter(products, v => (v.type === quotationType.ITEM
                    //   ? !_.isEmpty(v.title) && v.amount > 0 && !_.isEmpty(v.price)
                    //   : !_.isEmpty(v.title) && !_.isEmpty(v.price)))
                    input.items = !_.isEmpty(filterProduct) && filterProduct.map(v => (
                      {
                        itemType: v.type,
                        itemName: v.title,
                        price: v.price ? parseFloat(v.price, 10) : null,
                        quantity: v.amount ? parseInt(v.amount, 10) : null,
                      }
                    ))
                    input.isGraphic = stepOneData.graphicType && stepOneData.graphicType.name
                    delete input.graphicType
                    delete <input type="text" className="lineId" />
                    doCreateQuotationRequest({
                      variables: {
                        input,
                        // useBoxSuitDesign: stepOneData.boxSuitDesignService === 'useDesign',
                        useBoxSuitDesign: false,
                      },
                    }).then(({ data }) => {
                      if (data.createQuotationRequest) {
                        if (data.createQuotationRequest.success) {
                          setCallMutation(false)
                          message.success(data.createQuotationRequest.message)
                          setTimeout(() => {
                            window.location = PATH.quotationList
                          }, 300)
                        } else {
                          message.error(data.createQuotationRequest.message)
                        }
                      }
                    })
                  }}
                  inverse
                  icon='fal fa-plus'
                  color={theme.color.primaryColor}
                  text='สร้าง Quotation และส่งอีเมลแจ้งลูกค้า'
                  disabled={disabledButton || callMutation}
                  loading={callMutation}
                />
              </ButtonWrapper>
            }
          </BgContainer>
        </Div>
      )
    }
    </Mutation>
  )
}

export default QuotationCreate
