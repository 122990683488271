import styled from 'styled-components'
import {
  Button,
} from 'konsys-ui'
import { theme, media } from '../../../../styles/_variables'


export const PreviewCoverImage = styled.img`
  width: 160px;
  border-radius: 1px;
  margin-bottom: 24px;
  ${media.desktop`
    width: 196px;
  `}
  ${media.laptopL`
    width: 172px;
  `}
  ${media.laptop`
    width: 160px;
  `}
  ${media.tablet`
    width: 160px;
  `}
  ${media.mobileL`
    width: 142px;
  `}
`

export const DivImage = styled.div`
  width: 316px;
`

export const DivImageAction = styled.div`
  display: flex;
  padding: 12px 0px;
  width: 300px;
`

export const PreviewImageBox = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
`

export const PreviewImage = styled.img`
  width: 100%;
  border-radius: 1px;
`

export const DeleteImageButton = styled(Button)`
  font-size: ${theme.fonts.size.subTitle};
  padding: 4px 16px;
  width: 120px;
  justify-content: center;
  display: flex;
  border-radius: 8px;
`

export default PreviewCoverImage
