import React, { useState } from 'react'
import { Icon } from 'konsys-ui'
import Lightbox from 'react-images'
import {
  DescriptionContainer, Title, ImagesContainer,
  TitleWrapper, ImageWrapper,
} from './styled'

const SampleImageList = ({
  title = 'title',
  data = [],
  onClick,
}) => {
  const [openLightBox, setOpenLightBox] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  return (
  <DescriptionContainer>
    <TitleWrapper>
      <Title>{title}</Title>
    </TitleWrapper>
    <ImagesContainer>
      {data && data.map((item, index) => (
        <Image
          key={index}
          img={item.fileUrl}
          onClick={() => {
            setCurrentImage(index)
            setOpenLightBox(true)
          }}
        />
      ))}
    </ImagesContainer>
    <Lightbox
      images={data ? data.sort((a, b) => a.ordering - b.ordering).map(item => (
        { src: item.fileUrl }
      )) : []}
      currentImage={currentImage}
      isOpen={openLightBox}
      onClickNext={() => setCurrentImage(currentImage + 1)}
      onClickPrev={() => setCurrentImage(currentImage - 1)}
      onClose={() => setOpenLightBox(false)}
      backdropClosesModal={true}
    />
  </DescriptionContainer>)
}

const Image = ({ img, onClick }) => (
  <ImageWrapper img={img} onClick={() => onClick()}>
    <Icon icon='far fa-search-plus' />
  </ImageWrapper>
)

export default SampleImageList
