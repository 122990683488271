import styled from 'styled-components'
import { Button } from 'konsys-ui'
import { InputNumber as InputNumberAnt } from 'antd'
import { theme } from '../../../../../styles/_variables'


export const ButtonEdit = styled(Button)`
  border-radius: 20px;
  padding: 0px 8px;
  i {
    font-size: ${theme.fonts.size.label} !important;
    font-weight: ${theme.fonts.style.medium};
  }
  span {
    font-size: ${theme.fonts.size.normal};
    font-weight: ${theme.fonts.style.medium};
  }
`

export const InputNumber = styled(InputNumberAnt)`
  .ant-input-number-input {
    text-align: center;
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
  }
`

export default ButtonEdit
