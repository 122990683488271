import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import { Link } from 'react-router-dom'
import { message } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import PATH from '../../../../constants/path'
import { ADD_FOILING } from '../../../../constants/graphql/mutation'
import { theme } from '../../../../styles/_variables'
import FoilingForm from '../components/foiling-form'
import { generateRowsIncrementStepTable as generateRows } from '../../../../utils/util-services'

const { color } = theme


const FoilingCreate = () => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    costMinimum: 0,
    minimumBlockCost: 0,
    coverPhoto: [],
    sampleVideo: [],
    imagePhoto: [],
    foilingBlockCost: [],
    headerFoilingCosts: [{
      title: 'จำนวนผลิต (ชิ้น)',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: 'number',
      editable: false,
      editColumn: false,
      alignCell: 'center',
    }, {
      title: 'ราคาเริ่มต้น (บาท/ตร.นิ้ว)',
      dataIndex: 'column1',
      key: 'column1',
      inputType: 'number',
      editable: true,
      editColumn: false,
      alignCell: 'center',
    }],
    dataFoilingCosts: generateRows(),
    headerFoilingOperationCosts: [{
      title: 'จำนวนผลิต (ชิ้น)',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: 'number',
      editable: false,
      editColumn: false,
      alignCell: 'center',
    }, {
      title: 'ราคาเริ่มต้น (บาท)',
      dataIndex: 'column1',
      key: 'column1',
      inputType: 'number',
      editable: true,
      editColumn: false,
      alignCell: 'center',
    }],
    dataFoilingOperationCosts: generateRows(),
  })

  return (
        <Div display='flex' flexDirection='column'>
            <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
                <H4 bold color={color.primaryColor} margin='32px 0'>
                    <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                      <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                    </Link> {' > '}
                    <Link to='/package-setting/foiling' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                    <H4 bold color={color.primaryColor}>การปั๊มฟอยล์-ทองเค</H4>
                    </Link> {' > '} New
                </H4>
                <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                  <Link to='/package-setting/foiling' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                    <Button text='ยกเลิก' icon='fa fa-ban' ghost margin='0 16px 0 0' color={color.grey}/>
                  </Link>
                  <Mutation
                    mutation={ADD_FOILING}
                  >
                    {
                      doAddFoiling => (
                        <Button
                          text='บันทึก'
                          icon='fa fa-save'
                          inverse
                          margin='0 16px 0 0'
                          onClick={() => {
                            setCallMutation(true)
                            if (formValues.name === '') {
                              message.warning('กรุณากรอกข้อมูลชื่อการปั๊มฟอยล์-ทองเค')
                              setCallMutation(false)
                              setIsValidate(false)
                              return
                            }

                            if (formValues.name.match(new RegExp(/^\s/)) !== null) {
                              message.warning('กรุณากรอกข้อมูลชื่อการปั๊มฟอยล์-ทองเคให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
                              setCallMutation(false)
                              setIsValidate(false)
                              return
                            }
                            const input = {
                              name: formValues.name,
                              description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
                              minimumCost: parseFloat(formValues.costMinimum) || 0,
                              minimumBlockCost: parseFloat(formValues.minimumBlockCost) || 0,
                              coverPhoto: formValues.coverPhoto,
                              video: formValues.sampleVideo,
                              imagePhoto: formValues.imagePhoto,
                              foilingCostColumnName: formValues.headerFoilingCosts.filter(column => /column*/.test(column.key)).map(column => ({
                                name: column.title,
                                type: column.key,
                              })),
                              foilingCost: formValues.dataFoilingCosts.map(row => ({
                                quantity: parseInt(row.quantity, 10),
                                column1: parseFloat(row.column1),
                                column2: parseFloat(row.column2),
                                column3: parseFloat(row.column3),
                                column4: parseFloat(row.column4),
                                column5: parseFloat(row.column5),
                              })),
                              foilingBlockCost: formValues.foilingBlockCost.reduce((result, item) => {
                                for (let index = 0; index < item.size.length; index += 1) {
                                  result.push({
                                    width: parseFloat(item.size[index].width),
                                    height: parseFloat(item.size[index].height),
                                    price: parseFloat(item.price[index]),
                                  })
                                }
                                return result
                              }, []),
                              foilingOperationCostColumnName: formValues.headerFoilingOperationCosts.filter(column => /column*/.test(column.key)).map(column => ({
                                name: column.title,
                                type: column.key,
                              })),
                              foilingOperationCost: formValues.dataFoilingOperationCosts.map(row => ({
                                quantity: parseInt(row.quantity, 10),
                                column1: parseFloat(row.column1),
                                column2: parseFloat(row.column2),
                                column3: parseFloat(row.column3),
                                column4: parseFloat(row.column4),
                                column5: parseFloat(row.column5),
                              })),
                            }
                            doAddFoiling({
                              variables: {
                                input,
                              },
                            }).then(({ data }) => {
                              if (data.addFoiling) {
                                setCallMutation(false)
                                message.success(data.addFoiling.message)
                                setTimeout(() => {
                                  window.location = PATH.foilingSetting
                                }, 350)
                              } else {
                                setCallMutation(false)
                                if (data.addFoiling.message === 'ชื่อ การปั๊มฟอยล์-ทองเค นี้มีอยู่ในระบบแล้ว') {
                                  setIsValidate(false)
                                  setCallMutation(false)
                                }
                                message.error(data.addFoiling.message)
                              }
                            })
                          }}
                          disabled={callMutation}
                        />
                      )
                    }
                  </Mutation>
                </Div>
                </Div>
            </Div>
            <FoilingForm
              formValues={formValues}
              setFormValues={setFormValues}
              isValidate={isValidate}
              setIsValidate={setIsValidate}
              action={'create'}
            />
        </Div>
  )
}

export default FoilingCreate
