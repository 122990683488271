import styled, { css } from 'styled-components'
import { media, minMedia } from '../../../styles/_variables'

export const WidthContainer = styled.div`
  width: 100%;
  margin-left: 50%;
  max-width: 64vw;
  transform: translateX(-50%);
  margin-top: -60px;
  margin-bottom: -60px;
  ${props => props.bgColor && css`
    background-color: ${props.bgColor};
  `}
  ${minMedia.laptopL`
    max-width: 64vw;
  `}
  ${media.desktop`
    max-width: 100%;
    padding: 0px 60px;
  `}
  ${media.tablet`
    width: 100%;
    max-width: 100%;
  `}
`

export default WidthContainer
