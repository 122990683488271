import styled from 'styled-components'
import { theme, media } from '../../../../../styles/_variables'


export const PreviewCoverImage = styled.img`
  width: 160px;
  border-radius: 1px;
  margin-bottom: 24px;
  ${media.desktop`
    width: 196px;
  `}
  ${media.laptopL`
    width: 172px;
  `}
  ${media.laptop`
    width: 160px;
  `}
  ${media.tablet`
    width: 160px;
  `}
  ${media.mobileL`
    width: 142px;
  `}
`

export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`

export default PreviewCoverImage
