import React, { useState } from 'react'
import {
  WidthContainer, FullWidthContainer,
} from './styled'
import CoatingTitleInput from '../title'
import CoatingDescriptionInput from '../description'
import CoatingCoverImageInput from '../cover-image'
import CoatingSampleVideoInput from '../sample-video'
import CoatingSampleImageInput from '../sample-image'
import CoatingCostTable from '../cost-table'
import CoatingCostMinimumInput from '../cost-minimum'


const CoatingForm = ({
  formValues,
  setFormValues,
  isValidate,
  setIsValidate,
  action,
}) => {
  const [, setDisabledButton] = useState(true)
  return (
    <>
      <WidthContainer>
        <CoatingTitleInput
          label={'ชื่อวิธีการเคลือบ'}
          data={formValues}
          onInputChange={setFormValues}
          setDisabledButton={setDisabledButton}
          setIsValidate={setIsValidate}
          isValidate={isValidate}
        />
        <CoatingDescriptionInput
          data={formValues}
          onInputChange={setFormValues}
        />
        <CoatingCoverImageInput
          data={formValues}
          newFileList={formValues.coverPhoto || []}
          setNewFileList={setFormValues}
        />
        <CoatingSampleVideoInput
          data={formValues}
          newFileList={formValues.sampleVideo || []}
          setNewFileList={setFormValues}
        />
        <CoatingSampleImageInput
          data={formValues}
          newFileList={formValues.imagePhoto || []}
          setNewFileList={setFormValues}
        />
      </WidthContainer>
      <FullWidthContainer>
        <CoatingCostTable
          data={formValues}
          onChange={setFormValues}
        />
        <CoatingCostMinimumInput
          data={formValues}
          onInputChange={setFormValues}
          action={action}
        />
      </FullWidthContainer>
    </>
  )
}

export default CoatingForm
