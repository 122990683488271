import styled from 'styled-components'
import { Radio } from 'antd'

import { theme, media } from '../../../../../styles/_variables'


export const Title = styled.div`
  color: ${theme.color.primaryColor};
  font-size: 28px;
  font-weight: ${theme.fonts.style.medium};
`

export const DivImage = styled.div`
  padding: 6px;
  background-color: ${theme.color.offWhite};
  border-radius: 30px;
  border: 1px solid ${theme.color.primaryColor};
`

export const ImgIcon = styled.img`
  width: 36px;
`

export const Label = styled.div`
  color: ${props => (props.checked ? theme.color.offWhite : theme.color.primaryColor)};
  font-size: ${theme.fonts.size.subTitle};
  ${media.laptopL`
    font-size: 24px;
  `}
  ${media.laptop`
    font-size: 20px;
  `}
`

export const DivCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
`

export const DivRadioOrderType = styled.div`
  border: 1px solid ${theme.color.primaryColor};
  width: 100%;
  margin: 4px;
  padding: 24px 0px;
  height: 200px;
  ${media.laptopL`
    height: 170px;
  `}
  border-radius: 12px;
  background-color: ${props => (props.checked ? theme.color.greenPrimary : theme.color.offWhite)};
  cursor: pointer;
`

export const RadioOrderType = styled(Radio)`
  margin-right: 0px;
  .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${theme.color.primaryColor};
  }
  .ant-radio-inner::after {
    background-color: ${theme.color.textColor};
  }
  .ant-radio-inner {
    border: 1px solid ${theme.color.primaryColor};
  }
  .ant-radio-inner:hover {
    border: 1px solid ${theme.color.primaryColor};
  }
  .ant-radio-checked::after {
    border: 1px solid ${theme.color.primaryColor};
  }
`

export default Title
