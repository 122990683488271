/* eslint-disable radix */
import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import moment from 'moment'
import {
  message, Popconfirm,
  Row, Col, Select,
} from 'antd'
import { Query, Mutation } from 'react-apollo'
import {
  Div, H4, SmallText, Loading, Button,
  P, Input, H5, ButtonForm, LoadingIcon,
} from 'konsys-ui'
import {
  CreateQuotation, Notes, QuotationPDFList, StatusDropdown,
  PaymentEvidence, ShippedData, HistoryLog, HistoryLogQuotation,
} from '../../../components/quotation-components'
import UploadFile from '../../../components/common/upload'
import { Radio } from '../../../components/common/choice'
import BreadCrumb from '../../../components/common/breadcrumb'
import SummaryBox from '../../../components/common/summary'
import PATH from '../../../constants/path'
import { PAPER, PRINT_TECHNIQUE } from '../../../constants/detail'
import { theme } from '../../../styles/_variables'
import {
  status, statusKey, userDataInput, step1TitleInput,
} from '../constants'
import {
  GET_QUOTATION,
  LIST_PAPER,
  GET_PRINTING_FORM,
  GET_PACKAGE_DETAIL,
  PRICE_CALCULATOR,
} from '../../../constants/graphql/query'
import {
  EDIT_TAX_INVOICE, DELETE_QUOTATION, DUPLICATE_QUOTATION,
  UPDATE_BOX_DETAIL_QUOTATION,
} from '../../../constants/graphql/mutation'
import {
  HeaderWrapper, Line, DeleteButton,
  WidthContainer, MessageInputWrapper, RadioSelectWithDetailWrapper, ChoiceContent,
  WidthPartContainer, Content, ContentPart,
  QuotationCreateDiv, ActionZone, IconCalculate,
  Bar, ButtonBack, Title,
  LabelSummary, SummaryDivMobile, FullWidthContainer, WidthPriceContainer,
} from './styled'
import GraphicSelector from '../../../components/package-calculator/components/graphic-selector'
import Summary from '../../../components/package-calculator/components/summary'
import TablePrice from '../../../components/package-calculator/components/table-price'
import * as quotationType from '../../../constants/quotation-order-type'
import Detail from '../../../components/package-calculator/components/detail'
import Parts from '../../../components/package-calculator/components/parts'
import SummaryPartContent from '../../../components/package-calculator/components/summary-content'
import CalculationIcon from '../../../images/icon/CalculationIcon.png'
import SummaryToggleComponent from '../../../components/common/summary-toggle'


const EditableAddress = ({
  title, dataList, updateData, editable,
}) => {
  const [address, setAddress] = useState(_.reduce(dataList, (result, value) => {
    if (value !== null && value.name !== 'lineId') {
      result.push(value)
    }
    return result
  }, []))
  return (
    <Div>
      <SummaryBox
        title={title}
        dataList={address}
        editable={editable}
        onChange={(index, newValue, name) => {
          const newAddress = [...address]
          if (name) {
            const selectedData = _.filter(newAddress, v => v.name === name)
            if (selectedData.length > 0) {
              selectedData[0].value = newValue
            } else {
              newAddress.push({ title: '', value: newValue, name })
            }
          } else {
            newAddress[index].value = newValue
          }
          setAddress(newAddress)
          updateData(newAddress)
        }}
      />
    </Div>
  )
}

export const EditPredefinedForm = ({
  packageValues,
  setIsEditableBoxDetail,
}) => {
  const [step, setStep] = useState(1)
  const [formValues, setFormValues] = useState({
    showPromotion: true,
    amountProduction: 0,
    productionType: packageValues.produceType || 'newOrder',
    widthHoldingTax: false,
    graphicId: 0,
    moreDetail: packageValues.moreDetail || '',
    incrementalSteps: 0,
    minimumManufacture: 0,
    graphicFile: packageValues.graphicFile || [],
    partData: [],
    partDetail: [],
    totalCost: [],
    graphicName: packageValues.graphicName,
  })
  const [callCalculation, setCallCalculation] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 375)
  const [isMobileXL, setIsMobileXL] = useState(window.innerWidth <= 768)
  const [isLaptop, setIsLaptop] = useState(window.innerWidth > 1440)
  const [isLaptopL, setIsLaptopL] = useState(window.innerWidth > 1920)

  const screenSizeHandler = () => setIsMobileView(window.innerWidth <= 375)
  const screenSizeHandler2 = () => setIsMobileXL(window.innerWidth <= 768)
  const screenSizeHandler3 = () => setIsLaptop(window.innerWidth > 1440)
  const screenSizeHandler4 = () => setIsLaptopL(window.innerWidth > 1920)

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    window.addEventListener('resize', screenSizeHandler2)
    window.addEventListener('resize', screenSizeHandler3)
    window.addEventListener('resize', screenSizeHandler4)
    return () => {
      window.removeEventListener('resize', screenSizeHandler)
      window.removeEventListener('resize', screenSizeHandler2)
      window.removeEventListener('resize', screenSizeHandler3)
      window.removeEventListener('resize', screenSizeHandler4)
    }
  }, [])
  const [activeTab, setActiveTab] = useState(0)
  const onChangeTab = (key) => {
    setActiveTab(key)
  }
  const ContentRef = useRef(null)
  const [summaryActive, setSummaryActive] = useState(false)
  return (
    <Div>
      {/* <Button
        text='ยกเลิก'
        inverse
        buttonStyle='round'
        small
        onClick={() => setIsEditableBoxDetail(false)}
        icon={'far fa-chevron-double-left'}
        color={theme.color.secondaryColor}
      /> */}
      <Query
        query={GET_PACKAGE_DETAIL}
        fetchPolicy='network-only'
        variables={{
          id: packageValues.packageId,
        }}
        notifyOnNetworkStatusChange
        onCompleted={(data) => {
          if (data.packaging.success) {
            const packagingData = data.packaging.package
            const formPartData = packagingData.packageParts.map((item, index) => (
              {
                packagePartId: item.packagePartId,
                packagePartName: item.name,
                packageData: packagingData,
                measurementUnit: packageValues.partData[index].measurementUnit.label === 'ซม.' ? {
                  value: 1,
                  text: 'เซนติเมตร (ซม.)',
                  key: 'cm',
                  label: 'ซม.',
                } : {
                  value: 2,
                  text: 'นิ้ว',
                  key: 'inches',
                  label: 'นิ้ว',
                },
                sizeList: {
                  a: _.isNumber(packageValues.partData[index].sizeList.a) ? packageValues.partData[index].sizeList.a : null,
                  b: _.isNumber(packageValues.partData[index].sizeList.b) ? packageValues.partData[index].sizeList.b : null,
                  c: _.isNumber(packageValues.partData[index].sizeList.c) ? packageValues.partData[index].sizeList.c : null,
                  d: _.isNumber(packageValues.partData[index].sizeList.d) ? packageValues.partData[index].sizeList.d : null,
                  e: _.isNumber(packageValues.partData[index].sizeList.e) ? packageValues.partData[index].sizeList.e : null,
                  f: _.isNumber(packageValues.partData[index].sizeList.f) ? packageValues.partData[index].sizeList.f : null,
                  g: _.isNumber(packageValues.partData[index].sizeList.g) ? packageValues.partData[index].sizeList.g : null,
                  h: _.isNumber(packageValues.partData[index].sizeList.h) ? packageValues.partData[index].sizeList.h : null,
                  i: _.isNumber(packageValues.partData[index].sizeList.i) ? packageValues.partData[index].sizeList.i : null,
                  j: _.isNumber(packageValues.partData[index].sizeList.j) ? packageValues.partData[index].sizeList.j : null,
                },
                sampleImage: item.packagePartFiles,
                printing: {
                  selectedPrinting: packageValues.partData[index].printing ? packageValues.partData[index].printing.printingName ? 1 : 0 : 0,
                  side: packageValues.partData[index].printing.printingTypeName !== '' ? packageValues.partData[index].printing.printingTypeName === 'พิมพ์หน้าเดียว' ? 1 : 2 : null,
                  printingId: packageValues.partData[index].printing ? packageValues.partData[index].printing.printingName ? 1 : 0 : 0,
                  printingName: packageValues.partData[index].printing ? packageValues.partData[index].printing.printingBasicColorName : '-',
                  printingType: packageValues.partData[index].printing ? packageValues.partData[index].printing.printingTypeName === 'พิมพ์หน้าเดียว' ? 1 : 2 : null,
                  printingTypeName: 'พิมพ์หน้าเดียว',
                  printingBasicColorId: packageValues.partData[index].printing ? packageValues.partData[index].printing.printingBasicColorId : 0,
                  printingBasicColorName: packageValues.partData[index].printing ? packageValues.partData[index].printing.printingBasicColorName : '-',
                  checkExtraColor: packageValues.partData[index].printing ? packageValues.partData[index].printing.printingExtraColorId.length > 0 : false,
                  printingExtraColorId: packageValues.partData[index].printing ? packageValues.partData[index].printing.printingExtraColorId : 0,
                },
                paperList: item.paper.sort((a, b) => a.paperId - b.paperId),
                paper: {
                  paperId: packageValues.partData[index].paper ? packageValues.partData[index].paper.paperId : item.paper.length > 0 && item.paper[0].paperId,
                  name: packageValues.partData[index].paper ? packageValues.partData[index].paper.name : '',
                  thickness: item.paper.length > 0 && item.paper[0].paperThickness[0].thickness,
                  selectedThickness: packageValues.partData[index].paper ? packageValues.partData[index].paper.selectedThickness : item.paper.length > 0 && item.paper[0].paperThickness[0].thickness,
                  coatingFrontIds: [],
                  coatingBackIds: [],
                },
                coating: {
                  side: packageValues.partData[index].coating ? packageValues.partData[index].coating.coatingType === 'ไม่เคลือบ' ? 0 : packageValues.partData[index].coating.coatingType === 'เคลือบด้านหลัง' ? -1 : packageValues.partData[index].coating.coatingType === 'เคลือบด้านหน้า' ? 1 : packageValues.partData[index].coating.coatingType === 'เคลือบสองหน้า' ? 2 : 0 : 0,
                  coatingType: 'ไม่เคลือบ',
                  coatingFrontId: packageValues.partData[index].coating ? packageValues.partData[index].coating.coatingFrontId : null,
                  coatingFrontName: '',
                  coatingBackId: packageValues.partData[index].coating ? packageValues.partData[index].coating.coatingBackId : null,
                  coatingBackName: '',
                },
                spotUV: {
                  side: packageValues.partData[index].spotUV ? packageValues.partData[index].spotUV.spotUVType === 'ไม่เคลือบ' ? 0 : packageValues.partData[index].spotUV.spotUVType === 'เคลือบด้านหลัง' ? -1 : packageValues.partData[index].spotUV.spotUVType === 'เคลือบด้านหน้า' ? 1 : packageValues.partData[index].spotUV.spotUVType === 'เคลือบสองหน้า' ? 2 : 0 : 0,
                  spotUVType: 'ไม่เคลือบ',
                  spotUVFrontId: packageValues.partData[index].spotUV ? packageValues.partData[index].spotUV.spotUVFrontId : null,
                  spotUVFrontName: '',
                  spotUVBackId: packageValues.partData[index].spotUV ? packageValues.partData[index].spotUV.spotUVBackId : null,
                  spotUVBackName: '',
                },
                selectedEmbossing: packageValues.partData[index].embossing.length > 0 ? 1 : 0,
                embossing: packageValues.partData[index].embossing,
                foilingId: packageValues.partData[index].foilingId || 0,
                foilingName: '',
                foiling: packageValues.partData[index].foiling,
                selectedPlasticWindow: packageValues.partData[index].plasticWindow.length > 0 ? 1 : 0,
                plasticWindow: packageValues.partData[index].plasticWindow,
                gluing: item.gluing,
                additionalService: item.additionalService,
                dieCut: item.isDieCut,
                gluingId: null,
                additionalServiceId: [],
              }
            ))
            setFormValues({
              ...formValues,
              partData: formPartData,
              incrementalSteps: packagingData.incrementalSteps,
              minimumManufacture: packagingData.minimumManufacture,
              amountProduction: packageValues.produceQuantity || packagingData.minimumManufacture,
            })
          } else {
            message.error(data.packaging.message)
            setTimeout(() => {
              window.location = PATH.packaging
            }, 1000)
          }
        }}
      >
        {
          ({ loading, data }) => {
            if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
            if (!data.packaging.success) {
              return (
                <Div>
                  Something WentWrong
                </Div>
              )
            }
            const packagingData = data.packaging.package
            return (
              <>
                {
                  isMobileXL && <SummaryToggleComponent
                    setSummaryActive={setSummaryActive}
                    summaryActive={summaryActive}
                  >
                    <SummaryDivMobile step={step}>
                      <Summary
                        formValues={formValues}
                        activeTab={activeTab}
                        onChangeTab={onChangeTab}
                        sticky={false}
                        step={step}
                        setStep={setStep}
                        isFixedPosition
                        isFullWidth={isMobileXL}
                        setSummaryActive={setSummaryActive}
                        isShowGluing
                      />
                    </SummaryDivMobile>
                  </SummaryToggleComponent>
                }
                <FullWidthContainer>
                  {/* Title Detail Section */}
                  <div style={{ backgroundColor: '#FFFFFF', paddingTop: '2px' }}>
                    <Detail data={packagingData} isOrder={true} />
                  </div>
                  {/* Preview Component */}
                  <Content id="content-package" ref={ContentRef} step={step} data={packagingData.packageParts && packagingData.packageParts.length}>
                    <WidthPartContainer>
                      <Div display={step === 1 && 'flex'}>
                        {
                          step === 1 && formValues.partData.length > 0 && <ContentPart>
                            <Parts
                              data={packagingData}
                              formValues={formValues}
                              setFormValues={setFormValues}
                              activeTab={activeTab}
                              onChangeTab={onChangeTab}
                              disabled={disabled}
                              setDisabled={setDisabled}
                              isMobileXL={isMobileXL}
                              ContentRef={ContentRef}
                              setSummaryActive={setSummaryActive}
                              isShowGluing={true}
                            />
                          </ContentPart>
                        }
                        {
                          step === 2 && <Div>
                            <Bar>
                              <Div>
                                <ButtonBack
                                  inverse
                                  text='<< ย้อนกลับ'
                                  onClick={async () => {
                                    const element = document.getElementById('content-package')
                                    if (element) {
                                      await setStep(1)
                                      await element.scrollIntoView({
                                        behavior: 'smooth',
                                      })
                                    }
                                  }}
                                />
                              </Div>
                              <Title>ข้อมูลโปรโมชัน และราคา</Title>
                              <Div>
                                {
                                  !isMobileXL && <ButtonBack
                                    inverse
                                    text='<< ย้อนกลับ'
                                    style={{ opacity: 0, cursor: 'unset' }}
                                  />
                                }
                              </Div>
                            </Bar>
                            <Div display='flex' alignItems='center' justifyContent='flex-start'>
                              <Div flexBasis={isMobileXL ? '100%' : isLaptopL ? '33%' : isLaptop ? '44%' : '60%'}>
                                <SummaryPartContent
                                  setFormValues={setFormValues}
                                  formValues={formValues}
                                  setStep={setStep}
                                  isShowMore={true}
                                  isQuotationEdit={true}
                                  packageValues={packageValues}
                                />
                              </Div>
                              {
                                !isMobileXL && <Div flexBasis={ isLaptopL ? '67%' : isLaptop ? '56%' : '40%' }>
                                  <Summary
                                    formValues={formValues}
                                    activeTab={activeTab}
                                    onChangeTab={onChangeTab}
                                    sticky
                                    step={step}
                                    setStep={setStep}
                                    ContentRef={ContentRef}
                                    setSummaryActive={setSummaryActive}
                                    isShowGluing
                                  />
                                </Div>
                              }
                            </Div>
                          </Div>
                        }
                      </Div>
                    </WidthPartContainer>
                  </Content>
                </FullWidthContainer>
              </>
            )
          }
        }
      </Query>
      <ActionZone step={step}>
        <ButtonForm
          text={ step === 1 ? 'ยกเลิก' : 'ย้อนกลับ' }
          inverse
          buttonStyle='round'
          onClick={async () => {
            if (step === 1) {
              setTimeout(() => {
                window.location = `${PATH.quotationDetail}/${packageValues.quotationId}`
              }, 200)
            } else {
              const element = document.getElementById('content-package')
              if (element) {
                await setStep(1)
                await element.scrollIntoView({
                  behavior: 'smooth',
                })
              }
            }
          }}
          icon={'far fa-chevron-double-left'}
          color={theme.color.secondaryColor}
        />
      {
        step === 1 && <Query
          query={PRICE_CALCULATOR}
        >
          {
            ({ refetch }) => (
              <ButtonForm
                inverse
                buttonStyle='round'
                loading={callCalculation}
                disabled={callCalculation}
                color={theme.color.secondaryColor}
                text={<React.Fragment><IconCalculate src={CalculationIcon} />คำนวณราคา</React.Fragment>}
                onClick={async () => {
                  setCallCalculation(true)
                  const findNullSizePart = formValues.partData.map(item => Object.values(item.sizeList).filter(v => v === null || v === '' || v === undefined || v === 0))
                  const findNullSize = Array.prototype.concat.apply([], findNullSizePart).filter(v => v === null || v === '' || v === undefined)
                  const findEmptyEmbossing = formValues.partData.filter(item => (item.selectedEmbossing === 1 && item.embossing.length === 0) || (item.embossing.length > 0 && item.embossing.filter(x => x.width === null || x.lengths === null).length > 0))
                  const findEmptyFoiling = formValues.partData.filter(item => (item.foilingId > 0 && item.foiling.length === 0) || (item.foiling.length > 0 && item.foiling.filter(x => x.width === null || x.lengths === null).length > 0))
                  const findEmptyPlastic = formValues.partData.filter(item => (item.selectedPlasticWindow === 1 && item.plasticWindow.length === 0) || (item.plasticWindow.length > 0 && item.plasticWindow.filter(x => x.width === null || x.lengths === null).length > 0))
                  if (findNullSize.length > 0) {
                    message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                    setCallCalculation(false)
                    return
                  }
                  if (findNullSize.length > 0 || findEmptyEmbossing.length > 0 || findEmptyFoiling.length > 0 || findEmptyPlastic.length > 0) {
                    message.warning(`กรุณากรอกข้อมูล ${findNullSize.length > 0 ? 'ขนาดบรรจุภัณฑ์' : ''} ${findEmptyEmbossing.length > 0 ? 'ตำแหน่งการปั๊มนูน' : ''} ${findEmptyFoiling.length > 0 ? 'ตำแหน่งปั๊มฟอยล์-ทองเค' : ''} ${findEmptyPlastic.length > 0 ? 'ตำแหน่งการเจาะหน้าต่างและติดแผ่นใส' : ''} ให้ครบถ้วน`)
                    setCallCalculation(false)
                    return
                  }
                  const part = formValues.partData.map((item, index) => ({
                    order: index + 1,
                    packagePartId: item.packagePartId,
                    unit: item.measurementUnit.key,
                    operator: Object.values(item.sizeList).map(x => (parseFloat(x))),
                    printSizeWidth: 0.0,
                    printSizeLength: 0.0,
                    paper: {
                      paperId: item.paper.paperId,
                      thickness: parseFloat(item.paper.selectedThickness),
                    },
                    printing: item.printing.side && {
                      side: item.printing.printingType,
                      printingBasicColorId: item.printing.printingBasicColorId,
                      printingExtraColorId: item.printing.printingExtraColorId.filter(x => x.printingExtraColorId).map(x => x.printingExtraColorId),
                    },
                    coating: {
                      coatingIdFront: item.coating.side === 1 || item.coating.side === 2 ? item.coating.coatingFrontId : null,
                      coatingIdBehind: item.coating.side === -1 || item.coating.side === 2 ? item.coating.coatingBackId : null,
                    },
                    spotUV: {
                      spotUVIdFront: item.spotUV.spotUVFrontId,
                      spotUVIdBehind: item.spotUV.spotUVBackId,
                    },
                    embossing: item.embossing.map((x, i) => ({
                      order: i + 1,
                      width: parseFloat(x.width),
                      length: parseFloat(x.lengths),
                      unit: item.measurementUnit.key,
                    })),
                    foiling: {
                      foilingId: item.foilingId !== 0 ? item.foilingId : null,
                      foilingPart: item.foiling.map((x, i) => ({
                        order: i + 1,
                        width: parseFloat(x.width),
                        length: parseFloat(x.lengths),
                        unit: item.measurementUnit.key,
                      })),
                    },
                    dieCut: item.dieCut,
                    plasticWindow: item.plasticWindow.map((x, i) => ({
                      order: i + 1,
                      width: parseFloat(x.width),
                      length: parseFloat(x.lengths),
                      thickness: x.thickness,
                      unit: item.measurementUnit.key,
                    })),
                    gluingId: item.gluing ? item.gluing.gluingId : null,
                    additionalServiceId: item.additionalService.map(x => x.additionalServiceId) || [],
                  }))
                  const input = {
                    part,
                  }
                  await refetch({
                    input,
                  }).then(async (res) => {
                    if (res.data.priceCalculator.success) {
                      message.success('คำนวนราคาสำเร็จ')
                      setFormValues({
                        ...formValues,
                        partData: formValues.partData,
                        partDetail: res.data.priceCalculator.partDetail,
                        totalCost: res.data.priceCalculator.totalCost,
                        costDetail: res.data.priceCalculator.costDetail,
                      })
                      const element = document.getElementById('content-package')
                      if (element) {
                        setTimeout(async () => {
                          setCallCalculation(false)
                          await setStep(2)
                          await element.scrollIntoView({
                            behavior: 'smooth',
                          })
                        }, 500)
                      }
                    } else if (res.data.priceCalculator.message === 'oversize') {
                      setCallCalculation(false)
                      message.warning('ไม่สามารถให้บริการผลิตขนาดดังกล่าวได้ เนื่องจากมีขนาดเกินเครื่องพิมพ์ ของทางเรา ขออภัยในความไม่สะดวก')
                    } else if (res.data.priceCalculator.message === 'ขออภัย ขนาดที่ระบุ ไม่สามารถผลิตบนกระดาษชนิดนี้ได้') {
                      setCallCalculation(false)
                      message.warning('ขออภัย ขนาดที่ระบุ ไม่สามารถผลิตบนกระดาษชนิดนี้ได้')
                    } else if (res.data.priceCalculator.message === 'ไม่สามารถคำนวณ Width ได้' || res.data.priceCalculator.message === 'ไม่สามารถคำนวณ Length ได้') {
                      setCallCalculation(false)
                      message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                    } else {
                      setCallCalculation(false)
                      message.success('สามารถให้บริการผลิตขนาดดังกล่าวได้')
                    }
                  }).catch((e) => {
                    if (e.toString().includes('status code 400')) {
                      setCallCalculation(false)
                      message.warning('กรุณากรอกข้อมูลให้ครบถ้วน')
                    } else {
                      setCallCalculation(false)
                      message.error('ไม่สามารถคำนวนราคาได้')
                      setStep(1)
                    }
                  })
                }}
              />
            )
          }
        </Query>
      }
      {
        step === 2 && <QuotationCreateDiv>
        <Mutation
            mutation={UPDATE_BOX_DETAIL_QUOTATION}
          >
            {
                editBoxDetail => (
                  <Popconfirm
                    title='ต้องการบันทึกข้อมูลนี้ใช้หรือไม่'
                    onConfirm={() => {
                      // EDIT QUOTATION
                      const input = {
                        name: packageValues.name,
                      }
                      input.isPreDefine = true
                      input.partDetail = formValues.partDetail.map(item => _.omit(item, ['__typename'])).map((item, index) => ({
                        ...item,
                        packagePartId: formValues.partData[index].packagePartId,
                        unitSize: formValues.partData[index].measurementUnit.key,
                        sizeA: formValues.partData[index].sizeList.a,
                        sizeB: formValues.partData[index].sizeList.b,
                        sizeC: formValues.partData[index].sizeList.c,
                        sizeD: formValues.partData[index].sizeList.d,
                        sizeE: formValues.partData[index].sizeList.e,
                        sizeF: formValues.partData[index].sizeList.f,
                        sizeG: formValues.partData[index].sizeList.g,
                        sizeH: formValues.partData[index].sizeList.h,
                        sizeI: formValues.partData[index].sizeList.i,
                        sizeJ: formValues.partData[index].sizeList.j,
                        detailActualPrinter: {
                          ..._.omit(item.detailActualPrinter, ['__typename', 'maxYield']),
                          paperId: formValues.partData[index].paper && formValues.partData[index].paper.paperId ? formValues.partData[index].paper.paperId : null,
                        },
                        detailPrintingCost: item.detailPrintingCost && {
                          ..._.omit(item.detailPrintingCost, ['__typename']),
                          printingBasicColorId: formValues.partData[index].printing && formValues.partData[index].printing.printingBasicColorId ? formValues.partData[index].printing.printingBasicColorId : null,
                          printingExtraColor: formValues.partData[index].printing && formValues.partData[index].printing.printingExtraColorId.length > 0 ? formValues.partData[index].printing.printingExtraColorId.map(x => ({ id: x.printingExtraColorId, name: x.printingExtraColorName })) : [],
                        },
                        detailCoatingCost: {
                          ..._.omit(item.detailCoatingCost, ['__typename']),
                          coatingFrontId: formValues.partData[index].coating && formValues.partData[index].coating.coatingFrontId ? formValues.partData[index].coating.coatingFrontId : null,
                          coatingBehindId: formValues.partData[index].coating && formValues.partData[index].coating.coatingBackId ? formValues.partData[index].coating.coatingBackId : null,
                        },
                        detailSpotUVCost: {
                          ..._.omit(item.detailSpotUVCost, ['__typename']),
                          spotUVFrontId: formValues.partData[index].spotUV && formValues.partData[index].spotUV.spotUVFrontId ? formValues.partData[index].spotUV.spotUVFrontId : null,
                          spotUVBehindId: formValues.partData[index].spotUV && formValues.partData[index].spotUV.spotUVBackId ? formValues.partData[index].spotUV.spotUVBackId : null,
                        },
                        detailFoilingCost: item.detailFoilingCost ? {
                          foiling: item.detailFoilingCost.foiling,
                          foilingPart: item.detailFoilingCost.foilingPart.map(v => _.omit(v, ['__typename'])),
                          foilingId: formValues.partData[index].foilingId ? formValues.partData[index].foilingId : null,
                        } : null,
                        detailGluingCost: item.detailGluingCost && {
                          gluingId: formValues.partData[index].gluing ? formValues.partData[index].gluing.gluingId : null,
                          gluing: formValues.partData[index].gluing ? formValues.partData[index].gluing.name : null,
                        },
                        detailPrinter: _.omit(item.detailPrinter, ['__typename']),
                        detailSize: _.omit(item.detailSize, ['__typename']),
                        detailEmbossingCost: item.detailEmbossingCost.map(v => _.omit(v, ['__typename'])),
                        detailPlasticWindowCost: item.detailPlasticWindowCost.map(v => _.omit(v, ['__typename'])),
                        detailAdditionalServiceCost: formValues.partData[index].additionalService.length > 0 ? formValues.partData[index].additionalService.map(x => ({ additionalServiceId: x.additionalServiceId, name: x.name ? x.name : x.additionalService })) : [],
                      }))
                      input.totalCost = formValues.totalCost.map(item => _.omit(item, ['__typename', 'marginRate', 'reserveQuantity', 'solution', 'textSolution', 'repeatSolution', 'rawCost', 'rawRepeatCost']))
                      const quantity = [1, 500, 1000, 1500, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000]
                      const indexMinimum = quantity.reverse().find(e => e <= formValues.amountProduction)
                      const index = quantity.reverse().indexOf(indexMinimum)
                      const priceData = [...formValues.totalCost.slice(index + 1, formValues.totalCost.length)].filter(x => x).find(x => x.quantity === formValues.amountProduction)
                      // const priceData = formValues.totalCost.find(x => x.quantity === quantity[index])
                      input.price = formValues.productionType === 'newOrder' ? Math.round(priceData.totalCost * 100) / 100 : Math.round(priceData.totalRepeatCost * 100) / 100
                      input.produceType = formValues.productionType === 'newOrder' ? 'สั่งผลิตใหม่' : 'สั่งผลิตซ้ำ'
                      input.sampleFiles = formValues.graphicFile
                      input.produceQuantity = formValues.amountProduction || 0
                      input.isGraphic = formValues.graphicName || 'ไม่ใช้บริการ'
                      input.printingDescription = formValues.moreDetail
                      editBoxDetail({
                        variables: {
                          input,
                          id: packageValues.quotationId,
                        },
                      }).then((res) => {
                        if (res.data) {
                          if (res.data.editBoxDetail.success) {
                            message.success(res.data.editBoxDetail.message)
                            setTimeout(() => {
                              window.location = `${PATH.quotationDetail}/${packageValues.quotationId}`
                            }, 1000)
                          } else {
                            message.error(res.data.editBoxDetail.message)
                          }
                        }
                        setDisabledButton(false)
                      })
                    }}
                    okText='ใช่'
                    cancelText='ไม่ใช่'
                  >
                    <ButtonForm
                      inverse
                      buttonStyle='round'
                      color={theme.color.secondaryColor}
                      text='ยืนยัน'
                      loading={disabledButton}
                      disabled={disabledButton}
                    />
                  </Popconfirm>
                )
            }
        </Mutation>
        </QuotationCreateDiv>
      }
    </ActionZone>
    </Div>
  )
}

const SummaryContent = ({
  id,
  data,
  isPreDefine,
  activeTab,
  onChangeTab,
  packageValues,
}) => {
  const [disabledButton, setDisabledButton] = useState(false)
  const [address, setAddress] = useState(data.address)
  const [shippingAddress, setShippingAddress] = useState(data.shippingAddress)
  const [boxDetail, setBoxDetail] = useState({
    boxSuitDesignService: data.defaultBoxDetail.useBoxSuitDesign ? 'useDesign' : 'notUseDesign',
    name: data.defaultBoxDetail.name,
    boxSize: {
      width: data.defaultBoxDetail.width,
      height: data.defaultBoxDetail.height,
      length: data.defaultBoxDetail.length,
    },
    quantity: data.defaultBoxDetail.quantity,
    paperThickness: data.defaultBoxDetail.paperThickness || '-',
    paperTypeText: data.defaultBoxDetail.paperTypeText || '-',
    printingTechniqueText: data.defaultBoxDetail.printingTechniqueText,
    productSize: {
      width: data.defaultBoxDetail.designInfo ? data.defaultBoxDetail.designInfo.width : null,
      height: data.defaultBoxDetail.designInfo ? data.defaultBoxDetail.designInfo.height : null,
      length: data.defaultBoxDetail.designInfo ? data.defaultBoxDetail.designInfo.length : null,
    },
    productQuantityPerPiece: data.defaultBoxDetail.designInfo ? data.defaultBoxDetail.designInfo.quantity : null,
    designDescription: data.defaultBoxDetail.designInfo ? data.defaultBoxDetail.designInfo.description : null,
    printingDescription: data.defaultBoxDetail.printingDescription,
    sampleFiles: data.defaultBoxDetail.sampleFiles.map((sampleFile) => {
      const splitUrl = sampleFile.sampleFileUri.split('/')
      return {
        name: splitUrl[splitUrl.length - 1],
        sampleFileUri: sampleFile.sampleFileUri,
      }
    }),
    designFiles: data.defaultBoxDetail.designFiles.map((designFile) => {
      const splitUrl = designFile.designFileUri.split('/')
      return {
        name: splitUrl[splitUrl.length - 1],
        designFileUri: designFile.designFileUri,
      }
    }),
    graphicType: data.defaultBoxDetail.isGraphic,
  })
  const [isEditable, setIsEditable] = useState(false)
  const [isEditableBoxDetail, setIsEditableBoxDetail] = useState(false)

  const [isCalculated, setIsCalculated] = useState(false)

  const [priceValues, setPriceValues] = useState({
    partData: [],
    partDetail: [],
    totalCost: [],
    minimumManufacture: 500,
    incrementalSteps: 100,
    amountProduction: 500,
  })

  const [isTablet, setIsTablet] = useState(window.innerWidth <= 768)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth <= 1280)

  const screenSizeHandler2 = () => setIsTablet(window.innerWidth <= 768)
  const screenSizeHandler3 = () => setIsDesktop(window.innerWidth <= 1280)

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler2)
    window.addEventListener('resize', screenSizeHandler3)
    return () => {
      window.removeEventListener('resize', screenSizeHandler2)
      window.removeEventListener('resize', screenSizeHandler3)
    }
  }, [])

  const renderDataListPredefine = (values = []) => {
    // const tmpData = data.map(value => data.name)
    const listKey = ['produceType', 'produceQuantity', 'price', 'isGraphic', 'detail', 'sampleFiles']
    const resultList = []
    listKey.forEach((element) => {
      const tmp = values.findIndex(value => value.name === element)
      if (tmp > -1) {
        const result = JSON.parse(JSON.stringify(values[tmp]))
        let valueNew = <LabelSummary>{result.value ? result.value : '-'}</LabelSummary>
        if (result.title === 'จำนวนสั่งผลิต' || result.title === 'ราคาบรรจุภัณฑ์') {
          const tmpValue = result.value.split(' ')
          valueNew = <span><LabelSummary>{tmpValue[0]}</LabelSummary> {tmpValue[1]}</span>
        }
        if (result.title === 'ไฟล์แนบ') {
          resultList.push({
            ...result,
          })
        } else {
          resultList.push({
            ...result,
            value: valueNew,
          })
        }
      }
    })
    return resultList
  }

  const renderDataListFreeForm = (values = []) => {
    // const tmpData = data.map(value => data.name)
    const listKey = ['name', 'boxSize', 'quantity', 'productSize', 'paperTypeText', 'printingTechniqueText', 'isGraphic', 'detail', 'sampleFiles']
    const resultList = []
    listKey.forEach((element) => {
      const tmp = values.findIndex(value => value.name === element)
      if (tmp > -1) {
        const result = JSON.parse(JSON.stringify(values[tmp]))
        resultList.push({
          ...result,
        })
      }
    })
    return resultList
  }

  const ContentRef = useRef(null)
  return (
    <Div>
      {!isPreDefine && isEditableBoxDetail
        ? <>
          <Step1
            data={boxDetail}
            onInputChange={(value, name) => {
              const newData = Object.assign({}, boxDetail)
              if (name === 'boxSize') {
                newData.boxSize = {
                  width: parseFloat(value.w),
                  height: parseFloat(value.h),
                  length: parseFloat(value.l),
                }
              } else if (name === 'productSize') {
                newData.productSize = {
                  width: parseFloat(value.w),
                  height: parseFloat(value.h),
                  length: parseFloat(value.l),
                }
              } else if (name === 'boxSuitDesignService') {
                newData[name] = value
                if (value === 'notUseDesign') {
                  newData.productSize = {}
                  newData.productQuantityPerPiece = 0
                  newData.designDescription = ''
                } else {
                  newData.designFiles = []
                }
              } else {
                newData[name] = value
              }
              setBoxDetail(newData)
            }}
            onDesignChoiceChange={(value, name) => {
              const newData = Object.assign({}, boxDetail)
              newData[name] = value
              newData.designFiles = []
              setBoxDetail(newData)
            }}
            onUploadFileList={(fileList, name) => {
              const newData = Object.assign({}, boxDetail)
              const newFileList = [...boxDetail[name]]
              if (fileList.length >= 1) {
                Object.keys(fileList).forEach((v) => {
                  if (v !== 'length') {
                    const tmp = fileList[v]
                    tmp.upload = true
                    newFileList.push(tmp)
                  }
                })
              }
              newData[name] = newFileList
              setBoxDetail(newData)
            }}
            onRemoveFileList={(fileName, name) => {
              const newData = Object.assign({}, boxDetail)
              const newFileList = [...boxDetail[name]]
              _.remove(newFileList, v => v.name === fileName)
              newData[name] = newFileList
              setBoxDetail(newData)
            }}
            setDisabledButton={value => setDisabledButton(value)}
          />
          <Div textAlign='right' padding='16px 16px 0'>
            <Button
              style={{ marginRight: '8px' }}
              color={theme.color.grey}
              ghost
              small
              text='ยกเลิก'
              loading={disabledButton}
              disabled={disabledButton}
              onClick={() => setIsEditableBoxDetail(false)}
            />
            <Mutation
              mutation={UPDATE_BOX_DETAIL_QUOTATION}
            >
              {
                  editBoxDetail => (
                    <Popconfirm
                      title='ต้องการบันทึกข้อมูลนี้ใช้หรือไม่'
                      onConfirm={() => {
                        // EDIT QUOTATION
                        const input = {
                          name: boxDetail.name,
                          width: parseFloat(boxDetail.boxSize.width),
                          length: parseFloat(boxDetail.boxSize.length),
                          height: parseFloat(boxDetail.boxSize.height),
                          quantity: parseInt(boxDetail.quantity, 10),
                          paperTypeText: boxDetail.paperTypeText,
                          paperThickness: boxDetail.paperThickness,
                          printingTechniqueText: boxDetail.printingTechniqueText,
                          printingDescription: boxDetail.printingDescription,
                          // useBoxSuitDesign: boxDetail.boxSuitDesignService === 'useDesign',
                          useBoxSuitDesign: false,
                          useCompleteDesign: boxDetail.designServiceChoice === 'ต้องการใช้บริการทำแบบ',
                          sampleFiles: boxDetail.sampleFiles,
                          designFiles: boxDetail.designFiles,
                          productWidth: parseInt(boxDetail.productSize.width, 10),
                          productLength: parseInt(boxDetail.productSize.length, 10),
                          productHeight: parseInt(boxDetail.productSize.height, 10),
                          productQuantityPerPiece: parseInt(boxDetail.productSize.productQuantityPerPiece, 10),
                          isGraphic: boxDetail.graphicType && boxDetail.graphicType.name,
                        }
                        editBoxDetail({
                          variables: {
                            input,
                            id,
                          },
                        }).then((res) => {
                          if (res.data) {
                            if (res.data.editBoxDetail.success) {
                              message.success(res.data.editBoxDetail.message)
                              setTimeout(() => {
                                window.location = `${PATH.quotationDetail}/${id}`
                              }, 3000)
                            } else {
                              message.error(res.data.editBoxDetail.message)
                            }
                          }
                          setIsEditableBoxDetail(false)
                          setDisabledButton(false)
                        })
                      }}
                      okText='ใช่'
                      cancelText='ไม่ใช่'
                    >
                      <Button
                        color={theme.color.primaryColor}
                        ghost
                        small
                        text='บันทึก'
                        loading={disabledButton}
                        disabled={disabledButton}
                      />
                    </Popconfirm>
                  )
              }
            </Mutation>
          </Div>
        </>
        : (isPreDefine && isEditableBoxDetail) ? <>
          <EditPredefinedForm
            packageValues={packageValues}
            setIsEditableBoxDetail={setIsEditableBoxDetail}
          />
        </> : isPreDefine ? <Div>
          <Div display='flex' padding='16px 0px 0px'>
            <Div width="600px">
              <H4 display='block' style={{ width: 'max-content' }} bold color={theme.color.tertiaryColor}>รายละเอียดบรรจุภัณฑ์ และการสั่งผลิต</H4>
            </Div>
            <Div width="100%" textAlign='right' padding='0px 8px 0'>
              <Button
                color={theme.color.primaryColor}
                ghost
                small
                text='แก้ไขข้อมูล'
                loading={disabledButton}
                disabled={disabledButton}
                onClick={() => { setIsEditableBoxDetail(true) } }
                // onClick={() => {
                //   setTimeout(() => {
                //     window.location = `${PATH.quotationPreDefinedEditItem}/${id}`
                //   }, 200)
                // }}
              />
            </Div>
          </Div>
          {
            isCalculated ? <div ref={ContentRef}>
              <WidthPriceContainer>
                <Div display='flex' justifyContent='space-between' margin='16px 0px'>
                  <Div flexBasis='70%' margin='0px 16px 0px 0px'>
                    <TablePrice
                      totalCost={priceValues.totalCost || []}
                      partDetail={priceValues.partDetail || []}
                      formValues={priceValues}
                      setFormValues={setPriceValues}
                    />
                    <br/>
                    <ButtonForm
                      color={theme.color.secondaryColor}
                      small
                      text='ย้อนกลับ'
                      onClick={() => setIsCalculated(false)}
                      inverse
                    />
                  </Div>
                  <Div flexBasis='30%' margin='0px 16px'>
                    <Summary
                      formValues={packageValues}
                      activeTab={activeTab}
                      onChangeTab={onChangeTab}
                      sticky={true}
                      isPreDefine={false}
                      ContentRef={ContentRef}
                      onClickEdit={() => {}}
                      onClickReCal={() => {}}
                      setIsCalculated={setIsCalculated}
                      setPriceValues={setPriceValues}
                      priceValues={priceValues}
                      setIsEditableBoxDetail={setIsEditableBoxDetail}
                      orderId={id}
                    />
                  </Div>
                </Div>
              </WidthPriceContainer>
            </div> : <Div display='flex' justifyContent='space-between' margin='16px 0px' moreStyle={{ flexWrap: isTablet ? 'wrap' : 'nowrap' }}>
              <Div flexBasis={isTablet ? '100%' : isDesktop ? '45%' : '30%'} margin={isTablet ? '0px 0px 16px 0px' : '0px 16px 0px 0px'}>
                <Summary
                  formValues={packageValues}
                  activeTab={activeTab}
                  onChangeTab={onChangeTab}
                  sticky={false}
                  isPreDefine={isPreDefine}
                  onClickEdit={() => {}}
                  onClickReCal={() => {}}
                  setIsCalculated={setIsCalculated}
                  setPriceValues={setPriceValues}
                  priceValues={priceValues}
                  setIsEditableBoxDetail={setIsEditableBoxDetail}
                  isFixedPosition
                  orderId={id}
                  isFullWidth={isTablet}
                />
              </Div>
              <Div flexBasis={isTablet ? '100%' : isDesktop ? '55%' : '70%'} margin='0 0px' >
                <SummaryBox
                  title='รายละเอียดการสั่งผลิต'
                  dataList={renderDataListPredefine(data.boxDetail.filter(item => item.title || ((item.name === 'sampleFiles' || item.name === 'designFiles') && item.value && item.value.length > 0)))}
                  margin={'0px'}
                  isPredefined
                />
              </Div>
            </Div>
          }
          </Div> : <><SummaryBox
          title='รายละเอียดกล่องบรรจุภัณฑ์'
          dataList={renderDataListFreeForm(data.boxDetail)}
        />
        <Div textAlign='right' padding='0px 16px 0'>
          <Button
            color={theme.color.primaryColor}
            ghost
            small
            text='แก้ไขข้อมูล'
            loading={disabledButton}
            disabled={disabledButton}
            onClick={() => {
              // isPredefined go to predefined page
              setIsEditableBoxDetail(true)
            }}
          />
        </Div></>
      }
      <Div display='flex'>
        <Div flexBasis={'100%'}>
          <EditableAddress
            title='รายละเอียดใบกำกับภาษี'
            dataList={data.address.reduce((result, item) => {
              result.push(item)
              if (item.name === 'taxId') {
                result.push({ title: undefined, value: data.defaultBoxDetail.withHoldingTax, name: 'withHoldingTax' })
                result.push({ title: undefined, value: data.defaultBoxDetail.withHoldingTaxRate, name: 'withHoldingTaxRate' })
              }
              return result
            }, [])}
            id={id}
            updateData={newData => setAddress(newData)}
            editable={isEditable}
          />
        </Div>
      {/* isPreDefine */}
      </Div>
      <Div display='flex'>
        <Div flexBasis={'100%'}>
          <EditableAddress
            title='รายละเอียดการจัดส่ง'
            dataList={data.shippingAddress}
            updateData={newData => setShippingAddress(newData)}
            editable={isEditable}
          />
        </Div>
      {/* isPreDefine */}
      </Div>
      <Div textAlign='right' padding='24px 16px 0'>
      {
        isEditable ? <>
            <Button
              style={{ marginRight: '8px' }}
              color={theme.color.grey}
              ghost
              small
              text='ยกเลิก'
              loading={disabledButton}
              disabled={disabledButton}
              onClick={() => setIsEditable(false)}
            />
            <Mutation
              mutation={EDIT_TAX_INVOICE}
            >
            {
              doEditTaxInvoice => (
                  <Popconfirm
                    title='ยืนยันการแก้ไขข้อมูลหรือไม่'
                    onConfirm={() => {
                      setDisabledButton(true)
                      const input = _.reduce(address, (result, value) => {
                        const newResult = result
                        if (value !== null && (!_.isEmpty(value.value) || (_.isNumber(value.value) && value.value > 0))) {
                          newResult[value.name === 'name' ? 'userName' : value.name === 'surname' ? 'userSurname' : value.name] = value.value
                        }
                        return newResult
                      }, {})

                      _.reduce(shippingAddress, (result, value) => {
                        const newResult = result
                        const name = value.name !== 'shippingAddressNumber'
                          ? value.name === 'name'
                            ? 'shippingUserName'
                            : value.name === 'surname'
                              ? 'shippingUserSurName'
                              : value.name === 'detail'
                                ? 'shippingDescription'
                                : `shipping${value.name.charAt(0).toUpperCase() + value.name.slice(1)}`
                          : value.name
                        if (value !== null) {
                          newResult[name] = value.value
                        }
                        return newResult
                      }, Object.assign(input, {}))

                      // delete input.withHoldingTaxRate
                      if (input.companyName) {
                        if (/^\s*$/.test(input.companyName)) {
                          delete input.companyName
                        }
                      }
                      input.withHoldingTax = address.find(item => item.name === 'withHoldingTax') && address.find(item => item.name === 'withHoldingTax').value
                      doEditTaxInvoice({
                        variables: {
                          input,
                          id,
                        },
                      }).then((res) => {
                        if (res.data) {
                          if (res.data.editTaxInvoice.success) {
                            message.success(res.data.editTaxInvoice.message)
                          } else {
                            message.error(res.data.editTaxInvoice.message)
                          }
                        }
                        setDisabledButton(false)
                        setIsEditable(false)
                      })
                    }}
                    // onCancel={() => { setIsEditable(false) }}
                    okText='ยืนยัน'
                    cancelText='ยกเลิก'
                  >
                    <Button
                      color={theme.color.primaryColor}
                      ghost
                      small
                      text='บันทึก'
                      loading={disabledButton}
                      disabled={disabledButton}
                    />
                  </Popconfirm>
              )
            }
            </Mutation>
          </>
          : <Button
            onClick={() => { setIsEditable(true) }}
            color={theme.color.primaryColor}
            ghost
            small
            text='แก้ไขข้อมูลลูกค้า'
            loading={disabledButton}
            disabled={disabledButton}
          />
      }
      </Div>
    </Div>
  )
}

const QuotationDetail = ({ match }) => {
  const [activeTabSummary, setActiveTabSummary] = useState(0)

  const onChangeTab = (key) => {
    setActiveTabSummary(key)
  }

  const [products, setProducts] = useState([{
    id: 0,
    type: quotationType.ITEM,
    title: '',
    price: '',
    amount: '',
  }])
  const [productsItems, setProductsItems] = useState([])
  const [taxRate, setTaxRate] = useState(undefined)
  const [withHoldingTax, setwithHoldingTax] = useState(false)
  return (
    <Div>
      <Query
        query={GET_QUOTATION}
        notifyOnNetworkStatusChange
        fetchPolicy='network-only'
        variables={{ id: parseInt(match.params.id, 10) }}
        onCompleted={(data) => {
          if (data.requestById) {
            const quantity = [1, 500, 1000, 1500, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000]
            const indexMinimum = quantity.reverse().find(e => e <= (data.requestById.produceQuantity))
            const index = quantity.reverse().indexOf(indexMinimum)
            const priceData = data.requestById.totalCost.find(x => x.quantity === quantity[index])
            setProductsItems([{
              id: -1,
              type: quotationType.ITEM,
              title: data.requestById.name,
              price: data.requestById.price ? data.requestById.price : priceData ? data.requestById.productionType === 'สั่งผลิตใหม่' ? Math.round(priceData.totalCost * 100) / 100 : Math.round(priceData.totalRepeatCost * 100) / 100 : 0,
              amount: parseInt(data.requestById.produceQuantity),
            }])
          }
        }}
      >
      {
        ({ loading, data, error }) => {
          if (loading || !data) return <Loading fullWidth center />
          if (error) return <H4>Something went wrong</H4>
          if (!data.requestById) return <Div display='flex' alignItems='center' justifyContent='center' height='400px'><H4 color={theme.color.grey}>ไม่มี Quotation Request นี้</H4></Div>
          const userDataType = ['address', 'shippingAddress']
          const userData = {}
          userDataType.forEach((d) => {
            if (data.requestById[d]) {
              const omitData = _.omit(data.requestById[d], [
                '__typename',
                'addressId',
                'shippingAddressId',
                'shippingDescription',
              ])
              userData[d] = Object.keys(omitData).reduce((acc, key) => {
                const newResult = [...acc]
                const titleName = (key === 'addressNumber' || key === 'shippingAddressNumber')
                  ? 'เลขที่อยู่'
                  : key === 'postalCode' ? 'รหัสไปรษณีย์' : key === 'province' ? 'จังหวัด' : key === 'district' ? 'เขต / อำเภอ' : userDataInput[key]
                if (key === 'subDistrict') {
                  const index = _.findIndex(newResult, e => e.name === 'district')
                  newResult.splice(index, 0, {
                    title: 'แขวง / ตำบล',
                    value: _.isEmpty(omitData[key]) ? '' : omitData[key],
                    name: key,
                  })
                } else {
                  newResult.push({
                    title: titleName,
                    value: _.isEmpty(omitData[key]) ? '' : omitData[key],
                    name: key,
                  })
                }
                return newResult
              }, [])
            }
          })
          if (data.requestById.shippingAddress) {
            userData.shippingAddress.push({
              title: userDataInput.detail,
              value: data.requestById.shippingAddress.shippingDescription || '',
              name: 'detail',
            })
          }

          const omitData = _.omit(data.requestById, data.requestById.isPreDefine ? [
            'quotationId',
            'refId',
            'address',
            'shippingAddress',
            '__typename',
            'status',
            'length',
            'height',
            'paperThickness',
            'adminNote',
            'taxRate',
            'totalPrice',
            'createdAt',
            'quotationPDF',
            'partDetail',
            'totalCost',
            'incrementalSteps',
            'packageId',
            'minimumManufacture',
            'paperType',
            'paperThickness',
            'paperTypeText',
            'printingTechniqueText',
          ] : [
            'quotationId',
            'refId',
            'address',
            'shippingAddress',
            '__typename',
            'status',
            'length',
            'height',
            'width',
            'paperThickness',
            'adminNote',
            'taxRate',
            'totalPrice',
            'createdAt',
            'quotationPDF',
            'partDetail',
            'totalCost',
            'incrementalSteps',
            'packageId',
            'minimumManufacture',
            'useBoxSuitDesign',
            'useCompleteDesign',
          ])
          const packageValues = {
            quotationId: data.requestById.quotationId,
            packageId: data.requestById.packageId,
            produceQuantity: data.requestById.produceQuantity,
            produceType: data.requestById.produceType === 'สั่งผลิตใหม่' ? 'newOrder' : 'repeatOrder',
            incrementalSteps: data.requestById.incrementalSteps,
            minimumManufacture: data.requestById.minimumManufacture,
            graphicName: data.requestById.isGraphic,
            graphicFile: data.requestById.sampleFiles,
            moreDetail: data.requestById.printingDescription,
            designInfo: !data.requestById.isPreDefine ? {
              width: data.requestById.width,
              length: data.requestById.length,
              height: data.requestById.height,
              quantity: data.requestById.quantity,
            } : null,
            partData: data.requestById && data.requestById.partDetail.map(item => ({
              packagePartId: item.packagePartId || null,
              packagePartName: item.packagePartName || '-',
              packageData: {
                name: omitData.name || '-',
              },
              sizeList: {
                a: _.isNumber(item.sizeA) ? item.sizeA : -1,
                b: _.isNumber(item.sizeB) ? item.sizeB : -1,
                c: _.isNumber(item.sizeC) ? item.sizeC : -1,
                d: _.isNumber(item.sizeD) ? item.sizeD : -1,
                e: _.isNumber(item.sizeE) ? item.sizeE : -1,
                f: _.isNumber(item.sizeF) ? item.sizeF : -1,
                g: _.isNumber(item.sizeG) ? item.sizeG : -1,
                h: _.isNumber(item.sizeH) ? item.sizeH : -1,
                i: _.isNumber(item.sizeI) ? item.sizeI : -1,
                j: _.isNumber(item.sizeJ) ? item.sizeJ : -1,
              },
              measurementUnit: {
                label: item.unitSize === 'cm' ? 'ซม.' : 'นิ้ว',
              },
              paper: {
                paperId: item.paperId,
                name: item.paperType,
                selectedThickness: item.paperThickness,
              },
              coating: {
                side: (item.coatingFront && item.coatingBehind) ? 2 : (!item.coatingFront && item.coatingBehind) ? -1 : (item.coatingFront && !item.coatingBehind) ? 1 : 0,
                coatingType: (item.coatingFront && item.coatingBehind) ? 'เคลือบสองหน้า' : (!item.coatingFront && item.coatingBehind) ? 'เคลือบด้านหลัง' : (item.coatingFront && !item.coatingBehind) ? 'เคลือบด้านหน้า' : 'ไม่เคลือบ',
                coatingFrontName: item.coatingFront,
                coatingBackName: item.coatingBehind,
                coatingFrontId: item.coatingFrontId,
                coatingBackId: item.coatingBehindId,
              },
              spotUV: {
                side: (item.spotUVFront && item.spotUVBehind) ? 2 : (!item.spotUVFront && item.spotUVBehind) ? -1 : (item.spotUVFront && !item.spotUVBehind) ? 1 : 0,
                spotUVType: (item.spotUVFront && item.spotUVBehind) ? 'เคลือบสองหน้า' : (!item.spotUVFront && item.spotUVBehind) ? 'เคลือบด้านหลัง' : (item.spotUVFront && !item.spotUVBehind) ? 'เคลือบด้านหน้า' : 'ไม่เคลือบ',
                spotUVFrontName: item.spotUVFront,
                spotUVBackName: item.spotUVBehind,
                spotUVFrontId: item.spotUVFrontId,
                spotUVBackId: item.spotUVBehindId,
              },
              embossing: item.quotationEmbossing.map(x => ({
                ...x,
                lengths: x.length,
              })),
              foilingName: item.foiling,
              foilingId: item.foilingId,
              foiling: item.quotationFoiling.map(x => ({
                ...x,
                lengths: x.length,
              })),
              plasticWindow: item.quotationPlasticWindow.map(x => ({
                ...x,
                lengths: x.length,
              })),
              printing: {
                side: item.printingSide,
                printingType: item.printingSide,
                printingName: item.printingName,
                printingTypeName: item.printingSide === 1 ? 'พิมพ์หน้าเดียว' : item.printingSide === 2 ? 'พิมพ์สองหน้า' : '',
                printingBasicColorName: item.printingBasicColor,
                printingBasicColorId: item.printingBasicColorId,
                printingExtraColorId: item.quotationPrintingExtraColor.map(x => ({ printingExtraColorName: x.printingExtraColor, printingExtraColorId: x.printingExtraColorId })),
              },
              gluing: item.gluing && {
                gluingId: item.gluingId,
                gluing: item.gluing,
              },
              additionalService: item.quotationAdditionalService.length > 0 ? item.quotationAdditionalService.map(x => ({ additionalServiceId: x.additionalServiceId, name: x.name ? x.name : x.additionalService })) : [],
              dieCut: item.dieCut,
              gluingId: item.gluingId,
              additionalServiceId: [],
            })),
          }

          const boxDetail = Object.keys(omitData).reduce((acc, key) => {
            const newResult = [...acc]
            if (key === 'useBoxSuitDesign') {
              newResult.unshift({
                title: step1TitleInput.boxSuitDesignService,
                value: data.requestById.useBoxSuitDesign ? 'ต้องการใช้บริการออกแบบ' : 'ไม่ต้องการใช้บริการออกแบบ',
                name: key,
              })
            } else if (key === 'useCompleteDesign' && !data.requestById.useBoxSuitDesign) {
              newResult.push({
                title: step1TitleInput.designServiceChoice,
                value: data.requestById.useCompleteDesign ? 'ต้องการใช้บริการทำแบบ' : 'ไม่ต้องการใช้บริการทำแบบ',
                name: 'useCompleteDesign',
              })
            } else if (key === 'designInfo') {
              if (!_.isEmpty(data.requestById.designInfo)) {
                if (data.requestById.designInfo && data.requestById.designInfo.width && data.requestById.designInfo.height) {
                  newResult.splice(4, 0, {
                    title: step1TitleInput.productSize,
                    value: `${data.requestById.designInfo.width} ซม. x ${data.requestById.designInfo.length} ซม. x ${data.requestById.designInfo.height} ซม.`,
                    name: 'productSize',
                  })
                }
                if (_.isNumber(data.requestById.designInfo.quantity) && data.requestById.designInfo.quantity > 0) {
                  newResult.splice(5, 0, {
                    title: step1TitleInput.productQuantityPerPiece,
                    value: data.requestById.designInfo.quantity,
                    name: 'quantity',
                  })
                }
                if (!_.isEmpty(data.requestById.designInfo.description)) {
                  newResult.splice(8, 0, {
                    title: step1TitleInput.designDescription,
                    value: data.requestById.designInfo.description,
                    name: 'detail',
                  })
                }
              }
            } else if (key === 'paperTypeText') {
              newResult.push({
                title: step1TitleInput[key],
                value: `${data.requestById.paperTypeText || '-'} ${data.requestById.paperThickness || '-'} แกรม`,
                name: key,
              })
            } else if (key === 'printingTechniqueText') {
              newResult.push({
                title: step1TitleInput[key],
                value: data.requestById.printingTechniqueText,
                name: key,
              })
            } else if (key === 'sampleFiles' && !_.isEmpty(data.requestById[key])) {
              newResult.push({
                title: 'ไฟล์แนบ',
                value: data.requestById[key],
                name: key,
              })
            } else if (key === 'designFiles' && !_.isEmpty(data.requestById[key])) {
              newResult.push({
                title: 'ไฟล์ทำแบบ',
                value: data.requestById[key],
                name: key,
              })
            } else if (key === 'produceQuantity' && data.requestById[key]) {
              newResult.push({ title: 'จำนวนสั่งผลิต', value: `${data.requestById[key].toLocaleString()} ชิ้น`, name: key })
            } else if (key === 'price' && data.requestById[key]) {
              newResult.push({ title: 'ราคาบรรจุภัณฑ์', value: `${data.requestById[key].toLocaleString(undefined, { minimumFractionDigits: 2 })} บาท/ชิ้น`, name: key })
            } else if (key === 'produceType' && data.requestById[key]) {
              newResult.push({ title: 'รูปแบบการสั่งผลิต', value: data.requestById[key] === 'newOrder' ? 'สั่งผลิตใหม่' : data.requestById[key] === 'สั่งผลิตใหม่' ? 'สั่งผลิตใหม่' : 'สั่งผลิตซ้ำ', name: key })
            } else if (key === 'quantity') {
              if (_.isNumber(omitData[key]) && omitData[key] > 0) {
                newResult.splice(5, 0, {
                  title: step1TitleInput[key],
                  value: `${omitData[key]} ชิ้น`,
                  name: 'quantity',
                })
              }
            } else if (key !== 'withHoldingTaxRate' && key !== 'withHoldingTax') {
              newResult.push({
                title: step1TitleInput[key],
                value: omitData[key],
                name: key === 'printingDescription' ? 'detail' : key,
              })
            }
            return newResult
          }, [])

          const statusValue = _.filter(status, v => v.key === data.requestById.status)[0]
          return (
            <Div>
              <BreadCrumb link={{
                parent: [
                  {
                    text: 'Order ทั้งหมด',
                    link: PATH.quotationList,
                  },
                ],
                current: {
                  text: `รายละเอียด ${data.requestById.refId}`,
                },
              }} />
              <HeaderWrapper>
                <Div>
                  <H4 display='block' bold color={theme.color.tertiaryColor}>หมายเลขอ้างอิง {data.requestById.refId}</H4>
                  <SmallText>วันเวลา : {moment(data.requestById.createdAt).format('DD/MM/YYYY HH:mm')}</SmallText>
                </Div>
                <Div textAlign='right'>
                  <H4 color={statusValue.color} textAlign='right' display='block' bold>สถานะ {statusValue.text}</H4>
                  <StatusDropdown
                    id={data.requestById.quotationId}
                    presentStatus={data.requestById.status}
                    listPage
                  />
                </Div>
              </HeaderWrapper>
              {
                (
                  data.requestById.status !== statusKey.QUOTATION_REQUEST && data.requestById.status !== statusKey.QUOTATION_REVIEW && data.requestById.status !== statusKey.WAITING_FOR_PAYMENT
                  && data.requestById.status !== statusKey.PAYMENT_VERIFYING && data.requestById.status !== statusKey.REVISING_PAYMENT
                )
                && [
                  <ShippedData
                    margin={data.requestById.status === statusKey.SHIPPED && '40px 0 0'}
                    key='shippedData'
                    quotationId={parseInt(match.params.id, 10)}
                    isAbleToSendEmail={data.requestById.status === statusKey.SHIPPED}
                  />,
                  <Line key='line' />,
                ]
              }
              {
                (data.requestById.status !== statusKey.QUOTATION_REQUEST && data.requestById.status !== statusKey.QUOTATION_REVIEW && data.requestById.status !== statusKey.WAITING_FOR_PAYMENT)
                && [
                  <PaymentEvidence
                    margin={data.requestById.status !== statusKey.QUOTATION_REQUEST
                      && data.requestById.status !== statusKey.QUOTATION_REVIEW
                      && data.requestById.status !== statusKey.WAITING_FOR_PAYMENT
                      && '40px 0 0'}
                    key='paymentEvidence'
                    quotationId={parseInt(match.params.id, 10)}
                  />,
                  <Line key='line' />,
                ]
              }
              {
                data.requestById.status !== statusKey.QUOTATION_REQUEST && data.requestById.quotationPDF
                && [
                  <QuotationPDFList
                    margin={(data.requestById.status === statusKey.QUOTATION_REVIEW || data.requestById.status === statusKey.WAITING_FOR_PAYMENT) && '40px 0 0'}
                    showCreatNewButton={data.requestById.status === statusKey.QUOTATION_REVIEW}
                    key='quotationPDFList'
                    quotationId={parseInt(match.params.id, 10)}
                    link={data.requestById.quotationPDF}
                  />,
                  <Line key='line' />,
                ]
              }
              <SummaryContent
                id={parseInt(match.params.id, 10)}
                data={{
                  address: userData.address,
                  shippingAddress: userData.shippingAddress ? userData.shippingAddress : userData.address,
                  boxDetail,
                  defaultBoxDetail: data.requestById,
                }}
                isPreDefine={omitData.isPreDefine}
                packageValues={packageValues}
                onChangeTab={onChangeTab}
                activeTab={activeTabSummary}
              />
              <Line />
              <Notes quotationId={parseInt(match.params.id, 10)} />
              {
                data.requestById.status === statusKey.QUOTATION_REQUEST
                && [
                  <Line key='line' />,
                  <CreateQuotation
                    key='quotationCreate'
                    quotationId={parseInt(match.params.id, 10)}
                    productItems={productsItems}
                    getData={(productsValue, taxRateValue) => {
                      setProducts(productsValue)
                      setTaxRate(taxRateValue)
                    }}
                    withHoldingTax={withHoldingTax}
                    onChangeWithHoldingTax={setwithHoldingTax}
                    isPreDefine={omitData.isPreDefine}
                  />,
                ]
              }
              {
                data.requestById.status !== statusKey.QUOTATION_REQUEST
                && [
                  <Line key='line' />,
                  <HistoryLog key='history' margin='32px 0 0' id={parseInt(match.params.id, 10)} />,
                  <HistoryLogQuotation margin='32px 0 0' key='history-log' id={parseInt(match.params.id, 10)}/>,
                ]
              }
              <Div display='flex' margin='100px 0 0' moreStyle={{ columnGap: '5px' }}>
                <Mutation
                  mutation={DELETE_QUOTATION}
                >
                {
                  doDeleteQuotation => (
                    <Div>
                      <Popconfirm
                        title='ยืนยันการรลบ Order หรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'
                        onConfirm={() => {
                          doDeleteQuotation({
                            variables: {
                              id: parseInt(match.params.id, 10),
                            },
                          }).then((res) => {
                            if (res.data.deleteQuotation) {
                              if (res.data.deleteQuotation.success) {
                                message.success(res.data.deleteQuotation.message)
                                setTimeout(() => {
                                  window.location = PATH.quotationList
                                }, 3000)
                              } else {
                                message.error(res.data.deleteQuotation.message)
                              }
                            }
                          })
                        }}
                        okText='ยืนยัน'
                        cancelText='ยกเลิก'
                      >
                        <DeleteButton inverse color={theme.color.error} small text='ลบ Order นี้' />
                      </Popconfirm>
                    </Div>
                  )
                }
                </Mutation>
              <CopyQuotationButton match={match} />
              </Div>
            </Div>
          )
        }
      }
      </Query>
    </Div>
  )
}

const RadioSelectWithDetail = ({
  data, setDefaultChecked, defaultChecked, children,
}) => (
  <RadioSelectWithDetailWrapper>
    <Radio
      data={data}
      setDefaultChecked={value => setDefaultChecked(value)}
      defaultChecked={defaultChecked}
    />
    {
      children && <ChoiceContent checked={defaultChecked === data.value}>
      {
        children
      }
      </ChoiceContent>
    }
  </RadioSelectWithDetailWrapper>
)

const Step1 = ({
  data, onInputChange, onUploadFileList, onRemoveFileList,
  setDisabledButton, onDesignChoiceChange,
}) => {
  const [boxSize, setBoxSize] = useState({})
  const [productSize, setProductSize] = useState({})
  const [isValidate, setIsValidate] = useState(true)
  const paperType = PAPER.map(v => v.paper)
  return (
    <WidthContainer>
      <H4 display='block' bold margin='0 0 32px' color={theme.color.tertiaryColor}>รายละเอียดกล่องบรรจุภัณฑ์</H4>
      <H5 display='block' color={theme.color.secondaryColor}>{step1TitleInput.design}</H5>
      <Div margin='24px 0'>
        <H5 display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.name} *</H5>
        <Input
          width='100%'
          value={data.name}
          onChange={(e) => {
            onInputChange(e.target.value, 'name')
            if (e.target.value !== '') {
              setDisabledButton(false)
              setIsValidate(true)
            }
          }}
          onBlur={() => {
            if (data.name === '') {
              setIsValidate(false)
              setDisabledButton(true)
            }
          }}
          state={!isValidate && 'error'}
        />
      </Div>
      <Div margin='24px 0'>
        <H5 margin='0 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.boxSize}</H5>
        <Div display='flex'>
          <MessageInputWrapper width='calc((100% - 32px)/3)'>
            <Input width='100%' type='number' value={!_.isEmpty(data.boxSize) && data.boxSize.width} message='กว้าง (ซม.)'
            onKeyPress={(e) => {
              if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault()
              }
            }}
            onChange={(e) => {
              const newBoxSize = Object.assign({}, boxSize)
              newBoxSize.w = e.target.value
              newBoxSize.l = data.boxSize.length
              newBoxSize.h = data.boxSize.height
              setBoxSize(newBoxSize)
              onInputChange(newBoxSize, 'boxSize')
            }} />
          </MessageInputWrapper>
          <MessageInputWrapper margin='0 16px' width='calc((100% - 32px)/3)'>
            <Input type='number' value={!_.isEmpty(data.boxSize) && data.boxSize.length} width='100%' message='ยาว (ซม.)'
            onKeyPress={(e) => {
              if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault()
              }
            }}
            onChange={(e) => {
              const newBoxSize = Object.assign({}, boxSize)
              newBoxSize.w = data.boxSize.width
              newBoxSize.l = e.target.value
              newBoxSize.h = data.boxSize.height
              setBoxSize(newBoxSize)
              onInputChange(newBoxSize, 'boxSize')
            }} />
          </MessageInputWrapper>
          <MessageInputWrapper width='calc((100% - 32px)/3)'>
            <Input type='number' value={!_.isEmpty(data.boxSize) && data.boxSize.height} width='100%' message='สูง (ซม.)'
            onKeyPress={(e) => {
              if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault()
              }
            }}
            onChange={(e) => {
              const newBoxSize = Object.assign({}, boxSize)
              newBoxSize.w = data.boxSize.width
              newBoxSize.l = data.boxSize.length
              newBoxSize.h = e.target.value
              setBoxSize(newBoxSize)
              onInputChange(newBoxSize, 'boxSize')
            }} />
          </MessageInputWrapper>
        </Div>
      </Div>
      <Div margin='24px 0'>
        <H5 display='block' bold color={theme.color.primaryColor}>{step1TitleInput.quantity}</H5>
        <P display='block' margin='0 0 8px' color={theme.color.paleBlack}>ราคาต่อ 1 ชิ้นลดลง เมื่อจำนวนมากขึ้น</P>
        <Row gutter={16}>
          <Col md={8}>
            <MessageInputWrapper>
              <Input
                type='number'
                width='100%'
                message='ชิ้น'
                value={data.quantity}
                onChange={e => onInputChange(parseInt(e.target.value, 10), 'quantity')}
              />
            </MessageInputWrapper>
          </Col>
        </Row>
      </Div>
      {/* {
        data.boxSuitDesignService === 'useDesign'
        && [
          <Div key='productSize'>
            <Div margin='24px 0'>
              <H5 margin='0 16px 8px 0' bold color={theme.color.primaryColor}>{step1TitleInput.productSize}</H5>
              <P color={theme.color.paleBlack}>( กรุณาข้าม กรณีขนาดสินค้าไม่แน่นอน )</P>
              <Div display='flex'>
                <MessageInputWrapper width='calc((100% - 32px)/3)'>
                  <Input width='100%' type='number' value={!_.isEmpty(data.productSize) && data.productSize.width} message='กว้าง (ซม.)'
                  onKeyPress={(e) => {
                    if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                      e.preventDefault()
                    }
                  }}
                  onChange={(e) => {
                    const newBoxSize = Object.assign({}, productSize)
                    newBoxSize.w = e.target.value
                    setProductSize(newBoxSize)
                    onInputChange(newBoxSize, 'productSize')
                  }} />
                </MessageInputWrapper>
                <MessageInputWrapper margin='0 16px' width='calc((100% - 32px)/3)'>
                  <Input type='number' value={!_.isEmpty(data.productSize) && data.productSize.length} width='100%' message='ยาว (ซม.)'
                  onKeyPress={(e) => {
                    if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                      e.preventDefault()
                    }
                  }}
                  onChange={(e) => {
                    const newBoxSize = Object.assign({}, productSize)
                    newBoxSize.l = e.target.value
                    setProductSize(newBoxSize)
                    onInputChange(newBoxSize, 'productSize')
                  }} />
                </MessageInputWrapper>
                <MessageInputWrapper width='calc((100% - 32px)/3)'>
                  <Input type='number' value={!_.isEmpty(data.productSize) && data.productSize.height} width='100%' message='สูง (ซม.)'
                  onKeyPress={(e) => {
                    if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                      e.preventDefault()
                    }
                  }}
                  onChange={(e) => {
                    const newBoxSize = Object.assign({}, productSize)
                    newBoxSize.h = e.target.value
                    setProductSize(newBoxSize)
                    onInputChange(newBoxSize, 'productSize')
                  }} />
                </MessageInputWrapper>
              </Div>
            </Div>
          </Div>,
          <Div key='productTotal' margin='24px 0'>
            <H5 margin='0 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.productQuantityPerPiece}</H5>
            <Row gutter={16}>
              <Col md={8}>
                <MessageInputWrapper>
                  <Input
                    type='number'
                    width='100%'
                    message='ชิ้น'
                    value={data.productQuantityPerPiece}
                    onChange={e => onInputChange(parseInt(e.target.value, 10), 'productQuantityPerPiece')}
                  />
                </MessageInputWrapper>
              </Col>
            </Row>
          </Div>,
        ]
      } */}
      <Query
        query={LIST_PAPER}
        variables={{
          limit: 10000,
          offset: 0,
        }}
        notifyOnNetworkStatusChange
        fetchPolicy='network-only'
      >
        {
          (props) => {
            if (props && props.data && props.data.listPaper && props.data.listPaper.success) {
              const paperData = props.data.listPaper.paper
              const paperTypes = [...paperData.map(v => ({ text: v.name, value: v.name })), { text: 'อื่นๆ', value: 'อื่นๆ' }, { text: 'ไม่ทราบ', value: 'ไม่ทราบ' }]
              const paper = [...paperData.map(v => ({
                paper: {
                  text: v.name,
                  value: v.name,
                },
                gram: v.paperThickness.map(x => x.thickness.toString()).filter((e, i, a) => a.indexOf(e) === i),
              })), { paper: { text: 'อื่นๆ', value: 'อื่นๆ' }, gram: [0] }, { paper: { text: 'ไม่ทราบ', value: 'ไม่ทราบ' }, gram: [0] }]
              return (
                <Row gutter={16}>
                  <Col sm={12} md={8}>
                    <H5 margin='24px 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.paperType}</H5>
                    <Select
                      showSearch
                      style={{ width: '100%', fontSize: '18px' }}
                      placeholder="กรุณาเลือกกระดาษ"
                      optionFilterProp="children"
                      value={data.paperTypeText}
                      onChange={value => onInputChange(value, 'paperTypeText')}
                      onBlur={() => onInputChange(((_.isNumber(data.paperTypeText) && data.paperTypeText > 0) || !_.isEmpty(data.paperTypeText))
                        && _.filter(paper, v => v.paper.value === data.paperTypeText)[0].gram[0], 'paperThickness')}
                    >
                    {
                      paperTypes.map(v => (
                        <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>
                      ))
                    }
                    </Select>
                  </Col>
                  <Col sm={12} md={8}>
                    <H5 margin='24px 0 8px' bold color={theme.color.primaryColor}>ความหนากระดาษ</H5>
                    <Select
                      showSearch
                      style={{ width: '100%', fontSize: '18px' }}
                      placeholder="กรุณาเลือกความหนา"
                      optionFilterProp="children"
                      value={data.paperThickness}
                      onChange={value => onInputChange(value, 'paperThickness')}
                      disabled={!(data.paperTypeText && data.paperTypeText !== '') || data.paperTypeText === 'อื่นๆ' || data.paperTypeText === 'ไม่ทราบ'}
                    >
                    {
                      _.filter(paper, v => v.paper.value === data.paperTypeText).length === 1 && _.filter(paper, v => v.paper.value === data.paperTypeText)[0].gram.map(v => (
                        <Select.Option key={v} value={v}>{v}</Select.Option>
                      ))
                    }
                    </Select>
                  </Col>
                  <Col sm={24} md={8}>
                    <H5 margin='24px 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.printingTechnique}</H5>
                    <Query
                      query={GET_PRINTING_FORM}
                    >
                      {
                        (printingProps) => {
                          if (printingProps.data.getPrintingDetail) {
                            const printingData = printingProps.data.getPrintingDetail.printing
                            const resultBasicColor = printingData.printingBasicColor.map(v => v.colorQuantity).map(v => ({
                              text: `พิมพ์ ${v} สี`,
                              value: `พิมพ์ ${v} สี`,
                            }))
                            const resultExtraColor = printingData.printingBasicColor.map(v => v.colorQuantity).map(b => (
                              printingData.printingExtraColor.map(v => v.name).map(color => ({
                                text: `พิมพ์ ${b} สี + ${color}`,
                                value: `พิมพ์ ${b} สี + ${color}`,
                              }))
                            ))
                            const printingTechnique = [...resultBasicColor, ...resultExtraColor.reduce((a, b) => a.concat(b), []), { text: 'อื่นๆ', value: 'อื่นๆ' }, { text: 'ไม่ทราบ', value: 'ไม่ทราบ' }].filter((v, i, a) => a.findIndex(v2 => (v2.value === v.value)) === i)
                            return (
                              <Select
                                showSearch
                                style={{ width: '100%', fontSize: '18px' }}
                                placeholder="กรุณาเลือกจำนวนสี"
                                optionFilterProp="children"
                                value={data.printingTechniqueText}
                                onChange={value => onInputChange(value, 'printingTechniqueText')}
                              >
                              {
                                printingTechnique.map(v => (
                                  <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>
                                ))
                              }
                              </Select>
                            )
                          }
                          return (
                            <Select
                              showSearch
                              style={{ width: '100%', fontSize: '18px' }}
                              placeholder="กรุณาเลือกจำนวนสี"
                              optionFilterProp="children"
                              value={data.printingTechnique}
                              onChange={value => onInputChange(value, 'printingTechnique')}
                            >
                            {
                              PRINT_TECHNIQUE.map(v => (
                                <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>
                              ))
                            }
                            </Select>
                          )
                        }
                      }
                    </Query>
                  </Col>
                </Row>
              )
            }
            return (<p>something when wrong</p>)
          }
        }
      </Query>
      <Div margin='24px 0'>
        <H5 display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.printingDescription}</H5>
        <Input as='textarea' value={data.printingDescription} rows={4} width='100%' onChange={e => onInputChange(e.target.value, 'printingDescription')} />
      </Div>
      {/* {
        data.graphicType && <GraphicSelector
          value={data.graphicType && data.graphicType}
          onChange={e => onInputChange(e, 'graphicType')}
          custom
          maxWidth="100%"
          fullWidth
        />
      } */}
      {/* {
        data.boxSuitDesignService === 'useDesign'
          ? <Div margin='24px 0'>
            <H5 display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>{step1TitleInput.designDescription}</H5>
            <Input as='textarea' value={data.designDescription} rows={4} width='100%' onChange={e => onInputChange(e.target.value, 'designDescription')} />
          </Div>
          : <Div margin='24px 0'>
            <H5 display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>แนบไฟล์ตัวอย่าง</H5>
            <UploadFile
              list={data.sampleFiles}
              id='uploadFileId'
              multiple
              onChange={(fileList) => {
                let upload = false
                const allowFileType = ['pdf', 'zip', '7z', 'rar', 'jpeg', 'jpg', 'png', 'psd', 'ai', 'eps', 'ppt', 'pptx', 'ppt', 'ppsx', 'ppm', 'pps', 'ppa', 'doc', 'docm', 'docx', 'dot', 'dotx']
                for (let i = 0; i < fileList.length; i += 1) {
                  const splitName = fileList[i].name.split('.')
                  if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
                  || (data.sampleFiles.length > 0 && !_.isUndefined(_.find(data.sampleFiles, d => d.name === fileList[i].name)))) {
                    upload = false
                    break
                  } else {
                    upload = true
                  }
                }
                let sameFile = false
                for (let i = 0; i < fileList.length; i += 1) {
                  if (_.find(data.sampleFiles, d => d.name === fileList[i].name)) {
                    sameFile = true
                  }
                }
                if (sameFile) {
                  message.error('ไม่สามารถอัพโหลดไฟล์ซ้ำกันได้')
                } else if (upload) {
                  onUploadFileList(fileList, 'sampleFiles')
                } else {
                  message.error('ไม่สามารถอัพโหลดไฟล์ชนิดนี้ได้')
                }
              }}
              onRemoveFile={fileName => onRemoveFileList(fileName, 'sampleFiles')}
              fileType="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, , application/pdf, image/*, .ai, .eps, .zip, .7z, .doc, .docx, .ppt, .pptx"
            />
          </Div>
      } */}
      <GraphicSelector
        value={data.graphicType && data.graphicType}
        onChange={e => onInputChange(e, 'graphicType')}
        custom
        maxWidth="100%"
        fullWidth
      />
      <Div margin='24px 0'>
        <H5 display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>แนบไฟล์ตัวอย่าง</H5>
        <UploadFile
          list={data.sampleFiles}
          id='uploadFileId'
          multiple
          onChange={(fileList) => {
            let upload = false
            const allowFileType = ['pdf', 'zip', '7z', 'rar', 'jpeg', 'jpg', 'png', 'psd', 'ai', 'eps', 'ppt', 'pptx', 'ppt', 'ppsx', 'ppm', 'pps', 'ppa', 'doc', 'docm', 'docx', 'dot', 'dotx']
            for (let i = 0; i < fileList.length; i += 1) {
              const splitName = fileList[i].name.split('.')
              if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
              || (data.sampleFiles.length > 0 && !_.isUndefined(_.find(data.sampleFiles, d => d.name === fileList[i].name)))) {
                upload = false
                break
              } else {
                upload = true
              }
            }
            let sameFile = false
            for (let i = 0; i < fileList.length; i += 1) {
              if (_.find(data.sampleFiles, d => d.name === fileList[i].name)) {
                sameFile = true
              }
            }
            if (sameFile) {
              message.error('ไม่สามารถอัพโหลดไฟล์ซ้ำกันได้')
            } else if (upload) {
              onUploadFileList(fileList, 'sampleFiles')
            } else {
              message.error('ไม่สามารถอัพโหลดไฟล์ชนิดนี้ได้')
            }
          }}
          onRemoveFile={fileName => onRemoveFileList(fileName, 'sampleFiles')}
          fileType="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, , application/pdf, image/*, .ai, .eps, .zip, .7z, .doc, .docx, .ppt, .pptx"
        />
      </Div>
    </WidthContainer>
  )
}

const CopyQuotationButton = ({ match }) => (
  <Mutation
    mutation={DUPLICATE_QUOTATION}
  >
  {
    doDuplicateQuotation => (
      <Div>
        <Popconfirm
          title='ต้องการคัดลอก Order นี้ใช่หรือไม่'
          onConfirm={() => {
            doDuplicateQuotation({
              variables: {
                input: {
                  quotationId: parseInt(match.params.id, 10),
                },
              },
            }).then((res) => {
              if (res.data.duplicateQuotation) {
                if (res.data.duplicateQuotation.success) {
                  message.success(res.data.duplicateQuotation.message)
                  setTimeout(() => {
                    window.location = PATH.quotationList
                  }, 3000)
                } else {
                  message.error(res.data.duplicateQuotation.message)
                }
              }
            })
          }}
          okText='ใช่'
          cancelText='ไม่ใช่'
        >
            <Button inverse color={theme.color.primaryColor} small text='คัดลอก Order นี้' />
        </Popconfirm>
      </Div>
    )
  }
  </Mutation>
)

export default QuotationDetail
