import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { message, Popconfirm } from 'antd'
import {
  Div, H4, Button, LoadingIcon,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import PATH from '../../../../constants/path'
import { theme } from '../../../../styles/_variables'
import { GET_COATING_DETAIL } from '../../../../constants/graphql/query'
import { DELETE_COATING, EDIT_COATING } from '../../../../constants/graphql/mutation'
import CoatingForm from '../components/coating-form'
import BreadCrumb from '../../../../components/common/breadcrumb'

const { color } = theme


const CoatingDetail = ({ match }) => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    coverPhoto: [],
    sampleVideo: [],
    imagePhoto: [],
    costMinimum: 0,
    headerCoating: [{
      title: 'จำนวนผลิต (ชิ้น)',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: 'number',
      editable: false,
      editColumn: false,
      alignCell: 'center',
    }],
    dataCoating: [],
  })
  return (
    <Query
      query={GET_COATING_DETAIL}
      variables={{
        id: parseInt(match.params.id, 10),
      }}
      notifyOnNetworkStatusChange
      onCompleted={(data) => {
        const coatingData = data.getCoatingDetail.coating
        if (data.getCoatingDetail.success) {
          const coatingDataColumn = coatingData.coatingCostColumnName.map(item => ({
            title: item.name,
            dataIndex: item.type,
            key: item.type,
            inputType: 'number',
            editable: true,
            editColumn: item.type !== 'column1',
            alignCell: 'center',
          }))
          setFormValues({
            ...formValues,
            name: coatingData.name,
            description: coatingData.description,
            coverPhoto: coatingData.coatingFiles.filter(file => file.type === 'cover'),
            sampleVideo: coatingData.coatingFiles.filter(file => file.type === 'video'),
            imagePhoto: coatingData.coatingFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample'),
            headerCoating: [...formValues.headerCoating, ...coatingDataColumn],
            dataCoating: coatingData.coatingCost.map((item, index) => ({
              key: index,
              quantity: item.quantity,
              column1: item.column1,
              column2: item.column2,
              column3: item.column3,
              column4: item.column4,
              column5: item.column5,
            })),
            costMinimum: coatingData.minimumCost,
          })
        } else {
          message.error(data.getCoatingDetail.message)
          setTimeout(() => {
            window.location = PATH.coatingSetting
          }, 1000)
        }
      }}
    >
      {
        ({ loading, data }) => {
          if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
          const { coatingId, name } = data.getCoatingDetail.coating
          return (
            <>
              <Div display='flex' flexDirection='column'>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
                    <H4 bold color={color.primaryColor} margin='32px 0'>
                        <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                        <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                        </Link> {' > '}
                        <Link to='/package-setting/coating' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                        <H4 bold color={color.primaryColor}>การเคลือบผิว</H4>
                        </Link> {' > '} แก้ไขข้อมูล
                    </H4>
                    <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                        <Mutation mutation={DELETE_COATING}>
                        {
                          doDeleteCoating => (
                            <Popconfirm
                              title='ยืนยันการลบการเคลือบผิวหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'
                              onConfirm={() => {
                                doDeleteCoating({
                                  variables: {
                                    id: parseInt(coatingId, 10),
                                  },
                                }).then((res) => {
                                  if (res.data.deleteCoating) {
                                    if (res.data.deleteCoating.success) {
                                      message.success(res.data.deleteCoating.message)
                                      setTimeout(() => {
                                        window.location = PATH.coatingSetting
                                      }, 350)
                                    } else {
                                      message.error(res.data.deleteCoating.message)
                                    }
                                  }
                                })
                              }
                            }
                            okText='ยืนยัน'
                            cancelText='ยกเลิก'
                          >
                            <Button text='ลบ' icon='fa fa-trash-o fa-lg' ghost margin='0 16px 0 0' color={color.error}/>
                          </Popconfirm>
                          )
                        }
                        </Mutation>
                      <Mutation
                        mutation={EDIT_COATING}
                      >
                        {doEditCoating => (
                          <Button
                            text='บันทึก'
                            icon='fa fa-save'
                            inverse
                            margin='0 16px 0 0'
                            loading={callMutation}
                            disabled={callMutation}
                            onClick={() => {
                              setCallMutation(true)
                              if (formValues.name === '') {
                                message.warning('กรุณากรอกข้อมูลชื่อวิธีการเคลือบผิว')
                                setCallMutation(false)
                                setIsValidate(false)
                                return
                              }

                              if (formValues.name.match(new RegExp(/^\s/)) !== null) {
                                message.warning('กรุณากรอกข้อมูลชื่อวิธีการเคลือบผิวให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
                                setCallMutation(false)
                                setIsValidate(false)
                                return
                              }
                              const input = {
                                coatingId,
                                name: formValues.name,
                                description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
                                minimumCost: parseFloat(formValues.costMinimum),
                                coverPhoto: formValues.coverPhoto,
                                video: formValues.sampleVideo,
                                imagePhoto: formValues.imagePhoto,
                                coatingCostColumnName: formValues.headerCoating.filter(column => /column*/.test(column.key)).map(column => ({
                                  name: column.title,
                                  type: column.key,
                                  coatingId,
                                })),
                                coatingCost: formValues.dataCoating.map(row => ({
                                  coatingId,
                                  quantity: row.quantity,
                                  column1: parseFloat(row.column1),
                                  column2: parseFloat(row.column2),
                                  column3: parseFloat(row.column3),
                                  column4: parseFloat(row.column4),
                                  column5: parseFloat(row.column5),
                                })),
                              }
                              doEditCoating({
                                variables: {
                                  input,
                                },
                              }).then(({ data: dataResponse }) => {
                                if (dataResponse.editCoating && dataResponse.editCoating.success) {
                                  message.success(dataResponse.editCoating.message)
                                  setTimeout(() => {
                                    setCallMutation(false)
                                    window.location = PATH.coatingSetting
                                  }, 350)
                                } else {
                                  if (dataResponse.editCoating.message === 'ชื่อ วิธีการเคลือบผิว นี้มีอยู่ในระบบแล้ว') {
                                    setIsValidate(false)
                                  }
                                  setCallMutation(false)
                                  message.error(dataResponse.editCoating.message)
                                }
                              })
                            }}
                          />
                        )}
                      </Mutation>
                    </Div>
                  </Div>
                </Div>
                {
                  data.getCoatingDetail.coating && <CoatingForm
                    formValues={formValues}
                    setFormValues={setFormValues}
                    isValidate={isValidate}
                    setIsValidate={setIsValidate}
                    action={'detail'}
                  />
                }
            </Div>
          </>
          )
        }
      }
    </Query>
  )
}

export default CoatingDetail
