import React from 'react'
import {
  message, Popconfirm,
} from 'antd'
import {
  WidthContainer,
} from './styled'
import TagInput from '../tag-input'
import TagArea from '../tag-area'


const TagForm = ({
  formValues,
  setFormValues,
}) => {
  const handleAddTag = (value) => {
    if (!value) {
      message.warning('กรุณากรอกข้อมูลให้ครบถ้วน')
      return
    }
    if (formValues.tag.find(item => item.name === value)) {
      message.warning('ชื่อ Tag นี้มีอยู่แล้ว')
      return
    }
    const lastItem = formValues.tag[formValues.tag.length - 1]
    const tmpTag = [...formValues.tag, { tagId: lastItem ? lastItem.tagId + 1 : 1, name: value }]
    setFormValues({ tag: tmpTag })
  }
  return (
    <>
      <WidthContainer>
        <TagInput
          handleAdd={handleAddTag}
        />
        <TagArea
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </WidthContainer>
    </>
  )
}

export default TagForm
