import styled from 'styled-components'
import { Button } from 'konsys-ui'
import { theme } from '../../../../styles/_variables'


export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`

export const NewButton = styled(Button)`
  border-radius: 5px;
  padding: 0px 3px;
  width: 120px;
  display: flex;
  align-items: center;
  i {
    font-size: 12px !important;
    font-weight: ${theme.fonts.style.regular};
    padding-left: 4px;
  }
  span {
    font-size: 22px;
    font-weight: ${theme.fonts.style.medium};
  }
`

export const TagDiv = styled(Button)`
  border-radius: 1.4rem;
  padding: 2px 16px;
  max-width: 300px;
  text-align: left;
  cursor: pointer;
  i {
    font-size: ${theme.fonts.size.small} !important;
    font-weight: ${theme.fonts.style.medium};
  }
  span {
    word-break: break-all;
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
  }
`

export const TagDivDefault = styled(Button)`
  border-radius: 1.4rem;
  padding: 2px 16px;
  max-width: 300px;
  text-align: left;
  cursor: pointer;
  background-color: ${theme.color.paleGray} !important;
  border-color: ${theme.color.paleGray} !important;
  i {
    color: ${theme.color.greyNormal} !important;
    font-size: ${theme.fonts.size.label} !important;
    font-weight: ${theme.fonts.style.medium};
  }
  span {
    word-break: break-all;
    color: ${theme.color.greyNormal} !important;
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
  }
`

export default Label
