import React, { useState, useEffect } from 'react'
import { Div, LoadingIcon } from 'konsys-ui'
import { Query } from 'react-apollo'
import { Row, Col } from 'antd'
import { LIST_FOILING } from '../../../../constants/graphql/query'
import { theme } from '../../../../styles/_variables'
import NotchedContainer from '../../../common/notched-containner'
import OptionsList from '../common/options-list'
import OptionDescription from '../common/option-description'
import SampleImageList from '../common/sample-image-list'
import MultiSizeInput from '../common/multi-size-input'
import { CoverImage, CoverImageFoiling, DetailWrapper } from './styled'
import ForbiddenRed from '../../../../images/icon/Forbidden-white Icon.png'
import FoilingIcon from '../../../../images/icon/FoilingIcon.png'
import Recommendation from '../common/recommendation'


const MockIcon = () => <div style={{
  padding: '10px',
  background: 'gray',
}}></div>

const FoilingSelector = ({
  formValues,
  setFormValues,
  measurementUnit,
  activeTab,
  disabled,
}) => {
  const [foilingFormValues, setFoilingFormValues] = useState({
    foilingId: null,
    foilingPart: [],
  })
  const [optionItems, setOptionItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [remark, setRemark] = useState('')

  const handleOnSelect = (value) => {
    if (value.value === 0) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].foiling = []
      tmpFormValues[activeTab].foilingId = 0
      tmpFormValues[activeTab].foilingName = null
      setFormValues({ ...formValues, partData: tmpFormValues })
    } else {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].foilingId = value.foilingId
      tmpFormValues[activeTab].foilingName = value.name
      tmpFormValues[activeTab].foiling = formValues.partData[activeTab].foiling
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
    setSelectedItem(value)
    setFoilingFormValues({ ...foilingFormValues, foilingId: formValues.partData[activeTab].foilingId, foilingPart: formValues.partData[activeTab].foiling })
  }

  const handleChangeSize = (value) => {
    const tmpFormValues = formValues.partData
    tmpFormValues[activeTab].foiling = value
    setFormValues({ ...formValues, partData: tmpFormValues })
    setFoilingFormValues({ ...foilingFormValues, foilingPart: value })
  }

  useEffect(() => {
    if (optionItems.length > 1) {
      const foilingData = optionItems.map((item, index) => {
        const coverImg = item.foilingFiles.find(file => file.type === 'cover')
        const sampleImages = item.foilingFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
        const sampleVideo = item.foilingFiles.filter(file => file.type === 'video')
        return {
          ...item,
          label: item.name,
          value: item.foilingId,
          icon: coverImg ? <CoverImageFoiling imgUrl={coverImg.foilingFileUri} /> : <MockIcon />,
          sampleImages,
          sampleVideo,
          checked: formValues.partData[activeTab].foilingId === item.foilingId,
          index: index + 1,
        }
      })
      const tmpOptions = [{
        id: 0,
        foilingFiles: [],
        name: 'ไม่ใช้บริการ',
        label: 'ไม่ใช้บริการ',
        icon: <CoverImage src={ForbiddenRed} />,
        checked: formValues.partData[activeTab].foilingId === 0,
        value: 0,
        index: 0,
        sampleImages: [],
        backgroundRed: true,
      }, ...foilingData.filter(x => x.id !== 0)]
      setOptionItems(tmpOptions)
      handleOnSelect(tmpOptions.find(item => item.value === formValues.partData[activeTab].foilingId))
      setFoilingFormValues({ ...foilingFormValues, foilingId: formValues.partData[activeTab].foilingId, foilingPart: formValues.partData[activeTab].foiling })
    }
  }, [activeTab])

  return <Div display='flex' flexDirection='column' moreStyle={{ rowGap: '20px' }}>
  <NotchedContainer
    title='การปั๊มฟอยล์-ทองเค'
    titleIcon={FoilingIcon}
  >
    <Row type='flex' gutter={16}>
      <Col xs={24} sm={11} md={11} span={16}>
        <Query
          query={LIST_FOILING}
          notifyOnNetworkStatusChange
          fetchPolicy='network-only'
          variables={{
            limit: 10000,
            offset: 0,
          }}
          onCompleted={(data) => {
            if (data.listFoiling.foiling) {
              const foilingData = data.listFoiling.foiling.map((item, index) => {
                const coverImg = item.foilingFiles.find(file => file.type === 'cover')
                const sampleImages = item.foilingFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
                const sampleVideo = item.foilingFiles.filter(file => file.type === 'video')
                return {
                  ...item,
                  foilingFiles: item.foilingFiles,
                  label: item.name,
                  value: item.foilingId,
                  icon: coverImg ? <CoverImageFoiling imgUrl={coverImg.foilingFileUri} /> : <MockIcon />,
                  sampleImages,
                  sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].foilingFileUri : null,
                  checked: formValues.partData[activeTab].foilingId === item.foilingId,
                  index: index + 1,
                }
              })
              const tmpOptions = [{
                id: 0,
                foilingFiles: [],
                name: 'ไม่ใช้บริการ',
                label: 'ไม่ใช้บริการ',
                icon: <CoverImage src={ForbiddenRed} />,
                checked: formValues.partData[activeTab].foilingId === 0,
                value: 0,
                index: 0,
                sampleImages: [],
                backgroundRed: true,
              }, ...foilingData]
              const selectItem = tmpOptions.find(item => formValues.partData[activeTab].foilingId === item.value)
              setSelectedItem(selectItem)
              setOptionItems(tmpOptions)
              handleOnSelect(selectItem)
              setRemark(data.listFoiling.remark)
            }
          }}
        >
          {
            ({ loading, data, refetch }) => {
              if (loading) {
                return <Div moreStyle={{ width: '100%' }}>
                  <LoadingIcon color={theme.color.grey} />
                </Div>
              }
              return (
                <OptionsList
                  title='เลือกตัวเลือกที่ต้องการ'
                  options={optionItems}
                  onSelect={handleOnSelect}
                  circle
                  gridColumn={3}
                  disabled={disabled}
                />
              )
            }
          }
        </Query>
      </Col>
      <Col xs={24} sm={13} md={13} span={16}>
        <DetailWrapper>
          <OptionDescription
            title={selectedItem && selectedItem.name}
            description={selectedItem && selectedItem.description}
            videoSrc={selectedItem && selectedItem.sampleVideoUri }
          />
          {
            selectedItem && selectedItem.sampleImages.length > 0 && <SampleImageList
              title='รูปภาพตัวอย่าง'
              data={selectedItem.sampleImages.sort((a, b) => a.ordering - b.ordering).map(item => ({ fileUrl: item.foilingFileUri, ordering: item.ordering }))}
            />
          }
          {
            selectedItem && selectedItem.id !== 0 && selectedItem.sampleImages.length === 0 && <Div style={{ marginTop: '16px' }}></Div>
          }
          {
            selectedItem && selectedItem.index !== 0 ? <MultiSizeInput
              values={foilingFormValues.foilingPart}
              unit={measurementUnit}
              onChange={handleChangeSize}
              disabled={disabled}
            /> : null
          }
          {
            remark && <Recommendation
              value={remark.description}
            />
          }
        </DetailWrapper>
      </Col>
    </Row>
  </NotchedContainer>
</Div>
}


export default FoilingSelector
