
import React from 'react'
import _ from 'lodash'
import {
  message,
} from 'antd'
import {
  Div,
} from 'konsys-ui'
import UploadFile from '../../../common/upload'
import {
  Label,
} from './styled'


const ImageInput = ({
  newFileList,
  setNewFileList,
  data,
  formValues,
}) => (
  <Div display='flex' flexDirection='column'>
      <Label>แนบไฟล์</Label>
      <UploadFile
        list={newFileList}
        id='uploadCoverFileId'
        multiple
        onChange={(fileList) => {
          let upload = false
          const allowFileType = ['pdf', 'zip', '7z', 'rar', 'jpeg', 'jpg', 'png', 'psd', 'ai', 'eps', 'ppt', 'pptx', 'ppt', 'ppsx', 'ppm', 'pps', 'ppa', 'doc', 'docm', 'docx', 'dot', 'dotx']
          for (let i = 0; i < fileList.length; i += 1) {
            const splitName = fileList[i].name.split('.')
            if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
          || (newFileList.length > 0 && !_.isUndefined(_.find(newFileList, d => d.name === fileList[i].name)))) {
              upload = false
              break
            } else {
              upload = true
            }
          }
          let sameFile = false
          for (let i = 0; i < fileList.length; i += 1) {
            if (_.find(formValues.graphicFile, d => d.name === fileList[i].name)) {
              sameFile = true
            }
          }
          if (sameFile) {
            message.error('ไม่สามารถอัพโหลดไฟล์ซ้ำกันได้')
          } else if (upload) {
          // Set only one image to state
            setNewFileList({ ...formValues, graphicFile: [...fileList, ...formValues.graphicFile] })
          } else {
            message.error('ไม่สามารถอัพโหลดไฟล์ชนิดนี้ได้')
          }
        }}
        onRemoveFile={(fileName) => {
          const newUploadFileList = [...newFileList]
          _.remove(newUploadFileList, v => v.name === fileName)
          setNewFileList({ ...formValues, graphicFile: newUploadFileList })
        }}
        fileType="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, , application/pdf, image/*, .ai, .eps, .zip, .7z, .doc, .docx, .ppt, .pptx"
        confirm={true}
        confirmText={'ยืนยันการลบไฟล์หรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'}
        fileKey={'paperFileUri'}
      />
  </Div>
)

export default ImageInput
