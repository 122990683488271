import React, { useState } from 'react'
import {
  FullWidthContainer, QuaterFullWidthContainer,
} from './styled'
import ThicknessInput from '../title-thickness'
import PlasticMinimumCostInput from '../plastic-cost-minimum'
import PlasticOperationMinimumCostInput from '../plastic-cost-operation-minimum'
import PlasticCostTable from '../plastic-cost-table'
import PlasticOperationCostTable from '../plastic-cost-operation-table'


const ThicknessForm = ({
  formValues,
  setFormValues,
  isValidate,
  setIsValidate,
  action,
  history,
}) => {
  const [, setDisabledButton] = useState(true)
  return (
    <>
      <QuaterFullWidthContainer>
        <ThicknessInput
          label={'ความหนาแผ่นใส'}
          data={formValues}
          onInputChange={setFormValues}
          setDisabledButton={setDisabledButton}
          setIsValidate={setIsValidate}
          isValidate={isValidate}
        />
      </QuaterFullWidthContainer>
      <FullWidthContainer>
        <PlasticCostTable
          data={formValues}
          onChange={setFormValues}
          formValues={formValues}
        />
        <PlasticMinimumCostInput
          data={formValues}
          onInputChange={setFormValues}
          action={action}
        />
      </FullWidthContainer>
      <FullWidthContainer>
        <PlasticOperationCostTable
          data={formValues}
          onChange={setFormValues}
          formValues={formValues}
        />
        <PlasticOperationMinimumCostInput
          data={formValues}
          onInputChange={setFormValues}
          action={action}
        />
      </FullWidthContainer>
    </>
  )
}

export default ThicknessForm
