import React, { useState } from 'react'
import {
  WidthContainer,
} from './styled'
import CoverUpload from '../cover-upload'
import Title from '../title'
import Description from '../description'
import SwitchFreeOption from '../switch-free-option'


const PackageForm = ({
  promotionData,
  formValues,
  setFormValues,
  isValidate,
  setIsValidate,
}) => {
  const [, setDisabledButton] = useState(true)
  return (
    <div>
        <WidthContainer>
          <CoverUpload
            data={formValues}
            newFileList={formValues.coverPhoto}
            setNewFileList={setFormValues}
          />
          <Title
            label={'ชื่อโปรโมชั่น'}
            data={formValues}
            onInputChange={setFormValues}
            setDisabledButton={setDisabledButton}
            setIsValidate={setIsValidate}
            isValidate={isValidate}
          />
          <Description
            data={formValues}
            onInputChange={setFormValues}
          />
          <SwitchFreeOption
            data={formValues}
            onInputChange={setFormValues}
          />
        </WidthContainer>
    </div>
  )
}

export default PackageForm
