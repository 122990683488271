import { split, ApolloLink } from 'apollo-link'
import { ApolloClient } from 'apollo-client'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { setContext } from 'apollo-link-context'
// import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getAccessToken } from './utils/local-data-service'
import URL from './constants/environment'


export const url = process.env.REACT_APP_BUILD_MODE === 'production' ? URL.production : (process.env.REACT_APP_BUILD_MODE === 'staging') ? URL.staging : URL.development

const token = getAccessToken()
const NETWORK_INTERFACE_URL = token ? url.GRAPH_PRIVATE : url.GRAPH_PUBLIC

console.log('////////////')
console.log(NETWORK_INTERFACE_URL)
console.log('/////////////')

const SUBSCRIPTION_CLIENT_URL = 'wss://subscriptions.graph.cool/v1/API_KEY'

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: token ? `Bearer ${token}` : '',
  },
}))

const uploadLink = createUploadLink({
  uri: NETWORK_INTERFACE_URL,
})


// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: SUBSCRIPTION_CLIENT_URL,
  options: {
    reconnect: true,
  },
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  authLink.concat(uploadLink),
)

// const client = new ApolloClient({
//   link,
//   cache: new InMemoryCache(),
// })

const client = new ApolloClient({
  link: ApolloLink.from([
    // onError(({ networkError }) => {
    //   console.log(networkError.result.message)
    // }),
    link,
  ]),
  cache: new InMemoryCache(),
  ssrForceFetchDelay: 5000,
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})

export default client
