import styled from 'styled-components'
import { theme } from '../../../../../styles/_variables'


export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`

export const DivTitle = styled.div`
  width: 100%;
`

export default Label
