import React from 'react'
import { Link } from 'react-router-dom'
import { Popconfirm, message } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import parseHtml from 'html-react-parser'

import PATH from '../../../constants/path'
import { LIST_GRAPHIC } from '../../../constants/graphql/query'
import { DELETE_GRAPHIC } from '../../../constants/graphql/mutation'
import RecommendationSetting from '../recommendation-setting'
import {
  ButtonAction,
  SimpleTable, ActionGroupWrapper,
  Column, Description,
} from './styled'
import { theme } from '../../../styles/_variables'
import BreadCrumb from '../../../components/common/breadcrumb'


const { color } = theme

const columns = [{
  title: 'ชื่อตัวเลือกบริการด้านกราฟฟิก',
  dataIndex: 'name',
  render: text => <Column>{text}</Column>,
  align: 'center',
},
{
  title: 'คำอธิบาย',
  dataIndex: 'description',
  render: text => <Column>{text}</Column>,
  align: 'center',
},
{
  title: 'คำสั่ง',
  dataIndex: 'action',
  align: 'center',
  width: '120px',
}]

const GraphicSetting = props => (
  <Div>
    <Div display='flex' flexDirection='row' justifyContent='space-between' margin='32px 0px 0px 0px' alignItems='center'>
      <Div display='flex' flexDirection='column'>
        <H4 bold color={color.primaryColor}>
          <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
            <H4 bold color={color.primaryColor}>Catalog Settings</H4>
          </Link> {' > '} บริการด้านกราฟฟิก
        </H4>
        </Div>
        <Link to='/package-setting/graphic-create' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
          <Button
            htmlType="submit"
            text='New'
            icon='fal fa-plus'
            inverse
          />
        </Link>
    </Div>
    <TableListGraphic history={props.history} />
  </Div>
)

const TableListGraphic = ({
  history,
}) => (
  <Div>
    <Query
      query={LIST_GRAPHIC}
      fetchPolicy='cache-and-network'
      variables={{
        limit: 10000,
        offset: 0,
      }}
    >
      {
        ({ loading, data, refetch }) => {
          let graphicList = []
          let graphicRemark = null
          if (!loading && data) {
            let defaultItem = {}
            const otherItems = []
            data.listGraphic.graphic.forEach((item) => {
              const itemData = {
                name: item.name,
                description: <Description>{item.description ? parseHtml(item.description) : '-'}</Description>,
                action: <ActionGroup
                  rowData={item}
                  onEdit={() => history.push(`${PATH.graphicDetail}/${item.graphicId}`)}
                  isDefault={item.isDefault}
                />,
              }
              if (item.isDefault) {
                defaultItem = itemData
              } else {
                otherItems.push(itemData)
              }
            })
            graphicList = [defaultItem, ...otherItems]
            graphicRemark = data.listGraphic.remark
          }
          return (
            <>
              {/* <RecommendationSetting
                moduleName='graphic'
                data={graphicRemark}
                refetch={() => refetch()}
              /> */}
              <SimpleTable
                loading={loading}
                columns={columns}
                dataSource={graphicList}
                pagination={false}
              />
            </>
          )
        }
      }
    </Query>
  </Div>
)

const ActionGroup = ({ rowData, onEdit, isDefault = false }) => (
  <ActionGroupWrapper>
    <ButtonAction small inverse text='แก้ไข' icon='fa fa-pencil-square-o' onClick={onEdit} />
    {
      !isDefault && <Mutation mutation={DELETE_GRAPHIC}>
        {
          doDeleteCoating => (
            <Popconfirm
              title='ยืนยันการลบบริการด้านกราฟฟิกหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'
              onConfirm={() => {
                doDeleteCoating({
                  variables: {
                    id: parseInt(rowData.graphicId, 10),
                  },
                }).then((res) => {
                  if (res.data.deleteGraphic) {
                    if (res.data.deleteGraphic.success) {
                      message.success(res.data.deleteGraphic.message)
                      setTimeout(() => {
                        window.location = PATH.graphicSetting
                      }, 100)
                    } else {
                      message.error(res.data.deleteGraphic.message)
                    }
                  }
                })
              }}
              okText='ยืนยัน'
              cancelText='ยกเลิก'
            >
              <ButtonAction small text='ลบ' icon='fa fa-trash-o fa-lg' color={theme.color.error} />
            </Popconfirm>
          )
        }
      </Mutation>
    }
  </ActionGroupWrapper>
)

export default GraphicSetting
