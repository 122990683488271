import styled, { css } from 'styled-components'
import { Button, ButtonForm } from 'konsys-ui'
import { theme, media, minMedia } from '../../styles/_variables'

export const Menu = styled.div`
  padding: 0px 24px;
  cursor: pointer;
  color: ${theme.color.offWhite};
  background-color: ${theme.color.primaryColor};
  font-size: ${theme.fonts.size.subTitle};
  text-align: center;
  border-radius: 4px;
  font-weight: ${theme.fonts.style.medium};
`

export const ButtonSearch = styled(Button)`
  border-radius: 20px;
  padding: 0px 24px;
  i {
    font-size: ${theme.fonts.size.normal} !important;
    font-weight: ${theme.fonts.style.medium};
  }
  span {
    font-size: ${theme.fonts.size.subTitle};
    font-weight: ${theme.fonts.style.medium};
  }
`

export const WidthContainer = styled.div`
  margin-left: 50px;
  // max-width: 59vw;
  margin-top: ${props => (props.step === 2 ? '0px' : '-60px')};
  margin-bottom: ${props => (props.data === 1 ? '0px' : '-60px')};
  ${props => props.bgColor && css`
    background-color: ${props.bgColor};
  `}
  ${media.laptopL`
    max-width: 100%;
  `}
  ${media.laptop`
    max-width: 100%;
  `}
  ${media.tablet`
    max-width: 85%;
  `}
  ${media.mobileL`
    max-width: 78%;
  `}
  ${media.mobileM`
    max-width: 75%;
  `}
  ${media.mobileS`
    max-width: 74%;
  `}
  @media only screen and (min-width: 1370px) {
    max-width: ${props => (props.step === 2 ? '60vw' : '59vw')};
  }
  @media only screen and (min-width: 1440px) {
    max-width: ${props => (props.step === 2 ? '50vw' : '59vw')};
  }
`

export const ContentParts = styled.div`
  margin-left: -50px;
  margin-right: -60px;
  border-top: ${props => (props.step === 2 ? '0px' : `61px solid ${theme.color.greenPrimary}`)};
  border-bottom: ${props => (props.step === 1 && props.data === 1 ? '0px' : props.step === 1 && props.data > 1 ? `60px solid ${theme.color.greenPrimary}` : '0px')};
  ${media.laptopL`
    // max-width: 100%;
  `}
  ${media.tablet`
    max-width: 120%;
  `}
  ${media.mobileL`
    max-width: 130%;
  `}
  ${media.mobileM`
    max-width: 140%;
  `}
  @media only screen and (min-width: 1441px) {
    max-width: ${props => (props.step === 2 ? '39vw' : '120%')};
  }
  
`

export const ActionZone = styled.div`
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${media.tablet`
      justify-content: center;
      width: 100%;
      height: 120px;
      margin: 80px 0px 80px 0px;
    `}
`

export const ActionZone2 = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

export const ButtonCalculator = styled(Button)`
  width: 300px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: ${theme.fonts.size.title};
  border-radius: 25px;
  padding: 2px 8px !important;
  background-color: ${theme.color.greenPrimary};
  box-shadow: 2px 2px 0px 1px rgb(110, 201, 190, 0.4) !important;
  & > p {
    margin: 0px !important;
    font-size: ${theme.fonts.size.title};
  }
  
`

export const IconCalculate = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`

export const SummaryDiv = styled.div`
  width: 100%;
  margin-left: 12px;
  position: relative;
  margin-top: 48px;
`

export const ContentPart = styled.div`
`

export const SummaryDivMobile = styled.div`
  width: 100%;
  position: relative;
  margin-top: 24px;
  margin-bottom: 40px;
  padding: 8px 24px;
`

export const ButtonFormAction = styled(ButtonForm)`
  margin: 0px;
  padding: 8px 24px;
  white-space: nowrap;
  align-items: center;
  display: flex;
  & > span {
    font-size: ${theme.fonts.size.subTitle} !important;
    ${media.mobileL`
      font-size: ${theme.fonts.size.small} !important;
    `}
  }
  & > i {
    font-size: ${theme.fonts.size.subTitle} !important;
    width: 30px !important;
    height: 30px !important;
    ${media.mobileL`
      width: 12px !important;
      height: 12px !important;
      font-size: 12px !important;
      margin: 0px 4px 0px 0px !important;
    `}
  }
  ${media.mobileL`
    margin-left: 0px;
    padding: 8px 12px;
  `}
  
`

export default Menu
