import styled, { css } from 'styled-components'
import { theme, media } from '../../../../../styles/_variables'


export const OptionLabel = styled.label`
width: 100%;
min-height: 160px;
opacity: ${props => (props.disabled ? '0.5' : '1')};
> div{
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
> input{ /* HIDE RADIO */
    visibility: hidden; /* Makes input not-clickable */
    position: absolute; /* Remove input from document flow */
}
> input + div{ /* DIV STYLES */
    cursor:pointer;
    border:2px solid transparent;
}
> input:checked + div{ /* (RADIO CHECKED) DIV STYLES */
    font-weight: ${theme.fonts.style.semiBold}
    border: 5px solid rgba(${theme.color.primaryColorRGB}, 0.5) ;
    box-shadow: none;
}
`

export const OptionContentWrapper = styled.div`
display: grid;
align-content: center;
width: 100%;
padding: 16px 2px;
font-size: ${theme.fonts.size.subTitle};
color: ${theme.color.primaryColor}
text-align: center;
`

export const ImageDiv = styled.div`
 > img {
    width: fit-content;
    height: 90px;
    width: -moz-fit-content;
    width: auto;
    ${media.laptopL`
        height: 80px;
    `}
    ${media.laptop`
        height: 70px;
    `}
    ${media.tablet`
        height: 90px;
    `}
 }
`
export const Label = styled.div`
 font-size: ${theme.fonts.size.normal};
 color: ${theme.color.secondaryColor};
 margin-top: 8px;
 ${media.laptop`
    font-size: ${theme.fonts.size.small};
 `}
 ${media.tablet`
    font-size: ${theme.fonts.size.normal};
 `}
${media.mobileM`
    font-size: ${theme.fonts.size.small};
`}
`

export default OptionLabel
