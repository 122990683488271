import styled from 'styled-components'
import { media } from '../../../../../styles/_variables'

export const WidthContainer = styled.div`
  width: 45%;
  min-width: 300px;
  ${media.desktop`
    width: 55%;
  `}
  ${media.laptopL`
    width: 60%;
  `}
  ${media.laptop`
    width: 80%;
  `}
`
export const FullWidthContainer = styled.div`
  margin-top: 32px;
  width: 100%;
`

export default WidthContainer
