import React, { useState, useEffect } from 'react'
import {
  RadioDefault, CheckboxDefault, H5,
} from 'konsys-ui'
import {
  RadioWrapper, CheckboxWrapper, RadioCusWrapper,
} from './styled'

export const RadioCus = props => (
  <RadioCusWrapper disabled={props.disabled}>
    <input
      type="radio"
      {...props}
    />
    <label htmlFor={props.id}>
      <span></span>
      <H5 margin='0 0 0 8px'>{props.data.text}</H5>
    </label>
  </RadioCusWrapper>
)

export const Radio = (props) => {
  const [checked, setChecked] = useState(props.defaultChecked === props.data.value)
  useEffect(() => {
    setChecked(props.defaultChecked === props.data.value)
  })
  return (
    <RadioWrapper onClick={() => props.setDefaultChecked(props.data.value)}>
      <RadioDefault name={props.data.name} data={props.data} checked={checked} {...props} />
    </RadioWrapper>
  )
}

export const Checkbox = ({ data, setDefaultChecked, forceChecked }) => (
  <CheckboxWrapper>
    <CheckboxDefault onChange={e => setDefaultChecked(e.target.checked)} name={data.name} data={data} checked={forceChecked} />
  </CheckboxWrapper>
)


export default Radio
