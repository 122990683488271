import styled from 'styled-components'

export const UploadWrapper = styled.form`
  border: 1px dashed #B8B8B8;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 32px;
  position: relative;
  > input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
  }
  > label {
    width: 100%;
    height: 100%;
    text-align: center;
    button {
      padding: 0px 24px;
      > p {
        font-size: 25px;
      }
    }
  }
`

export default UploadWrapper
