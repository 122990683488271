import React, { useState } from 'react'
import { message } from 'antd'
import {
  WidthContainer, FullWidthContainer, Label,
} from './styled'
import PrintingTitleInput from '../title'
import PrintingDescriptionInput from '../description'
import PrintingTypeTable from '../printing-type-table'
import PrintingTable from '../printing-table'
import PrintingOperationCostTable from '../printing-operation-cost-table'


const PrintingForm = ({
  formValues,
  setFormValues,
  isValidate,
  setIsValidate,
}) => {
  const [editingKey, setEditingKey] = useState(false)
  return (
    <>
      <WidthContainer>
        <PrintingTitleInput
          label={'ชื่อวิธีการพิมพ์'}
          data={formValues}
          onInputChange={setFormValues}
          setIsValidate={setIsValidate}
          isValidate={isValidate}
        />
        <PrintingDescriptionInput
          data={formValues}
          onInputChange={setFormValues}
        />
      </WidthContainer>
      <FullWidthContainer>
        <PrintingTypeTable
          title="ประเภทเครื่องพิมพ์"
          data={formValues.printingType}
          onSubmitEditRow={(key, value) => {
            const tmp = [...formValues.printingType]
            tmp[key] = value
            setFormValues({ ...formValues, printingType: tmp })
          }}
        />
        <br/>
        <PrintingTable
          title="ตัวเลือกรูปแบบสีการพิมพ์"
          columns={[{
            title: 'ตัวเลือกรูปแบบสีการพิมพ์',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            alignCell: 'center',
            inputType: 'text',
            editable: true,
          }, {
            title: 'จำนวนสีการพิมพ์ (สี)',
            dataIndex: 'colorQuantity',
            key: 'colorQuantity',
            align: 'center',
            alignCell: 'center',
            inputType: 'number',
            maxNumber: 4,
            editable: true,
          }]}
          data={formValues.printingBasicColor}
          onSubmitEditRow={async (key, value) => {
            const tmpPrintingBasicColor = [...formValues.printingBasicColor]
            tmpPrintingBasicColor[key] = value

            // const tmpPrintingExtraColor = [...formValues.printingExtraColor]
            // const totalColorPrintingBasicColor = await tmpPrintingBasicColor.reduce((accumulator, object) => accumulator + object.colorQuantity, 0)
            // const totalColor = totalColorPrintingBasicColor + tmpPrintingExtraColor.length

            // const tmpPrintingPlateColorCost = [...formValues.printingPlateColorCost]
            // const printingPlateColorCost = await Array(totalColor).fill(0).map((item, i) => ({
            //   colorQuantity: i + 1,
            //   costPrintMachine3: (tmpPrintingPlateColorCost[i] && tmpPrintingPlateColorCost[i].costPrintMachine3) || 0,
            //   costPrintMachine5: (tmpPrintingPlateColorCost[i] && tmpPrintingPlateColorCost[i].costPrintMachine5) || 0,
            //   key: i,
            // }))
            setFormValues({ ...formValues, printingBasicColor: tmpPrintingBasicColor })
          }}
          onAddRow={value => setFormValues({ ...formValues, printingBasicColor: value })}
          onSubmitDeleteRow={value => setFormValues({ ...formValues, printingBasicColor: value })}
          typeTable={'printingBasicColorId'}
        />
        <PrintingTable
          title="ตัวเลือกสีพิเศษ"
          columns={[{
            title: 'ตัวเลือกสีพิเศษ',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            alignCell: 'center',
            inputType: 'text',
            editable: true,
          }, {
            title: '',
            dataIndex: 'blank',
            key: 'blank',
            align: 'center',
            alignCell: 'center',
            inputType: 'text',
            editable: false,
          }]}
          data={formValues.printingExtraColor}
          onSubmitEditRow={(key, value) => {
            const tmpPrintingExtraColor = [...formValues.printingExtraColor]
            tmpPrintingExtraColor[key] = value

            // const tmpPrintingBasicColor = [...formValues.printingBasicColor]
            // const totalColorPrintingBasicColor = tmpPrintingBasicColor.reduce((accumulator, object) => accumulator + object.colorQuantity, 0)
            // const totalColor = totalColorPrintingBasicColor + tmpPrintingExtraColor.length

            // const tmpPrintingPlateColorCost = [...formValues.printingPlateColorCost]
            // const printingPlateColorCost = []
            // Array(totalColor).fill(0).forEach((item, i) => printingPlateColorCost.push({
            //   colorQuantity: i + 1,
            //   costPrintMachine3: (tmpPrintingPlateColorCost[i] && tmpPrintingPlateColorCost[i].costPrintMachine3) || 0,
            //   costPrintMachine5: (tmpPrintingPlateColorCost[i] && tmpPrintingPlateColorCost[i].costPrintMachine5) || 0,
            //   key: i,
            // }))
            setFormValues({ ...formValues, printingExtraColor: tmpPrintingExtraColor })
          }}
          onAddRow={value => setFormValues({ ...formValues, printingExtraColor: value })}
          onSubmitDeleteRow={value => setFormValues({ ...formValues, printingExtraColor: value })}
          typeTable={'printingExtraColorId'}
        />
        <PrintingTable
          title="ต้นทุนแม่พิมพ์สี (Printing Plate Costs)"
          columns={[{
            title: 'จำนวนสี',
            dataIndex: 'colorQuantity',
            key: 'colorQuantity',
            align: 'center',
            alignCell: 'center',
            inputType: 'number',
            editable: true,
          }, {
            title: 'ต้นทุนสำหรับเครื่องพิมพ์ตัด 5 (บาท)',
            dataIndex: 'costPrintMachine5',
            key: 'costPrintMachine5',
            align: 'center',
            alignCell: 'center',
            inputType: 'number',
            maxNumber: 9999,
            editable: true,
          }, {
            title: 'ต้นทุนสำหรับเครื่องพิมพ์ตัด 3 (บาท)',
            dataIndex: 'costPrintMachine3',
            key: 'costPrintMachine3',
            align: 'center',
            alignCell: 'center',
            inputType: 'number',
            maxNumber: 9999,
            editable: true,
          }]}
          data={formValues.printingPlateColorCost}
          onSubmitEditRow={(key, value) => {
            const tmp = [...formValues.printingPlateColorCost]
            tmp[key] = value
            setFormValues({ ...formValues, printingPlateColorCost: tmp })
          }}
          onAddRow={value => setFormValues({ ...formValues, printingPlateColorCost: value })}
          onSubmitDeleteRow={value => setFormValues({ ...formValues, printingPlateColorCost: value })}
          typeTable={'printingPlateColorCostId'}
        />
        <Label>ต้นทุนการพิมพ์ (Printing Operation Cost)</Label>
        <PrintingOperationCostTable
          title="1. การพิมพ์ 1 สี"
          data={formValues.printingOperationCostOneColor}
          onSubmitEditRow={(key, value) => {
            const tmp = [...formValues.printingOperationCostOneColor]
            tmp[value.index] = value
            setFormValues({ ...formValues, printingOperationCostOneColor: tmp })
          }}
          editingKey={editingKey}
          setEditingKey={setEditingKey}
        />
        <br/>
        <PrintingOperationCostTable
          title="2. การพิมพ์ 2-4 สี"
          data={formValues.printingOperationCostFewColors}
          onSubmitEditRow={(key, value) => {
            const tmp = [...formValues.printingOperationCostFewColors]
            tmp[value.index] = value
            setFormValues({ ...formValues, printingOperationCostFewColors: tmp })
          }}
          editingKey={editingKey}
          setEditingKey={setEditingKey}
        />
        <br/>
        <PrintingOperationCostTable
          title="3. การพิมพ์สีพิเศษ (ต่อสีพิเศษ 1 สี)"
          data={formValues.printingOperationCostExtraColor}
          onSubmitEditRow={(key, value) => {
            const tmp = [...formValues.printingOperationCostExtraColor]
            tmp[value.index] = value
            setFormValues({ ...formValues, printingOperationCostExtraColor: tmp })
          }}
          editingKey={editingKey}
          setEditingKey={setEditingKey}
        />
      </FullWidthContainer>
    </>
  )
}

export default PrintingForm
