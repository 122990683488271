import styled from 'styled-components'
import Img from '../common/img'
import { theme } from '../../styles/_variables'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: ${props => (props.bg ? `url(${props.bg})` : 'url(\'http://www.unsplash.it/1200/640\')')};
  background-color: rgba(255, 255, 255, 0.9);
  background-size: cover;
`

export const LoginBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  background-color: ${theme.color.black};
  border-radius: 8px;
  flex-direction: column;
  padding: 40px;
  box-shadow: rgb(56, 53, 48) 0px 0px 50px;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.cover})`};
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: relative;
`

export const Image = styled(Img)`
  width: 50%;
`

export default Wrapper
