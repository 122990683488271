import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import {
  Button,
  Div,
  H4,
  ButtonForm,
} from 'konsys-ui'
import { theme, media, minMedia } from '../../../styles/_variables'


export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0 0;
`

export const Line = styled.hr`
  border-top: solid 1px rgba(192, 191, 193, 0.3);
  margin: 40px 0;
`

export const DeleteButton = styled(Button)`
  border: 1px solid ${theme.color.error}33;
  background-color: ${theme.color.error}88;
`

/* STEP 1 */


export const WidthContainer = styled.div`
  width: 100%;
  max-width: ${theme.maxWidth};
  ${media.tablet`
    width: 100%;
    max-width: 100%;
  `}
  h3 {
    font-weight: 600;
    ${media.tablet`
      font-size: ${theme.fonts.size.subTitle};
    `}
  }
  h4 {
    ${media.tablet`
      font-size: ${theme.fonts.size.extraNormal};
    `}
  }
`

export const RadioSelectWithDetailWrapper = styled.div``

export const ChoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0 0;
  position: relative;
  > ${RadioSelectWithDetailWrapper} {
    margin: 0 0 16px;
    transition: all 0.8s ease;
  }
  ${media.tablet`
    flex-direction: row;
    margin: 8px 0 0;
    padding: 0;
    > ${RadioSelectWithDetailWrapper} {
      &:first-child {
        margin: 0 16px 0 0;
      }
      > div {
        margin-top: 8px;
      }
    }
    &#step1-2 {
      position: relative;
      padding-bottom: 285px;
      > ${RadioSelectWithDetailWrapper} > div:last-child {
        margin: 0;
        position: absolute;
        top: 63px;
        left: 0;
      }
    }
    &#step1-1 {
      position: relative;
      padding-bottom: 96px;
      > ${RadioSelectWithDetailWrapper} > div:last-child {
        margin: 0;
        position: absolute;
        top: 63px;
        left: 0;
      }
    }
    &#step2 {
      flex-wrap: wrap;
      > div {
        margin: 0;
      }
    }
  `}
  ${media.mobileL`
    flex-direction: column;
    padding: 0;
    padding-bottom: 0 !important;
    > ${RadioSelectWithDetailWrapper} {
      &:first-child {
        margin: 8px 0 0;
      }
      > div {
        width: 100%;
        position: relative !important;
        top: 0 !important;
        margin: 8px 0 0 !important;
      }
    }
  `}
`

export const ChoiceContent = styled.div`
  background-color: #B8C9C7;
  transition: max-height 0.8s ease;
  opacity: ${props => (props.checked ? 1 : 0)};
  max-height: ${props => (props.checked ? '2000px' : 0)};
  overflow: hidden;
  padding: ${props => (props.checked ? '56px 32px 32px' : 0)};
  margin: ${props => (props.checked ? '-24px 0 0 72px' : '0 0 0 72px')};
  ${media.tablet`
    position: relative;
    top: 0;
    padding: ${props => (props.checked ? '16px' : 0)};
    margin: ${props => (props.checked ? '8px 0 16px' : 0)};
    width: 100%;
  `}
`

export const DetailWrapper = styled(Link)`
  box-shadow: 3px 3px 0 1px rgba(${theme.color.primaryColorRGB}, 0.7) !important;
  display: block;
  background-color: ${theme.color.primaryColor};
  background-position: top right;
  background-size: cover;
  padding: 16px;
  margin: 24px 0;
  text-align: center;
`

export const DetailWrapperA = styled.a`
  box-shadow: 3px 3px 0 1px rgba(${theme.color.primaryColorRGB}, 0.7) !important;
  display: block;
  background-color: ${theme.color.primaryColor};
  background-position: top right;
  background-size: cover;
  padding: 16px;
  margin: 24px 0;
  text-align: center;
`

export const MutationResultDetailWrapper = styled.div`
  button {
    > p {
      font-size: ${theme.fonts.size.subTitle};
    }
  }
  ${media.tablet`
    > * {
      text-align: center;
    }
  `}
`

export const ImgBox = styled.img`
  width: 85%;
  ${media.tablet`
    width: 70%;
  `}
`

export const MessageInputWrapper = styled(Div)`
  > div > div:last-child {
    text-align: left;
    margin: 4px 0 0;
    > span {
      font-size: ${theme.fonts.size.small};
      color: ${theme.color.lightGrey};
      font-family: ${theme.fonts.family.normal};
    }
  }
`

export const MutationResultWrapper = styled(Div)`
  ${media.tablet`
    flex-direction: column;
    justify-content: center;
    > div:first-child {
      text-align: center;
      margin: 0 0 16px;
    }
  `}
`

export const FullWidthContainer = styled.div`
  // width: fit-content;
  margin: 0 auto;
  margin-left: -60px;
  margin-right: -60px;
`

export const WidthPartContainer = styled.div`
  width: 100%;
  margin-left: 50%;
  max-width: 64vw;
  transform: translateX(-50%);
  margin-top: -60px;
  margin-bottom: -60px;
  ${props => props.bgColor && css`
    background-color: ${props.bgColor};
  `}
  ${minMedia.laptopL`
    max-width: 59vw;
  `}
  ${media.desktop`
    max-width: 100%;
    padding: 0px 60px;
  `}
  ${media.tablet`
    width: 100%;
    max-width: 100%;
  `}
`

export const WidthPriceContainer = styled.div`
  width: 100%;
  margin-left: 50%;
  max-width: 64vw;
  transform: translateX(-50%);
  ${props => props.bgColor && css`
    background-color: ${props.bgColor};
  `}
  ${minMedia.laptopL`
    max-width: 59vw;
  `}
  ${media.desktop`
    max-width: 100%;
  `}
  ${media.tablet`
    width: 100%;
    max-width: 100%;
  `}
`

export const Content = styled.div`
  width: 100%;
  border-top: 60px solid ${theme.color.greenPrimary};
  border-bottom: ${props => (props.step === 2 || props.data === 1 ? '0px' : `60px solid ${theme.color.greenPrimary}`)};
`

export const SummaryDiv = styled.div`
  width: 100%;
  margin-left: 12px;
  position: relative;
`

export const ContentPart = styled.div`
  display: inline-flex;
`

export const QuotationCreateDiv = styled.div`
  // width: 100%;
  // margin-top: 1110px;
`

export const QuotationCreateDivDetail = styled.div`
  width: 100%;
  margin-bottom: 60px;
`

export const ActionZone = styled.div`
    width: ${props => (props.step === 1 ? '100%' : '100%')};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${props => (props.step === 1 ? '60px' : '80px')};
    margin-bottom: ${props => (props.step === 1 ? '0px' : '10px')};
    column-gap: 20px;
`

export const ButtonCalculator = styled(Button)`
  width: 300px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: ${theme.fonts.size.title};
  border-radius: 25px;
  padding: 2px 8px !important;
  background-color: ${theme.color.greenPrimary};
  border-color: ${theme.color.greenPrimary};
  box-shadow: 2px 2px 0px 1px rgb(110, 201, 190, 0.4) !important;
  & > p {
    margin: 0px !important;
    font-size: ${theme.fonts.size.title};
  }
`

export const IconCalculate = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`

export const LabelSummary = styled.span`
  color: ${theme.color.primaryColor};
`

export const SummaryDivMobile = styled.div`
  width: 100%;
  padding: 0px 16px 0px 16px;
  position: relative;
  margin-top: 24px;
`

export const Bar = styled(Div)`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled(H4)`
  color: ${theme.color.offWhite};
  ${media.mobileL`
    margin-left: -60px;
    font-size: ${theme.fonts.size.extraNormal};
  `}
  ${media.mobileM`
    margin-left: -60px;
    font-size: ${theme.fonts.size.normal};
  `}
`

export const ButtonBack = styled(Button)`
  padding: 8px 0px;
  background-color: ${theme.color.greenPrimary};
  border-color: ${theme.color.greenPrimary};
  ${media.mobileL`
    margin-left: 0px;
  `}
  > p {
    ${media.mobileM`
      font-size: ${theme.fonts.size.label};
    `}
  }
`

export const ButtonFormAction = styled(ButtonForm)`
  margin: 0px;
  padding: 8px 24px;
  white-space: nowrap;
  align-items: center;
  display: flex;
  & > span {
    font-size: ${theme.fonts.size.subTitle} !important;
    ${media.mobileL`
      font-size: ${theme.fonts.size.small} !important;
    `}
  }
  & > i {
    font-size: ${theme.fonts.size.subTitle} !important;
    width: 30px !important;
    height: 30px !important;
    ${media.mobileL`
      width: 12px !important;
      height: 12px !important;
      font-size: 12px !important;
      margin: 0px 4px 0px 0px !important;
    `}
  }
  ${media.mobileL`
    margin-left: 0px;
    padding: 8px 12px;
  `}
  
`

export default HeaderWrapper
