/* eslint-disable radix */
import React from 'react'
import { Query } from 'react-apollo'
import _ from 'lodash'
import {
  Div, LoadingIcon, H4,
} from 'konsys-ui'
import PATH from '../../../constants/path'
import Banner from '../../../images/packaging-catalog/banner.jpg'
import { theme } from '../../../styles/_variables'
import Container from '../../../components/container'
import { GET_QUOTATION } from '../../../constants/graphql/query'
import BreadCrumb from '../../../components/common/breadcrumb'
import {
  WidthContainer,
} from './styled'
import { EditPredefinedForm } from '../detail'

const OrderPredefineEditItem = ({
  match,
}) => (
  <Query
    query={GET_QUOTATION}
    fetchPolicy='network-only'
    variables={{
      id: parseInt(match.params.id, 10),
    }}
    notifyOnNetworkStatusChange
  >
    {
      ({ loading, data }) => {
        if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
        if (!data.requestById) {
          return (
            <Container header={{
              type: 'title',
              img: Banner,
              title: 'ชื่อบรรจุภัณฑ์',
              color: theme.color.offWhite,
            }}>
              <WidthContainer>
                <Div moreStyle={{ paddingTop: '148px', paddingBottom: '200px' }}>
                  <H4>ขออภัยค่ะ {data.packaging.message}</H4>
                </Div>
              </WidthContainer>
            </Container>
          )
        }
        const omitData = _.omit(data.requestById, data.requestById.isPreDefine ? [
          'address',
          'shippingAddress',
          '__typename',
          'status',
          'length',
          'height',
          'paperThickness',
          'adminNote',
          'taxRate',
          'totalPrice',
          'createdAt',
          'quotationPDF',
          'partDetail',
          'totalCost',
          'incrementalSteps',
          'packageId',
          'minimumManufacture',
          'paperType',
          'paperThickness',
          'paperTypeText',
          'printingTechniqueText',
        ] : [
          'address',
          'shippingAddress',
          '__typename',
          'status',
          'length',
          'height',
          'paperThickness',
          'adminNote',
          'taxRate',
          'totalPrice',
          'createdAt',
          'quotationPDF',
          'partDetail',
          'totalCost',
          'incrementalSteps',
          'packageId',
          'minimumManufacture',
        ])
        const packageValues = {
          quotationId: data.requestById.quotationId,
          packageId: data.requestById.packageId,
          produceQuantity: data.requestById.produceQuantity,
          produceType: data.requestById.produceType === 'สั่งผลิตใหม่' ? 'newOrder' : 'repeatOrder',
          incrementalSteps: data.requestById.incrementalSteps,
          minimumManufacture: data.requestById.minimumManufacture,
          graphicName: data.requestById.isGraphic,
          graphicFile: data.requestById.sampleFiles,
          moreDetail: data.requestById.printingDescription,
          partData: data.requestById && data.requestById.partDetail.map(item => ({
            packagePartId: item.packagePartId || null,
            packagePartName: item.packagePartName || '-',
            packageData: {
              name: omitData.name || '-',
            },
            sizeList: {
              a: _.isNumber(item.sizeA) ? item.sizeA : -1,
              b: _.isNumber(item.sizeB) ? item.sizeB : -1,
              c: _.isNumber(item.sizeC) ? item.sizeC : -1,
              d: _.isNumber(item.sizeD) ? item.sizeD : -1,
              e: _.isNumber(item.sizeE) ? item.sizeE : -1,
              f: _.isNumber(item.sizeF) ? item.sizeF : -1,
              g: _.isNumber(item.sizeG) ? item.sizeG : -1,
              h: _.isNumber(item.sizeH) ? item.sizeH : -1,
              i: _.isNumber(item.sizeI) ? item.sizeI : -1,
              j: _.isNumber(item.sizeJ) ? item.sizeJ : -1,
            },
            measurementUnit: {
              label: item.unitSize === 'cm' ? 'ซม.' : 'นิ้ว',
            },
            paper: {
              paperId: item.paperId,
              name: item.paperType,
              selectedThickness: item.paperThickness,
            },
            coating: {
              side: (item.coatingFront && item.coatingBehind) ? 2 : (!item.coatingFront && item.coatingBehind) ? -1 : (item.coatingFront && !item.coatingBehind) ? 1 : 0,
              coatingType: (item.coatingFront && item.coatingBehind) ? 'เคลือบสองหน้า' : (!item.coatingFront && item.coatingBehind) ? 'เคลือบด้านหลัง' : (item.coatingFront && !item.coatingBehind) ? 'เคลือบด้านหน้า' : 'ไม่เคลือบ',
              coatingFrontName: item.coatingFront,
              coatingBackName: item.coatingBehind,
              coatingFrontId: item.coatingFrontId,
              coatingBackId: item.coatingBehindId,
            },
            spotUV: {
              side: (item.spotUVFront && item.spotUVBehind) ? 2 : (!item.spotUVFront && item.spotUVBehind) ? -1 : (item.spotUVFront && !item.spotUVBehind) ? 1 : 0,
              spotUVType: (item.spotUVFront && item.spotUVBehind) ? 'เคลือบสองหน้า' : (!item.spotUVFront && item.spotUVBehind) ? 'เคลือบด้านหลัง' : (item.spotUVFront && !item.spotUVBehind) ? 'เคลือบด้านหน้า' : 'ไม่เคลือบ',
              spotUVFrontName: item.spotUVFront,
              spotUVBackName: item.spotUVBehind,
              spotUVFrontId: item.spotUVFrontId,
              spotUVBackId: item.spotUVBehindId,
            },
            embossing: item.quotationEmbossing.map(x => ({
              ...x,
              lengths: x.length,
            })),
            foilingId: item.foilingId,
            foiling: item.quotationFoiling.map(x => ({
              ...x,
              lengths: x.length,
            })),
            plasticWindow: item.quotationPlasticWindow.map(x => ({
              ...x,
              lengths: x.length,
            })),
            printing: {
              printingName: item.printingName,
              printingTypeName: item.printingSide === 1 ? 'พิมพ์หน้าเดียว' : item.printingSide === 2 ? 'พิมพ์สองหน้า' : '',
              printingBasicColorName: item.printingBasicColor,
              printingBasicColorId: item.printingBasicColorId,
              printingExtraColorId: item.quotationPrintingExtraColor.map(x => ({ printingExtraColorName: x.printingExtraColor, printingExtraColorId: x.printingExtraColorId })),
            },
            gluing: item.gluing && {
              gluingId: item.gluingId,
              gluing: item.gluing,
            },
            additionalService: item.quotationAdditionalService.length > 0 ? item.quotationAdditionalService.map(x => ({ additionalServiceId: x.additionalServiceId, name: x.name })) : [],
            dieCut: item.dieCut,
            gluingId: item.gluingId,
            additionalServiceId: [],
          })),
        }
        return (
        <>
          <BreadCrumb link={{
            parent: [
              {
                text: 'Order ทั้งหมด',
                link: PATH.quotationList,
              },
              {
                text: `รายละเอียด ${omitData.refId}`,
                link: `${PATH.quotationDetail}/${match.params.id}`,
              },
            ],
            current: {
              text: `แก้ไขข้อมูลบรรจุภัณฑ์ ${omitData.name}`,
            },
          }} />
          <EditPredefinedForm
            packageValues={packageValues}
          />
        </>
        )
      }
    }
  </Query>
)


export default OrderPredefineEditItem
