import React, { useState } from 'react'
import _ from 'lodash'
import { Query } from 'react-apollo'
import {
  Div, Button,
} from 'konsys-ui'
import {
  Select, message,
} from 'antd'
import { LIST_ADDITIONAL_SERVICE } from '../../../../constants/graphql/query'
import { theme } from '../../../../styles/_variables'
import {
  Label, Box, LabelExtraService,
  ActionBox, ActionButton,
} from './styled'


const AdditionalService = ({
  number,
  formValues,
  setPartData,
}) => {
  const [listOptionAdditionalService, setListOptionAdditionalService] = useState([])
  const handleChange = (value, index, additionalServiceList) => {
    if (formValues.partData) {
      const tmpPartData = formValues.partData
      const result = additionalServiceList.find(item => item.additionalServiceId === value)
      result.active = true
      result.is_plus = true
      tmpPartData[number].additionalService[index] = result
      setPartData({
        ...formValues,
        partData: tmpPartData,
      })
      const tmpListOptionAdditionalService = additionalServiceList.map(item => ({
        ...item,
        is_plus: true,
        active: formValues.partData && !_.isEmpty(formValues.partData[number].additionalService.find(x => x.additionalServiceId === item.additionalServiceId)),
      }))
      setListOptionAdditionalService(tmpListOptionAdditionalService)
    }
  }
  const handleAdd = (additionalServiceList, index) => {
    if (formValues.partData) {
      const tmpPartData = formValues.partData
      const tmpList = [...tmpPartData[number].additionalService]
      tmpList.push(additionalServiceList ? {
        ...listOptionAdditionalService.find(x => !x.active),
        is_plus: additionalServiceList.length > tmpPartData[number].additionalService.length + 1,
        active: true,
      } : {
        additionalServiceId: -1,
        description: '',
        name: 'ไม่มีบริการเสริม',
      })
      tmpPartData[number].additionalService = tmpList
      const impId = tmpList[tmpList.length - 1].additionalServiceId
      const tmpListAddtionalService = listOptionAdditionalService.map(item => ({
        ...item,
        active: item.additionalServiceId === impId ? true : item.active,
      }))
      setListOptionAdditionalService(tmpListAddtionalService)
      if (index >= 0) tmpPartData[number].additionalService[index].is_plus = false
      if (additionalServiceList.length >= tmpPartData[number].additionalService.length) {
        setPartData({
          ...formValues,
          partData: tmpPartData,
        })
      }
    }
  }
  const handleRemove = (index) => {
    if (formValues.partData) {
      const tmpPartData = formValues.partData
      const tmpAdditionalList = [...tmpPartData[number].additionalService]

      const impId = tmpPartData[number].additionalService[index].additionalServiceId
      const tmpListAddtionalService = listOptionAdditionalService.map(item => ({
        ...item,
        active: item.additionalServiceId === impId ? false : item.active,
      }))
      setListOptionAdditionalService(tmpListAddtionalService)

      _.remove(tmpAdditionalList, (v, i) => i === index)
      if (tmpAdditionalList.length > 0 && index >= 0) tmpAdditionalList[tmpAdditionalList.length - 1].is_plus = true
      tmpPartData[number].additionalService = tmpAdditionalList
      setPartData({
        ...formValues,
        partData: tmpPartData,
      })
    }
  }
  return (
    <Div>
      <Label>บริการเสริมอื่นๆ</Label>
      <Query
        query={LIST_ADDITIONAL_SERVICE}
        fetchPolicy='network-only'
        variables={{
          limit: 10000,
          offset: 0,
          order: 'createdAt',
          sort: 'ASC',
        }}
        notifyOnNetworkStatusChange
        onCompleted={(data) => {
          const { additionalService } = data.listAdditionalService
          const result = additionalService.map(item => ({
            ...item,
            is_plus: true,
            active: formValues.partData && !_.isEmpty(formValues.partData[number].additionalService.find(x => x.additionalServiceId === item.additionalServiceId)),
          }))
          setListOptionAdditionalService(result)
        }}
      >
      {
      ({ data }) => (<>
        { data.listAdditionalService && data.listAdditionalService.success && formValues.partData && formValues.partData[number].additionalService.length === 0 && <Button
            text='เพิ่มบริการเสริม'
            icon='fa fa-plus'
            onClick={() => {
              if (data.listAdditionalService.additionalService.length > 0) {
                handleAdd(data.listAdditionalService.additionalService, -1)
              } else {
                message.warning('ไม่มีบริการเสริมอื่นๆ')
              }
            }}
          />
        }
        {
          data.listAdditionalService && data.listAdditionalService.success && formValues.partData && formValues.partData[number].additionalService.map((item, index) => (
            <Div key={index} display='flex' flexDirection='row' justifyContent='space-between' moreStyle={{
              marginBottom: '12px',
            }}>
              <Box>
                <Div flexBasis="50%">
                  <LabelExtraService>บริการเสริม {index + 1}</LabelExtraService>
                </Div>
                <Div flexBasis="50%">
                  <Select
                    style={{ width: '100%', fontSize: theme.fonts.size.normal }}
                    value={item.additionalServiceId}
                    onChange={value => handleChange(value, index, data.listAdditionalService.additionalService)}
                  >
                    {
                      data.listAdditionalService && data.listAdditionalService.success && listOptionAdditionalService.length > 0 && listOptionAdditionalService.map(v => (
                        <Select.Option disabled={v.active} key={v.additionalServiceId} value={v.additionalServiceId}>{v.name}</Select.Option>
                      ))
                    }
                  </Select>
                </Div>
              </Box>
              <ActionBox>
                <Div moreStyle={{
                  float: 'left',
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px',
                }}>
                  <ActionButton
                    icon='fa fa-minus'
                    onlyIcon
                    inverse
                    small
                    buttonStyle="round"
                    onClick={() => {
                      handleRemove(index)
                    }}
                  />
                </Div>
                <Div moreStyle={{
                  float: 'right',
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px',
                }}>
                  {
                    formValues.partData[number].additionalService.length - 1 === index && <ActionButton
                      icon='fa fa-plus'
                      onlyIcon
                      inverse
                      small
                      buttonStyle="round"
                      disabled={data.listAdditionalService.additionalService.length - 1 === index}
                      onClick={() => {
                        if (data.listAdditionalService.additionalService.length - 1 !== index) {
                          handleAdd(data.listAdditionalService.additionalService, index)
                        }
                      }}
                    />
                  }
                </Div>
              </ActionBox>
            </Div>
          ))
        }
      </>)
      }
      </Query>
    </Div>
  )
}
export default AdditionalService
