import React from 'react'
import {
  Div,
} from 'konsys-ui'
import Transfer from '../../../../../components/common/transfer'
import {
  Label, DivTitle,
} from './styled'

const PaperFrontCoating = ({
  data = [],
  onChange,
}) => (
    <Div>
      <DivTitle>
        <Label>{'ตัวเลือกการเคลือบผิวกระดาษด้านหน้า'}</Label>
      </DivTitle>
      <Transfer dataSource={data} onChange={onChange}/>
    </Div>
)


export default PaperFrontCoating
