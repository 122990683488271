import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { message } from 'antd'
import {
  Div, H4, LoadingIcon, Button,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import PATH from '../../../constants/path'
import { theme } from '../../../styles/_variables'
import { GET_PLASTIC_WINDOW_DETAIL } from '../../../constants/graphql/query'
import { ADD_PLASTIC_WINDOW, EDIT_PLASTIC_WINDOW } from '../../../constants/graphql/mutation'
import PlasticForm from './components/plastic-form'
import BreadCrumb from '../../../components/common/breadcrumb'
import RecommendationSetting from '../recommendation-setting'


const { color } = theme
const Plastic = ({
  history,
}) => {
  const [isValidate, setIsValidate] = useState(true)
  const [hasPlastic, setHasPlastic] = useState(false)
  const [hasQuery, setHasQuery] = useState(false)
  const [formValues, setFormValues] = useState({
    edit: false,
    name: '',
    description: '',
    sampleVideo: [],
    imagePhoto: [],
    thickness: [],
    plasticWindowId: null,
  })

  useEffect(() => {
    localStorage.removeItem('plasticWindowThicknessForm')
    localStorage.removeItem('plasticWindowThicknessSelected')
  }, [])

  return (
    <Div>
      <Query
        query={GET_PLASTIC_WINDOW_DETAIL}
        notifyOnNetworkStatusChange
        onCompleted={(data) => {
          const plasticWindowData = data.getPlasticWindowDetail.plasticWindow
          if (plasticWindowData) {
            setHasPlastic(true)
            setHasQuery(true)
            const thickness = plasticWindowData.plasticWindowThickness.reduce((result, item) => {
              result.push({
                key: result.length,
                thickness: [item.thickness],
                value: item,
              })
              return result
            }, [])
            const tmpLocalForm = JSON.parse(localStorage.getItem('plasticWindowThicknessForm'))
            const tmpFormvalues = tmpLocalForm || {
              ...formValues,
              edit: true,
              plasticWindowId: plasticWindowData.plasticWindowId,
              name: plasticWindowData.name,
              description: plasticWindowData.description,
              sampleVideo: plasticWindowData.plasticWindowFiles.filter(file => file.type === 'video'),
              imagePhoto: plasticWindowData.plasticWindowFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample'),
              thickness,
            }
            setFormValues(tmpFormvalues)
            localStorage.setItem('plasticWindowThicknessForm', JSON.stringify(tmpFormvalues))
          } else {
            setHasQuery(true)
            localStorage.setItem('plasticWindowThicknessForm', JSON.stringify({
              name: '',
              description: '',
              sampleVideo: [],
              imagePhoto: [],
              thickness: [],
              plasticWindowId: null,
            }))
          }
        }}
      >
        {
          ({ loading, data, refetch }) => {
            if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
            if (!data.getPlasticWindowDetail.success) return <p>error something</p>
            const plasticRemark = data.getPlasticWindowDetail.plasticWindow.remark
            return (
              <>
                <Div display='flex' flexDirection='row' justifyContent='space-between' margin='32px 0px 0px 0px' alignItems='center'>
                  <Div display='flex' flexDirection='column'>
                    <H4 bold color={color.primaryColor}>
                      <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                        <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                      </Link> {' > '} การเจาะหน้าต่างและติดแผ่นใส
                    </H4>
                  </Div>
                  <SaveButton
                    isCreated={!hasPlastic}
                    formValues={formValues}
                    setIsValidate={setIsValidate}
                    history={history}
                    plasticWindowId={data.getPlasticWindowDetail.plasticWindow && data.getPlasticWindowDetail.plasticWindow.plasticWindowId}
                  />
                </Div>
                <RecommendationSetting
                  moduleName='plastic'
                  data={plasticRemark}
                  refetch={() => refetch()}
                />
                {
                  !hasPlastic ? <PlasticForm
                    formValues={formValues}
                    setFormValues={setFormValues}
                    isValidate={isValidate}
                    setIsValidate={setIsValidate}
                    action={'create'}
                    history={history}
                  /> : (data.getPlasticWindowDetail.plasticWindow && formValues.edit) && <PlasticForm
                    formValues={formValues}
                    setFormValues={setFormValues}
                    isValidate={isValidate}
                    setIsValidate={setIsValidate}
                    action={'detail'}
                    history={history}
                  />
                }
              </>
            )
          }
        }
      </Query>
      </Div>
  )
}

const SaveButton = ({
  isCreated,
  formValues,
  setIsValidate,
  plasticWindowId,
  history,
}) => {
  const [callMutation, setCallMutation] = useState(false)
  return (
    <>
    {
      isCreated ? <Mutation mutation={ADD_PLASTIC_WINDOW}>
        {doAddPlasticWindow => (
        <Button
          text='บันทึก'
          icon='fa fa-save'
          inverse
          onClick={() => {
            setCallMutation(true)
            if (formValues.name === '') {
              message.warning('กรุณากรอกข้อมูลชื่อการเจาะหน้าต่างและติดแผ่นใส')
              setCallMutation(false)
              setIsValidate(false)
              return
            }

            if (formValues.name.match(new RegExp(/^\s/)) !== null) {
              message.warning('กรุณากรอกข้อมูลชื่อการเจาะหน้าต่างและติดแผ่นใสให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
              setCallMutation(false)
              setIsValidate(false)
              return
            }
            const input = {
              plasticWindowId,
              name: formValues.name,
              description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
              video: formValues.sampleVideo,
              imagePhoto: formValues.imagePhoto,
            }
            doAddPlasticWindow({
              variables: {
                input,
              },
            }).then(({ data }) => {
              if (data.addPlasticWindow) {
                setCallMutation(false)
                message.success(data.addPlasticWindow.message)
                setTimeout(() => {
                  localStorage.removeItem('plasticWindowThicknessForm')
                  localStorage.removeItem('plasticWindowThicknessSelected')
                  window.location = PATH.plasticSetting
                }, 350)
              } else {
                setCallMutation(false)
                if (data.addPlasticWindow.message === 'ชื่อ การเจาะหน้าต่างและติดแผ่นใส นี้มีอยู่ในระบบแล้ว') {
                  setIsValidate(false)
                  setCallMutation(false)
                }
                message.error(data.addPlasticWindow.message)
              }
              setCallMutation(false)
            })
          }}
          disabled={callMutation}
          loading={callMutation}
        />)}
        </Mutation> : formValues.edit && <Mutation mutation={EDIT_PLASTIC_WINDOW}>
        {doEditPlasticWindow => (
        <Button
          text='บันทึก'
          icon='fa fa-save'
          inverse
          onClick={() => {
            setCallMutation(true)
            if (formValues.name === '') {
              message.warning('กรุณากรอกข้อมูลชื่อการเจาะหน้าต่างและติดแผ่นใส')
              setCallMutation(false)
              setIsValidate(false)
              return
            }

            if (formValues.name.match(new RegExp(/^\s/)) !== null) {
              message.warning('กรุณากรอกข้อมูลชื่อการเจาะหน้าต่างและติดแผ่นใสให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
              setCallMutation(false)
              setIsValidate(false)
              return
            }
            const input = {
              plasticWindowId,
              name: formValues.name,
              description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
              video: formValues.sampleVideo,
              imagePhoto: formValues.imagePhoto,
            }
            doEditPlasticWindow({
              variables: {
                input,
              },
            }).then(({ data }) => {
              if (data.editPlasticWindow.success) {
                setCallMutation(false)
                message.success(data.editPlasticWindow.message)
                setTimeout(() => {
                  localStorage.removeItem('plasticWindowThicknessForm')
                  localStorage.removeItem('plasticWindowThicknessSelected')
                  window.location = PATH.plasticSetting
                }, 350)
              } else {
                setCallMutation(false)
                if (data.editPlasticWindow.message === 'ชื่อ การเจาะหน้าต่างและติดแผ่นใส นี้มีอยู่ในระบบแล้ว') {
                  setIsValidate(false)
                  setCallMutation(false)
                }
                message.error(data.editPlasticWindow.message)
              }
              setCallMutation(false)
            })
          }}
          disabled={callMutation}
          loading={callMutation}
        />)}
        </Mutation>
    }
    </>
  )
}

export default Plastic
