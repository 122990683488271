import styled from 'styled-components'


export const CoverImage = styled.div`
    background-size: cover;
    background-image: url(${props => props.imgUrl});
    border-radius: 50%;
`

export const DetailWrapper = styled.div`
    display: flex;
    width: 100%;
    height: calc(100% - 5px);
    flex-flow: column;
    // height: fit-content;
    // height: -moz-fit-content;
    row-gap: 10px;
`


export default CoverImage
