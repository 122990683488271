/* eslint-disable no-restricted-globals */
import React, { useState } from 'react'
import { Query } from 'react-apollo'
import {
  Div, LoadingIcon,
} from 'konsys-ui'

import {
  WidthContainer, FullWidthContainer,
} from './styled'

import { LIST_COATING } from '../../../../../constants/graphql/query'
import PaperTitleInput from '../title'
import PaperDescriptionInput from '../description'
import PaperCoverImageInput from '../cover-image'
import PaperSampleVideoInput from '../sample-video'
import PaperSampleImageInput from '../sample-image'
import PaperTable from '../paper-table'
import PaperFrontCoating from '../front-coating'
import PaperBackCoating from '../back-coating'
import { theme } from '../../../../../styles/_variables'


const CoatingForm = ({
  formValues,
  setFormValues,
  isValidate,
  setIsValidate,
}) => {
  const [, setDisabledButton] = useState(true)

  const handleOnSubmitEditRow = (key, value) => {
    const tmp = [...formValues.paperTable]
    const tmpValue = value
    if (tmpValue.column1 === null || isNaN(tmpValue.column1)) tmpValue.column1 = 0
    if (tmpValue.column2 === null || isNaN(tmpValue.column2)) tmpValue.column2 = 0
    if (tmpValue.column3 === null || isNaN(tmpValue.column3)) tmpValue.column3 = 0
    if (tmpValue.column4 === null || isNaN(tmpValue.column4)) tmpValue.column4 = 0
    if (tmpValue.column5 === null || isNaN(tmpValue.column5)) tmpValue.column5 = 0
    tmp[key] = tmpValue
    setFormValues({ ...formValues, paperTable: tmp })
  }

  const handleOnSubmitDeleteRow = (value) => {
    setFormValues({ ...formValues, paperTable: value })
  }

  const handleOnAddRow = (value) => {
    setFormValues({ ...formValues, paperTable: value })
  }

  return (
    <>
      <WidthContainer>
        <PaperTitleInput
          label={'ชื่อกระดาษ'}
          data={formValues}
          onInputChange={setFormValues}
          setDisabledButton={setDisabledButton}
          setIsValidate={setIsValidate}
          isValidate={isValidate}
        />
        <PaperDescriptionInput
          data={formValues}
          onInputChange={setFormValues}
        />
        <PaperCoverImageInput
          data={formValues}
          newFileList={formValues.coverPhoto}
          setNewFileList={setFormValues}
        />
        <PaperSampleVideoInput
          data={formValues}
          newFileList={formValues.sampleVideo}
          setNewFileList={setFormValues}
        />
        <PaperSampleImageInput
          data={formValues}
          newFileList={formValues.imagePhoto}
          setNewFileList={setFormValues}
        />
      </WidthContainer>
      <FullWidthContainer>
        <PaperTable
          data={formValues.paperTable}
          onSubmitEditRow={handleOnSubmitEditRow}
          onSubmitDeleteRow={handleOnSubmitDeleteRow}
          onAddRow={handleOnAddRow}
        />
      </FullWidthContainer>
      <WidthContainer>
      <Query
        query={LIST_COATING}
        variables={{
          limit: 10000,
          offset: 0,
        }}
        notifyOnNetworkStatusChange
        onCompleted={(data) => {
          const { coating } = data.listCoating
          let frontCoating = []
          if (formValues.defaultFrontCoating.length > 0) {
            const { defaultFrontCoating } = formValues
            frontCoating = coating.map((item) => {
              const active = [...defaultFrontCoating].filter(itemCoatingId => itemCoatingId === item.coatingId).length > 0
              return ({
                key: item.coatingId,
                title: item.name,
                active,
              })
            })
          } else {
            frontCoating = coating.map(item => ({ key: item.coatingId, title: item.name, active: false }))
          }

          let backCoating = []
          if (formValues.defaultBackCoating.length > 0) {
            const { defaultBackCoating } = formValues
            backCoating = coating.map((item) => {
              const active = [...defaultBackCoating].filter(itemCoatingId => itemCoatingId === item.coatingId).length > 0
              return ({
                key: item.coatingId,
                title: item.name,
                active,
              })
            })
          } else {
            backCoating = coating.map(item => ({ key: item.coatingId, title: item.name, active: false }))
          }

          setFormValues({
            ...formValues,
            frontCoating,
            backCoating,
          })
        }}
      >
        {
          ({ loading, data }) => {
            if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
            if (!data.listCoating.success) return <p>error something</p>
            return (<>
              <PaperFrontCoating
                data={formValues.frontCoating}
                onChange={value => setFormValues({ ...formValues, frontCoating: value })}
              />
              <PaperBackCoating
                data={formValues.backCoating}
                onChange={value => setFormValues({ ...formValues, backCoating: value })}
              />
            </>)
          }
        }
      </Query>
      </WidthContainer>
    </>
  )
}

export default CoatingForm
