import styled, { css } from 'styled-components'
import {
  Button,
} from 'konsys-ui'
import { theme, media } from '../../../styles/_variables'

export const NotchDiv = styled.div`
width: fit-content;
padding: 0px 15px 10px 15px;
height: 50px;
font-size: ${theme.fonts.size.subTitle};
color: ${theme.color.secondaryColor}
${media.mobileL`
    padding: 8px 15px 10px 15px;
`}
${media.mobileM`
    font-size: 24px !important;
`}
${media.mobileS`
    font-size: 22px !important;
`}
position: relative;

background: ${theme.color.offWhite};

-webkit-border-top-left-radius: 15px;
-webkit-border-top-right-radius: 15px;
-moz-border-radius-topleft: 15px;
-moz-border-radius-topright: 15px;
border-top-left-radius: 15px;
border-top-right-radius: 15px;

&:after {
    content: "";
    position: absolute;

    height: 10px;
    width: 20px;

    bottom: 0;
}

&:after {
    right: -20px;

    border-radius: 0 0 0 10px;
    -moz-border-radius: 0 0 0 10px;
    -webkit-border-radius: 0 0 0 10px;

    -webkit-box-shadow: -10px 0 0 0 ${theme.color.offWhite};
    box-shadow: -10px 0 0 0 ${theme.color.offWhite};
}
`

export const ContentContainer = styled.div`
${props => props.noTitle && 'border-top-left-radius: 5px;'}
border-top-right-radius: 5px;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
padding: 16px;
height: fit-content;
max-width: ${props => (props.maxWidth ? props.maxWidth : '990px')};
background-color: ${theme.color.offWhite};
width: 100%;
box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
`

export const ContainerIcon = styled.div`
background-size: cover;
background-image: url(${props => props.img});
width: 25px;
height: 25px;
margin-right: 10px;
`

export const TitleWrapper = styled.div`
display: flex;
column-gap: 5px;
align-items: center;
`

export const ButtonEdit = styled(Button)`
    border-radius: 10px;
    padding: 2px 12px;
    height: 40px;
`

export const IconEdit = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-top: -4px;
`

export default NotchDiv
