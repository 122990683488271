import React from 'react'
import { Div, H3 } from 'konsys-ui'
import {
  NotchDiv, ContentContainer, ContainerIcon,
  TitleWrapper, ButtonEdit, IconEdit,
} from './styled'
import EditIcon from '../../../images/icon/EditIcon.png'

const NotchedContainer = ({
  title = 'Title here',
  titleIcon = '',
  children,
  noTitle,
  showEditIcon = false,
  handleEdit = () => {},
  maxWidth,
}) => (<Div>
  <Div display='flex' justifyContent='space-between'>
    { noTitle || <NotchDiv>
        <TitleWrapper>
          {/* <Div margin='5px' moreStyle={{ background: 'yellow', width: '30px', height: '30px' }}>
          </Div> */}
          <ContainerIcon img={titleIcon} />
          {title}
        </TitleWrapper>
      </NotchDiv>
    }
    {
      showEditIcon && <Div display='flex' justifyContent='flex-end'>
        <ButtonEdit
          text={<React.Fragment><IconEdit src={EditIcon}/>แก้ไขข้อมูล</React.Fragment>}
          ghost
          inverse
          small
          onClick={handleEdit}
        />
      </Div>
    }
  </Div>
  <ContentContainer noTitle={noTitle} maxWidth={maxWidth}>{children}</ContentContainer>
</Div>)

export default NotchedContainer
