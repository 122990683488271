import React, { useState } from 'react'
import {
  Div, H4, Input,
} from 'konsys-ui'
import { theme } from '../../../../../styles/_variables'
import {
  ButtonEdit, InputNumber,
} from './styled'


const { color } = theme

const FoilingBlockCostMinimumInput = ({
  data,
  onInputChange,
  action,
}) => {
  const [actionState, setActionState] = useState(action)
  const [minimumBlockCost, setMinimumBlockCost] = useState(data.minimumBlockCost || 0)
  return (
    <Div display='flex' flexDirection='column'>
      <Div display='flex' flexDirection='row' justifyContent='flex-start' alignItems='baseline' moreStyle={{ columnGap: '12px' }}>
        <H4 bold color={color.primaryColor} margin='16px 0px 32px 0px'>ต้นทุนขั้นต่ำ</H4>
        {
          actionState === 'create' ? <>
          <InputNumber
            value={data.minimumBlockCost}
            min={0}
            max={9999}
            step="1"
            type='number'
            onChange={(e) => {
              onInputChange({ ...data, minimumBlockCost: Math.round(e * 100) / 100 })
            }}
            onKeyPress={(e) => {
              if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault()
              }
            }}
            style={{ width: '20%' }}
          />
          <H4 bold color={color.primaryColor} margin='16px 0px 32px 0px'>บาท</H4>
          </> : actionState === 'detail' ? <>
            <H4 bold color={color.textColor} margin='32px 0px 32px 64px'>{data.minimumBlockCost} บาท</H4>
            <ButtonEdit
              text='แก้ไข'
              icon='fa fa-pencil'
              inverse
              onClick={() => setActionState('edit')}
            />
          </> : actionState === 'edit' ? <>
            <InputNumber
              value={minimumBlockCost}
              min={0}
              max={9999}
              step="1"
              type='number'
              onChange={(e) => {
                setMinimumBlockCost(Math.round(e * 100) / 100)
              }}
              onKeyPress={(e) => {
                if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                  e.preventDefault()
                }
              }}
              style={{ width: '20%' }}
            />
            <H4 bold color={color.primaryColor} margin='32px 0'>บาท</H4>
            <ButtonEdit
              text='ยกเลิก'
              onClick={() => {
                setMinimumBlockCost(data.minimumBlockCost)
                setActionState('detail')
              }}
            />
            <ButtonEdit
              text='ยืนยัน'
              inverse
              onClick={() => {
                onInputChange({ ...data, minimumBlockCost: Math.round(minimumBlockCost * 100) / 100 })
                setActionState('detail')
              }}
            />
          </> : null
        }
      </Div>
    </Div>
  )
}

export default FoilingBlockCostMinimumInput
