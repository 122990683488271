import React, { useState, useEffect } from 'react'
import { Div, LoadingIcon } from 'konsys-ui'
import { Row, Col } from 'antd'
import { Query } from 'react-apollo'
import { GET_PLASTIC_WINDOW_DETAIL } from '../../../../constants/graphql/query'
import { theme } from '../../../../styles/_variables'
import NotchedContainer from '../../../common/notched-containner'
import OptionsList from '../common/options-list'
import OptionDescription from '../common/option-description'
import SampleImageList from '../common/sample-image-list'
import MultiSizeInput from '../common/multi-size-input'
import ForbiddenRed from '../../../../images/icon/Forbidden-red Icon.png'
import Correct from '../../../../images/icon/Correct Icon.png'
import WindowBoxIcon from '../../../../images/icon/Window-boxIcon.png'
import Recommendation from '../common/recommendation'
import { CoverImage, DetailWrapper } from './styled'


const PlasticWindowSelector = ({
  formValues,
  setFormValues,
  measurementUnit,
  activeTab,
  disabled,
}) => {
  const [optionItems, setOptionItems] = useState([{
    id: 1,
    label: 'ไม่ใช้บริการ',
    icon: <CoverImage src={ForbiddenRed} />,
    checked: true,
    value: 0,
    index: 0,
  }, {
    id: 2,
    label: 'ใช้บริการ',
    icon: <CoverImage src={Correct} />,
    checked: false,
    value: 1,
    index: 1,
  }])
  const [plasticWindowFormValues, setPlasticWindowFormValues] = useState([])
  const [plasticWindow, setPlasticWindow] = useState(null)
  const [isUseService, setIsUseService] = useState(false)
  const [remark, setRemark] = useState('')

  const handleOnSelect = (value) => {
    if (value.value === 0) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].selectedPlasticWindow = value.value
      tmpFormValues[activeTab].plasticWindow = []
      setFormValues({ ...formValues, partData: tmpFormValues })
    } else {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].selectedPlasticWindow = value.value
      tmpFormValues[activeTab].plasticWindow = formValues.partData[activeTab].plasticWindow
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
    setIsUseService(value.value === 1)
    setPlasticWindowFormValues(formValues.partData[activeTab].plasticWindow)
  }

  const handleChangeSize = (value) => {
    const tmpFormValues = formValues.partData
    tmpFormValues[activeTab].plasticWindow = value
    setFormValues({ ...formValues, partData: tmpFormValues })
    setPlasticWindowFormValues(value)
    setFormValues({ ...formValues, plasticWindow: plasticWindowFormValues })
  }

  useEffect(() => {
    if (formValues.partData[activeTab]) {
      const tmpOptions = optionItems.map(item => ({
        ...item,
        checked: item.value === formValues.partData[activeTab].selectedPlasticWindow,
      }))
      setOptionItems(tmpOptions)
      handleOnSelect(tmpOptions.find(item => item.value === formValues.partData[activeTab].selectedPlasticWindow))
      setPlasticWindowFormValues(formValues.partData[activeTab].plasticWindow)
    }
  }, [activeTab])

  return <Div display='flex' flexDirection='column' moreStyle={{ rowGap: '20px' }}>
  <NotchedContainer
    title='การเจาะหน้าต่างและติดแผ่นใส'
    titleIcon={WindowBoxIcon}
  >
    <Row type='flex' gutter={16}>
      <Col xs={24} sm={11} md={11} span={16}>
        <Query
          query={GET_PLASTIC_WINDOW_DETAIL}
          notifyOnNetworkStatusChange
          fetchPolicy='network-only'
          onCompleted={(data) => {
            const plasticWindowData = data.getPlasticWindowDetail.plasticWindow
            const sampleVideo = plasticWindowData.plasticWindowFiles.filter(file => file.type === 'video')
            if (plasticWindowData) {
              setPlasticWindow({
                name: plasticWindowData.name,
                description: plasticWindowData.description,
                sampleImages: plasticWindowData.plasticWindowFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample'),
                sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].plasticWindowFileUri : null,
                plasticWindowThickness: plasticWindowData.plasticWindowThickness,
              })
              setRemark(plasticWindowData.remark)
            }
          }}
        >
          {
            ({ loading }) => {
              if (loading) {
                return <Div moreStyle={{ width: '100%' }}>
                  <LoadingIcon color={theme.color.grey} />
                </Div>
              }
              return (
                <OptionsList
                  title='เลือกตัวเลือกที่ต้องการ'
                  options={optionItems}
                  onSelect={handleOnSelect}
                  disabled={disabled}
                />
              )
            }
          }
        </Query>
      </Col>
      <Col xs={24} sm={13} md={13} span={16}>
        <DetailWrapper>
          <OptionDescription
            title={isUseService ? plasticWindow && plasticWindow.name : 'ไม่ใช้บริการ'}
            description={isUseService ? plasticWindow && plasticWindow.description : ''}
            videoSrc={isUseService ? plasticWindow && plasticWindow.sampleVideoUri : null }
          />
          {
            isUseService && plasticWindow && plasticWindow.sampleImages.length > 0 && <SampleImageList
              title='รูปภาพตัวอย่าง'
              data={plasticWindow.sampleImages.map(item => ({ fileUrl: item.plasticWindowFileUri, ordering: item.ordering }))}
            />
          }
          {
            isUseService && plasticWindow && plasticWindow.sampleImages.length === 0 && <Div style={{ marginTop: '16px' }}></Div>
          }
          {
            isUseService ? <MultiSizeInput
              values={plasticWindowFormValues}
              thicknessList={plasticWindow ? plasticWindow.plasticWindowThickness : []}
              unit={measurementUnit}
              onChange={handleChangeSize}
              small
              disabled={disabled}
            /> : null
          }
          {
            remark && <Recommendation
              value={remark.description}
            />
          }
        </DetailWrapper>
      </Col>
    </Row>
  </NotchedContainer>
</Div>
}


export default PlasticWindowSelector
