import React, { useState } from 'react'
import {
  Div,
} from 'konsys-ui'
import {
  ContentParts,
  FullWidthContainer,
} from './styled'
import TabPart from '../../../common/tab-part'
import PriceCalculationForm from '../price-calculator-form'
import Summary from '../summary'

const Parts = ({
  data,
  setFormValues,
  formValues,
  tested,
  activeTab,
  onChangeTab,
  isMobileXL,
  disabled,
  setDisabled,
  ContentRef,
  setSummaryActive,
  isShowGluing,
}) => (
  <FullWidthContainer>
    <ContentParts>
      <TabPart
        onChange={onChangeTab}
        data={data.packageParts && data.packageParts}
        keyTab={activeTab}
      >
          <Div display='flex' alignItems='center' justifyContent='center'>
            { data.packageParts && <Div flexBasis={isMobileXL ? '100%' : '70%'}> <PriceCalculationForm
              measurementUnit={formValues.partData && formValues.partData[activeTab].measurementUnit}
              activeTab={activeTab}
              formValues={formValues}
              setFormValues={setFormValues}
              defaultPrinting={false}
              tested={tested}
              disabled={disabled}
              setDisabled={setDisabled}
            /></Div>
          }
          {
            !isMobileXL && <Div flexBasis='30%'>
              <Summary
                formValues={formValues}
                activeTab={activeTab}
                onChangeTab={onChangeTab}
                sticky={true}
                ContentRef={ContentRef}
                setSummaryActive={setSummaryActive}
                isShowGluing={isShowGluing}
              />
            </Div>
          }
          </Div>
      </TabPart>
    </ContentParts>
  </FullWidthContainer>
)


export default Parts
