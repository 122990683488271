import React, { useState } from 'react'
import _ from 'lodash'
import { Query, Mutation } from 'react-apollo'
import { message, Popconfirm } from 'antd'
import {
  Div, Button, LoadingIcon,
} from 'konsys-ui'
import { SHOW_BANNER } from '../../constants/graphql/query'
import { ADD_BANNER, DELETE_BANNER } from '../../constants/graphql/mutation'
import { theme } from '../../styles/_variables'
import UploadFile from '../../components/common/upload'

const ImageBanner = ({ img, id, refetch }) => (
  <Div margin='16px 12px'>
    <img src={img} style={{ maxHeight: '250px', width: 'auto', display: 'block' }} />
    <Mutation
      mutation={DELETE_BANNER}
    >
    {
      doDeleteBanner => (
        <Popconfirm
          title="ยืนยันที่จะลบภาพนี้ ?"
          onConfirm={() => {
            doDeleteBanner({
              variables: {
                id,
              },
            }).then(({ data }) => {
              if (data.deleteBanner) {
                if (data.deleteBanner.success) {
                  message.success(data.deleteBanner.message)
                  refetch()
                } else {
                  message.error(data.deleteBanner.message)
                }
              }
            })
          }}
          okText="ยืนยัน"
          cancelText="ยกเลิก"
        >
          <Button small ghost margin='8px 0 0' text='ลบรูปภาพนี้' />
        </Popconfirm>
      )
    }
    </Mutation>
  </Div>
)

const Home = () => {
  const [newFileList, setNewFileList] = useState([])
  const [disabledButton, setDisabledButton] = useState(false)

  return (
    <Query
      query={SHOW_BANNER}
    >
    {
      ({ loading, data, refetch }) => {
        if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
        if (!data.showBanner.success) return <p>error something</p>
        const bannerData = data.showBanner.banner
        return (
          <Div>
            <Mutation
              mutation={ADD_BANNER}
            >
            {
              doAddImage => (
                <Div>
                  <UploadFile
                    list={newFileList}
                    id='uploadFileId'
                    multiple
                    onChange={(fileList) => {
                      let upload = false
                      const allowFileType = ['jpg', 'jpeg', 'png']
                      for (let i = 0; i < fileList.length; i += 1) {
                        const splitName = fileList[i].name.split('.')
                        if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
                        || (newFileList.length > 0 && !_.isUndefined(_.find(newFileList, d => d.name === fileList[i].name)))) {
                          upload = false
                          break
                        } else {
                          upload = true
                        }
                      }
                      if (upload) {
                        const newUploadFileList = [...newFileList]
                        Object.keys(fileList).forEach((v) => {
                          if (v !== 'length') {
                            newUploadFileList.push(fileList[v])
                          }
                        })
                        setNewFileList(newUploadFileList)
                      } else {
                        message.error('สามารถอัพโหลดได้เฉพาะไฟล์ jpg, jpeg, png และไม่สามารถอัพรูปซ้ำได้')
                      }
                    }}
                    onRemoveFile={(fileName) => {
                      const newUploadFileList = [...newFileList]
                      _.remove(newUploadFileList, v => v.name === fileName)
                      setNewFileList(newUploadFileList)
                    }}
                    fileType="image/*"
                  />
                  <Button disabled={newFileList.length <= 0 || disabledButton} inverse fullWidth text='อัพโหลดรูปภาพใหม่' onClick={() => {
                    setDisabledButton(true)
                    doAddImage({
                      variables: {
                        images: newFileList,
                      },
                    }).then((resp) => {
                      setDisabledButton(false)
                      if (resp.data.addBanner) {
                        if (resp.data.addBanner.success) {
                          setNewFileList([])
                          message.success(resp.data.addBanner.message)
                          setTimeout(() => {
                            refetch()
                          }, 3000)
                        } else {
                          message.error(resp.data.addBanner.message)
                        }
                      }
                    })
                  }} />
                </Div>
              )
            }
            </Mutation>
            <Div margin='24px -12px'>
            {
              bannerData && bannerData.length > 0 && bannerData.map(v => (
                <ImageBanner key={v} img={v.bannerFileUri} id={v.bannerId} refetch={() => {
                  setTimeout(() => {
                    refetch()
                  }, 3000)
                }} />
              ))
            }
            </Div>
          </Div>
        )
      }
    }
    </Query>
  )
}

export default Home
