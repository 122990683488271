import React, { useState, useEffect } from 'react'
import { Query } from 'react-apollo'
import { LIST_ADDITIONAL_SERVICE } from '../../../../constants/graphql/query'
import { Title } from './styled'
import MultiRowSelectBox from '../common/multi-row-select-box'


const ExtraServiceSelector = ({
  formValues,
  setFormValues,
  activeTab,
  disabled,
}) => {
  const [extraList, setExtraList] = useState([])
  const handleOnChange = (value, numberTab) => {
    const tmpFormValues = formValues.partData
    const result = value.map(x => tmpFormValues[activeTab].extraServiceList.find(y => x.value === y.value))
    tmpFormValues[numberTab].additionalServiceId = result
    const extraServiceList = extraList.map(item => ({
      ...item,
      disabled: value.find(x => item.value && item.value === x.value) !== undefined,
    }))
    tmpFormValues[numberTab].extraServiceList = extraServiceList
    setFormValues({ ...formValues, partData: tmpFormValues })
  }

  useEffect(() => {
    if (extraList) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].extraServiceList = extraList.map(item => ({
        value: item.value,
        title: item.name,
        name: item.name,
        disabled: item.disabled,
      }))
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
  }, [extraList, activeTab])

  const setRows = (values, numberTab) => {
    const tmpFormValues = formValues.partData
    tmpFormValues[numberTab].addtionalRows = values
    const result = values.map(x => tmpFormValues[numberTab].extraServiceList.find(y => x.value === y.value))
    tmpFormValues[numberTab].additionalServiceId = result
    const extraServiceList = tmpFormValues[numberTab].extraServiceList.map(item => ({
      ...item,
      disabled: values.find(x => item.value && item.value === x.value) !== undefined,
    }))
    tmpFormValues[numberTab].extraServiceList = extraServiceList
    setFormValues({ ...formValues, partData: tmpFormValues })
  }

  const toggleOption = (value, numberTab) => {
    if (!value) {
      return
    }
    const tmpOptions = [...formValues.partData[numberTab].extraServiceList]
    const tmpFormValues = formValues.partData
    tmpFormValues[numberTab].extraServiceList = tmpOptions
    setFormValues({ ...formValues, partData: tmpFormValues })
  }

  return (
    <div>
      <Title>บริการเสริมอื่นๆ</Title>
      <Query
        query={LIST_ADDITIONAL_SERVICE}
        fetchPolicy='network-only'
        variables={{
          limit: 10000,
          offset: 0,
        }}
        onCompleted={(data) => {
          if (data.listAdditionalService) {
            const extraServiceList = data.listAdditionalService.additionalService.map(item => ({
              value: item.additionalServiceId,
              title: item.name,
              name: item.name,
              disabled: formValues.partData[activeTab] && formValues.partData[activeTab].additionalServiceId.filter(x => x).find(x => item.additionalServiceId && item.additionalServiceId === x.value) !== undefined,
            }))
            setExtraList(extraServiceList)
          }
        }}
      >
        {
          () => (
              <MultiRowSelectBox
                title={'บริการเสริม'}
                placeholder={'เลือกบริการเสริม'}
                optionsData={formValues.partData[activeTab] && formValues.partData[activeTab].extraServiceList}
                defaultValues={formValues.partData[activeTab] && formValues.partData[activeTab].additionalServiceId.map(x => (x || ({
                  title: '',
                  name: '',
                })))}
                onChange={handleOnChange}
                disabled={disabled}
                initTextButton='ใช้บริการเสริมอื่นๆ'
                warningTextNoOptions='ไม่มีบริการเสริมอื่นๆ'
                white={true}
                rows={formValues.partData[activeTab] && formValues.partData[activeTab].addtionalRows}
                setRows={setRows}
                toggleOption={toggleOption}
                activeTab={activeTab}
              />
          )
        }
      </Query>
    </div>
  )
}

export default ExtraServiceSelector
