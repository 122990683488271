import React, { useState } from 'react'
import { Query } from 'react-apollo'
import {
  Button, Div, Icon, LoadingIcon,
} from 'konsys-ui'
import { Modal } from 'antd'
import { SHOW_GALLERY } from '../../../constants/graphql/query'
import {
  UploadWrapper, Content, IconDiv,
} from './styled'
import { theme } from '../../../styles/_variables'
import {
  ImageWrapper, ImageWithButtonWrapper,
} from '../../../pages/gallery/styled'

const { color } = theme

const Image = ({ img, onClick }) => (
  <ImageWrapper img={img} onClick={() => onClick()}>
    <Icon icon='far fa-check' />
  </ImageWrapper>
)

const SelectGallery = ({
  id,
  selectImage,
  style,
}) => {
  // selectImage is a function select image in gallery --> IS REQUIRED.
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const setCurrentImage = (image) => {
    selectImage(image.galleryFileUri)
    setIsModalVisible(false)
  }

  return (
    <Div style={{ width: '100%', ...style }}>
      <UploadWrapper>
        <label htmlFor={id}>
          <Content>
            <IconDiv>
              <Icon
                color={color.offWhite}
                style={{
                  borderRadius: '50%',
                  backgroundColor: color.primaryColor,
                  width: '54px',
                  height: '54px',
                  fontSize: '26px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                icon='far fa-image'
              />
            </IconDiv>
            <Button
              style={{ fontSize: theme.fonts.size.subTitle }}
              fontSize={theme.fonts.size.subTitle}
              color={theme.color.paleBlack}
              buttonStyle='rectangle'
              text='เลือกจาก Gallery'
              onClick={showModal}
            />
          </Content>
        </label>
      </UploadWrapper>
      {
        isModalVisible && <Query
          query={SHOW_GALLERY}
          variables={{
            limit: 10000,
          }}
        >
          {
            ({ loading, data }) => {
              if (loading) return <Modal title="Select Images" visible={isModalVisible} onCancel={handleCancel} footer={null}><LoadingIcon color={theme.color.grey} /></Modal>
              if (!data.showGallery.success) return <p>error something</p>
              const galleryData = data.showGallery.gallery
              return (
                <Modal title="Select Images" visible={isModalVisible} onCancel={handleCancel} footer={null}>
                  <Div>
                    <Div margin='24px -12px' display='flex' moreStyle={{ flexWrap: 'wrap' }}>
                    {
                      galleryData.map((v, i) => (
                        <ImageWithButtonWrapper key={v.galleryId}>
                          <Image
                            img={v.galleryFileUri}
                            onClick={() => {
                              setCurrentImage(galleryData[i])
                            }}
                          />
                        </ImageWithButtonWrapper>
                      ))
                    }
                    </Div>
                  </Div>
                </Modal>
              )
            }
          }
        </Query>
      }
    </Div>
  )
}

export default SelectGallery
