import React, { useState } from 'react'
import {
  WidthContainer, FullWidthContainer, Label,
} from './styled'
import CostMinimumInput from '../cost-minimum'
import DieCutBlocksTable from '../die-cutting-blocks-table'
import DieCuttingOperationCostTable from '../die-cutting-operation-cost-table'

const PrintingForm = ({
  formValues,
  setFormValues,
}) => (
    <>
      <WidthContainer>
        <DieCutBlocksTable
          title="ต้นทุนแม่พิมพ์ใบมีด (Die-cut Blocks)"
          data={formValues.dieCutBlocks}
          onSubmitEditRow={(key, value) => {
            const tmp = [...formValues.dieCutBlocks]
            tmp[key] = value
            setFormValues({ ...formValues, dieCutBlocks: tmp })
          }}
          onSubmitDeleteRow={(value) => {
            setFormValues({ ...formValues, dieCutBlocks: value })
          }}
          onAddRow={value => setFormValues({ ...formValues, dieCutBlocks: value })}
        />
        <CostMinimumInput
          data={formValues}
          onInputChange={setFormValues}
        />
      </WidthContainer>
      <FullWidthContainer>
        <DieCuttingOperationCostTable
          data={formValues}
          onChange={setFormValues}
        />
      </FullWidthContainer>
    </>
)

export default PrintingForm
