import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Div, H4, LoadingIcon,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import { message } from 'antd'
import {
  NewButton,
} from './styled'
import { theme } from '../../../styles/_variables'
// import TagForm from './components/tag-form'
import PATH from '../../../constants/path'
import { GET_PRICE_NOTE } from '../../../constants/graphql/query'
import { UPDATE_PRICE_NOTE } from '../../../constants/graphql/mutation'
import BreadCrumb from '../../../components/common/breadcrumb'
import Description from './components/description'

const { color } = theme

const TagSetting = () => {
  const [formValues, setFormValues] = useState({
    description: '',
  })
  const [callMutation, setCallMutation] = useState(false)
  return (
    <Div>
      <BreadCrumb link={{
        parent: [
          {
            text: 'การตั้งค่าบรรจุภัณฑ์',
            link: PATH.packageSetting,
          },
        ],
        current: {
          text: 'การตั้งค่า หมายเหตุการสรุปราคา',
        },
      }} />
      <Div display='flex' flexDirection='row' justifyContent='space-between' margin='32px 0px 0px 0px' alignItems='center'>
          <Div display='flex' flexDirection='column'>
          <H4 bold color={color.primaryColor}>
              <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                <H4 bold color={color.primaryColor}>Catalog Settings</H4>
              </Link> {' > '} แก้ไขข้อความใต้ตารางราคา
          </H4>
          </Div>
          <Mutation mutation={UPDATE_PRICE_NOTE}>
            {
              doUpdateTag => (
                <NewButton
                  text='บันทึก'
                  icon='fa fa-floppy-o'
                  inverse
                  disabled={callMutation}
                  onClick={() => {
                    setCallMutation(true)
                    doUpdateTag({
                      variables: {
                        description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
                      },
                    }).then((res) => {
                      if (res.data.editPriceNote) {
                        if (res.data.editPriceNote.success) {
                          message.success(res.data.editPriceNote.message)
                          setTimeout(() => {
                            window.location = PATH.priceNoteSetting
                            setCallMutation(false)
                          }, 100)
                        } else {
                          message.error(res.data.editPriceNote.message)
                          setCallMutation(false)
                        }
                      }
                    })
                  }}
                />
              )
            }
          </Mutation>
      </Div>
      <Query
        query={GET_PRICE_NOTE}
        notifyOnNetworkStatusChange
        onCompleted={(data) => {
          if (data.priceNote) {
            setFormValues({ description: data.priceNote.description })
          }
        }}
      >
        {
          ({ loading, data }) => {
            if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
            return (
              <>
                <Description
                  data={formValues}
                  onInputChange={setFormValues}
                />
              </>
            )
          }
        }
      </Query>

    </Div>
  )
}

export default TagSetting
