import React from 'react'
import {
  Div,
} from 'konsys-ui'
import { Row, Col } from 'antd'
import { FormWrapper } from './styled'
import PrintingSize from '../printing-size'
import PrintingSizeDefault from '../printing-size-default'
import PaperSelector from '../paper-selector'
import PrintingSelector from '../printing-selector'
import CoatingSelector from '../coating-selector'
import SpotUvSelector from '../spot-uv-selector'
import FoilingSelector from '../foiling-selector'
import PlasticWindowSelector from '../plastic-window-selector'
import EmbossingSelector from '../embossing-selector'
import GluingSelector from '../gluing-selector'
import ExtraServiceSelector from '../extra-service-selector'
import DieCutSelector from '../die-cut-selector'


const PriceCalculationForm = ({
  measurementUnit,
  formValues,
  setFormValues,
  activeTab,
  defaultPrinting = false,
  tested = '',
  disabled,
  setDisabled,
}) => (
  <FormWrapper>
    {
      defaultPrinting ? <PrintingSizeDefault
        formValues={formValues}
        setFormValues={setFormValues}
        unit={measurementUnit}
        activeTab={activeTab}
        setDisabled={setDisabled}
      /> : <PrintingSize
        formValues={formValues}
        setFormValues={setFormValues}
        unit={measurementUnit}
        activeTab={activeTab}
        setDisabled={setDisabled}
      />
    }
    <br/>
    <PaperSelector
      formValues={formValues}
      setFormValues={setFormValues}
      activeTab={activeTab}
      disabled={disabled}
    />
    <br/>
    <PrintingSelector
      formValues={formValues}
      setFormValues={setFormValues}
      activeTab={activeTab}
      disabled={disabled}
    />
    <br/>
    <CoatingSelector
      formValues={formValues}
      setFormValues={setFormValues}
      activeTab={activeTab}
      disabled={disabled}
    />
    <br/>
    <SpotUvSelector
      formValues={formValues}
      setFormValues={setFormValues}
      activeTab={activeTab}
      disabled={disabled}
    />
    <br/>
    <EmbossingSelector
      formValues={formValues}
      setFormValues={setFormValues}
      measurementUnit={measurementUnit}
      activeTab={activeTab}
      disabled={disabled}
    />
    <br/>
    <FoilingSelector
      formValues={formValues}
      setFormValues={setFormValues}
      measurementUnit={measurementUnit}
      activeTab={activeTab}
      disabled={disabled}
    />
    <br/>
    <PlasticWindowSelector
      formValues={formValues}
      setFormValues={setFormValues}
      measurementUnit={measurementUnit}
      activeTab={activeTab}
      disabled={disabled}
    />
    <br/>
    {
      tested === 'calculated' && <>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12} span={16}>
            <GluingSelector
              formValues={formValues}
              setFormValues={setFormValues}
              activeTab={activeTab}
              disabled={disabled}
            />
          </Col>
          <Col xs={24} sm={12} md={12} span={16}>
            <DieCutSelector
              formValues={formValues}
              setFormValues={setFormValues}
              activeTab={activeTab}
              disabled={disabled}
            />
          </Col>
          <Col xs={24} sm={24} md={24} span={16}>
            <ExtraServiceSelector
              formValues={formValues}
              setFormValues={setFormValues}
              activeTab={activeTab}
              disabled={disabled}
            />
          </Col>
        </Row>
      </>
    }
    {
      tested === 'package-calulated' && <>
        <DieCutSelector
          formValues={formValues}
          setFormValues={setFormValues}
          activeTab={activeTab}
          disabled={disabled}
        />
        <br/>
      </>
    }
  </FormWrapper>
)

export default PriceCalculationForm
