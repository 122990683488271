/* eslint-disable radix */
import React, { useState } from 'react'
import {
  Div, H4,
} from 'konsys-ui'
import {
  NumberLabel,
  ContentTabPane,
  Title,
  BottomTabBar,
  TopTabBar,
  TabTitleDiv,
  TitlePart,
  TabsAnt,
} from './styled'
import { theme } from '../../../styles/_variables'


const { TabPane } = TabsAnt
const { color } = theme
const Label = ({
  number,
  label,
  activeTab,
  onlyOne,
  isMobileXL,
}) => (
  <TabTitleDiv
    display='flex'
    flexDirection='row'
    className='tab-title'
    moreStyle={{
      columnGap: isMobileXL ? '4px' : '12px',
      flexWrap: 'wrap',
      rowGap: '12px',
      alignItems: 'center',
    }}
  >
    {!onlyOne && <NumberLabel activeTab={parseInt(activeTab) === parseInt(number)}>{number + 1}</NumberLabel>} <TitlePart color={parseInt(activeTab) === parseInt(number) ? color.greenPrimary : color.offGray}>{label}</TitlePart>
  </TabTitleDiv>
)

const renderTabBar = (props, DefaultTabBar, data, activeTab, children, noDefault, noShow) => (
    <Div>
        <TopTabBar style={{
          width: '100%',
          background: 'transparent',
          height: '60px',
          borderBottom: '0px',
          display: noShow && 'none',
        }}
          hideOne={!noDefault && data && data.length === 1}
        >
            <DefaultTabBar {...props} style={{
              borderBottom: '0px',
              height: '60px',
            }} />
        </TopTabBar>
        <ContentTabPane isOne={data && data.length === 1}>
          { children }
        </ContentTabPane>
        {
          (noDefault || data.length > 1) && <BottomTabBar
            className='bottom-tab-bar'
            style={{
              width: '100%',
              background: 'transparent',
              height: '60px',
              borderBottom: '0px',
              display: noShow && 'none',
            }}
          >
            {noDefault || data.length > 1
              ? <DefaultTabBar
                {...props} style={{
                  borderBottom: '0px',
                  height: '60px',
                }}
              />
              : null
            }
          </BottomTabBar>
        }
    </Div>
)

const TabPart = ({
  onChange,
  data,
  children,
  noDefault,
  isMobileXL,
  noShow,
  keyTab = '0',
}) => {
  const [activeTab, setActiveTab] = useState('0')
  const handleTabClick = (key) => {
    setActiveTab(key)
  }
  return (
    <TabsAnt
      defaultActiveKey={keyTab}
      activeKey={keyTab.toString()}
      type="card"
      onChange={onChange}
      tabBarGutter={8}
      centered
      tabBarExtraContent={<Title color={color.offWhite}>{ !noDefault && data && data.length > 1 ? 'เลือกชิ้นส่วน :' : 'กรุณาระบุรายละเอียดที่ต้องการ' }</Title>}
      renderTabBar={(props, DefaultTabBar) => renderTabBar(props, DefaultTabBar, data, keyTab, children, noDefault, noShow)}
      onTabClick={handleTabClick}
    >
      {
        data && data.map((item, index) => (
          <TabPane tab={<Label onlyOne={!noDefault && data.length === 1} number={index} label={item.name} activeTab={keyTab} isMobileXL={isMobileXL} />} key={index}>
            {/* <ContentTabPane>
              { children }
            </ContentTabPane> */}
          </TabPane>
        ))
      }
    </TabsAnt>
  )
}

export default TabPart
