import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation login (
    $client_id: String!
    $client_secret: String!
    $grant_type: GrantType!
    $username: String!
    $password: String!
  ) {
    login (
      input: {
        client_id: $client_id
        client_secret: $client_secret
        grant_type: $grant_type
        username: $username
        password: $password
      }
    ) {
      success
      message
      createdAccessToken{
        access_token
        refresh_token
        token_type
        userId
      }
    }
  }
`

export const REQ_RESET_PASSWORD_TOKEN = gql`
  mutation forgetPassword (
    $email: String!
  ) {
    forgetPassword(input: {
      email: $email
    }) {
      success
      message
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword (
    $token: String!
    $newPassword: String!
  ) {
    resetPassword(input: {
      token: $token
      newPassword: $newPassword
    }) {
      success
      message
    }
  }
`

export const CREATE_CLIENT = gql`
  mutation createCustomer (
    $companyId: String
    $companyName: String
    $credit: String
    $companyAddress: String
    $taxId: String
    $officeNumber: String
    $contactName: String
    $contactNumber: String
    $email: String
    $fax: String
    $websiteUrl: String
    $note: String
    $type: CustomerType
    $images: [Upload]
  ) {
    createCustomer(input: {
      companyId: $companyId
      companyName: $companyName
      credit: $credit
      companyAddress: $companyAddress
      taxId: $taxId
      officeNumber: $officeNumber
      contactName: $contactName
      contactNumber: $contactNumber
      email: $email
      fax: $fax
      websiteUrl: $websiteUrl
      note: $note
      type: $type
      images: $images
    }) {
      success
      message
    }
  }
`

export const UPDATE_CLIENT = gql`
  mutation updateCustomer (
    $companyId: String
    $companyName: String
    $credit: String
    $companyAddress: String
    $taxId: String
    $officeNumber: String
    $contactName: String
    $contactNumber: String
    $email: String
    $fax: String
    $websiteUrl: String
    $note: String
    $type: CustomerType
    $id: String!
    $images: [Upload]
    ) {
    updateCustomer(input: {
      companyId: $companyId
      companyName: $companyName
      credit: $credit
      companyAddress: $companyAddress
      taxId: $taxId
      officeNumber: $officeNumber
      contactName: $contactName
      contactNumber: $contactNumber
      email: $email
      fax: $fax
      websiteUrl: $websiteUrl
      note: $note
      type: $type
      id: $id
      images: $images
    }) {
      success
      message
    }
  }
`

export const UPDATE_TAX_RATE = gql`
  mutation editTaxRate (
    $taxRate: Float!
    $withHoldingTaxRate: Float!
  ) {
    editTaxRate (
      taxRate: $taxRate
      withHoldingTaxRate: $withHoldingTaxRate
    ) {
      success
      message
    }
  }
`

export const CREATE_QUOTATION = gql`
  mutation createQuotation (
    $id: Int!
    $input: CreateQuotationInput
  ) {
    createQuotation (
      id: $id
      input: $input
    ) {
      success
      message
      updatedQuotation {
        quotationId
        refId
        status
      }
    }
  }
`

export const ADD_ADMIN_NOTE = gql`
  mutation addAdminNote (
    $adminNote: String
    $id: Int
  ) {
    addAdminNote (
      input: {
        adminNote: $adminNote
      }
      id: $id
    ) {
      success
      message

    }
  }
`

export const RESEND_QUOTATION_EMAIL = gql`
  mutation resendQuotationEmail (
    $id: Int
  ) {
    resendQuotationEmail (
      id: $id
    ) {
      success
      message
    }
  }
`

export const UPDATE_STATUS = gql`
  mutation updateStatus (
    $id: Int
    $status: String!
  ) {
    updateStatus (
      id: $id
      input: {
        status: $status
      }
    ) {
      success
      message
    }
  }
`

export const ADD_SHIPPING = gql`
  mutation addShipping (
    $id: Int
    $shipper: String
    $trackingId: String
  ) {
    addShipping (
      id: $id
      input: {
        shipper: $shipper,
        trackingId: $trackingId,
      }
    ) {
      success
      message
    }
  }
`

export const QUOTATION_REQUEST_CREATE = gql`
  mutation createQuotationRequest (
    $useBoxSuitDesign: Boolean
    $input: CreateQuotationRequestInput
  ) {
    createQuotationRequest (
      input: $input
      useBoxSuitDesign: $useBoxSuitDesign
    ) {
      success
      message
      createdQuotation {
        quotationId
        refId
      }
    }
  }
`

export const DUPLICATE_QUOTATION = gql`
  mutation DuplicateQuotation($input: QuotationInput) {
    duplicateQuotation(input: $input) {
      createdQuotation {
        quotationId
        refId
        name
        status
        width
        length
        height
        quantity
        paperType
        paperThickness
        printingTechnique
        printingDescription
        taxRate
        totalPrice
        adminNote
        address {
          addressId
          userName
          userSurname
          companyName
          companyType
          branch
          taxId
          telephoneNumber
          mobileNumber
          email
          lineId
          addressNumber
          district
          subDistrict
          province
          postalCode
          name
          surname
        }
        useBoxSuitDesign
        useCompleteDesign
        shippingAddress {
          shippingAddressId
          shippingUserName
          shippingUserSurname
          shippingCompanyName
          shippingTelephoneNumber
          shippingMobileNumber
          shippingEmail
          shippingLineId
          shippingAddressNumber
          shippingDistrict
          shippingSubDistrict
          shippingProvince
          shippingPostalCode
          shippingDescription
          name
          surname
          companyName
          telephoneNumber
          mobileNumber
          email
          lineId
          district
          subDistrict
          province
          postalCode
        }
        sampleFiles {
          sampleFileUri
        }
        designFiles {
          designFileUri
        }
        expiryDate
        createdAt
        quotationPDF
        designInfo {
          designInfoId
          width
          length
          height
          quantity
          description
        }
      isGraphic
      paperTypeText
      printingTechniqueText
    }
    success
    message
  }
}
`

export const ADD_GALLERY = gql`
  mutation addGallery (
    $images: [Upload]
  ) {
    addGallery (
      input: {
        images: $images
      }
    ) {
      success
      message
    }
  }
`

export const DELETE_GALLERY = gql`
  mutation deleteGallery (
    $id: Int!
  ) {
    deleteGallery (
      id: $id
    ) {
      success
      message
    }
  }
`

export const ADD_BANNER = gql`
  mutation addBanner (
    $images: [Upload]
  ) {
    addBanner (
      input: {
        images: $images
      }
    ) {
      success
      message
    }
  }
`

export const DELETE_BANNER = gql`
  mutation deleteBanner (
    $id: Int!
  ) {
    deleteBanner (
      id: $id
    ) {
      success
      message
    }
  }
`

export const REPLACE_POPUP = gql`
  mutation replacePopUp (
    $image: Upload
  ) {
    replacePopUp (
      input: {
        image: $image
      }
    ) {
      success
      message
    }
  }
`

export const EDIT_TAX_INVOICE = gql`
  mutation editTaxInvoice (
    $id: Int
    $input: EditTaxInvoiceInput
  ) {
    editTaxInvoice (
      id: $id
      input: $input
    ) {
      success
      message
    }
  }
`

export const SEND_SHIP_EMAIL = gql`
  mutation sendShippedEmail (
    $input: SendShippedEmailInput
  ) {
    sendShippedEmail (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_SHIPPED = gql`
  mutation EditShipped (
    $input: SendShippedEmailInput
  ) {
    editShipped (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_QUOTATION = gql`
  mutation deleteQuotation (
    $id: Int!
  ) {
    deleteQuotation (
      id: $id
    ) {
      success
      message
    }
  }
`

export const CREATE_PACKAGE = gql`
  mutation createPackage (
    $input: CreatePackageInput
  ) {
    createPackage (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_PACKAGE = gql`
  mutation editPackage (
    $input: EditPackageInput
  ) {
    editPackage (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_PACKAGE_STATUS = gql`
  mutation editPackageStatusByPackageId (
    $packageId: Int
    $status: String
  ) {
    editPackageStatusByPackageId (
      packageId: $packageId
      status: $status
    ) {
      success
      message
    }
  }
`

export const DELETE_PACKAGE = gql`
  mutation deletePackaging (
    $id: Int!
  ) {
    deletePackaging (
      id: $id
    ) {
      success
      message
    }
  }
`

export const ADD_REMARK = gql`
  mutation addRemark (
    $input: AddRemarkInput
  ) {
    addRemark (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_REMARK = gql`
  mutation edtRemark (
    $input: EditRemarkInput
  ) {
    editRemark (
      input: $input
    ) {
      success
      message
    }
  }
`

export const ADD_COATING = gql`
  mutation addCoating (
    $input: AddCoatingInput
  ) {
    addCoating (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_COATING = gql`
  mutation editCoating (
    $input: EditCoatingInput
  ) {
    editCoating (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_COATING = gql`
  mutation deleteCoating (
    $id: Int!
  ) {
    deleteCoating (
      coatingId: $id
    ) {
      success
      message
    }
  }
`

export const ADD_GLUING = gql`
  mutation addGluing (
    $input: AddGluingInput
  ) {
    addGluing (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_GLUING = gql`
  mutation editGluing (
    $input: EditGluingInput
  ) {
    editGluing (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_GLUING = gql`
  mutation deleteGluing (
    $id: Int!
  ) {
    deleteGluing (
      gluingId: $id
    ) {
      success
      message
    }
  }
`

export const ADD_ADDITIONAL_SERVICE = gql`
  mutation addAdditionalService (
    $input: AddAdditionalServiceInput
  ) {
    addAdditionalService (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_ADDITIONAL_SERVICE = gql`
  mutation editAdditionalService (
    $input: EditAdditionalServiceInput
  ) {
    editAdditionalService (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_ADDITIONAL_SERVICE = gql`
  mutation deleteAdditionalService (
    $id: Int!
  ) {
    deleteAdditionalService (
      additionalServiceId: $id
    ) {
      success
      message
    }
  }
`

export const ADD_TAG = gql`
  mutation addTag (
    $input: AddTagInput
  ) {
    addTag (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_TAG = gql`
  mutation editTag (
    $input: EditTagInput
  ) {
    editTag (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_TAG = gql`
  mutation deleteTag (
    $id: Int!
  ) {
    deleteTag (
      tagId: $id
    ) {
      success
      message
    }
  }
`

export const ADD_PAPER = gql`
  mutation addPaper (
    $input: AddPaperInput
  ) {
    addPaper (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_PAPER = gql`
  mutation editPaper (
    $input: EditPaperInput
  ) {
    editPaper (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_PAPER = gql`
  mutation deletePaper (
    $id: Int!
  ) {
    deletePaper (
      paperId: $id
    ) {
      success
      message
      packageError
      packageErrorMessage
    }
  }
`

export const UPDATE_TAG = gql`
  mutation updateTag (
    $input: [UpdateTagInput]
  ) {
    updateTag (
      input: $input
    ) {
      success
      message
    }
  }
`

export const ADD_PRINTING = gql`
  mutation addPrinting (
    $input: AddPrintingInput
  ) {
    addPrinting (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_PRINTING = gql`
  mutation editPrinting (
    $input: EditPrintingInput
  ) {
    editPrinting (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_PRINTING = gql`
  mutation deletePrinting (
    $id: Int!
  ) {
    deletePrinting (
      gluingId: $id
    ) {
      success
      message
    }
  }
`

export const ADD_SPOT_UV = gql`
  mutation addSpotUV (
    $input: AddSpotUVInput
  ) {
    addSpotUV (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_SPOT_UV = gql`
  mutation editSpotUV (
    $input: EditSpotUVInput
  ) {
    editSpotUV (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_SPOT_UV = gql`
  mutation deleteSpotUV (
    $id: Int!
  ) {
    deleteSpotUV (
      spotUVId: $id
    ) {
      success
      message
    }
  }
`

export const ADD_DIE_CUT = gql`
  mutation addDieCut (
    $input: AddDieCutInput
  ) {
    addDieCut (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_DIE_CUT = gql`
  mutation editDieCut (
    $input: EditDieCutInput
  ) {
    editDieCut (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_DIE_CUT = gql`
  mutation deleteDieCut (
    $id: Int!
  ) {
    deleteDieCut (
      dieCutId: $id
    ) {
      success
      message
    }
  }
`

export const ADD_EMBOSSING = gql`
  mutation addEmbossing (
    $input: AddEmbossingInput
  ) {
    addEmbossing (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_EMBOSSING = gql`
  mutation editEmbossing (
    $input: EditEmbossingInput
  ) {
    editEmbossing (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_EMBOSSING = gql`
  mutation deleteEmbossing (
    $id: Int!
  ) {
    deleteEmbossing (
      embossingId: $id
    ) {
      success
      message
    }
  }
`

export const ADD_GRAPHIC = gql`
  mutation addGraphic (
    $input: AddGraphicInput
  ) {
    addGraphic (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_GRAPHIC = gql`
  mutation editGraphic (
    $input: EditGraphicInput
  ) {
    editGraphic (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_GRAPHIC = gql`
  mutation deleteGraphic (
    $id: Int!
  ) {
    deleteGraphic (
      graphicId: $id
    ) {
      success
      message
    }
  }
`

export const UPDATE_RESERVE_PACKAGING = gql`
  mutation updateReservePackaging (
    $input: [UpdateReservePackagingInput]
  ) {
    updateReservePackaging (
      input: $input
    ) {
      success
      message
    }
  }
`

export const ADD_FOILING = gql`
  mutation addFoiling (
    $input: AddFoilingInput
  ) {
    addFoiling (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_FOILING = gql`
  mutation editFoiling (
    $input: EditFoilingInput
  ) {
    editFoiling (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_FOILING = gql`
  mutation deleteFoiling (
    $id: Int!
  ) {
    deleteFoiling (
      foilingId: $id
    ) {
      success
      message
    }
  }
`

export const EDIT_CUTTING = gql`
  mutation editDieCut (
    $input: EditDieCutInput
  ) {
    editDieCut (
      input: $input
    ) {
      success
      message
    }
  }
`
export const UPDATE_DELIVERY = gql`
  mutation updateDelivery (
    $input: UpdateDeliveryInput
  ) {
    updateDelivery (
      input: $input
    ) {
      success
      message
    }
  }
`

export const ADD_CUTTING = gql`
  mutation addDieCut (
    $input: AddDieCutInput
  ) {
    addDieCut (
      input: $input
    ) {
      success
      message
    }
  }
`

export const UPDATE_MARGIN_RATE = gql`
  mutation updateMarginRate (
    $input: [UpdateMarginRateInput]
  ) {
    updateMarginRate (
      input: $input
    ) {
      success
      message
    }
  }
`

export const ADD_PROMOTION = gql`
  mutation addPromotion (
    $input: AddPromotionInput
  ) {
    addPromotion (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_PROMOTION = gql`
  mutation editPromotion (
    $input: EditPromotionInput
  ) {
    editPromotion (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_PROMOTION = gql`
  mutation deletePromotion (
    $promotionId: Int!
  ) {
    deletePromotion (
      promotionId: $promotionId
    ) {
      success
      message
    }
  }
`

export const EDIT_PROMOTION_STATUS = gql`
  mutation editPromotionStatusByPromotionId (
    $promotionId: Int
    $status: String
  ) {
    editPromotionStatusByPromotionId (
      promotionId: $promotionId
      status: $status
    ) {
      success
      message
    }
  }
`

export const ADD_PLASTIC_WINDOW = gql`
  mutation addPlasticWindow (
    $input: AddPlasticWindowInput
  ) {
    addPlasticWindow (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_PLASTIC_WINDOW = gql`
  mutation editPlasticWindow (
    $input: EditPlasticWindowInput
  ) {
    editPlasticWindow (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_PLASTIC_WINDOW = gql`
  mutation deletePlasticWindow (
    $id: Int!
  ) {
    deletePlasticWindow (
      plasticWindowId: $id
    ) {
      success
      message
    }
  }
`

export const ADD_PLASTIC_WINDOW_THICKNESS = gql`
  mutation addPlasticWindowThickness (
    $input: AddPlasticWindowThicknessInput
  ) {
    addPlasticWindowThickness (
      input: $input
    ) {
      success
      message
    }
  }
`

export const EDIT_PLASTIC_WINDOW_THICKNESS = gql`
  mutation editPlasticWindowThickness (
    $input: EditPlasticWindowThicknessInput
  ) {
    editPlasticWindowThickness (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_PLASTIC_WINDOW_THICKNESS = gql`
  mutation deletePlasticWindowThickness (
    $id: Int!
  ) {
    deletePlasticWindowThickness (
      plasticWindowThicknessId: $id
    ) {
      success
      message
    }
  }
`

export const REARRANGE_PROMOTION = gql`
  mutation RearrangePromotion (
    $input: RearrangePromotionInput
  ) {
    rearrangePromotion (
      input: $input
    ) {
      success
      message
    }
  }
`

export const REARRANGE_PACKAGE = gql`
  mutation RearrangePackage (
    $input: RearrangePackageInput
  ) {
    rearrangePackage (
      input: $input
    ) {
      success
      message
    }
  }
`

export const UPDATE_QUOTATION_SUBMITTED_CONFIRM_TEXT = gql`
  mutation editQuotationSubmittedConfirmText (
    $confirmText: String
  ) {
    editQuotationSubmittedConfirmText (
      confirmText: $confirmText
    ) {
      success
      message
    }
  }
`

export const UPLOAD_QUOTATION_SUBMITTED_CONFIRM_FILE = gql`
  mutation uploadQuotationSubmittedConfirmFile (
    $input: QuotationSubmittedInput
  ) {
    uploadQuotationSubmittedConfirmFile (
      input: $input
    ) {
      success
      message
    }
  }
`

export const DELETE_QUOTATION_SUBMITTED_CONFIRM_FILE = gql`
  mutation deleteQuotationSubmittedConfirmFile {
    deleteQuotationSubmittedConfirmFile {
      success
      message
    }
  }
`

export const CREATE_PAYMENT = gql`
  mutation createPayment (
    $quotationId: Int!
    $amount: Float!
    $date: String!
    $month: String!
    $year: String!
    $hour: String!
    $minute: String!
    $name: String!
    $paymentMethod: Method!
    $evidenceFiles: [Upload]!
  ) {
    createPayment (
      input: {
        quotationId: $quotationId
        amount: $amount
        date: $date
        month: $month
        year: $year
        hour: $hour
        minute: $minute
        name: $name
        paymentMethod: $paymentMethod
        evidenceFiles: $evidenceFiles
      }
    ) {
      success
      message
      createdPayment {
        paymentId
        name
      }
    }
  }
`

// addPaymentEvidenceFiles
export const ADD_PAYMENT_EVIDENCE_FILES = gql`
  mutation addPaymentEvidenceFiles (
    $quotationId: Int!
    $images: [Upload]
  ) {
    addPaymentEvidenceFiles (
      input: {
        quotationId: $quotationId
        images: $images
      }
    ) {
      success
      message
    }
  }
`


export const UPDATE_BOX_DETAIL_QUOTATION = gql`
  mutation editBoxDetail (
    $id: Int!
    $input: UpdateBoxDetailInput
  ) {
    editBoxDetail (
      id: $id
      input: $input
    ) {
      success
      message
    }
  }
`

export const UPLOAD_GENERAL_FILE = gql`
  mutation uploadGeneralFile (
    $input: UploadGeneralFileInput
  ) {
    uploadGeneralFile (
      input: $input
    ) {
      success
      message
      generalFileUri
    }
  }
`

export const UPDATE_PRICE_NOTE = gql`
  mutation editPriceNote (
    $description: String
  ) {
    editPriceNote (
      description: $description
    ) {
      success
      message
    }
  }
`
