import styled, { css } from 'styled-components'
import { Radio } from 'antd'
import { theme, media } from '../../../../../styles/_variables'


export const OptionLabel = styled.label`
// display : block;
width: 100%;
> div{
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
> input{ /* HIDE RADIO */
    visibility: hidden; /* Makes input not-clickable */
    position: absolute; /* Remove input from document flow */
}
> input + div{ /* DIV STYLES */
    cursor:pointer;
    border:2px solid transparent;
}
> input:checked + div{ /* (RADIO CHECKED) DIV STYLES */
    font-weight: ${theme.fonts.style.semiBold}
    border: 3px solid rgba(${theme.color.primaryColorRGB}, 0.5) ;
}
`

export const OptionContentWrapper = styled.div`
display: grid;
align-content: space-between
width: 100%;
padding: 15px;
font-size: ${theme.fonts.size.subTitle};
color: ${theme.color.primaryColor}
text-align: center;
`

export const DivRadioOrderType = styled.div`
  border: 1px solid ${theme.color.primaryColor};
  width: 100%;
  max-width: ${props => (props.fullWidth ? '100%' : '95px')};
  margin: 2px;
  padding: 24px 0px;
  border-radius: 12px;
  background-color: ${props => (props.checked ? theme.color.greenPrimary : theme.color.offWhite)};
  cursor: pointer;
  display: flex;
  flex-flow: column;
`

export const RadioOrderType = styled(Radio)`
  margin-right: 0px;
  .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${theme.color.primaryColor};
  }
  .ant-radio-inner::after {
    background-color: ${theme.color.textColor};
  }
  .ant-radio-inner {
    border: 1px solid ${theme.color.primaryColor};
  }
  .ant-radio-inner:hover {
    border: 1px solid ${theme.color.primaryColor};
  }
  .ant-radio-checked::after {
    border: 1px solid ${theme.color.primaryColor};
  }
`

export const DivCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
`

export const Label = styled.div`
  color: ${props => (props.checked ? theme.color.offWhite : theme.color.primaryColor)};
  font-size: ${theme.fonts.size.normal};
  line-height: ${theme.fonts.size.normal};
`

export const ImgIcon = styled.img`
  width: 100%;
`

export const CoverImage = styled.div`
  background-color: ${props => (props.forbidden ? theme.color.greenPrimary : theme.color.offWhite)};
  padding: 8px;
  width: 50px;
  height: 50px;
  overflow: hidden;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid ${theme.color.primaryColor};
`

export default OptionLabel
