import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import 'antd/dist/antd.less'
import 'react-table/react-table.css'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
// eslint-disable-next-line
import './styles/_override.css?global'
import { theme } from './styles/_variables'

console.log('env: ', process.env)

ReactDOM.render(<ThemeProvider theme={theme}><App /></ThemeProvider>, document.getElementById('root'))
