import React, { PureComponent } from 'react'
import { H2 } from 'konsys-ui'
import { Container } from './styled'

export default class Landing extends PureComponent {
  render() {
    return (
      <Container>
        <H2>KONSYS CMS</H2>
      </Container>
    )
  }
}
