import styled from 'styled-components'
import { Button, Div } from 'konsys-ui'
import { theme } from '../../../styles/_variables'

export const TitleWrapper = styled.div`
display: flex;
flex-direction: row;
align-content: center;
align-items: center;
column-gap: 5px;
> h4{
  margin: 0;
}
`

export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`

export const ButtonAction = styled(Button)`
  padding: 1px 12px 0px 12px !important;
  height: 30px;
  border-radius: 20px;
  i {
  }
`

export const ActionGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  width: 200px;
  margin-right: 20px;
`

export const Container = styled.div`
  margin: 24px 0px 48px;
`

export const ContentDescription = styled.div`
  margin: 0px;
  font-size: ${theme.fonts.size.extraNormal};
  & > * {
    margin-bottom: 1px !important;
  }
`

export default Label
