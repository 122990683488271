import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Query, Mutation } from 'react-apollo'
import moment from 'moment'
import {
  message, Switch, Modal, Select,
} from 'antd'
import {
  Div, H3, Button, LoadingIcon, Icon,
} from 'konsys-ui'
import TagFilter from './components/tag-filter'
import { theme } from '../../../styles/_variables'
import PATH from '../../../constants/path'
import {
  ImageWrapper, ImageLabel, DivPackageList, DivPackage, DivAction,
  DivSearch, ImageLabelOther, InputSearch, Label, DivToolbarAction,
  DivToolbar, ButtonToolbar, ButtonSearch, SelectSort, LabelOptionSort,
  TagDivActive, StyledItem, StyledContainer, Container, StyledHandle,
  ButtonDiv,
} from './styled'
import { SHOW_PACKAGE, LIST_TAG } from '../../../constants/graphql/query'
import BreadCrumb from '../../../components/common/breadcrumb'


const { color } = theme

const OrderPredefineCreate = (props) => {
  const [textSearch, setTextSearch] = useState('')
  const [settingVisible, setSettingVisible] = useState(false)
  const [tmpSortBy, setTmpSortBy] = useState({
    order: 'ordering',
    sort: 'DESC',
  })
  const [sortBy, setSortBy] = useState({
    order: 'ordering',
    sort: 'DESC',
  })
  const [settingFilter, setSettingFilter] = useState(false)
  const [tmpFilterBy, setTmpFilterBy] = useState([])
  const [filterBy, setFilterBy] = useState([])

  const handleSort = (value) => {
    const result = value.split('_')
    setTmpSortBy({
      order: result[0],
      sort: result[1],
    })
    // setSettingVisible(false)
  }

  const handleCloseSort = (refetch) => {
    setSortBy({
      order: tmpSortBy.order,
      sort: tmpSortBy.sort,
    })
    setSettingVisible(false)
  }

  const handleOnChangeFilter = (index, isActive) => {
    const tmp = [...tmpFilterBy]
    tmp[index].active = isActive
    setTmpFilterBy(tmp)
  }

  const handleOnClearFilter = () => {
    let tmp = [...tmpFilterBy]
    tmp = tmp.map(item => ({
      ...item,
      active: false,
    }))
    setTmpFilterBy(tmp)
  }

  const handleOnCloseFilter = () => {
    setFilterBy(JSON.parse(JSON.stringify(tmpFilterBy)))
    setSettingFilter(false)
  }

  return (
    <Div display='flex' flexDirection='column'>
      <Query
        query={SHOW_PACKAGE}
        fetchPolicy='network-only'
        variables={{
          limit: 10000,
          offset: 0,
          name: textSearch,
          status: 'active',
          order: (sortBy && sortBy.order) || 'ordering',
          sort: (sortBy && sortBy.sort) || 'DESC',
          tag: filterBy.reduce((result, item) => {
            if (item.active) result.push(item.value)
            return result
          }, []),
        }}
      >
        {
          ({ loading, data, refetch }) => {
            if (data && data.showPackaging && !data.showPackaging.success) return <p>error something</p>
            if (loading) return <p>...loading</p>
            const packagingData = data.showPackaging && data.showPackaging.packaging.filter(item => item.ordering !== -1)
            return (
              <>
              <BreadCrumb link={{
                parent: [
                  {
                    text: 'Order ทั้งหมด',
                    link: PATH.quotationList,
                  },
                ],
                current: {
                  text: 'เลือกบรรจุภัณฑ์',
                },
              }} />
              <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                <H3 bold color={color.tertiaryColor} margin='32px 0'>เลือกบรรจุภัณฑ์</H3>
              </Div>
              <DivToolbar>
                <SearchBox value={textSearch} onSummit={value => setTextSearch(value)}/>
                <DivToolbarAction>
                  <ButtonToolbar
                    text='ตัวเลือก'
                    icon='fas fa-tasks'
                    inverse
                    width='200px'
                    onClick={() => setSettingFilter(true)}
                  />
                  <ButtonToolbar
                    text='การจัดเรียง'
                    icon='fas fa-sort-amount-down'
                    inverse
                    onClick={() => setSettingVisible(true)}
                    style={{
                      width: '224px',
                    }}
                  />
                </DivToolbarAction>
              </DivToolbar>
              <Div style={{ padding: '8px 0px' }}>
                {
                filterBy.reduce((result, item) => {
                  if (item.active) {
                    result.push(<TagDivActive
                      text={item.title}
                      icon='fa fa-tag fa-rotate-90'
                      inverse
                      onClick={async () => {
                        const tmp = [...tmpFilterBy]
                        tmp[item.key].active = false
                        setTmpFilterBy(tmp)
                        setFilterBy(JSON.parse(JSON.stringify(tmp)))
                      }}
                    />)
                  }
                  return result
                }, [])
                }
              </Div>
              <DivPackageList>
                {
                  loading ? <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div> : <Container>
                    {
                      packagingData.map((item, index) => (
                        <Package
                          key={index}
                          data={item}
                          history={props.history}
                        />
                      ))
                    }
                  </Container>
                }
              </DivPackageList>
              </>
            )
          }
        }
      </Query>
      <Modal
        title={
          <Label color={theme.color.textColor} style={{ fontWeight: theme.fonts.style.regular }}>
            <Icon icon='fas fa-sliders-h' margin='0px 8px 0px 0px' color={theme.color.primaryColor} style={{ fontWeight: theme.fonts.style.regular }} />
            เลือกตัวเลือกการค้นหาที่ต้องการ
          </Label>
        }
        visible={settingFilter}
        onCancel={handleOnCloseFilter}
        footer={null}
        closable={false}
      >
        <Div position='absolute' top='16px' right='16px' cursor='pointer' onClick={handleOnCloseFilter}>
          <Icon fontSize={theme.fonts.size.extraNormal} color={theme.color.grey} icon='far fa-times' />
        </Div>
        <Query
          query={LIST_TAG}
          variables={{
            limit: 1000,
            offset: 0,
            order: 'createdAt',
            sort: 'ASC',
          }}
          notifyOnNetworkStatusChange
          onCompleted={(data) => {
            setTmpFilterBy(data.listTag.tag.map((item, index) => ({
              key: index,
              value: item.tagId,
              title: item.name,
              active: false,
            })))
          }}
        >
        {
          ({ loading, data }) => {
            if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
            if (!data.listTag.success) return <p>error something</p>
            return (
              <TagFilter
                data={tmpFilterBy}
                onChange={handleOnChangeFilter}
                onClear={handleOnClearFilter}
              />
            )
          }
        }
        </Query>
      </Modal>
      <Modal
        title={
          <Label color={theme.color.textColor}>
            <Icon icon='fas fa-sort-amount-down' margin='0 15px 0 0' color={theme.color.primaryColor}/>
            เลือกวิธีจัดเรียง
          </Label>
        }
        visible={settingVisible}
        onCancel={handleCloseSort}
        footer={null}
      >
        <SelectSort
          dropdown
          optionFilterProp="children"
          size="large"
          menuItemSelectedIcon
          suffixIcon={
            <Icon fontSize={theme.fonts.size.extraNormal} icon='far fa-angle-down' />
          }
          value={tmpSortBy.order !== 'ordering' ? `${tmpSortBy.order}_${tmpSortBy.sort}` : 'กรุณาเลือกวิธีจัดเรียง'}
          onChange={handleSort}
        >
          <Select.Option key={'createdAtDESC'} value={'createdAt_DESC'}><LabelOptionSort>{'ตามวันเวลา (ใหม่ -> เก่า)'}</LabelOptionSort></Select.Option>
          <Select.Option key={'createdAtASC'} value={'createdAt_ASC'}><LabelOptionSort>{'ตามวันเวลา (เก่า -> ใหม่)'}</LabelOptionSort></Select.Option>
          <Select.Option key={'nameASC'} value={'name_ASC'}><LabelOptionSort>{'ตามชื่อ (A(ก) -> Z(ฮ))'}</LabelOptionSort></Select.Option>
          <Select.Option key={'nameDESC'} value={'name_DESC'}><LabelOptionSort>{'ตามชื่อ (Z(ฮ) -> A(ก))'}</LabelOptionSort></Select.Option>
        </SelectSort>
      </Modal>
    </Div>
  )
}


const SearchBox = ({
  value,
  onSummit,
}) => {
  const [textSearch, setTextSearch] = useState(value)

  const onSummitSearch = (e) => {
    e.preventDefault()
    onSummit(textSearch)
  }

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={onSummitSearch}
    >
      <DivSearch>
        <ButtonSearch
          htmlType="submit"
          text='ค้นหา'
          icon='fa fa-search'
          inverse
        />
        <InputSearch
          value={textSearch}
          placeholder="ค้นหา"
          onChange={ e => setTextSearch(e.target.value)}
        />
      </DivSearch>
    </form>
  )
}

const Image = ({ img }) => (
  <ImageWrapper img={img}/>
)

const ImageWithToggle = ({
  img,
  status,
  packageId,
  refetch,
}) => (
  <Div moreStyle={{ position: 'relative' }}>
    <Div moreStyle={{
      position: 'absolute',
      zIndex: 10,
      right: 0,
      padding: 8,
    }}>
    </Div>
    <Image
      img={img}
    />
  </Div>
)

const Package = ({
  data,
  history,
  refetch,
}) => {
  const file = data.packageFiles.filter(item => item.type === 'cover')
  const {
    name,
    status,
    packageId,
    ordering,
  } = data

  return (
    <StyledItem onClick={() => history.push(`${PATH.quotationPreDefinedCreate}/${packageId}`)}>
      <div className="content">
        <ImageWithToggle
          packageId={packageId}
          img={file.length > 0 ? file[0].packageFileUri : ''}
          status={status}
          refetch={refetch}
        />
        { ordering === -1 ? (
          <ImageLabelOther>{name}</ImageLabelOther>
        ) : (
          <>
            <ImageLabel>{name || 'Unknown'}</ImageLabel>
          </>
        )}
      </div>
    </StyledItem>
  )
}

export default OrderPredefineCreate
