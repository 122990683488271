import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { message, Popconfirm } from 'antd'
import {
  Div, H4, Button, LoadingIcon,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import { GET_PAPER_DETAIL } from '../../../../constants/graphql/query'
import { DELETE_PAPER, EDIT_PAPER } from '../../../../constants/graphql/mutation'
import { theme } from '../../../../styles/_variables'
import PaperForm from '../components/paper-form'
import PATH from '../../../../constants/path'
import BreadCrumb from '../../../../components/common/breadcrumb'


const { color } = theme


const PaperDetail = ({ match }) => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    coverPhoto: [],
    sampleVideo: [],
    imagePhoto: [],
    paperTable: [],
    frontCoating: [],
    backCoating: [],
    defaultFrontCoating: [],
    defaultBackCoating: [],
  })

  return (
    <Query
      fetchPolicy='network-only'
      query={GET_PAPER_DETAIL}
      variables={{
        id: parseInt(match.params.id, 10),
      }}
      notifyOnNetworkStatusChange
      onCompleted={(data) => {
        const paperData = data.getPaperDetail.paper
        if (data.getPaperDetail.success) {
          const paperTable = paperData.paperThickness.reduce((result, item) => {
            const index = result.findIndex(x => x.thickness === item.thickness)
            if (result.length > 0 && result[index] && result[index].thickness === item.thickness) {
              result[index].size.push({
                width: item.sizeWidth,
                height: item.sizeHeight,
              })
              result[index].price.push(item.price)
              result[index].paperThicknessId.push(item.paperThicknessId)
            } else {
              result.push({
                paperThicknessId: [item.paperThicknessId],
                key: result.length,
                thickness: item.thickness,
                size: [{
                  width: item.sizeWidth,
                  height: item.sizeHeight,
                }],
                price: [item.price],
              })
            }
            return result
          }, [])
          setFormValues({
            ...formValues,
            name: paperData.name,
            description: paperData.description,
            coverPhoto: paperData.paperFiles.filter(file => file.type === 'cover'),
            sampleVideo: paperData.paperFiles.filter(file => file.type === 'video'),
            imagePhoto: paperData.paperFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample'),
            paperTable,
            defaultFrontCoating: paperData.coatingFront.map(item => item.coatingId),
            defaultBackCoating: paperData.coatingBehind.map(item => item.coatingId),
          })
        } else {
          message.error(data.getPaperDetail.message)
          setTimeout(() => {
            window.location = PATH.paperSetting
          }, 1000)
        }
      }}
    >
      {
        ({ loading, data }) => {
          if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
          if (!data.getPaperDetail.paper) return <p>error something</p>
          const paperData = data.getPaperDetail.paper
          return (
            <>
              <Div display='flex' flexDirection='column'>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
                  <H4 bold color={color.primaryColor} margin='32px 0'>
                    <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                      <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                    </Link> {' > '}
                    <Link to='/package-setting/paper' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                    <H4 bold color={color.primaryColor}>กระดาษ</H4>
                    </Link> {' > '} แก้ไขข้อมูล
                  </H4>
                  <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                      <Mutation mutation={DELETE_PAPER}>
                        {
                          doDeletePaper => (
                            <Popconfirm
                              title='ยืนยันการรลบประเภทกระดาษหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'
                              onConfirm={() => {
                                doDeletePaper({
                                  variables: {
                                    id: parseInt(match.params.id, 10),
                                  },
                                }).then((res) => {
                                  if (res.data.deletePaper) {
                                    if (res.data.deletePaper.success) {
                                      message.success(res.data.deletePaper.message)
                                      if (res.data.deletePaper.packageError) {
                                        message.warning(res.data.deletePaper.packageErrorMessage)
                                      }
                                      setTimeout(() => {
                                        window.location = PATH.paperSetting
                                      }, 1200)
                                    } else {
                                      message.error(res.data.deletePaper.message)
                                    }
                                  }
                                })
                              }}
                              okText='ยืนยัน'
                              cancelText='ยกเลิก'
                            >
                              <Button text='ลบ' icon='fa fa-trash' ghost margin='0 16px 0 0' color={color.error}/>
                            </Popconfirm>
                          )
                        }
                      </Mutation>
                      <Mutation
                        mutation={EDIT_PAPER}
                      >
                        {doEditPaper => (
                          <Button
                            text='บันทึก'
                            icon='fa fa-save'
                            inverse
                            margin='0 16px 0 0'
                            loading={callMutation}
                            disabled={callMutation}
                            onClick={() => {
                              setCallMutation(true)
                              if (formValues.name === '') {
                                message.warning('กรุณากรอกข้อมูลชื่อประเภทกระดาษ')
                                setCallMutation(false)
                                setIsValidate(false)
                                return
                              }

                              if (formValues.name.match(new RegExp(/^\s/)) !== null) {
                                message.warning('กรุณากรอกข้อมูลชื่อประเภทกระดาษให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
                                setCallMutation(false)
                                setIsValidate(false)
                                return
                              }
                              const paperId = parseInt(match.params.id, 10)
                              const input = {
                                paperId,
                                name: formValues.name,
                                description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
                                coverPhoto: formValues.coverPhoto,
                                video: formValues.sampleVideo,
                                imagePhoto: formValues.imagePhoto,
                                paperThickness: formValues.paperTable.reduce((result, paper) => {
                                  for (let index = 0; index < paper.size.length; index += 1) {
                                    result.push({
                                      paperId,
                                      paperThicknessId: paper.paperThicknessId[index] || null,
                                      thickness: parseInt(paper.thickness, 10),
                                      sizeWidth: parseFloat(paper.size[index].width),
                                      sizeHeight: parseFloat(paper.size[index].height),
                                      price: parseFloat(paper.price[index]),
                                    })
                                  }
                                  return result
                                }, []),
                                coatingFrontId: formValues.frontCoating.reduce((result, item) => {
                                  if (item.active) result.push(item.key)
                                  return result
                                }, []),
                                coatingBehindId: formValues.backCoating.reduce((result, item) => {
                                  if (item.active) result.push(item.key)
                                  return result
                                }, []),
                              }
                              doEditPaper({
                                variables: {
                                  input,
                                },
                              }).then(({ data: dataResponse }) => {
                                if (dataResponse.editPaper && dataResponse.editPaper.success) {
                                  message.success(dataResponse.editPaper.message)
                                  setCallMutation(false)
                                  setTimeout(() => {
                                    window.location = PATH.paperSetting
                                  }, 350)
                                } else {
                                  setCallMutation(false)
                                  if (dataResponse.editPaper.message === 'ชื่อ ประเภทกระดาษ นี้มีอยู่ในระบบแล้ว') {
                                    setIsValidate(false)
                                  }
                                  setCallMutation(false)
                                  message.error(dataResponse.editPaper.message)
                                }
                              })
                            }}
                          />
                        )}
                      </Mutation>
                    </Div>
                  </Div>
                </Div>
              </Div>
              {
                data.getPaperDetail.paper && !loading && <PaperForm
                  formValues={formValues}
                  setFormValues={setFormValues}
                  isValidate={isValidate}
                  setIsValidate={setIsValidate}
                  action={'detail'}
                />
              }
            </>
          )
        }
      }
    </Query>
  )
}

export default PaperDetail
