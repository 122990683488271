import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { message } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import { LIST_MARGIN_RATE } from '../../../constants/graphql/query'
import { UPDATE_MARGIN_RATE } from '../../../constants/graphql/mutation'
import TableFixedColumns from '../../../components/common/table-fixed-column'
import { theme } from '../../../styles/_variables'
import { generateRowsIncrementStepTable as generateRows } from '../../../utils/util-services'
import PATH from '../../../constants/path'
import BreadCrumb from '../../../components/common/breadcrumb'


const { color } = theme


const MarginRateSetting = () => {
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    rowData: generateRows(),
  })
  return (
  <Div>
    <Div display='flex' flexDirection='row' justifyContent='space-between' margin='32px 0px 0px 0px' alignItems='center'>
      <Div display='flex' flexDirection='column'>
        <H4 bold color={color.primaryColor}>
          <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
            <H4 bold color={color.primaryColor}>Catalog Settings</H4>
          </Link> {' > '} Margin Rate
        </H4>
        </Div>
        <SaveButton formValues={formValues} callMutation={callMutation} setCallMutation={setCallMutation} />
    </Div>
    <TableMarginRate
      formValues={formValues}
      setFormValues={setFormValues}
    />
  </Div>
  )
}

const TableMarginRate = ({
  formValues,
  setFormValues,
}) => {
  const columns = [{
    title: 'จำนวนผลิต (ชิ้น)',
    dataIndex: 'quantity',
    align: 'center',
    alignCell: 'center',
    width: '40%',
  }, {
    title: 'Margin (%)',
    dataIndex: 'marginPercent',
    editable: true,
    align: 'center',
    alignCell: 'center',
    width: '40%',
  }]
  return (
    <Div>
      <Query
        query={LIST_MARGIN_RATE}
        notifyOnNetworkStatusChange
        fetchPolicy='network-only'
        variables={{
          limit: 20,
          offset: 0,
        }}
        onCompleted={(data) => {
          const marginRageData = data.listMarginRate.marginRate.map(item => ({
            key: item.marginRateId,
            quantity: item.produceQuantity,
            marginPercent: item.marginPercent,
          }))
          setFormValues({ ...formValues, rowData: marginRageData })
        }}
      >
        {
          ({ loading }) => (
            <Div style={{ width: '60%' }}>
              <TableFixedColumns
                loading={loading}
                columns={columns}
                formValues={formValues}
                setFormValues={setFormValues}
                dataIndexFix={'marginPercent'}
              />
            </Div>
          )
        }
      </Query>
    </Div>
  )
}

const SaveButton = ({
  formValues,
  callMutation,
  setCallMutation,
}) => (
  <Mutation mutation={UPDATE_MARGIN_RATE}>
    {
      doUpdateMarginRate => (
        <Button
          text='บันทึก'
          icon='fa fa-save'
          inverse
          margin='0 16px 0 0'
          onClick={() => {
            setCallMutation(true)
            const input = formValues.rowData.map(row => ({
              marginRateId: row.key,
              produceQuantity: row.quantity,
              marginPercent: parseFloat(row.marginPercent, 10),
            }))
            doUpdateMarginRate({
              variables: {
                input,
              },
            }).then(({ data }) => {
              if (data.updateMarginRate) {
                setCallMutation(false)
                message.success(data.updateMarginRate.message)
              } else {
                setCallMutation(false)
                message.error(data.updateMarginRate.message)
              }
            })
          }}
          disabled={callMutation}
        />
      )
    }
  </Mutation>
)

export default MarginRateSetting
