/* eslint-disable no-restricted-globals */
import React, { useState } from 'react'
import {
  WidthContainer, FullWidthContainer,
} from './styled'
import FoilingTitleInput from '../title'
import FoilingDescriptionInput from '../description'
import FoilingCoverImageInput from '../cover-image'
import FoilingSampleVideoInput from '../sample-video'
import FoilingSampleImageInput from '../sample-image'
import FoilingBlockCostTable from '../block-cost-table'
import FoilingBlockCostMinimumInput from '../block-cost-minimum'
import FoilingCostTable from '../cost-table'
import FoilingCostMinimumInput from '../cost-minimum'
import FoilingOperationCostTable from '../operation-cost-table'

const FoilingForm = ({
  formValues,
  setFormValues,
  isValidate,
  setIsValidate,
  action,
}) => {
  const [, setDisabledButton] = useState(true)

  const handleOnSubmitEditRow = (key, value) => {
    const tmp = [...formValues.foilingBlockCost]
    const tmpValue = value
    if (tmpValue.column1 === null || isNaN(tmpValue.column1)) tmpValue.column1 = 0
    if (tmpValue.column2 === null || isNaN(tmpValue.column2)) tmpValue.column2 = 0
    if (tmpValue.column3 === null || isNaN(tmpValue.column3)) tmpValue.column3 = 0
    if (tmpValue.column4 === null || isNaN(tmpValue.column4)) tmpValue.column4 = 0
    if (tmpValue.column5 === null || isNaN(tmpValue.column5)) tmpValue.column5 = 0
    tmp[key] = tmpValue
    setFormValues({ ...formValues, foilingBlockCost: tmp })
  }

  const handleOnSubmitDeleteRow = (value) => {
    setFormValues({ ...formValues, foilingBlockCost: value })
  }

  const handleOnAddRow = (value) => {
    setFormValues({ ...formValues, foilingBlockCost: value })
  }
  return (
    <>
      <WidthContainer>
        <FoilingTitleInput
          label={'ชื่อการปั๊มฟอยล์-ทองเค'}
          data={formValues}
          onInputChange={setFormValues}
          setDisabledButton={setDisabledButton}
          setIsValidate={setIsValidate}
          isValidate={isValidate}
        />
        <FoilingDescriptionInput
          data={formValues}
          onInputChange={setFormValues}
        />
        <FoilingCoverImageInput
          data={formValues}
          newFileList={formValues.coverPhoto || []}
          setNewFileList={setFormValues}
        />
        <FoilingSampleVideoInput
          data={formValues}
          newFileList={formValues.sampleVideo || []}
          setNewFileList={setFormValues}
        />
        <FoilingSampleImageInput
          data={formValues}
          newFileList={formValues.imagePhoto || []}
          setNewFileList={setFormValues}
        />
      </WidthContainer>
      <FoilingBlockCostTable
        data={formValues.foilingBlockCost}
        onSubmitEditRow={handleOnSubmitEditRow}
        onSubmitDeleteRow={handleOnSubmitDeleteRow}
        onAddRow={handleOnAddRow}
      />
      <FoilingBlockCostMinimumInput
        data={formValues}
        onInputChange={setFormValues}
        action={action}
      />
      <FullWidthContainer>
        <FoilingCostTable
          data={formValues}
          onChange={setFormValues}
          formValues={formValues}
        />
        <FoilingCostMinimumInput
          data={formValues}
          onInputChange={setFormValues}
          action={action}
        />
        <FoilingOperationCostTable
          data={formValues}
          onChange={setFormValues}
          formValues={formValues}
        />
      </FullWidthContainer>
    </>
  )
}

export default FoilingForm
