import React, { useState } from 'react'
import { Mutation, Query } from 'react-apollo'
import {
  Popconfirm, message, Switch,
} from 'antd'
import {
  Div, H4, LoadingIcon, Button,
} from 'konsys-ui'
import { theme } from '../../../styles/_variables'
import { GET_PROMOTION_DETAIL } from '../../../constants/graphql/query'
import { EDIT_PROMOTION, DELETE_PROMOTION } from '../../../constants/graphql/mutation'
import PATH from '../../../constants/path'
import BreadCrumb from '../../../components/common/breadcrumb'
import PromotionForm from '../components/promotion-form'


const { color } = theme
const SwitchWithLabel = ({ label, onChange, value }) => (
  <Div display={'flex'} flexDirection='row' alignItems='center'>
    {label && <H4 display={'block'} margin='0 8px' color={theme.color.primaryColor}>{label}</H4>}
    <Switch onChange={onChange} defaultChecked={value} />
  </Div>
)

const PromotionDetail = ({ match }) => {
  const [isValidate, setIsValidate] = useState(true)
  const [formValues, setFormValues] = useState({
    coverPhoto: [],
    name: '',
    description: '',
    status: false,
  })
  const [callMutation, setCallMutation] = useState(false)
  const promotionId = parseInt(match.params.id, 10)

  return (
      <Div display='flex' flexDirection='column'>
        <Query
          query={GET_PROMOTION_DETAIL}
          fetchPolicy='network-only'
          variables={{
            id: parseInt(match.params.id, 10),
          }}
          notifyOnNetworkStatusChange
          onCompleted={(data) => {
            const promotionData = data.getPromotionDetail.promotion
            if (data.getPromotionDetail.success) {
              setFormValues({
                coverPhoto: promotionData.cover_uri ? [{ promotionFileUri: promotionData.cover_uri, type: 'cover', name: promotionData.cover_filename }] : [],
                name: promotionData.name,
                description: promotionData.description,
                status: promotionData.status === 'active',
                isFree: promotionData.isFree,
              })
            } else {
              message.error(data.getPromotionDetail.message)
              setTimeout(() => {
                window.location = PATH.promotionList
              }, 1000)
            }
          }}
        >
          {
            ({ loading, data }) => {
              if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
              if (!data.getPromotionDetail.success) return <p>error something</p>
              const promotionData = data.getPromotionDetail.promotion
              return (
                <>
                  <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
                      <H4 bold color={color.primaryColor} margin='32px 0'>แก้ไขโปรโมชัน (Edit Promotion)</H4>
                      <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
                        <SwitchWithLabel
                          label={'สถานะ'}
                          onChange={checked => setFormValues({ ...formValues, status: checked })}
                          value={promotionData.status === 'active'}
                        />
                        <br/>
                        <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                          <DeleteButton promotionId={promotionId} />
                          <SaveButton
                            promotionId={promotionId}
                            formValues={formValues}
                            callMutation={callMutation}
                            setCallMutation={setCallMutation}
                            setIsValidate={setIsValidate}
                          />
                        </Div>
                      </Div>
                  </Div>
                  <PromotionForm
                    formValues={formValues}
                    setFormValues={setFormValues}
                    isValidate={isValidate}
                    setIsValidate={setIsValidate}
                  />
                </>
              )
            }
          }
        </Query>
      </Div>
  )
}

const DeleteButton = ({ promotionId }) => (
  <Mutation mutation={DELETE_PROMOTION}>
    {
      doDeletePromotion => (
        <Popconfirm
          title="ยืนยันการลบโปรโมชั่นหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก"
          onConfirm={() => {
            doDeletePromotion({
              variables: {
                promotionId: parseInt(promotionId, 10),
              },
            }).then((res) => {
              if (res.data.deletePromotion) {
                if (res.data.deletePromotion.success) {
                  message.success(res.data.deletePromotion.message)
                  setTimeout(() => {
                    window.location = PATH.promotionList
                  }, 100)
                } else {
                  message.error(res.data.deletePromotion.message)
                }
              }
            })
          }}
          okText='ยืนยัน'
          cancelText='ยกเลิก'
        >
          <Button text='ลบ' icon='fa fa-trash' ghost margin='0 16px 0 0' color={color.error}/>
        </Popconfirm>
      )
    }
  </Mutation>
)

const SaveButton = ({
  promotionId,
  formValues,
  callMutation,
  setCallMutation,
  setIsValidate,
}) => (
  <Mutation
    mutation={EDIT_PROMOTION}
  >
    {
      doEditPromotion => (
        <Button
          text='บันทึก'
          icon='fa fa-floppy-o'
          inverse
          onClick={async () => {
            setCallMutation(true)
            if (formValues.name === '') {
              message.warning('กรุณากรอกข้อมูลชื่อโปรโมชั่น')
              setCallMutation(false)
            }

            const input = {
              promotionId,
              name: formValues.name,
              description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
              status: formValues.status ? 'active' : 'inactive',
              coverPhoto: formValues.coverPhoto,
              isFree: formValues.isFree,
            }
            doEditPromotion({
              variables: {
                input,
              },
            }).then(({ data }) => {
              if (data.editPromotion) {
                if (data.editPromotion.success) {
                  setCallMutation(false)
                  message.success(data.editPromotion.message)
                  setTimeout(() => {
                    window.location = PATH.promotionList
                  }, 100)
                } else {
                  setCallMutation(false)
                  if (data.editPromotion.message === 'ชื่อ Promotion นี้มีอยู่ในระบบแล้ว') {
                    setIsValidate(false)
                    setCallMutation(false)
                  }
                  message.error(data.editPromotion.message)
                }
              }
            })
          }}
          disabled={callMutation}
        />
      )
    }
  </Mutation>
)

export default PromotionDetail
