import React, { PureComponent } from 'react'
import _ from 'lodash'
import { TabWrapper, TabPane } from './styled'
import CONFIG from '../../constants/menu-config'

export default class UsersListTabWrapper extends PureComponent {
  render() {
    return (
      <TabWrapper>
      {
        _.find(CONFIG.subMenu, o => o.key === _.replace(this.props.match.path, '/', '')).menu.map((v, i) => (
          <TabPane tab={v.label} key={i}><span>{v.label}</span></TabPane>
        ))
      }
      </TabWrapper>
    )
  }
}
