import React, { useState } from 'react'
import _ from 'lodash'
import Lightbox from 'react-images'
import { Query, Mutation } from 'react-apollo'
import { message, Popconfirm } from 'antd'
import {
  Div, Icon, Button, LoadingIcon,
} from 'konsys-ui'
import { SHOW_GALLERY } from '../../constants/graphql/query'
import { ADD_GALLERY, DELETE_GALLERY } from '../../constants/graphql/mutation'
import { theme } from '../../styles/_variables'
import UploadFile from '../../components/common/upload'
import {
  ImageWrapper, ImageWithButtonWrapper,
} from './styled'

const Image = ({ img, onClick }) => (
  <ImageWrapper img={img} onClick={() => onClick()}>
    <Icon icon='far fa-search-plus' />
  </ImageWrapper>
)

const GalleryContainer = () => {
  const [openLightBox, setOpenLightBox] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [newFileList, setNewFileList] = useState([])
  const [disabledButton, setDisabledButton] = useState(false)

  return (
    <Query
      query={SHOW_GALLERY}
      variables={{
        limit: 10000,
      }}
    >
    {
      ({ loading, data, refetch }) => {
        if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
        if (!data.showGallery.success) return <p>error something</p>
        const galleryData = data.showGallery.gallery
        return (
          <Div>
            <Mutation
              mutation={ADD_GALLERY}
            >
            {
              doAddImage => (
                <Div>
                  <UploadFile
                    list={newFileList}
                    id='uploadFileId'
                    multiple
                    onChange={(fileList) => {
                      let upload = false
                      const allowFileType = ['jpg', 'jpeg', 'png']
                      for (let i = 0; i < fileList.length; i += 1) {
                        const splitName = fileList[i].name.split('.')
                        if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
                        || (newFileList.length > 0 && !_.isUndefined(_.find(newFileList, d => d.name === fileList[i].name)))) {
                          upload = false
                          break
                        } else {
                          upload = true
                        }
                      }
                      if (upload) {
                        const newUploadFileList = [...newFileList]
                        Object.keys(fileList).forEach((v) => {
                          if (v !== 'length') {
                            newUploadFileList.push(fileList[v])
                          }
                        })
                        setNewFileList(newUploadFileList)
                      } else {
                        message.error('สามารถอัพโหลดได้เฉพาะไฟล์ jpg, jpeg, png และไม่สามารถอัพรูปซ้ำได้')
                      }
                    }}
                    onRemoveFile={(fileName) => {
                      const newUploadFileList = [...newFileList]
                      _.remove(newUploadFileList, v => v.name === fileName)
                      setNewFileList(newUploadFileList)
                    }}
                    fileType="image/*"
                  />
                  <Button disabled={newFileList.length <= 0 || disabledButton} inverse fullWidth text='อัพโหลดรูปภาพใหม่' onClick={() => {
                    setDisabledButton(true)
                    doAddImage({
                      variables: {
                        images: newFileList,
                      },
                    }).then((res) => {
                      setDisabledButton(false)
                      if (res.data.addGallery) {
                        if (res.data.addGallery.success) {
                          setNewFileList([])
                          message.success(res.data.addGallery.message)
                          setTimeout(() => {
                            refetch()
                          }, 3000)
                        } else {
                          message.error(res.data.addGallery.message)
                        }
                      }
                    })
                  }} />
                </Div>
              )
            }
            </Mutation>
            <Div>
              <Div margin='24px -12px' display='flex' moreStyle={{ flexWrap: 'wrap' }}>
              {
                galleryData.map((v, i) => (
                <ImageWithButtonWrapper key={v.galleryId}>
                  <Image
                    img={v.galleryFileUri}
                    onClick={() => {
                      setCurrentImage(i)
                      setOpenLightBox(true)
                    }}
                  />
                  <Mutation
                    mutation={DELETE_GALLERY}
                  >
                  {
                    doDeleteGallery => (
                      <Popconfirm
                        title="ยืนยันที่จะลบภาพนี้ ?"
                        onConfirm={() => {
                          doDeleteGallery({
                            variables: {
                              id: v.galleryId,
                            },
                          }).then((resp) => {
                            if (resp.data.deleteGallery) {
                              if (resp.data.deleteGallery.success) {
                                message.success(resp.data.deleteGallery.message)
                                // do refetch
                                setTimeout(() => {
                                  refetch()
                                }, 3000)
                              } else {
                                message.error(resp.data.deleteGallery.message)
                              }
                            }
                          })
                        }}
                        okText="ยืนยัน"
                        cancelText="ยกเลิก"
                      >
                        <Button small ghost margin='8px 0 0' text='ลบรูปภาพนี้' />
                      </Popconfirm>
                    )
                  }
                  </Mutation>
                </ImageWithButtonWrapper>
                ))
              }
              </Div>
              <Lightbox
                images={galleryData.map(v => (
                  { src: v.galleryFileUri }
                ))}
                currentImage={currentImage}
                isOpen={openLightBox}
                onClickNext={() => setCurrentImage(currentImage + 1)}
                onClickPrev={() => setCurrentImage(currentImage - 1)}
                onClose={() => setOpenLightBox(false)}
                backdropClosesModal={true}
              />
            </Div>
          </Div>
        )
      }
    }
    </Query>
  )
}

export default GalleryContainer
