import gql from 'graphql-tag'

export const GET_MESSGAE = gql`
  query getMessage {
    getMessage{
      sucess
      description
    }
  }
`

export const GET_CLIENTS = gql`
  query getCustomerLists(
    $limit: Int
    $offset: Int
    $id: String
    $companyName: String
    $contactName: String
    $email: String
    $orderBy: OrderBy
    $orderField: customerOrderField
  ) {
    getCustomerLists(input: {
      limit: $limit
      offset: $offset
      id: $id
      companyName: $companyName
      contactName: $contactName
      email: $email
      orderBy: $orderBy
      orderField: $orderField
    }) {
      lists {
        id
        companyName
        contactName
        contactNumber
        email
        officeNumber
      }
      totalCount
      totalFilter
    }
  }
`

export const GET_CLIENT = gql`
  query customerInfo(
    $id: String
  ) {
    customerInfo(id: $id) {
      id
      companyId
      companyName
      credit
      companyAddress
      taxId
      officeNumber
      contactName
      contactNumber
      email
      fax
      websiteUrl
      note
      type
      status
      images {
        filename
        url
      }
      createdAt
      updatedAt
    }
  }
`

export const GET_QUOTATIONS = gql`
  query requestList(
    $orderBy: String
    $offset: Int
    $limit: Int
    $status: Status
    $fullName: String
    $refId: String
  ) {
    requestList (input: {
      orderBy: $orderBy
      offset: $offset
      limit: $limit
      status: $status
      fullName: $fullName
      refId: $refId
     }) {
       list{
        quotationId
        refId
        status
        createdAt
        address {
          addressId
          name
          surname
          companyName
        }
      }
      totalCount
      filterCount
    }
  }
`

export const GET_QUOTATION = gql`
  query requestById (
    $id: Int
  ) {
    requestById (
      id: $id
    ) {
      quotationId
      refId
      name
      status
      width
      length
      height
      quantity
      paperType
      paperThickness
      printingTechnique
      printingDescription
      taxRate
      totalPrice
      adminNote
      quotationPDF
      useBoxSuitDesign
      useCompleteDesign
      packageId
      incrementalSteps
      minimumManufacture
      withHoldingTax
      withHoldingTaxRate
      sampleFiles {
        sampleFileUri
      }
      designFiles {
        designFileUri
      }
      createdAt
      address {
        addressId
        name
        surname
        companyName
        companyType
        branch
        taxId
        telephoneNumber
        mobileNumber
        email
        lineId
        addressNumber
        district
        subDistrict
        province
        postalCode
      }
      shippingAddress {
        shippingAddressId
        name
        surname
        companyName
        telephoneNumber
        mobileNumber
        email
        lineId
        shippingAddressNumber
        district
        subDistrict
        province
        postalCode
        shippingDescription
      }
      designInfo {
        width
        length
        height
        quantity
        description
      }
      isGraphic
      paperTypeText
      printingTechniqueText
      isGenQuotationPDF
      isPreDefine
      produceType
      produceQuantity
      price
      totalCost{
        quantity
        totalCost
        totalRepeatCost
      }
      partDetail{
        packagePartId
        packagePartName
        order
        unitSize
        sizeA
        sizeB
        sizeC
        sizeD
        sizeE
        sizeF
        sizeG
        sizeH
        sizeI
        sizeJ
        paperId
        paperType
        paperThickness
        printingName
        printingSide
        printingBasicColorId
        printingBasicColor
        coatingFrontId
        coatingFront
        coatingBehindId
        coatingBehind
        spotUVFrontId
        spotUVFront
        spotUVBehindId
        spotUVBehind
        gluingId
        gluing
        foilingId
        foiling
        dieCut
        quotationPrintingExtraColor{
          quotationPrintingExtraColorId
          quotationPackagePartId
          printingExtraColorId
          printingExtraColor
        }
        quotationEmbossing{
          quotationPackagePartId
          quotationEmbossingId
          width
          length
          unit
        }
        quotationFoiling{
          quotationPackagePartId
          quotationFoilingId
          width
          length
          unit
        }
        quotationPlasticWindow{
          quotationPackagePartId
          quotationPlasticWindowId
          width
          length
          unit
          thickness
        }
        quotationAdditionalService{
          quotationAdditionalServiceId
          quotationPackagePartId
          additionalServiceId
          additionalService
        }
      }
    }
  }
`

export const GET_TAX_RATE = gql`
  query taxRate {
    taxRate {
      taxRate
      withHoldingTaxRate
    }
  }
`

export const GET_NEXT_POSSIBLE = gql`
  query showNextPossible (
    $id: Int!
    $presentStatus: String!
  ) {
    showNextPossible(input:{
      id: $id
      presentStatus: $presentStatus,
    })
  }
`

export const GET_ADMIN_NOTE = gql`
  query showAdminNote (
    $id: Int
  ) {
    showAdminNote (
      id: $id
    )
  }
`

export const GET_PAYMENT_EVIDENCE = gql`
  query showPayment (
    $id: Int
  ) {
    showPayment (
      id: $id
    ) {
      paymentId
      name
      dateTime
      networth
      paymentMethod
      evidenceFiles {
        evidenceId
        evidenceFileUri
        createdAt
      }
    }
  }
`

export const GET_SHIPPING_DETAIL = gql`
  query showShippingDetail (
    $id: Int
  ) {
    showShippingDetail (
      id: $id
    ) {
      shipping {
        shipper
        trackingId
        billingNo
        billingDate
      }
    }
  }
`

export const CHECK_TOKEN_RESET_PASSWORD = gql`
  query checkToken (
    $token: String!
  ) {
    checkToken (
      input: {
        token: $token
      }
    )
  }
`

export const SHOW_GALLERY = gql`
  query showGallery (
    $limit: Int
    $offset: Int
  ) {
    showGallery (
      input: {
        limit: $limit,
        offset: $offset,
      }
    ) {
      success
      message
      gallery {
        galleryId
        galleryFileUri
      }
    }
  }
`

export const SHOW_BANNER = gql`
  query showBanner {
    showBanner (
      input: {
        limit: 1000
      }
    ) {
      success
      message
      banner {
        bannerId
        bannerFileUri
      }
    }
  }
`

export const SHOW_POPUP = gql`
  query showPopUp {
    showPopUp {
      success
      message
      popUp {
        popUpId
        popUpFileUri
      }
    }
  }
`

export const SHOW_HISTORY = gql`
  query showStatusHistory (
    $id: Int
  ) {
    showStatusHistory (
      id: $id
    ) {
      status
      dateTime
    }
  }
`

export const SHOW_PACKAGE = gql`
  query showPackaging (
    $limit: Int
    $offset: Int
    $name: String
    $order: String
    $sort: String
    $tag: [Int]
    $status: String
  ) {
    showPackaging(
      input: { 
        limit: $limit, 
        offset: $offset,
        name: $name,
        order: $order,
        sort: $sort,
        tag: $tag,
        status: $status
      }
    ) {
        success
        message
        packaging {
          packageId
          name
          createdAt
          status
          ordering
          errorStatus
          packageFiles {
            ordering
            type
            packageFileUri
          }
        }
        count
      }
    }
`

export const GET_PACKAGE_DETAIL = gql`
query packaging (
  $id: Int
) {
  packaging(
    id: $id
  ) {
      success
      message
      package{
        packageId
        name
        description
        amountPart
        minimumManufacture
        incrementalSteps
        ordering
        userId
        status
        createdAt
        updatedAt
        packageFiles{
          packageFileId
          packageFileUri
          name
          packageId
          type
          ordering
          createdAt
          updatedAt
        }
        packageParts{
          packagePartId
          name
          packageId
          ordering
          amountVariablePart
          width
          length
          isDieCut
          createdAt
          updatedAt
          packagePartFiles{
            packagePartFileId
            packagePartFileUri
            name
            type
            ordering
            packagePartId
            createdAt
            updatedAt
          }
          paper{
            paperId
            name
            paperFiles {
              paperFileId
              type
              ordering
              paperFileUri
              paperId
              createdAt
              updatedAt
            }
            paperThickness{
              paperThicknessId
              paperId
              thickness
              sizeWidth
              sizeHeight
              price
              createdAt
              updatedAt
            }
            coatingFront{
              coatingId
              name
              description
              minimumCost
              createdAt
              updatedAt
            }
            coatingBehind{
              coatingId
              name
              description
              minimumCost
              createdAt
              updatedAt
            }
            description
            createdAt
            updatedAt
          }
          gluing{
            gluingId
            name
            description
            minimumCost
            createdAt
            updatedAt
          }
          additionalService{
            additionalServiceId
            name
            description
            minimumCost
            createdAt
            updatedAt
          }
        }
        tag{
          tagId
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const LIST_COATING = gql`
  query listCoating (
    $limit: Int
    $offset: Int
    $order: String
    $sort: String
  ) {
    listCoating(
      input: { 
        limit: $limit, 
        offset: $offset,
        order: $order,
        sort: $sort
      }
    ) {
        success
        message
        count
        coating {
          coatingId
          name
          description
          minimumCost
          createdAt
          updatedAt
          coatingFiles {
            coatingFileId
            type
            ordering
            coatingFileUri
            name
            createdAt
            updatedAt
          }
        }
        remark {
          remarkId
          moduleName
          description
          createdAt
          updatedAt
        }
      }
    }
`

export const GET_COATING_DETAIL = gql`
  query getCoatingDetail (
    $id: Int
  ) {
    getCoatingDetail(
      id: $id
    ) {
        success
        message
        coating {
          coatingId
          name
          description
          minimumCost
          createdAt
          updatedAt
          coatingFiles{
            coatingFileId
            type
            ordering
            coatingFileUri
            name
            coatingId
            createdAt
            updatedAt
          }
          coatingCostColumnName{
            coatingCostColumnNameId
            name
            type
            coatingId
            createdAt
            updatedAt
          }
          coatingCost{
            coatingCostId
            coatingId
            quantity
            column1
            column2
            column3
            column4
            column5
            createdAt
            updatedAt
          }
        }
      }
    }
`

export const LIST_GLUING = gql`
  query listGluing (
    $limit: Int
    $offset: Int
    $order: String
    $sort: String
  ) {
    listGluing(
      input: { 
        limit: $limit, 
        offset: $offset,
        order: $order,
        sort: $sort
      }
    ) {
        success
        message
        count
        gluing {
          gluingId
          name
          description
          minimumCost
          createdAt
          updatedAt
        }
        remark {
          remarkId
          moduleName
          description
          createdAt
          updatedAt
        }
      }
    }
`

export const GET_GLUING_DETAIL = gql`
  query getGluingDetail (
    $id: Int
  ) {
    getGluingDetail(
      id: $id
    ) {
        success
        message
        gluing {
          gluingId
          name
          description
          minimumCost
          createdAt
          updatedAt
          gluingCostColumnName{
            gluingCostColumnNameId
            name
            type
            gluingId
            createdAt
            updatedAt
          }
          gluingCost{
            gluingCostId
            gluingId
            quantity
            column1
            column2
            column3
            column4
            column5
            createdAt
            updatedAt
          }
        }
      }
    }
`

export const LIST_ADDITIONAL_SERVICE = gql`
  query listAdditionalService (
    $limit: Int
    $offset: Int
    $order: String
    $sort: String
  ) {
    listAdditionalService(
      input: { 
        limit: $limit, 
        offset: $offset,
        order: $order,
        sort: $sort
      }
    ) {
        success
        message
        count
        additionalService {
          additionalServiceId
          name
          description
          minimumCost
          createdAt
          updatedAt
        }
        remark {
          remarkId
          moduleName
          description
          createdAt
          updatedAt
        }
      }
    }
`

export const GET_ADDITIONAL_SERVICE_DETAIL = gql`
  query getAdditionalServiceDetail (
    $id: Int
  ) {
    getAdditionalServiceDetail(
      id: $id
    ) {
        success
        message
        additionalService {
          additionalServiceId
          name
          description
          minimumCost
          createdAt
          updatedAt
          additionalServiceCostColumnName{
            additionalServiceCostColumnNameId
            name
            type
            additionalServiceId
            createdAt
            updatedAt
          }
          additionalServiceCost{
            additionalServiceCostId
            additionalServiceId
            quantity
            column1
            column2
            column3
            column4
            column5
            createdAt
            updatedAt
          }
        }
      }
    }
`

export const LIST_TAG = gql`
  query listTag (
    $limit: Int
    $offset: Int
    $order: String
    $sort: String
  ) {
    listTag(
      input: { 
        limit: $limit, 
        offset: $offset,
        order: $order,
        sort: $sort
      }
    ) {
        success
        message
        count
        tag {
          tagId
          name
          createdAt
          updatedAt
        }
        remark {
          remarkId
          moduleName
          description
          createdAt
          updatedAt
        }
      }
    }
`

export const LIST_PAPER = gql`
  query listPaper (
    $limit: Int
    $offset: Int
    $order: String
    $sort: String
  ) {
    listPaper(
      input: { 
        limit: $limit, 
        offset: $offset,
        order: $order,
        sort: $sort
      }
    ) {
        success
        message
        count
        paper {
          paperId
          name
          description
          createdAt
          updatedAt
          paperFiles{
            paperFileId
            type
            ordering
            paperFileUri
            paperId
            createdAt
            updatedAt
          }
          paperThickness{
            paperThicknessId
            paperId
            thickness
            sizeWidth
            sizeHeight
            price
            createdAt
            updatedAt
          }
          coatingFront{
            coatingId
            name
            description
            minimumCost
            createdAt
            updatedAt
          }
          coatingBehind{
            coatingId
            name
            description
            minimumCost
            createdAt
            updatedAt
          }
        }
        remark {
          remarkId
          moduleName
          description
          createdAt
          updatedAt
        }
      }
    }
`

export const GET_PAPER_DETAIL = gql`
  query getPaperDetail (
    $id: Int
  ) {
    getPaperDetail(
      id: $id
    ) {
        success
        message
        paper {
          paperId
          name
          description
          createdAt
          updatedAt
          paperFiles{
            paperFileId
            type
            ordering
            paperFileUri
            name
            paperId
            createdAt
            updatedAt
          }
          paperThickness{
            paperThicknessId
            paperId
            thickness
            sizeWidth
            sizeHeight
            price
            createdAt
            updatedAt
          }
          coatingFront{
            coatingId
            name
            description
            minimumCost
            createdAt
            updatedAt
          }
          coatingBehind{
            coatingId
            name
            description
            minimumCost
            createdAt
            updatedAt
          }
        }
      }
    }
`

export const LIST_PRINTING = gql`
query listPrinting (
  $limit: Int
  $offset: Int
  $order: String
  $sort: String
) {
  listPrinting(
    input: { 
      limit: $limit, 
      offset: $offset,
      order: $order,
      sort: $sort
    }
  ) {
      success
      message
      count
      printing {
        printingId
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_PRINTING_FORM = gql`
query GetPrintingDetail {
  getPrintingDetail {
    printing {
      printingId
      name
      description
      printingBasicColor {
        printingBasicColorId
        printingId
        name
        colorQuantity
        createdAt
        updatedAt
      }
      printingExtraColor {
        printingExtraColorId
        printingId
        name
        createdAt
        updatedAt
      }
      printingType {
        printingTypeId
        printingId
        colorQuantity
        name
        width
        height
        createdAt
        updatedAt
      }
    }
    success
    message
  }
}
`

export const GET_PRINTING_DETAIL = gql`
query getPrintingDetail {
  getPrintingDetail {
      success
      message
      printing {
        printingId
        name
        description
        createdAt
        updatedAt
        printingBasicColor {
          printingBasicColorId
          printingId
          name
          colorQuantity
          createdAt
          updatedAt
        }
        printingExtraColor {
          printingExtraColorId
          printingId
          name
          createdAt
          updatedAt
        }
        printingOperationCost {
          printingOperationCostId
          printingId
          type
          quantity
          costPrintMachine5LessThanEqual160
          costPrintMachine5MoreThan160
          costPrintMachine3LessThanEqual160
          costPrintMachine3MoreThan160
          createdAt
          updatedAt
        }
        printingPlateColorCost {
          printingPlateColorCostId
          printingId
          colorQuantity
          costPrintMachine5
          costPrintMachine3
          createdAt
          updatedAt
        }
        printingType {
          printingTypeId
          printingId
          colorQuantity
          name
          width
          height
          createdAt
          updatedAt
        }
      }
      remark {
        remarkId
        moduleName
        description
        createdAt
        updatedAt
      }
    }
  }
`

export const LIST_SPOT_UV = gql`
  query listSpotUV (
    $limit: Int
    $offset: Int
    $order: String
    $sort: String
  ) {
    listSpotUV(
      input: { 
        limit: $limit, 
        offset: $offset,
        order: $order,
        sort: $sort
      }
    ) {
        success
        message
        count
        spotUV {
          spotUVId
          name
          description
          minimumCost
          spotUVFiles {
            spotUVFileId
            type
            ordering
            spotUVFileUri
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        remark {
          remarkId
          moduleName
          description
          createdAt
          updatedAt
        }
      }
    }
`

export const GET_SPOT_UV_DETAIL = gql`
  query getSpotUVDetail (
    $id: Int
  ) {
    getSpotUVDetail(
      id: $id
    ) {
        success
        message
        spotUV{
          spotUVId
          name
          description
          minimumCost
          createdAt
          updatedAt
          spotUVFiles{
            spotUVFileId
            type
            ordering
            spotUVFileUri
            name
            spotUVId
            createdAt
            updatedAt
          }
          spotUVCostColumnName{
            spotUVCostColumnNameId
            name
            type
            spotUVId
            createdAt
            updatedAt
          }
          spotUVCost{
            spotUVCostId
            spotUVId
            quantity
            column1
            column2
            column3
            column4
            column5
            createdAt
            updatedAt
          }
        }
      }
    }
`

export const LIST_DIE_CUT = gql`
  query listDieCut (
    $limit: Int
    $offset: Int
    $order: String
    $sort: String
  ) {
    listDieCut(
      input: { 
        limit: $limit, 
        offset: $offset,
        order: $order,
        sort: $sort
      }
    ) {
        success
        message
        count
        dieCut {
          dieCutId
          minimumCost
          createdAt
          updatedAt
        }
        remark {
          remarkId
          moduleName
          description
          createdAt
          updatedAt
        }
      }
    }
`

export const GET_DIE_CUT_DETAIL = gql`
  query getDieCutDetail (
    $id: Int
  ) {
    getDieCutDetail(
      id: $id
    ) {
        success
        message
        dieCut{
          dieCutId
          minimumCost
          createdAt
          updatedAt
          dieCutBlock{
            dieCutBlockId
            width
            height
            price
            dieCutId
            createdAt
            updatedAt
          }
          dieCutCostColumnName{
            dieCutCostColumnNameId
            name
            type
            dieCutId
            createdAt
            updatedAt
          }
          dieCutCost{
            dieCutCostId
            dieCutId
            quantity
            column1
            column2
            column3
            column4
            column5
            createdAt
            updatedAt
          }
        }
      }
    }
`

export const LIST_EMBOSSING = gql`
query listEmbossing (
  $limit: Int
  $offset: Int
  $order: String
  $sort: String
) {
  listEmbossing(
    input: { 
      limit: $limit, 
      offset: $offset,
      order: $order,
      sort: $sort
    }
  ) {
      success
      message
      count
      embossing {
        embossingId
        name
        description
        minimumCost
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_EMBOSSING_DETAIL = gql`
query getEmbosingDetail{
  getEmbossingDetail{
      success
      message
      embossing {
        embossingId
        name
        description
        minimumCost
        createdAt
        updatedAt
        remark {
          remarkId
          moduleName
          description
          createdAt
          updatedAt
        }
        embossingFiles {
          embossingFileId
          type
          ordering
          embossingFileUri
          name
          embossingId
          createdAt
          updatedAt
        }
        embossingCostColumnName {
          embossingCostColumnNameId
          name
          type
          embossingId
          createdAt
          updatedAt
        }
        embossingCost {
          embossingCostId
          embossingId
          quantity
          column1
          column2
          column3
          column4
          column5
          createdAt
          updatedAt
        }
        embossingBlockCost {
          embossingBlockCostId
          embossingId
          width
          height
          price
          createdAt
          updatedAt
        }
      }
      
    }
  }
`

export const LIST_GRAPHIC = gql`
query listGraphic (
  $limit: Int
  $offset: Int
  $order: String
  $sort: String
) {
  listGraphic(
    input: { 
      limit: $limit, 
      offset: $offset,
      order: $order,
      sort: $sort
    }
  ) {
      success
      message
      count
      graphic {
        graphicId
        name
        description
        graphicFileName
        graphicFileUri
        createdAt
        updatedAt
        isDefault
      }
      remark {
        remarkId
        moduleName
        description
        createdAt
        updatedAt
      }
    }
  }
`

export const LIST_RESERVE_PACKAGING = gql`
  query listReservePackaging (
    $limit: Int
    $offset: Int
    $order: String
    $sort: String
  ) {
    listReservePackaging(
      input: { 
        limit: $limit, 
        offset: $offset,
        order: $order,
        sort: $sort
      }
    ) {
        success
        message
        count
        reservePackaging {
          reservePackagingId 
          produceQuantity
          reserveQuantity
          createdAt
          updatedAt
        }
      }
    }
`

export const LIST_FOILING = gql`
query listFoiling (
  $limit: Int
  $offset: Int
  $order: String
  $sort: String
) {
  listFoiling(
    input: { 
      limit: $limit, 
      offset: $offset,
      order: $order,
      sort: $sort
    }
  ) {
      success
      message
      count
      foiling {
        foilingId
        name
        description
        minimumCost
        minimumBlockCost
        createdAt
        updatedAt
        foilingFiles {
          foilingFileId
          type
          ordering
          foilingFileUri
          name
          foilingId
          createdAt
          updatedAt
        }
      }
      remark {
        remarkId
        moduleName
        description
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_FOILING_DETAIL = gql`
query getFoilingDetail (
  $id: Int
) {
  getFoilingDetail(
    id: $id
  ) {
      success
      message
      foiling {
        foilingId
        name
        description
        minimumCost
        minimumBlockCost
        createdAt
        updatedAt
        foilingFiles {
          foilingFileId
          type
          ordering
          foilingFileUri
          name
          foilingId
          createdAt
          updatedAt
        }
        foilingCostColumnName {
          foilingCostColumnNameId
          name
          type
          foilingId
          createdAt
          updatedAt
        }
        foilingCost {
          foilingCostId
          foilingId
          quantity
          column1
          column2
          column3
          column4
          column5
          createdAt
          updatedAt
        }
        foilingBlockCost {
          foilingBlockCostId
          foilingId
          width
          height
          price
          createdAt
          updatedAt
        }
        foilingOperationCostColumnName {
          foilingOperationCostColumnNameId
          name
          type
          foilingId
          createdAt
          updatedAt
        }
        foilingOperationCost {
          foilingOperationCostId
          foilingId
          quantity
          column1
          column2
          column3
          column4
          column5
          createdAt
          updatedAt
        }
      }
      
    }
  }
`

export const GET_DIE_CUTTING = gql`
query getDieCutDetail {
  getDieCutDetail {
      success
      message
      dieCut {
        dieCutId
        minimumCost
        createdAt
        updatedAt
        dieCutBlock {
          dieCutBlockId
          width
          height
          price
          dieCutId
        }
        dieCutCostColumnName {
          dieCutCostColumnNameId
          name
          type
          dieCutId
        }
        dieCutCost {
          dieCutCostId
          dieCutId
          quantity
          column1
          column2
          column3
          column4
          column5
        }
      } 
    }
  }
`

export const LIST_DELIVERY = gql`
query listDelivery (
  $limit: Int
  $offset: Int
  $order: String
  $sort: String
) {
  listDelivery(
    input: { 
      limit: $limit, 
      offset: $offset,
      order: $order,
      sort: $sort
    }
  ) {
      success
      message
      count
      delivery {
        deliveryId
        quantity
        price
        column1
        column2
        column3
        column4
        column5
        createdAt
        updatedAt
      }
      deliveryColumnName {
        deliveryColumnNameId
        name
        type
        createdAt
        updatedAt
      }
    }
  }
`

export const LIST_MARGIN_RATE = gql`
  query listMarginRate (
    $limit: Int
    $offset: Int
    $order: String
    $sort: String
  ) {
    listMarginRate(
      input: { 
        limit: $limit, 
        offset: $offset,
        order: $order,
        sort: $sort
      }
    ) {
        success
        message
        count
        marginRate {
          marginRateId 
          produceQuantity
          marginPercent
          createdAt
          updatedAt
        }
      }
    }
`

export const LIST_PROMOTION = gql`
  query listPromotion (
    $limit: Int
    $offset: Int
    $order: String
    $sort: String
    $status: String
  ) {
    listPromotion(
      input: { 
        limit: $limit, 
        offset: $offset,
        order: $order,
        sort: $sort,
        status: $status
      }
    ) {
        success
        message
        count
        promotion {
          promotionId
          name
          description
          cover_uri
          status
          isFree
          ordering
          createdAt
          updatedAt
        }
      }
    }
`

export const GET_PROMOTION_DETAIL = gql`
  query getPromotionDetail (
    $id: Int
  ) {
    getPromotionDetail(
      id: $id
    ) {
        success
        message
        promotion {
          promotionId
          name
          description
          cover_uri
          cover_filename
          status
          isFree
          ordering
          createdAt
          updatedAt
        }
      }
    }
`

export const LIST_PLASTIC_WINDOW = gql`
  query listPlasticWindow (
    $limit: Int
    $offset: Int
    $order: String
    $sort: String
  ) {
    listPlasticWindow(
      input: { 
        limit: $limit, 
        offset: $offset,
        order: $order,
        sort: $sort
      }
    ) {
        success
        message
        count
        plasticWindow {
          plasticWindowId
          name
          description
          createdAt
          updatedAt
        }
        remark {
          remarkId
          moduleName
          description
          createdAt
          updatedAt
        }
      }
    }
`

export const GET_PLASTIC_WINDOW_DETAIL = gql`
  query getPlasticWindowDetail (
    $id: Int
  ) {
    getPlasticWindowDetail(
      id: $id
    ) {
        success
        message
        plasticWindow {
          plasticWindowId
          name
          description
          createdAt
          updatedAt
          remark {
            remarkId
            moduleName
            description
            createdAt
            updatedAt
          }
          plasticWindowFiles {
            plasticWindowFileId
            type
            ordering
            plasticWindowFileUri
            name
            plasticWindowId
            createdAt
            updatedAt
          }
          plasticWindowThickness {
            plasticWindowThicknessId
            thickness
            plasticWindowId
            minimumCost
            minimumOperationCost
            createdAt
            updatedAt
            plasticWindowThicknessTypeCost {
              plasticWindowThicknessCostColumnName {
                plasticWindowThicknessCostColumnNameId
                name
                type
                typeCost
                plasticWindowThicknessId
                createdAt
                updatedAt
              }
              plasticWindowThicknessCost {
                plasticWindowThicknessCostId
                plasticWindowThicknessId
                type
                quantity
                column1
                column2
                column3
                column4
                column5
                createdAt
                updatedAt
              }
            }
            plasticWindowThicknessTypeOperationCost {
              plasticWindowThicknessCostColumnName {
                plasticWindowThicknessCostColumnNameId
                name
                type
                typeCost
                plasticWindowThicknessId
                createdAt
                updatedAt
              }
              plasticWindowThicknessCost {
                plasticWindowThicknessCostId
                plasticWindowThicknessId
                type
                quantity
                column1
                column2
                column3
                column4
                column5
                createdAt
                updatedAt
              }
            }
          }
        }
      }
    }
`

export const GET_PLASTIC_WINDOW_THICKNESS_DETAIL = gql`
  query getPlasticWindowThicknessDetail (
    $id: Int
  ) {
    getPlasticWindowThicknessDetail(
      id: $id
    ) {
        success
        message
        plasticWindowThickness {
          plasticWindowThicknessId
          plasticWindowId
          thickness
          minimumCost
          minimumOperationCost
          createdAt
          updatedAt
          plasticWindowThicknessTypeCost {
            plasticWindowThicknessCostColumnName {
              plasticWindowThicknessCostColumnNameId
              name
              type
              typeCost
              plasticWindowThicknessId
              createdAt
              updatedAt
            }
            plasticWindowThicknessCost {
              plasticWindowThicknessCostId
              plasticWindowThicknessId
              type
              quantity
              column1
              column2
              column3
              column4
              column5
              createdAt
              updatedAt
            }
          }
          plasticWindowThicknessTypeOperationCost {
            plasticWindowThicknessCostColumnName {
              plasticWindowThicknessCostColumnNameId
              name
              type
              typeCost
              plasticWindowThicknessId
              createdAt
              updatedAt
            }
            plasticWindowThicknessCost {
              plasticWindowThicknessCostId
              plasticWindowThicknessId
              type
              quantity
              column1
              column2
              column3
              column4
              column5
              createdAt
              updatedAt
            }
          }
        }
      }
    }
`

export const GET_PRINTER_SIZE = gql`
  query printerSize (
    $input: PrinterSizeInput
  ) {
    printerSize (
      input: $input
    ) {
        success
        message
      }
    }
`

export const PRICE_CALCULATOR = gql`
  query priceCalculator (
    $input: PriceCalculatorInput
  ) {
    priceCalculator (
      input: $input
    ) {
        success
        message
        totalCost{
          quantity
          totalCost
          totalRepeatCost
          rawCost
          rawRepeatCost
          solution
          repeatSolution
          textSolution
          marginRate
          reserveQuantity
        }
        partDetail{
          packagePartName
          order
          detailSize{
            unit
            width
            length
          }
          detailPrinter{
            printer
            width
            length
          }
          detailActualPrinter{
            actualWidth
            actualLength
            dimensionCut
            paper
            paperThickness
            paperWidth
            paperLength
            maxYield
          }
          detailPrintingCost{
            printingName
            side
            printingBasicColor
            printingExtraColor
          }
          detailCoatingCost{
            coatingFront
            coatingBehind
          }
          detailSpotUVCost{
            spotUVFront
            spotUVBehind
          }
          detailEmbossingCost{
            order
            unit
            width
            length
          }
          detailFoilingCost{
            foiling
            foilingPart{
              order
              unit
              width
              length
            }
          }
          detailDieCutCost
          detailPlasticWindowCost{
            order
            unit
            thickness
            width
            length
          }
          detailGluingCost
          detailAdditionalServiceCost
        }
        costDetail{
          order
          paperCost{
            quantity
            paperCost
            paperRepeatCost
            solution
            repeatSolution
            textSolution
          }
          printingCost{
            quantity
            printingCost
            printingRepeatCost
            solution
            repeatSolution
            textSolution
          }
          coatingCost{
            quantity
            coatingCost
            coatingRepeatCost
            solution
            repeatSolution
            textSolution
            coatingFrontMinimumCost
            coatingBehindMinimumCost
          }
          spotUVCost{
            quantity
            spotUVCost
            spotUVRepeatCost
            solution
            repeatSolution
            textSolution
            spotUVFrontMinimumCost
            spotUVBehindMinimumCost
          }
          embossingCost{
            quantity
            embossingCost
            embossingRepeatCost
            solution
            repeatSolution
            textSolution
            minimumCost
          }
          foilingCost{
            quantity
            foilingCost
            foilingRepeatCost
            solution
            repeatSolution
            textSolution
            minimumBlockCost
            minimumCost
          }
          dieCutCost{
            quantity
            dieCutCost
            dieCutRepeatCost
            solution
            repeatSolution
            textSolution
            minimumCost
          }
          plasticWindowCost{
            quantity
            plasticWindowCost
            plasticWindowRepeatCost
            solution
            repeatSolution
            textSolution
            minimumPlasticCost
            minimumAttachmentCost
          }
          gluingCost{
            quantity
            gluingCost
            gluingRepeatCost
            solution
            repeatSolution
            textSolution
            minimumCost
          }
          additionalServiceCost{
            quantity
            additionalServiceCost
            additionalServiceRepeatCost
            solution
            repeatSolution
            textSolution
            minimumCost
          }
          shippingCost{
            quantity
            shippingCost
            shippingRepeatCost
          }
        }
      }
    }
`

export const PRICE_CALCULATOR_QUANTITY = gql`
  query priceCalculatorQuantity (
    $input: PriceCalculatorQuantityInput
  ) {
    priceCalculatorQuantity (
      input: $input
    ) {
        success
        message
        totalCost{
          quantity
          totalCost
          totalRepeatCost
          rawCost
          rawRepeatCost
          solution
          repeatSolution
          textSolution
          marginRate
          reserveQuantity
        }
        partDetail{
          packagePartName
          order
          detailSize{
            unit
            width
            length
          }
          detailPrinter{
            printer
            width
            length
          }
          detailActualPrinter{
            actualWidth
            actualLength
            dimensionCut
            paper
            paperThickness
            paperWidth
            paperLength
            maxYield
          }
          detailPrintingCost{
            printingName
            side
            printingBasicColor
            printingExtraColor
          }
          detailCoatingCost{
            coatingFront
            coatingBehind
          }
          detailSpotUVCost{
            spotUVFront
            spotUVBehind
          }
          detailEmbossingCost{
            order
            unit
            width
            length
          }
          detailFoilingCost{
            foiling
            foilingPart{
              order
              unit
              width
              length
            }
          }
          detailDieCutCost
          detailPlasticWindowCost{
            order
            unit
            thickness
            width
            length
          }
          detailGluingCost
          detailAdditionalServiceCost
        }
        costDetail{
          order
          paperCost{
            quantity
            paperCost
            paperRepeatCost
            solution
            repeatSolution
            textSolution
          }
          printingCost{
            quantity
            printingCost
            printingRepeatCost
            solution
            repeatSolution
            textSolution
          }
          coatingCost{
            quantity
            coatingCost
            coatingRepeatCost
            solution
            repeatSolution
            textSolution
            coatingFrontMinimumCost
            coatingBehindMinimumCost
          }
          spotUVCost{
            quantity
            spotUVCost
            spotUVRepeatCost
            solution
            repeatSolution
            textSolution
            spotUVFrontMinimumCost
            spotUVBehindMinimumCost
          }
          embossingCost{
            quantity
            embossingCost
            embossingRepeatCost
            solution
            repeatSolution
            textSolution
            minimumCost
          }
          foilingCost{
            quantity
            foilingCost
            foilingRepeatCost
            solution
            repeatSolution
            textSolution
            minimumBlockCost
            minimumCost
          }
          dieCutCost{
            quantity
            dieCutCost
            dieCutRepeatCost
            solution
            repeatSolution
            textSolution
            minimumCost
          }
          plasticWindowCost{
            quantity
            plasticWindowCost
            plasticWindowRepeatCost
            solution
            repeatSolution
            textSolution
            minimumPlasticCost
            minimumAttachmentCost
          }
          gluingCost{
            quantity
            gluingCost
            gluingRepeatCost
            solution
            repeatSolution
            textSolution
            minimumCost
          }
          additionalServiceCost{
            quantity
            additionalServiceCost
            additionalServiceRepeatCost
            solution
            repeatSolution
            textSolution
            minimumCost
          }
          shippingCost{
            quantity
            shippingCost
            shippingRepeatCost
          }
        }
      }
    }
`

export const GET_QUOTATION_SUBMITTED = gql`
  query quotationSubmitted {
    quotationSubmitted {
      confirmText
      confirmFileUri
    }
  }
`

export const CHECK_PRINTER_SIZE = gql`
  query printerSize (
    $input: PrinterSizeInput
  ) {
    printerSize (
      input: $input
    ) {
      success
      message
    }
  }
`

export const SHOW_HISTORY_QUOTATION = gql`
  query showHistoryQuotation (
    $id: Int
  ) {
    showHistoryQuotation (
      id: $id
    ) {
      quotationPDF
      dateTime
    }
  }
`

export const GET_PRICE_NOTE = gql`
  query priceNote {
    priceNote {
      description
    }
  }
`
