import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import {
  message,
  Switch,
} from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import { theme } from '../../../styles/_variables'
import { ADD_PROMOTION } from '../../../constants/graphql/mutation'
import PATH from '../../../constants/path'
import 'react-quill/dist/quill.snow.css'
import 'katex/dist/katex.min.css'
import PromotionForm from '../components/promotion-form'


const { color } = theme
const SwitchWithLabel = ({ label, onChange }) => (
  <Div display={'flex'} flexDirection='row' alignItems='center'>
    {label && <H4 display={'block'} margin='0 8px' color={theme.color.primaryColor}>{label}</H4>}
    <Switch onChange={onChange} />
  </Div>
)

const PromotionCreate = () => {
  const [isValidate, setIsValidate] = useState(true)
  const [formValues, setFormValues] = useState({
    coverPhoto: [],
    name: '',
    description: '',
    status: false,
  })
  const [callMutation, setCallMutation] = useState(false)

  return (
      <Div display='flex' flexDirection='column'>
        <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
            <H4 bold color={color.primaryColor} margin='32px 0'>โปรโมชั่นใหม่ (New Promotion)</H4>
            <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
              <SwitchWithLabel label={'สถานะ'} onChange={checked => setFormValues({ ...formValues, status: checked })} />
              <br/>
              <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
              <SaveButton
                formValues={formValues}
                callMutation={callMutation}
                setCallMutation={setCallMutation}
                setIsValidate={setIsValidate}
              />
              </Div>
            </Div>
        </Div>
        <PromotionForm
          formValues={formValues}
          setFormValues={setFormValues}
          isValidate={isValidate}
          setIsValidate={setIsValidate}
        />
      </Div>
  )
}

const SaveButton = ({
  formValues,
  callMutation,
  setCallMutation,
  setIsValidate,
}) => (
  <Mutation
    mutation={ADD_PROMOTION}
  >
    {
      doCreatePromotion => (
        <Button
          text='บันทึก'
          icon='fa fa-floppy-o'
          inverse
          onClick={async () => {
            setCallMutation(true)
            if (formValues.name === '') {
              message.warning('กรุณากรอกข้อมูลชื่อโปรโมชั่น')
              setCallMutation(false)
              return
            }
            if (formValues.name.match(new RegExp(/^\s/)) !== null) {
              message.warning('กรุณากรอกข้อมูลชื่อโปรโมชั่นให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
              setCallMutation(false)
              setIsValidate(false)
              return
            }

            const input = {
              name: formValues.name,
              description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
              status: formValues.status ? 'active' : 'inactive',
              coverPhoto: formValues.coverPhoto,
              isFree: formValues.isFree,
            }
            doCreatePromotion({
              variables: {
                input,
              },
            }).then(({ data }) => {
              if (data.addPromotion) {
                if (data.addPromotion.success) {
                  setCallMutation(false)
                  message.success(data.addPromotion.message)
                  setTimeout(() => {
                    window.location = PATH.promotionList
                  }, 100)
                } else {
                  setCallMutation(false)
                  if (data.addPromotion.message === 'ชื่อ Promotion นี้มีอยู่ในระบบแล้ว') {
                    setIsValidate(false)
                    setCallMutation(false)
                  }
                  message.error(data.addPromotion.message)
                }
              }
            })
          }}
          disabled={callMutation}
        />
      )
    }
  </Mutation>
)

export default PromotionCreate
