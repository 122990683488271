import React, { useState, useEffect } from 'react'
import parseHtml from 'html-react-parser'
import {
  Div, H4, P,
} from 'konsys-ui'
import { Row, Col } from 'antd'
import {
  FullWidthContainer,
  TagDiv,
  Content,
  ItemContent,
  Description,
  LabelTitle,
  LabelText,
  LabelSpan,
} from './styled'
import Gallery from '../gallery'
import { theme } from '../../../../styles/_variables'


const { color } = theme
const Detail = ({
  data,
  isOrder,
}) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 375)
  const [isMobileXL, setIsMobileXL] = useState(window.innerWidth <= 570)

  const screenSizeHandler = () => setIsMobileView(window.innerWidth <= 375)
  const screenSizeHandler2 = () => setIsMobileXL(window.innerWidth <= 570)

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    window.addEventListener('resize', screenSizeHandler2)
    return () => {
      window.removeEventListener('resize', screenSizeHandler)
      window.removeEventListener('resize', screenSizeHandler2)
    }
  }, [])
  return (
    <FullWidthContainer isOrder={isOrder}>
      <Content>
        <Row gutter={0}>
          <Col xs={24} sm={24} md={12} span={12} style={{ paddingRight: isMobileXL ? '0px' : '8px', width: 'fit-content' }}>
            <ItemContent>
              <Gallery image={data.packageFiles}/>
            </ItemContent>
          </Col>
          <Col xs={24} sm={24} md={12} span={12} style={{ paddingLeft: isMobileXL ? '0px' : '8px' }}>
            <ItemContent>
              <LabelTitle color={color.greenPrimary}>{data.name}</LabelTitle>
              <Description>{parseHtml(data.description)}</Description>
              <LabelText bold>จำนวนชิ้นส่วนประกอบ : <LabelSpan>{data.amountPart} ชิ้นส่วน</LabelSpan></LabelText>
              <LabelText bold>จำนวนขั้นต่ำในการผลิต : <LabelSpan>{data.minimumManufacture} กล่อง</LabelSpan></LabelText>
              <Div style={{ marginTop: '12px' }}>
                {
                  data.tag && data.tag.map(item => (
                    <TagDiv
                      key={item.tagId}
                      htmlType="submit"
                      text={item.name}
                      icon='fa fa-tag fa-rotate-90'
                      inverse
                    />
                  ))
                }
              </Div>
            </ItemContent>
          </Col>
        </Row>
      </Content>
    </FullWidthContainer>
  )
}

export default Detail
