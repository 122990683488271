import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { message } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'

import { LIST_RESERVE_PACKAGING } from '../../../constants/graphql/query'
import { UPDATE_RESERVE_PACKAGING } from '../../../constants/graphql/mutation'
import TableFixedColumns from '../../../components/common/table-fixed-column'
import { theme } from '../../../styles/_variables'
import { generateRowsIncrementStepTable as generateRows } from '../../../utils/util-services'
import PATH from '../../../constants/path'
import BreadCrumb from '../../../components/common/breadcrumb'

const { color } = theme

const ReservationPackaging = () => {
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    rowData: generateRows(),
  })
  return (
  <Div>
    <Div display='flex' flexDirection='row' justifyContent='space-between' margin='32px 0px 0px 0px' alignItems='center'>
      <Div display='flex' flexDirection='column'>
        <H4 bold color={color.primaryColor}>
          <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
            <H4 bold color={color.primaryColor}>Catalog Settings</H4>
          </Link> {' > '} จำนวนสำรอง
        </H4>
        </Div>
        <SaveButton formValues={formValues} callMutation={callMutation} setCallMutation={setCallMutation} />
    </Div>
    <TableReservationPackaging formValues={formValues} setFormValues={setFormValues} />
  </Div>
  )
}

const TableReservationPackaging = ({
  formValues,
  setFormValues,
}) => {
  const columns = [{
    title: 'จำนวนผลิต (ชิ้น)',
    dataIndex: 'quantity',
    align: 'center',
    alignCell: 'center',
    width: '45%',
  }, {
    title: 'จำนวนสำรอง (ชิ้น)',
    dataIndex: 'reserveQuantity',
    editable: true,
    align: 'center',
    alignCell: 'center',
    width: '45%',
  }]
  return (
  <Div>
    <Query
      query={LIST_RESERVE_PACKAGING}
      variables={{
        limit: 20,
        offset: 0,
      }}
      onCompleted={(data) => {
        const reservePackagingList = data.listReservePackaging.reservePackaging.map(item => ({
          key: item.reservePackagingId,
          quantity: item.produceQuantity,
          reserveQuantity: item.reserveQuantity,
        }))
        setFormValues({ ...formValues, rowData: reservePackagingList })
      }}
    >
      {
        ({ loading }) => (
          <Div style={{ width: '80%' }}>
            <TableFixedColumns
              loading={loading}
              columns={columns}
              formValues={formValues}
              setFormValues={setFormValues}
              dataIndexFix={'reserveQuantity'}
            />
          </Div>
        )
      }
    </Query>
  </Div>
  )
}

const SaveButton = ({
  formValues,
  callMutation,
  setCallMutation,
}) => (
  <Mutation mutation={UPDATE_RESERVE_PACKAGING}>
    {
      doUpdateReservePackaging => (
        <Button
          text='บันทึก'
          icon='fa fa-save'
          inverse
          margin='0 16px 0 0'
          onClick={() => {
            setCallMutation(true)
            const input = formValues.rowData.map(row => ({
              reservePackagingId: row.key,
              produceQuantity: row.quantity,
              reserveQuantity: parseInt(row.reserveQuantity, 10),
            }))
            doUpdateReservePackaging({
              variables: {
                input,
              },
            }).then(({ data }) => {
              setCallMutation(false)
              if (data.updateReservePackaging) {
                setCallMutation(false)
                message.success(data.updateReservePackaging.message)
              } else {
                message.error(data.updateReservePackaging.message)
              }
            })
          }}
          disabled={callMutation}
        />
      )
    }
  </Mutation>
)

export default ReservationPackaging
