import React from 'react'
import {
  Div, Icon, LoadingIcon,
} from 'konsys-ui'
import {
  Row, Col,
} from 'antd'
import parseHtml from 'html-react-parser'
import { Query } from 'react-apollo'
import { LIST_PROMOTION } from '../../../../constants/graphql/query'
import { theme } from '../../../../styles/_variables'
import FreePriceTagsIcon from '../../../../images/icon/Free-tagIcon.png'
import {
  ImageWrapper, ImageLabel, DivPromotionList, DivAction,
  ImageDescription, StyledItem, StyledContainer, Container, StyledHandle,
  DivPromotion, LabelPromotion, Column,
} from './styled'


const Promotion = ({
  formValues,
  setFormValues,
}) => {
  const handlePromotion = () => {
    const tmpFormValues = formValues
    setFormValues({ ...tmpFormValues, showPromotion: !tmpFormValues.showPromotion })
  }
  return (
    <Div moreStyle={{ width: '100%', marginBottom: '24px' }}>
     <Div moreStyle={{ marginBottom: '12px' }}>
        <LabelPromotion>Promotion</LabelPromotion> <Icon onClick={handlePromotion} style={{ cursor: 'pointer' }} color='rgba(0, 0, 0, 0.25)' icon={formValues.showPromotion ? 'far fa-eye' : 'far fa-eye-slash'} />
      </Div>
      {
        formValues.showPromotion && <Query
          query={LIST_PROMOTION}
          fetchPolicy='network-only'
          notifyOnNetworkStatusChange
          variables={{
            limit: 10000,
            offset: 0,
            order: 'ordering',
            sort: 'ASC',
            status: 'active',
          }}
        >
          {
            ({ loading, data }) => {
              if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
              if (!data.listPromotion.success) return <p>error something</p>
              return (
                <Container>
                  <SortableList
                    items={data.listPromotion.promotion && data.listPromotion.promotion}
                  />
                </Container>
              )
            }
          }
        </Query>
      }
    </Div>
  )
}

const SortableList = (props) => {
  const { items, ...restProps } = props
  return (
    <StyledContainer>
      <Row type="flex" gutter={16}>
        {items.map((item, index) => (
          <Column
            key={`item-${item.promotionId}`}
          >
            <SortableItem
              index={index}
              value={item}
              {...restProps}
            />
          </Column>
        ))}
      </Row>
    </StyledContainer>
  )
}

const SortableItem = (props) => {
  const { value: item } = props
  return (
    <StyledItem>
      <ImageWrapper img={item.cover_uri || ''}/>
      <DivPromotion>
        <ImageLabel isFree={item.isFree}>
          {
            item.isFree && <Div margin='0 5px'>
              <img src={FreePriceTagsIcon} height='30px'/>
            </Div>
          }
          {item.name || '-'}
        </ImageLabel>
        <ImageDescription>{item.description ? parseHtml(item.description) : '-'}</ImageDescription>
      </DivPromotion>
    </StyledItem>
  )
}

export default Promotion
