import React, { useState, useEffect } from 'react'
import { Query } from 'react-apollo'
import { Row, Col } from 'antd'
import { Div, LoadingIcon } from 'konsys-ui'
import { Checkbox, DetailWrapper } from './styled'
import { GET_PRINTING_FORM } from '../../../../constants/graphql/query'
import { theme } from '../../../../styles/_variables'
import NotchedContainer from '../../../common/notched-containner'
import OptionsList from '../common/options-list'
import OptionDescription from '../common/option-description'
import SelectBox from '../common/select-box'
import MultiSelectBox from '../common/multi-select-box'
import cmykIconImage from '../../../../images/icon/CMYK Icon.png'
import forbiddenRedIcon from '../../../../images/icon/Forbidden-red Icon.png'
import offsetPrintingIcon from '../../../../images/icon/Offset-printing Icon.png'


const printings = [{
  label: 'ไม่พิมพ์ลวดลาย',
  printingId: 0,
  value: 0,
  name: 'ไม่พิมพ์ลวดลาย',
  icon: <img width={'100%'} src={forbiddenRedIcon} />,
  checked: true,
}]

const PrintingSelector = ({
  formValues,
  setFormValues,
  activeTab,
  disabled,
}) => {
  const [printingFormValue, setPrintingFormValue] = useState({
    side: undefined,
    printingBasicColorId: undefined,
    printingExtraColorId: null,
  })
  const [printingOptions, setPrintingOptions] = useState(printings)
  const [selectedPrinting, setSelectedPrinting] = useState(null)
  const [extraColorChecked, setExtraColorChecked] = useState(false)

  const handleOnSelect = (value) => {
    if (value.value === 0) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].printing.selectedPrinting = 0
      tmpFormValues[activeTab].printing.printingId = null
      tmpFormValues[activeTab].printing.printingName = ''
      tmpFormValues[activeTab].printing.printingType = 1
      tmpFormValues[activeTab].printing.printingTypeName = 'พิมพ์หน้าเดียว'
      tmpFormValues[activeTab].printing.printingBasicColorId = null
      tmpFormValues[activeTab].printing.printingBasicColorName = null
      tmpFormValues[activeTab].printing.printingExtraColorId = []
      tmpFormValues[activeTab].printing.checkExtraColor = false
      tmpFormValues[activeTab].printing.side = null
      setFormValues({ ...formValues, partData: tmpFormValues })
    } else if (formValues.partData[activeTab].printing.side
      || formValues.partData[activeTab].printing.printingBasicColorId
      || formValues.partData[activeTab].printing.checkExtraColor) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].printing.selectedPrinting = value.printingId
      tmpFormValues[activeTab].printing.printingId = value.printingId
      tmpFormValues[activeTab].printing.printingName = value.name
      tmpFormValues[activeTab].printing.printingType = formValues.partData[activeTab].printing.side
      tmpFormValues[activeTab].printing.printingTypeName = formValues.partData[activeTab].printing.side === 1 ? 'พิมพ์หน้าเดียว' : 'พิมพ์สองหน้า'
      tmpFormValues[activeTab].printing.side = formValues.partData[activeTab].printing.side
      tmpFormValues[activeTab].printing.printingBasicColorId = formValues.partData[activeTab].printing.printingBasicColorId
      tmpFormValues[activeTab].printing.checkExtraColor = formValues.partData[activeTab].printing.checkExtraColor
      setFormValues({ ...formValues, partData: tmpFormValues })
    } else {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].printing.selectedPrinting = value.printingId
      tmpFormValues[activeTab].printing.printingId = value.printingId
      tmpFormValues[activeTab].printing.printingName = value.name
      tmpFormValues[activeTab].printing.printingType = 1
      tmpFormValues[activeTab].printing.printingTypeName = 'พิมพ์หน้าเดียว'
      tmpFormValues[activeTab].printing.printingBasicColorId = value.printingBasicColor[0] && value.printingBasicColor[0].value
      tmpFormValues[activeTab].printing.printingBasicColorName = value.printingBasicColor[0] && value.printingBasicColor[0].title
      tmpFormValues[activeTab].printing.side = 1
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
    setSelectedPrinting(value)
    setPrintingFormValue({})
    setExtraColorChecked(false)
  }

  const handleSelectSideOption = (value) => {
    const selected = selectedPrinting.sideOptions.find(item => item.value === value)
    const tmpFormValues = formValues.partData
    tmpFormValues[activeTab].printing.printingType = selected.value
    tmpFormValues[activeTab].printing.printingTypeName = selected.title
    tmpFormValues[activeTab].printing.side = selected.value
    setFormValues({ ...formValues, partData: tmpFormValues })
    setPrintingFormValue({ ...printingFormValue, side: value })
  }

  const handleSelectBasicColor = (value) => {
    const selected = selectedPrinting.printingBasicColor.find(item => item.value === value)
    const tmpFormValues = formValues.partData
    tmpFormValues[activeTab].printing.printingBasicColorId = value
    tmpFormValues[activeTab].printing.printingBasicColorName = selected.title
    setFormValues({ ...formValues, partData: tmpFormValues })
    setPrintingFormValue({ ...printingFormValue, printingBasicColorId: value })
  }

  const handleCheckExtraColor = (value) => {
    const tmpFormValues = formValues.partData
    tmpFormValues[activeTab].printing.checkExtraColor = value
    setFormValues({ ...formValues, partData: tmpFormValues })
    setExtraColorChecked(value)
    if (!value) {
      setPrintingFormValue({ ...printingFormValue, printingExtraColor: null })
      const tmpFormValues2 = formValues.partData
      tmpFormValues2[activeTab].printing.printingExtraColorId = []
      setFormValues({ ...formValues, partData: tmpFormValues2 })
    }
  }

  const handleSelectExtraColor = (value) => {
    if (value) {
      if (value.length === 0) {
        handleCheckExtraColor(false)
      }
      const extraColorIds = value.map(i => i.value)
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].printing.printingExtraColorId = value.map(i => ({ printingExtraColorId: i.value, printingExtraColorName: selectedPrinting.printingExtraColor.find(item => item.value === i.value) ? selectedPrinting.printingExtraColor.find(item => item.value === i.value).title : null }))
      setFormValues({ ...formValues, partData: tmpFormValues })
      setPrintingFormValue({ ...printingFormValue, printingExtraColor: extraColorIds })
    }
  }

  useEffect(() => {
    const tmpPrintingOptions = [...printingOptions]
    if (tmpPrintingOptions.find(x => x.printingId === formValues.partData[activeTab].printing.selectedPrinting)) {
      handleOnSelect(tmpPrintingOptions.find(x => x.printingId === formValues.partData[activeTab].printing.selectedPrinting))
      const tmpPrinting = tmpPrintingOptions.map(item => ({
        ...item,
        checked: formValues.partData[activeTab].printing.selectedPrinting === item.printingId,
      }))
      setPrintingOptions(tmpPrinting)
    }
  }, [activeTab, formValues.partData[activeTab].printing.printingId])

  useEffect(() => {
    const tmpPrintingOptions = [...printingOptions]
    if (tmpPrintingOptions.find(x => x.printingId === formValues.partData[activeTab].printing.selectedPrinting)) {
      const tmpPrintingSelected = tmpPrintingOptions.find(x => x.printingId === formValues.partData[activeTab].printing.selectedPrinting)
      if (tmpPrintingSelected.printingExtraColor) {
        tmpPrintingSelected.printingExtraColor = tmpPrintingSelected.printingExtraColor.map(item => ({ value: item.value, title: item.title }))
        setSelectedPrinting(tmpPrintingSelected)
      }
    }
  }, [activeTab, formValues.partData[activeTab].printing.checkExtraColor])

  useEffect(() => {
    const tmpPrintingOptions = [...printingOptions]
    if (tmpPrintingOptions.find(x => x.printingId === formValues.partData[activeTab].printing.selectedPrinting)) {
      const tmpPrintingSelected = tmpPrintingOptions.find(x => x.printingId === formValues.partData[activeTab].printing.selectedPrinting)
      if (tmpPrintingSelected.printingExtraColor) {
        tmpPrintingSelected.printingExtraColor = tmpPrintingSelected.printingExtraColor.map(item => ({ value: item.value, title: item.title }))
        setSelectedPrinting(tmpPrintingSelected)
      }
    }
  }, [activeTab, formValues.partData[activeTab].printing.printingExtraColorId])

  return <Div display='flex' flexDirection='column' moreStyle={{ rowGap: '20px' }}>
  <NotchedContainer
    title='การพิมพ์ลวดลาย'
    titleIcon={cmykIconImage}
  >
    <Row type='flex' gutter={16}>
      <Col xs={24} sm={11} md={11} span={16}>
        <Query
          query={GET_PRINTING_FORM}
          notifyOnNetworkStatusChange
          fetchPolicy='network-only'
          onCompleted={(data) => {
            const printingData = Object.assign({}, data.getPrintingDetail.printing)
            if (printingData) {
              const tmpPrintingOptions = [...printingOptions]
              const tmpPrint = Object.assign({}, printingData)
              tmpPrint.printingId = printingData.printingId
              tmpPrint.description = printingData.description || '-'
              tmpPrint.name = printingData.name || '-'
              tmpPrint.value = printingData.printingId
              tmpPrint.printingBasicColor = printingData.printingBasicColor.map(item => ({
                value: item.printingBasicColorId,
                title: item.name,
              }))
              tmpPrint.printingExtraColor = printingData.printingExtraColor.map(item => ({
                value: item.printingExtraColorId,
                title: item.name,
              }))
              tmpPrint.label = 'Offset Printing'
              tmpPrint.icon = <img width={'100%'} src={offsetPrintingIcon} />
              tmpPrint.checked = false
              tmpPrint.sideOptions = [{
                value: 1,
                title: 'พิมพ์หน้าเดียว',
              }, {
                value: 2,
                title: 'พิมพ์สองหน้า',
              }]
              tmpPrintingOptions[0].checked = formValues.partData[activeTab].printing.selectedPrinting === 0
              tmpPrint.checked = formValues.partData[activeTab].printing.selectedPrinting !== 0
              setPrintingOptions([
                ...tmpPrintingOptions,
                tmpPrint,
              ])
              handleOnSelect(formValues.partData[activeTab].printing.selectedPrinting === 0 ? tmpPrintingOptions[0] : tmpPrint)
            }
          }}
        >
          {
            ({ loading }) => {
              if (loading) {
                return <Div moreStyle={{ width: '100%' }}>
                  <LoadingIcon color={theme.color.grey} />
                </Div>
              }
              return (
                selectedPrinting && <OptionsList
                  title='เลือกตัวเลือกที่ต้องการ'
                  options={printingOptions}
                  onSelect={handleOnSelect}
                  value={formValues.partData[activeTab].printing.selectedPrinting}
                  activeTab={activeTab}
                  disabled={disabled}
                />
              )
            }
          }
      </Query>
    </Col>
    <Col xs={24} sm={13} md={13} span={16}>
      {
        selectedPrinting && <DetailWrapper>
          <OptionDescription
            title={selectedPrinting.name}
            description={selectedPrinting.description}
          />
          {
            selectedPrinting.value !== 0 && <React.Fragment>
              <SelectBox
                title='ลักษณะการพิมพ์'
                placeholder='เลือกลักษณะการพิมพ์'
                value={formValues.partData[activeTab].printing.side}
                options={selectedPrinting.sideOptions || []}
                onChange={handleSelectSideOption}
                disabled={selectedPrinting.value === 0 || disabled}
              />
              <SelectBox
                title='รูปแบบสีการพิมพ์'
                placeholder='เลือกรูปแบบสีการพิมพ์'
                value={formValues.partData[activeTab].printing.printingBasicColorId}
                options={selectedPrinting.printingBasicColor || []}
                onChange={handleSelectBasicColor}
                disabled={selectedPrinting.value === 0 || disabled}
              />
              <Checkbox
                onChange={e => handleCheckExtraColor(e.target.checked)}
                checked={formValues.partData[activeTab].printing.checkExtraColor}
                disabled={selectedPrinting.value === 0 || disabled}
              >
                ต้องการพิมพ์สีพิเศษ อาทิ สีผสม สีขาว หรือสีทอง
              </Checkbox>
              { formValues.partData[activeTab].printing.checkExtraColor && <MultiSelectBox
                  title={'สีพิเศษ'}
                  placeholder={'เลือกสีพิเศษ'}
                  optionsData={selectedPrinting.printingExtraColor || []}
                  onChange={handleSelectExtraColor}
                  defaultValues={formValues.partData[activeTab].printing.printingExtraColorId.map(value => ({ value: value.printingExtraColorId }))}
                  disabled={disabled}
                />
              }
            </React.Fragment>
          }
        </DetailWrapper>
      }
    </Col>
  </Row>
  </NotchedContainer>
</Div>
}

export default PrintingSelector
