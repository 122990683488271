/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react'
import { Popconfirm, message } from 'antd'
import {
  Div, Input, H4,
} from 'konsys-ui'
import {
  DivToolbar, ButtonAction, LabelCell, DivCell,
  DivActionColumn, ButtonActionNew,
  Table, DivRowSize,
  DivItemCell,
} from './styled'
import PATH from '../../../../../constants/path'
import { theme } from '../../../../../styles/_variables'
import { generateRowsIncrementStepTable as generateRows } from '../../../../../utils/util-services'

const { color } = theme


const EditableCell = ({
  editing,
  children,
  inputType,
  record,
  dataIndex,
  title,
  onChangeCell,
  onAddCellPrice,
  onRemoveCellPrice,
  alignCell = 'left',
  style,
  ...restProps
}) => {
  const [value, setValue] = useState(record ? record[dataIndex] : '')
  useEffect(() => {
    if (record && record[dataIndex]) {
      setValue(record[dataIndex])
    }
  }, [record])

  let inputNode = <DivItemCell>
    <Input
      type="number"
      value={value}
      width="100px"
      onChange={(e) => {
        setValue(e.target.value)
        onChangeCell(e.target.value, dataIndex)
      }}
      onKeyPress={(e) => {
        if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
          e.preventDefault()
        }
      }}
    />
  </DivItemCell>
  let cellNode = <LabelCell>{children}</LabelCell>
  if (dataIndex === 'thickness') {
    inputNode = (<Div>
      {value.map((item, index) => (
        <DivRowSize key={index}>
          <DivItemCell>
            <Input
              width="200px"
              value={item}
              onChange={(e) => {
                const tmpValue = [...value]
                tmpValue[index] = e.target.value
                setValue(tmpValue)
                onChangeCell(tmpValue, dataIndex)
              }}
            />
          </DivItemCell>
        </DivRowSize>
      ))}
    </Div>)
    cellNode = (<Div>
      {value.map(item => (<LabelCell key={item.key}>{item}</LabelCell>))}
    </Div>)
  }

  return (
    <td {...restProps}>
      <DivCell alignCell={alignCell}>
        {editing ? inputNode : cellNode}
      </DivCell>
    </td>
  )
}

export const ThicknessTable = ({
  pagination = false,
  data = [],
  onSubmitEditRow,
  onSubmitDeleteRow,
  onAddRow,
  formValues,
  action,
  history,
}) => {
  const [columns] = useState([{
    title: 'ความหนาแผ่นใส',
    dataIndex: 'thickness',
    key: 'thickness',
    align: 'center',
    alignCell: 'center',
    editable: true,
  }])
  const [rows, setRows] = useState(data)
  const [editingKey, setEditingKey] = useState('')
  const [dataRowEditing, setDataRowEditing] = useState({})

  useEffect(() => {
    setRows(data)
  }, [data])

  const isEditingRow = record => record.key === editingKey
  const renderColumn = () => {
    let tmp = [...columns]
    tmp = columns.map((column) => {
      let onCell = {
        onCell: record => ({
          record,
          dataIndex: column.dataIndex,
          alignCell: column.alignCell,
          title: column.title,
        }),
      }
      if (column.editable) {
        onCell = {
          onCell: record => ({
            record,
            dataIndex: column.dataIndex,
            alignCell: column.alignCell,
            title: column.title,
            editing: isEditingRow(record),
            onChangeCell: (value, dataIndex) => {
              setDataRowEditing({
                ...dataRowEditing,
                [dataIndex]: value,
              })
            },
            onRemoveCellPrice: (indexRemove) => {
              const tmpRow = { ...dataRowEditing }
              tmpRow.size.splice(indexRemove, 1).map((item, indexItem) => ({ ...item, key: indexItem }))
              tmpRow.price.splice(indexRemove, 1)
              setDataRowEditing({
                ...tmpRow,
              })
            },
            onAddCellPrice: () => {
              const tmpRow = { ...dataRowEditing }
              tmpRow.size.push({ thickness: 0, key: tmpRow.thickness.length })
              tmpRow.price.push(0)
              setDataRowEditing({
                ...tmpRow,
              })
            },
          }),
        }
      }
      return {
        title: column.title,
        dataIndex: column.dataIndex,
        key: column.key,
        align: 'center',
        // width: '10%',
        ...onCell,
      }
    })
    tmp.push({
      title: 'คำสั่ง',
      dataIndex: 'action',
      key: 'action',
      width: '120px',
      align: 'center',
      onCell: () => ({
        alignCell: 'center',
      }),
      render: (text, record) => {
        const editable = isEditingRow(record)
        return editable ? (
          <Div>
            <Div>
              <ButtonAction
                text='ยืนยัน'
                inverse
                icon='fa fa-floppy-o'
                onClick={() => {
                  const result = [...rows]
                  const duplicateThickness = result.find(row => row.thickness[0] !== 0 && row.thickness[0] === dataRowEditing.thickness[0])
                  if (duplicateThickness) {
                    message.warning('ความหนาแผ่นใสนี้มีอยู่ในระบบแล้ว')
                    return
                  }
                  result[record.key] = { ...dataRowEditing }
                  if (result[record.key].thickness[0] === '') {
                    message.warning('กรุณากรอกข้อมูลความหนา')
                    return
                  }
                  setRows(result)
                  onSubmitEditRow(record.key, dataRowEditing)
                  setDataRowEditing({})
                  setEditingKey('')
                  const tmpPlasticObject = {
                    plasticWindowId: formValues.plasticWindowId,
                    plasticWindowThicknessId: null,
                    minimumCost: 0,
                    minimumOperationCost: 0,
                    plasticWindowThicknessTypeCost: {
                      plasticWindowThicknessCost: generateRows(),
                      plasticWindowThicknessCostColumnName: [],
                    },
                    plasticWindowThicknessTypeOperationCost: {
                      plasticWindowThicknessCost: generateRows(),
                      plasticWindowThicknessCostColumnName: [],
                    },
                    thickness: dataRowEditing.thickness[0],
                  }
                  localStorage.setItem('plasticWindowThicknessSelected', JSON.stringify(tmpPlasticObject))
                  setTimeout(() => {
                    history.push(`${PATH.plasticCreate}`)
                  }, 100)
                }}
              />
            </Div>
            <Div>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={async () => {
                  let result = JSON.parse(JSON.stringify([...rows]))
                  await result.splice(record.key, 1)
                  result = await result.map((item, index) => ({ ...item, key: index }))
                  await setRows(result)
                  await onSubmitDeleteRow(result)
                  await setDataRowEditing({})
                  await setEditingKey('')
                }}
              >
                <ButtonAction
                  text='ลบ'
                  icon='fa fa-trash-o'
                  color={color.error}
                />
              </Popconfirm>
            </Div>
          </Div>
        ) : (
          <ButtonAction
            disabled={editingKey !== ''}
            text='แก้ไข'
            inverse
            icon='fa fa-pencil-square-o'
            onClick={() => {
              const tmpPlasticObject = {
                plasticWindowId: formValues.plasticWindowId,
                plasticWindowThicknessId: null,
                minimumCost: 0,
                minimumOperationCost: 0,
                plasticWindowThicknessTypeCost: {
                  plasticWindowThicknessCost: generateRows(),
                  plasticWindowThicknessCostColumnName: [],
                },
                plasticWindowThicknessTypeOperationCost: {
                  plasticWindowThicknessCost: generateRows(),
                  plasticWindowThicknessCostColumnName: [],
                },
                thickness: record.thickness[0],
              }
              localStorage.setItem('plasticWindowThicknessSelected', JSON.stringify(record.value || tmpPlasticObject))
              if (action === 'create') history.push(`${PATH.plasticCreate}`)
              if (action === 'detail') history.push(`${PATH.plasticDetail}/${record.value.plasticWindowThicknessId}`)
            }}
          />
        )
      },
    })
    return tmp
  }

  return (
    <Div>
      <DivToolbar>
        <DivActionColumn>
          <H4 bold color={color.primaryColor}>ตัวเลือกความหน้าของแผ่นใส</H4>
          <ButtonActionNew
            text='New'
            disabled={editingKey !== ''}
            icon='fal fa-plus'
            inverse
            onClick={() => {
              const tmpRows = [...rows]
              const newRow = {
                key: tmpRows.length,
                thickness: [0],
                value: null,
              }
              tmpRows.push(newRow)
              onAddRow(tmpRows)
              setRows(tmpRows)
              setDataRowEditing(newRow)
              setEditingKey(newRow.key)
            }}
          />
        </DivActionColumn>
      </DivToolbar>
      <Div>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          pagination={pagination}
          columns={renderColumn()}
          dataSource={rows}
        />
      </Div>
    </Div>
  )
}

export default ThicknessTable
