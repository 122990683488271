import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import { Link } from 'react-router-dom'
import { message } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import PATH from '../../../../constants/path'
import { ADD_SPOT_UV } from '../../../../constants/graphql/mutation'
import { theme } from '../../../../styles/_variables'
import SpotUVForm from '../components/spot-uv-form'
import { generateRowsIncrementStepTable as generateRows } from '../../../../utils/util-services'

const { color } = theme


const SpotUVCreate = () => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    coverPhoto: [],
    sampleVideo: [],
    imagePhoto: [],
    costMinimum: 0,
    headerSpotUV: [{
      title: 'จำนวนผลิต (ชิ้น)',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: 'number',
      editable: false,
      editColumn: false,
      alignCell: 'center',
    }, {
      title: 'ราคาเริ่มต้น (บาท/ชิ้น)',
      dataIndex: 'column1',
      key: 'column1',
      inputType: 'number',
      editable: true,
      editColumn: false,
      alignCell: 'center',
    }],
    dataSpotUV: generateRows(),
  })

  return (
        <Div display='flex' flexDirection='column'>
            <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
                <H4 bold color={color.primaryColor} margin='32px 0'>
                    <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                      <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                    </Link> {' > '}
                    <Link to='/package-setting/spot-uv' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                    <H4 bold color={color.primaryColor}>การเคลือบยูวีเฉพาะจุด</H4>
                    </Link> {' > '} New
                </H4>
                <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                    <Link to='/package-setting/spot-uv' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                      <Button text='ยกเลิก' icon='fa fa-ban' ghost margin='0 16px 0 0' color={color.grey}/>
                    </Link>
                    <Mutation
                      mutation={ADD_SPOT_UV}
                    >
                      {
                        doAddSpotUV => (
                          <Button
                            text='บันทึก'
                            icon='fa fa-save'
                            inverse
                            margin='0 16px 0 0'
                            onClick={() => {
                              setCallMutation(true)
                              if (formValues.name === '') {
                                message.warning('กรุณากรอกข้อมูลชื่อวิธีการเคลือบยูวีเฉพาะจุด')
                                setCallMutation(false)
                                setIsValidate(false)
                                return
                              }
                              if (formValues.name.match(new RegExp(/^\s/)) !== null) {
                                message.warning('กรุณากรอกข้อมูลชื่อวิธีการเคลือบยูวีเฉพาะจุดให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
                                setCallMutation(false)
                                setIsValidate(false)
                                return
                              }
                              const input = {
                                name: formValues.name,
                                description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
                                minimumCost: parseFloat(formValues.costMinimum),
                                coverPhoto: formValues.coverPhoto,
                                video: formValues.sampleVideo,
                                imagePhoto: formValues.imagePhoto,
                                spotUVCostColumnName: formValues.headerSpotUV.filter(column => /column*/.test(column.key)).map(column => ({
                                  name: column.title,
                                  type: column.key,
                                })),
                                spotUVCost: formValues.dataSpotUV.map(row => ({
                                  quantity: parseInt(row.quantity, 10),
                                  column1: parseFloat(row.column1),
                                  column2: parseFloat(row.column2),
                                  column3: parseFloat(row.column3),
                                  column4: parseFloat(row.column4),
                                  column5: parseFloat(row.column5),
                                })),
                              }
                              doAddSpotUV({
                                variables: {
                                  input,
                                },
                              }).then(({ data }) => {
                                if (data.addSpotUV) {
                                  setCallMutation(false)
                                  message.success(data.addSpotUV.message)
                                  setTimeout(() => {
                                    window.location = PATH.spotUVSetting
                                  }, 350)
                                } else {
                                  setCallMutation(false)
                                  if (data.addSpotUV.message === 'ชื่อ วิธีการเคลือบยูวีเฉพาะจุด นี้มีอยู่ในระบบแล้ว') {
                                    setIsValidate(false)
                                    setCallMutation(false)
                                  }
                                  message.error(data.addSpotUV.message)
                                }
                              })
                            }}
                            disabled={callMutation}
                          />
                        )
                      }
                    </Mutation>
                </Div>
                </Div>
            </Div>
            <SpotUVForm
              formValues={formValues}
              setFormValues={setFormValues}
              isValidate={isValidate}
              setIsValidate={setIsValidate}
              action={'create'}
            />
        </Div>
  )
}

export default SpotUVCreate
