import React, { useState, useRef, useEffect } from 'react'
import ImageGallery from 'react-image-gallery'
import Lightbox from 'react-images'


const Gallery = ({
  image = [],
}) => {
  const [openLightBox, setOpenLightBox] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const galleryElement = useRef()

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 375)
  const [isMobileXL, setIsMobileXL] = useState(window.innerWidth <= 768)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768)
  const [isDesktopM, setIsDesktopM] = useState(window.innerWidth >= 800)
  const [isDesktopL, setIsDesktopL] = useState(window.innerWidth >= 1024)
  const [isDesktopXL, setIsDesktopXL] = useState(window.innerWidth >= 1280)
  const [isDesktopXXL, setIsDesktopXXL] = useState(window.innerWidth >= 1600)

  const screenSizeHandler = () => setIsMobileView(window.innerWidth <= 375)
  const screenSizeHandler2 = () => setIsMobileXL(window.innerWidth <= 768)
  const screenSizeHandler3 = () => setIsDesktop(window.innerWidth >= 768)
  const screenSizeHandler4 = () => setIsDesktopM(window.innerWidth >= 800)
  const screenSizeHandler5 = () => setIsDesktopL(window.innerWidth >= 1024)
  const screenSizeHandler6 = () => setIsDesktopXL(window.innerWidth >= 1280)
  const screenSizeHandler7 = () => setIsDesktopXXL(window.innerWidth >= 1600)

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    window.addEventListener('resize', screenSizeHandler2)
    window.addEventListener('resize', screenSizeHandler3)
    window.addEventListener('resize', screenSizeHandler4)
    window.addEventListener('resize', screenSizeHandler5)
    window.addEventListener('resize', screenSizeHandler6)
    window.addEventListener('resize', screenSizeHandler7)
    return () => {
      window.removeEventListener('resize', screenSizeHandler)
      window.removeEventListener('resize', screenSizeHandler2)
      window.removeEventListener('resize', screenSizeHandler3)
      window.removeEventListener('resize', screenSizeHandler4)
      window.removeEventListener('resize', screenSizeHandler5)
      window.removeEventListener('resize', screenSizeHandler6)
      window.removeEventListener('resize', screenSizeHandler7)
    }
  }, [])
  return (
    <React.Fragment>
      <ImageGallery
        ref={galleryElement}
        items={image.sort((a, b) => a.ordering - b.ordering).map(item => ({
          original: item.packageFileUri,
          thumbnail: item.packageFileUri,
          thumbnailWidth: isMobileXL ? '75px' : '92px',
          thumbnailHeight: isMobileXL ? '75px' : '92px',
          originalClass: 'img-gallery',
          originalHeight: isDesktopXXL ? '360px' : isDesktopXL ? '300px' : isDesktopL ? '220px' : isDesktopM ? '210px' : isDesktop && '200px',
        }))}
        showNav={false}
        thumbnailPosition={'left'}
        showFullscreenButton={false}
        showPlayButton={false}
        infinite={true}
        disableThumbnailScroll={true}
        onClick={() => {
          setCurrentImage(galleryElement.current.state.currentIndex)
          setOpenLightBox(true)
        }}
      />
      <Lightbox
        images={image.map(v => (
          { src: v.packageFileUri }
        ))}
        currentImage={currentImage}
        isOpen={openLightBox}
        onClickNext={() => setCurrentImage(currentImage + 1)}
        onClickPrev={() => setCurrentImage(currentImage - 1)}
        onClose={() => setOpenLightBox(false)}
        backdropClosesModal={true}
      />
    </React.Fragment>
  )
}

export default Gallery
