import styled, { css } from 'styled-components'

export const ImageWithButtonWrapper = styled.div`
  width: calc((100% - 72px) / 3);
  margin: 12px;
  text-align: center;
`

export const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  cursor: pointer;
  position: relative;
  ${props => props.img && css`
    background-image: url(${props.img});
    background-size: cover;
    background-position: center;
  `}
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.5s ease;
  }
  &:after {
    content: '';
    position: absolute;
    top: 16px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    opacity: 0;
    transition: all 0.5s ease;
  }
  > i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    transition: all 0.5s ease;
  }
  &:hover {
    &:before {
      background-color: rgba(0, 0, 0, 0.7);
    }
    &:after {
      opacity: 1;
    }
    > i {
      opacity: 1;
    }
  }
`

export default ImageWrapper
