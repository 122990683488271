import styled from 'styled-components'
import { Button } from 'konsys-ui'
import { theme } from '../../styles/_variables'

export const Menu = styled.div`
  padding: 0px 24px;
  cursor: pointer;
  color: ${theme.color.offWhite};
  background-color: ${theme.color.primaryColor};
  font-size: ${theme.fonts.size.subTitle};
  text-align: center;
  border-radius: 4px;
  font-weight: ${theme.fonts.style.medium};
`

export const ButtonSearch = styled(Button)`
  border-radius: 20px;
  padding: 0px 24px;
  i {
    font-size: ${theme.fonts.size.normal} !important;
    font-weight: ${theme.fonts.style.medium};
  }
  span {
    font-size: ${theme.fonts.size.subTitle};
    font-weight: ${theme.fonts.style.medium};
  }
`

export default Menu
