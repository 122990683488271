import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Div, H4, Button, LoadingIcon,
} from 'konsys-ui'
import { message, Popconfirm } from 'antd'
import { Query, Mutation } from 'react-apollo'

import { theme } from '../../../../styles/_variables'
import PATH from '../../../../constants/path'
import { LIST_GRAPHIC } from '../../../../constants/graphql/query'
import { DELETE_GRAPHIC, EDIT_GRAPHIC } from '../../../../constants/graphql/mutation'
import GraphicForm from '../components/graphic-form'
import BreadCrumb from '../../../../components/common/breadcrumb'


const { color } = theme


const GraphicDetail = ({ match }) => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    coverPhoto: [],
  })
  const [isDefault, setIsDefault] = useState(false)
  const graphicId = parseInt(match.params.id, 10)
  return (
    <Div>
      <Query
        query={LIST_GRAPHIC}
        notifyOnNetworkStatusChange
        onCompleted={(data) => {
          const graphicData = data.listGraphic.graphic.find(item => item.graphicId === graphicId)
          if (!graphicData) {
            message.error('ไม่พบบริการด้านกราฟฟิก')
            setTimeout(() => {
              window.location = PATH.graphicSetting
            }, 1000)
          } else {
            setIsDefault(graphicData.isDefault)
            setFormValues({
              ...formValues,
              name: graphicData.name,
              description: graphicData.description,
              coverPhoto: graphicData.graphicFileUri ? [{ graphicFileUri: graphicData.graphicFileUri, type: 'cover', name: graphicData.graphicFileName }] : [],
            })
          }
        }}
      >
        {
          ({ loading, data }) => {
            if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
            const graphicData = data.listGraphic.graphic.find(item => item.graphicId === graphicId)
            return (
              <Div display='flex' flexDirection='column'>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
                  <H4 bold color={color.primaryColor} margin='32px 0'>
                    <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                      <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                    </Link> {' > '}
                    <Link to='/package-setting/graphic' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                      <H4 bold color={color.primaryColor}>บริการด้านกราฟฟิก</H4>
                    </Link> {' > '} แก้ไขข้อมูล
                  </H4>
                  <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                      { !isDefault && <DeleteButton graphicId={graphicId} isDefault={isDefault} />}
                      <SaveButton
                        graphicId={graphicId}
                        formValues={formValues}
                        callMutation={callMutation}
                        setCallMutation={setCallMutation}
                        setIsValidate={setIsValidate}
                      />
                    </Div>
                  </Div>
                </Div>
                {
                  data.listGraphic.graphic && <GraphicForm
                    formValues={formValues}
                    setFormValues={setFormValues}
                    isValidate={isValidate}
                    setIsValidate={setIsValidate}
                    action={'detail'}
                  />
                }
              </Div>
            )
          }
        }
      </Query>
    </Div>
  )
}

const DeleteButton = ({ graphicId }) => (
  <Mutation mutation={DELETE_GRAPHIC}>
    {
      doDeleteGraphic => (
        <Popconfirm
          title="ยืนยันการลบบริการด้านกราฟฟิกหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก"
          onConfirm={() => {
            doDeleteGraphic({
              variables: {
                id: parseInt(graphicId, 10),
              },
            }).then((res) => {
              if (res.data.deleteGraphic) {
                if (res.data.deleteGraphic.success) {
                  message.success(res.data.deleteGraphic.message)
                  setTimeout(() => {
                    window.location = PATH.graphicSetting
                  }, 350)
                } else {
                  message.error(res.data.deleteGraphic.message)
                }
              }
            })
          }}
          okText='ยืนยัน'
          cancelText='ยกเลิก'
        >
          <Button text='ลบ' icon='fa fa-trash' ghost margin='0 16px 0 0' color={color.error}/>
        </Popconfirm>
      )
    }
  </Mutation>
)

const SaveButton = ({
  graphicId,
  formValues,
  callMutation,
  setCallMutation,
  setIsValidate,
}) => (
  <Mutation mutation={EDIT_GRAPHIC}>
    {
      doEditGraphic => (
        <Button
          text='บันทึก'
          icon='fa fa-save'
          inverse
          margin='0 16px 0 0'
          onClick={() => {
            setCallMutation(true)
            if (formValues.name === '') {
              message.warning('กรุณากรอกข้อมูลชื่อบริการด้านกราฟฟิก')
              setCallMutation(false)
              setIsValidate(false)
              return
            }

            if (formValues.name.match(new RegExp(/^\s/)) !== null) {
              message.warning('กรุณากรอกข้อมูลชื่อบริการด้านกราฟฟิกให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
              setCallMutation(false)
              setIsValidate(false)
              return
            }
            const input = {
              graphicId,
              name: formValues.name,
              description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
              coverPhoto: formValues.coverPhoto,
            }
            doEditGraphic({
              variables: {
                input,
              },
            }).then(({ data }) => {
              if (data.editGraphic) {
                setCallMutation(false)
                message.success(data.editGraphic.message)
                setTimeout(() => {
                  window.location = PATH.graphicSetting
                }, 350)
              } else {
                setCallMutation(false)
                if (data.editGraphic.message === 'ชื่อ บริการด้านกราฟฟิก นี้มีอยู่ในระบบแล้ว') {
                  setIsValidate(false)
                  setCallMutation(false)
                }
                message.error(data.editGraphic.message)
              }
            })
          }}
          disabled={callMutation}
        />
      )
    }
  </Mutation>
)

export default GraphicDetail
