import styled from 'styled-components'
import OPT from 'options'
import { Menu as MenuAntd, Drawer as DrawerAnt } from 'antd'
import { theme } from '../../styles/_variables'

const { SubMenu, Item } = MenuAntd
const isLightTheme = OPT.theme === 'light'

export const Drawer = styled(DrawerAnt)`
  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-content {
    background-color: ${isLightTheme ? '#eee' : '#333'}
  }
`

export const Wrapper = styled.div`
  background-color: ${isLightTheme ? '#eee' : '#333'} !important;
  width: ${props => (props.ipad ? 'initial' : '250px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
`

export const Menu = styled(MenuAntd)`
  padding: 20px 0 0 0;
  border: none;
  width: ${props => (props.ipad ? 'initial' : '250px')};
  margin: ${props => (props.ipad && '85px 0 0 0')};
  background-color: ${isLightTheme ? '#eee' : '#333'};
  font-family: ${theme.fonts.family.normal};
  .ant-menu-submenu-selected {
    background-color: ${theme.color.primaryColor}11;
  }
  .ant-menu-submenu > .ant-menu-submenu-title {
    padding: ${props => (props.ipad && '0 8px')} !important;
    display: flex;
    align-items: center;
  }
  > .ant-menu-item {
    padding: ${props => (props.ipad && '0 8px')} !important;
    display: flex;
    align-items: center;
  }
  .ant-menu-inline.ant-menu-sub {
    font-family: ${theme.fonts.family.normal};
    .ant-menu-submenu-title:hover {
      color: ${theme.color.primaryColor} !important;
      .ant-menu-submenu-arrow {
        ::before, ::after {
          background: ${theme.color.primaryColor} !important;
        }
      }
    }
  }
  .ant-menu-item-selected {
    background-color: ${theme.color.primaryColor}33 !important;
    color: ${theme.color.primaryColor};
    ::after {
      border-right: 3px solid ${theme.color.secondaryColor} !important;
    }
  }
  .ant-menu-item:hover {
    color: ${theme.color.primaryColor};
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${theme.color.secondaryColor}33;
    ::after {
      border-right: 3px solid ${theme.color.secondaryColor};
    }
  }
`

export const SubMenuAntd = styled(SubMenu)`
  .ant-menu-sub {
    background-color: ${isLightTheme ? '#eee' : '#333'};
    box-shadow: none;
  }
  background-color: ${isLightTheme ? '#eee' : '#333'};
`

export const ItemAntd = styled(Item)`
  .ant-menu-sub {
    background-color: ${isLightTheme ? '#eee' : '#333'};
    box-shadow: none;
  }
  background-color: ${isLightTheme ? '#eee' : '#333'};
`

export const ItemWrapper = styled.div`
  margin-left: 65px;
  border-bottom: 1px solid ${theme.color.charcoalGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px !important;
`

export const MainContainer = styled.div`
  display: flex;
  z-index: 1;
`
