import styled from 'styled-components'
import { Button } from 'konsys-ui'
import { theme, minMedia } from '../../../../styles/_variables'

export const ButtonClear = styled(Button)`
  border: unset !important;
  padding: 2px;
  background-color: ${theme.color.offWhite} !important;
  color: ${theme.color.grey} !important;
  i {
    color: ${theme.color.grey} !important;
  }
  &:hover {
    border: unset !important;
  }
  &:focus {
    box-shadow: unset !important;
  }
`

export const TagDivActive = styled(Button)`
  border-radius: 1.4rem;
  padding: 0px 12px;
  width: fit-content !important;
  max-width: 300px;
  text-align: left;
  margin: 4px;
  i {
    font-size: ${theme.fonts.size.small} !important;
    font-weight: ${theme.fonts.style.medium};
  }
  span {
    word-break: break-all;
    font-size: ${theme.fonts.size.normal};
    font-weight: ${theme.fonts.style.bold};
  }
  
  ${minMedia.tablet`
    &:hover {
      background-color: ${theme.color.paleBlack} !important;
      border-color: ${theme.color.paleBlack} !important;
    }
  `}
`

export const TagDivInActive = styled(Button)`
  border-radius: 1.4rem;
  padding: 0px 12px;
  width: fit-content !important;
  max-width: 300px;
  text-align: left;
  margin: 4px;
  background-color: ${theme.color.paleGray} !important;
  border-color: ${theme.color.paleGray} !important;
  i {
    font-size: ${theme.fonts.size.small} !important;
    font-weight: ${theme.fonts.style.medium};
    color: ${theme.color.lightGray} !important;
  }
  span {
    word-break: break-all;
    color: ${theme.color.lightGray} !important;
    font-size: ${theme.fonts.size.normal};
    font-weight: ${theme.fonts.style.bold};
  }
  
  ${minMedia.tablet`
    &:hover {
      background-color: ${theme.color.paleGray} !important;
      border-color: ${theme.color.paleGray} !important;
      i {
          color: ${theme.color.offWhite} !important;
      }
      span {
          color: ${theme.color.offWhite} !important;
      }
    }
  `}
`

export default TagDivActive
