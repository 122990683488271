import React from 'react'
import _ from 'lodash'
import { Mutation } from 'react-apollo'
import {
  Popconfirm,
  message,
  Row,
  Col,
} from 'antd'
import {
  Div, Button,
} from 'konsys-ui'
import UploadFile from '../../../../components/common/upload'
import {
  UPLOAD_QUOTATION_SUBMITTED_CONFIRM_FILE,
  DELETE_QUOTATION_SUBMITTED_CONFIRM_FILE,
} from '../../../../constants/graphql/mutation'
import { theme } from '../../../../styles/_variables'
import {
  PreviewCoverImage, DivImage,
  PreviewImageBox, PreviewImage, DeleteImageButton,
  DivImageAction,
} from './styled'


const CoverUpload = ({
  newFileList,
  setNewFileList,
  data,
  refetch,
}) => (
  <Div display='flex' flexDirection='column'>
      <PreviewCoverImage src={newFileList.length === 1 ? newFileList[0].quotationSubmittedFileUri ? null : URL.createObjectURL(newFileList[0]) : null } />
      <UploadFile
          list={newFileList.length === 1 ? newFileList[0].type === 'cover' ? [] : newFileList : []}
          id='uploadCoverFileId'
          multiple={false}
          onChange={(fileList) => {
            let upload = false
            const allowFileType = ['jpg', 'jpeg', 'png']
            for (let i = 0; i < fileList.length; i += 1) {
              const splitName = fileList[i].name.split('.')
              if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
            || (newFileList.length > 0 && !_.isUndefined(_.find(newFileList, d => d.name === fileList[i].name)))) {
                upload = false
                break
              } else {
                upload = true
              }
            }
            if (upload) {
            // Set only one image to state
              setNewFileList({ ...data, confirmFile: [...fileList] })
            } else {
              message.error('สามารถอัพโหลดได้เฉพาะไฟล์ jpg, jpeg, png และไม่สามารถอัพรูปซ้ำได้')
            }
          }}
          onRemoveFile={(fileName) => {
            const newUploadFileList = [...newFileList]
            _.remove(newUploadFileList, v => v.name === fileName)
            setNewFileList({ ...data, confirmFile: newUploadFileList })
          }}
          fileType="image/*"
          confirm={true}
          confirmText={'ยืนยันการลบรูปภาพหน้าปกหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'}
      />
      <Mutation mutation={UPLOAD_QUOTATION_SUBMITTED_CONFIRM_FILE}>
        {
          doUploadQuotationSubmittedConfirmFile => (
            <Button
              inverse
              text='อัพโหลดรูปภาพใหม่'
              disabled={newFileList.length === 0 ? true : newFileList.length === 1 ? newFileList[0].type === 'cover' ? true : newFileList.length === 0 : true}
              onClick={() => {
                doUploadQuotationSubmittedConfirmFile({
                  variables: {
                    input: {
                      confirmFile: newFileList,
                    },
                  },
                }).then((res) => {
                  if (res.data.uploadQuotationSubmittedConfirmFile) {
                    if (res.data.uploadQuotationSubmittedConfirmFile.success) {
                      message.success(res.data.uploadQuotationSubmittedConfirmFile.message)
                    } else {
                      message.error(res.data.uploadQuotationSubmittedConfirmFile.message)
                    }
                    refetch()
                  }
                })
              }}
            />
          )
        }
      </Mutation>
      <Row
        align='middle'
        type='flex'
        gutter={[1, 1]}
        style={{ marginTop: '20px' }}
      >
        {
          newFileList.length === 1 && newFileList[0].type === 'cover' && newFileList.map((v, i) => (
            <Col key={i} span={4}>
              <DivImage>
                <PreviewImageBox>
                  <PreviewImage src={v.type === 'cover' && v.quotationSubmittedFileUri} />
                </PreviewImageBox>
                <DivImageAction>
                  <Mutation mutation={DELETE_QUOTATION_SUBMITTED_CONFIRM_FILE}>
                    {
                      doDeleteQuotationSubmittedConfirmFile => (
                        <Popconfirm
                          title="ยืนยันการลบรูปภาพหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก"
                          onConfirm={() => {
                            doDeleteQuotationSubmittedConfirmFile().then((res) => {
                              if (res.data.deleteQuotationSubmittedConfirmFile) {
                                if (res.data.deleteQuotationSubmittedConfirmFile.success) {
                                  message.success(res.data.deleteQuotationSubmittedConfirmFile.message)
                                } else {
                                  message.error(res.data.deleteQuotationSubmittedConfirmFile.message)
                                }
                                refetch()
                              }
                            })
                          }}
                          okText='ยืนยัน'
                          cancelText='ยกเลิก'
                        >
                          <DeleteImageButton
                            color={theme.color.paleBlack}
                            buttonStyle='rectangle'
                            text='ลบรูปนี้'
                            />
                        </Popconfirm>
                      )
                    }
                  </Mutation>
                </DivImageAction>
              </DivImage>
            </Col>
          ))
        }

      </Row>
  </Div>
)

export default CoverUpload
