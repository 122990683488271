import styled from 'styled-components'
import { InputNumber as AntInputNumber } from 'antd'
import { Button } from 'konsys-ui'
import { theme, media, minMedia } from '../../../../styles/_variables'

export const Input = styled(AntInputNumber)`
width: 100%;
border-radius: 8px;
font-size: ${theme.fonts.size.normal};
font-weight: ${theme.fonts.style.medium};
background-color: rgba(${theme.color.primaryColorRGB}, 0.1)
`

export const ButtonConfirm = styled(Button)`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 6px 16px 4px 10px;
background-color: ${theme.color.greenPrimary};
box-shadow: 2px 2px 0px 1px rgb(110, 201, 190, 0.4) !important;
& > p {
    font-size: ${theme.fonts.size.extraNormal};
    line-height: 24px;
    margin: 0px !important;
  }
${media.mobileL`
    margin-bottom: 12px;
`}
`

export const InputWrapper = styled.div`
> p{
    width: 40%;
    ${minMedia.laptop`
        width: 100%;
    `}
    ${minMedia.laptopL`
        width: 60%;
    `}
    text-align: center;
    margin-right: 12px;
    margin-bottom: 0px;
    font-size: ${theme.fonts.size.normal};
    color: ${theme.color.greyNormal};
}
> span{
    width: 20%;
    text-align: center;
    margin-left: 12px;
    margin-right: 12px;
    font-size: ${theme.fonts.size.normal};
    color: ${theme.color.greyNormal};
}
width: 100%;
font-size: ${theme.fonts.size.normal}
font-weight: ${theme.fonts.style.medium};
display: flex;
height: fit-content;
margin-bottom: 6px;
`

export const FormWrapper = styled.div`
width: 100%;
display: flex;
height: fit-content;
justify-content: center;
`

export const CenterWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
`

export const DetailWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: fit-content;
row-gap: 10px;
justify-content:center;
align-items: center;
`

export const Container = styled.div`
display: flex;
flex-direction: column;
width: 100%;
min-height: 300px;
background: white;
`

export const TextRecommend = styled.div`
padding: 12px;
font-size: ${theme.fonts.size.small};
border-radius: 4px;
background-color: #838782;
color: ${theme.color.offWhite}
width: 100%;
line-height: ${theme.fonts.size.small};
`

export const IconConfirm = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-top: -4px;
`

export default Input
