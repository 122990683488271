import React from 'react'
import { TableIncrementalStep } from '../../../../../components/common/table'

const CostTable = ({
  data = {},
  onChange,
}) => {
  const handleOnSubmitEditRow = (key, value) => {
    const tmp = [...data.plasticWindowThicknessTypeCost.plasticWindowThicknessCost]
    tmp[key] = value
    onChange({
      ...data,
      plasticWindowThicknessTypeCost: {
        plasticWindowThicknessCost: tmp,
        plasticWindowThicknessCostColumnName: [...data.plasticWindowThicknessTypeCost.plasticWindowThicknessCostColumnName],
      },
    })
  }

  const handleOnSubmitEditColumn = (value, rows) => {
    const tmpHeaders = value.map((column, i) => {
      let colDataIndex
      if (/column*/.test(column.dataIndex)) {
        colDataIndex = column.dataIndex
      } else if (column.dataIndex !== 'quantity' && column.dataIndex !== 'price') {
        colDataIndex = `column${i}`
      } else {
        colDataIndex = column.dataIndex
      }
      return {
        ...column,
        dataIndex: colDataIndex,
        key: colDataIndex,
      }
    })
    onChange({
      ...data,
      plasticWindowThicknessTypeCost: {
        plasticWindowThicknessCost: rows,
        plasticWindowThicknessCostColumnName: tmpHeaders,
      },
    })
  }

  const handleOnSubmitNewColumn = (value, index) => {
    const tmpHeaders = [...data.plasticWindowThicknessTypeCost.plasticWindowThicknessCostColumnName].map((column, i) => {
      let colDataIndex
      if (/column*/.test(column.dataIndex)) {
        colDataIndex = `column${i}`
      } else {
        colDataIndex = column.dataIndex
      }
      return {
        ...column,
        dataIndex: colDataIndex,
        key: colDataIndex,
      }
    })
    const tmpDatas = [...data.plasticWindowThicknessTypeCost.plasticWindowThicknessCost].map((row, i) => ({
      ...row,
      [index]: 0,
    }))
    tmpHeaders.push({
      title: value,
      dataIndex: index,
      key: index,
      inputType: 'number',
      editable: true,
      editColumn: true,
      alignCell: 'center',
    })
    onChange({
      ...data,
      plasticWindowThicknessTypeCost: {
        plasticWindowThicknessCost: tmpDatas,
        plasticWindowThicknessCostColumnName: tmpHeaders,
      },
    })
  }

  return (
    <TableIncrementalStep
      title="ต้นทุนแผ่นใส"
      onSubmitEditRow={handleOnSubmitEditRow}
      onSubmitEditColumn={handleOnSubmitEditColumn}
      onSubmitNewColumn={handleOnSubmitNewColumn}
      headers={data.plasticWindowThicknessTypeCost.plasticWindowThicknessCostColumnName}
      data={data.plasticWindowThicknessTypeCost.plasticWindowThicknessCost}
    />
  )
}

export default CostTable
