import React from 'react'
import { Link } from 'react-router-dom'
import {
  Div, H4,
} from 'konsys-ui'
import { theme } from '../../styles/_variables'
import {
  Menu, ButtonSearch,
} from './styled'

const { color } = theme

const SETTING_MENU = [{
  label: 'กระดาษ',
  link: '/package-setting/paper',
}, {
  label: 'การพิมพ์',
  link: '/package-setting/printing',
}, {
  label: 'การเคลือบผิว',
  link: '/package-setting/coating',
}, {
  label: 'การเคลือบยูวีเฉพาะจุด',
  link: '/package-setting/spot-uv',
}, {
  label: 'การปั๊มนูน',
  link: '/package-setting/embossing',
}, {
  label: 'การปั๊มฟอยล์-ทองเค',
  link: '/package-setting/foiling',
}, {
  label: 'การเจาะหน้าต่างและติดแผ่นใส',
  link: '/package-setting/plastic-window',
}, {
  label: 'การปั๊มตัด',
  link: '/package-setting/die-cutting',
}, {
  label: 'การติดกาว',
  link: '/package-setting/gluing',
}, {
  label: 'บริการเสริม',
  link: '/package-setting/extra-service',
}, {
  label: 'บริการจัดส่ง',
  link: '/package-setting/shipping',
}, {
  label: 'จำนวนสำรอง',
  link: '/package-setting/reservation-packaging',
}, {
  label: 'บริการด้านกราฟฟิก',
  link: '/package-setting/graphic',
}, {
  label: 'ข้อความใต้ตารางราคา',
  link: '/package-setting/price-note',
}]
const PackageSetting = () => (
    <Div display='flex' flexDirection='column'>
      <H4 bold color={color.primaryColor} margin='32px 0'>
        <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
          <H4 bold color={color.primaryColor}>Catalog Settings</H4>
        </Link>
      </H4>
      <Div display='flex' flexDirection='row' moreStyle={{ columnGap: '12px', flexWrap: 'wrap', rowGap: '12px' }}>
        {
          SETTING_MENU.map((item, index) => (
            <Link to={item.link} style={{ alignSelf: 'flex-end', cursor: 'pointer' }} key={index} >
              <Menu>{item.label}</Menu>
            </Link>
          ))
        }
      </Div>
      <Div display='flex' flexDirection='row' moreStyle={{
        columnGap: '12px',
        flexWrap: 'wrap',
        rowGap: '12px',
        marginTop: '96px',
      }}>
        <Link to='/package-setting/margin-rate' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
          <ButtonSearch
            htmlType="submit"
            text='Margin Rate'
            icon='fa fa-percent'
            inverse
          />
        </Link>
        <Link to='/package-setting/tag' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
          <ButtonSearch
            htmlType="submit"
            text='Tag Management'
            icon='fa fa-tag fa-rotate-90'
            inverse
          />
        </Link>
      </Div>
    </Div>
)

export default PackageSetting
