import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { message, Popconfirm } from 'antd'
import {
  Div, H4, Button, LoadingIcon,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import PATH from '../../../../constants/path'
import { theme } from '../../../../styles/_variables'
import { GET_SPOT_UV_DETAIL } from '../../../../constants/graphql/query'
import { DELETE_SPOT_UV, EDIT_SPOT_UV } from '../../../../constants/graphql/mutation'
import SpotUVForm from '../components/spot-uv-form'
import BreadCrumb from '../../../../components/common/breadcrumb'


const { color } = theme


const SpotUVDetail = ({ match }) => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    coverPhoto: [],
    sampleVideo: [],
    imagePhoto: [],
    costMinimum: 0,
    headerSpotUV: [{
      title: 'จำนวนผลิต (ชิ้น)',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: 'number',
      editable: false,
      editColumn: false,
      alignCell: 'center',
    }],
    dataSpotUV: [],
  })
  return (
    <Query
      query={GET_SPOT_UV_DETAIL}
      variables={{
        id: parseInt(match.params.id, 10),
      }}
      notifyOnNetworkStatusChange
      onCompleted={(data) => {
        const spotUVData = data.getSpotUVDetail.spotUV
        if (data.getSpotUVDetail.success) {
          const spotUVDataColumn = spotUVData.spotUVCostColumnName.map(item => ({
            title: item.name,
            dataIndex: item.type,
            key: item.type,
            inputType: 'number',
            editable: true,
            editColumn: item.type !== 'column1',
            alignCell: 'center',
          }))
          setFormValues({
            ...formValues,
            name: spotUVData.name,
            description: spotUVData.description,
            coverPhoto: spotUVData.spotUVFiles.filter(file => file.type === 'cover'),
            sampleVideo: spotUVData.spotUVFiles.filter(file => file.type === 'video'),
            imagePhoto: spotUVData.spotUVFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample'),
            headerSpotUV: [...formValues.headerSpotUV, ...spotUVDataColumn],
            dataSpotUV: spotUVData.spotUVCost.map((item, index) => ({
              key: index,
              quantity: item.quantity,
              column1: item.column1,
              column2: item.column2,
              column3: item.column3,
              column4: item.column4,
              column5: item.column5,
            })),
            costMinimum: spotUVData.minimumCost,
          })
        } else {
          message.error(data.getSpotUVDetail.message)
          setTimeout(() => {
            window.location = PATH.spotUVSetting
          }, 1000)
        }
      }}
    >
      {
        ({ loading, data }) => {
          if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
          if (!data.getSpotUVDetail.spotUV) return <p>error something</p>
          const { spotUVId, name } = data.getSpotUVDetail.spotUV
          return (
            <>
              <Div display='flex' flexDirection='column'>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
                    <H4 bold color={color.primaryColor} margin='32px 0'>
                        <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                          <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                        </Link> {' > '}
                        <Link to='/package-setting/spot-uv' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                        <H4 bold color={color.primaryColor}>การเคลือบยูวีเฉพาะจุด</H4>
                        </Link> {' > '} แก้ไขข้อมูล
                    </H4>
                    <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                        <Mutation mutation={DELETE_SPOT_UV}>
                        {
                          doDeleteSpotUV => (
                            <Popconfirm
                              title='ยืนยันการลบการเคลือบยูวีเฉพาะจุดหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'
                              onConfirm={() => {
                                doDeleteSpotUV({
                                  variables: {
                                    id: parseInt(spotUVId, 10),
                                  },
                                }).then((res) => {
                                  if (res.data.deleteSpotUV) {
                                    if (res.data.deleteSpotUV.success) {
                                      message.success(res.data.deleteSpotUV.message)
                                      setTimeout(() => {
                                        window.location = PATH.spotUVSetting
                                      }, 350)
                                    } else {
                                      message.error(res.data.deleteSpotUV.message)
                                    }
                                  }
                                })
                              }
                            }
                            okText='ยืนยัน'
                            cancelText='ยกเลิก'
                          >
                            <Button text='ลบ' icon='fa fa-trash-o fa-lg' ghost margin='0 16px 0 0' color={color.error}/>
                          </Popconfirm>
                          )
                        }
                        </Mutation>
                      <Mutation
                        mutation={EDIT_SPOT_UV}
                      >
                        {doEditSpotUV => (
                          <Button
                            text='บันทึก'
                            icon='fa fa-save'
                            inverse
                            margin='0 16px 0 0'
                            loading={callMutation}
                            disabled={callMutation}
                            onClick={() => {
                              setCallMutation(true)
                              if (formValues.name === '') {
                                message.warning('กรุณากรอกข้อมูลชื่อวิธีการเคลือบยูวีเฉพาะจุด')
                                setCallMutation(false)
                                setIsValidate(false)
                                return
                              }
                              if (formValues.name.match(new RegExp(/^\s/)) !== null) {
                                message.warning('กรุณากรอกข้อมูลชื่อวิธีการเคลือบยูวีเฉพาะจุดให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
                                setCallMutation(false)
                                setIsValidate(false)
                                return
                              }
                              const input = {
                                spotUVId,
                                name: formValues.name,
                                description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
                                minimumCost: parseFloat(formValues.costMinimum),
                                coverPhoto: formValues.coverPhoto,
                                video: formValues.sampleVideo,
                                imagePhoto: formValues.imagePhoto,
                                spotUVCostColumnName: formValues.headerSpotUV.filter(column => /column*/.test(column.key)).map(column => ({
                                  name: column.title,
                                  type: column.key,
                                  spotUVId,
                                })),
                                spotUVCost: formValues.dataSpotUV.map(row => ({
                                  spotUVId,
                                  quantity: row.quantity,
                                  column1: parseFloat(row.column1),
                                  column2: parseFloat(row.column2),
                                  column3: parseFloat(row.column3),
                                  column4: parseFloat(row.column4),
                                  column5: parseFloat(row.column5),
                                })),
                              }
                              doEditSpotUV({
                                variables: {
                                  input,
                                },
                              }).then(({ data: dataResponse }) => {
                                if (dataResponse.editSpotUV && dataResponse.editSpotUV.success) {
                                  message.success(dataResponse.editSpotUV.message)
                                  setTimeout(() => {
                                    setCallMutation(false)
                                    window.location = PATH.spotUVSetting
                                  }, 350)
                                } else {
                                  setCallMutation(false)
                                  if (dataResponse.editSpotUV.message === 'ชื่อ วิธีการเคลือบยูวีเฉพาะจุด นี้มีอยู่ในระบบแล้ว') {
                                    setIsValidate(false)
                                  }
                                  setCallMutation(false)
                                  message.error(dataResponse.editSpotUV.message)
                                }
                              })
                            }}
                          />
                        )}
                      </Mutation>
                    </Div>
                  </Div>
                </Div>
                {
                  data.getSpotUVDetail.spotUV && <SpotUVForm
                    formValues={formValues}
                    setFormValues={setFormValues}
                    isValidate={isValidate}
                    setIsValidate={setIsValidate}
                    action={'detail'}
                  />
                }
            </Div>
          </>
          )
        }
      }
    </Query>
  )
}

export default SpotUVDetail
