import { Table as TableAnt, InputNumber as InputNumberAnt } from 'antd'
import { Button } from 'konsys-ui'
import styled from 'styled-components'
import { theme } from '../../../styles/_variables'


export const SimpleTable = styled(TableAnt)`
margin-top: 30px;
width: 100%;
.ant-table-column-title {
  color: ${theme.color.primaryColor};
  font-size: ${theme.fonts.size.subTitle};
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 20px 40px 20px 40px;
  border-bottom: 0px;
}
.ant-table-row {
  font-size: ${theme.fonts.size.extraNormal};
  color: ${theme.color.textColor};
  vertical-align: middle;
}
border: ${theme.color.primaryColor} solid 1px;
border-radius: 5px;
tr:nth-child(even) {
  background-color: ${theme.color.offWhite};
}
`

export const ButtonAction = styled(Button)`
  padding: 2px 6px !important;
  border-radius: 20px;
`

export const DivCell = styled.div`
  display: flex;
  justify-content: left;
  ${props => props.alignCell && `
    justify-content: ${props.alignCell};
  `}
`

export const LabelCell = styled.p`
  font-weight: 600;
  font-size: ${theme.fonts.size.extraNormal};
  margin-bottom: 0px;
`

export const InputNumber = styled(InputNumberAnt)`
  .ant-input-number-input {
    text-align: center;
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
  }
`

export const LabelColumn = styled.p`
  color: ${theme.color.primaryColor};
  font-size: 24px;
  margin-bottom: 0px;
`

export default SimpleTable
