/* eslint-disable no-restricted-globals */
import React from 'react'
import { TableIncrementalStep } from '../../../../../components/common/table'

const CoatingCostTable = ({
  data = {},
  onChange,
}) => {
  const handleOnSubmitEditRow = (key, value) => {
    const tmp = [...data.dataCoating]
    const tmpValue = value
    if (tmpValue.column1 === null || isNaN(tmpValue.column1)) tmpValue.column1 = 0
    if (tmpValue.column2 === null || isNaN(tmpValue.column2)) tmpValue.column2 = 0
    if (tmpValue.column3 === null || isNaN(tmpValue.column3)) tmpValue.column3 = 0
    if (tmpValue.column4 === null || isNaN(tmpValue.column4)) tmpValue.column4 = 0
    if (tmpValue.column5 === null || isNaN(tmpValue.column5)) tmpValue.column5 = 0
    tmp[key] = tmpValue
    onChange({ ...data, dataCoating: tmp })
  }

  const handleOnSubmitEditColumn = (value, rows) => {
    onChange({ ...data, headerCoating: value, dataCoating: rows })
  }

  const handleOnSubmitNewColumn = (value, index) => {
    const tmpHeaders = [...data.headerCoating].map((column, i) => {
      let colDataIndex
      if (/column*/.test(column.dataIndex)) {
        colDataIndex = `column${i}`
      } else {
        colDataIndex = column.dataIndex
      }
      return {
        ...column,
        dataIndex: colDataIndex,
        key: colDataIndex,
      }
    })
    const tmpDatas = [...data.dataCoating].map((row, i) => ({
      ...row,
      [index]: 0,
    }))
    tmpHeaders.push({
      title: value,
      dataIndex: index,
      key: index,
      inputType: 'number',
      editable: true,
      editColumn: true,
      alignCell: 'center',
    })
    onChange({ ...data, headerCoating: tmpHeaders, dataCoating: tmpDatas })
  }

  return (
    <TableIncrementalStep
      title="ต้นทุนการเคลือบ (บาท/ชิ้น)"
      onSubmitEditRow={handleOnSubmitEditRow}
      onSubmitEditColumn={handleOnSubmitEditColumn}
      onSubmitNewColumn={handleOnSubmitNewColumn}
      headers={data.headerCoating}
      data={data.dataCoating}
      maxColumns={6}
    />
  )
}

export default CoatingCostTable
