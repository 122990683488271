import styled from 'styled-components'
import { Button } from 'konsys-ui'
import { theme } from '../../../styles/_variables'


export const NewButton = styled(Button)`
  border-radius: 5px;
  padding: 4px 16px;
  i {
    font-size: ${theme.fonts.size.normal} !important;
    font-weight: ${theme.fonts.style.medium};
  }
  span {
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
  }
`

export default NewButton
