import styled from 'styled-components'
import { Button } from 'konsys-ui'
import { theme, media } from '../../../../../styles/_variables'


export const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

export const MultiSelectBoxContainer = styled.div`
  display: flex;
  align-items: center;
`

export const MultiSelectBoxWrapper = styled.div`
  width: 100%;
  > div{
    background: ${theme.color.offWhite};
    border: 1px solid rgba(${theme.color.primaryColorRGB}, 0.3);
    > p{
      color: ${theme.color.textColor};
    }
  }
  .ant-select-selection {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    color: ${theme.color.textColor};
    background-color: rgba(255, 255, 255, 0.9);
  }
  .ant-select-selection:hover {
    border-color: ${theme.color.primaryColor};
  }
  .ant-select-open .ant-select-selection {
    border-color: ${theme.color.primaryColor};
  }
  .ant-select-selection__rendered {
    text-align: center;
    margin-top: -2px;
  }
  .ant-select-selection__placeholder {
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
    text-align: center;
  }
  .ant-select-selection-selected-value {
    color: ${theme.color.textColor}
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
    text-align: center;
    width: 100%;
    margin-top: 0px;
  }
  .ant-select-arrow {
    color: ${theme.color.textColor};
  }
`

export const ButtonAction = styled(Button)`
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 25px;
  height: 25px;
  i {
    font-size: 14px !important;
    margin: 1px 0px 0px 0px !important;
  }
`


export default MultiSelectBoxContainer
