import React from 'react'
import {
  Router, Route, Switch, Redirect,
} from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import client from './apollo-client'
import history from './history'
import LandingPage from './pages/landing'
import Login from './pages/login'
import { ForgotPasswordSection, ResetSection, ResetPassword } from './components/login/reset-password'
import Users from './pages/users/list'
import QuotationCreate from './pages/quotation-page/create'
import QuotationPreDefinedCreate from './pages/quotation-page/pre-defined-create'
import QuotationPreDefinedCreateItem from './pages/quotation-page/pre-defined-create/detail'
import QuotationPreDefinedEditItem from './pages/quotation-page/pre-defined-edit/detail'
import QuotationPreDefinedPriceItem from './pages/quotation-page/pre-defined-price/detail'
import QuotationList from './pages/quotation-page/list'
import QuotationDetail from './pages/quotation-page/detail'
import NotFound from './pages/not-found'
import { ContentWrapper, MainContainer } from './components/container'
// import { CreateQuotation } from './components/quotation-components'
import SideBar from './components/sidebar'
import Gallery from './pages/gallery'
import Home from './pages/home-setting'
import Popup from './pages/popup-setting'
import PATH from './constants/path'
import PromotionList from './pages/promotion-page/list'
import PromotionCreate from './pages/promotion-page/create'
import PromotionDetail from './pages/promotion-page/detail'
import PackageList from './pages/package-page/list'
import PackageCreate from './pages/package-page/create'
import PackageDetail from './pages/package-page/detail'
import PackagePreview from './pages/package-page/preview'
import PriceCalculator from './pages/price-calculator'
import PackageSetting from './pages/package-setting'
import CoatingSetting from './pages/package-setting/coating'
import CoatingCreate from './pages/package-setting/coating/create'
import CoatingDetail from './pages/package-setting/coating/detail'
import SpotUVSetting from './pages/package-setting/spot-uv'
import SpotUVCreate from './pages/package-setting/spot-uv/create'
import SpotUVDetail from './pages/package-setting/spot-uv/detail'
import PaperSetting from './pages/package-setting/paper'
import PaperCreate from './pages/package-setting/paper/create'
import PaperDetail from './pages/package-setting/paper/detail'
import GluingSetting from './pages/package-setting/gluing'
import GluingCreate from './pages/package-setting/gluing/create'
import GluingDetail from './pages/package-setting/gluing/detail'
import ReservationPackaging from './pages/package-setting/reservation-packaging'
import ExtraServiceSetting from './pages/package-setting/extra-service'
import ExtraServiceCreate from './pages/package-setting/extra-service/create'
import ExtraServiceDetail from './pages/package-setting/extra-service/detail'
import PrintingSetting from './pages/package-setting/printing'
import MarginRateSetting from './pages/package-setting/margin-rate'
import TagSetting from './pages/package-setting/tag'
import DieCuttingSetting from './pages/package-setting/die-cutting'
import ShippingSetting from './pages/package-setting/shipping'
import EmbossingSetting from './pages/package-setting/embossing'
import PlasticSetting from './pages/package-setting/plastic'
import PlasticCreate from './pages/package-setting/plastic/create'
import PlasticDetail from './pages/package-setting/plastic/detail'
import FoilingSetting from './pages/package-setting/foiling'
import FoilingCreate from './pages/package-setting/foiling/create'
import FoilingDetail from './pages/package-setting/foiling/detail'
import GraphicSetting from './pages/package-setting/graphic'
import GraphicCreate from './pages/package-setting/graphic/create'
import GraphicDetail from './pages/package-setting/graphic/detail'
import PriceNoteSetting from './pages/package-setting/price-note'
import QuotationSubmitted from './pages/quotation-submitted'


history.listen(() => {
  window.scrollTo(0, 0)
})
const App = () => (
  <ApolloProvider client={client}>
    <Router history={history}>
      <Switch>
        <Route path={PATH.login} exact component={Login} />
        <Route path={PATH.forgetPassword} exact component={ForgotPasswordSection}/>
        <Route path={PATH.resetPassword} exact component={ResetPassword}/>
        <MainContainer>
          <SideBar />
          <ContentWrapper history={history}>
            <Switch>
              <Route path={PATH.home} exact component={LandingPage}/>
              <Route path={PATH.home} exact component={() => <div><p>Admin Home</p></div>} />
              <Route path={PATH.landing} exact component={LandingPage}/>
              <Route path={PATH.users} exact component={Users}/>
              <Route path={PATH.quotationList} exact component={QuotationList}/>
              <Route path={`${PATH.quotationDetail}/:id`} component={QuotationDetail}/>
              <Route path={PATH.quotationCreate} exact component={QuotationCreate}/>
              <Route path={PATH.quotationPreDefinedCreate} exact component={QuotationPreDefinedCreate}/>
              <Route path={`${PATH.quotationPreDefinedCreate}/:id`} component={QuotationPreDefinedCreateItem}/>
              <Route path={`${PATH.quotationPreDefinedEditItem}/:id`} component={QuotationPreDefinedEditItem}/>
              <Route path={`${PATH.quotationPreDefinedPriceItem}/:id`} component={QuotationPreDefinedPriceItem}/>
              <Route path={PATH.gallery} exact component={Gallery} />
              <Route path={PATH.homeSetting} exact component={Home} />
              <Route path={PATH.popupSetting} exact component={Popup} />
              <Route path={PATH.promotionList} exact component={PromotionList}/>
              <Route path={PATH.promotionCreate} exact component={PromotionCreate}/>
              <Route path={`${PATH.promotionDetail}/:id`} component={PromotionDetail}/>
              <Route path={PATH.packageList} exact component={PackageList}/>
              <Route path={PATH.packageCreate} exact component={PackageCreate}/>
              <Route path={`${PATH.packageDetail}/:id`} component={PackageDetail}/>
              <Route path={`${PATH.packagePreview}/:id`} component={PackagePreview}/>
              <Route path={PATH.priceCalculator} exact component={PriceCalculator}/>
              <Route path={PATH.packageSetting} exact component={PackageSetting}/>
              <Route path={PATH.coatingSetting} exact component={CoatingSetting}/>
              <Route path={PATH.coatingCreate} exact component={CoatingCreate}/>
              <Route path={`${PATH.coatingDetail}/:id`} component={CoatingDetail}/>
              <Route path={PATH.spotUVSetting} exact component={SpotUVSetting}/>
              <Route path={PATH.spotUVCreate} exact component={SpotUVCreate}/>
              <Route path={`${PATH.spotUVDetail}/:id`} component={SpotUVDetail}/>
              <Route path={PATH.paperSetting} exact component={PaperSetting}/>
              <Route path={PATH.paperCreate} exact component={PaperCreate}/>
              <Route path={`${PATH.paperDetail}/:id`} component={PaperDetail}/>
              <Route path={PATH.gluingSetting} exact component={GluingSetting}/>
              <Route path={PATH.gluingCreate} exact component={GluingCreate}/>
              <Route path={`${PATH.gluingDetail}/:id`} component={GluingDetail}/>
              <Route path={PATH.reservationPackaging} exact component={ReservationPackaging}/>
              <Route path={PATH.extraServiceSetting} exact component={ExtraServiceSetting}/>
              <Route path={PATH.extraServiceCreate} exact component={ExtraServiceCreate}/>
              <Route path={`${PATH.extraServiceDetail}/:id`} component={ExtraServiceDetail}/>
              <Route path={PATH.marginRateSetting} exact component={MarginRateSetting}/>
              <Route path={PATH.tagSetting} exact component={TagSetting}/>
              <Route path={`${PATH.printingSetting}`} component={PrintingSetting}/>
              <Route path={`${PATH.dieCuttingSetting}`} component={DieCuttingSetting}/>
              <Route path={`${PATH.shippingSetting}`} component={ShippingSetting}/>
              <Route path={PATH.embossingSetting} exact component={EmbossingSetting}/>
              <Route path={PATH.foilingSetting} exact component={FoilingSetting}/>
              <Route path={PATH.foilingCreate} exact component={FoilingCreate}/>
              <Route path={`${PATH.foilingDetail}/:id`} component={FoilingDetail}/>
              <Route path={PATH.graphicSetting} exact component={GraphicSetting}/>
              <Route path={PATH.graphicCreate} exact component={GraphicCreate}/>
              <Route path={`${PATH.graphicDetail}/:id`} component={GraphicDetail}/>
              <Route path={PATH.plasticSetting} exact component={PlasticSetting}/>
              <Route path={PATH.plasticCreate} exact component={PlasticCreate}/>
              <Route path={`${PATH.plasticDetail}/:id`} component={PlasticDetail}/>
              <Route path={PATH.priceNoteSetting} exact component={PriceNoteSetting}/>
              <Route path={PATH.quotationSubmited} exact component={QuotationSubmitted}/>
              <Redirect from='/' to={PATH.quotationList} />
              <Route component={NotFound}/>
            </Switch>
          </ContentWrapper>
        </MainContainer>
      </Switch>
    </Router>
  </ApolloProvider>
)

export default App
