import React, { useState, useEffect } from 'react'
import { Div, LoadingIcon } from 'konsys-ui'
import { Row, Col } from 'antd'
import { Query } from 'react-apollo'
import { LIST_GRAPHIC } from '../../../../constants/graphql/query'
import { theme } from '../../../../styles/_variables'
import NotchedContainer from '../../../common/notched-containner'
import OptionsList from '../common/options-list'
import OptionDescription from '../common/option-description'
import ForbiddenWhite from '../../../../images/icon/Forbidden-white Icon.png'
import GraphicIcon from '../../../../images/icon/Graphic-serviceIcon.png'
import RepeatOrder from '../../../../images/icon/Repeat Icon.png'


const GraphicSelector = ({
  formValues,
  setFormValues,
  value,
  onChange,
  custom = false,
  maxWidth = '990px',
  fullWidth = false,
}) => {
  const [optionItems, setOptionItems] = useState([])
  const [selectedItem, setSelectedItem] = useState({
    value: {},
  })

  const handleOnSelect = (e) => {
    const tmpFormValues = formValues
    setFormValues({ ...tmpFormValues, graphicId: e, graphicName: e.name })
    setSelectedItem(e)
  }

  const handleOnCustomSelect = (e) => {
    onChange(e)
    setSelectedItem(e)
  }

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 375)
  const [isMobileXL, setIsMobileXL] = useState(window.innerWidth <= 570)

  const screenSizeHandler = () => setIsMobileView(window.innerWidth <= 375)
  const screenSizeHandler2 = () => setIsMobileXL(window.innerWidth <= 570)

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    window.addEventListener('resize', screenSizeHandler2)
    return () => {
      window.removeEventListener('resize', screenSizeHandler)
      window.removeEventListener('resize', screenSizeHandler2)
    }
  }, [])

  return <Div display='flex' flexDirection='column' moreStyle={{ rowGap: '20px' }}>
  <NotchedContainer
    title='บริการด้านกราฟฟิก'
    titleIcon={GraphicIcon}
    maxWidth={maxWidth}
  >
    <Row type="flex" gutter={16}>
      <Col xs={24} sm={24} md={14} xl={fullWidth ? 8 : 12} span={16}>
        <Query
          query={LIST_GRAPHIC}
          notifyOnNetworkStatusChange
          fetchPolicy='network-only'
          onCompleted={(data) => {
            const graphicData = data.listGraphic.graphic
            if (graphicData) {
              let defaultOption = {
                id: 0,
                name: 'ไม่ใช้บริการ',
                label: 'ไม่ใช้บริการ',
                checked: (formValues && formValues.graphicName === 'ไม่ใช้บริการ') || ((!((formValues && formValues.graphicName)) && !((formValues && formValues.graphicId)) && !(value)) || value === 'ไม่ใช้บริการ'),
                value: 0,
                index: 0,
                sampleImages: [],
                icon: ForbiddenWhite,
                padding: true,
              }
              const resultGraphicData = data.listGraphic.graphic.map((item, index) => {
                if (item.isDefault) {
                  defaultOption = {
                    ...defaultOption,
                    name: item.name,
                    label: item.name,
                    icon: item.graphicFileUri ? item.graphicFileUri : RepeatOrder,
                    description: item.description,
                  }
                  return null
                }
                return {
                  ...item,
                  id: index + 1,
                  label: item.name,
                  value: index + 1,
                  checked: value ? value === item.name : (formValues && formValues.graphicId) ? formValues.graphicId.graphicId === item.graphicId : (formValues && formValues.graphicName) ? formValues.graphicName === item.name : false,
                  index: index + 1,
                  icon: item.graphicFileUri ? item.graphicFileUri : RepeatOrder,
                  padding: !item.graphicFileUri,
                  description: item.description,
                }
              }).filter(item => item)
              setOptionItems([defaultOption, ...resultGraphicData])
              if (value) {
                const selectItem = [defaultOption, ...resultGraphicData].find(x => x.name === value)
                setSelectedItem({
                  checked: true,
                  ...selectItem,
                })
              } else if (formValues && formValues.graphicId) {
                const selectItem = [defaultOption, ...resultGraphicData].find(x => x.graphicId === formValues.graphicId.graphicId)
                setSelectedItem({
                  checked: true,
                  ...selectItem,
                })
              } else if (formValues && formValues.graphicName) {
                const selectItem = [defaultOption, ...resultGraphicData].find(x => x.label === formValues.graphicName)
                setSelectedItem({
                  checked: true,
                  ...selectItem,
                })
              } else {
                setSelectedItem(defaultOption)
              }
            }
          }}
        >
          {
            ({ loading, data, refetch }) => {
              if (loading) {
                return <Div moreStyle={{ width: '100%' }}>
                  <LoadingIcon color={theme.color.grey} />
                </Div>
              }
              return (
                <OptionsList
                  title='เลือกตัวเลือกที่ต้องการ'
                  options={optionItems.length > 0 && optionItems}
                  onSelect={custom ? handleOnCustomSelect : handleOnSelect}
                  grahic={true}
                  gridColumn={isMobileXL ? (optionItems.length > 2 ? 3 : 2) : 3}
                  fullWidth={fullWidth}
                  disabledScrollX
                />
              )
            }
          }
        </Query>
      </Col>
      <Col xs={24} sm={24} md={10} xl={fullWidth ? 16 : 12} span={8}>
        {
          custom ? <Div moreStyle={{ width: '100%', height: 'calc(100% - 5px);' }}>
            <OptionDescription
              title={selectedItem ? selectedItem.label : value ? value.label : selectedItem ? selectedItem.label : '-'}
              description={selectedItem ? selectedItem.description : value ? value.description : selectedItem ? selectedItem.description : '-'}
              onClickVideo={() => {}}
            />
          </Div> : <Div moreStyle={{ width: '100%', height: 'calc(100% - 5px);' }}>
          <OptionDescription
            title={selectedItem && selectedItem.label}
            description={selectedItem && selectedItem.description}
            onClickVideo={() => {}}
          />
          </Div>
        }
      </Col>
    </Row>
  </NotchedContainer>
</Div>
}


export default GraphicSelector
