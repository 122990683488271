import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import { message, Popconfirm } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import PATH from '../../../../constants/path'
import { theme } from '../../../../styles/_variables'
import ThicknessForm from '../components/thickness-form'
import { ADD_PLASTIC_WINDOW_THICKNESS } from '../../../../constants/graphql/mutation'
import { generateRowsIncrementStepTable as generateRows } from '../../../../utils/util-services'


const { color } = theme

const PlasticWindownThicknessCreate = ({
  history,
}) => {
  const [callMutation, setCallMutation] = useState(false)
  const [isValidate, setIsValidate] = useState(true)
  const [formValues, setformValues] = useState({
    thickness: 0,
    minimumCost: 0,
    minimumOperationCost: 0,
    plasticWindowThicknessTypeCost: {
      plasticWindowThicknessCost: generateRows(),
      plasticWindowThicknessCostColumnName: [{
        title: 'จำนวนผลิต (ชิ้น)',
        dataIndex: 'quantity',
        key: 'quantity',
        inputType: 'number',
        editable: false,
        editColumn: false,
        alignCell: 'center',
      }, {
        title: 'ราคาเริ่มต้น (บาท/ตร.นิ้ว)',
        dataIndex: 'column1',
        key: 'column1',
        inputType: 'number',
        editable: true,
        editColumn: false,
        alignCell: 'center',
      }],
    },
    plasticWindowThicknessTypeOperationCost: {
      plasticWindowThicknessCost: generateRows(),
      plasticWindowThicknessCostColumnName: [{
        title: 'จำนวนผลิต (ชิ้น)',
        dataIndex: 'quantity',
        key: 'quantity',
        inputType: 'number',
        editable: false,
        editColumn: false,
        alignCell: 'center',
      }, {
        title: 'ราคาเริ่มต้น (บาท/ชิ้น)',
        dataIndex: 'column1',
        key: 'column1',
        inputType: 'number',
        editable: true,
        editColumn: false,
        alignCell: 'center',
      }],
    },
  })

  useEffect(() => {
    const tmpForm = JSON.parse(localStorage.getItem('plasticWindowThicknessSelected'))
    if (tmpForm) {
      const plasticWindowThicknessCostColumnName = tmpForm.plasticWindowThicknessTypeCost.plasticWindowThicknessCostColumnName.map(item => ({
        title: item.name,
        dataIndex: item.type,
        key: item.type,
        inputType: 'number',
        editable: true,
        editColumn: item.type !== 'column1',
        alignCell: 'center',
      }))
      const plasticWindowThicknessOperationCostColumnName = tmpForm.plasticWindowThicknessTypeOperationCost.plasticWindowThicknessCostColumnName.map(item => ({
        title: item.name,
        dataIndex: item.type,
        key: item.type,
        inputType: 'number',
        editable: true,
        editColumn: item.type !== 'column1',
        alignCell: 'center',
      }))
      setformValues({
        plasticWindowId: tmpForm.plasticWindowId,
        thickness: tmpForm.thickness,
        minimumCost: tmpForm.minimumCost,
        minimumOperationCost: tmpForm.minimumOperationCost,
        plasticWindowThicknessTypeCost: {
          plasticWindowThicknessCost: tmpForm.plasticWindowThicknessTypeCost.plasticWindowThicknessCost || [],
          plasticWindowThicknessCostColumnName: [...formValues.plasticWindowThicknessTypeCost.plasticWindowThicknessCostColumnName, ...plasticWindowThicknessCostColumnName],
        },
        plasticWindowThicknessTypeOperationCost: {
          plasticWindowThicknessCost: tmpForm.plasticWindowThicknessTypeOperationCost.plasticWindowThicknessCost || [],
          plasticWindowThicknessCostColumnName: [...formValues.plasticWindowThicknessTypeOperationCost.plasticWindowThicknessCostColumnName, ...plasticWindowThicknessOperationCostColumnName],
        },
      })
    }
  }, [])

  return (
    <Div>
      <Div display='flex' flexDirection='row' justifyContent='space-between' margin='32px 0px 0px 0px' alignItems='center'>
        <Div display='flex' flexDirection='column'>
          <H4 bold color={color.primaryColor}>
            <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
              <H4 bold color={color.primaryColor}>Catalog Settings</H4>
            </Link> {' > '}
            <Link to='/package-setting/plastic-window' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
              <H4 bold color={color.primaryColor}>การเจาะหน้าต่างและติดแผ่นใส</H4>
            </Link> {' > '} New
          </H4>
        </Div>
        <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
          <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
            <Popconfirm
              title='ยืนยันความหนาแผ่นใสหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'
              onConfirm={() => {
                const tmpFormValues = JSON.parse(localStorage.getItem('plasticWindowThicknessForm'))
                const tmpThickness = tmpFormValues.thickness
                const newTmpThickness = [...tmpThickness]
                _.remove(newTmpThickness, (v, i) => v.thickness[0] === formValues.thickness)
                const newTmpFormValues = {
                  ...tmpFormValues,
                  thickness: newTmpThickness,
                }
                localStorage.setItem('plasticWindowThicknessForm', JSON.stringify(newTmpFormValues))
                setTimeout(() => {
                  history.push({
                    pathname: `${PATH.plasticSetting}`,
                    state: { from: 'plasticThickness' },
                  })
                }, 50)
              }}
              okText='ยืนยัน'
              cancelText='ยกเลิก'
            >
              <Button text='ยกเลิก' icon='fa fa-ban' ghost margin='0 16px 0 0' color={color.grey}/>
            </Popconfirm>
            <Mutation
              mutation={ADD_PLASTIC_WINDOW_THICKNESS}
            >
              {
                doAddPlasticWindowThickness => (
                  <Button
                    text='บันทึก'
                    icon='fa fa-save'
                    inverse
                    margin='0 16px 0 0'
                    onClick={() => {
                      setCallMutation(true)
                      if (!formValues.thickness) {
                        message.warning('กรุณากรอกข้อมูลความหนา')
                        setCallMutation(false)
                        setIsValidate(false)
                        return
                      }
                      const plasticWindowThicknessCost = {
                        plasticWindowThicknessCostColumnName: formValues.plasticWindowThicknessTypeCost.plasticWindowThicknessCostColumnName.filter(column => /column*/.test(column.key)).map(column => ({
                          name: column.title,
                          type: column.key,
                          plasticWindowThicknessId: column.plasticWindowThicknessId,
                        })),
                        plasticWindowThicknessCost: formValues.plasticWindowThicknessTypeCost.plasticWindowThicknessCost.map(row => ({
                          plasticWindowThicknessId: row.plasticWindowThicknessId,
                          quantity: row.quantity,
                          column1: parseFloat(row.column1) || 0,
                          column2: parseFloat(row.column2) || 0,
                          column3: parseFloat(row.column3) || 0,
                          column4: parseFloat(row.column4) || 0,
                          column5: parseFloat(row.column5) || 0,
                        })),
                      }
                      const plasticWindowThicknessOperationCost = {
                        plasticWindowThicknessCostColumnName: formValues.plasticWindowThicknessTypeOperationCost.plasticWindowThicknessCostColumnName.filter(column => /column*/.test(column.key)).map(column => ({
                          name: column.title,
                          type: column.key,
                          plasticWindowThicknessId: column.plasticWindowThicknessId || null,
                        })),
                        plasticWindowThicknessCost: formValues.plasticWindowThicknessTypeOperationCost.plasticWindowThicknessCost.map(row => ({
                          plasticWindowThicknessId: row.plasticWindowThicknessId || null,
                          quantity: row.quantity,
                          column1: parseFloat(row.column1) || 0,
                          column2: parseFloat(row.column2) || 0,
                          column3: parseFloat(row.column3) || 0,
                          column4: parseFloat(row.column4) || 0,
                          column5: parseFloat(row.column5) || 0,
                        })),
                      }
                      const input = {
                        plasticWindowId: formValues.plasticWindowId,
                        thickness: formValues.thickness,
                        minimumCost: parseFloat(formValues.minimumCost),
                        minimumOperationCost: parseFloat(formValues.minimumOperationCost),
                        plasticWindowThicknessCost: [plasticWindowThicknessCost],
                        plasticWindowThicknessOperationCost: [plasticWindowThicknessOperationCost],
                      }
                      doAddPlasticWindowThickness({
                        variables: {
                          input,
                        },
                      }).then(({ data }) => {
                        if (data.addPlasticWindowThickness.success) {
                          setCallMutation(false)
                          message.success(data.addPlasticWindowThickness.message)
                          setTimeout(() => {
                            window.location = PATH.plasticSetting
                          }, 350)
                        } else {
                          setCallMutation(false)
                          if (data.addPlasticWindowThickness.message === 'ความหนาการเจาะหน้าต่างและติดแผ่นใส นี้มีอยู่ในระบบแล้ว') {
                            setIsValidate(false)
                            setCallMutation(false)
                          }
                          message.error(data.addPlasticWindowThickness.message)
                        }
                      })
                    }}
                    disabled={callMutation}
                  />
                )
              }
            </Mutation>
          </Div>
        </Div>
      </Div>
      <ThicknessForm
        formValues={formValues}
        setFormValues={setformValues}
        isValidate={isValidate}
        setIsValidate={setIsValidate}
        action={'create'}
      />
    </Div>
  )
}

export default PlasticWindownThicknessCreate
