import styled from 'styled-components'


export const CoverImageFoiling = styled.div`
    background-size: cover;
    background-image: url(${props => props.imgUrl});
    border-radius: 50%;
`

export const CoverImage = styled.img`
    background-size: cover;
    border-radius: 50%;
    width: 100%;
`

export const DetailWrapper = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    height: calc(100% - 5px);
    // height: fit-content;
    // height: -moz-fit-content;
    // row-gap: 10px;
`

export default CoverImage
