import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { message } from 'antd'
import {
  Div, H4, LoadingIcon,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import PATH from '../../../constants/path'
import { theme } from '../../../styles/_variables'
import { GET_EMBOSSING_DETAIL } from '../../../constants/graphql/query'
import { ADD_EMBOSSING, EDIT_EMBOSSING } from '../../../constants/graphql/mutation'
import {
  NewButton,
} from './styled'
import EmbossingForm from './components/embossing-form'
import { generateRowsIncrementStepTable as generateRows } from '../../../utils/util-services'
import BreadCrumb from '../../../components/common/breadcrumb'
import RecommendationSetting from '../recommendation-setting'


const { color } = theme

const EmbossingSetting = () => {
  const [isValidate, setIsValidate] = useState(true)
  const [hasEmbossing, setHasEmbossing] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    costMinimum: 0,
    sampleVideo: [],
    imagePhoto: [],
    embossingBlockCost: [],
    headerEmbossing: [{
      title: 'จำนวนผลิต (ชิ้น)',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: 'number',
      editable: false,
      editColumn: false,
      alignCell: 'center',
    }, {
      title: 'ราคาเริ่มต้น (บาท/ตร.นิ้ว)',
      dataIndex: 'column1',
      key: 'column1',
      inputType: 'number',
      editable: true,
      editColumn: false,
      alignCell: 'center',
    }],
    dataEmbossing: generateRows(),
  })
  return (
    <Div>
      <Query
        query={GET_EMBOSSING_DETAIL}
        notifyOnNetworkStatusChange
        onCompleted={(data) => {
          const embossingData = data.getEmbossingDetail.embossing
          if (embossingData) {
            setHasEmbossing(true)
            const embossingDataColumn = embossingData.embossingCostColumnName.map(item => ({
              title: item.name,
              dataIndex: item.type,
              key: item.type,
              inputType: 'number',
              editable: true,
              editColumn: item.type !== 'column1',
              alignCell: 'center',
            }))
            const embossingBlockCost = embossingData.embossingBlockCost.reduce((result, item) => {
              result.push({
                key: result.length,
                size: [{
                  width: item.width,
                  height: item.height,
                }],
                price: [item.price],
              })
              return result
            }, [])
            const dataEmbossing = embossingData.embossingCost.map((item, index) => ({
              key: index,
              quantity: item.quantity,
              column1: item.column1,
              column2: item.column2,
              column3: item.column3,
              column4: item.column4,
              column5: item.column5,
            }))
            setFormValues({
              ...formValues,
              edit: true,
              name: embossingData.name,
              description: embossingData.description,
              costMinimum: embossingData.minimumCost,
              sampleVideo: embossingData.embossingFiles.filter(file => file.type === 'video'),
              imagePhoto: embossingData.embossingFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample'),
              embossingBlockCost,
              headerEmbossing: [formValues.headerEmbossing[0], ...embossingDataColumn],
              dataEmbossing: dataEmbossing.length > 0 ? dataEmbossing : generateRows(),
            })
          }
        }}
      >
        {
          ({ loading, data, refetch }) => {
            if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
            if (!data.getEmbossingDetail.success) return <p>error something</p>
            const embossingRemark = data.getEmbossingDetail.embossing.remark
            return (
              <>
                <Div display='flex' flexDirection='row' justifyContent='space-between' margin='32px 0px 0px 0px' alignItems='center'>
                  <Div display='flex' flexDirection='column'>
                    <H4 bold color={color.primaryColor}>
                      <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                        <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                      </Link> {' > '} การปั๊มนูน
                    </H4>
                  </Div>
                  <SaveButton
                    isCreated={!hasEmbossing}
                    formValues={formValues}
                    setIsValidate={setIsValidate}
                    embossingId={data.getEmbossingDetail.embossing && data.getEmbossingDetail.embossing.embossingId}
                  />
                </Div>
                <RecommendationSetting
                  moduleName='embossing'
                  data={embossingRemark}
                  refetch={() => refetch()}
                />
                {
                  !hasEmbossing ? <EmbossingForm
                    formValues={formValues}
                    setFormValues={setFormValues}
                    isValidate={isValidate}
                    setIsValidate={setIsValidate}
                    action={'create'}
                  /> : (data.getEmbossingDetail.embossing && formValues.edit) && <EmbossingForm
                    formValues={formValues}
                    setFormValues={setFormValues}
                    isValidate={isValidate}
                    setIsValidate={setIsValidate}
                    action={'detail'}
                  />
                }
                </>
            )
          }
        }
      </Query>
    </Div>
  )
}

const SaveButton = ({
  isCreated,
  formValues,
  setIsValidate,
  embossingId,
}) => {
  const [callMutation, setCallMutation] = useState(false)
  return (
    <>
    {
      isCreated ? <Mutation mutation={ADD_EMBOSSING}>
        {doAddEmbossing => (
        <NewButton
          text='บันทึก'
          icon='fa fa-save'
          inverse
          onClick={() => {
            setCallMutation(true)
            if (formValues.name === '') {
              message.warning('กรุณากรอกข้อมูลชื่อการปั๊มนูน')
              setCallMutation(false)
              setIsValidate(false)
              return
            }

            if (formValues.name.match(new RegExp(/^\s/)) !== null) {
              message.warning('กรุณากรอกข้อมูลชื่อการปั๊มนูนให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
              setCallMutation(false)
              setIsValidate(false)
              return
            }
            const input = {
              name: formValues.name,
              description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
              minimumCost: parseFloat(formValues.costMinimum),
              video: formValues.sampleVideo,
              imagePhoto: formValues.imagePhoto,
              embossingBlockCost: formValues.embossingBlockCost.reduce((result, item) => {
                for (let index = 0; index < item.size.length; index += 1) {
                  result.push({
                    width: parseFloat(item.size[index].width),
                    height: parseFloat(item.size[index].height),
                    price: parseFloat(item.price[index]),
                  })
                }
                return result
              }, []),
              embossingCostColumnName: formValues.headerEmbossing.filter(column => /column*/.test(column.key)).map(column => ({
                name: column.title,
                type: column.key,
              })),
              embossingCost: formValues.dataEmbossing.map(row => ({
                quantity: parseInt(row.quantity, 10),
                column1: parseFloat(row.column1),
                column2: parseFloat(row.column2),
                column3: parseFloat(row.column3),
                column4: parseFloat(row.column4),
                column5: parseFloat(row.column5),
              })),
            }
            doAddEmbossing({
              variables: {
                input,
              },
            }).then(({ data }) => {
              if (data.addEmbossing) {
                setCallMutation(false)
                message.success(data.addEmbossing.message)
                setTimeout(() => {
                  window.location = PATH.embossingSetting
                }, 350)
              } else {
                setCallMutation(false)
                if (data.addEmbossing.message === 'ชื่อ การปั๊มนูน นี้มีอยู่ในระบบแล้ว') {
                  setIsValidate(false)
                  setCallMutation(false)
                }
                message.error(data.addEmbossing.message)
              }
              setCallMutation(false)
            })
          }}
          disabled={callMutation}
          loading={callMutation}
        />)}
        </Mutation> : formValues.edit && <Mutation mutation={EDIT_EMBOSSING}>
        {doEditEmbossing => (
        <NewButton
          text='บันทึก'
          icon='fa fa-save'
          inverse
          onClick={() => {
            setCallMutation(true)
            if (formValues.name === '') {
              message.warning('กรุณากรอกข้อมูลชื่อการปั๊มนูน')
              setCallMutation(false)
              setIsValidate(false)
              return
            }

            if (formValues.name.match(new RegExp(/^\s/)) !== null) {
              message.warning('กรุณากรอกข้อมูลชื่อการปั๊มนูนให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
              setCallMutation(false)
              setIsValidate(false)
              return
            }
            const input = {
              embossingId,
              name: formValues.name,
              description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
              minimumCost: parseFloat(formValues.costMinimum),
              video: formValues.sampleVideo,
              imagePhoto: formValues.imagePhoto,
              embossingBlockCost: formValues.embossingBlockCost.reduce((result, item) => {
                for (let index = 0; index < item.size.length; index += 1) {
                  result.push({
                    embossingId,
                    width: parseFloat(item.size[index].width),
                    height: parseFloat(item.size[index].height),
                    price: parseFloat(item.price[index]),
                  })
                }
                return result
              }, []),
              embossingCostColumnName: formValues.headerEmbossing.filter(column => /column*/.test(column.key)).map(column => ({
                embossingId,
                name: column.title,
                type: column.key,
              })),
              embossingCost: formValues.dataEmbossing.map(row => ({
                embossingId,
                quantity: parseInt(row.quantity, 10),
                column1: parseFloat(row.column1),
                column2: parseFloat(row.column2),
                column3: parseFloat(row.column3),
                column4: parseFloat(row.column4),
                column5: parseFloat(row.column5),
              })),
            }
            doEditEmbossing({
              variables: {
                input,
              },
            }).then(({ data }) => {
              if (data.editEmbossing) {
                setCallMutation(false)
                message.success(data.editEmbossing.message)
                setTimeout(() => {
                  window.location = PATH.embossingSetting
                }, 350)
              } else {
                setCallMutation(false)
                if (data.editEmbossing.message === 'ชื่อ Embossing นี้มีอยู่ในระบบแล้ว') {
                  setIsValidate(false)
                  setCallMutation(false)
                }
                message.error(data.editEmbossing.message)
              }
              setCallMutation(false)
            })
          }}
          disabled={callMutation}
          loading={callMutation}
        />)}
        </Mutation>
    }
    </>
  )
}

export default EmbossingSetting
