import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import { Link } from 'react-router-dom'
import { message } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import { ADD_PAPER } from '../../../../constants/graphql/mutation'
import { theme } from '../../../../styles/_variables'
import PaperForm from '../components/paper-form'
import PATH from '../../../../constants/path'

const { color } = theme


const PaperCreate = () => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    coverPhoto: [],
    sampleVideo: [],
    imagePhoto: [],
    paperTable: [],
    frontCoating: [],
    backCoating: [],
    defaultFrontCoating: [],
    defaultBackCoating: [],
  })

  return (
    <Div display='flex' flexDirection='column'>
      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
        <H4 bold color={color.primaryColor} margin='32px 0'>
            <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
              <H4 bold color={color.primaryColor}>Catalog Settings</H4>
            </Link> {' > '}
            <Link to='/package-setting/paper' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
            <H4 bold color={color.primaryColor}>กระดาษ</H4>
            </Link> {' > '} New
        </H4>
        <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
          <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
            <Link to='/package-setting/paper' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
              <Button text='ยกเลิก' icon='fa fa-ban' ghost margin='0 16px 0 0' color={color.grey}/>
            </Link>
            <Mutation
              mutation={ADD_PAPER}
            >
              {
                doAddPaper => (
                  <Button
                    text='บันทึก'
                    icon='fa fa-save'
                    inverse
                    margin='0 16px 0 0'
                    onClick={() => {
                      setCallMutation(true)
                      if (formValues.name === '') {
                        message.warning('กรุณากรอกข้อมูลชื่อประเภทกระดาษ')
                        setCallMutation(false)
                        setIsValidate(false)
                        return
                      }

                      if (formValues.name.match(new RegExp(/^\s/)) !== null) {
                        message.warning('กรุณากรอกข้อมูลชื่อประเภทกระดาษให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
                        setCallMutation(false)
                        setIsValidate(false)
                        return
                      }
                      const input = {
                        name: formValues.name,
                        description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
                        coverPhoto: formValues.coverPhoto,
                        video: formValues.sampleVideo,
                        imagePhoto: formValues.imagePhoto,
                        paperThickness: formValues.paperTable.reduce((result, paper) => {
                          for (let index = 0; index < paper.size.length; index += 1) {
                            result.push({
                              thickness: parseInt(paper.thickness, 10),
                              sizeWidth: parseFloat(paper.size[index].width),
                              sizeHeight: parseFloat(paper.size[index].height),
                              price: parseFloat(paper.price[index]),
                            })
                          }
                          return result
                        }, []),
                        coatingFrontId: formValues.frontCoating.reduce((result, item) => {
                          if (item.active) result.push(item.key)
                          return result
                        }, []),
                        coatingBehindId: formValues.backCoating.reduce((result, item) => {
                          if (item.active) result.push(item.key)
                          return result
                        }, []),
                      }
                      doAddPaper({
                        variables: {
                          input,
                        },
                      }).then(({ data }) => {
                        if (data.addPaper) {
                          if (data.addPaper.success) {
                            setCallMutation(false)
                            message.success(data.addPaper.message)
                            setTimeout(() => {
                              window.location = PATH.paperSetting
                            }, 350)
                          } else {
                            message.error(data.addPaper.message)
                          }
                        } else {
                          setCallMutation(false)
                          if (data.addPaper.message === 'ชื่อ ประเภทกระดาษ นี้มีอยู่ในระบบแล้ว') {
                            setIsValidate(false)
                            setCallMutation(false)
                          }
                          message.error(data.addPaper.message)
                        }
                        setCallMutation(false)
                      })
                    }}
                    disabled={callMutation}
                    loading={callMutation}
                  />
                )
              }
            </Mutation>
          </Div>
        </Div>
      </Div>
      <PaperForm
        formValues={formValues}
        setFormValues={setFormValues}
        isValidate={isValidate}
        setIsValidate={setIsValidate}
        action={'create'}
      />
    </Div>
  )
}

export default PaperCreate
