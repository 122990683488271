import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import { message } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import PATH from '../../../../constants/path'
import { ADD_ADDITIONAL_SERVICE } from '../../../../constants/graphql/mutation'
import { theme } from '../../../../styles/_variables'
import ExtraServiceForm from '../components/extra-service-form'
import { generateRowsIncrementStepTable as generateRows } from '../../../../utils/util-services'


const { color } = theme


const ExtraServiceCreate = () => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    costMinimum: 0,
    headerExtraService: [{
      title: 'จำนวนผลิต (ชิ้น)',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: 'number',
      editable: false,
      editColumn: false,
      alignCell: 'center',
    }, {
      title: 'ราคาเริ่มต้น (บาท/ชิ้น)',
      dataIndex: 'column1',
      key: 'column1',
      inputType: 'number',
      editable: true,
      editColumn: false,
      alignCell: 'center',
    }],
    dataExtraService: generateRows(),
  })

  return (
        <Div display='flex' flexDirection='column'>
            <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
                <H4 bold color={color.primaryColor} margin='32px 0'>
                    <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                      <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                    </Link> {' > '}
                    <Link to='/package-setting/extra-service' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                    <H4 bold color={color.primaryColor}>บริการเสริม</H4>
                    </Link> {' > '} New
                </H4>
                <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                  <Link to='/package-setting/extra-service' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                      <Button text='ยกเลิก' icon='fa fa-ban' ghost margin='0 16px 0 0' color={color.grey}/>
                    </Link>
                  <Mutation
                    mutation={ADD_ADDITIONAL_SERVICE}
                  >
                    {
                      doAddAdditionalService => (
                        <Button
                          text='บันทึก'
                          icon='fa fa-save'
                          inverse
                          margin='0 16px 0 0'
                          onClick={() => {
                            setCallMutation(true)
                            if (formValues.name === '') {
                              message.warning('กรุณากรอกข้อมูลชื่อบริการเสริม')
                              setCallMutation(false)
                              setIsValidate(false)
                              return
                            }

                            if (formValues.name.match(new RegExp(/^\s/)) !== null) {
                              message.warning('กรุณากรอกข้อมูลชื่อบริการเสริมให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
                              setCallMutation(false)
                              setIsValidate(false)
                              return
                            }
                            const input = {
                              name: formValues.name,
                              description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
                              minimumCost: parseFloat(formValues.costMinimum),
                              additionalServiceCostColumnName: formValues.headerExtraService.filter(column => /column*/.test(column.key)).map(column => ({
                                name: column.title,
                                type: column.key,
                              })),
                              additionalServiceCost: formValues.dataExtraService.map(row => ({
                                quantity: parseInt(row.quantity, 10),
                                column1: parseFloat(row.column1),
                                column2: parseFloat(row.column2),
                                column3: parseFloat(row.column3),
                                column4: parseFloat(row.column4),
                                column5: parseFloat(row.column5),
                              })),
                            }
                            doAddAdditionalService({
                              variables: {
                                input,
                              },
                            }).then(({ data }) => {
                              if (data.addAdditionalService) {
                                setCallMutation(false)
                                message.success(data.addAdditionalService.message)
                                setTimeout(() => {
                                  window.location = PATH.extraServiceSetting
                                }, 350)
                              } else {
                                setCallMutation(false)
                                if (data.addAdditionalService.message === 'ชื่อ บริการเสริม นี้มีอยู่ในระบบแล้ว') {
                                  setIsValidate(false)
                                  setCallMutation(false)
                                }
                                message.error(data.addAdditionalService.message)
                              }
                            })
                          }}
                          disabled={callMutation}
                        />
                      )
                    }
                  </Mutation>
                </Div>
                </Div>
            </Div>
            <ExtraServiceForm
              formValues={formValues}
              setFormValues={setFormValues}
              isValidate={isValidate}
              setIsValidate={setIsValidate}
              action={'create'}
            />
        </Div>
  )
}

export default ExtraServiceCreate
