/* eslint-disable no-prototype-builtins */
import React, { useState } from 'react'
import _ from 'lodash'
import {
  Div,
} from 'konsys-ui'
import { message } from 'antd'
import { Query } from 'react-apollo'
import { theme } from '../../../../styles/_variables'
import {
  Box,
  Label,
  TextLabel,
  TextValue,
  ButtonAction,
  SummaryBox,
  ButtonEdit,
  RowHeader,
  Header,
  SubHeader,
  Row,
  Divider,
  IconEdit,
  SectionDiv,
  ItemDiv,
  ContentDiv,
  TextUnit,
} from './styled'
import TabSummary from '../../../common/tab-summary'
import { PRICE_CALCULATOR } from '../../../../constants/graphql/query'
import EditIcon from '../../../../images/icon/EditIcon.png'
import CalculationIcon from '../../../../images/icon/CalculationIcon.png'
import PATH from '../../../../constants/path'


const Summary = ({
  formValues,
  activeTab,
  onChangeTab,
  defaultPrinting,
  sticky,
  isFixedPosition = false,
  isPreDefine = false,
  setPriceValues,
  priceValues,
  step,
  setStep,
  isNotTop,
  isTop180,
  ContentRef,
  orderId,
  style,
  isFullWidth,
  setSummaryActive,
  isShowGluing,
}) => {
  const [callCalculation, setCallCalculation] = useState(false)
  return (
  <Box style={style} sticky={sticky} isFixedPosition={isFixedPosition} isNotTop={isNotTop} isTop180={isTop180} isFullWidth={isFullWidth}>
    <TabSummary
      onChange={onChangeTab}
      data={formValues.partData && formValues.partData}
      handleChangeTap={onChangeTab}
      activeTab={activeTab}
      sticky={sticky}
      ContentRef={ContentRef}
      isTop180={isTop180}
    >
      <Div display='flex' flexDirection='column'>
        <RowHeader>
          <Row>
            <Header>{formValues.partData[activeTab].packageData ? formValues.partData[activeTab].packageData.name : '-' }</Header>
          </Row>
          <Row>
            <SubHeader>{formValues.partData[activeTab].packagePartName || '-' }</SubHeader>
          </Row>
          <Divider/>
        </RowHeader>
        <SummaryBox>
          <SectionDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
            <Label>ขนาดบรรุจุภัณฑ์</Label>
          </SectionDiv>
          <ContentDiv display='flex' flexDirection='column' >
            {
              !defaultPrinting ? <>
                {
                  (formValues.partData[activeTab].sizeList.hasOwnProperty('a') && formValues.partData[activeTab].sizeList.a >= 0) && <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
                    <TextLabel>ความยาวด้าน A</TextLabel>
                    <TextValue>{ _.isNumber(formValues.partData[activeTab].sizeList.a) ? formValues.partData[activeTab].sizeList.a : '-' } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                  </ItemDiv>
                }
                {
                  (formValues.partData[activeTab].sizeList.hasOwnProperty('b') && formValues.partData[activeTab].sizeList.b >= 0) && <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                    <TextLabel>ความยาวด้าน B</TextLabel>
                    <TextValue>{ _.isNumber(formValues.partData[activeTab].sizeList.b) ? formValues.partData[activeTab].sizeList.b : '-' } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                  </ItemDiv>
                }
                {
                  (formValues.partData[activeTab].sizeList.hasOwnProperty('c') && formValues.partData[activeTab].sizeList.c >= 0) && <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                    <TextLabel>ความยาวด้าน C</TextLabel>
                    <TextValue>{ _.isNumber(formValues.partData[activeTab].sizeList.c) ? formValues.partData[activeTab].sizeList.c : '-' } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                  </ItemDiv>
                }
                {
                  (formValues.partData[activeTab].sizeList.hasOwnProperty('d') && formValues.partData[activeTab].sizeList.d >= 0) && <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                    <TextLabel>ความยาวด้าน D</TextLabel>
                    <TextValue>{ _.isNumber(formValues.partData[activeTab].sizeList.d) ? formValues.partData[activeTab].sizeList.d : '-' } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                  </ItemDiv>
                }
                {
                  (formValues.partData[activeTab].sizeList.hasOwnProperty('e') && formValues.partData[activeTab].sizeList.e >= 0) && <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                    <TextLabel>ความยาวด้าน E</TextLabel>
                    <TextValue>{ _.isNumber(formValues.partData[activeTab].sizeList.e) ? formValues.partData[activeTab].sizeList.e : '-' } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                  </ItemDiv>
                }
                {
                  (formValues.partData[activeTab].sizeList.hasOwnProperty('f') && formValues.partData[activeTab].sizeList.f >= 0) && <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                    <TextLabel>ความยาวด้าน F</TextLabel>
                    <TextValue>{ _.isNumber(formValues.partData[activeTab].sizeList.f) ? formValues.partData[activeTab].sizeList.f : '-' } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                  </ItemDiv>
                }
                {
                  (formValues.partData[activeTab].sizeList.hasOwnProperty('g') && formValues.partData[activeTab].sizeList.g >= 0) && <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                    <TextLabel>ความยาวด้าน G</TextLabel>
                    <TextValue>{ _.isNumber(formValues.partData[activeTab].sizeList.g) ? formValues.partData[activeTab].sizeList.g : '-' } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                  </ItemDiv>
                }
                {
                  (formValues.partData[activeTab].sizeList.hasOwnProperty('h') && formValues.partData[activeTab].sizeList.h >= 0) && <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                    <TextLabel>ความยาวด้าน H</TextLabel>
                    <TextValue>{ _.isNumber(formValues.partData[activeTab].sizeList.h) ? formValues.partData[activeTab].sizeList.h : '-' } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                  </ItemDiv>
                }
                {
                  (formValues.partData[activeTab].sizeList.hasOwnProperty('i') && formValues.partData[activeTab].sizeList.i >= 0) && <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                    <TextLabel>ความยาวด้าน I</TextLabel>
                    <TextValue>{ _.isNumber(formValues.partData[activeTab].sizeList.i) ? formValues.partData[activeTab].sizeList.i : '-' } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                  </ItemDiv>
                }
                {
                  (formValues.partData[activeTab].sizeList.hasOwnProperty('j') && formValues.partData[activeTab].sizeList.j >= 0) && <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                    <TextLabel>ความยาวด้าน J</TextLabel>
                    <TextValue>{ _.isNumber(formValues.partData[activeTab].sizeList.j) ? formValues.partData[activeTab].sizeList.j : '-' } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                  </ItemDiv>
                }
              </> : <>
                {
                  formValues.partData[activeTab].hasOwnProperty('printSizeWidth') && <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
                    <TextLabel>ด้านกว้าง</TextLabel>
                    <TextValue>{ formValues.partData[activeTab].printSizeWidth || '-' } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                  </ItemDiv>
                }
                {
                  formValues.partData[activeTab].hasOwnProperty('printSizeLength') && <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                    <TextLabel>ด้านยาว</TextLabel>
                    <TextValue>{ formValues.partData[activeTab].printSizeLength || '-' } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                  </ItemDiv>
                }
              </>
            }
          </ContentDiv>
          <SectionDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
            <Label>ประเภทกระดาษ</Label>
            <TextValue>{ formValues.partData[activeTab].paper.name || '-' }</TextValue>
          </SectionDiv>
          <ContentDiv display='flex' flexDirection='column' >
            <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
              <TextLabel>ความหนากระดาษ</TextLabel>
              <TextValue>{ formValues.partData[activeTab].paper.selectedThickness || 0} <TextUnit>แกรม</TextUnit></TextValue>
            </ItemDiv>
          </ContentDiv>

          <SectionDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
            <Label>การพิมพ์ลวดลาย</Label>
            <TextValue>{ formValues.partData[activeTab].printing.printingName || 'ไม่พิมพ์ลวดลาย' }</TextValue>
          </SectionDiv>
          {
            (formValues.partData[activeTab].printing.printingId > 0 || formValues.partData[activeTab].printing.printingName) && <ContentDiv display='flex' flexDirection='column' >
              <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
                <TextLabel>ลักษณะการพิมพ์</TextLabel>
                <TextValue>{ formValues.partData[activeTab].printing.printingTypeName || '-' }</TextValue>
              </ItemDiv>
              <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                <TextLabel>รูปแบบสีการพิมพ์</TextLabel>
                <TextValue>{ formValues.partData[activeTab].printing.printingBasicColorName || '-' }</TextValue>
              </ItemDiv>
              { formValues.partData[activeTab].printing.printingExtraColorId && formValues.partData[activeTab].printing.printingExtraColorId.map((item, index) => (
                <ItemDiv key={index} display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                  <TextLabel>สีพิเศษ {index + 1}</TextLabel>
                  <TextValue>{ item.printingExtraColorName || '-' }</TextValue>
                </ItemDiv>
              ))}
            </ContentDiv>
          }

          <SectionDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
            <Label>การเคลือบผิว</Label>
            <TextValue>{ formValues.partData[activeTab].coating.coatingType || '-' }</TextValue>
          </SectionDiv>
          {
            formValues.partData[activeTab].coating.coatingType !== 'ไม่เคลือบ' && <ContentDiv display='flex' flexDirection='column' >
              {
                formValues.partData[activeTab].coating.coatingType === 'เคลือบสองหน้า' && <ItemDiv display='flex' flexDirection='column' >
                  <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
                    <TextLabel>การเคลือบด้านหน้า</TextLabel>
                    <TextValue>{ formValues.partData[activeTab].coating.coatingFrontName || '-' }</TextValue>
                  </ItemDiv>
                  <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                    <TextLabel>การเคลือบด้านหลัง</TextLabel>
                    <TextValue>{ formValues.partData[activeTab].coating.coatingBackName || '-' }</TextValue>
                  </ItemDiv>
                </ItemDiv>
              }
              {
                formValues.partData[activeTab].coating.coatingType === 'เคลือบด้านหน้า' && <ItemDiv display='flex' flexDirection='column' >
                  <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
                    <TextLabel>การเคลือบด้านหน้า</TextLabel>
                    <TextValue>{ formValues.partData[activeTab].coating.coatingFrontName || '-' }</TextValue>
                  </ItemDiv>
                </ItemDiv>
              }
              {
                formValues.partData[activeTab].coating.coatingType === 'เคลือบด้านหลัง' && <ItemDiv display='flex' flexDirection='column' >
                  <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
                    <TextLabel>การเคลือบด้านหลัง</TextLabel>
                    <TextValue>{ formValues.partData[activeTab].coating.coatingBackName || '-' }</TextValue>
                  </ItemDiv>
                </ItemDiv>
              }
            </ContentDiv>
          }

          <SectionDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
            <Label>การเคลือบยูวีเฉพาะจุด</Label>
            <TextValue>{ formValues.partData[activeTab].spotUV.spotUVType || '-' }</TextValue>
          </SectionDiv>
          {
            formValues.partData[activeTab].spotUV.spotUVType !== 'ไม่เคลือบ' && <ContentDiv display='flex' flexDirection='column' >
              {
                formValues.partData[activeTab].spotUV.spotUVType === 'เคลือบสองหน้า' && <ItemDiv display='flex' flexDirection='column' >
                  <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
                    <TextLabel>การเคลือบด้านหน้า</TextLabel>
                    <TextValue>{ formValues.partData[activeTab].spotUV.spotUVFrontName || '-' }</TextValue>
                  </ItemDiv>
                  <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                    <TextLabel>การเคลือบด้านหลัง</TextLabel>
                    <TextValue>{ formValues.partData[activeTab].spotUV.spotUVBackName || '-' }</TextValue>
                  </ItemDiv>
                </ItemDiv>
              }
              {
                formValues.partData[activeTab].spotUV.spotUVType === 'เคลือบด้านหน้า' && <ItemDiv display='flex' flexDirection='column' >
                  <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
                    <TextLabel>การเคลือบด้านหน้า</TextLabel>
                    <TextValue>{ formValues.partData[activeTab].spotUV.spotUVFrontName || '-' }</TextValue>
                  </ItemDiv>
                </ItemDiv>
              }
              {
                formValues.partData[activeTab].spotUV.spotUVType === 'เคลือบด้านหลัง' && <ItemDiv display='flex' flexDirection='column' >
                  <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
                    <TextLabel>การเคลือบด้านหลัง</TextLabel>
                    <TextValue>{ formValues.partData[activeTab].spotUV.spotUVBackName || '-' }</TextValue>
                  </ItemDiv>
                </ItemDiv>
              }
            </ContentDiv>
          }

          <SectionDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
            <Label>การปั๊มนูน</Label>
            <TextValue>{ formValues.partData[activeTab].embossing.length > 0 ? 'ใช้บริการ' : 'ไม่ใช้บริการ' }</TextValue>
          </SectionDiv>
          { formValues.partData[activeTab].embossing.length > 0 && <ContentDiv display='flex' flexDirection='column'>
              { formValues.partData[activeTab].embossing.map((item, index) => (
                <ItemDiv key={index} display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: index > 0 && '4px' }}>
                  <TextLabel>ตำแหน่งที่ {index + 1}</TextLabel>
                  <TextValue>{ `${item.width || '0'}x${item.lengths || '0'}` } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                </ItemDiv>
              ))}
            </ContentDiv>
          }

          <SectionDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
            <Label>การปั๊มฟอยล์-ทองเค</Label>
            <TextValue>{ formValues.partData[activeTab].foilingName || 'ไม่ใช้บริการ' }</TextValue>
          </SectionDiv>
          { formValues.partData[activeTab].foiling.length > 0 && <ContentDiv display='flex' flexDirection='column'>
              { formValues.partData[activeTab].foiling.map((item, index) => (
                <ItemDiv key={index} display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: index > 0 && '4px' }}>
                  <TextLabel>ตำแหน่งที่ {index + 1}</TextLabel>
                  <TextValue>{ `${item.width || '0'}x${item.lengths || '0'}` } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                </ItemDiv>
              ))}
            </ContentDiv>
          }

          <SectionDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
            <Label>การเจาะหน้าต่างและติดแผ่นใส</Label>
            <TextValue>{ formValues.partData[activeTab].plasticWindow.length > 0 ? 'ใช้บริการ' : 'ไม่ใช้บริการ' }</TextValue>
          </SectionDiv>
          { formValues.partData[activeTab].plasticWindow.length > 0 && <ContentDiv display='flex' flexDirection='column'>
              { formValues.partData[activeTab].plasticWindow.map((item, index) => (
                <ItemDiv key={index} style={{ marginTop: index > 0 && '4px' }}>
                  <ItemDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
                    <TextLabel>ตำแหน่งที่ {index + 1}</TextLabel>
                    <TextValue>{ `${item.width || '0'}x${item.lengths || '0'}` } <TextUnit>{ formValues.partData[activeTab].measurementUnit.label }</TextUnit></TextValue>
                  </ItemDiv>
                  <ItemDiv key={index} display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: '4px' }}>
                    <TextLabel></TextLabel>
                    <TextValue><TextLabel>ความหนา</TextLabel> { item.thickness }</TextValue>
                  </ItemDiv>
                </ItemDiv>
              ))}
            </ContentDiv>
          }
          {
            (isShowGluing && (formValues.partData[activeTab].gluingName || formValues.partData[activeTab].gluing)) && <SectionDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
              <Label>วิธีการติดกาว</Label>
              <TextValue>{ formValues.partData[activeTab].gluing ? formValues.partData[activeTab].gluing.gluing ? formValues.partData[activeTab].gluing.gluing : formValues.partData[activeTab].gluing.name : formValues.partData[activeTab].gluingName }</TextValue>
            </SectionDiv>
          }
          {
            formValues.partData[activeTab].dieCut && <SectionDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
              <Label>บริการปั้มตัด</Label>
              <TextValue>{ formValues.partData[activeTab].dieCut ? 'ใช้บริการ' : 'ไม่ใช้บริการ' }</TextValue>
            </SectionDiv>
          }
          {
            formValues.partData[activeTab].additionalServiceId && formValues.partData[activeTab].additionalServiceId.length > 0 && <SectionDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
              <Label>บริการเสริม</Label>
              <TextValue>ใช้บริการ</TextValue>
            </SectionDiv>
          }
          { formValues.partData[activeTab].additionalServiceId && formValues.partData[activeTab].additionalServiceId.length > 0 && <ContentDiv display='flex' flexDirection='column'>
              { formValues.partData[activeTab].additionalServiceId.map((item, index) => (
                <ItemDiv key={index} display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: index > 0 && '4px' }}>
                  <Label>บริการเสริมที่ {index + 1}</Label>
                  <TextValue>{item ? item.title ? item.title : item.name ? item.name : '-' : '-'}</TextValue>
                </ItemDiv>
              ))}
            </ContentDiv>
          }
          {/* alternative additionalservice */}
          {
            formValues.partData[activeTab].additionalService && formValues.partData[activeTab].additionalService.length > 0 && <SectionDiv display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start'>
              <Label>บริการเสริม</Label>
              <TextValue>ใช้บริการ</TextValue>
            </SectionDiv>
          }
          { formValues.partData[activeTab].additionalService && formValues.partData[activeTab].additionalService.length > 0 && <ContentDiv display='flex' flexDirection='column'>
              { formValues.partData[activeTab].additionalService.map((item, index) => (
                <ItemDiv key={index} display='flex' flexDirection='row' justifyContent='space-between' alignItems='flext-start' style={{ marginTop: index > 0 && '4px' }}>
                  <Label>บริการเสริมที่ {index + 1}</Label>
                  <TextValue>{item ? item.title ? item.title : item.name ? item.name : '-' : '-'}</TextValue>
                </ItemDiv>
              ))}
            </ContentDiv>
          }
        </SummaryBox>
      </Div>
      {
        isPreDefine && <Div display='flex' flexDirection='row' justifyContent='center' alignItems='center' style={{ paddingTop: '24px' }}>
          <ButtonAction
            inverse
            text={<React.Fragment><IconEdit src={EditIcon}/>แก้ไขข้อมูล</React.Fragment>}
            color={theme.color.primaryColor}
            buttonStyle="round"
            stlye={{ fontSize: theme.fonts.size.label }}
            onClick={() => {
              setTimeout(() => {
                window.location = `${PATH.quotationPreDefinedEditItem}/${orderId}`
              }, 200)
            }}
          />
          <Query
            query={PRICE_CALCULATOR}
          >
            {
              ({ refetch }) => (
              <ButtonAction
                inverse
                text={<React.Fragment>{callCalculation ? '' : <IconEdit src={CalculationIcon}/> }คำนวณราคา</React.Fragment>}
                color={theme.color.primaryColor}
                buttonStyle="round"
                loading={callCalculation}
                disabled={callCalculation}
                onClick={async () => {
                  setCallCalculation(true)
                  const part = formValues.partData.map((item, index) => ({
                    order: index + 1,
                    packagePartId: item.packagePartId,
                    unit: item.measurementUnit.label === 'ซม.' ? 'cm' : 'inches',
                    operator: Object.values(item.sizeList).filter(x => x >= 0).map(x => (parseFloat(x))),
                    printSizeWidth: 0.0,
                    printSizeLength: 0.0,
                    paper: {
                      paperId: item.paper.paperId,
                      thickness: parseFloat(item.paper.selectedThickness),
                    },
                    printing: item.printing.side && {
                      side: item.printing.printingType,
                      printingBasicColorId: item.printing.printingBasicColorId,
                      printingExtraColorId: item.printing.printingExtraColorId.filter(x => x.printingExtraColorId).map(x => x.printingExtraColorId),
                    },
                    coating: {
                      coatingIdFront: item.coating.side === 1 || item.coating.side === 2 ? item.coating.coatingFrontId : null,
                      coatingIdBehind: item.coating.side === -1 || item.coating.side === 2 ? item.coating.coatingBackId : null,
                    },
                    spotUV: {
                      spotUVIdFront: item.spotUV.spotUVFrontId,
                      spotUVIdBehind: item.spotUV.spotUVBackId,
                    },
                    embossing: item.embossing.map((x, i) => ({
                      order: i + 1,
                      width: parseFloat(x.width),
                      length: parseFloat(x.lengths),
                      unit: item.measurementUnit.label === 'ซม.' ? 'cm' : 'inches',
                    })),
                    foiling: {
                      foilingId: item.foilingId !== 0 ? item.foilingId : null,
                      foilingPart: item.foiling.map((x, i) => ({
                        order: i + 1,
                        width: parseFloat(x.width),
                        length: parseFloat(x.lengths),
                        unit: item.measurementUnit.label === 'ซม.' ? 'cm' : 'inches',
                      })),
                    },
                    dieCut: item.dieCut,
                    plasticWindow: item.plasticWindow.map((x, i) => ({
                      order: i + 1,
                      width: parseFloat(x.width),
                      length: parseFloat(x.lengths),
                      thickness: x.thickness,
                      unit: item.measurementUnit.label === 'ซม.' ? 'cm' : 'inches',
                    })),
                    gluingId: item.gluing ? item.gluing.gluingId : null,
                    additionalServiceId: item.additionalService.map(x => x.additionalServiceId) || [],
                  }))
                  const input = {
                    part,
                  }
                  await refetch({
                    input,
                  }).then((res) => {
                    setCallCalculation(false)
                    if (res.data.priceCalculator.success) {
                      message.success('คำนวนราคาสำเร็จ')
                      setPriceValues({
                        ...priceValues,
                        incrementalSteps: formValues.incrementalSteps,
                        minimumManufacture: formValues.minimumManufacture,
                        amountProduction: formValues.produceQuantity,
                        partDetail: res.data.priceCalculator.partDetail,
                        totalCost: res.data.priceCalculator.totalCost,
                        costDetail: res.data.priceCalculator.costDetail,
                      })
                      const tmpFormValues = {
                        ...priceValues,
                        incrementalSteps: formValues.incrementalSteps,
                        minimumManufacture: formValues.minimumManufacture,
                        amountProduction: formValues.produceQuantity,
                        partDetail: res.data.priceCalculator.partDetail,
                        totalCost: res.data.priceCalculator.totalCost,
                        costDetail: res.data.priceCalculator.costDetail,
                      }
                      localStorage.removeItem('priceValues')
                      localStorage.setItem('priceValues', JSON.stringify(tmpFormValues))
                      setTimeout(() => {
                        window.open(`${PATH.quotationPreDefinedPriceItem}/${orderId}`, '_blank')
                      }, 200)
                    } else if (res.data.priceCalculator.message === 'oversize') {
                      message.warning('ไม่สามารถให้บริการผลิตขนาดดังกล่าวได้ เนื่องจากมีขนาดเกินเครื่องพิมพ์ ของทางเรา ขออภัยในความไม่สะดวก')
                    } else if (res.data.priceCalculator.message === 'ขออภัย ขนาดที่ระบุ ไม่สามารถผลิตบนกระดาษชนิดนี้ได้') {
                      message.warning('ขออภัย ขนาดที่ระบุ ไม่สามารถผลิตบนกระดาษชนิดนี้ได้')
                    } else if (res.data.priceCalculator.message === 'ไม่สามารถคำนวณ Width ได้' || res.data.priceCalculator.message === 'ไม่สามารถคำนวณ Length ได้') {
                      message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                    } else {
                      message.success('สามารถให้บริการผลิตขนาดดังกล่าวได้')
                    }
                  }).catch((e) => {
                    setCallCalculation(false)
                    message.error('ไม่สามารถคำนวนราคาได้')
                  })
                }}
              />
              )
           }
          </Query>
        </Div>
      }
      {
        !isPreDefine && step === 2 && <Div display="flex" justifyContent="center" alignItems="center" style={{ marginTop: '24px' }}>
          <ButtonEdit
            text={<React.Fragment><IconEdit src={EditIcon}/>แก้ไขข้อมูล</React.Fragment>}
            ghost
            inverse
            small
            onClick={async () => {
              const element = document.getElementById('content-package')
              if (element) {
                await setStep(1)
                await element.scrollIntoView({
                  behavior: 'smooth',
                })
              }
              setSummaryActive(false)
            }}
          />
        </Div>
      }
    </TabSummary>
  </Box>
  )
}

export default Summary
