import styled, { css } from 'styled-components'
import {
  Div, Button,
} from 'konsys-ui'
import { Col } from 'antd'
import { theme, media } from '../../../styles/_variables'

export const StyledItem = styled.div`
float: left;
width: 100%;
height: 100%;
display: flex;
flex-flow: column;
filter: drop-shadow(0 2px 3px rgb(0 0 0 / 0.01)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.02));
& {
    > div {
      -webkit-transition-duration: 400ms;
      transition-duration: 400ms;
      transition-delay: 50ms;
    }
  }
}
`

export const StyledContainer = styled.div`
  margin: 0px;
`

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`

export const StyledHandle = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  overflow: hidden;

  > svg {
    fill: ${theme.color.primaryColor};
    opacity: 0.5;
  }

  cursor: grab;
`

export const Column = styled(Col)`
  flexBasis: 20%;
  width: 20%;
  padding: 8px;
  display: flex;
  ${media.tablet`
    flexBasis: 33%;
    width: 33%;
  `}
  ${media.mobileL`
    flexBasis: 50%;
    width: 50%;
  `}
`
export const ImageLabel = styled.div`
  display: flex;
  height: fit-content;
  font-weight: ${theme.fonts.style.semiBold};
  font-size: ${theme.fonts.size.extraNormal};
  margin-bottom: 0;
  padding-left: ${props => (props.isFree ? '8px' : '16px')};
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: ${theme.color.secondaryColor};
  background-color: ${theme.color.offWhite};
  word-break: break-all;
`

export const ImageDescription = styled.div`
  font-weight: 500;
  font-size: ${theme.fonts.size.small};
  padding: 4px 16px 16px 16px;
  color: ${theme.color.textColor};
  height: 100%;
  word-break: break-word;
  p {
    margin-bottom: 0px;
    line-height: 1.2;
  }
`

export const DivPromotionList = styled.div`
  display: flex;
  margin: 24px 0px;
  flex-wrap: wrap;
  ${media.mobileL`
    justify-content: center;
  `}
`

export const DivAction = styled.div`
  display: flex;
  justify-content: center;
`

export const ImageWithButtonWrapper = styled.div`
  width: calc((100% - 72px) / 3);
  margin: 12px;
  text-align: center;
`

export const ImageWrapper = styled.div`
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-top: 100%;
  cursor: pointer;
  position: relative;
  ${props => props.img && css`
    background-image: url(${props.img});
    background-size: cover;
    background-position: center;
  `}
`

export const DivPromotion = styled(Div)`
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  flex: 1 1 auto;
  background-color: ${theme.color.offWhite};
  margin-bottom: 16px;
`

export const ButtonDiv = styled(Button)`
  font-size: ${theme.fonts.size.normal};
  font-weight: ${theme.fonts.style.medium};
  padding: 8px 24px;
  ${media.laptopL`
    font-size: 12px;
    padding: 8px 24px;
  `}
  ${media.laptop`
    font-size: 12px;
    padding: 6px 18px;
  `}
  ${media.tablet`
    font-size: 10px;
    padding: 6px 16px;
    margin-top: 84px;
  `}
  ${media.mobileL`
    font-size: 10px;
  `}
`

export default ImageWrapper
