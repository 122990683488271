import React, { useState } from 'react'
import { Mutation, Query } from 'react-apollo'
import { Link } from 'react-router-dom'
import { message } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import { EDIT_CUTTING, ADD_CUTTING } from '../../../constants/graphql/mutation'
import { GET_DIE_CUTTING } from '../../../constants/graphql/query'
import { theme } from '../../../styles/_variables'
import DieCuttingForm from './components/die-cutting-form'
import { generateRowsIncrementStepTable as generateRows } from '../../../utils/util-services'
import BreadCrumb from '../../../components/common/breadcrumb'
import PATH from '../../../constants/path'

const { color } = theme

const DieCuttingSetting = () => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [action, setAction] = useState('create')
  const [dieCutLoading, setDieCutLoading] = useState(false)
  const [formValues, setFormValues] = useState({
    dieCutId: null,
    costMinimum: 0,
    dieCutBlocks: [],
    headerDieCuttingOperationCost: [{
      title: 'จำนวนผลิต (ชิ้น)',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: 'number',
      editable: false,
      editColumn: false,
      alignCell: 'center',
    }, {
      title: 'ราคาเริ่มต้น (บาท/ตร.นิ้ว)',
      dataIndex: 'column1',
      key: 'column1',
      inputType: 'number',
      editable: true,
      editColumn: false,
      alignCell: 'center',
    }],
    dieCuttingOperationCost: generateRows(),
  })

  return (
    <>
      <Div display='flex' flexDirection='column'>
        <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
            <H4 bold color={color.primaryColor} margin='32px 0'>
                <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                </Link> {' > '}
                <Link to='/package-setting/die-cutting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                <H4 bold color={color.primaryColor}>การปั๊มตัด</H4>
                </Link>
            </H4>
            <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
            <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
              <Mutation
                mutation={action === 'edit' ? EDIT_CUTTING : ADD_CUTTING}
              >
                {
                  doDieCutting => (
                    <Button
                      text='บันทึก'
                      icon='fa fa-save'
                      inverse
                      margin='0 16px 0 0'
                      onClick={() => {
                        setCallMutation(true)
                        const header = formValues.headerDieCuttingOperationCost.reduce((result, item, index) => {
                          if (index > 0) {
                            result.push({
                              name: item.title,
                              type: item.dataIndex,
                              dieCutId: formValues.dieCutId,
                            })
                          }
                          return result
                        }, [])
                        let input = {}
                        if (action === 'edit') {
                          input = {
                            dieCutId: formValues.dieCutId,
                            minimumCost: parseFloat(formValues.costMinimum),
                            dieCutBlock: formValues.dieCutBlocks.map(item => ({
                              width: parseFloat(item.size[0].width),
                              height: parseFloat(item.size[0].height),
                              price: parseFloat(item.price),
                              dieCutId: formValues.dieCutId,
                              dieCutBlockId: item.dieCutBlockId,
                            })),
                            dieCutCostColumnName: header,
                            dieCutCost: formValues.dieCuttingOperationCost.map(item => ({
                              dieCutId: formValues.dieCutId,
                              quantity: item.quantity,
                              column1: parseFloat(item.column1),
                              column2: parseFloat(item.column2),
                              column3: parseFloat(item.column3),
                              column4: parseFloat(item.column4),
                              column5: parseFloat(item.column5),
                            })),
                          }
                        } else if (action === 'create') {
                          input = {
                            minimumCost: parseFloat(formValues.costMinimum),
                            dieCutBlock: formValues.dieCutBlocks.map(item => ({
                              width: parseFloat(item.size[0].width),
                              height: parseFloat(item.size[0].height),
                              price: parseFloat(item.price),
                            })),
                            dieCutCostColumnName: header,
                            dieCutCost: formValues.dieCuttingOperationCost.map(item => ({
                              quantity: item.quantity,
                              column1: parseFloat(item.column1),
                              column2: parseFloat(item.column2),
                              column3: parseFloat(item.column3),
                              column4: parseFloat(item.column4),
                              column5: parseFloat(item.column5),
                            })),
                          }
                        }
                        doDieCutting({
                          variables: {
                            input,
                          },
                        }).then(({ data }) => {
                          if (data.editDieCut) {
                            setCallMutation(false)
                            message.success(data.editDieCut.message)
                            setTimeout(() => {
                              window.location = PATH.dieCuttingSetting
                            }, 100)
                          } else if (data.addDieCut) {
                            setCallMutation(false)
                            message.success(data.addDieCut.message)
                            setTimeout(() => {
                              window.location = PATH.dieCuttingSetting
                            }, 350)
                          } else {
                            setCallMutation(false)
                            if (data.addPaper.message === 'ชื่อ การปั๊มตัด นี้มีอยู่ในระบบแล้ว') {
                              setIsValidate(false)
                              setCallMutation(false)
                            }
                            message.error(data.addPaper.message)
                          }
                          setCallMutation(false)
                        })
                      }}
                      disabled={!dieCutLoading || callMutation}
                      loading={!dieCutLoading || callMutation}
                    />
                  )
                }
              </Mutation>
            </Div>
            </Div>
        </Div>
        <Query
          query={GET_DIE_CUTTING}
          fetchPolicy='network-only'
          notifyOnNetworkStatusChange
          onCompleted={async (data) => {
            if (data.getDieCutDetail.dieCut) {
              const tmpData = data.getDieCutDetail.dieCut
              const dataDieCuttingOperationCost = tmpData.dieCutCost.map((item, index) => ({
                key: index,
                quantity: item.quantity,
                column1: item.column1,
                column2: item.column2,
                column3: item.column3,
                column4: item.column4,
                column5: item.column5,
              }))
              const headerDieCuttingOperationCost = [formValues.headerDieCuttingOperationCost[0], ...tmpData.dieCutCostColumnName.map(item => ({
                title: item.name,
                dataIndex: item.type,
                key: item.type,
                inputType: 'number',
                editable: true,
                editColumn: item.type !== 'column1',
                alignCell: 'center',
              }))]
              if (headerDieCuttingOperationCost.length === 1) {
                headerDieCuttingOperationCost.push({
                  title: 'ราคาเริ่มต้น (บาท/ตร.นิ้ว)',
                  dataIndex: 'column1',
                  key: 'column1',
                  inputType: 'number',
                  editable: true,
                  editColumn: false,
                  alignCell: 'center',
                })
              }
              const dieCutBlock = await tmpData.dieCutBlock.map((item, index) => ({
                key: index,
                price: item.price,
                size: [{
                  width: item.width,
                  height: item.height,
                }],
                dieCutBlockId: item.dieCutBlockId,
              }))
              await setFormValues({
                dieCutId: tmpData.dieCutId,
                costMinimum: tmpData.minimumCost,
                dieCutBlocks: dieCutBlock,
                headerDieCuttingOperationCost,
                dieCuttingOperationCost: dataDieCuttingOperationCost.length > 0 ? dataDieCuttingOperationCost : generateRows(),
              })
              await setDieCutLoading(true)
              setAction('edit')
            } else {
              await setDieCutLoading(true)
            }
          }}
        >
        {
          () => (
          <>
            {
              action === 'edit' && dieCutLoading && <DieCuttingForm
                formValues={formValues}
                setFormValues={setFormValues}
                isValidate={isValidate}
                setIsValidate={setIsValidate}
              />
            }
            {
              action === 'create' && <DieCuttingForm
                formValues={formValues}
                setFormValues={setFormValues}
                isValidate={isValidate}
                setIsValidate={setIsValidate}
              />
            }
          </>
          )
        }
        </Query>
      </Div>
    </>
  )
}

export default DieCuttingSetting
