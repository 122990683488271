import React from 'react'
import { Link } from 'react-router-dom'
import { Popconfirm, message } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import parseHtml from 'html-react-parser'
import PATH from '../../../constants/path'
import { LIST_GLUING } from '../../../constants/graphql/query'
import { DELETE_GLUING } from '../../../constants/graphql/mutation'
import RecommendationSetting from '../recommendation-setting'
import {
  ButtonAction,
  SimpleTable, ActionGroupWrapper,
  Column, Description,
} from './styled'
import { theme } from '../../../styles/_variables'
import BreadCrumb from '../../../components/common/breadcrumb'


const { color } = theme

const columns = [{
  title: 'วิธีการติดกาว',
  dataIndex: 'name',
  render: text => <Column>{text}</Column>,
  align: 'center',
},
{
  title: 'คำอธิบาย',
  dataIndex: 'description',
  render: text => <Column>{text}</Column>,
  align: 'center',
},
{
  title: 'คำสั่ง',
  dataIndex: 'action',
  align: 'center',
  width: '120px',
},
]

const GluingSetting = props => (
  <Div>
    <Div display='flex' flexDirection='row' justifyContent='space-between' margin='32px 0px 0px 0px' alignItems='center'>
      <Div display='flex' flexDirection='column'>
        <H4 bold color={color.primaryColor}>
          <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
            <H4 bold color={color.primaryColor}>Catalog Settings</H4>
          </Link> {' > '} การติดกาว
        </H4>
        </Div>
        <Link to='/package-setting/gluing-create' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
          <Button
            htmlType="submit"
            text='New'
            icon='fal fa-plus'
            inverse
          />
        </Link>
    </Div>
    <TableListGluing history={props.history} />
  </Div>
)

const TableListGluing = ({
  history,
}) => (
  <Div style={{ width: '640px' }}>
    <Query
      query={LIST_GLUING}
      fetchPolicy='cache-and-network'
      variables={{
        limit: 10000,
        offset: 0,
      }}
    >
      {
        ({ loading, data, refetch }) => {
          let gluingList = []
          let gluingRemark = null
          if (!loading) {
            gluingList = data.listGluing.gluing.map(item => ({
              name: item.name,
              description: <Description>{item.description ? parseHtml(item.description) : '-'}</Description>,
              action: <ActionGroup rowData={item} onEdit={() => history.push(`${PATH.gluingDetail}/${item.gluingId}`)}/>,
            }))
            gluingRemark = data.listGluing.remark
          }
          return (
            <>
              {/* <RecommendationSetting
                moduleName='gluing'
                data={gluingRemark}
                refetch={() => refetch()}
              /> */}
              <SimpleTable
                loading={loading}
                columns={columns}
                dataSource={gluingList}
                pagination={false}
              />
            </>
          )
        }
      }
    </Query>
  </Div>
)

const ActionGroup = ({ rowData, onEdit }) => (
  <ActionGroupWrapper>
    <ButtonAction small inverse text='แก้ไข' icon='fa fa-pencil-square-o' onClick={onEdit} />
    <Mutation mutation={DELETE_GLUING}>
      {
        doDeleteCoating => (
          <Popconfirm
            title='ยืนยันการลบการติดกาวหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'
            onConfirm={() => {
              doDeleteCoating({
                variables: {
                  id: parseInt(rowData.gluingId, 10),
                },
              }).then((res) => {
                if (res.data.deleteGluing) {
                  if (res.data.deleteGluing.success) {
                    message.success(res.data.deleteGluing.message)
                    setTimeout(() => {
                      window.location = PATH.gluingSetting
                    }, 100)
                  } else {
                    message.error(res.data.deleteGluing.message)
                  }
                }
              })
            }}
            okText='ยืนยัน'
            cancelText='ยกเลิก'
          >
            <ButtonAction small text='ลบ' icon='fa fa-trash-o fa-lg' color={theme.color.error} />
          </Popconfirm>
        )
      }
    </Mutation>
  </ActionGroupWrapper>
)

export default GluingSetting
