import styled from 'styled-components'
import { Select as AntSelect } from 'antd'
import { theme } from '../../../../styles/_variables'


export const Title = styled.p`
color: ${theme.color.primaryColor};
font-size: ${theme.fonts.size.title};
margin-bottom: 0;
`

export const Select = styled(AntSelect)`
.ant-select-selection{
    width: 300px;
}
`

export default Select
