import styled from 'styled-components'
import { Button } from 'konsys-ui'
import { Table as TableAnt } from 'antd'
import { theme } from '../../../styles/_variables'


export const NewButton = styled(Button)`
  border-radius: 5px;
  padding: 0px 10px;
  i {
    font-size: ${theme.fonts.size.normal} !important;
    font-weight: ${theme.fonts.style.medium};
  }
  span {
    font-size: ${theme.fonts.size.subTitle};
    font-weight: ${theme.fonts.style.medium};
  }
`

export const ButtonAction = styled(Button)`
  padding: 2px 6px !important;
  border-radius: 20px;
`

export const ActionGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  row-gap: 5px;
  width: 60px;
`

export const SimpleTable = styled(TableAnt)`
  margin-top: 30px;
  min-width: 60%;
  width: fit-content;
  .ant-table-column-title {
    color: ${theme.color.primaryColor};
    font-size: ${theme.fonts.size.subTitle};
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 10px 10px 10px 10px;
    border-bottom: 0px;
  }
  .ant-table-row {
    font-size: ${theme.fonts.size.extraNormal};
    color: ${theme.color.textColor};
    vertical-align: middle;
  }
  border: ${theme.color.primaryColor} solid 1px;
  border-radius: 5px;
  tr:nth-child(even) {
    background-color: ${theme.color.offWhite};
  }
`

export const LabeColumn = styled.div`
  margin: 0px 20px;
  text-align: left;
  font-size: ${theme.fonts.size.extraNormal};
`

export default NewButton
