import React, { useState } from 'react'
import {
  Div, H4, Input,
} from 'konsys-ui'
import { theme } from '../../../../../styles/_variables'
import {
  NewButton,
} from './styled'


const { color } = theme
const TagInput = ({
  handleAdd,
}) => {
  const [name, setName] = useState('')
  const handleChangeName = (value) => {
    setName(value.target.value)
  }
  return (
    <Div display='flex' flexDirection='column'>
        <H4 bold color={color.primaryColor}>
            เพิ่ม Tag ใหม่
        </H4>
        <Div display='flex' flexDirection='row' justifyContent='flex-end' moreStyle={{
          columnGap: '10px',
          marginTop: '18px',
        }}>
          <Input
            width='100%'
            onChange={value => handleChangeName(value)}
            value={name}
          />
          <NewButton
            htmlType="submit"
            text='New Tag'
            icon='fal fa-plus'
            inverse
            onClick={() => {
              handleAdd(name)
              setName('')
            }}
          />
        </Div>
    </Div>
  )
}

export default TagInput
