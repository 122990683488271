import styled from 'styled-components'
import { P as PKS } from 'konsys-ui'
import Img from '../../components/common/img'

export const Div = styled.div`
  position: absolute;
  top: -9px;
`

export const Image = styled(Img)`
  width: 30px;
  height: 30px;
`

export const a = styled.div`
`

export const P = styled(PKS)`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`
