import React from 'react'
import _ from 'lodash'
import {
  Checkbox,
} from 'antd'
import {
  Div, H5, P, Input,
} from 'konsys-ui'
import {
  Title, TextRowWrapper, SummaryBoxWrapper, Link,
  CheckboxLabel,
} from './styled'
import { theme } from '../../../styles/_variables'

const TextRow = ({
  data, editable, onChange, branchData, isPredefined, withHoldingTaxRate,
}) => (
  isPredefined ? <TextRowWrapper margin={'0px'}>
    <Div
      flexBasis='45%'
      display='flex'
      alignItems='center'
      padding={'0'}
    >
      <P color={'black'}>{data.title}</P>
    </Div>
    <Div
      flexBasis='55%'
      textAlign='right'
      padding={'0'}
      // flexDirection={(data.name === 'sampleFiles' || data.name === 'designFiles') ? 'column' : 'row'}
    >
    {
      data.name === 'sampleFiles' || data.name === 'designFiles'
        ? data.value.map((v) => {
          if (v[data.name === 'sampleFiles' ? 'sampleFileUri' : 'designFileUri']) {
            const fileUri = v[data.name === 'sampleFiles' ? 'sampleFileUri' : 'designFileUri']
            const splitUrl = fileUri.split('/')
            return <Div key={fileUri}>
                <Link
                  style={{ display: 'inline-block' }}
                  href={fileUri}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <P display='block' textAlign='right' margin='0 0 4px'>{splitUrl[splitUrl.length - 1]}</P>
                </Link>
              </Div>
          }
          return null
        })
        : <P style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap', textAlign: 'end' }}>{data.value || '-'}</P>
    }
    </Div>
  </TextRowWrapper>
    : <TextRowWrapper type={data.name} margin={data.name === 'detail' || data.name === 'detail-technique' ? '0' : '0'}>
    <Div
      flexBasis='34%'
      display='flex'
      alignItems='center'
      padding={data.name === 'detail' || data.name === 'detail-technique' ? '24px 16px' : '0'}
      bgColor={data.name === 'detail' ? theme.color.primaryColor : data.name === 'detail-technique' && theme.color.paleGray}
    >
      <P color={data.name === 'detail' ? theme.color.offWhite : data.name === 'detail-technique' && 'black'}>{data.title}</P>
    </Div>
    <Div
      flexBasis='66%'
      textAlign='right'
      padding={(data.name === 'detail' || data.name === 'detail-technique') ? '24px 16px' : '0'}
      bgColor={(data.name === 'detail' || data.name === 'detail-technique') && theme.color.offWhite}
      flexDirection={(data.name === 'sampleFiles' || data.name === 'designFiles') ? 'column' : 'row'}
    >
    {
      data.name === 'companyType'
        ? <Div margin='16px 0' display='inline-flex' flexDirection='column' alignItems='flex-start'>
          <Div>
            <input
              checked={data.value === 'headquarter'}
              value='headquarter'
              onChange={e => onChange(e.target.value)}
              type='radio'
              name='companyType'
              style={{ margin: '0 8px 0 0' }}
              disabled={!editable}
            /> สำนักงานใหญ่
          </Div>
          <Div>
            <input disabled={!editable} checked={data.value === 'branch'} value='branch' onChange={e => onChange(e.target.value)} type='radio' name='companyType' style={{ margin: '0 8px 0 0' }} /> สาขา
            {
              editable
                ? <Input disabled={data.value !== 'branch'} value={branchData || ''} onChange={e => onChange(e.target.value, 'branch')} margin='0 0 0 32px' type='text' />
                : <P style={{ wordBreak: 'break-all' }}>&nbsp;{branchData || ''}</P>
            }
          </Div>
        </Div>
        : data.name === 'withHoldingTax'
          ? <Div display='inline-flex' flexDirection='column' alignItems='flex-start'>
          <Div
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Checkbox
              style={{ display: 'flex', alignItems: 'center' }}
              checked={data.value}
              name="withHoldingTax"
              disabled={!editable}
              onChange={(e) => {
                onChange(e.target.checked)
                // if (onChangeWithHoldingTax) onChangeWithHoldingTax(e.target.checked)
              }}
            >
              {/* <CheckboxLabel>หักภาษี ณ ท่ีจ่าย {withHoldingTaxRate} %</CheckboxLabel> */}
            </Checkbox>
            <span
              style={{
                paddingLeft: 8,
              }}
            >
              หักภาษี ณ ท่ีจ่าย {withHoldingTaxRate} %
            </span>
          </Div>
        </Div>
          : data.name === 'sampleFiles' || data.name === 'designFiles'
            ? data.value.map((v) => {
              if (v[data.name === 'sampleFiles' ? 'sampleFileUri' : 'designFileUri']) {
                const fileUri = v[data.name === 'sampleFiles' ? 'sampleFileUri' : 'designFileUri']
                const splitUrl = fileUri.split('/')
                return <Div key={fileUri}>
                <Link
                  style={{ display: 'inline-block' }}
                  href={fileUri}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <P display='block' textAlign='right' margin='0 0 4px'>{splitUrl[splitUrl.length - 1]}</P>
                </Link>
              </Div>
              }
              return null
            })
            : editable
              ? <Input
                  width='70%'
                  defaultValue={data.value}
                  name={data.name}
                  onChange={e => onChange(e.target.value, data.name)}
                />
              : <P style={{ wordBreak: 'break-all' }}>{data.value || '-'}</P>
    }
    </Div>
  </TextRowWrapper>
)

const SummaryBox = ({
  title, dataList, editable, onChange, margin, isPredefined,
}) => (
  <SummaryBoxWrapper margin={margin}>
    <Title>
      <H5 color={theme.color.offWhite}>{title}</H5>
    </Title>
    {
      dataList.length > 0
      && <Div padding='8px 0'>
      {
        _.filter(dataList, d => d !== null && d.value !== null).map((v, i) => {
          if (v.name !== 'branch' && v.name !== 'withHoldingTaxRate') {
            return (
              <TextRow
                isPredefined={isPredefined}
                editable={editable}
                key={v.title}
                data={v}
                onChange={(value, name) => {
                  if (name === 'companyName' && (!value || value === '')) {
                    onChange(3, '', 'companyType')
                  }
                  onChange(i, value, name)
                }}
                withHoldingTaxRate={_.filter(dataList, e => e && e.name === 'withHoldingTaxRate').length > 0 && _.filter(dataList, e => e && e.name === 'withHoldingTaxRate')[0].value}
                branchData={_.filter(dataList, e => e && e.name === 'branch').length > 0 && _.filter(dataList, e => e && e.name === 'branch')[0].value}
              />
            )
          }
          return null
        })
      }
      </Div>
    }
  </SummaryBoxWrapper>
)

export default SummaryBox
