import React from 'react'
import {
  Container,
  ButtonActionPlus,
  ButtonActionMinus,
} from './styled'

const RowContainer = ({
  index,
  item,
  length,
  onClickPlus,
  onClickMinus,
  children,
  disabledFirstItem,
  maxItem,
}) => (
    <Container>
      <div style={{ margin: '0 5px 0 0', width: '100%' }}>
        {children}
      </div>
      <ButtonActionMinus
        icon="fa fa-minus"
        onlyIcon
        inverse
        small
        buttonStyle="round"
        disabled={disabledFirstItem ? (index >= 0 && length <= 1) : (index > 0 && length < 1)}
        onClick={() => onClickMinus(item.value, index)}
      />
      <ButtonActionPlus
        icon="fa fa-plus"
        onlyIcon
        inverse
        small
        buttonStyle="round"
        disabled={(length !== 1 && length - index !== 1) || length === maxItem}
        onClick={() => onClickPlus(index)}
      />
    </Container>
)

export default RowContainer
