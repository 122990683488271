import React, { useState } from 'react'
import { Div, Button } from 'konsys-ui'
import { Query } from 'react-apollo'
import { message } from 'antd'
import NotchedContainer from '../../../common/notched-containner'
import {
  InputWrapper, Input, FormWrapper,
  CenterWrapper, ButtonConfirm,
  IconConfirm,
} from './styled'
import DimensionIcon from '../../../../images/icon/DimensionIcon.png'
import { CHECK_PRINTER_SIZE } from '../../../../constants/graphql/query'
import ConfirmIcon from '../../../../images/icon/ConfirmIcon.png'


const PrintingSize = ({
  formValues,
  setFormValues,
  unit,
  activeTab,
  setDisabled,
}) => {
  const [printingSize, setPrintingSize] = useState({
    width: null,
    length: null,
  })
  const handleChange = (value, key) => {
    const tmpFormValues = formValues.partData
    tmpFormValues[activeTab].printSizeWidth = key === 'width' ? value : tmpFormValues[activeTab].printSizeWidth
    tmpFormValues[activeTab].printSizeLength = key === 'length' ? value : tmpFormValues[activeTab].printSizeLength
    setFormValues({
      partData: tmpFormValues,
      ...formValues,
    })
  }

  return <Div display='flex' flexDirection='column' moreStyle={{ rowGap: '20px' }}>
  <NotchedContainer
    title='ขนาดใบพิมพ์'
    titleIcon={DimensionIcon}
  >
    <FormWrapper>
      <CenterWrapper>
        <InputDimension
          title={'ด้านกว้าง'}
          value={formValues.partData[activeTab] && formValues.partData[activeTab].printSizeWidth}
          unit={formValues.partData[activeTab].measurementUnit.label}
          onChange={v => handleChange(v, 'width')}
        />
        <InputDimension
          title={'ด้านยาว'}
          value={formValues.partData[activeTab] && formValues.partData[activeTab].printSizeLength}
          unit={formValues.partData[activeTab].measurementUnit.label}
          onChange={v => handleChange(v, 'length')}
        />
        <Query
            query={CHECK_PRINTER_SIZE}
          >
          {
            ({ loading, data, refetch }) => (
              <ButtonConfirm
                inverse
                buttonStyle='round'
                text={<React.Fragment><IconConfirm src={ConfirmIcon} />ยืนยันขนาด</React.Fragment>}
                onClick={async () => {
                  if ((formValues.partData[activeTab].printSizeWidth === null || formValues.partData[activeTab].printSizeWidth === '') && (formValues.partData[activeTab].printSizeLength === null || formValues.partData[activeTab].printSizeLength === '')) {
                    message.warning('กรุณากรอกข้อมูลด้านกว้างและด้านยาวให้ครบถ้วน')
                    setDisabled(true)
                    return
                  }
                  if (formValues.partData[activeTab].printSizeWidth === null || formValues.partData[activeTab].printSizeWidth === '') {
                    message.warning('กรุณากรอกข้อมูลด้านกว้างให้ครบถ้วน')
                    setDisabled(true)
                    return
                  }
                  if (formValues.partData[activeTab].printSizeLength === null || formValues.partData[activeTab].printSizeLength === '') {
                    message.warning('กรุณากรอกข้อมูลด้านยาวให้ครบถ้วน')
                    setDisabled(true)
                    return
                  }
                  if (formValues.partData[activeTab].printSizeWidth === 0 && formValues.partData[activeTab].printSizeLength === 0) {
                    message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                    setDisabled(true)
                    return
                  }
                  if (formValues.partData[activeTab].printSizeWidth <= 0 || formValues.partData[activeTab].printSizeLength <= 0) {
                    message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                    setDisabled(true)
                    return
                  }
                  setDisabled(false)
                  const input = {
                    unit: unit.key,
                    width: parseFloat(formValues.partData[activeTab].printSizeWidth, 10),
                    length: parseFloat(formValues.partData[activeTab].printSizeLength, 10),
                    operator: [],
                    packagePartId: null,
                  }
                  await refetch({
                    input,
                  }).then((res) => {
                    const result = res.data.printerSize
                    if (result.message === 'oversize') {
                      setDisabled(true)
                      message.warning('ไม่สามารถให้บริการผลิตขนาดดังกล่าวได้ เนื่องจากมีขนาดเกินเครื่องพิมพ์ ของทางเรา ขออภัยในความไม่สะดวก')
                    } else if (res.data.printerSize.message === 'ไม่สามารถคำนวณ Width ได้' || res.data.printerSize.message === 'ไม่สามารถคำนวณ Length ได้') {
                      setDisabled(true)
                      message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                    } else {
                      setDisabled(false)
                      message.success('สามารถให้บริการผลิตขนาดดังกล่าวได้')
                    }
                  })
                }}
              />
            )
          }
        </Query>
      </CenterWrapper>
    </FormWrapper>
  </NotchedContainer>
</Div>
}

const InputDimension = ({
  title,
  value,
  unit,
  onChange,
}) => (
  <InputWrapper>
    <p>{title}</p>
    <Input
      type='number'
      min={0}
      max={9999}
      value={value}
      onChange={onChange}
      onKeyPress={(e) => {
        if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
          e.preventDefault()
        }
      }}
    />
    <span>{unit}</span>
  </InputWrapper>
)


export default PrintingSize
