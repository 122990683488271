import React, { useState } from 'react'
import _ from 'lodash'
import {
  message,
} from 'antd'
import {
  Div, P, Input,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import {
  Label, NewButton, TagDiv, TagDivDefault,
} from './styled'
import { LIST_TAG } from '../../../../constants/graphql/query'
import { ADD_TAG } from '../../../../constants/graphql/mutation'
import { theme } from '../../../../styles/_variables'


const { color } = theme
const Tag = ({
  formValues,
  setFormValues,
}) => {
  const [name, setName] = useState('')
  const [callMutation, setCallMutation] = useState(false)
  const handleChangeName = (value) => {
    setName(value.target.value)
  }

  const handleAdd = (value, doAddTag, refetch) => {
    if (!value) {
      message.warning('กรุณากรอกชื่อ Tag ให้ครบถ้วน')
      setCallMutation(false)
    } else {
      const input = {}
      input.name = value
      doAddTag({
        variables: {
          input,
        },
      }).then(({ data: response }) => {
        if (response.addTag) {
          if (response.addTag.success) {
            setCallMutation(false)
            message.success(response.addTag.message)
            refetch()
          } else {
            if (response.addTag.message === 'ชื่อ Tag นี้มีอยู่ในระบบแล้ว') {
              setCallMutation(false)
            }
            message.error(response.addTag.message)
            setCallMutation(false)
          }
        }
      })
    }
  }

  const handleSelectTag = (tag) => {
    const tmpTag = [...formValues.tag, tag]
    setFormValues({ ...formValues, tag: tmpTag })
  }

  const removeSelectTag = (item) => {
    const newTmpTag = [...formValues.tag]
    _.remove(newTmpTag, v => v.tagId === item.tagId)
    setFormValues({ ...formValues, tag: newTmpTag })
  }
  return (
    <Div display='flex' flexDirection='column'>
      <Label style={{ paddingRight: '24px' }}>Tags</Label>
      <Query
        query={LIST_TAG}
        fetchPolicy='network-only'
        variables={{
          limit: 10000,
          offset: 0,
          order: 'createdAt',
          sort: 'ASC',
        }}
      >
        {
            ({ loading, data, refetch }) => {
              if (!loading) {
                if (!data.listTag && !data.listTag.success) {
                  return (<P>error something</P>)
                }
              }
              return (
                <>
                  <Div display='flex' flexDirection='row' justifyContent='flex-end' moreStyle={{
                    columnGap: '10px',
                  }}>
                    <Input
                      width='100%'
                      onChange={value => handleChangeName(value)}
                      value={name}
                    />
                    <Mutation
                      mutation={ADD_TAG}
                    >
                      {
                        doAddTag => (
                          <NewButton
                            htmlType="submit"
                            text='New Tag'
                            icon='fal fa-plus'
                            inverse
                            onClick={() => {
                              handleAdd(name, doAddTag, refetch)
                              setName('')
                            }}
                            disabled={callMutation}
                          />
                        )
                      }
                    </Mutation>
                  </Div>
                  <Div display='flex' flexDirection='row' moreStyle={{
                    columnGap: '12px',
                    flexWrap: 'wrap',
                    rowGap: '12px',
                    marginTop: '12px',
                  }}>
                    {
                      formValues.tag && formValues.tag.map(item => (
                        <TagDiv
                          key={item.tagId}
                          htmlType="submit"
                          text={item.name}
                          icon='fa fa-tag fa-rotate-90'
                          inverse
                          onClick={() => removeSelectTag(item)}
                        />
                      ))
                    }
                  </Div>
                  <Div display='flex' flexDirection='row' moreStyle={{
                    columnGap: '12px',
                    flexWrap: 'wrap',
                    rowGap: '12px',
                    marginTop: '48px',
                  }}>
                    {
                      formValues.tag && (data.listTag && data.listTag.success) && data.listTag.tag.filter(({ tagId }) => !formValues.tag.some(o => o.tagId === tagId)).map(item => (
                        <TagDivDefault
                          key={item.tagId}
                          htmlType="submit"
                          text={item.name}
                          icon='fa fa-tag fa-rotate-90'
                          inverse
                          onClick={() => handleSelectTag(item)}
                        />
                      ))
                    }
                  </Div>
                </>
              )
            }
          }
      </Query>
    </Div>
  )
}

export default Tag
