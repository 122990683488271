import styled from 'styled-components'
import {
  Icon, Div, H4,
} from 'konsys-ui'
import { Tabs as AntTabs } from 'antd'
import { theme, minMedia } from '../../../styles/_variables'


export const FullWidthContainer = styled.div`
  width: 100%;
`

export const NumberLabel = styled(Icon)`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // font-size: ${theme.fonts.size.normal};
  // background-color:${props => (props.activeTab ? theme.color.primaryColor : theme.color.offGray)};
  // width: 28px;
  // height: 28px;
  // color: ${props => (props.activeTab ? theme.color.offGray : '#8a8a8a')};
  // border-radius: 50%;
  // font-style: normal;
  // font-weight: bold;
`

export const ContentTabPane = styled(Div)`
  padding: 12px;
  background-color: ${theme.color.offWhite}
  border-radius: 8px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
`

export const Title = styled(H4)`
  margin-left: 0px;
  margin-right: 20px;
  margin-top: 16px;
`

export const TopTabBar = styled(Div)`
  .ant-tabs-bar > .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div > .ant-tabs-tab.ant-tabs-tab-active{
    height: 33px !important;
    border: 0px !important;
    margin-top: 7px !important;
    background-color: #FFFFFF !important;
    padding-left: 6px !important;
    padding-right: 10px !important;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }

  .ant-tabs-bar > .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div > .ant-tabs-tab{
    height: 33px !important;
    border: 0px !important;
    margin-top: -7px !important;
    background-color: #8a8a8a !important;
    padding-left: 6px !important;
    padding-right: 10px !important;
  }

  .ant-tabs-bar > .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div{
    margin-top: 0px !important;
  }

  .ant-tabs-bar > .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div > .ant-tabs-tab > .tab-title{
    padding: 4px 4px 0px 4px !important;
  }
`

export const Tabs = styled(AntTabs)`
.ant-tabs-nav-wrap {
  height: 40px;
  margin-left: 4px;
  margin-bottom: 1px;
  overflow: hidden;
}
.ant-tabs-nav-scroll {
  height: 40px;
}
.ant-tabs-bar  ant-tabs-top-bar {
  height: 40px;
}
.ant-tabs-bar {
  margin: 0;
  border-bottom: none;
  border-radius: 16px;
}
.ant-tabs-tabpane {
  border-radius: 16px;
}
.ant-tabs-nav .ant-tabs-tab{
  margin: 0 2px 0 0;
  padding: 0;
  .tab{
    z-index: 0;
  }
}
.ant-tabs-tab .tab .label .circle-number {
  color: ${theme.color.grey} !important;
  background: ${theme.color.offWhite};
}
.ant-tabs-ink-bar{
  height: 0;
}
.ant-tabs-tab-active{
  z-index: 10;
  filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
}
.ant-tabs-tab-active .tab{
  border-bottom: 40px solid ${theme.color.lightGray};
  border-color: transparent transparent ${theme.color.offWhite} transparent !important;
}
.ant-tabs-tab-active .tab .label .circle-number-active {
  color: ${theme.color.offWhite};
  background: ${theme.color.secondaryColor};
}
`

export const TrapezoidTab = styled.div`
  .tab {
    height: 0;
    width: 40px;
    border-bottom: 40px solid #a8a8a8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-sizing: border-box;
    display: block;
  }
  .tab.tab-active {
    width: 46px;
  }
  .tab .label.label-active {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding-top: 6px;
    padding-bottom: 0px;
  }
  .tab .label.label-inactive {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding-top: 6px;
    padding-bottom: 0px;
  }
  .tab .label .circle-number {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
    height: 24px;
    color: #a8a8a8;
    font-size: ${theme.fonts.size.normal};
    font-weight: ${theme.fonts.style.semiBold};
    border-radius: 50%;
  }

  .tab .label .circle-number-active {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
    height: 24px;
    font-size: ${theme.fonts.size.normal};
    font-weight: ${theme.fonts.style.semiBold};
    border-radius: 50%;
  }
`


export default FullWidthContainer
