import React, { useState } from 'react'
import { Mutation, Query } from 'react-apollo'
import { Link } from 'react-router-dom'
import { message } from 'antd'
import {
  Div, H4, Button, LoadingIcon,
} from 'konsys-ui'
import { UPDATE_DELIVERY } from '../../../constants/graphql/mutation'
import { LIST_DELIVERY } from '../../../constants/graphql/query'
import { theme } from '../../../styles/_variables'
import ShippingForm from './components/shipping-form'
import { generateRowsIncrementStepTable as generateRows } from '../../../utils/util-services'
import BreadCrumb from '../../../components/common/breadcrumb'


import PATH from '../../../constants/path'

const { color } = theme

const ShippingSetting = () => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    costMinimum: 0,
    dieCutBlocks: [],
    headerShippingCost: [{
      title: 'จำนวนผลิต (ชิ้น)',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: 'number',
      editable: false,
      editColumn: false,
      alignCell: 'center',
    }],
    shippingCost: generateRows(),
  })

  return (
    <Div display='flex' flexDirection='column'>
      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
          <H4 bold color={color.primaryColor} margin='32px 0'>
              <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                <H4 bold color={color.primaryColor}>Catalog Settings</H4>
              </Link> {' > '}
              <Link to='/package-setting/shipping' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
              <H4 bold color={color.primaryColor}>บริการจัดส่งทั่วประเทศ</H4>
              </Link>
          </H4>
          <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
          <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
            <Mutation
              mutation={UPDATE_DELIVERY}
            >
              {
                doDelivery => (
                  <Button
                    text='บันทึก'
                    icon='fa fa-save'
                    inverse
                    margin='0 16px 0 0'
                    onClick={() => {
                      setCallMutation(true)
                      const input = {
                        delivery: formValues.shippingCost.map(item => ({
                          deliveryId: item.deliveryId,
                          quantity: parseInt(item.quantity, 10),
                          column1: parseInt(item.column1, 10),
                          column2: parseInt(item.column2, 10),
                          column3: parseInt(item.column3, 10),
                          column4: parseInt(item.column4, 10),
                          column5: parseInt(item.column5, 10),
                        })),
                        deliveryColumnName: formValues.headerShippingCost.reduce((result, item, index) => {
                          if (index > 0) {
                            result.push({
                              deliveryColumnNameId: item.deliveryColumnNameId,
                              name: item.title,
                              type: item.dataIndex,
                            })
                          }
                          return result
                        }, []),
                      }
                      doDelivery({
                        variables: {
                          input,
                        },
                      }).then(({ data }) => {
                        if (data.updateDelivery) {
                          setCallMutation(false)
                          message.success(data.updateDelivery.message)
                          setTimeout(() => {
                            window.location = PATH.shippingSetting
                          }, 350)
                        } else {
                          setCallMutation(false)
                          message.error(data.updateDelivery.message)
                        }
                        setCallMutation(false)
                      })
                    }}
                    disabled={callMutation}
                    loading={callMutation}
                  />
                )
              }
            </Mutation>
          </Div>
          </Div>
      </Div>
      <Query
        query={LIST_DELIVERY}
        notifyOnNetworkStatusChange
        onCompleted={async (data) => {
          if (data.listDelivery.success) {
            const { delivery, deliveryColumnName } = data.listDelivery
            await setFormValues({
              ...formValues,
              shippingCost: delivery.map((item, index) => ({
                key: index,
                deliveryId: item.deliveryId,
                quantity: item.quantity,
                column1: item.column1,
                column2: item.column2,
                column3: item.column3,
                column4: item.column4,
                column5: item.column5,
              })),
              headerShippingCost: [...formValues.headerShippingCost, ...deliveryColumnName.map(item => ({
                title: item.name,
                dataIndex: item.type,
                key: item.type,
                inputType: 'number',
                editable: true,
                editColumn: item.type !== 'column1',
                alignCell: 'center',
                deliveryColumnNameId: item.deliveryColumnNameId,
              }))],
            })
          }
        }}
      >
      {
         ({ loading, data }) => {
           if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
           if (!data.listDelivery.success) return <p>error something</p>
           return (
            <ShippingForm
              formValues={formValues}
              setFormValues={setFormValues}
              isValidate={isValidate}
              setIsValidate={setIsValidate}
            />
           )
         }
      }
      </Query>
    </Div>
  )
}

export default ShippingSetting
