import React, { useState, useEffect } from 'react'
import {
  Title, OptionsContainer, Container,
  ScrollWrapper, NoOptionContainer,
} from './styled'
import RectangleOptionItem from '../rectangle-option-item'
import CircleOptionItem from '../circle-option-item'
import GrapicTypeSelectItem from '../grapic-type-select-item'

const OptionsList = ({
  title = 'Title',
  options,
  circle,
  onSelect,
  gridColumn,
  grahic,
  black,
  disabled,
  noOptionText = 'ไม่มีตัวเลือก',
  fullWidth,
  disabledScrollX,
}) => {
  const [items, setItems] = useState([])
  useEffect(() => {
    setItems(options)
  }, [options])

  const onChecked = (e) => {
    const tmp = [...items]
    const value = Number.isNaN(e) ? e : parseInt(e, 10)
    setItems(tmp.map(item => (item.value === value ? { ...item, checked: true } : { ...item, checked: false })))
    const checkedItem = items.find(item => item.value === value)
    onSelect(checkedItem)
  }

  return (
    <Container>
      <Title black={black}>
        {title}
      </Title>
      <ScrollWrapper disabledScrollX={disabledScrollX}>
        {
          items.length === 0 ? <NoOptionContainer>{noOptionText}</NoOptionContainer>
            : <OptionsContainer gridColumn={gridColumn || (circle ? 3 : 2)} grahic={grahic}>
            {
              items && items.length > 0 && items.map((item, index) => {
                if (circle) {
                  return (
                    <CircleOptionItem
                      key={item.key}
                      id={item.key}
                      value={item.value}
                      checked={item.checked}
                      onChecked={onChecked}
                      icon={item.icon}
                      label={item.label}
                      textOverlay={item.textOverlay}
                      disabled={disabled}
                      backgroundRed={item.backgroundRed}
                    />
                  )
                }
                if (grahic) {
                  return (
                    <GrapicTypeSelectItem
                      key={index}
                      id={item.value}
                      value={item.value}
                      checked={item.checked}
                      onChecked={onChecked}
                      icon={item.icon}
                      label={item.label}
                      textOverlay={item.textOverlay}
                      padding={item.padding}
                      fullWidth={fullWidth}
                    />
                  )
                }
                return (
                  <RectangleOptionItem
                    key={index}
                    id={item.value}
                    value={item.value}
                    checked={item.checked}
                    onChecked={onChecked}
                    icon={item.icon}
                    label={item.label}
                    disabled={disabled}
                  />
                )
              })
            }
          </OptionsContainer>
        }
      </ScrollWrapper>
    </Container>
  )
}

export default OptionsList
