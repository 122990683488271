import React, { useState, useEffect } from 'react'
import {
  Div, Icon,
} from 'konsys-ui'
import { message } from 'antd'
import { Mutation } from 'react-apollo'
import parseHtml from 'html-react-parser'
import {
  ButtonAction,
  Label,
  ActionGroupWrapper,
  ContentDescription,
  Container,
} from './styled'
import { ADD_REMARK, EDIT_REMARK } from '../../../constants/graphql/mutation'
import { theme } from '../../../styles/_variables'
import Description from './components/description'


const RecommendationSetting = ({ data, refetch, moduleName }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [value, setValue] = useState(null)
  const [action, setAction] = useState('edit')
  const [callMutation, setCallMutation] = useState(false)
  const [, setIsValidate] = useState(true)
  const [rollbackValue, setRollbackValue] = useState('')

  const handleOnEdit = () => {
    setIsEdit(true)
  }

  const handleChangeInput = (v) => {
    setValue(v)
  }

  const handleOnCancel = () => {
    setIsEdit(false)
    setValue(rollbackValue)
    setTimeout(() => {
      refetch()
    }, 1000)
  }
  useEffect(() => {
    if (data) {
      setValue(data)
      setRollbackValue(data)
      setAction('edit')
    } else {
      setAction('add')
      setValue({
        description: '',
      })
    }
  }, [data])
  return (
    <Container>
      <Div
        display='flex'
        flexDirection='row'
        alignContent='center'
        alignItems='center'
        moreStyle={{ columnGap: '5px' }}
      >
        <Icon icon='far fa-question-circle' color={theme.color.tertiaryColor} />
        <Label>
            คำแนะนำ
        </Label>
        {isEdit ? <ActionGroup
            handleOnCancel={handleOnCancel}
            callMutation={callMutation}
            setCallMutation={setCallMutation}
            setIsValidate={setIsValidate}
            setIsEdit={setIsEdit}
            action={action}
            moduleName={moduleName}
            description={value && value.description}
            remarkId={value && value.remarkId}
            refetch={refetch}
          />
          : <ButtonAction
              small
              inverse
              text={data ? 'แก้ไข' : 'เพิ่ม'}
              icon={data ? 'fa fa-pencil-square-o' : 'fal fa-plus'}
              onClick={handleOnEdit}
            />
        }
      </Div>
      { value !== null ? isEdit ? <Description
            data={value}
            onInputChange={handleChangeInput}
          /> : <ReadState value={value.description}/>
        : ''
      }
    </Container>
  )
}

const ReadState = ({ value }) => (
  <ContentDescription>
    {value ? parseHtml(value) : ''}
  </ContentDescription>
)

const ActionGroup = ({
  handleOnCancel,
  callMutation,
  setCallMutation,
  setIsValidate,
  setIsEdit,
  action,
  moduleName,
  description,
  remarkId,
  refetch,
}) => (
  <ActionGroupWrapper>
    <ButtonAction
      small
      text='Cancel'
      icon='fa fa-times'
      onClick={handleOnCancel}
    />
    <SaveButton
      callMutation={callMutation}
      setCallMutation={setCallMutation}
      setIsValidate={setIsValidate}
      setIsEdit={setIsEdit}
      action={action}
      moduleName={moduleName}
      description={description}
      remarkId={remarkId}
      refetch={refetch}
    />
  </ActionGroupWrapper>
)

const SaveButton = ({
  callMutation,
  setCallMutation,
  setIsValidate,
  setIsEdit,
  action,
  moduleName,
  description,
  remarkId,
  refetch,
}) => (
  <Mutation mutation={action === 'add' ? ADD_REMARK : EDIT_REMARK}>
    {
      doEditRemark => (
        <ButtonAction
          text='บันทึก'
          icon='fa fa-save'
          inverse
          onClick={() => {
            doEditRemark(true)
            const inputAdd = {
              moduleName,
              description: description.replace(' ', '&nbsp;'),
            }
            const inputEdit = {
              remarkId,
              description: description.replace(' ', '&nbsp;'),
            }
            doEditRemark({
              variables: {
                input: action === 'add' ? inputAdd : inputEdit,
              },
            }).then(({ data }) => {
              if (data.addRemark) {
                setCallMutation(false)
                setIsEdit(false)
                if (data.addRemark.success) message.success(data.addRemark.message)
                if (!data.addRemark.success) message.error(data.addRemark.message)
                setTimeout(() => {
                  refetch()
                }, 1000)
              } else if (data.editRemark) {
                setCallMutation(false)
                setIsEdit(false)
                if (data.editRemark.success) message.success(data.editRemark.message)
                if (!data.editRemark.success) message.error(data.editRemark.message)
                setTimeout(() => {
                  refetch()
                }, 1000)
              } else {
                setCallMutation(false)
                if (data.addRemark.message === 'Remark นี้มีอยู่ในระบบแล้ว') {
                  setIsValidate(false)
                  setCallMutation(false)
                }
                message.error(data.addRemark.message)
              }
            })
          }}
          disabled={callMutation}
        />
      )
    }
  </Mutation>
)


export default RecommendationSetting
