import React, { useState, useEffect } from 'react'
import { Popconfirm, message } from 'antd'
import {
  Div, Input,
} from 'konsys-ui'
import {
  DivToolbar, ButtonAction, LabelCell, DivCell,
  DivActionColumn, ButtonActionNew,
  Table, DivItemCell, DivTitle, Label,
  InputNumber,
} from './styled'
import { theme } from '../../../../../styles/_variables'

const { color } = theme


const EditableCell = ({
  editing,
  children,
  inputType,
  record,
  dataIndex,
  title,
  onChangeCell,
  alignCell = 'left',
  style,
  maxNumber,
  ...restProps
}) => {
  const [value, setValue] = useState(record ? record[dataIndex] : '')
  useEffect(() => {
    if (record && (record[dataIndex] || record[dataIndex] === 0)) {
      setValue(record[dataIndex])
    }
  }, [record])

  const inputNode = <DivItemCell>
    {inputType === 'number' ? <InputNumber
      type="number"
      min={0}
      max={maxNumber}
      value={value}
      width="100px"
      onChange={(e) => {
        // eslint-disable-next-line radix
        // eslint-disable-next-line radix
        setValue(parseInt(e))
        // eslint-disable-next-line radix
        onChangeCell(parseInt(e), dataIndex)
      }}
      onKeyPress={(e) => {
        if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
          e.preventDefault()
        }
      }}
    />
      : <Input
        type="text"
        value={value}
        width="100px"
        onChange={(e) => {
          setValue(e.target.value)
          onChangeCell(e.target.value, dataIndex)
        }}
    />
    }
  </DivItemCell>
  const cellNode = <LabelCell>{children}</LabelCell>
  return (
    <td {...restProps}>
      <DivCell alignCell={alignCell}>
        {editing ? inputNode : cellNode}
      </DivCell>
    </td>
  )
}

const PrintingTable = ({
  pagination = false,
  data = [],
  onSubmitEditRow,
  onSubmitDeleteRow,
  onAddRow,
  title,
  columns = [],
  typeTable,
}) => {
  const [rows, setRows] = useState(data)
  const [editingKey, setEditingKey] = useState('')
  const [dataRowEditing, setDataRowEditing] = useState({})

  const isEditingRow = record => record.key === editingKey
  useEffect(() => {
    setRows(data)
  }, [data])

  const renderColumn = () => {
    let tmp = [...columns]
    tmp = columns.map((column) => {
      let onCell = {
        onCell: record => ({
          record,
          dataIndex: column.dataIndex,
          alignCell: column.alignCell,
          title: column.title,
          inputType: column.inputType,
          maxNumber: column.maxNumber,
        }),
      }
      if (column.editable) {
        onCell = {
          onCell: record => ({
            record,
            dataIndex: column.dataIndex,
            alignCell: column.alignCell,
            title: column.title,
            inputType: column.inputType,
            editing: isEditingRow(record),
            width: column.width,
            maxNumber: column.maxNumber,
            onChangeCell: (value, dataIndex) => {
              setDataRowEditing({
                ...dataRowEditing,
                [dataIndex]: value,
              })
            },
          }),
        }
      }
      return {
        title: <>{column.title.split('(')[0]}<br/>{column.title.split('(')[1] && '('}{column.title.split('(')[1]}</>,
        dataIndex: column.dataIndex,
        key: column.key,
        align: 'center',
        // width: '20%',
        ...onCell,
      }
    })
    tmp.push({
      title: 'คำสั่ง',
      dataIndex: 'action',
      key: 'action',
      width: '120px',
      align: 'center',
      onCell: () => ({
        alignCell: 'center',
      }),
      render: (text, record) => {
        const editable = isEditingRow(record)
        return editable ? (
          <Div>
            <Div>
              <ButtonAction
                text='ยืนยัน'
                inverse
                icon='fa fa-floppy-o'
                onClick={() => {
                  const result = [...rows]
                  result[record.key] = { ...dataRowEditing }
                  if ('name' in result[record.key] && result[record.key].name === '') {
                    message.warning('กรุณากรอกชื่อตัวเลือกให้ครบถ้วน')
                    return
                  }
                  // eslint-disable-next-line no-restricted-globals
                  if ('colorQuantity' in result[record.key] && (typeof result[record.key].colorQuantity !== 'number' || isNaN(result[record.key].colorQuantity))) {
                    message.warning('กรุณากรอกจำนวนสีการพิมพ์เป็นตัวเลขให้ถูกต้อง')
                    return
                  }
                  // eslint-disable-next-line radix
                  if ('colorQuantity' in result[record.key] && parseInt(result[record.key].colorQuantity) < 1) {
                    message.warning('กรุณากรอกจำนวนสีการพิมพ์เป็นตัวเลขมากกว่า 0 ให้ถูกต้อง')
                    return
                  }
                  // eslint-disable-next-line no-restricted-globals
                  if ('costPrintMachine5' in result[record.key] && (typeof result[record.key].costPrintMachine5 !== 'number' || isNaN(result[record.key].costPrintMachine5))) {
                    message.warning('กรุณากรอกต้นทุนสำหรับเครื่องพิมพ์ตัด 5 เป็นตัวเลขให้ถูกต้อง')
                    return
                  }
                  // eslint-disable-next-line radix
                  if ('costPrintMachine5' in result[record.key] && parseInt(result[record.key].costPrintMachine5) < 0) {
                    message.warning('กรุณากรอกต้นทุนสำหรับเครื่องพิมพ์ตัด 5 เป็นตัวเลขมากกว่า 0 ให้ถูกต้อง')
                    return
                  }
                  // eslint-disable-next-line no-restricted-globals
                  if ('costPrintMachine3' in result[record.key] && (typeof result[record.key].costPrintMachine3 !== 'number' || isNaN(result[record.key].costPrintMachine3))) {
                    message.warning('กรุณากรอกต้นทุนสำหรับเครื่องพิมพ์ตัด 3 เป็นตัวเลขให้ถูกต้อง')
                    return
                  }
                  // eslint-disable-next-line radix
                  if ('costPrintMachine3' in result[record.key] && parseInt(result[record.key].costPrintMachine3) < 0) {
                    message.warning('กรุณากรอกต้นทุนสำหรับเครื่องพิมพ์ตัด 3 มากกว่า 0 ให้ถูกต้อง')
                    return
                  }
                  setRows(result)
                  onSubmitEditRow(record.key, dataRowEditing)
                  setDataRowEditing({})
                  setEditingKey('')
                }}
              />
            </Div>
            <Div>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => {
                  let result = JSON.parse(JSON.stringify([...rows]))
                  result.splice(record.key, 1)
                  result = result.map((item, index) => ({ ...item, key: index }))
                  setRows(result)
                  onSubmitDeleteRow(result)
                  setDataRowEditing({})
                  setEditingKey('')
                }}
              >
                <ButtonAction
                  text='ลบ'
                  icon='fa fa-trash-o'
                  color={color.error}
                />
              </Popconfirm>
            </Div>
          </Div>
        ) : (
          <ButtonAction
            disabled={editingKey !== ''}
            text='แก้ไข'
            inverse
            icon='fa fa-pencil-square-o'
            onClick={() => {
              setDataRowEditing(record)
              setEditingKey(record.key)
            }}
          />
        )
      },
    })
    return tmp
  }

  return (
    <Div>
      <DivToolbar>
        <DivTitle>
          <Label>{title}</Label>
        </DivTitle>
      </DivToolbar>
      <Div>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          pagination={pagination}
          columns={renderColumn()}
          dataSource={rows}
        />
      </Div>
      <DivToolbar>
        <DivActionColumn>
          <ButtonActionNew
            text='New'
            disabled={editingKey !== ''}
            icon='fal fa-plus'
            inverse
            onClick={() => {
              const tmpRows = [...rows]
              const keyColumns = columns.map(col => col.key)
              const newRow = {
                key: tmpRows.length,
                price: [0],
                size: [{ width: 0, height: 0 }],
                thickness: 0,
              }
              newRow[typeTable] = null
              keyColumns.forEach((item) => {
                newRow[item] = ''
              })
              tmpRows.push(newRow)
              onAddRow(tmpRows)
              setRows(tmpRows)
              setDataRowEditing(newRow)
              setEditingKey(newRow.key)
            }}
          />
        </DivActionColumn>
      </DivToolbar>
    </Div>
  )
}

export default PrintingTable
