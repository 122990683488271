/* eslint-disable radix */
import React from 'react'
import {
  Select,
} from 'antd'
import {
  Label, DivContentAmountPart,
} from './styled'


const AMOUNT_PART = [{
  value: 1,
  text: '1 ชิ้น',
}, {
  value: 2,
  text: '2 ชิ้น',
}, {
  value: 3,
  text: '3 ชิ้น',
}, {
  value: 4,
  text: '4 ชิ้น',
}, {
  value: 5,
  text: '5 ชิ้น',
}]
const AmountPart = ({
  data,
  onInputChange,
  partData,
  paperList,
  setActiveTab,
}) => (
  <DivContentAmountPart id="amount-part">
    <Label style={{ paddingRight: '24px' }}>จำนวนชิ้นส่วน</Label>
    <Select
      dropdown
      onChange={(e) => {
        const partDataValue = Array(e).fill(0).map((item, i) => {
          if (partData[i]) {
            return partData[i]
          }
          return ({
            packagePartId: null,
            name: `ชิ้นส่วนที่ ${i + 1}`,
            packageData: {
              name: `ชิ้นส่วนที่ ${i + 1}`,
            },
            measurementUnit: {
              value: 2,
              text: 'นิ้ว',
              key: 'inches',
              label: 'นิ้ว',
            },
            printSizeWidth: null,
            printSizeLength: null,
            printing: {
              selectedPrinting: 0,
              side: null,
              printingId: null,
              printingName: '',
              printingType: 1,
              printingTypeName: 'พิมพ์หน้าเดียว',
              printingBasicColorId: 0,
              printingBasicColorName: '',
              checkExtraColor: false,
              printingExtraColorId: [],
            },
            paperList,
            paper: {
              paperId: null,
              name: '',
              thickness: 0,
              selectedThickness: 0,
              coatingFrontIds: [],
              coatingBackIds: [],
            },
            coating: {
              side: 0,
              coatingType: 'ไม่เคลือบ',
              coatingFrontId: null,
              coatingFrontName: '',
              coatingBackId: null,
              coatingBackName: '',
            },
            spotUV: {
              side: 0,
              spotUVType: 'ไม่เคลือบ',
              spotUVFrontId: null,
              spotUVFrontName: '',
              spotUVBackId: null,
              spotUVBackName: '',
            },
            selectedEmbossing: 0,
            embossing: [],
            foilingId: 0,
            foilingName: '',
            foiling: [],
            selectedPlasticWindow: 0,
            plasticWindow: [],
            gluingId: null,
            gluingName: '',
            gluing: null,
            additionalServiceId: [],
            additionalService: [],
            dieCut: false,
            addtionalRows: [],
            extraServiceList: [],
          })
        })
        onInputChange({ ...data, partData: partDataValue, amountPart: e })
        setActiveTab(0)
      }}
      style={{ width: '120px', fontSize: '18px' }}
      value={data.amountPart}
    >
    {
      AMOUNT_PART.map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)
    }
    </Select>
  </DivContentAmountPart>
)

export default AmountPart
