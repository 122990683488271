import styled from 'styled-components'
import { Button } from 'konsys-ui'
import { Table as TableAnt, InputNumber as InputNumberAnt } from 'antd'
import { theme } from '../../../../../styles/_variables'


export const LabelCell = styled.p`
  font-weight: 600;
  font-size: ${theme.fonts.size.normal};
  margin-bottom: 0rem !important;
`

export const DivInput = styled.div`
  width: 100px;
`

export const DivCell = styled.div`
  display: flex;
  justify-content: left;
  ${props => props.alignCell && `
    justify-content: ${props.alignCell};
  `}
`

export const ButtonAction = styled(Button)`
  padding: 2px 6px !important;
  border-radius: 20px;
  width: 65px;
  margin: 2px;
`

export const DivRowSize = styled.div`
  display: flex;
`

export const DivItemCell = styled.div`
  margin: 4px;
`

export const Table = styled(TableAnt)`
  border: ${theme.color.primaryColor} solid 1px;
  border-radius: 5px;
  .ant-table-column-title {
    color: ${theme.color.primaryColor};
    font-size: ${theme.fonts.size.subTitle};
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    border-bottom: 0px;
  }
  .ant-table-row {
    font-size: ${theme.fonts.size.extraNormal};
    color: ${theme.color.textColor};
    vertical-align: middle;
  }
  tr:nth-child(even) {
    background-color: ${theme.color.offWhite};
  }
  .ant-table-tbody > tr:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 4px;
  }
  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 4px;
  }
`

export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`

export const DivTitle = styled.div`
  width: 100%;
`

export const DivToolbar = styled.div`
    display: flex;
`

export const InputNumber = styled(InputNumberAnt)`
  .ant-input-number-input {
    text-align: center;
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
  }
`

export default Table
