import React from 'react'
import {
  DivRadioOrderType, RadioOrderType,
  DivCenter, Label, ImgIcon, CoverImage,
} from './styled'


const GrapicTypeSelectItem = ({
  value,
  checked,
  onChecked,
  icon,
  label,
  padding,
  fullWidth,
}) => (
  <DivRadioOrderType
    checked={checked}
    onChange={e => onChecked(e.target.value)}
    value={value}
    onClick={() => onChecked(value)}
    fullWidth={fullWidth}
  >
    <DivCenter>
      <CoverImage
        forbidden={value === 0}
      >
        <ImgIcon
          src={icon}
          padding={padding}
        />
      </CoverImage>
    </DivCenter>
    <DivCenter style={{ padding: '16px 8px 4px', flex: '1 1 auto' }}>
      <Label checked={checked}>{label}</Label>
    </DivCenter>
    <DivCenter>
      <RadioOrderType
        onChange={e => onChecked(e.target.value)}
        checked={checked}
        value={value}
        id={value}
      />
    </DivCenter>
  </DivRadioOrderType>
)

export default GrapicTypeSelectItem
