import React from 'react'
import { Query } from 'react-apollo'
import {
  Div,
} from 'konsys-ui'

import { LIST_PAPER } from '../../../../constants/graphql/query'
import Transfer from '../../../../components/common/transfer'
import {
  Label,
} from './styled'


const Paper = ({
  number,
  formValues,
  setPartData,
}) => {
  const handleChange = (value) => {
    if (formValues.partData) {
      const tmpPartData = formValues.partData
      tmpPartData[number].paper = value
      setPartData({
        ...formValues,
        partData: tmpPartData,
      })
    }
  }
  return (
    <Div>
      <Label>ตัวเลือกกระดาษ</Label>
      <Query
        query={LIST_PAPER}
        fetchPolicy='network-only'
        variables={{
          limit: 10000,
          offset: 0,
        }}
        notifyOnNetworkStatusChange
        onCompleted={(data) => {
          const { paper } = data.listPaper
          let paperList = []
          if (formValues.partData && formValues.partData[number].paper.length > 0) {
            const { defaultPaper } = formValues.partData[number]
            paperList = paper.map((item) => {
              const active = [...defaultPaper].filter(itemPaperId => itemPaperId === item.paperId).length > 0
              return ({
                key: item.paperId,
                title: item.name,
                active,
              })
            })
          } else {
            paperList = paper.map(item => ({ key: item.paperId, title: item.name, active: false }))
          }

          if (formValues.partData) {
            const tmpPartData = formValues.partData
            tmpPartData[number].paper = paperList
            setPartData({
              ...formValues,
              partData: tmpPartData,
            })
          }
        }}
      >
      {
      () => (<>
        {
          formValues.partData[number].paper && <Transfer dataSource={formValues.partData[number].paper} onChange={value => handleChange(value)}/>
        }
      </>)
      }
      </Query>
    </Div>
  )
}
export default Paper
