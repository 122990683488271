import styled from 'styled-components'
import { Button } from 'konsys-ui'
import { Table as TableAnt } from 'antd'
import { theme } from '../../../../../styles/_variables'

export const DivToolbar = styled.div`
    display: flex;
`

export const LabelCell = styled.p`
  font-weight: 600;
  font-size: ${theme.fonts.size.normal};
  margin-bottom: 0rem !important;
`

export const DivInput = styled.div`
  width: 100px;
`

export const DivCell = styled.div`
  display: flex;
  justify-content: left;
  ${props => props.alignCell && `
    justify-content: ${props.alignCell};
  `}
`

export const DivPrice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ButtonAction = styled(Button)`
  padding: 2px 6px !important;
  border-radius: 20px;
  width: 65px;
  margin: 2px;
`

export const ButtonActionNew = styled(Button)`
  padding: 0px 6px !important;
  border-radius: 30px;
  height: 40px;
  min-width: 100px;
  margin-bottom: 6px;
`

export const DivActionColumn = styled.div`
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const DivRowSize = styled.div`
  display: flex;
`

export const DivItemCell = styled.div`
  margin: 4px;
`

export const ButtonActionPrice = styled(Button)`
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4px;
  padding: 10px;
  height: 20px;
  i {
    margin: 0px !important;
  }
`

export const Table = styled(TableAnt)`
  border: ${theme.color.primaryColor} solid 1px;
  border-radius: 5px;
  .ant-table-column-title {
    color: ${theme.color.primaryColor};
    font-size: ${theme.fonts.size.subTitle};
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    border-bottom: 0px;
  }
  .ant-table-row {
    font-size: ${theme.fonts.size.extraNormal};
    color: ${theme.color.textColor};
    vertical-align: middle;
  }
  tr:nth-child(even) {
    background-color: ${theme.color.offWhite};
  }
  .ant-table-tbody > tr:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 4px;
  }
  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 4px;
  }
`

export default DivToolbar
