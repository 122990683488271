/* eslint-disable radix */
import React, { useState, useEffect, useRef } from 'react'
import { Query, Mutation } from 'react-apollo'
import _ from 'lodash'
import {
  Div, LoadingIcon, H4, Button, ButtonForm,
} from 'konsys-ui'
import { message } from 'antd'
import PATH from '../../../constants/path'
import Banner from '../../../images/packaging-catalog/banner.jpg'
import { theme } from '../../../styles/_variables'
import Container from '../../../components/container'
import { GET_PACKAGE_DETAIL, PRICE_CALCULATOR } from '../../../constants/graphql/query'
import { QUOTATION_REQUEST_CREATE } from '../../../constants/graphql/mutation'
import Detail from '../../../components/package-calculator/components/detail'
import Parts from '../../../components/package-calculator/components/parts'
import Summary from '../../../components/package-calculator/components/summary'
import SummaryContent from '../../../components/package-calculator/components/summary-content'
import BreadCrumb from '../../../components/common/breadcrumb'
import { Step2 } from '../create'
import { CreateQuotation } from '../../../components/quotation-components'
import VALIDATE from '../../../constants/validate'
import * as quotationType from '../../../constants/quotation-order-type'
import {
  ActionZone,
  ButtonCalculator,
  Content,
  WidthContainer,
  ContentPart,
  QuotationCreateDiv,
  QuotationCreateDivDetail,
  SummaryDivMobile,
  IconCalculate,
  FullWidthContainer,
  ButtonBack,
  Title,
  Bar,
  ButtonFormAction,
  ActionZone2,
} from './styled'
import CalculationIcon from '../../../images/icon/CalculationIcon.png'
import SummaryToggleComponent from '../../../components/common/summary-toggle'

const OrderPredefineCreateItem = ({
  match,
}) => {
  const [step, setStep] = useState(1)
  const [formValues, setFormValues] = useState({
    showPromotion: true,
    amountProduction: 0,
    productionType: 'newOrder',
    widthHoldingTax: false,
    graphicId: 0,
    moreDetail: '',
    incrementalSteps: 0,
    minimumManufacture: 0,
    graphicFile: [],
    partData: [],
    partDetail: [],
    totalCost: [],
  })
  const [callCalculation, setCallCalculation] = useState(false)

  const [stepOneData, setStepOneData] = useState({
    boxSuitDesignService: 'notUseDesign',
    name: '',
    boxSize: {},
    quantity: 0,
    productSize: {},
    productQuantityPerPiece: 0,
    designDescription: '',
    printingDescription: '',
    sampleFiles: [],
    designServiceChoice: 'notUseDesignService',
    designFiles: [],
    graphicType: null,
  })
  const [stepTwoData, setStepTwoData] = useState({
    newShipData: false,
    taxData: {},
    shipData: {},
    detail: '',
  })
  const step2RequireField = [
    'userName',
    'userSurname',
    'email',
    'addressNumber',
    'district',
    'subDistrict',
    'province',
    'postalCode',
    'mobileNumber',
  ]

  const [callMutation, setCallMutation] = useState(false)
  const [disabledButton, setDisabledButton] = useState(true)
  const [withHoldingTax, setwithHoldingTax] = useState(false)
  const [withHoldingTaxRate, setWithHoldingTaxRate] = useState(0)
  const [products, setProducts] = useState([])
  const [productsItems, setProductsItems] = useState([])
  const [taxRate, setTaxRate] = useState(undefined)

  useEffect(() => {
    if (callMutation) {
      setDisabledButton(true)
    } else {
      const objectData = stepTwoData.newShipData ? ['taxData', 'shipData'] : ['taxData']
      for (let y = 0; y < objectData.length; y += 1) {
        const parentName = objectData[y]
        if (!_.isEmpty(stepTwoData[objectData[y]])) {
          for (let i = 0; i < step2RequireField.length; i += 1) {
            if (
              _.filter(Object.keys(stepTwoData[parentName]), d => d === step2RequireField[i]).length === 0
              || ((step2RequireField[i] !== 'postalCode' || step2RequireField[i] !== 'email') && stepTwoData[parentName][step2RequireField[i]] === '')
              || (step2RequireField[i] === 'postalCode' && (stepTwoData[parentName].postalCode === 0 || stepTwoData[parentName].postalCode.length !== 5))
              || (step2RequireField[i] === 'email' && !VALIDATE.email.test(stepTwoData[parentName].email))
              || (step2RequireField[i] === 'mobileNumber' && stepTwoData[parentName].mobileNumber.length !== 10)
              || stepOneData === ''
              // || _.filter(products, v => (v.type === quotationType.ITEM ? !_.isEmpty(v.title) && v.amount > 0 && !_.isEmpty(v.price) : !_.isEmpty(v.title) && !_.isEmpty(v.price))).length <= 0
            ) {
              if (parentName === 'shipData' && step2RequireField[i] === 'email' && !stepTwoData[parentName].email) {
                setDisabledButton(false)
                break
              }
              setDisabledButton(true)
              break
            } else {
              setDisabledButton(false)
            }
          }
        } else {
          setDisabledButton(true)
          break
        }
      }
    }
  })

  useEffect(() => {
    if (step === 2) {
      const quantity = [1, 500, 1000, 1500, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000]
      const indexMinimum = quantity.reverse().find(e => e <= parseInt(formValues.amountProduction))
      const index = quantity.reverse().indexOf(indexMinimum)
      const priceData = [...formValues.totalCost.slice(index + 1, formValues.totalCost.length)].filter(x => x).find(x => x.quantity === formValues.amountProduction)
      if (productsItems.length === 0) {
        setProducts([{
          id: 0,
          type: quotationType.ITEM,
          title: formValues.partData.length > 0 ? formValues.partData[0].packageData.name : '-',
          price: priceData ? formValues.productionType === 'newOrder' ? Math.round(priceData.totalCost * 100) / 100 : Math.round(priceData.totalRepeatCost * 100) / 100 : 0,
          amount: parseInt(formValues.amountProduction),
        }])
        setProductsItems([{
          id: 0,
          type: quotationType.ITEM,
          title: formValues.partData.length > 0 ? formValues.partData[0].packageData.name : '-',
          price: priceData ? formValues.productionType === 'newOrder' ? Math.round(priceData.totalCost * 100) / 100 : Math.round(priceData.totalRepeatCost * 100) / 100 : 0,
          amount: parseInt(formValues.amountProduction),
        }])
      } else {
        const cloneProductItems = productsItems.map(x => x)
        const foundIndex = cloneProductItems.findIndex(x => x.id === 0)
        cloneProductItems[foundIndex] = {
          id: 0,
          type: quotationType.ITEM,
          title: formValues.partData.length > 0 ? formValues.partData[0].packageData.name : '-',
          price: priceData ? formValues.productionType === 'newOrder' ? Math.round(priceData.totalCost * 100) / 100 : Math.round(priceData.totalRepeatCost * 100) / 100 : 0,
          amount: parseInt(formValues.amountProduction),
        }
        setProducts([...cloneProductItems])
        setProductsItems([...cloneProductItems])
      }
    }
  }, [formValues.totalCost, formValues.productionType])

  const [activeTab, setActiveTab] = useState(0)
  const onChangeTab = (key) => {
    setActiveTab(key)
  }

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 375)
  const [isMobileXL, setIsMobileXL] = useState(window.innerWidth <= 768)
  const [isLaptop, setIsLaptop] = useState(window.innerWidth > 1440)
  const [isLaptopL, setIsLaptopL] = useState(window.innerWidth > 1920)

  const screenSizeHandler = () => setIsMobileView(window.innerWidth <= 375)
  const screenSizeHandler2 = () => setIsMobileXL(window.innerWidth <= 768)
  const screenSizeHandler3 = () => setIsLaptop(window.innerWidth > 1440)
  const screenSizeHandler4 = () => setIsLaptopL(window.innerWidth > 1920)

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    window.addEventListener('resize', screenSizeHandler2)
    window.addEventListener('resize', screenSizeHandler3)
    window.addEventListener('resize', screenSizeHandler4)
    return () => {
      window.removeEventListener('resize', screenSizeHandler)
      window.removeEventListener('resize', screenSizeHandler2)
      window.removeEventListener('resize', screenSizeHandler3)
      window.removeEventListener('resize', screenSizeHandler4)
    }
  }, [])

  const [disabled, setDisabled] = useState(false)
  const [callCreateQoutationMutation, setCallCreateQoutationMutation] = useState(false)
  const ContentRef = useRef(null)
  const [summaryActive, setSummaryActive] = useState(false)
  return (
    <Query
      query={GET_PACKAGE_DETAIL}
      fetchPolicy='network-only'
      variables={{
        id: parseInt(match.params.id, 10),
      }}
      notifyOnNetworkStatusChange
      onCompleted={(data) => {
        if (data.packaging.success) {
          const packagingData = data.packaging.package
          if (packagingData.status === 'active') {
            const formPartData = packagingData.packageParts.map((item) => {
              const variables = Array.from(Array(item.amountVariablePart)).map((_e, i) => String.fromCharCode('a'.charCodeAt() + i))
              const sizeList = {}
              variables.forEach((v) => { sizeList[v] = null })
              return (
                {
                  packagePartId: item.packagePartId,
                  packagePartName: item.name,
                  packageData: packagingData,
                  measurementUnit: {
                    value: 1,
                    text: 'เซนติเมตร (ซม.)',
                    key: 'cm',
                    label: 'ซม.',
                  },
                  sizeList,
                  sampleImage: item.packagePartFiles,
                  printing: {
                    selectedPrinting: 0,
                    side: null,
                    printingId: null,
                    printingName: '',
                    printingType: 1,
                    printingTypeName: 'พิมพ์หน้าเดียว',
                    printingBasicColorId: 0,
                    printingBasicColorName: '',
                    checkExtraColor: false,
                    printingExtraColorId: [],
                  },
                  paperList: item.paper.sort((a, b) => a.paperId - b.paperId),
                  paper: {
                    paperId: item.paper.length > 0 && item.paper[0].paperId,
                    name: '',
                    thickness: item.paper.length > 0 && item.paper[0].paperThickness.length > 0 ? item.paper[0].paperThickness[0].thickness : [],
                    selectedThickness: item.paper.length > 0 && item.paper[0].paperThickness.length > 0 && item.paper[0].paperThickness[0].thickness,
                    coatingFrontIds: [],
                    coatingBackIds: [],
                  },
                  coating: {
                    side: 0,
                    coatingType: 'ไม่เคลือบ',
                    coatingFrontId: null,
                    coatingFrontName: '',
                    coatingBackId: null,
                    coatingBackName: '',
                  },
                  spotUV: {
                    side: 0,
                    spotUVType: 'ไม่เคลือบ',
                    spotUVFrontId: null,
                    spotUVFrontName: '',
                    spotUVBackId: null,
                    spotUVBackName: '',
                  },
                  selectedEmbossing: 0,
                  embossing: [],
                  foilingId: 0,
                  foilingName: '',
                  foiling: [],
                  selectedPlasticWindow: 0,
                  plasticWindow: [],
                  gluing: item.gluing,
                  additionalService: item.additionalService,
                  dieCut: item.isDieCut,
                  gluingId: null,
                  additionalServiceId: [],
                }
              )
            })
            setFormValues({
              ...formValues,
              partData: formPartData,
              incrementalSteps: packagingData.incrementalSteps,
              minimumManufacture: packagingData.minimumManufacture,
              amountProduction: packagingData.minimumManufacture,
            })
          } else {
            message.error('ไม่พบข้อมูลบรรจุภัณฑ์นี้ ขออภัยในความไม่สะดวก')
            setTimeout(() => {
              window.location = PATH.quotationPreDefinedCreate
            }, 1000)
          }
        } else {
          message.error(data.packaging.message)
          setTimeout(() => {
            window.location = PATH.quotationPreDefinedCreate
          }, 1000)
        }
      }}
    >
      {
        ({ loading, data }) => {
          if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
          if (!data.packaging.success) {
            return (
              <Container header={{
                type: 'title',
                img: Banner,
                title: 'ชื่อบรรจุภัณฑ์',
                color: theme.color.offWhite,
              }}>
                <WidthContainer>
                  <Div moreStyle={{ paddingTop: '148px', paddingBottom: '200px' }}>
                    <H4>ขออภัยค่ะ {data.packaging.message}</H4>
                  </Div>
                </WidthContainer>
              </Container>
            )
          }
          const packagingData = data.packaging.package
          if (packagingData.status === 'inactive') {
            return (
              <Container header={{
                type: 'title',
                img: Banner,
                title: 'ชื่อบรรจุภัณฑ์',
                color: theme.color.offWhite,
              }}>
                <WidthContainer>
                  <Div moreStyle={{ paddingTop: '148px', paddingBottom: '200px' }}>
                    <H4>ขออภัยค่ะ ไม่พบข้อมูลบรรจุภัณฑ์ดังกล่าว</H4>
                  </Div>
                </WidthContainer>
              </Container>
            )
          }
          return (
            <>
              {
                isMobileXL && <SummaryToggleComponent
                  setSummaryActive={setSummaryActive}
                  summaryActive={summaryActive}
                >
                  <SummaryDivMobile>
                    <Summary
                      formValues={formValues}
                      activeTab={activeTab}
                      onChangeTab={onChangeTab}
                      defaultPrinting={true}
                      sticky={false}
                      step={step}
                      setStep={setStep}
                      style={{ maxWidth: '100%', top: 0 }}
                      setSummaryActive={setSummaryActive}
                      isShowGluing
                    />
                  </SummaryDivMobile>
                </SummaryToggleComponent>
              }
              <BreadCrumb link={{
                parent: [
                  {
                    text: 'Order ทั้งหมด',
                    link: PATH.quotationList,
                  },
                  {
                    text: 'เลือกบรรจุภัณฑ์',
                    link: PATH.quotationPreDefinedCreate,
                  },
                ],
                current: {
                  text: `สร้างใบเสนอราคาสำหรับบรรจุภัณฑ์ ${packagingData.name}`,
                },
              }} />
              <FullWidthContainer>
                {/* Title Detail Section */}
                <div style={{ backgroundColor: '#FFFFFF', paddingTop: '2px' }}>
                  <Detail data={packagingData} isOrder={true} />
                </div>
                {/* Preview Component */}
                <Content id="content-package" ref={ContentRef} step={step} data={packagingData.packageParts && packagingData.packageParts.length}>
                  <WidthContainer>
                    <Div display={ step === 1 && 'flex' }>
                      {
                        step === 1 && formValues.partData.length > 0 && <ContentPart>
                          <Parts
                            data={packagingData}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            activeTab={activeTab}
                            onChangeTab={onChangeTab}
                            isMobileXL={isMobileXL}
                            disabled={disabled}
                            setDisabled={setDisabled}
                            ContentRef={ContentRef}
                            setSummaryActive={setSummaryActive}
                            isShowGluing={true}
                          />
                        </ContentPart>
                      }
                      {
                        step === 2 && <Div>
                          <Bar>
                            <Div>
                              <ButtonBack
                                inverse
                                text='<< ย้อนกลับ'
                                onClick={() => setStep(1)}
                              />
                            </Div>
                            <Title>ข้อมูลโปรโมชัน และราคา</Title>
                            <Div>
                              {
                                !isMobileXL && <ButtonBack
                                  inverse
                                  text='<< ย้อนกลับ'
                                  style={{ opacity: 0, cursor: 'unset' }}
                                />
                              }
                            </Div>
                          </Bar>
                          <Div display='flex' alignItems='center' justifyContent='flex-start'>
                            <Div flexBasis={isMobileXL ? '100%' : isLaptopL ? '33%' : isLaptop ? '44%' : '60%'}>
                              <SummaryContent
                                setFormValues={setFormValues}
                                formValues={formValues}
                                setStep={setStep}
                                isShowMore={true}
                                setDisabledButton={setDisabledButton}
                              />
                            </Div>
                            {
                              !isMobileXL && <Div flexBasis={ isLaptopL ? '67%' : isLaptop ? '56%' : '40%' }>
                                <Summary
                                  formValues={formValues}
                                  activeTab={activeTab}
                                  onChangeTab={onChangeTab}
                                  sticky
                                  step={step}
                                  setStep={setStep}
                                  ContentRef={ContentRef}
                                  setSummaryActive={setSummaryActive}
                                  isShowGluing
                                />
                              </Div>
                            }
                          </Div>
                        </Div>
                      }
                    </Div>
                  </WidthContainer>
                </Content>
                <ActionZone>
                  {
                    step === 1 && <Query
                      query={PRICE_CALCULATOR}
                    >
                      {
                        ({ refetch }) => (
                          <ButtonCalculator
                            inverse
                            buttonStyle='round'
                            loading={callCalculation}
                            disabled={callCalculation || disabled}
                            text={<React.Fragment><IconCalculate src={CalculationIcon} />คำนวณราคา</React.Fragment>}
                            onClick={async () => {
                              setCallCalculation(true)
                              const findNullSizePart = formValues.partData.map(item => Object.values(item.sizeList).filter(v => v === null || v === '' || v === undefined))
                              const findNullSize = Array.prototype.concat.apply([], findNullSizePart).filter(v => v === null || v === '' || v === undefined || v === 0)
                              const findEmptyEmbossing = formValues.partData.filter(item => (item.selectedEmbossing === 1 && item.embossing.length === 0) || (item.embossing.length > 0 && item.embossing.filter(x => x.width === null || x.lengths === null).length > 0))
                              const findEmptyFoiling = formValues.partData.filter(item => (item.foilingId > 0 && item.foiling.length === 0) || (item.foiling.length > 0 && item.foiling.filter(x => x.width === null || x.lengths === null).length > 0))
                              const findEmptyPlastic = formValues.partData.filter(item => (item.selectedPlasticWindow === 1 && item.plasticWindow.length === 0) || (item.plasticWindow.length > 0 && item.plasticWindow.filter(x => x.width === null || x.lengths === null).length > 0))
                              if (findNullSize.length > 0) {
                                message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                                setCallCalculation(false)
                                return
                              }
                              if (findNullSize.length > 0 || findEmptyEmbossing.length > 0 || findEmptyFoiling.length > 0 || findEmptyPlastic.length > 0) {
                                message.warning(`กรุณากรอกข้อมูล ${findNullSize.length > 0 ? 'ขนาดบรรจุภัณฑ์' : ''} ${findEmptyEmbossing.length > 0 ? 'ตำแหน่งการปั๊มนูน' : ''} ${findEmptyFoiling.length > 0 ? 'ตำแหน่งปั๊มฟอยล์-ทองเค' : ''} ${findEmptyPlastic.length > 0 ? 'ตำแหน่งการเจาะหน้าต่างและติดแผ่นใส' : ''} ให้ครบถ้วน`)
                                setCallCalculation(false)
                                return
                              }
                              const part = formValues.partData.map((item, index) => ({
                                order: index + 1,
                                packagePartId: item.packagePartId,
                                unit: item.measurementUnit.key,
                                operator: Object.values(item.sizeList).map(x => (parseFloat(x))),
                                printSizeWidth: 0.0,
                                printSizeLength: 0.0,
                                paper: {
                                  paperId: item.paper.paperId,
                                  thickness: parseFloat(item.paper.selectedThickness),
                                },
                                printing: item.printing.side && {
                                  side: item.printing.printingType,
                                  printingBasicColorId: item.printing.printingBasicColorId,
                                  printingExtraColorId: item.printing.printingExtraColorId.filter(x => x.printingExtraColorId).map(x => x.printingExtraColorId),
                                },
                                coating: {
                                  coatingIdFront: item.coating.side === 1 || item.coating.side === 2 ? item.coating.coatingFrontId : null,
                                  coatingIdBehind: item.coating.side === -1 || item.coating.side === 2 ? item.coating.coatingBackId : null,
                                },
                                spotUV: {
                                  spotUVIdFront: item.spotUV.spotUVFrontId,
                                  spotUVIdBehind: item.spotUV.spotUVBackId,
                                },
                                embossing: item.embossing.map((x, i) => ({
                                  order: i + 1,
                                  width: parseFloat(x.width),
                                  length: parseFloat(x.lengths),
                                  unit: item.measurementUnit.key,
                                })),
                                foiling: {
                                  foilingId: item.foilingId !== 0 ? item.foilingId : null,
                                  foilingPart: item.foiling.map((x, i) => ({
                                    order: i + 1,
                                    width: parseFloat(x.width),
                                    length: parseFloat(x.lengths),
                                    unit: item.measurementUnit.key,
                                  })),
                                },
                                dieCut: item.dieCut,
                                plasticWindow: item.plasticWindow.map((x, i) => ({
                                  order: i + 1,
                                  width: parseFloat(x.width),
                                  length: parseFloat(x.lengths),
                                  thickness: x.thickness,
                                  unit: item.measurementUnit.key,
                                })),
                                gluingId: item.gluing ? item.gluing.gluingId : null,
                                additionalServiceId: item.additionalService.map(x => x.additionalServiceId) || [],
                              }))
                              const input = {
                                part,
                              }
                              await refetch({
                                input,
                              }).then(async (res) => {
                                if (res.data.priceCalculator.success) {
                                  // const quantity = [1, 500, 1000, 1500, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000]
                                  // const indexMinimum = quantity.reverse().find(e => e <= formValues.amountProduction)
                                  // const index = quantity.reverse().indexOf(indexMinimum)
                                  // const priceData = res.data.priceCalculator.totalCost.find(x => x.quantity === quantity[index])
                                  // setProductsItems([{
                                  //   id: 0,
                                  //   type: quotationType.ITEM,
                                  //   title: '',
                                  //   price: '',
                                  //   amount: '',
                                  // },
                                  // {
                                  //   id: -1,
                                  //   type: quotationType.ITEM,
                                  //   title: formValues.partData.length > 0 ? formValues.partData[0].packageData.name : '-',
                                  //   price: formValues.productionType === 'newOrder' ? Math.round(priceData.totalCost * 100) / 100 : Math.round(priceData.totalRepeatCost * 100) / 100,
                                  //   amount: parseInt(formValues.amountProduction),
                                  // },
                                  // ])
                                  setFormValues({
                                    ...formValues,
                                    partData: formValues.partData,
                                    partDetail: res.data.priceCalculator.partDetail,
                                    totalCost: res.data.priceCalculator.totalCost,
                                    costDetail: res.data.priceCalculator.costDetail,
                                  })
                                  const element = document.getElementById('content-package')
                                  if (element) {
                                    setTimeout(async () => {
                                      setCallCalculation(false)
                                      await setStep(2)
                                      await element.scrollIntoView({
                                        behavior: 'smooth',
                                      })
                                    }, 500)
                                  }
                                } else if (res.data.priceCalculator.message === 'oversize') {
                                  setCallCalculation(false)
                                  message.warning('ไม่สามารถให้บริการผลิตขนาดดังกล่าวได้ เนื่องจากมีขนาดเกินเครื่องพิมพ์ ของทางเรา ขออภัยในความไม่สะดวก')
                                } else if (res.data.priceCalculator.message === 'ขออภัย ขนาดที่ระบุ ไม่สามารถผลิตบนกระดาษชนิดนี้ได้') {
                                  setCallCalculation(false)
                                  message.warning('ขออภัย ขนาดที่ระบุ ไม่สามารถผลิตบนกระดาษชนิดนี้ได้')
                                } else if (res.data.priceCalculator.message === 'ไม่สามารถคำนวณ Width ได้' || res.data.priceCalculator.message === 'ไม่สามารถคำนวณ Length ได้') {
                                  setCallCalculation(false)
                                  message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                                } else {
                                  setCallCalculation(false)
                                  message.success('สามารถให้บริการผลิตขนาดดังกล่าวได้')
                                }
                              }).catch((e) => {
                                if (e.toString().includes('status code 400')) {
                                  setCallCalculation(false)
                                  message.warning('กรุณากรอกข้อมูลให้ครบถ้วน')
                                } else {
                                  setCallCalculation(false)
                                  message.error('ไม่สามารถคำนวนราคาได้')
                                  setStep(1)
                                }
                              })
                            }}
                          />
                        )
                      }
                    </Query>
                  }
                  {
                    step === 2 && <QuotationCreateDiv>
                        <QuotationCreateDivDetail>
                          <Step2
                            data={stepTwoData}
                            onChangeData={(value, name, parentName) => {
                              const newData = Object.assign({}, stepTwoData)
                              if (parentName) {
                                newData[parentName][name] = value
                              } else {
                                newData[name] = value
                              }
                              if (parentName === 'taxData' && name === 'companyName' && (value === '' || value === null)) {
                                newData.taxData.branch = ''
                              }
                              if (parentName === 'shipData' && name === 'companyName' && (value === '' || value === null)) {
                                newData.shipData.branch = ''
                              }
                              setStepTwoData(newData)
                            }}
                            withHoldingTax={withHoldingTax}
                            setWithHoldingTax={setwithHoldingTax}
                            setWithHoldingTaxRate={setWithHoldingTaxRate}
                          />
                          <CreateQuotation
                            key='quotationCreate'
                            hideButton
                            getData={(productsValue, taxRateValue) => {
                              setProducts(productsValue)
                              setTaxRate(taxRateValue)
                            }}
                            withHoldingTax={withHoldingTax}
                            isPreDefine={true}
                            onChangeWithHoldingTax={setwithHoldingTax}
                            productItems={productsItems}
                          />
                        </QuotationCreateDivDetail>
                        <Div display='flex' justifyContent='space-between'>
                          <ButtonFormAction
                            text='ย้อนกลับ'
                            onClick={async () => {
                              const element = document.getElementById('content-package')
                              if (element) {
                                await setStep(1)
                                await element.scrollIntoView({
                                  behavior: 'smooth',
                                })
                              }
                            }}
                            inverse
                            color={theme.color.secondaryColor}
                            buttonStyle="round"
                            activeGreen={step === 3}
                            icon={'far fa-chevron-double-left'}
                            style={{ marginLeft: '24px' }}
                          />
                          <Mutation
                            mutation={QUOTATION_REQUEST_CREATE}
                          >
                            {
                              doCreateQuotationRequest => (
                                <ButtonFormAction
                                  style={{ marginRight: '24px' }}
                                  inverse
                                  color={theme.color.tertiaryColo}
                                  buttonStyle="round"
                                  rightIcon
                                  activeGreen={step === 3}
                                  icon='fal fa-plus'
                                  text='สร้าง Quotation และส่งอีเมลแจ้งลูกค้า'
                                  loading={callMutation}
                                  disabled={callCreateQoutationMutation || disabledButton}
                                  onClick={() => {
                                    setCallCreateQoutationMutation(true)
                                    const input = {}
                                    Object.keys(stepOneData).forEach((v) => {
                                      if (v !== 'boxSuitDesignService') {
                                        if (v === 'boxSize' || v === 'productSize') {
                                          if (!_.isEmpty(stepOneData[v])) {
                                            input[v === 'boxSize' ? 'width' : 'productWidth'] = parseFloat(stepOneData[v].width, 10)
                                            input[v === 'boxSize' ? 'length' : 'productLength'] = parseFloat(stepOneData[v].length, 10)
                                            input[v === 'boxSize' ? 'height' : 'productHeight'] = parseFloat(stepOneData[v].height, 10)
                                          }
                                        } else if (v === 'designServiceChoice') {
                                          input.useCompleteDesign = stepOneData[v] === 'useDesignService'
                                        } else if ((typeof stepOneData[v] === 'number' && stepOneData[v] > 0) || !_.isEmpty(stepOneData[v])) {
                                          input[v] = stepOneData[v]
                                        }
                                      }
                                    })
                                    input.produceQuantity = parseInt(formValues.amountProduction) || 0
                                    input.name = formValues.partData.length > 0 ? formValues.partData[0].packageData.name : '-'
                                    const filterProduct = products
                                    // _.filter(products, v => (v.type === quotationType.ITEM
                                    //   ? !_.isEmpty(v.title) && v.amount > 0 && v.price
                                    //   : !_.isEmpty(v.title) && !_.isEmpty(v.price)))
                                    input.items = !_.isEmpty(filterProduct) && filterProduct.map(v => (
                                      {
                                        itemType: v.type,
                                        itemName: v.title,
                                        price: v.price ? parseFloat(v.price, 10) : null,
                                        quantity: v.amount ? parseInt(v.amount, 10) : null,
                                      }
                                    ))
                                    if (filterProduct.length === 0) {
                                      message.warning('กรุณากรอกข้อมูลรายการสินค้าและบริการ')
                                      setCallCreateQoutationMutation(false)
                                      return
                                    }
                                    input.productWidth = 0
                                    input.productLength = 0
                                    input.productHeight = 0
                                    input.isPreDefine = true
                                    input.partDetail = formValues.partDetail.map(item => _.omit(item, ['__typename'])).map((item, index) => ({
                                      ...item,
                                      packagePartId: formValues.partData[index].packagePartId,
                                      unitSize: formValues.partData[index].measurementUnit.key,
                                      sizeA: formValues.partData[index].sizeList.a,
                                      sizeB: formValues.partData[index].sizeList.b,
                                      sizeC: formValues.partData[index].sizeList.c,
                                      sizeD: formValues.partData[index].sizeList.d,
                                      sizeE: formValues.partData[index].sizeList.e,
                                      sizeF: formValues.partData[index].sizeList.f,
                                      sizeG: formValues.partData[index].sizeList.g,
                                      sizeH: formValues.partData[index].sizeList.h,
                                      sizeI: formValues.partData[index].sizeList.i,
                                      sizeJ: formValues.partData[index].sizeList.j,
                                      detailActualPrinter: {
                                        ..._.omit(item.detailActualPrinter, ['__typename', 'maxYield']),
                                        paperId: formValues.partData[index].paper && formValues.partData[index].paper.paperId ? formValues.partData[index].paper.paperId : null,
                                      },
                                      detailPrintingCost: item.detailPrintingCost && {
                                        ..._.omit(item.detailPrintingCost, ['__typename']),
                                        printingBasicColorId: formValues.partData[index].printing && formValues.partData[index].printing.printingBasicColorId ? formValues.partData[index].printing.printingBasicColorId : null,
                                        printingExtraColor: formValues.partData[index].printing && formValues.partData[index].printing.printingExtraColorId.length > 0 ? formValues.partData[index].printing.printingExtraColorId.map(x => ({ id: x.printingExtraColorId, name: x.printingExtraColorName })) : [],
                                      },
                                      detailCoatingCost: {
                                        ..._.omit(item.detailCoatingCost, ['__typename']),
                                        coatingFrontId: formValues.partData[index].coating && formValues.partData[index].coating.coatingFrontId ? formValues.partData[index].coating.coatingFrontId : null,
                                        coatingBehindId: formValues.partData[index].coating && formValues.partData[index].coating.coatingBackId ? formValues.partData[index].coating.coatingBackId : null,
                                      },
                                      detailSpotUVCost: {
                                        ..._.omit(item.detailSpotUVCost, ['__typename']),
                                        spotUVFrontId: formValues.partData[index].spotUV && formValues.partData[index].spotUV.spotUVFrontId ? formValues.partData[index].spotUV.spotUVFrontId : null,
                                        spotUVBehindId: formValues.partData[index].spotUV && formValues.partData[index].spotUV.spotUVBackId ? formValues.partData[index].spotUV.spotUVBackId : null,
                                      },
                                      detailFoilingCost: item.detailFoilingCost ? {
                                        foiling: item.detailFoilingCost.foiling,
                                        foilingPart: item.detailFoilingCost.foilingPart.map(v => _.omit(v, ['__typename'])),
                                        foilingId: formValues.partData[index].foilingId ? formValues.partData[index].foilingId : null,
                                      } : null,
                                      detailGluingCost: item.detailGluingCost && {
                                        gluingId: formValues.partData[index].gluing ? formValues.partData[index].gluing.gluingId : null,
                                        gluing: formValues.partData[index].gluing ? formValues.partData[index].gluing.name : null,
                                      },
                                      detailPrinter: _.omit(item.detailPrinter, ['__typename']),
                                      detailSize: _.omit(item.detailSize, ['__typename']),
                                      detailEmbossingCost: item.detailEmbossingCost.map(v => _.omit(v, ['__typename'])),
                                      detailPlasticWindowCost: item.detailPlasticWindowCost.map(v => _.omit(v, ['__typename'])),
                                      detailAdditionalServiceCost: formValues.partData[index].additionalService.length > 0 ? formValues.partData[index].additionalService.map(x => ({ additionalServiceId: x.additionalServiceId, name: x.name })) : [],
                                    }))
                                    input.totalCost = formValues.totalCost.map(item => _.omit(item, ['__typename']))
                                    const quantity = [1, 500, 1000, 1500, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, parseInt(formValues.amountProduction)]
                                    const indexMinimum = quantity.reverse().find(e => e <= parseInt(formValues.amountProduction))
                                    const index = quantity.reverse().indexOf(indexMinimum)
                                    const priceData = formValues.totalCost.find(x => x.quantity === quantity[index])
                                    input.price = formValues.productionType === 'newOrder' ? Math.round(priceData.totalCost * 100) / 100 : Math.round(priceData.totalRepeatCost * 100) / 100
                                    input.sampleFiles = formValues.graphicFile
                                    input.isSameAddress = !stepTwoData.newShipData
                                    input.addressDescription = stepTwoData.detail
                                    input.printingDescription = formValues.moreDetail || null
                                    input.withHoldingTax = stepTwoData.taxData.withHoldingTax
                                    input.taxRate = taxRate || 0
                                    input.withHoldingTaxRate = stepTwoData.taxData.withHoldingTax ? withHoldingTaxRate : 0
                                    const stepTwoDataList = input.isSameAddress ? ['taxData'] : ['taxData', 'shipData']
                                    stepTwoDataList.forEach((d) => {
                                      Object.keys(stepTwoData[d]).forEach((v) => {
                                        let inputName = v
                                        if (v !== 'addressId' && v !== 'description' && v !== '__typename' && v !== 'withHoldingTax') {
                                          if (d === 'shipData') {
                                            inputName = `shipping${v.charAt(0).toUpperCase()}${v.slice(1)}`
                                          }
                                          input[inputName] = stepTwoData[d][v]
                                        }
                                      })
                                    })
                                    input.isGraphic = formValues.graphicId ? formValues.graphicId.name ? formValues.graphicId.name : 'ไม่ใช้บริการ' : 'ไม่ใช้บริการ'
                                    input.produceType = formValues.productionType === 'newOrder' ? 'สั่งผลิตใหม่' : 'สั่งผลิตซ้ำ'
                                    input.packageId = parseInt(match.params.id, 10)
                                    input.totalCost = formValues.totalCost.map(item => ({
                                      quantity: item.quantity,
                                      totalCost: item.totalCost,
                                      totalRepeatCost: item.totalRepeatCost,
                                    }))
                                    delete input.graphicType
                                    delete <input type="text" className="lineId" />
                                    doCreateQuotationRequest({
                                      variables: {
                                        input,
                                        useBoxSuitDesign: true,
                                      },
                                    }).then((resp) => {
                                      if (!resp.loading && !_.isUndefined(resp.data)) {
                                        if (resp.data.createQuotationRequest.success) {
                                          message.success(resp.data.createQuotationRequest.message)
                                          setTimeout(() => {
                                            setCallCreateQoutationMutation(false)
                                            window.location = PATH.quotationList
                                          }, 3000)
                                        } else {
                                          setCallCreateQoutationMutation(false)
                                          message.error(resp.data.createQuotationRequest.message)
                                        }
                                      }
                                    })
                                  }
                                  }
                                />
                              )
                            }
                          </Mutation>
                        </Div>
                    </QuotationCreateDiv>
                  }
                </ActionZone>
              </FullWidthContainer>
            </>
          )
        }
      }
    </Query>
  )
}

export default OrderPredefineCreateItem
