import styled, { css } from 'styled-components'
import { theme, media } from '../../../../../styles/_variables'

export const Title = styled.div`
text-align: center;
font-size: 22px;
font-weight: ${theme.fonts.style.semiBold};
color: ${theme.color.offWhite};
background: ${props => (props.black ? theme.color.textColor : theme.color.greenPrimary)}
width: 100%;
padding: 0px;
border-radius: ${props => (props.black ? '3px' : '5px')}
margin-bottom: 10px;
`

export const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => (props.gridColumn)}, minmax(0, 1fr));
  // grid-auto-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: ${props => (props.grahic ? '6px' : '0px')};
  padding: 2px;
`

export const ScrollWrapper = styled.div`
${props => (props.disabledScrollX ? 'overflow-x: hidden;' : '')}
overflow-y: auto;
width: 100%
height: fit-content;
height: -moz-fit-content;
`

export const Container = styled.div`
display: flex;
flex-direction: column;
width: 100%;
min-height: max-content;
background: white;
`

export const NoOptionContainer = styled.div`
display: flex;
justify-content: center;
font-size: ${theme.fonts.size.normal};
font-weight: ${theme.fonts.style.semiBold};
height: 100px;
align-items: center;
`

export default Title
