import styled, { css } from 'styled-components'
import { theme, media } from '../../../styles/_variables'

export const Title = styled.div`
  background-color: ${theme.color.primaryColor};
  padding: 8px 16px;
`

export const SummaryBoxWrapper = styled.div`
  margin: ${props => (props.margin ? props.margin : '24px 0 0')};
`

export const TextRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  ${props => props.margin && css`
    margin: ${props.margin};
  `}
      
  ${props => (props.type === 'detail' || props.type === 'detail-technique'
    ? css`
      > div {
        margin: 0;
      }
    `
    : css`
      > div {
        margin: 0 16px;
      }
    `
  )}
`

export const Link = styled.a`
  color: ${theme.color.primaryColor};
  > p {
    color: ${theme.color.primaryColor};
  }
  &:hover {
    > p {
      color: ${theme.color.primaryColor};
    }
  }
`

export const CheckboxLabel = styled.span`
  font-weight: ${theme.fonts.style.medium};
  font-size: ${theme.fonts.size.normal};
`


export default Title
