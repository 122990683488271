import React, { useState, useEffect } from 'react'
import { Input } from 'antd'
import {
  SimpleTable,
  ButtonAction,
  DivCell,
  LabelCell,
  InputNumber,
  LabelColumn,
} from './styled'


const EditableCell = ({
  editing,
  children,
  inputType,
  record,
  dataIndex,
  title,
  onChangeCell,
  style,
  alignCell = 'left',
  ...restProps
}) => {
  const [value, setValue] = useState(record ? record[dataIndex] : '')

  useEffect(() => {
    if (record && record[dataIndex]) {
      setValue(record[dataIndex])
    }
  }, [record])

  const inputNode = inputType === 'number'
    ? <InputNumber
        type="number"
        min={0}
        max={9999}
        value={value}
        onChange={(e) => {
          if ((e !== '' || parseFloat(e, 10) >= 0)) {
            setValue(e)
            onChangeCell(e, dataIndex)
          }
        }}
        onKeyPress={(e) => {
          if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
            e.preventDefault()
          }
        }}
      /> : <Input
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          onChangeCell(e.target.value, dataIndex)
        }}
      />
  return <td {...restProps}>
      <DivCell alignCell={alignCell}>
        <LabelCell>{editing ? inputNode : children}</LabelCell>
      </DivCell>
    </td>
}

const TableFixedColumns = ({
  loading,
  columns,
  formValues,
  setFormValues,
  dataIndexFix,
}) => {
  const [cols, setCols] = useState([])
  const [editingKey, setEditingKey] = useState('')
  const [dataRowEditing, setDataRowEditing] = useState({})

  useEffect(() => {
    if (columns.length > 0) {
      setCols(columns)
    }
  }, [columns])

  const isEditing = record => record.key === editingKey

  const edit = (record) => {
    setDataRowEditing(record)
    setEditingKey(record.key)
  }

  const save = async (key) => {
    const newData = [...formValues.rowData]
    const index = newData.findIndex(item => key === item.key)
    if (index > -1) {
      const tmpDataRowEditing = dataRowEditing
      if (tmpDataRowEditing[dataIndexFix] === null) tmpDataRowEditing[dataIndexFix] = 0
      newData.splice(index, 1, { ...tmpDataRowEditing })
      setFormValues({ ...formValues, rowData: newData })
    } else {
      setFormValues({ ...formValues, rowData: newData })
    }
    setDataRowEditing({})
    setEditingKey('')
  }

  const actionColumn = {
    title: 'คำสั่ง',
    dataIndex: 'action',
    align: 'center',
    width: '160px',
    alignCell: 'center',
    render: (_, record) => {
      const editable = isEditing(record)
      return editable ? (
        <span>
          <ButtonAction
            onClick={() => save(record.key)}
            style={{
              marginRight: 8,
            }}
            text='บันทึก'
            inverse
            icon='fa fa-floppy-o'
          />
        </span>
      ) : (
        <ButtonAction
          disabled={editingKey !== ''}
          onClick={() => edit(record)}
          text='แก้ไข'
          inverse
          icon='fa fa-pencil-square-o'
        />
      )
    },
  }
  const mergedColumns = [...cols, actionColumn].map((col) => {
    if (!col.editable) {
      return {
        ...col,
        title: <LabelColumn>{col.title}</LabelColumn>,
        onCell: record => ({
          record,
          alignCell: col.alignCell,
          ...col,
        }),
      }
    }

    return {
      ...col,
      title: <LabelColumn>{col.title}</LabelColumn>,
      onCell: record => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        alignCell: col.alignCell,
        onChangeCell: (e, dataIndex) => {
          setDataRowEditing({
            ...dataRowEditing,
            [dataIndex]: e,
          })
        },
        ...col,
      }),
    }
  })

  return (
    <SimpleTable
      components={{
        body: {
          cell: EditableCell,
        },
      }}
      loading={loading}
      columns={mergedColumns}
      dataSource={formValues.rowData}
      pagination={false}
    />
  )
}

export default TableFixedColumns
