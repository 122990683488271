
import styled, { css } from 'styled-components'
import {
  Button,
  Div,
  H4,
  ButtonForm,
} from 'konsys-ui'
import { theme, media, minMedia } from '../../../styles/_variables'


export const ActionZone = styled.div`
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    ${media.tablet`
      justify-content: center;
      width: 100%;
      height: 120px;
      margin: 80px 0px 40px 0px;
    `}
`

export const ActionZone2 = styled.div`
    width: 70%;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0px 40px 0px;
    ${media.laptop`
      justify-content: space-between;
      width: 100%;
    `}
    ${media.tablet`
      justify-content: space-between;
      width: 100%;
    `}
`

export const ButtonCalculator = styled(Button)`
  width: 300px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: ${theme.fonts.size.title};
  border-radius: 25px;
  padding: 2px 8px !important;
  background-color: ${theme.color.greenPrimary};
  box-shadow: 2px 2px 0px 1px rgb(110, 201, 190, 0.4) !important;
  & > p {
    margin: 0px !important;
    font-size: ${theme.fonts.size.title};
  }
`

export const IconCalculate = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`

export const WidthContainer = styled.div`
  width: 100%;
  margin-left: 50%;
  max-width: 64vw;
  transform: translateX(-50%);
  margin-top: -60px;
  margin-bottom: -60px;
  ${props => props.bgColor && css`
    background-color: ${props.bgColor};
  `}
  ${minMedia.laptopL`
    max-width: 59vw;
  `}
  ${minMedia.desktop`
    max-width: 38vw;
  `}
  ${media.laptopL`
    max-width: 100%;
    padding: 0px 60px;
  `}
  ${media.tablet`
    width: 100%;
    max-width: 100%;
  `}
`

export const FullWidthContainer = styled.div`
  // width: fit-content;
  margin: 0 auto;
  margin-left: -60px;
  margin-right: -60px;
`

export const Content = styled.div`
  width: 100%;
  border-top: 60px solid ${theme.color.greenPrimary};
  border-bottom: ${props => (props.step === 2 || props.data === 1 ? '0px' : `60px solid ${theme.color.greenPrimary}`)};
`

export const SummaryDiv = styled.div`
  width: 100%;
  margin-left: 12px;
  position: relative;
`

export const SummaryDivMobile = styled.div`
  width: 100%;
  padding: 8px 24px;
  position: relative;
  margin-top: 30px;
`

export const ContentPart = styled.div`
  display: inline-flex;
`

export const Bar = styled(Div)`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled(H4)`
  color: ${theme.color.offWhite};
  ${media.mobileL`
    margin-left: -60px;
    font-size: ${theme.fonts.size.extraNormal};
  `}
  ${media.mobileM`
    margin-left: -60px;
    font-size: ${theme.fonts.size.normal};
  `}
`

export const ButtonBack = styled(Button)`
  padding: 8px 0px;
  background-color: ${theme.color.greenPrimary};
  border-color: ${theme.color.greenPrimary};
  ${media.mobileL`
    margin-left: 0px;
  `}
  > p {
    ${media.mobileM`
      font-size: ${theme.fonts.size.label};
    `}
  }
`

export const ButtonFormAction = styled(ButtonForm)`
  margin: 0px;
  padding: 8px 24px;
  white-space: nowrap;
  align-items: center;
  display: flex;
  & > span {
    font-size: ${theme.fonts.size.subTitle} !important;
    ${media.mobileL`
      font-size: ${theme.fonts.size.small} !important;
    `}
  }
  & > i {
    font-size: ${theme.fonts.size.subTitle} !important;
    width: 30px !important;
    height: 30px !important;
    ${media.mobileL`
      width: 12px !important;
      height: 12px !important;
      font-size: 12px !important;
      margin: 0px 4px 0px 0px !important;
    `}
  }
  ${media.mobileL`
    margin-left: 0px;
    padding: 8px 12px;
  `}
  
`

export default ActionZone
