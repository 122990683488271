import React, { useState, useEffect, useRef } from 'react'
import { Query } from 'react-apollo'
import {
  Div, LoadingIcon, H4,
} from 'konsys-ui'
import { message } from 'antd'
import PATH from '../../../constants/path'
import Banner from '../../../images/packaging-catalog/banner.jpg'
import { theme } from '../../../styles/_variables'
import Container from '../../../components/container'
import { GET_PACKAGE_DETAIL, PRICE_CALCULATOR } from '../../../constants/graphql/query'
import Detail from '../../../components/package-calculator/components/detail'
import Parts from '../../../components/package-calculator/components/parts'
import Summary from '../../../components/package-calculator/components/summary'
import SummaryContent from '../../../components/package-calculator/components/summary-content'
import {
  ActionZone,
  ButtonCalculator,
  Content,
  WidthContainer,
  IconCalculate,
  ContentPart,
  SummaryDivMobile,
  FullWidthContainer,
  ButtonBack,
  Title,
  Bar,
  ButtonFormAction,
  ActionZone2,
} from './styled'
import CalculationIcon from '../../../images/icon/CalculationIcon.png'
import SummaryToggleComponent from '../../../components/common/summary-toggle'


const PackagingDetail = ({
  match,
}) => {
  const [step, setStep] = useState(1)
  const [formValues, setFormValues] = useState({
    showPromotion: true,
    amountProduction: 0,
    productionType: 'newOrder',
    widthHoldingTax: false,
    graphicId: 0,
    moreDetail: '',
    incrementalSteps: 0,
    minimumManufacture: 0,
    graphicFile: [],
    partData: [],
    partDetail: [],
    totalCost: [],
  })
  const [activeTab, setActiveTab] = useState(0)
  const [callCalculation, setCallCalculation] = useState(false)

  const onChangeTab = (key) => {
    setActiveTab(key)
  }

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 375)
  const [isMobileXL, setIsMobileXL] = useState(window.innerWidth <= 820)

  const screenSizeHandler = () => setIsMobileView(window.innerWidth <= 375)
  const screenSizeHandler2 = () => setIsMobileXL(window.innerWidth <= 820)

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    window.addEventListener('resize', screenSizeHandler2)
    return () => {
      window.removeEventListener('resize', screenSizeHandler)
      window.removeEventListener('resize', screenSizeHandler2)
    }
  }, [])

  const [disabled, setDisabled] = useState(false)
  const ContentRef = useRef(null)
  const [summaryActive, setSummaryActive] = useState(false)
  return (
    <Query
      query={GET_PACKAGE_DETAIL}
      fetchPolicy='network-only'
      variables={{
        id: parseInt(match.params.id, 10),
      }}
      notifyOnNetworkStatusChange
      onCompleted={(data) => {
        if (data.packaging.success) {
          const packagingData = data.packaging.package
          const formPartData = packagingData.packageParts.map((item) => {
            const variables = Array.from(Array(item.amountVariablePart)).map((_e, i) => String.fromCharCode('a'.charCodeAt() + i))
            const sizeList = {}
            variables.forEach((v) => { sizeList[v] = null })
            return (
              {
                packagePartId: item.packagePartId,
                packagePartName: item.name,
                packageData: packagingData,
                measurementUnit: {
                  value: 1,
                  text: 'เซนติเมตร (ซม.)',
                  key: 'cm',
                  label: 'ซม.',
                },
                sizeList,
                sampleImage: item.packagePartFiles,
                printing: {
                  selectedPrinting: 0,
                  side: null,
                  printingId: null,
                  printingName: '',
                  printingType: 1,
                  printingTypeName: 'พิมพ์หน้าเดียว',
                  printingBasicColorId: 0,
                  printingBasicColorName: '',
                  checkExtraColor: false,
                  printingExtraColorId: [],
                },
                paperList: item.paper.sort((a, b) => a.paperId - b.paperId),
                paper: {
                  paperId: item.paper.length > 0 && item.paper[0].paperId,
                  name: '',
                  thickness: item.paper.length > 0 && item.paper[0].paperThickness.length > 0 ? item.paper[0].paperThickness[0].thickness : [],
                  selectedThickness: item.paper.length > 0 && item.paper[0].paperThickness.length > 0 && item.paper[0].paperThickness[0].thickness,
                  coatingFrontIds: [],
                  coatingBackIds: [],
                },
                coating: {
                  side: 0,
                  coatingType: 'ไม่เคลือบ',
                  coatingFrontId: null,
                  coatingFrontName: '',
                  coatingBackId: null,
                  coatingBackName: '',
                },
                spotUV: {
                  side: 0,
                  spotUVType: 'ไม่เคลือบ',
                  spotUVFrontId: null,
                  spotUVFrontName: '',
                  spotUVBackId: null,
                  spotUVBackName: '',
                },
                selectedEmbossing: 0,
                embossing: [],
                foilingId: 0,
                foilingName: '',
                foiling: [],
                selectedPlasticWindow: 0,
                plasticWindow: [],
                gluing: item.gluing,
                additionalServiceId: item.additionalService,
                dieCut: item.isDieCut,
              }
            )
          })
          setFormValues({
            ...formValues,
            partData: formPartData,
            incrementalSteps: packagingData.incrementalSteps,
            minimumManufacture: packagingData.minimumManufacture,
            amountProduction: packagingData.minimumManufacture,
          })
        } else {
          message.error(data.packaging.message)
          setTimeout(() => {
            window.location = PATH.packaging
          }, 1000)
        }
      }}
    >
      {
        ({ loading, data }) => {
          if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
          if (!data.packaging.success) {
            return (
              <Container header={{
                type: 'title',
                img: Banner,
                title: 'ชื่อบรรจุภัณฑ์',
                color: theme.color.offWhite,
              }}>
                <WidthContainer>
                  <H4>ขออภัยค่ะ {data.packaging.message}</H4>
                </WidthContainer>
              </Container>
            )
          }
          const packagingData = data.packaging.package
          return (
            <>
              {
                isMobileXL && <SummaryToggleComponent
                  setSummaryActive={setSummaryActive}
                  summaryActive={summaryActive}
                >
                  <SummaryDivMobile>
                    <Summary
                      formValues={formValues}
                      activeTab={activeTab}
                      onChangeTab={onChangeTab}
                      defaultPrinting={true}
                      sticky={false}
                      step={step}
                      setStep={setStep}
                      style={{ maxWidth: '100%', top: 0 }}
                      setSummaryActive={setSummaryActive}
                      isShowGluing
                    />
                  </SummaryDivMobile>
                </SummaryToggleComponent>
              }
              <FullWidthContainer>
                {/* Title Detail Section */}
                <div style={{ backgroundColor: '#FFFFFF' }}>
                  <Detail data={packagingData} />
                </div>
                {/* Preview Component */}
                <Content id="content-package" ref={ContentRef} step={step} data={packagingData.packageParts && packagingData.packageParts.length}>
                  <WidthContainer>
                    <Div display={ step === 1 && 'flex' }>
                      {
                        step === 1 && formValues.partData.length > 0 && <ContentPart>
                          <Parts
                            data={packagingData}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            activeTab={activeTab}
                            onChangeTab={onChangeTab}
                            isMobileXL={isMobileXL}
                            disabled={disabled}
                            setDisabled={setDisabled}
                            ContentRef={ContentRef}
                            isShowGluing={true}
                          />
                        </ContentPart>
                      }
                      {
                        step === 2 && <Div>
                          <Bar>
                            <Div>
                              <ButtonBack
                                inverse
                                text='<< ย้อนกลับ'
                                onClick={() => setStep(1)}
                              />
                            </Div>
                            <Title>ข้อมูลโปรโมชัน และราคา</Title>
                            <Div>
                              {
                                !isMobileXL && <ButtonBack
                                  inverse
                                  text='<< ย้อนกลับ'
                                  style={{ opacity: 0, cursor: 'unset' }}
                                />
                              }
                            </Div>
                          </Bar>
                          <Div>
                            <Div display='flex' alignItems='center' justifyContent='center'>
                              <Div flexBasis={isMobileXL ? '100%' : '70%'}>
                                <SummaryContent
                                  setFormValues={setFormValues}
                                  formValues={formValues}
                                  setStep={setStep}
                                  isShowMore={true}
                                />
                              </Div>
                              {
                                !isMobileXL && <Div flexBasis='30%'>
                                  <Summary
                                    formValues={formValues}
                                    activeTab={activeTab}
                                    onChangeTab={onChangeTab}
                                    sticky
                                    step={step}
                                    setStep={setStep}
                                    ContentRef={ContentRef}
                                    setSummaryActive={setSummaryActive}
                                    isShowGluing
                                  />
                                </Div>
                              }
                            </Div>
                          </Div>
                        </Div>
                      }
                    </Div>
                  </WidthContainer>
                </Content>
                <WidthContainer>
                  {
                    step === 1 && <ActionZone>
                    <Query
                      query={PRICE_CALCULATOR}
                    >
                      {
                        ({ refetch }) => (
                          <ButtonCalculator
                            inverse
                            buttonStyle='round'
                            loading={callCalculation}
                            disabled={callCalculation || disabled}
                            text={<React.Fragment><IconCalculate src={CalculationIcon} />คำนวณราคา</React.Fragment>}
                            onClick={async () => {
                              setCallCalculation(true)
                              const findNullSizePart = formValues.partData.map(item => Object.values(item.sizeList).filter(v => v === null || v === '' || v === undefined))
                              const findNullSize = Array.prototype.concat.apply([], findNullSizePart).filter(v => v === null || v === '' || v === undefined || v === 0)
                              const findEmptyEmbossing = formValues.partData.filter(item => (item.selectedEmbossing === 1 && item.embossing.length === 0) || (item.embossing.length > 0 && item.embossing.filter(x => x.width === null || x.lengths === null).length > 0))
                              const findEmptyFoiling = formValues.partData.filter(item => (item.foilingId > 0 && item.foiling.length === 0) || (item.foiling.length > 0 && item.foiling.filter(x => x.width === null || x.lengths === null).length > 0))
                              const findEmptyPlastic = formValues.partData.filter(item => (item.selectedPlasticWindow === 1 && item.plasticWindow.length === 0) || (item.plasticWindow.length > 0 && item.plasticWindow.filter(x => x.width === null || x.lengths === null).length > 0))
                              if (findNullSize.length > 0) {
                                message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                                setCallCalculation(false)
                                return
                              }
                              if (findNullSize.length > 0 || findEmptyEmbossing.length > 0 || findEmptyFoiling.length > 0 || findEmptyPlastic.length > 0) {
                                message.warning(`กรุณากรอกข้อมูล ${findNullSize.length > 0 ? 'ขนาดบรรจุภัณฑ์' : ''} ${findEmptyEmbossing.length > 0 ? 'ตำแหน่งการปั๊มนูน' : ''} ${findEmptyFoiling.length > 0 ? 'ตำแหน่งปั๊มฟอยล์-ทองเค' : ''} ${findEmptyPlastic.length > 0 ? 'ตำแหน่งการเจาะหน้าต่างและติดแผ่นใส' : ''} ให้ครบถ้วน`)
                                setCallCalculation(false)
                                return
                              }
                              const part = formValues.partData.map((item, index) => ({
                                order: index + 1,
                                packagePartId: item.packagePartId,
                                unit: item.measurementUnit.key,
                                operator: Object.values(item.sizeList).map(x => (parseFloat(x))),
                                printSizeWidth: 0.0,
                                printSizeLength: 0.0,
                                paper: {
                                  paperId: item.paper.paperId,
                                  thickness: parseFloat(item.paper.selectedThickness),
                                },
                                printing: item.printing.side && {
                                  side: item.printing.printingType,
                                  printingBasicColorId: item.printing.printingBasicColorId,
                                  printingExtraColorId: item.printing.printingExtraColorId.filter(x => x.printingExtraColorId).map(x => x.printingExtraColorId),
                                },
                                coating: {
                                  coatingIdFront: item.coating.side === 1 || item.coating.side === 2 ? item.coating.coatingFrontId : null,
                                  coatingIdBehind: item.coating.side === -1 || item.coating.side === 2 ? item.coating.coatingBackId : null,
                                },
                                spotUV: {
                                  spotUVIdFront: item.spotUV.spotUVFrontId,
                                  spotUVIdBehind: item.spotUV.spotUVBackId,
                                },
                                embossing: item.embossing.map((x, i) => ({
                                  order: i + 1,
                                  width: parseFloat(x.width),
                                  length: parseFloat(x.lengths),
                                  unit: item.measurementUnit.key,
                                })),
                                foiling: {
                                  foilingId: item.foilingId !== 0 ? item.foilingId : null,
                                  foilingPart: item.foiling.map((x, i) => ({
                                    order: i + 1,
                                    width: parseFloat(x.width),
                                    length: parseFloat(x.lengths),
                                    unit: item.measurementUnit.key,
                                  })),
                                },
                                dieCut: item.dieCut,
                                plasticWindow: item.plasticWindow.map((x, i) => ({
                                  order: i + 1,
                                  width: parseFloat(x.width),
                                  length: parseFloat(x.lengths),
                                  thickness: x.thickness,
                                  unit: item.measurementUnit.key,
                                })),
                                gluingId: item.gluing ? item.gluing.gluingId : null,
                                additionalServiceId: item.additionalServiceId.map(x => x.additionalServiceId) || [],
                              }))
                              const input = {
                                part,
                              }
                              await refetch({
                                input,
                              }).then(async (res) => {
                                if (res.data.priceCalculator.success) {
                                  await setFormValues({
                                    ...formValues,
                                    partData: formValues.partData,
                                    partDetail: res.data.priceCalculator.partDetail,
                                    totalCost: res.data.priceCalculator.totalCost,
                                    costDetail: res.data.priceCalculator.costDetail,
                                  })
                                  const element = document.getElementById('content-package')
                                  if (element) {
                                    setTimeout(async () => {
                                      setCallCalculation(false)
                                      await setStep(2)
                                      await element.scrollIntoView({
                                        behavior: 'smooth',
                                      })
                                    }, 500)
                                  }
                                } else if (res.data.priceCalculator.message === 'oversize') {
                                  setCallCalculation(false)
                                  message.warning('ไม่สามารถให้บริการผลิตขนาดดังกล่าวได้ เนื่องจากมีขนาดเกินเครื่องพิมพ์ ของทางเรา ขออภัยในความไม่สะดวก')
                                } else if (res.data.priceCalculator.message === 'ขออภัย ขนาดที่ระบุ ไม่สามารถผลิตบนกระดาษชนิดนี้ได้') {
                                  setCallCalculation(false)
                                  message.warning('ขออภัย ขนาดที่ระบุ ไม่สามารถผลิตบนกระดาษชนิดนี้ได้')
                                } else if (res.data.priceCalculator.message === 'ไม่สามารถคำนวณ Width ได้' || res.data.priceCalculator.message === 'ไม่สามารถคำนวณ Length ได้') {
                                  setCallCalculation(false)
                                  message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                                } else {
                                  setCallCalculation(false)
                                  message.success('สามารถให้บริการผลิตขนาดดังกล่าวได้')
                                }
                              }).catch((e) => {
                                if (e.toString().includes('status code 400')) {
                                  setCallCalculation(false)
                                  message.warning('กรุณากรอกข้อมูลให้ครบถ้วน')
                                } else {
                                  setCallCalculation(false)
                                  message.error('ไม่สามารถคำนวนราคาได้')
                                  setStep(1)
                                }
                              })
                            }}
                          />
                        )
                      }
                    </Query>
                  </ActionZone>
                }
                {
                  step === 2 && <ActionZone2>
                      <ButtonFormAction
                        text='ย้อนกลับ'
                        onClick={async () => {
                          const element = document.getElementById('content-package')
                          if (element) {
                            await setStep(1)
                            await element.scrollIntoView({
                              behavior: 'smooth',
                            })
                          }
                        }}
                        inverse
                        color={theme.color.secondaryColor}
                        buttonStyle="round"
                        rightIcon
                        activeGreen={step === 3}
                        icon={'far fa-chevron-double-left'}
                        style={{ marginLeft: !isMobileXL && '12px' }}
                      />
                    </ActionZone2>
                }
                </WidthContainer>
              </FullWidthContainer>
            </>
          )
        }
      }
    </Query>
  )
}

export default PackagingDetail
