import styled from 'styled-components'
import { Input } from 'antd'
import { Div, Input as InputTest } from 'konsys-ui'
import { theme, media } from '../../../../styles/_variables'


export const LabelAmount = styled.div`
  color: ${theme.color.offWhite};
  font-size: ${theme.fonts.size.subTitle};
  ${media.laptopL`
    font-size: 24px;
  `}
  font-weight: ${theme.fonts.style.medium};
  display: flex;
  justify-content: center;
  algin-items: center;
`

export const DivAmount = styled.div`
  padding: 20px;
  background-color: ${theme.color.greenPrimary};
  border-radius: 6px;
`

export const SpanButton = styled.span`
  font-weight: ${theme.fonts.style.medium};
  font-size: ${theme.fonts.size.extraNormal};
`

export const DivCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center
`

export const InputAmount = styled(InputTest)`
  width: 160px;
  ${media.laptop`
      width: 140px;
  `}
  text-align: center;
  font-size: 30px;
  padding-left: 32px !important;
  .ant-input {
    font-size: 30px;
    height: 40px;
    text-align: center;
    border-radius: 20px;
    border: 0px !important;
    padding-left: 40px;
    padding-right: 40px !important;
  }
  .ant-input-suffix {
    font-size: 30px;
    color: ${theme.color.primaryColor};
  }

`

export const DivInput = styled(Div)`
  background-color: white;
  border-radius: 24px;
  div {
    display: flex;
    alginItems: center;
    ${media.laptop`
        width: fit-content;
    `}
  }
  input {
    border: 0px !important;
    padding: 0px 0px 0px 40px !important;
    background-color: transparent;
  }
  input:focus, input.gDAGJy:hover {
    border: unset;
  }
  span {
    font-size: 30px;
    color: ${theme.color.primaryColor};
    padding: 0 8px;
  }
`

export const ModalTitle = styled.p`
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
    color: ${theme.color.primaryColor};
    margin-bottom: 0px; !important;
    white-space: nowrap;
`

export const TextTitle = styled.p`
    font-size: ${theme.fonts.size.normal};
    font-weight: ${theme.fonts.style.medium};
    color: ${theme.color.textColor};
    margin-left: 4px;
    margin-bottom: 0px; !important;
    white-space: nowrap;
    margin-right: 8px;
`

export const TextLabel = styled.p`
    color: ${theme.color.grey};
    font-size: ${theme.fonts.size.normal};
    font-weight: ${theme.fonts.style.normal};
    margin-left: 4px;
    margin-bottom: 0px; !important;
    white-space: nowrap;
    margin-right: 8px;
`
export const TextValue = styled.p`
    color: ${theme.color.primaryColor};
    font-size: ${theme.fonts.size.normal};
    font-weight: ${theme.fonts.style.medium};
    margin-bottom: 0px;
    text-align: right;
    word-break: break-word;
`

export const DivPricing = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 4px;
  &::-webkit-scrollbar {
      width: 3px;
  }
  &::-webkit-scrollbar-thumb {
      height: 4px;
      background: ${theme.color.primaryColor};
  }
  &::-webkit-scrollbar-track {
      background: ${theme.color.lightPaleGrey};
  }
  ${media.mobileL`
      max-height: fit-content;
      overflow-y: hidden;
      height: fit-content;
  `}
`

export const MoreTextLabel = styled.p`
  font-weight: ${theme.fonts.style.bold};
  font-size: ${theme.fonts.size.normal};
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  margin-bottom: 0px;
  color: ${theme.color.fourthColor};
  ${media.mobileL`
    font-size: ${theme.fonts.size.normal};
  `}
`

export const MoreText = styled.h4`
  font-weight: ${theme.fonts.style.regular};
  font-size: ${theme.fonts.size.normal};
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  margin-bottom: 0px;
  color: ${theme.color.fourthColor};
  ${media.mobileL`
    font-size: ${theme.fonts.size.small};
  `}
  &:before {
    content: "- ";
  }
`

export const DescriptionContent = styled(Div)`
  font-weight: ${theme.fonts.style.regular};
  font-size: ${theme.fonts.size.normal};
  & > p {
    margin: 0px !important;
    font-family: 'printAble4u', sans-serif;
    font-weight: ${theme.fonts.style.regular};
    font-size: 20px;
    line-height: 24px;
    color: ${theme.color.fourthColor};
  }
`

export default LabelAmount
