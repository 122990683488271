import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import {
  RowsContainer, SelectBoxContainer, ButtonAddRow,
  ButtonAction, LabelPosition, Span,
  SelectBox,
} from './styled'


const MultiSelectBox = ({
  title,
  placeholder,
  defaultValues = [],
  optionsData = [],
  onChange,
  disabled,
}) => {
  const [rows, setRows] = useState([])
  const [options, setOptions] = useState(optionsData)
  useEffect(() => {
    if (defaultValues.length !== 0) {
      const tmpDefaultValues = [...defaultValues]
      setRows(tmpDefaultValues.map(v => ({ title, placeholder, value: v.value })))
    } else {
      setRows([{ title, placeholder }])
    }
  }, [])

  useEffect(() => {
    if (optionsData) setOptions([...optionsData])
  }, [optionsData])

  const onClickPlus = () => {
    const itemToInsert = { title, placeholder }
    const cloneRows = JSON.parse(JSON.stringify(rows))
    const tmp = [...cloneRows]
    if (tmp.length !== options.length) {
      tmp.push(itemToInsert)
      setRows(tmp)
    }
  }
  const toggleOption = (value) => {
    if (!value) {
      return
    }
    const cloneOptions = JSON.parse(JSON.stringify(options))
    const tmpOptions = [...cloneOptions]
    const optionIndex = tmpOptions.findIndex(item => item.value === value)
    tmpOptions[optionIndex].disabled = !tmpOptions[optionIndex].disabled
    setOptions(tmpOptions)
  }
  const onClickMinus = (value, index) => {
    const cloneRows = JSON.parse(JSON.stringify(rows))
    const tmp = [...cloneRows]
    tmp.splice(index, 1)
    setRows(tmp)
    toggleOption(value)
    onChange(tmp)
  }
  const onSelection = (value, index) => {
    const cloneRows = JSON.parse(JSON.stringify(rows))
    const tmp = [...cloneRows]
    tmp[index].value = value
    setRows(tmp)
    onChange(tmp)
  }
  const handleOnChange = (newValue, oldValue, index) => {
    onSelection(newValue, index)
    toggleOption(newValue)
    toggleOption(oldValue)
  }
  return (
    <RowsContainer>
      {
        rows.map((item, index, arr) => (
          <ColorSelectBox
            key={index}
            item={item}
            index={index}
            length={arr.length}
            options={options}
            handleOnChange={handleOnChange}
            onClickMinus={onClickMinus}
            disabled={disabled}
          />
        ))
      }
      <ButtonAddRow
        icon="fa fa-plus"
        onlyIcon
        small
        disabled={rows.length === options.length}
        onClick={onClickPlus}
      />
    </RowsContainer>
  )
}

const ColorSelectBox = ({
  item,
  index,
  options,
  handleOnChange,
  onClickMinus,
  disabled,
}) => (
  <SelectBoxContainer>
    <Row type='flex' justify='space-between' style={{ alignItems: 'center', marginBottom: '2px' }}>
      <Col span={12} style={{ justifyContent: 'left' }}>
        <LabelPosition>{`${item.title} ${index + 1}`}</LabelPosition>
      </Col>
      <Col span={12} style={{ display: 'flex', justifyContent: 'right' }}>
        <ButtonAction
          icon="fa fa-minus"
          onlyIcon
          inverse
          small
          buttonStyle="round"
          onClick={() => onClickMinus(item.value, index)}
        />
      </Col>
    </Row>
    <Row type='flex' justify='space-between' style={{ alignItems: 'center' }}>
      <Col span={12}>
        <Span>สี</Span>
      </Col>
      <Col span={12}>
        <SelectBox
          placeholder={item.placeholder}
          onChange={value => handleOnChange(value, item.value, index)}
          value={item.value}
          disabled={disabled}
        >
          {
            options && options.map(option => (
              <SelectBox.Option
                key={option.index}
                value={option.value}
                disabled={option.disabled}
              >
                {option.title}
              </SelectBox.Option>
            ))
          }
        </SelectBox>
      </Col>
    </Row>
  </SelectBoxContainer>
)

export default MultiSelectBox
