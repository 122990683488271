import React, { useState, useEffect } from 'react'
import { Div, LoadingIcon } from 'konsys-ui'
import { Query } from 'react-apollo'
import { Row, Col } from 'antd'
import { LIST_COATING } from '../../../../constants/graphql/query'
import { theme } from '../../../../styles/_variables'
import NotchedContainer from '../../../common/notched-containner'
import OptionsList from '../common/options-list'
import OptionDescription from '../common/option-description'
import SampleImageList from '../common/sample-image-list'
import SelectBox from '../common/select-box'
import Recommendation from '../common/recommendation'
import { CoverImage, DetailWrapper } from './styled'
import coatingIconImage from '../../../../images/icon/CoatingIcon.png'


const MockIcon = () => <div style={{
  padding: '10px',
  background: 'gray',
}}></div>

const CoatingSelector = ({
  formValues,
  setFormValues,
  activeTab,
  disabled,
}) => {
  const coatingInitialValues = {
    coatingIdFront: null,
    coatingIdBehind: null,
  }
  const sideOptions = [
    { value: 0, title: 'ไม่เคลือบ' },
    { value: 1, title: 'เคลือบด้านหน้า' },
    { value: -1, title: 'เคลือบด้านหลัง' },
    { value: 2, title: 'เคลือบสองหน้า' },
  ]
  const [coatingFormValues, setCoatingFormValue] = useState(coatingInitialValues)
  const [side, setSide] = useState(formValues.partData[activeTab].coating.side)
  const [selectedFrontCoating, setSelectedFrontCoating] = useState(null)
  const [selectedBackCoating, setSelectedBackCoating] = useState(null)

  const handleSelectSideOption = (value) => {
    if (value === 0) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].coating.coatingType = 'ไม่เคลือบ'
      tmpFormValues[activeTab].coating.coatingFrontId = null
      tmpFormValues[activeTab].coating.coatingFrontName = ''
      tmpFormValues[activeTab].coating.coatingBackId = null
      tmpFormValues[activeTab].coating.coatingBackName = ''
      tmpFormValues[activeTab].coating.side = value
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
    if (value === 1) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].coating.coatingType = 'เคลือบด้านหน้า'
      tmpFormValues[activeTab].coating.coatingBackId = null
      tmpFormValues[activeTab].coating.side = value
      tmpFormValues[activeTab].coating.coatingFrontId = formValues.partData[activeTab].coating.coatingFrontId || (formValues.partData[activeTab].paper && formValues.partData[activeTab].paper.coatingFrontIds.length > 0 ? formValues.partData[activeTab].paper.coatingFrontIds[0].coatingId : null)
      tmpFormValues[activeTab].coating.coatingFrontName = formValues.partData[activeTab].coating.coatingFrontName
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
    if (value === -1) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].coating.coatingType = 'เคลือบด้านหลัง'
      tmpFormValues[activeTab].coating.coatingFrontId = null
      tmpFormValues[activeTab].coating.side = value
      tmpFormValues[activeTab].coating.coatingBackId = formValues.partData[activeTab].coating.coatingBackId || (formValues.partData[activeTab].paper && formValues.partData[activeTab].paper.coatingBackIds.length > 0 ? formValues.partData[activeTab].paper.coatingBackIds[0].coatingId : null)
      tmpFormValues[activeTab].coating.coatingBackName = formValues.partData[activeTab].coating.coatingBackName
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
    if (value === 2) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].coating.coatingType = 'เคลือบสองหน้า'
      tmpFormValues[activeTab].coating.side = value
      tmpFormValues[activeTab].coating.coatingFrontId = formValues.partData[activeTab].coating.coatingFrontId || (formValues.partData[activeTab].paper && formValues.partData[activeTab].paper.coatingFrontIds.length > 0 ? formValues.partData[activeTab].paper.coatingFrontIds[0].coatingId : null)
      tmpFormValues[activeTab].coating.coatingBackId = formValues.partData[activeTab].coating.coatingBackId || (formValues.partData[activeTab].paper && formValues.partData[activeTab].paper.coatingBackIds.length > 0 ? formValues.partData[activeTab].paper.coatingBackIds[0].coatingId : null)
      tmpFormValues[activeTab].coating.coatingFrontName = formValues.partData[activeTab].coating.coatingFrontName
      tmpFormValues[activeTab].coating.coatingBackName = formValues.partData[activeTab].coating.coatingBackName
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
    setSide(value)
    setCoatingFormValue(coatingInitialValues)
  }

  const handleOnSelectFrontCoating = (value) => {
    if (value) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].coating.coatingFrontId = value.coatingId
      tmpFormValues[activeTab].coating.coatingFrontName = value.name
      setFormValues({ ...formValues, partData: tmpFormValues })
      setSelectedFrontCoating(value)
      setCoatingFormValue({ ...coatingFormValues, coatingIdFront: value.coatingId })
    } else {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].coating.coatingFrontId = null
      tmpFormValues[activeTab].coating.coatingFrontName = null
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
  }
  const handleOnSelectBackCoating = (value) => {
    if (value) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].coating.coatingBackId = value.coatingId
      tmpFormValues[activeTab].coating.coatingBackName = value.name
      setFormValues({ ...formValues, partData: tmpFormValues })
      setSelectedBackCoating(value)
      setCoatingFormValue({ ...coatingFormValues, coatingIdBehind: value.coatingId })
    } else {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].coating.coatingBackId = null
      tmpFormValues[activeTab].coating.coatingBackName = null
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
  }

  useEffect(() => {
    setFormValues({ ...formValues, coating: coatingFormValues })
  }, [coatingFormValues])

  useEffect(() => {
    handleSelectSideOption(formValues.partData[activeTab].coating.side)
  }, [activeTab])

  return <Div display='flex' flexDirection='column' moreStyle={{ rowGap: '5px' }}>
    <NotchedContainer
      title='การเคลือบผิว'
      titleIcon={coatingIconImage}
    >
      <SelectBox
        title='ลักษณะการเคลือบ'
        placeholder='เลือกลักษณะการเคลือบ'
        value={side}
        options={sideOptions}
        onChange={handleSelectSideOption}
        centerTitle
        disabled={disabled}
      />
    </NotchedContainer>
    {
      formValues.partData[activeTab].coating.side === 1 ? <FrontCoatingSelector
        formValues={formValues}
        activeTab={activeTab}
        value={selectedFrontCoating}
        handleOnSelect={handleOnSelectFrontCoating}
        filterOptions={formValues.partData[activeTab].paper.coatingFrontIds.map(v => v.coatingId)}
        disabled={disabled}
      />
        : formValues.partData[activeTab].coating.side === -1 ? <BackCoatingSelector
          formValues={formValues}
          activeTab={activeTab}
          value={selectedBackCoating}
          handleOnSelect={handleOnSelectBackCoating}
          filterOptions={formValues.partData[activeTab].paper.coatingBackIds.map(v => v.coatingId)}
          disabled={disabled}
        />
          : formValues.partData[activeTab].coating.side === 2 ? <Div display='flex' flexDirection='column' moreStyle={{ rowGap: '5px' }}>
              <FrontCoatingSelector
                formValues={formValues}
                activeTab={activeTab}
                value={selectedFrontCoating}
                handleOnSelect={handleOnSelectFrontCoating}
                filterOptions={formValues.partData[activeTab].paper.coatingFrontIds.map(v => v.coatingId)}
                disabled={disabled}
              />
              <BackCoatingSelector
                value={selectedBackCoating}
                activeTab={activeTab}
                formValues={formValues}
                handleOnSelect={handleOnSelectBackCoating}
                filterOptions={formValues.partData[activeTab].paper.coatingBackIds.map(v => v.coatingId)}
                disabled={disabled}
              />
            </Div>
            : ''
    }
  </Div>
}

const SelectorFront = ({
  title,
  secondTitle,
  value,
  handleOnSelect,
  filterOptions = [],
  textOverlay,
  formValues,
  activeTab,
  disabled,
}) => {
  const [coatingData, setCoatingData] = useState([])
  const [coatingFetchList, setCoatingRawDatas] = useState([])
  const [coatingOptions, setCoatingOption] = useState([])
  const [remark, setRemark] = useState('')

  useEffect(() => {
    if (coatingData) {
      const coatingList = coatingFetchList.filter(v => filterOptions.includes(v.coatingId))
        .map((item, index) => {
          const coverImg = item.coatingFiles.find(file => file.type === 'cover')
          const sampleImages = item.coatingFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
          const sampleVideo = item.coatingFiles.filter(file => file.type === 'video')
          return {
            ...item,
            label: item.name,
            value: item.coatingId,
            icon: coverImg ? <CoverImage imgUrl={coverImg.coatingFileUri} /> : <MockIcon />,
            textOverlay,
            sampleImages,
            sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].coatingFileUri : null,
            checked: formValues.partData[activeTab].coating.coatingFrontId === item.coatingId,
          }
        })
      setCoatingOption(coatingList)
      if (coatingList.length > 0) handleOnSelect(coatingList.find(item => item.coatingId === formValues.partData[activeTab].coating.coatingFrontId))
    }
  }, [activeTab, coatingData, formValues.partData[activeTab].coating.side])

  useEffect(() => {
    if (coatingData) {
      const frontId = formValues.partData[activeTab].paper.coatingFrontIds.find(item => item.coatingId === formValues.partData[activeTab].coating.coatingFrontId)
        ? formValues.partData[activeTab].paper.coatingFrontIds.find(item => item.coatingId === formValues.partData[activeTab].coating.coatingFrontId).coatingId
        : formValues.partData[activeTab].paper.coatingFrontIds.length > 0 ? formValues.partData[activeTab].paper.coatingFrontIds[0].coatingId : null
      const coatingList = coatingFetchList.filter(v => filterOptions.includes(v.coatingId))
        .map((item, index) => {
          const coverImg = item.coatingFiles.find(file => file.type === 'cover')
          const sampleImages = item.coatingFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
          const sampleVideo = item.coatingFiles.filter(file => file.type === 'video')
          return {
            ...item,
            label: item.name,
            value: item.coatingId,
            icon: coverImg ? <CoverImage imgUrl={coverImg.coatingFileUri} /> : <MockIcon />,
            textOverlay,
            sampleImages,
            sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].coatingFileUri : null,
            checked: frontId === item.coatingId,
          }
        })
      setCoatingOption(coatingList)
      if (coatingList.length > 0) handleOnSelect(coatingList.find(item => item.coatingId === frontId))
      if (!frontId) handleOnSelect(null)
    }
  }, [formValues.partData[activeTab].paper.paperId])

  return <NotchedContainer noTitle>
    <Row type='flex' gutter={16}>
      <Col xs={24} sm={coatingOptions.length === 0 ? 24 : 11} md={coatingOptions.length === 0 ? 24 : 11} span={16}>
        <Query
          query={LIST_COATING}
          notifyOnNetworkStatusChange
          fetchPolicy='network-only'
          variables={{
            limit: 10000,
            offset: 0,
            order: 'createdAt',
            sort: 'ASC',
          }}
          onCompleted={(data) => {
            if (data.listCoating.coating) {
              setCoatingRawDatas(data.listCoating.coating)
              const coatingRawData = formValues.partData[activeTab].paper.coatingFrontIds.map((items, index) => {
                const item = data.listCoating.coating.find(x => x.coatingId === items.coatingId)
                const coverImg = item.coatingFiles.find(file => file.type === 'cover')
                const sampleImages = item.coatingFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
                const sampleVideo = item.coatingFiles.filter(file => file.type === 'video')
                return {
                  ...item,
                  label: item.name,
                  value: item.coatingId,
                  icon: coverImg ? <CoverImage imgUrl={coverImg.coatingFileUri} /> : <MockIcon />,
                  textOverlay,
                  sampleImages,
                  sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].coatingFileUri : null,
                  checked: index === 0,
                }
              })
              setCoatingData(coatingRawData)
              const coatingList = data.listCoating.coating.filter(v => filterOptions.includes(v.coatingId))
                .map((items, index) => {
                  const item = data.listCoating.coating.find(x => x.coatingId === items.coatingId)
                  const coverImg = item.coatingFiles.find(file => file.type === 'cover')
                  const sampleImages = item.coatingFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
                  const sampleVideo = item.coatingFiles.filter(file => file.type === 'video')
                  return {
                    ...item,
                    label: item.name,
                    value: item.coatingId,
                    icon: coverImg ? <CoverImage imgUrl={coverImg.coatingFileUri} /> : <MockIcon />,
                    textOverlay,
                    sampleImages,
                    sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].coatingFileUri : null,
                    checked: formValues.partData[activeTab].coating.coatingFrontId === item.coatingId,
                  }
                })
              setCoatingOption(coatingList)
              handleOnSelect(coatingList.find(item => item.coatingId === formValues.partData[activeTab].coating.coatingFrontId))
              setRemark(data.listCoating.remark)
            }
          }}
          >
            {
              ({ loading, data, refetch }) => {
                if (loading) {
                  return <Div moreStyle={{ width: '100%' }}>
                    <LoadingIcon color={theme.color.grey} />
                  </Div>
                }
                return (
                  <OptionsList
                    title={title}
                    options={coatingOptions}
                    circle
                    onSelect={handleOnSelect}
                    black
                    disabled={disabled}
                    noOptionText='ไม่สามารถเคลือบได้'
                  />
                )
              }
            }
          </Query>
      </Col>
      {
        coatingOptions.length > 0 && <Col xs={24} sm={13} md={13} span={16}>
          <DetailWrapper>
            <OptionDescription
              title={coatingOptions.length === 0 ? 'ไม่สามารถเคลือบได้' : value && value.name}
              secondTitle={secondTitle}
              description={value && value.description}
              videoSrc={value && value.sampleVideoUri}
            />
            {
              value && value.sampleImages.length > 0 && <SampleImageList
                title='รูปภาพตัวอย่าง'
                data={value.sampleImages.map(item => ({ fileUrl: item.coatingFileUri, ordering: item.ordering }))}
              />
            }
            {
              coatingOptions.length > 0 && remark && <Recommendation
                value={remark.description}
              />
            }
          </DetailWrapper>
        </Col>
      }
    </Row>
  </NotchedContainer>
}

const SelectorBack = ({
  title,
  secondTitle,
  value,
  handleOnSelect,
  filterOptions = [],
  textOverlay,
  formValues,
  activeTab,
  disabled,
}) => {
  const [coatingData, setCoatingData] = useState([])
  const [coatingFetchList, setCoatingRawDatas] = useState([])
  const [coatingOptions, setCoatingOption] = useState([])
  const [remark, setRemark] = useState('')

  useEffect(() => {
    if (coatingData) {
      const coatingList = coatingFetchList.filter(v => filterOptions.includes(v.coatingId))
        .map((item, index) => {
          const coverImg = item.coatingFiles.find(file => file.type === 'cover')
          const sampleImages = item.coatingFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
          const sampleVideo = item.coatingFiles.filter(file => file.type === 'video')
          return {
            ...item,
            label: item.name,
            value: item.coatingId,
            icon: coverImg ? <CoverImage imgUrl={coverImg.coatingFileUri} /> : <MockIcon />,
            textOverlay,
            sampleImages,
            sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].coatingFileUri : null,
            checked: formValues.partData[activeTab].coating.coatingBackId === item.coatingId,
          }
        })
      setCoatingOption(coatingList)
      if (coatingList.length > 0) handleOnSelect(coatingList.find(item => item.coatingId === formValues.partData[activeTab].coating.coatingBackId))
    }
  }, [activeTab, coatingData, formValues.partData[activeTab].coating.side])

  useEffect(() => {
    if (coatingData) {
      const backId = formValues.partData[activeTab].paper.coatingBackIds.find(item => item.coatingId === formValues.partData[activeTab].coating.coatingBackId)
        ? formValues.partData[activeTab].paper.coatingBackIds.find(item => item.coatingId === formValues.partData[activeTab].coating.coatingBackId).coatingId
        : formValues.partData[activeTab].paper.coatingBackIds.length > 0 && formValues.partData[activeTab].paper.coatingBackIds[0].coatingId
      const coatingList = coatingFetchList.filter(v => filterOptions.includes(v.coatingId))
        .map((item, index) => {
          const coverImg = item.coatingFiles.find(file => file.type === 'cover')
          const sampleImages = item.coatingFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
          const sampleVideo = item.coatingFiles.filter(file => file.type === 'video')
          return {
            ...item,
            label: item.name,
            value: item.coatingId,
            icon: coverImg ? <CoverImage imgUrl={coverImg.coatingFileUri} /> : <MockIcon />,
            textOverlay,
            sampleImages,
            sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].coatingFileUri : null,
            checked: backId === item.coatingId,
          }
        })
      setCoatingOption(coatingList)
      if (coatingList.length > 0) handleOnSelect(coatingList.find(item => item.coatingId === backId))
      if (!backId) handleOnSelect(null)
    }
  }, [formValues.partData[activeTab].paper.paperId])

  return <NotchedContainer noTitle>
    <Row type='flex' gutter={16}>
      <Col xs={24} sm={coatingOptions.length === 0 ? 24 : 11} md={coatingOptions.length === 0 ? 24 : 11} span={16}>
        <Query
          query={LIST_COATING}
          notifyOnNetworkStatusChange
          fetchPolicy='network-only'
          variables={{
            limit: 10000,
            offset: 0,
          }}
          onCompleted={(data) => {
            if (data.listCoating.coating) {
              setCoatingRawDatas(data.listCoating.coating)
              const coatingRawData = formValues.partData[activeTab].paper.coatingBackIds.map((items, index) => {
                const item = data.listCoating.coating.find(x => x.coatingId === items.coatingId)
                const coverImg = item.coatingFiles.find(file => file.type === 'cover')
                const sampleImages = item.coatingFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
                const sampleVideo = item.coatingFiles.filter(file => file.type === 'video')
                return {
                  ...item,
                  label: item.name,
                  value: item.coatingId,
                  icon: coverImg ? <CoverImage imgUrl={coverImg.coatingFileUri} /> : <MockIcon />,
                  textOverlay,
                  sampleImages,
                  sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].coatingFileUri : null,
                  checked: index === 0,
                }
              })
              setCoatingData(coatingRawData)
              const coatingList = data.listCoating.coating.filter(v => filterOptions.includes(v.coatingId))
                .map((items, index) => {
                  const item = data.listCoating.coating.find(x => x.coatingId === items.coatingId)
                  const coverImg = item.coatingFiles.find(file => file.type === 'cover')
                  const sampleImages = item.coatingFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample')
                  const sampleVideo = item.coatingFiles.filter(file => file.type === 'video')
                  return {
                    ...item,
                    label: item.name,
                    value: item.coatingId,
                    icon: coverImg ? <CoverImage imgUrl={coverImg.coatingFileUri} /> : <MockIcon />,
                    textOverlay,
                    sampleImages,
                    sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].coatingFileUri : null,
                    checked: formValues.partData[activeTab].coating.coatingBackId === item.coatingId,
                  }
                })
              setCoatingOption(coatingList)
              handleOnSelect(coatingList.find(item => item.coatingId === formValues.partData[activeTab].coating.coatingBackId))
              setRemark(data.listCoating.remark)
            }
          }}
          >
            {
              ({ loading, data, refetch }) => {
                if (loading) {
                  return <Div moreStyle={{ width: '100%' }}>
                    <LoadingIcon color={theme.color.grey} />
                  </Div>
                }
                return (
                  <OptionsList
                    title={title}
                    options={coatingOptions}
                    circle
                    onSelect={handleOnSelect}
                    black
                    disabled={disabled}
                    noOptionText='ไม่สามารถเคลือบได้'
                  />
                )
              }
            }
          </Query>
        </Col>
        {
          coatingOptions.length > 0 && <Col xs={24} sm={13} md={13} span={16}>
            <DetailWrapper>
              <OptionDescription
                title={coatingOptions.length === 0 ? 'ไม่สามารถเคลือบได้' : value && value.name}
                secondTitle={secondTitle}
                description={value && value.description}
                videoSrc={value && value.sampleVideoUri}
              />
              {
                value && value.sampleImages.length > 0 && <SampleImageList
                  title='รูปภาพตัวอย่าง'
                  data={value.sampleImages.map(item => ({ fileUrl: item.coatingFileUri, ordering: item.ordering }))}
                />
              }
              {
                coatingOptions.length > 0 && remark && <Recommendation
                  value={remark.description}
                />
              }
            </DetailWrapper>
          </Col>
        }
      </Row>
  </NotchedContainer>
}

const FrontCoatingSelector = ({
  value,
  filterOptions,
  handleOnSelect,
  formValues,
  activeTab,
  disabled,
}) => (
  <SelectorFront
    title='เลือกวิธีการเคลือบด้านหน้า'
    secondTitle='การเคลือบด้านหน้า'
    value={value}
    filterOptions={filterOptions}
    handleOnSelect={handleOnSelect}
    formValues={formValues}
    activeTab={activeTab}
    disabled={disabled}
  />
)

const BackCoatingSelector = ({
  value,
  filterOptions,
  handleOnSelect,
  formValues,
  activeTab,
  disabled,
}) => (
  <SelectorBack
    title='เลือกวิธีการเคลือบด้านหลัง'
    secondTitle='การเคลือบด้านหลัง'
    value={value}
    filterOptions={filterOptions}
    handleOnSelect={handleOnSelect}
    formValues={formValues}
    activeTab={activeTab}
    disabled={disabled}
  />
)


export default CoatingSelector
