import React, { useState } from 'react'
import { Query } from 'react-apollo'
import {
  Div,
} from 'konsys-ui'
import {
  Select,
} from 'antd'
import { LIST_GLUING } from '../../../../constants/graphql/query'
import { theme } from '../../../../styles/_variables'
import {
  Label,
} from './styled'


const Gluing = ({
  number,
  formValues,
  setPartData,
}) => {
  const [listGluing, setListGluing] = useState([{ id: 0, name: 'ไม่ติดกาว' }])
  const handleChange = (value) => {
    if (formValues.partData) {
      const tmpPartData = formValues.partData
      tmpPartData[number].gluing = value
      setPartData({
        ...formValues,
        partData: tmpPartData,
      })
    }
  }
  return (
    <Div>
      <Label>วิธีการติดกาว</Label>
      <Query
        query={LIST_GLUING}
        fetchPolicy='network-only'
        variables={{
          limit: 10000,
          offset: 0,
        }}
        notifyOnNetworkStatusChange
        onCompleted={(data) => {
          const { gluing } = data.listGluing
          const result = gluing.map(item => ({
            id: item.gluingId,
            name: item.name,
          }))
          result.push({ id: 0, name: 'ไม่ติดกาว' })
          setListGluing(result)
        }}
      >
      {
      ({ data }) => (<>
        {
          (data.listGluing && data.listGluing.success) && <Select
            style={{ width: '100%', fontSize: theme.fonts.size.normal }}
            value={formValues.partData && formValues.partData[number].gluing}
            onChange={value => handleChange(value)}
          >
          {
            listGluing.length > 0 && listGluing.map(v => (
              <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>
            ))
          }
          </Select>
        }
      </>)
      }
      </Query>
    </Div>
  )
}
export default Gluing
