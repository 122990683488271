/* eslint-disable radix */
import React, { useState, useEffect, useRef } from 'react'
import { Mutation, Query } from 'react-apollo'
import _ from 'lodash'
import {
  message,
  Switch,
} from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import evaluatex from 'evaluatex/dist/evaluatex'
import { theme } from '../../../styles/_variables'
import { CREATE_PACKAGE } from '../../../constants/graphql/mutation'
import { PRICE_CALCULATOR, LIST_PAPER } from '../../../constants/graphql/query'
import PATH from '../../../constants/path'
import 'react-quill/dist/quill.snow.css'
import 'katex/dist/katex.min.css'
import PackageForm from '../components/package-form'
import Detail from '../../../components/package-calculator/components/detail'
import Parts from '../../../components/package-calculator/components/parts'
import Summary from '../../../components/package-calculator/components/summary'
import SummaryContent from '../../../components/package-calculator/components/summary-content'
import {
  ActionZone,
  ButtonCalculator,
  Content,
  WidthContainer,
  SummaryDivMobile,
  ContentPart,
  IconCalculate,
  FullWidthContainer,
  ButtonBack,
  Title,
  Bar,
  ButtonFormAction,
  ActionZone2,
} from '../preview/styled'
import CalculationIcon from '../../../images/icon/CalculationIcon.png'
import SummaryToggleComponent from '../../../components/common/summary-toggle'


const { color } = theme
const SwitchWithLabel = ({ label, onChange }) => (
  <Div display={'flex'} flexDirection='row' alignItems='center'>
    {label && <H4 display={'block'} margin='0 8px' color={theme.color.primaryColor}>{label}</H4>}
    <Switch onChange={onChange} />
  </Div>
)

const PackageCreate = () => {
  const [step, setStep] = useState(1)
  const [isPreview, setIsPreview] = useState(false)
  const [isValidate, setIsValidate] = useState(true)
  const [formValues, setFormValues] = useState({
    coverPhoto: [],
    imagePhoto: [],
    name: '',
    description: '',
    amountPart: 1,
    minimumProduce: 500,
    incrementalSteps: 100,
    status: false,
    tag: [],
    partData: [{
      name: '',
      image: [],
      amountVariable: 1,
      width: 'a',
      length: 'a',
      requiredName: false,
      requiredWidth: false,
      requiredLength: false,
      gluing: 0,
      paper: [],
      defaultPaper: [],
      additionalService: [],
      isDieCut: 0,
    }],
  })
  const [callMutation, setCallMutation] = useState(false)

  const [formPreviewValues, setFormPreviewValues] = useState({
    packageFiles: [],
    name: '',
    description: '',
    amountPart: 1,
    tag: [],
    showPromotion: true,
    amountProduction: 0,
    productionType: 'newOrder',
    widthHoldingTax: false,
    graphicId: 0,
    moreDetail: '',
    incrementalSteps: 0,
    minimumManufacture: 1,
    graphicFile: [],
    partData: [],
    packageParts: [],
    partDetail: [],
    totalCost: [],
  })
  const [callCalculation, setCallCalculation] = useState(false)

  const [activeTab, setActiveTab] = useState(0)
  const onChangeTab = (key) => {
    setActiveTab(key)
  }

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 375)
  const [isMobileXL, setIsMobileXL] = useState(window.innerWidth <= 820)

  const screenSizeHandler = () => setIsMobileView(window.innerWidth <= 375)
  const screenSizeHandler2 = () => setIsMobileXL(window.innerWidth <= 820)

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    window.addEventListener('resize', screenSizeHandler2)
    return () => {
      window.removeEventListener('resize', screenSizeHandler)
      window.removeEventListener('resize', screenSizeHandler2)
    }
  }, [])

  const [disabled, setDisabled] = useState(false)
  const ContentRef = useRef(null)
  const [summaryActive, setSummaryActive] = useState(false)
  return (
      <Div display='flex' flexDirection='column'>
        <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
          <H4 bold color={color.primaryColor} margin='32px 0'>บรรจุภัณฑ์ใหม่ (New Packaging)</H4>
          <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
            {/* Setting Button at right */}
            <SwitchWithLabel label={'สถานะ'} onChange={checked => setFormValues({ ...formValues, status: checked })} />
            <br/>
            <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
              <Query
                query={LIST_PAPER}
                notifyOnNetworkStatusChange
                fetchPolicy='network-only'
                variables={{
                  limit: 10000,
                  offset: 0,
                }}
                >
                  {
                    ({ loading, data }) => {
                      if (loading) return <Button text={isPreview ? 'ยกเลิก' : 'ดูตัวอย่าง'} icon='fa fa-eye' ghost margin='0 16px 0 0' loading={true} />
                      if (!data.listPaper) return <p>error something</p>
                      return (
                      <Button
                        text={isPreview ? 'ยกเลิก' : 'ดูตัวอย่าง'}
                        icon='fa fa-eye'
                        ghost
                        margin='0 16px 0 0'
                        onClick={() => {
                          const findErrorFormula = []
                          formValues.partData.forEach((item) => {
                            const variables = Array.from(Array(item.amountVariable)).map((_e, i) => String.fromCharCode('a'.charCodeAt() + i))
                            const width = item.width.replaceAll('\\', '').replaceAll('left', '').replaceAll('right', '').replaceAll('cdot', '\\cdot')
                              .replaceAll('frac', '\\frac')
                            const length = item.length.replaceAll('\\', '').replaceAll('left', '').replaceAll('right', '').replaceAll('cdot', '\\cdot')
                              .replaceAll('frac', '\\frac')
                            try {
                              const formulaWidth = evaluatex(width)
                              const formulaLength = evaluatex(length)
                              const values = {}
                              variables.forEach((v) => { values[v] = 10 })
                              formulaWidth(values)
                              formulaLength(values)
                            } catch (e) {
                              findErrorFormula.push(item)
                            }
                          })
                          if (!_.isEmpty(findErrorFormula)) {
                            message.warning('สูตรไม่ถูกต้อง')
                            return
                          }
                          const tmpData = formPreviewValues
                          tmpData.name = formValues.name
                          tmpData.description = formValues.description
                          tmpData.amountPart = formValues.amountPart
                          tmpData.incrementalSteps = formValues.incrementalSteps
                          tmpData.minimumManufacture = formValues.minimumProduce === 0 ? 1 : formValues.minimumProduce
                          tmpData.tag = formValues.tag
                          const coverPphoto = formValues.coverPhoto.map(item => ({
                            packageFileUri: URL.createObjectURL(item),
                          }))
                          const imagePhoto = formValues.imagePhoto.map((item) => {
                            if (item.type === 'upload') {
                              return ({
                                packageFileUri: URL.createObjectURL(item.src),
                              })
                            }
                            return ({
                              packageFileUri: item.src,
                            })
                          })
                          tmpData.packageFiles = [...coverPphoto, ...imagePhoto]
                          const partData = formValues.partData.map((item) => {
                            const variables = Array.from(Array(item.amountVariable)).map((_e, i) => String.fromCharCode('a'.charCodeAt() + i))
                            const values = {}
                            variables.forEach((v) => { values[v] = null })
                            return ({
                              packagePartId: null,
                              packagePartName: item.name,
                              name: item.name,
                              packageData: {
                                name: formValues.name || '-',
                              },
                              measurementUnit: {
                                value: 1,
                                text: 'เซนติเมตร (ซม.)',
                                key: 'cm',
                                label: 'ซม.',
                              },
                              sizeList: values,
                              width: item.width.replaceAll('\\', '').replaceAll('left', '').replaceAll('right', '').replaceAll('cdot', '\\cdot')
                                .replaceAll('frac', '\\frac'),
                              length: item.length.replaceAll('\\', '').replaceAll('left', '').replaceAll('right', '').replaceAll('cdot', '\\cdot')
                                .replaceAll('frac', '\\frac'),
                              sampleImage: item.image.map(x => ({ packagePartFileUri: URL.createObjectURL(x.src) })),
                              printing: {
                                selectedPrinting: 0,
                                side: null,
                                printingId: null,
                                printingName: '',
                                printingType: 1,
                                printingTypeName: 'พิมพ์หน้าเดียว',
                                printingBasicColorId: 0,
                                printingBasicColorName: '',
                                checkExtraColor: false,
                                printingExtraColorId: [],
                              },
                              paperList: data.listPaper.paper && data.listPaper.paper.filter(x => item.paper.filter(p => p.active).map(y => y.key).includes(x.paperId)).sort((a, b) => a.paperId - b.paperId),
                              paper: {
                                paperId: 0,
                                name: '',
                                thickness: 0,
                                selectedThickness: 0,
                                coatingFrontIds: [],
                                coatingBackIds: [],
                              },
                              coating: {
                                side: 0,
                                coatingType: 'ไม่เคลือบ',
                                coatingFrontId: null,
                                coatingFrontName: '',
                                coatingBackId: null,
                                coatingBackName: '',
                              },
                              spotUV: {
                                side: 0,
                                spotUVType: 'ไม่เคลือบ',
                                spotUVFrontId: null,
                                spotUVFrontName: '',
                                spotUVBackId: null,
                                spotUVBackName: '',
                              },
                              selectedEmbossing: 0,
                              embossing: [],
                              foilingId: 0,
                              foilingName: '',
                              foiling: [],
                              selectedPlasticWindow: 0,
                              plasticWindow: [],
                              gluing: item.gluing,
                              additionalServiceId: item.additionalService,
                              dieCut: item.isDieCut === 1,
                              additionalService: [],
                            })
                          })
                          const findEmptyPaper = formValues.partData.filter(item => _.isEmpty(item.paper.filter(x => x.active)) && item)
                          if (!_.isEmpty(findEmptyPaper)) {
                            message.warning('ไม่สามารถดูพรีวิวได้ กรุณาเลือกประเภทกระดาษ')
                            return
                          }
                          tmpData.packageParts = partData
                          tmpData.partData = partData
                          setFormPreviewValues({ ...tmpData })
                          setIsPreview(!isPreview)
                        }}
                      />
                      )
                    }
                  }
                </Query>
              <Mutation
                mutation={CREATE_PACKAGE}
              >
                {
                    doCreatePackage => (
                        <Button
                          text='บันทึก'
                          icon='fa fa-floppy-o'
                          inverse
                          onClick={async () => {
                            setCallMutation(true)
                            if (formValues.name === '') {
                              message.warning('กรุณากรอกข้อมูลชื่อบรรจุภัณฑ์')
                              setCallMutation(false)
                              setIsValidate(false)
                              return
                            }

                            if (formValues.name.match(new RegExp(/^\s/)) !== null) {
                              message.warning('กรุณากรอกข้อมูลชื่อบรรจุภัณฑ์ให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
                              setCallMutation(false)
                              setIsValidate(false)
                              return
                            }

                            // Validate Input PartData
                            const tmpPartData = formValues.partData.map(item => ({
                              ...item,
                              requiredName: item.name === '' || (/\s/).test(item.name),
                              requiredWidth: item.width === '',
                              requiredLength: item.length === '',
                            }))
                            setFormValues({ ...formValues, partData: tmpPartData })

                            const findEmptyPart = formValues.partData.find(item => _.isEmpty(item.name) || _.isEmpty(item.width) || _.isEmpty(item.length))
                            const findSpacePartName = formValues.partData.find(item => item.name === ' ')
                            const findEmptyPaper = formValues.partData.filter(item => _.isEmpty(item.paper.filter(x => x.active)) && item)

                            if (!_.isEmpty(findSpacePartName)) {
                              message.error('ไม่สามารถสร้างชื่อชิ้นส่วนที่มีเว้นวรรคอย่างเดียว')
                              setCallMutation(false)
                              return
                            }
                            // validate formula
                            const findErrorFormula = []
                            formValues.partData.forEach((item) => {
                              const variables = Array.from(Array(item.amountVariable)).map((_e, i) => String.fromCharCode('a'.charCodeAt() + i))
                              const width = item.width.replaceAll('\\', '').replaceAll('left', '').replaceAll('right', '').replaceAll('cdot', '\\cdot')
                                .replaceAll('frac', '\\frac')
                              const length = item.length.replaceAll('\\', '').replaceAll('left', '').replaceAll('right', '').replaceAll('cdot', '\\cdot')
                                .replaceAll('frac', '\\frac')
                              try {
                                const formulaWidth = evaluatex(width)
                                const formulaLength = evaluatex(length)
                                const values = {}
                                variables.forEach((v) => { values[v] = 10 })
                                formulaWidth(values)
                                formulaLength(values)
                              } catch (e) {
                                findErrorFormula.push(item)
                              }
                            })
                            const status = formValues.status ? 'active' : 'inactive'
                            const input = {}
                            input.name = formValues.name
                            input.description = formValues.description.replace(' ', '&nbsp;')
                            input.status = (!_.isEmpty(findEmptyPart) || !_.isEmpty(findSpacePartName) || !_.isEmpty(findEmptyPaper) || !_.isEmpty(findErrorFormula)) ? 'inactive' : status
                            input.tagId = formValues.tag.map(item => item.tagId)
                            input.coverPhoto = formValues.coverPhoto
                            input.imagePhoto = formValues.imagePhoto
                            input.amountPart = formValues.amountPart
                            input.minimumProduce = formValues.minimumProduce
                            input.incrementalStep = formValues.incrementalSteps
                            input.parts = formValues.partData.map(item => ({
                              // packagePartId: null,
                              name: item.name,
                              image: item.image,
                              amountVariable: item.amountVariable,
                              width: item.width,
                              length: item.length,
                              gluingId: item.gluing === 0 ? null : item.gluing,
                              paperId: item.paper.filter(x => x.active).map(x => x.key),
                              additionalServiceId: item.additionalService.map(x => x.additionalServiceId),
                              isDieCut: item.isDieCut === 1,
                            }))
                            doCreatePackage({
                              variables: {
                                input,
                              },
                            }).then(({ data }) => {
                              if (data.createPackage) {
                                if (data.createPackage.success) {
                                  message.success(data.createPackage.message)
                                  setTimeout(() => {
                                    window.location = PATH.packageList
                                    setCallMutation(false)
                                  }, 500)
                                } else {
                                  if (data.createPackage.message === 'ชื่อ Package นี้มีอยู่ในระบบแล้ว') {
                                    setIsValidate(false)
                                    setCallMutation(false)
                                  }
                                  message.error(data.createPackage.message)
                                }
                              }
                            })
                          }}
                          disabled={callMutation}
                        />
                    )
                }
              </Mutation>
            </Div>
          </Div>
        </Div>
        {/* Content Add Packaging */}
        {
          isPreview ? <>
            {
              isMobileXL && <SummaryToggleComponent
                setSummaryActive={setSummaryActive}
                summaryActive={summaryActive}
              >
                <SummaryDivMobile>
                  <Summary
                    formValues={formPreviewValues}
                    activeTab={activeTab}
                    onChangeTab={onChangeTab}
                    defaultPrinting={true}
                    sticky={false}
                    step={step}
                    setStep={setStep}
                    style={{ maxWidth: '100%', top: 0 }}
                    setSummaryActive={setSummaryActive}
                    isShowGluing
                  />
                </SummaryDivMobile>
              </SummaryToggleComponent>
            }
            <FullWidthContainer>
              <div style={{ backgroundColor: '#FFFFFF' }}>
                <Detail data={formPreviewValues} />
              </div>
              {/* Preview Component */}
              <Content id="content-package" ref={ContentRef} step={step} data={formValues.partData && formValues.partData.length}>
                <WidthContainer>
                  <Div display='flex'>
                    {
                      step === 1 && formValues.partData.length > 0 && <ContentPart>
                        <Parts
                          data={formPreviewValues}
                          formValues={formPreviewValues}
                          setFormValues={setFormPreviewValues}
                          activeTab={activeTab}
                          onChangeTab={onChangeTab}
                          isMobileXL={isMobileXL}
                          disabled={disabled}
                          setDisabled={setDisabled}
                          ContentRef={ContentRef}
                          isShowGluing={true}
                        />
                      </ContentPart>
                    }
                    {
                      step === 2 && <Div>
                        <Bar>
                          <Div>
                            <ButtonBack
                              inverse
                              text='<< ย้อนกลับ'
                              onClick={() => setStep(1)}
                            />
                          </Div>
                          <Title>ข้อมูลโปรโมชัน และราคา</Title>
                          <Div>
                            {
                              !isMobileXL && <ButtonBack
                                inverse
                                text='<< ย้อนกลับ'
                                style={{ opacity: 0, cursor: 'unset' }}
                              />
                            }
                          </Div>
                        </Bar>
                        <Div display='flex' alignItems='center' justifyContent='center'>
                          <Div flexBasis={isMobileXL ? '100%' : '70%'}>
                            <SummaryContent
                              setFormValues={setFormPreviewValues}
                              formValues={formPreviewValues}
                              setStep={setStep}
                              isShowMore={true}
                            />
                          </Div>
                          {
                            !isMobileXL && <Div flexBasis='30%'>
                              <Summary
                                formValues={formPreviewValues}
                                activeTab={activeTab}
                                onChangeTab={onChangeTab}
                                defaultPrinting={true}
                                sticky
                                step={step}
                                setStep={setStep}
                                ContentRef={ContentRef}
                                setSummaryActive={setSummaryActive}
                                isShowGluing
                              />
                            </Div>
                          }
                        </Div>
                      </Div>
                    }
                  </Div>
                </WidthContainer>
              </Content>
              <WidthContainer>
              {
                step === 1 && <ActionZone><Query
                  query={PRICE_CALCULATOR}
                >
                  {
                    ({ refetch }) => (
                      <ButtonCalculator
                        inverse
                        buttonStyle='round'
                        loading={callCalculation}
                        disabled={callCalculation || disabled}
                        text={<React.Fragment><IconCalculate src={CalculationIcon} />คำนวณราคา</React.Fragment>}
                        onClick={async () => {
                          setCallCalculation(true)
                          const findNullSizePart = formPreviewValues.partData.map(item => Object.values(item.sizeList).filter(v => v === null || v === '' || v === undefined))
                          const findNullSize = Array.prototype.concat.apply([], findNullSizePart).filter(v => v === null || v === '' || v === undefined || v === 0)
                          const findEmptyEmbossing = formPreviewValues.partData.filter(item => (item.selectedEmbossing === 1 && item.embossing.length === 0) || (item.embossing.length > 0 && item.embossing.filter(x => x.width === null || x.lengths === null).length > 0))
                          const findEmptyFoiling = formPreviewValues.partData.filter(item => (item.foilingId > 0 && item.foiling.length === 0) || (item.foiling.length > 0 && item.foiling.filter(x => x.width === null || x.lengths === null).length > 0))
                          const findEmptyPlastic = formPreviewValues.partData.filter(item => (item.selectedPlasticWindow === 1 && item.plasticWindow.length === 0) || (item.plasticWindow.length > 0 && item.plasticWindow.filter(x => x.width === null || x.lengths === null).length > 0))
                          if (findNullSize.length > 0) {
                            message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                            setCallCalculation(false)
                            return
                          }
                          if (findNullSize.length > 0 || findEmptyEmbossing.length > 0 || findEmptyFoiling.length > 0 || findEmptyPlastic.length > 0) {
                            message.warning(`กรุณากรอกข้อมูล ${findNullSize.length > 0 ? 'ขนาดบรรจุภัณฑ์' : ''} ${findEmptyEmbossing.length > 0 ? 'ตำแหน่งการปั๊มนูน' : ''} ${findEmptyFoiling.length > 0 ? 'ตำแหน่งปั๊มฟอยล์-ทองเค' : ''} ${findEmptyPlastic.length > 0 ? 'ตำแหน่งการเจาะหน้าต่างและติดแผ่นใส' : ''} ให้ครบถ้วน`)
                            setCallCalculation(false)
                            return
                          }
                          const part = formPreviewValues.partData.map((item, index) => ({
                            order: index + 1,
                            packagePartId: null,
                            unit: item.measurementUnit.key,
                            operator: [],
                            printSizeWidth: evaluatex(item.width)(item.sizeList),
                            printSizeLength: evaluatex(item.length)(item.sizeList),
                            paper: {
                              paperId: item.paper.paperId,
                              thickness: parseFloat(item.paper.selectedThickness),
                            },
                            printing: item.printing.side && {
                              side: item.printing.printingType,
                              printingBasicColorId: item.printing.printingBasicColorId,
                              printingExtraColorId: item.printing.printingExtraColorId.filter(x => x.printingExtraColorId).map(x => x.printingExtraColorId),
                            },
                            coating: {
                              coatingIdFront: item.coating.side === 1 || item.coating.side === 2 ? item.coating.coatingFrontId : null,
                              coatingIdBehind: item.coating.side === -1 || item.coating.side === 2 ? item.coating.coatingBackId : null,
                            },
                            spotUV: {
                              spotUVIdFront: item.spotUV.spotUVFrontId,
                              spotUVIdBehind: item.spotUV.spotUVBackId,
                            },
                            embossing: item.embossing.map((x, i) => ({
                              order: i + 1,
                              width: parseFloat(x.width),
                              length: parseFloat(x.lengths),
                              unit: item.measurementUnit.key,
                            })),
                            foiling: {
                              foilingId: item.foilingId !== 0 ? item.foilingId : null,
                              foilingPart: item.foiling.map((x, i) => ({
                                order: i + 1,
                                width: parseFloat(x.width),
                                length: parseFloat(x.lengths),
                                unit: item.measurementUnit.key,
                              })),
                            },
                            dieCut: item.dieCut,
                            plasticWindow: item.plasticWindow.map((x, i) => ({
                              order: i + 1,
                              width: parseFloat(x.width),
                              length: parseFloat(x.lengths),
                              thickness: x.thickness,
                              unit: item.measurementUnit.key,
                            })),
                            gluingId: item.gluing || null,
                            additionalServiceId: item.additionalServiceId.map(x => x.additionalServiceId) || [],
                          }))
                          const input = {
                            part,
                          }
                          await refetch({
                            input,
                          }).then(async (res) => {
                            if (res.data.priceCalculator.success) {
                              setFormPreviewValues({
                                ...formPreviewValues,
                                partDetail: res.data.priceCalculator.partDetail,
                                totalCost: res.data.priceCalculator.totalCost,
                                costDetail: res.data.priceCalculator.costDetail,
                                partDataCalculate: part,
                                amountProduction: parseInt(formPreviewValues.minimumManufacture),
                              })
                              const element = document.getElementById('content-package')
                              if (element) {
                                setTimeout(async () => {
                                  setCallCalculation(false)
                                  await setStep(2)
                                  await element.scrollIntoView({
                                    behavior: 'smooth',
                                  })
                                }, 500)
                              }
                            } else if (res.data.priceCalculator.message === 'oversize') {
                              setCallCalculation(false)
                              message.warning('ไม่สามารถให้บริการผลิตขนาดดังกล่าวได้ เนื่องจากมีขนาดเกินเครื่องพิมพ์ ของทางเรา ขออภัยในความไม่สะดวก')
                            } else if (res.data.priceCalculator.message === 'ขออภัย ขนาดที่ระบุ ไม่สามารถผลิตบนกระดาษชนิดนี้ได้') {
                              setCallCalculation(false)
                              message.warning('ขออภัย ขนาดที่ระบุ ไม่สามารถผลิตบนกระดาษชนิดนี้ได้')
                            } else if (res.data.priceCalculator.message === 'ไม่สามารถคำนวณ Width ได้' || res.data.priceCalculator.message === 'ไม่สามารถคำนวณ Length ได้') {
                              setCallCalculation(false)
                              message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                            } else {
                              setCallCalculation(false)
                              message.success('สามารถให้บริการผลิตขนาดดังกล่าวได้')
                            }
                          }).catch((e) => {
                            if (e.toString().includes('status code 400')) {
                              setCallCalculation(false)
                              message.warning('กรุณากรอกข้อมูลให้ครบถ้วน')
                            } else {
                              setCallCalculation(false)
                              message.error('ไม่สามารถคำนวนราคาได้')
                              setStep(1)
                            }
                          })
                        }}
                      />
                    )
                  }
                </Query></ActionZone>
              }
              {
                step === 2 && <ActionZone2>
                    <ButtonFormAction
                      text='ย้อนกลับ'
                      onClick={async () => {
                        const element = document.getElementById('content-package')
                        if (element) {
                          await setStep(1)
                          await element.scrollIntoView({
                            behavior: 'smooth',
                          })
                        }
                      }}
                      inverse
                      color={theme.color.secondaryColor}
                      buttonStyle="round"
                      rightIcon
                      activeGreen={step === 3}
                      icon={'far fa-chevron-double-left'}
                      style={{ marginLeft: !isMobileXL && '12px' }}
                    />
                  </ActionZone2>
              }
            </WidthContainer>
          </FullWidthContainer>
          </> : <PackageForm
            formValues={formValues}
            setFormValues={setFormValues}
            isValidate={isValidate}
            setIsValidate={setIsValidate}
          />
        }
      </Div>
  )
}

export default PackageCreate
