import React, { useState } from 'react'
import { Query, Mutation } from 'react-apollo'
import { Link } from 'react-router-dom'
import {
  Button, H4, InputWithLabel, Icon,
} from 'konsys-ui'
import { message } from 'antd'
import validator from 'validator'
import { theme } from '../../styles/_variables'
import { passwordValidator, isEveryIndex } from '../../utils/util-services'
import history from '../../history'
import Login from '../../pages/login'
import { REQ_RESET_PASSWORD_TOKEN, RESET_PASSWORD } from '../../constants/graphql/mutation'
import { CHECK_TOKEN_RESET_PASSWORD } from '../../constants/graphql/query'
import {
  Wrapper, LoginBox,
} from './styled'
import PATH from '../../constants/path'

const { color } = theme

const ForgotPasswordSection = () => {
  const [email, setEmail] = useState('')

  return (
    <Wrapper>
      <LoginBox>
        <Link to={PATH.login}>
          <Button
            ghost
            color={color.coolGrey}
            icon='far fa-arrow-left'
            onlyIcon
            style={{ position: 'absolute', top: 40, left: 40 }}
          />
        </Link>
        <H4 color={color.paleGrey}>ลืมรหัสผ่าน</H4>
        <InputWithLabel
          width='70%'
          input={{
            ghost: true,
            placeholder: 'username@gmail.com',
            prefix: <Icon className='prefix' icon='far fa-user' />,
            color: color.coolGrey,
            onChange: e => setEmail(e.target.value),
          }}
          title={{
            text: 'อีเมล',
            color: color.paleGrey,
          }}
          margin='16px 0 32px 0'
        />
        <Mutation
          mutation={REQ_RESET_PASSWORD_TOKEN}
        >
        {(doRequestToken, { loading }) => (
          <Button
            ghost
            color={color.primaryColor}
            inverse
            text='ขอรหัสผ่านใหม่'
            loading={loading}
            disabled={!validator.isEmail(email)}
            onClick={() => doRequestToken({
              variables: { email },
            }).then((resp) => {
              if (!resp.loading && resp.data.forgetPassword.success) {
                message.success(resp.data.forgetPassword.message)
                setTimeout(() => {
                  history.push('/')
                }, 3000)
                history.push('/')
              } else message.error(resp.data.forgetPassword.message)
            })}
          />
        )}
        </Mutation>
      </LoginBox>
    </Wrapper>
  )
}

const ResetPassword = props => (
  <Query
    query={CHECK_TOKEN_RESET_PASSWORD}
    variables={{
      token: props.match.params.token,
    }}
  >
    {
      (res) => {
        if (!res.data.checkToken) {
          return <Login/>
        }
        return <ResetSection props={props}/>
      }
    }
  </Query>
)

const ResetSection = ({ props }) => {
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const validatePassword = passwordValidator(password) ? 'success' : password.length > 0 && 'error'
  const validateConfirmPassword = passwordConfirm.length > 0 && passwordConfirm === password ? 'success' : passwordConfirm.length > 0 && 'error'

  const checkInput = (c, m) => {
    switch (m) {
      case 0: if (!/\s/.test(c)) {
        setPassword(c)
      } break
      case 1: if (!/\s/.test(c)) {
        setPasswordConfirm(c)
      } break
      default:
    }
  }

  return (
    <Wrapper>
      <LoginBox>
      <H4 color={color.paleGrey}>เปลี่ยนรหัสผ่าน</H4>
      <InputWithLabel
        width='70%'
        input={{
          value: password,
          prefix: <Icon className='prefix' icon='far fa-key' />,
          type: 'password',
          color: color.coolGrey,
          onChange: e => checkInput(e.target.value, 0),
          state: !passwordValidator(password) && password.length > 0 && 'error',
          message: !passwordValidator(password) && password.length > 0 && 'รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัว และประกอบไปด้วยภาษาอังกฤษ ตัวเลข หรือสัญลักษณ์',
        }}
        title={{
          text: 'รหัสผ่านใหม่',
          color: color.paleGrey,
        }}
        margin='16px 0 16px 0'
      />
      <InputWithLabel
        width='70%'
        input={{
          value: passwordConfirm,
          prefix: <Icon className='prefix' icon='far fa-key' />,
          type: 'password',
          color: color.coolGrey,
          onChange: e => checkInput(e.target.value, 1),
          state: passwordConfirm !== password && passwordConfirm.length > 0 && 'error',
          message: passwordConfirm !== password && passwordConfirm.length > 0 && 'รหัสผ่านไม่ตรง',
        }}
        title={{
          text: 'ยืนยันรหัสผ่าน',
          color: color.paleGrey,
        }}
        margin='0 0 32px 0'
      />
      <Mutation
        mutation={RESET_PASSWORD}
      >
      {(doResetpassword, { loading }) => (
        <Button
          ghost
          inverse
          text='เปลี่ยนรหัสผ่าน'
          disabled={!isEveryIndex([validatePassword, validateConfirmPassword], 'success')}
          loading={loading}
          onClick={() => doResetpassword({
            variables: {
              token: props.match.params.token,
              newPassword: password,
            },
          }).then((resp) => {
            if (!resp.loading && resp.data.resetPassword.success) {
              message.success(resp.data.resetPassword.message)
              setTimeout(() => {
                history.push('/')
              }, 3000)
            } else message.error(resp.data.resetPassword.message)
          })}
        />
      )}
      </Mutation>
      </LoginBox>
    </Wrapper>
  )
}
export { ForgotPasswordSection, ResetSection, ResetPassword }
