import styled from 'styled-components'
import { Select as SelectAnt } from 'antd'
import { Button } from 'konsys-ui'
import { theme } from '../../../../../styles/_variables'


export const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`

export const ButtonAddRow = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 100%;
  height: 30px;
  i {
    margin: 0px !important;
  }
`

export const MultiSelectBoxContainer = styled.div`
  display: flex;
  align-items: center;
`

export const MultiSelectBoxWrapper = styled.div`
  width: 100%;
  > div{
    background: ${theme.color.offWhite};
    border: 1px solid rgba(${theme.color.primaryColorRGB}, 0.3);
    > p{
      color: ${theme.color.textColor};
    }
  }
  .ant-select-selection {
    border-radius: 5px;
    border-color: ${theme.color.grey};
    color: ${theme.color.textColor};
    background-color: rgba(255, 255, 255, 0.9);
  }
  .ant-select-selection__rendered {
    text-align: center;
  }
  .ant-select-selection__placeholder {
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
    text-align: center;
  }
  .ant-select-selection-selected-value {
    color: ${theme.color.textColor}
    font-size: ${theme.fonts.size.extraNormal};
    font-weight: ${theme.fonts.style.medium};
    text-align: center;
    width: 100%;
    margin-top: -4px;
  }
  .ant-select-arrow {
    color: ${theme.color.textColor};
  }
`

export const ButtonAction = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 10px 9.6px;
  width: 25px;
  height: 25px;
  border-color: ${theme.color.greenPrimary};
  background: ${theme.color.greenPrimary};
  i {
    font-size: 14px !important;
    margin: 2px 0px 0px 0px !important;
  }
`


export const LabelPosition = styled.span`
  font-size: ${theme.fonts.size.small};
  background: ${theme.color.greenPrimary};
  color: ${theme.color.offWhite};
  padding: 4px 12px;
  border-radius: 16px;
  width: 82px;
  text-align: center;
  height: fit-content;
  align-items: center;
`

export const Span = styled.span`
  font-size: ${theme.fonts.size.small};
`

export const SelectBoxContainer = styled.div`
  display: flex;
  border: 1px solid rgba(${theme.color.primaryColorRGB}, 0.3);
  flex-direction: column;
  row-gap: 8px;
  padding: 8px;
  border-radius: 5px;
  justify-content: space-between;
`

export const SelectBox = styled(SelectAnt)`
  width: 100%;
  .ant-select-selection {
    border-radius: 5px;
    border-color: ${theme.color.grey};
    color: ${theme.color.textColor};
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid  rgba(217, 217, 217, 1);
  }
  .ant-select-selection__rendered {
    text-align: center;
  }
  .ant-select-selection__placeholder {
    font-size: ${theme.fonts.size.small};
    font-weight: ${theme.fonts.style.medium};
    text-align: center;
  }
  .ant-select-selection-selected-value {
    color: ${theme.color.textColor}
    font-size: ${theme.fonts.size.small};
    font-weight: ${theme.fonts.style.medium};
    text-align: center;
    width: 100%;
  }
  .ant-select-arrow {
    color: ${theme.color.textColor};
  }
`

export default MultiSelectBoxContainer
