import React from 'react'
import {
  Div,
} from 'konsys-ui'
import ReactQuill from 'react-quill'
import {
  Label, DivToolbarEditor,
} from './styled'
import 'react-quill/dist/quill.snow.css'


const editorModules = {
  toolbar: {
    container: '#toolbar',
  },
}

const editorFormats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'link', 'image', 'video',
]

const CustomToolbar = () => (
    <DivToolbarEditor id="toolbar">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-strike"></button>
    </DivToolbarEditor>
)
const PaperDescriptionInput = ({
  onInputChange,
  data,
}) => (
  <Div>
    <Label>คำอธิบาย</Label>
    <CustomToolbar/>
    <Div className='custom-editor'>
      <ReactQuill
        theme="snow"
        defaultValue={data.description}
        value={data.description}
        formats={editorFormats}
        modules={editorModules}
        onChange={v => onInputChange({ ...data, description: v })}
      />
    </Div>
  </Div>
)

export default PaperDescriptionInput
