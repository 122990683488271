import React, { useState } from 'react'
import {
  WidthContainer, FullWidthContainer,
} from './styled'
import SpotUVTitleInput from '../title'
import SpotUVDescriptionInput from '../description'
import SpotUVCoverImageInput from '../cover-image'
import SpotUVSampleVideoInput from '../sample-video'
import SpotUVSampleImageInput from '../sample-image'
import SpotUVCostTable from '../cost-table'
import SpotUVCostMinimumInput from '../cost-minimum'


const SpotUVForm = ({
  formValues,
  setFormValues,
  isValidate,
  setIsValidate,
  action,
}) => {
  const [, setDisabledButton] = useState(true)
  return (
    <>
      <WidthContainer>
        <SpotUVTitleInput
          label={'ชื่อวิธีการเคลือบเฉพาะจุด'}
          data={formValues}
          onInputChange={setFormValues}
          setDisabledButton={setDisabledButton}
          setIsValidate={setIsValidate}
          isValidate={isValidate}
        />
        <SpotUVDescriptionInput
          data={formValues}
          onInputChange={setFormValues}
        />
        <SpotUVCoverImageInput
          data={formValues}
          newFileList={formValues.coverPhoto || []}
          setNewFileList={setFormValues}
        />
        <SpotUVSampleVideoInput
          data={formValues}
          newFileList={formValues.sampleVideo || []}
          setNewFileList={setFormValues}
        />
        <SpotUVSampleImageInput
          data={formValues}
          newFileList={formValues.imagePhoto || []}
          setNewFileList={setFormValues}
        />
      </WidthContainer>
      <FullWidthContainer>
        <SpotUVCostTable
          data={formValues}
          onChange={setFormValues}
        />
        <SpotUVCostMinimumInput
          data={formValues}
          onInputChange={setFormValues}
          action={action}
        />
      </FullWidthContainer>
    </>
  )
}

export default SpotUVForm
