import styled from 'styled-components'
import { theme, media } from '../../../../styles/_variables'

export const WidthContainer = styled.div`
  width: 60%;
  min-width: 300px;
`
export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`
export const FilePanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export const DivideText = styled.h4`
  font-weight: 700;
  font-size: 32px;
  margin: 8px 0;
  color: ${theme.color.primaryColor};
  padding: 0px 16px;
`
export const PreviewImageBox = styled.div`
  width: 86px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.desktop`
    width: 96px;
    height: 96px;
  `}
  ${media.laptopL`
    width: 80px;
    height: 80px;
  `}
  ${media.laptop`
    width: 64px;
    height: 64px;
  `}
`

export const PreviewImage = styled.img`
  max-height: 86px;
  max-width 86px;
  border-radius: 1px;
`
export const FullWidthContainer = styled.div`
  margin-top: 32px;
  width: 100%;
`
export const PartSection = styled.div`
  background-color: ${theme.color.offWhite};
  padding: 18px;
  border-radius: 10px;
  max-width: 800px;
  margin-bottom: 12px;
`
export const PartLabel = styled.div`
  background-color: ${theme.color.primaryColor};
  color: ${theme.color.offWhite};
  padding: 0px 24px;
  border-radius: 20px;
  display: inline-block;
  width: fit-content;
  position: relative;
  font-weight: ${theme.fonts.style.medium};
  font-size: 24px;
`
export const PartTitleLabel = styled.h4`
  font-weight: 700;
  font-size: 24px;
  margin-top: 16px;
  margin-bottom: 8px;
  color: ${theme.color.primaryColor};
`

export const DivToolbarEditor = styled.div`
    width: fit-content;
    background-color: ${theme.color.offWhite};
    margin-bottom: 16px;
    border-radius: 3px;
`

export const DivContentAmountPart = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 0;
    width: auto;
`

export const DivImage = styled.div`
  width: fit-content;
  margin: 0 auto;
`

export const DivImageAction = styled.div`
  padding: 16px 0px;
`

export const PreviewCoverImage = styled.img`
  width: 160px;
  // position: relative;
  border-radius: 1px;
  margin-bottom: 24px;
`
export const MathField = styled.div`
  outline: 0 !important;
  font-family: Symbola;
  font-weight: 200;
  font-size: 18px;
  line-height: 134px;
  height: 134px;
  border: 1px solid #C0BFC1;
  border-radius: 4px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 6px 8px 6px 8px;
  box-sizing: border-box;
  width: 100%;
  border-color: ${props => props.statusColor};
  overflow-x: auto;
  height: 120px;
`

export const FormulaDiv = styled.div`
  display: flex;
  margin-bottom: 8px;
`

export const VariableLabel = styled.div`
  font-size: 24px;
`

export default WidthContainer
