const PATH = {
  home: '/home',
  login: '/login',
  quotationCreate: '/order-create',
  quotationPreDefinedCreate: '/order-pre-defined-create',
  quotationPreDefinedEditItem: '/order-pre-defined-edit',
  quotationPreDefinedPriceItem: '/order-pre-defined-price',
  quotationList: '/order-list',
  quotationDetail: '/order',
  information: '/printing-information',
  gallery: '/gallery',
  request: '/quotation-request',
  payment: '/payment-verification',
  forgetPassword: '/forget-password',
  resetPassword: '/reset-password/:token',
  landing: '/landing',
  users: '/users',
  homeSetting: '/home-setting',
  popupSetting: '/popup-setting',
  promotionList: '/promotion-list',
  promotionCreate: '/promotion-create',
  promotionDetail: '/promotion-detail',
  packageList: '/package-list',
  packageCreate: '/package-create',
  packageDetail: '/package',
  packagePreview: '/package-preview',
  priceCalculator: '/price-calculator',
  packageSetting: '/package-setting',
  coatingSetting: '/package-setting/coating',
  coatingCreate: '/package-setting/coating-create',
  coatingDetail: '/package-setting/coating',
  spotUVSetting: '/package-setting/spot-uv',
  spotUVCreate: '/package-setting/spot-uv-create',
  spotUVDetail: '/package-setting/spot-uv',
  paperSetting: '/package-setting/paper',
  paperCreate: '/package-setting/paper-create',
  paperDetail: '/package-setting/paper',
  gluingSetting: '/package-setting/gluing',
  gluingCreate: '/package-setting/gluing-create',
  gluingDetail: '/package-setting/gluing',
  reservationPackaging: '/package-setting/reservation-packaging',
  extraServiceSetting: '/package-setting/extra-service',
  extraServiceCreate: '/package-setting/extra-service-create',
  extraServiceDetail: '/package-setting/extra-service',
  marginRateSetting: '/package-setting/margin-rate',
  tagSetting: '/package-setting/tag',
  printingSetting: '/package-setting/printing',
  dieCuttingSetting: '/package-setting/die-cutting',
  shippingSetting: '/package-setting/shipping',
  embossingSetting: '/package-setting/embossing',
  plasticSetting: '/package-setting/plastic-window',
  plasticCreate: '/package-setting/plastic-window/thickness-create',
  plasticDetail: '/package-setting/plastic-window/thickness',
  foilingSetting: '/package-setting/foiling',
  foilingCreate: '/package-setting/foiling-create',
  foilingDetail: '/package-setting/foiling',
  graphicSetting: '/package-setting/graphic',
  graphicCreate: '/package-setting/graphic-create',
  graphicDetail: '/package-setting/graphic',
  priceNoteSetting: '/package-setting/price-note',
  quotationSubmited: '/quotation-submitted',
}

export default PATH
