import styled from 'styled-components'
import { theme, media } from '../../../../styles/_variables'

export const FullWidthContainer = styled.div`
  width: 100%;
  margin-left: 0%;
  justify-content: center;
  position: relative;
`

export const ContentParts = styled.div`
  max-width: 100%;
  width: 100%;
  margin-left: 0%;
  z-index: 10;
`

export default FullWidthContainer
