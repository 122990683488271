import styled from 'styled-components'
import { Button } from 'konsys-ui'
import { theme } from '../../../../../styles/_variables'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonActionPlus = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 10.8px
  width: 24px;
  height: 24px;
  border-color: ${theme.color.greenPrimary};
  background: ${theme.color.greenPrimary};
  margin-right: 4px;
  i {
    font-size: 14px !important;
    margin: 2px 0px 0px 0px !important;
  }
`

export const ButtonActionMinus = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 10.4px
  width: 24px;
  height: 24px;
  border-color: ${theme.color.greenPrimary};
  background: ${theme.color.greenPrimary};
  margin-right: 4px;
  i {
    font-size: 14px !important;
    margin: 2px 0px 0px 0px !important;
  }
`

export default Container
