import styled from 'styled-components'
import { Select as AntSelect } from 'antd'
import { theme, media } from '../../../../../styles/_variables'


export const SelectBoxContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 4px 15px 4px 15px;
  background-color: ${theme.color.greenPrimary};
  border-radius: 5px;
  // box-shadow: ${props => (props.center ? 'none;' : '2px 2px 0px 1px rgb(110, 201, 190, 0.4) !important;')}
`

export const Select = styled(AntSelect)`
  width: ${props => (props.center ? '40%;' : '100%;')}
  margin-left: ${props => (props.center ? '40px;' : '0px;')}
  .ant-select-selection {
    border-radius: 5px;
    border: 1px solid ${props => (props.white ? '#d1d1d1' : '#EFFFFE')} !important;
    background-color: ${props => (props.white ? '#FFFFFF' : '#EFFFFE')} !important; !important;
    color: ${theme.color.greyNormal};
    padding: 2px 0px;
  }
  .ant-select-selection__rendered {
    text-align: center;
  }
  .ant-select-selection__placeholder {
    font-size: ${theme.fonts.size.normal};
    font-weight: ${theme.fonts.style.medium};
    text-align: center;
  }
  .ant-select-selection-selected-value {
    color: ${theme.color.greyNormal};
    font-size: ${theme.fonts.size.normal};
    font-weight: ${props => (props.thin ? theme.fonts.style.light : theme.fonts.style.medium)};
    text-align: center;
    width: 100%;
    margin-top: -2px;
  }
  .ant-select-arrow {
    color: rgba(${theme.color.primaryColorRGB}, 1);
    top: 40% !important;
    opacity: 0.6;
  }

`

export const Title = styled.p`
  width: ${props => (props.center ? 'fit-content;' : '100%;')}
  font-size: ${theme.fonts.size.extraNormal};
  font-weight: ${theme.fonts.style.medium};
  color: ${theme.color.offWhite}
  margin-bottom: 0;
  text-align: ${props => (props.center ? 'right' : props.centerTitle ? 'center' : 'left')}
`
export default Select
