import styled from 'styled-components'

export const WidthContainer = styled.div`
  margin-top: 32px;
  width: 60%;
  min-width: 300px;
`

export const QuaterFullWidthContainer = styled.div`
  margin-top: 32px;
  width: 60%;
`

export const FullWidthContainer = styled.div`
  margin-top: 32px;
  width: 100%;
`

export default WidthContainer
