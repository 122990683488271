import React from 'react'
import { Icon } from 'konsys-ui'
import parseHtml from 'html-react-parser'
import { theme } from '../../../../../styles/_variables'
import {
  Label, TitleWrapper, DescriptionWrapper,
  Container,
} from './styled'

const Recommendation = ({ value }) => (
  <Container>
    <TitleWrapper>
      <Icon icon='far fa-question-circle' color={theme.color.secondaryColor} />
      <Label>คำแนะนำ</Label>
    </TitleWrapper>
    <DescriptionWrapper>
      {value && parseHtml(value)}
    </DescriptionWrapper>
  </Container>
)

export default Recommendation
