import React, { useState } from 'react'
import { Mutation, Query } from 'react-apollo'
import { Link } from 'react-router-dom'
import { message } from 'antd'
import {
  Div, H4, Button, LoadingIcon,
} from 'konsys-ui'
import { EDIT_PRINTING, ADD_PRINTING } from '../../../constants/graphql/mutation'
import { GET_PRINTING_DETAIL } from '../../../constants/graphql/query'
import { theme } from '../../../styles/_variables'
import PrintingForm from './components/printing-form'
import PATH from '../../../constants/path'
import RecommendationSetting from '../recommendation-setting'
import BreadCrumb from '../../../components/common/breadcrumb'

const { color } = theme

const generateRowsTable = (boundaries = [
  { start: 1, end: 1, step: 0 },
  { start: 500, end: 2000, step: 500 },
  { start: 3000, end: 10000, step: 1000 },
]) => {
  const rows = []
  let n = 0
  let index = 0
  for (let i = 0; i < boundaries.length; i += 1) {
    const { start, end, step } = boundaries[i]
    let isStart = false
    while (n < end) {
      if (!isStart) {
        n = start
        isStart = true
      } else {
        n += step
      }
      rows.push({
        key: index,
        quantity: n,
        costPrintMachine3: {
          moreThan160: 0,
          lessThanEqual160: 0,
        },
        costPrintMachine5: {
          moreThan160: 0,
          lessThanEqual160: 0,
        },
      })
      index += 1
    }
  }
  return rows
}

const PrintingSetting = () => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    printingId: null,
    name: '',
    description: '',
    printingType: [{
      name: 'เครื่องพิมพ์ตัด 5',
      size: {
        width: 0,
        height: 0,
      },
    }, {
      name: 'เครื่องพิมพ์ตัด 3',
      size: {
        width: 0,
        height: 0,
      },
    }],
    printingBasicColor: [],
    printingExtraColor: [],
    printingPlateColorCost: [],
    printingOperationCostOneColor: generateRowsTable(),
    printingOperationCostFewColors: generateRowsTable(),
    printingOperationCostExtraColor: generateRowsTable(),
  })
  return (
    <Div display='flex' flexDirection='column'>
      <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
          <H4 bold color={color.primaryColor} margin='32px 0'>
              <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                <H4 bold color={color.primaryColor}>Catalog Settings</H4>
              </Link> {' > '}
              <Link to='/package-setting/printing' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
              <H4 bold color={color.primaryColor}>การพิมพ์</H4>
              </Link>
          </H4>
          <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
          <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
            <Mutation
              mutation={formValues.printingId ? EDIT_PRINTING : ADD_PRINTING}
            >
              {
                doPrinting => (
                  <Button
                    text='บันทึก'
                    icon='fa fa-save'
                    inverse
                    margin='0 16px 0 0'
                    onClick={() => {
                      setCallMutation(true)
                      const printingId = {}
                      if (formValues.printingId) {
                        printingId.printingId = formValues.printingId
                      }
                      const printingOperationCost = [...formValues.printingOperationCostOneColor.map(item => ({
                        type: 'print_one_color',
                        quantity: parseInt(item.quantity, 10),
                        costPrintMachine5LessThanEqual160: parseInt(item.costPrintMachine5.lessThanEqual160, 10),
                        costPrintMachine5MoreThan160: parseInt(item.costPrintMachine5.moreThan160, 10),
                        costPrintMachine3LessThanEqual160: parseInt(item.costPrintMachine3.lessThanEqual160, 10),
                        costPrintMachine3MoreThan160: parseInt(item.costPrintMachine3.moreThan160, 10),
                        ...printingId,
                      })), ...formValues.printingOperationCostFewColors.map(item => ({
                        printingId: formValues.printingId,
                        type: 'print_few_colors',
                        quantity: parseInt(item.quantity, 10),
                        costPrintMachine5LessThanEqual160: parseInt(item.costPrintMachine5.lessThanEqual160, 10),
                        costPrintMachine5MoreThan160: parseInt(item.costPrintMachine5.moreThan160, 10),
                        costPrintMachine3LessThanEqual160: parseInt(item.costPrintMachine3.lessThanEqual160, 10),
                        costPrintMachine3MoreThan160: parseInt(item.costPrintMachine3.moreThan160, 10),
                      })), ...formValues.printingOperationCostExtraColor.map(item => ({
                        printingId: formValues.printingId,
                        type: 'print_extra_color',
                        quantity: parseInt(item.quantity, 10),
                        costPrintMachine5LessThanEqual160: parseInt(item.costPrintMachine5.lessThanEqual160, 10),
                        costPrintMachine5MoreThan160: parseInt(item.costPrintMachine5.moreThan160, 10),
                        costPrintMachine3LessThanEqual160: parseInt(item.costPrintMachine3.lessThanEqual160, 10),
                        costPrintMachine3MoreThan160: parseInt(item.costPrintMachine3.moreThan160, 10),
                      }))]
                      if (formValues.name === '') {
                        message.warning('กรุณากรอกข้อมูลชื่อการพิมพ์')
                        setCallMutation(false)
                        setIsValidate(false)
                        return
                      }

                      if (formValues.name.match(new RegExp(/^\s/)) !== null) {
                        message.warning('กรุณากรอกข้อมูลชื่อการพิมพ์ให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
                        setCallMutation(false)
                        setIsValidate(false)
                        return
                      }
                      const input = {
                        ...printingId,
                        name: formValues.name,
                        description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
                        printingType: formValues.printingType.map(item => ({
                          printingTypeId: formValues.printingTypeId,
                          printingId: formValues.printingId,
                          colorQuantity: parseInt(item.colorQuantity, 10),
                          name: item.name,
                          width: parseFloat(item.size.width, 10),
                          height: parseFloat(item.size.height, 10),
                        })),
                        printingBasicColor: formValues.printingBasicColor.map(item => ({
                          printingBasicColorId: item.printingBasicColorId,
                          printingId: formValues.printingId,
                          colorQuantity: parseInt(item.colorQuantity, 10),
                          name: item.name,
                        })),
                        printingExtraColor: formValues.printingExtraColor.map(item => ({
                          printingExtraColorId: item.printingExtraColorId,
                          printingId: formValues.printingId,
                          name: item.name,
                        })),
                        printingPlateColorCost: formValues.printingPlateColorCost.map(item => ({
                          printingPlateColorCostId: item.printingPlateColorCostId,
                          printingId: formValues.printingId,
                          colorQuantity: parseInt(item.colorQuantity, 10),
                          costPrintMachine5: parseInt(item.costPrintMachine5, 10),
                          costPrintMachine3: parseInt(item.costPrintMachine3, 10),
                        })),
                        printingOperationCost: [...printingOperationCost],
                      }
                      doPrinting({
                        variables: {
                          input,
                        },
                      }).then(({ data }) => {
                        if (data.editPrinting.success) {
                          setCallMutation(false)
                          message.success(data.editPrinting.message)
                          setTimeout(() => {
                            window.location = PATH.printingSetting
                          }, 350)
                        } else if (data.addPrinting.success) {
                          setCallMutation(false)
                          message.success(data.addPrinting.message)
                          setTimeout(() => {
                            window.location = PATH.printingSetting
                          }, 350)
                        } else {
                          setCallMutation(false)
                          if (data.editPrinting.message === 'ชื่อ การพิมพ์ นี้มีอยู่ในระบบแล้ว') {
                            setIsValidate(false)
                            setCallMutation(false)
                          } else if (data.addPrinting.message === 'ชื่อ การพิมพ์ นี้มีอยู่ในระบบแล้ว') {
                            setIsValidate(false)
                            setCallMutation(false)
                          }
                          if (data.editPrinting.message) message.error(data.editPrinting.message)
                          else message.error(data.addPrinting.message)
                        }
                        setCallMutation(false)
                      })
                    }}
                    disabled={callMutation}
                    loading={callMutation}
                  />
                )
              }
            </Mutation>
          </Div>
          </Div>
      </Div>
      <Query
        query={GET_PRINTING_DETAIL}
        notifyOnNetworkStatusChange
        onCompleted={async (data) => {
          if (data.getPrintingDetail.printing) {
            const tmpData = data.getPrintingDetail.printing
            const resultPrintingOperationCost = await tmpData.printingOperationCost.reduce((result, item) => {
              const tmpItem = {
                printingOperationCostId: item.printingOperationCostId,
                quantity: item.quantity,
                costPrintMachine3: {
                  moreThan160: item.costPrintMachine3MoreThan160,
                  lessThanEqual160: item.costPrintMachine3LessThanEqual160,
                },
                costPrintMachine5: {
                  moreThan160: item.costPrintMachine5MoreThan160,
                  lessThanEqual160: item.costPrintMachine5LessThanEqual160,
                },
              }
              if (item.type === 'print_one_color') {
                result.printingOperationCostOneColor.push({
                  index: result.printingOperationCostOneColor.length,
                  ...tmpItem,
                })
              } else if (item.type === 'print_few_colors') {
                result.printingOperationCostFewColors.push({
                  index: result.printingOperationCostFewColors.length,
                  ...tmpItem,
                })
              } else if (item.type === 'print_extra_color') {
                result.printingOperationCostExtraColor.push({
                  index: result.printingOperationCostExtraColor.length,
                  ...tmpItem,
                })
              }
              return result
            }, {
              printingOperationCostOneColor: [],
              printingOperationCostFewColors: [],
              printingOperationCostExtraColor: [],
            })
            if (resultPrintingOperationCost.printingOperationCostOneColor.length === 0) resultPrintingOperationCost.printingOperationCostOneColor = generateRowsTable()
            if (resultPrintingOperationCost.printingOperationCostFewColors.length === 0) resultPrintingOperationCost.printingOperationCostFewColors = generateRowsTable()
            if (resultPrintingOperationCost.printingOperationCostExtraColor.length === 0) resultPrintingOperationCost.printingOperationCostExtraColor = generateRowsTable()
            await setFormValues({
              ...formValues,
              success: true,
              printingId: tmpData.printingId,
              name: tmpData.name,
              description: tmpData.description,
              printingType: tmpData.printingType.map((item, index) => ({
                key: index,
                printingTypeId: item.printingTypeId,
                name: item.name,
                size: {
                  width: item.width,
                  height: item.height,
                },
              })),
              printingBasicColor: await tmpData.printingBasicColor.map((item, index) => ({
                key: index,
                printingBasicColorId: item.printingBasicColorId,
                name: item.name,
                colorQuantity: item.colorQuantity,
              })),
              printingExtraColor: await tmpData.printingExtraColor.map((item, index) => ({
                key: index,
                printingExtraColorId: item.printingExtraColorId,
                name: item.name,
              })),
              printingPlateColorCost: await tmpData.printingPlateColorCost.map((item, index) => ({
                key: index,
                printingPlateColorCostId: item.printingPlateColorCostId,
                colorQuantity: item.colorQuantity,
                costPrintMachine5: item.costPrintMachine5,
                costPrintMachine3: item.costPrintMachine3,
              })),
              ...resultPrintingOperationCost,
            })
            // setAction('edit')
          }
        }}
      >
      {
         ({ loading, data, refetch }) => {
           let printingRemark = null
           if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
           if (!data.getPrintingDetail.success) return <p>error something</p>
           if (data.getPrintingDetail.remark) printingRemark = data.getPrintingDetail.remark
           return (
            <>
              {/* <RecommendationSetting
                moduleName='printing'
                data={printingRemark}
                refetch={() => refetch()}
              /> */}
              {
                formValues.success && data.getPrintingDetail.success && <PrintingForm
                  formValues={formValues}
                  setFormValues={setFormValues}
                  isValidate={isValidate}
                  setIsValidate={setIsValidate}
                />
              }
            </>
           )
         }
      }
      </Query>
    </Div>
  )
}

export default PrintingSetting
