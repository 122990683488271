import React from 'react'
import {
  Popconfirm,
} from 'antd'
import {
  Div,
} from 'konsys-ui'
import { Area, TagDiv, DeleteButton } from './styled'

const TagArea = ({
  formValues,
  setFormValues,
}) => {
  const handleDeleteTag = (index) => {
    const tmpTag = formValues.tag
    tmpTag.splice(index, 1)
    setFormValues({ tag: tmpTag })
  }
  return (
    <Area display='flex' flexDirection='column' moreStyle={{ columnGap: '12px', flexWrap: 'wrap', rowGap: '12px' }}>
     {
       formValues.tag ? formValues.tag.map((item, index) => (
      <Div key={item.tagId} display='flex' flexDirection='row' moreStyle={{ columnGap: '12px', flexWrap: 'wrap', rowGap: '12px' }}>
        <TagDiv
          htmlType="submit"
          text={item.name}
          icon='fa fa-tag fa-rotate-90'
          inverse
        />
        <Popconfirm
          title='ยืนยันการรลบ Tag หรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'
          onConfirm={() => handleDeleteTag(index)}
          okText='ยืนยัน'
          cancelText='ยกเลิก'
        >
          <DeleteButton
            text='ลบ Tag นี้'
          />
        </Popconfirm>
      </Div>
       )) : null
    }
    </Area>
  )
}

export default TagArea
