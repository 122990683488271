import styled from 'styled-components'
import { theme } from '../../../../../styles/_variables'

export const WidthContainer = styled.div`
  width: 60%;
  min-width: 300px;
`
export const FullWidthContainer = styled.div`
  margin-top: 32px;
  width: 100%;
`

export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`

export default WidthContainer
