import styled from 'styled-components'
import { Tabs } from 'antd'

export const TabWrapper = styled(Tabs)`

`

export const TabPane = styled(Tabs.TabPane)`

`

export default TabWrapper
