import React from 'react'
import {
  Div, P, Icon,
} from 'konsys-ui'
import {
  TransferListWrapper,
  ItemWrapper,
  FlipIconWrapper,
} from './styled'
import { theme } from '../../../styles/_variables'

const Transfer = ({
  dataSource = [],
  onChange,
}) => {
  const handleToggle = (toggledItem) => {
    const toggledIndex = dataSource.findIndex(item => item.key === toggledItem.key)
    const tmp = [...dataSource]
    tmp[toggledIndex].active = !tmp[toggledIndex].active
    onChange(tmp)
  }

  const handleFlipList = () => {
    const tmp = [...dataSource]
    for (let i = 0; i < tmp.length; i += 1) {
      tmp[i].active = !tmp[i].active
    }
    onChange(tmp)
  }

  const TransferListItem = ({
    key,
    title,
    onClickIcon = () => {},
    icon,
    backgroundColorRGB = theme.color.secondaryColorRGB,
  }) => (
    <ItemWrapper key={key} backgroundColorRGB={backgroundColorRGB} >
      <P>{title}</P>
      <Div display='flex' alignItems='center' padding={'0px 10px'} cursor='pointer' onClick={onClickIcon}>
        <Icon fontSize={theme.fonts.size.normal} color={theme.color.offWhite} icon={icon} />
      </Div>
    </ItemWrapper>
  )

  return (
    <Div display='flex' flexDirection='row' moreStyle={{ columnGap: '5px' }}>
      <TransferListWrapper>
        {dataSource.filter(item => item.active).map(item => (
          <TransferListItem
            key={item.key}
            title={item.title}
            onClickIcon={() => handleToggle(item)}
            icon='far fa-times'
          />
        ))}
      </TransferListWrapper>
      <Div display='flex' moreStyle={{
        display: 'flex',
        alignItems: 'center',
      }}>
        <FlipIconWrapper>
          <Icon
            fontSize={theme.fonts.size.subTitle}
            fontWeight={theme.fonts.style.light}
            color={theme.color.secondaryColor}
            icon='fas fa-repeat'
            moreStyle={{ fontWeight: theme.fonts.style.extraLight }}
            onClick={() => handleFlipList()}
          />
        </FlipIconWrapper>
      </Div>
      <TransferListWrapper>
        { dataSource.filter(item => !item.active).map(item => (
          <TransferListItem
            key={item.key}
            title={item.title}
            onClickIcon={() => handleToggle(item)}
            icon='fal fa-plus'
            backgroundColorRGB={theme.color.textColorRGB}
          />
        ))
        }
      </TransferListWrapper>
    </Div>
  )
}

export default Transfer
