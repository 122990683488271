import React, { useState, useEffect } from 'react'
import { Div, LoadingIcon } from 'konsys-ui'
import { Query } from 'react-apollo'
import { Row, Col } from 'antd'
import { GET_EMBOSSING_DETAIL } from '../../../../constants/graphql/query'
import { theme } from '../../../../styles/_variables'
import NotchedContainer from '../../../common/notched-containner'
import OptionsList from '../common/options-list'
import OptionDescription from '../common/option-description'
import SampleImageList from '../common/sample-image-list'
import MultiSizeInput from '../common/multi-size-input'
import ForbiddenRed from '../../../../images/icon/Forbidden-red Icon.png'
import Correct from '../../../../images/icon/Correct Icon.png'
import EmbossingIcon from '../../../../images/icon/EmbossingIcon.png'
import Recommendation from '../common/recommendation'
import { CoverImage, DetailWrapper } from './styled'


const EmbossingSelector = ({
  formValues,
  setFormValues,
  measurementUnit,
  activeTab,
  disabled,
}) => {
  const [optionItems, setOptionItems] = useState([{
    id: 1,
    label: 'ไม่ใช้บริการ',
    icon: <CoverImage src={ForbiddenRed} />,
    checked: false,
    value: 0,
    index: 0,
  }, {
    id: 2,
    label: 'ใช้บริการ',
    icon: <CoverImage src={Correct} />,
    checked: false,
    value: 1,
    index: 1,
  }])
  const [embossingFormValues, setEmbossingFormValues] = useState([])
  const [embossing, setEmbossing] = useState(null)
  const [isUseService, setIsUseService] = useState(false)
  const [remark, setRemark] = useState('')

  const handleOnSelect = (value) => {
    if (value.value === 0) {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].selectedEmbossing = value.value
      tmpFormValues[activeTab].embossing = []
      setFormValues({ ...formValues, partData: tmpFormValues })
    } else {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].selectedEmbossing = value.value
      tmpFormValues[activeTab].embossing = formValues.partData[activeTab].embossing
      setFormValues({ ...formValues, partData: tmpFormValues })
    }
    setIsUseService(value.value === 1)
    setEmbossingFormValues(formValues.partData[activeTab].embossing)
  }

  const handleChangeSize = (value) => {
    const tmpFormValues = formValues.partData
    tmpFormValues[activeTab].embossing = value
    setFormValues({ ...formValues, partData: tmpFormValues, embossing: embossingFormValues })
    setEmbossingFormValues(value)
    setFormValues({ ...formValues })
  }

  useEffect(() => {
    if (formValues.partData[activeTab]) {
      const tmpOptions = optionItems.map(item => ({
        ...item,
        checked: item.value === formValues.partData[activeTab].selectedEmbossing,
      }))
      setOptionItems(tmpOptions)
      handleOnSelect(tmpOptions.find(item => item.value === formValues.partData[activeTab].selectedEmbossing))
      setEmbossingFormValues(formValues.partData[activeTab].embossing)
    }
  }, [activeTab])

  return <Div display='flex' flexDirection='column' moreStyle={{ rowGap: '20px' }}>
  <NotchedContainer
    title='การปั๊มนูน'
    titleIcon={EmbossingIcon}
  >
    <Row type='flex' gutter={16}>
      <Col xs={24} sm={11} md={11} span={16}>
        <Query
          query={GET_EMBOSSING_DETAIL}
          notifyOnNetworkStatusChange
          fetchPolicy='network-only'
          onCompleted={(data) => {
            const embossingData = data.getEmbossingDetail.embossing
            const sampleVideo = embossingData.embossingFiles.filter(file => file.type === 'video')
            if (embossingData) {
              setEmbossing({
                name: embossingData.name,
                description: embossingData.description,
                sampleImages: embossingData.embossingFiles.sort((a, b) => a.ordering - b.ordering).filter(file => file.type === 'sample'),
                sampleVideoUri: sampleVideo.length > 0 ? sampleVideo[0].embossingFileUri : null,
              })
              setRemark(embossingData.remark)
            }
          }}
        >
          {
            ({ loading, data, refetch }) => {
              if (loading) {
                return <Div moreStyle={{ width: '100%' }}>
                  <LoadingIcon color={theme.color.grey} />
                </Div>
              }
              return (
                <OptionsList
                  title='เลือกตัวเลือกที่ต้องการ'
                  options={optionItems}
                  onSelect={handleOnSelect}
                  disabled={disabled}
                />
              )
            }
          }
        </Query>
      </Col>
      <Col xs={24} sm={13} md={13} span={16}>
        <DetailWrapper>
          <OptionDescription
            title={isUseService ? embossing && embossing.name : 'ไม่ใช้บริการ'}
            description={isUseService ? embossing && embossing.description : ''}
            videoSrc={isUseService ? embossing && embossing.sampleVideoUri : null }
          />
          {
            isUseService && embossing && embossing.sampleImages.length > 0 && <SampleImageList
              title='รูปภาพตัวอย่าง'
              data={embossing.sampleImages.map(item => ({ fileUrl: item.embossingFileUri, ordering: item.ordering }))}
            />
          }
          {
            isUseService && embossing && embossing.sampleImages.length === 0 && <Div style={{ marginTop: '16px' }}></Div>
          }
          {
            isUseService ? <MultiSizeInput
              values={embossingFormValues}
              unit={measurementUnit}
              onChange={handleChangeSize}
              disabled={disabled}
            /> : null
          }
          {
            remark && <Recommendation
              value={remark.description}
            />
          }
        </DetailWrapper>
      </Col>
    </Row>
  </NotchedContainer>
</Div>
}


export default EmbossingSelector
