import React, { useState, useEffect } from 'react'
import { Button } from 'konsys-ui'
import {
  message,
} from 'antd'
import {
  RowsContainer,
  MultiSelectBoxWrapper,
} from './styled'
import SelectBox from '../select-box'
import RowContainer from '../row-container'


const MultiRowSelectBox = ({
  title,
  placeholder,
  defaultValues = [],
  optionsData = [],
  onChange,
  initTextButton = 'เพิ่มตัวเลือก',
  warningTextNoOptions = 'ไม่มีตัวเลือก',
  white,
  rows,
  setRows,
  toggleOption,
  activeTab,
}) => {
  useEffect(() => {
    if (defaultValues.length !== 0) {
      const tmpDefaultValues = [...defaultValues]
      setRows(tmpDefaultValues.map(v => ({ title, placeholder, value: v.value })), activeTab)
    }
  }, [])

  const onClickPlus = (index) => {
    const itemToInsert = { title, placeholder }
    const cloneRows = JSON.parse(JSON.stringify(rows))
    const tmp = [...cloneRows]
    if (optionsData.length === 0) {
      message.warning(warningTextNoOptions)
      return
    }
    if (tmp.length !== optionsData.length) {
      tmp.splice(index + 1, 0, itemToInsert)
      setRows(tmp, activeTab)
    }
  }
  const onClickMinus = (value, index) => {
    const cloneRows = JSON.parse(JSON.stringify(rows))
    const tmp = [...cloneRows]
    tmp.splice(index, 1)
    setRows(tmp, activeTab)
    toggleOption(value, activeTab)
    onChange(tmp, activeTab)
  }
  const onSelection = (value, index) => {
    const cloneRows = JSON.parse(JSON.stringify(rows))
    const tmp = [...cloneRows]
    tmp[index].value = value
    setRows(tmp, activeTab)
    onChange(tmp, activeTab)
  }
  const handleOnChange = (newValue, oldValue, index) => {
    onSelection(newValue, index)
    toggleOption(newValue, activeTab)
    toggleOption(oldValue, activeTab)
  }
  return (
    <RowsContainer>
      {
        rows.length > 0 ? rows.map((item, index, arr) => (
          <RowContainer
            key={index}
            index={index}
            item={item}
            length={arr.length}
            onClickPlus={onClickPlus}
            onClickMinus={onClickMinus}
            maxItem={optionsData.length}
          >
            <MultiSelectBoxWrapper>
              <SelectBox
                center={true}
                key={index}
                title={`${item.title} ${index + 1}`}
                placeholder={item.placeholder}
                options={optionsData}
                value={item.value}
                onChange={value => handleOnChange(value, item.value, index)}
                white={white}
              />
            </MultiSelectBoxWrapper>
          </RowContainer>
        ))
          : <Button
              buttonStyle='rectangle'
              text={initTextButton}
              onClick={onClickPlus}
            />
      }
    </RowsContainer>
  )
}

export default MultiRowSelectBox
