import React, { useState } from 'react'
import _ from 'lodash'
import {
  message,
} from 'antd'
import {
  Div,
} from 'konsys-ui'
import UploadFile from '../../../../../components/common/upload'
import {
  PreviewCoverVideo, Label,
} from './styled'


const CoatingSampleVideoInput = ({
  newFileList,
  setNewFileList,
  data,
}) => {
  const [src, setSrc] = useState(newFileList && newFileList.length === 1 ? newFileList[0].coatingFileUri ? newFileList[0].coatingFileUri : (newFileList.length === 1 && newFileList[0]) : '')
  return (
    <Div display='flex' flexDirection='column'>
      <Label>วิดีโอตัวอย่าง</Label>
      {
        newFileList.length === 1 && <PreviewCoverVideo controls key={src} >
          <source
            src={src}
            type="video/mp4"
          />
        </PreviewCoverVideo>
      }
      <UploadFile
          list={newFileList}
          id='uploadCoverFileId'
          multiple={false}
          onChange={(fileList) => {
            let upload = false
            const allowFileType = ['mp4']
            for (let i = 0; i < fileList.length; i += 1) {
              const splitName = fileList[i].name.split('.')
              if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
            || (newFileList.length > 0 && !_.isUndefined(_.find(newFileList, d => d.name === fileList[i].name)))) {
                upload = false
                break
              } else {
                upload = true
              }
            }
            if (upload) {
            // Set only one image to state
              setSrc(URL.createObjectURL(fileList[0]))
              setNewFileList({ ...data, sampleVideo: [...fileList] })
            } else {
              message.error('สามารถอัพโหลดได้เฉพาะไฟล์ mp4 และไม่สามารถอัพวิดีโอซ้ำได้')
            }
          }}
          onRemoveFile={(fileName) => {
            const newUploadFileList = [...newFileList]
            _.remove(newUploadFileList, v => v.name === fileName)
            setNewFileList({ ...data, sampleVideo: newUploadFileList })
          }}
          fileType="video/*"
          confirm={true}
          confirmText={'ยืนยันการลบวิดีโอหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'}
          fileKey={'coatingFileUri'}
      />
    </Div>
  )
}

export default CoatingSampleVideoInput
