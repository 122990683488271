import React, { useState } from 'react'
import {
  WidthContainer, FullWidthContainer, Label,
} from './styled'
import ShippingTable from '../shipping-table'

const ShippingForm = ({
  formValues,
  setFormValues,
}) => (
    <>
      <FullWidthContainer>
        <ShippingTable
          data={formValues}
          onChange={setFormValues}
        />
      </FullWidthContainer>
    </>
)

export default ShippingForm
