/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { Div, Button } from 'konsys-ui'
import { Query } from 'react-apollo'
import { message, Row, Col } from 'antd'
import evaluatex from 'evaluatex/dist/evaluatex'
import NotchedContainer from '../../../common/notched-containner'
import {
  InputWrapper, Input, DetailWrapper,
  Container, TextRecommend, ButtonConfirm,
  IconConfirm,
} from './styled'
import DimensionIconImage from '../../../../images/icon/DimensionIcon.png'
import PartGallery from '../part-gallery'
import UnitOfMeasure from '../unit-of-measure'
import { CHECK_PRINTER_SIZE } from '../../../../constants/graphql/query'
import ConfirmIcon from '../../../../images/icon/ConfirmIcon.png'


const PrintingSize = ({
  formValues,
  setFormValues,
  activeTab,
  setDisabled,
}) => {
  const [unitOptions, setUnitOptions] = useState({
    value: 1,
    text: 'เซนติเมตร (ซม.)',
    key: 'cm',
    label: 'ซม.',
  })
  const [printingSize, setPrintingSize] = useState({
    a: 0,
    b: 0,
    c: 0,
    d: 0,
    e: 0,
    f: 0,
    g: 0,
    h: 0,
    i: 0,
    j: 0,
  })

  const handleChange = (value, key) => {
    const reg = /^-?[0-9]*(\.[0-9]*)?$/
    if ((!Number.isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      const tmpFormValues = formValues.partData
      tmpFormValues[activeTab].sizeList[key] = value
      setFormValues({ ...formValues, partData: tmpFormValues })
      const tmp = { ...printingSize }
      tmp[key] = value
      setPrintingSize(tmp)
    }
  }

  const onChangeUnit = (value) => {
    const tmpFormValues = formValues.partData
    tmpFormValues[activeTab].measurementUnit = value
    setFormValues({ ...formValues, partData: tmpFormValues })
    setUnitOptions(value)
  }

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 375)
  const [isMobileXL, setIsMobileXL] = useState(window.innerWidth <= 570)

  const screenSizeHandler = () => setIsMobileView(window.innerWidth <= 375)
  const screenSizeHandler2 = () => setIsMobileXL(window.innerWidth <= 570)

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    window.addEventListener('resize', screenSizeHandler2)
    return () => {
      window.removeEventListener('resize', screenSizeHandler)
      window.removeEventListener('resize', screenSizeHandler2)
    }
  }, [])

  return <Div display='flex' flexDirection='column' moreStyle={{ rowGap: '20px' }}>
    <NotchedContainer
      title='ขนาดบรรจุภัณฑ์'
      titleIcon={DimensionIconImage}
    >
      <Row gutter={16} type="flex">
        <Col xs={24} sm={10} md={10} span={16}>
          <Container>
            <PartGallery image={formValues.partData[activeTab].sampleImage.sort((a, b) => a.ordering - b.ordering)} isMobileXL={isMobileXL}/>
          </Container>
        </Col>
        <Col xs={24} sm={14} md={14} span={16}>
          <DetailWrapper>
            <UnitOfMeasure
              data={formValues.partData[activeTab].measurementUnit}
              onInputChange={onChangeUnit}
            />
            <TextRecommend>
              หมายเหตุ: <br/>
              สามารถปรับแต่งรายละเอียดของบรรจุภัณฑ์ได้ตามต้องการ อาทิ การเจาะรู ความโค้งมนของมุมต่างๆ และขนาด-รูปร่างของลิ้นเสียบ เป็นต้น
            </TextRecommend>
            {
              (formValues.partData[activeTab].sizeList.hasOwnProperty('a') && formValues.partData[activeTab].sizeList.a >= 0) && <InputDimension
                title={'ความยาวด้าน A'}
                unit={formValues.partData[activeTab].measurementUnit.label}
                onChange={v => handleChange(v, 'a')}
                value={formValues.partData[activeTab].sizeList.a}
              />
            }
            {
              (formValues.partData[activeTab].sizeList.hasOwnProperty('b') && formValues.partData[activeTab].sizeList.b >= 0) && <InputDimension
                title={'ความยาวด้าน B'}
                unit={formValues.partData[activeTab].measurementUnit.label}
                onChange={v => handleChange(v, 'b')}
                value={formValues.partData[activeTab].sizeList.b}
              />
            }
            {
              (formValues.partData[activeTab].sizeList.hasOwnProperty('c') && formValues.partData[activeTab].sizeList.c >= 0) && <InputDimension
                title={'ความยาวด้าน C'}
                unit={formValues.partData[activeTab].measurementUnit.label}
                onChange={v => handleChange(v, 'c')}
                value={formValues.partData[activeTab].sizeList.c}
              />
            }
            {
              (formValues.partData[activeTab].sizeList.hasOwnProperty('d') && formValues.partData[activeTab].sizeList.d >= 0) && <InputDimension
                title={'ความยาวด้าน D'}
                unit={formValues.partData[activeTab].measurementUnit.label}
                onChange={v => handleChange(v, 'd')}
                value={formValues.partData[activeTab].sizeList.d}
              />
            }
            {
              (formValues.partData[activeTab].sizeList.hasOwnProperty('e') && formValues.partData[activeTab].sizeList.e >= 0) && <InputDimension
                title={'ความยาวด้าน E'}
                unit={formValues.partData[activeTab].measurementUnit.label}
                onChange={v => handleChange(v, 'e')}
                value={formValues.partData[activeTab].sizeList.e}
              />
            }
            {
              (formValues.partData[activeTab].sizeList.hasOwnProperty('f') && formValues.partData[activeTab].sizeList.f >= 0) && <InputDimension
                title={'ความยาวด้าน F'}
                unit={formValues.partData[activeTab].measurementUnit.label}
                onChange={v => handleChange(v, 'f')}
                value={formValues.partData[activeTab].sizeList.f}
              />
            }
            {
              (formValues.partData[activeTab].sizeList.hasOwnProperty('g') && formValues.partData[activeTab].sizeList.g >= 0) && <InputDimension
                title={'ความยาวด้าน G'}
                unit={formValues.partData[activeTab].measurementUnit.label}
                onChange={v => handleChange(v, 'g')}
                value={formValues.partData[activeTab].sizeList.g}
              />
            }
            {
              (formValues.partData[activeTab].sizeList.hasOwnProperty('h') && formValues.partData[activeTab].sizeList.h >= 0) && <InputDimension
                title={'ความยาวด้าน H'}
                unit={formValues.partData[activeTab].measurementUnit.label}
                onChange={v => handleChange(v, 'h')}
                value={formValues.partData[activeTab].sizeList.h}
              />
            }
            {
              (formValues.partData[activeTab].sizeList.hasOwnProperty('i') && formValues.partData[activeTab].sizeList.i >= 0) && <InputDimension
                title={'ความยาวด้าน I'}
                unit={formValues.partData[activeTab].measurementUnit.label}
                onChange={v => handleChange(v, 'i')}
                value={formValues.partData[activeTab].sizeList.i}
              />
            }
            {
              (formValues.partData[activeTab].sizeList.hasOwnProperty('j') && formValues.partData[activeTab].sizeList.j >= 0) && <InputDimension
                title={'ความยาวด้าน J'}
                unit={formValues.partData[activeTab].measurementUnit.label}
                onChange={v => handleChange(v, 'j')}
                value={formValues.partData[activeTab].sizeList.j}
              />
            }
            <Query
              query={CHECK_PRINTER_SIZE}
            >
              {
                ({ loading, data, refetch }) => (
                  <ButtonConfirm
                    inverse
                    buttonStyle='round'
                    text={<React.Fragment><IconConfirm src={ConfirmIcon} />ยืนยันขนาด</React.Fragment>}
                    onClick={async () => {
                      const findNullSize = Object.values(formValues.partData[activeTab].sizeList).filter(v => v === null || v === '' || v === undefined)
                      if (findNullSize.length > 0) {
                        message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                        setDisabled(true)
                        return
                      }
                      setDisabled(false)
                      const input = {
                        unit: formValues.partData[activeTab].measurementUnit.key,
                        width: !formValues.partData[activeTab].packagePartId ? evaluatex(formValues.partData[activeTab].width)(formValues.partData[activeTab].sizeList) : null,
                        length: !formValues.partData[activeTab].packagePartId ? evaluatex(formValues.partData[activeTab].length)(formValues.partData[activeTab].sizeList) : null,
                        operator: formValues.partData[activeTab].packagePartId ? Object.values(formValues.partData[activeTab].sizeList) : [],
                        packagePartId: formValues.partData[activeTab].packagePartId,
                      }
                      await refetch({
                        input,
                      }).then((res) => {
                        const result = res.data.printerSize
                        if (result.message === 'oversize') {
                          setDisabled(true)
                          message.warning('ไม่สามารถให้บริการผลิตขนาดดังกล่าวได้ เนื่องจากมีขนาดเกินเครื่องพิมพ์ ของทางเรา ขออภัยในความไม่สะดวก')
                        } else if (res.data.printerSize.message === 'ไม่สามารถคำนวณ Width ได้' || res.data.printerSize.message === 'ไม่สามารถคำนวณ Length ได้') {
                          setDisabled(true)
                          message.warning('ข้อมูลขนาดไม่ถูกต้อง กรุณาระบุขนาดใหม่อีกครั้ง')
                        } else {
                          setDisabled(false)
                          message.success('สามารถให้บริการผลิตขนาดดังกล่าวได้')
                        }
                      })
                    }}
                  />
                )
              }
            </Query>
          </DetailWrapper>
        </Col>
      </Row>
    </NotchedContainer>
  </Div>
}

const InputDimension = ({
  title,
  value,
  unit,
  onChange,
}) => (
  <InputWrapper>
    <p>{title}</p>
    <Input
      type='number'
      min={0}
      max={9999}
      value={value}
      onChange={onChange}
      onKeyPress={(e) => {
        if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
          e.preventDefault()
        }
      }}
    />
    <span>{unit}</span>
  </InputWrapper>
)


export default PrintingSize
