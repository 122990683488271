import React from 'react'
import {
  Div, H4,
} from 'konsys-ui'
import {
  InputNumber,
} from 'antd'
import {
  Label,
} from './styled'
import { theme } from '../../../../styles/_variables'


const { color } = theme
const InputNumberWithLabel = ({
  label,
  value,
  onInputChange,
  unit,
  min = 0,
  max = 100000,
  style,
}) => (
    <Div style={{ ...style }}>
    {label && <Label display={'block'} bold color={theme.color.primaryColor}>{label}</Label>}
    <Div display={'flex'} flexDirection='row'>
      <InputNumber
        style={{ width: '100%', fontSize: 'large' }}
        min={min}
        max={max}
        step={100}
        value={value}
        onChange={
          (e) => {
            const reg = /^-?[0-9]*(\.[0-9]*)?$/
            if ((!Number.isNaN(e) && reg.test(e)) || e === '' || e === '-') {
              onInputChange(Math.round(e / 100) * 100)
            }
          }
        }
        formatter={v => `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={v => v.replace(/(,*)|0*(\d+)/g, '')}
        />
        {unit && <H4 color={color.lightBlack} margin='0 0 0 15px'>{unit}</H4>}
    </Div>
    </Div>
)

const Manufacturer = ({
  data,
  setData,
  setDisabledButton,
  setIsValidate,
  isValidate,
}) => (
  <Div display={'flex'} flexDirection='row' >
      <InputNumberWithLabel
        label={'จำนวนขั้นต่ำในการผลิต'}
        value={data.minimumProduce}
        min={500}
        max={10000}
        onInputChange={(value) => {
          setData({ ...data, minimumProduce: value })
        }}
        setDisabledButton={setDisabledButton}
        setIsValidate={setIsValidate}
        isValidate={isValidate}
        unit={'ชิ้น'}
        onKeyPress={(e) => {
          if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
            e.preventDefault()
          }
        }}
      />
      <InputNumberWithLabel
        style={{ marginLeft: '30px' }}
        label={'Incremental Steps'}
        min={100}
        max={10000}
        value={data.incrementalSteps}
        onInputChange={(value) => {
          setData({ ...data, incrementalSteps: value })
        }}
        setDisabledButton={setDisabledButton}
        setIsValidate={setIsValidate}
        isValidate={isValidate}
        unit={'ชิ้น'}
        onKeyPress={(e) => {
          if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
            e.preventDefault()
          }
        }}
      />
  </Div>
)

export default Manufacturer
