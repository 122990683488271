export const PRINT_TECHNIQUE = [
  {
    text: 'พิมพ์ 1 สี',
    value: 1,
  }, {
    text: 'พิมพ์ 2 สี',
    value: 2,
  }, {
    text: 'พิมพ์ 4 สี',
    value: 3,
  }, {
    text: 'พิมพ์ 4 สี + สีพิเศษ',
    value: 4,
  }, {
    text: 'ไม่ทราบ',
    value: 5,
  },
]

export const PAPER = [
  {
    paper: {
      text: 'อาร์ตการ์ดหน้าเดียว',
      value: 1,
    },
    gram: ['210', '230', '250', '270', '300', '350', '400'],
  }, {
    paper: {
      text: 'อาร์ตการ์ดสองหน้า',
      value: 2,
    },
    gram: ['190', '210', '230', '260', '310', '360'],
  },
  {
    paper: {
      text: 'กล่องแป้งหน้าขาวหลังเทา',
      value: 3,
    },
    gram: ['270', '310', '350', '400', '450'],
  },
  {
    paper: {
      text: 'กล่องแป้งหน้าขาวหลังขาว',
      value: 4,
    },
    gram: ['270', '300', '350', '400', '450'],
  },
  {
    paper: {
      text: 'ฟอยล์',
      value: 5,
    },
    gram: ['300', '350'],
  },
  {
    paper: {
      text: 'จั่วปัง',
      value: 6,
    },
    gram: ['430 (เบอร์ 8)', '535 (เบอร์ 10)', '642 (เบอร์ 12)', '752 (เบอร์ 14)', '845 (เบอร์ 16)', '1110 (เบอร์ 20)', '1324 (เบอร์ 24)', '1544 (เบอร์ 28)', '1730 (เบอร์ 32)'],
  },
]
