import React from 'react'
import {
  Div, Input,
} from 'konsys-ui'
import {
  Label, InputNumber,
} from './styled'
import { theme } from '../../../../../styles/_variables'


const ThicknessInput = ({
  label,
  data,
  onInputChange,
  setDisabledButton,
  setIsValidate,
  isValidate,
}) => (
  <Div>
    {label && <Label display='block' margin='0 0 8px' bold color={theme.color.primaryColor}>{label}</Label>}
    <Div>
      <Input
        width='100%'
        value={data.thickness}
        onChange={(e) => {
          onInputChange({ ...data, thickness: e.target.value })
          if (e.target.value !== '') {
            setDisabledButton(false)
            setIsValidate(true)
          }
        }}
        onBlur={(e) => {
          if (!e.target.value) {
            setIsValidate(false)
            setDisabledButton(true)
          }
        }}
        style={{ width: '100%', borderColor: !isValidate && theme.color.error }}
      />
    </Div>
  </Div>
)

export default ThicknessInput
