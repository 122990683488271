import React, { useState, useEffect } from 'react'
import {
  Div, Input,
} from 'konsys-ui'
import {
  ButtonAction, LabelCell, DivCell,
  Table, DivToolbar, DivTitle,
  DivItemCell, Label, DivColumnGram, DivGram,
  DivCellGram, InputNumber,
} from './styled'

const EditableCell = ({
  editing,
  children,
  inputType,
  record,
  dataIndex,
  title,
  onChangeCell,
  alignCell = 'left',
  style,
  ...restProps
}) => {
  const [value, setValue] = useState(record ? record[dataIndex] : '')
  useEffect(() => {
    if (record && record[dataIndex]) {
      setValue(record[dataIndex])
    }
  }, [record])

  let inputNode = <DivItemCell>
    {inputType === 'number' ? <InputNumber
      type="number"
      min={0}
      max={9999}
      value={value}
      width="100px"
      onChange={(e) => {
        setValue(parseFloat(e))
        onChangeCell(parseFloat(e), dataIndex)
      }}
    />
      : <Input
        type="text"
        value={value}
        width="100px"
        onChange={(e) => {
          setValue(e.target.value)
          onChangeCell(e.target.value, dataIndex)
        }}
    />
    }
  </DivItemCell>
  let cellNode = <LabelCell>{children}</LabelCell>
  if (dataIndex === 'costPrintMachine5' || dataIndex === 'costPrintMachine3') {
    inputNode = (<Div>
      <DivColumnGram>
        <DivCellGram>
          <InputNumber
            type="number"
            width="60px"
            min={0}
            max={9999}
            value={value.lessThanEqual160}
            onChange={(e) => {
              const tmpValue = { ...value }
              tmpValue.lessThanEqual160 = parseFloat(e)
              setValue(tmpValue)
              onChangeCell(tmpValue, dataIndex)
            }}
            onKeyPress={(e) => {
              if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault()
              }
            }}
          />
        </DivCellGram>
        <DivCellGram>
          <InputNumber
            type="number"
            width="60px"
            min={0}
            max={9999}
            value={value.moreThan160}
            onChange={(e) => {
              const tmpValue = { ...value }
              tmpValue.moreThan160 = parseFloat(e)
              setValue(tmpValue)
              onChangeCell(tmpValue, dataIndex)
            }}
            onKeyPress={(e) => {
              if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault()
              }
            }}
          />
        </DivCellGram>
      </DivColumnGram>
    </Div>)
    cellNode = (<>
      <DivCellGram>
        <LabelCell>{value.lessThanEqual160}</LabelCell>
      </DivCellGram>
      <DivCellGram>
        <LabelCell>{value.moreThan160}</LabelCell>
      </DivCellGram>
    </>)
  }
  return (
    <td {...restProps}>
      <DivCell alignCell={alignCell}>
        {editing ? inputNode : cellNode}
      </DivCell>
    </td>
  )
}

const PrintingTypeTable = ({
  pagination = false,
  data = [],
  onSubmitEditRow,
  title,
}) => {
  const [columns] = useState([{
    title: 'จำนวนผลิต (ชิ้น)',
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'center',
    alignCell: 'center',
    editable: false,
  }, {
    title: <>
      {'เครื่องพิมพ์ตัด 5'}
      <DivColumnGram>
        <DivGram>
          {'<= 160 แกรม'}
        </DivGram>
        <DivGram>
          {'> 160 แกรม'}
        </DivGram>
      </DivColumnGram>
    </>,
    dataIndex: 'costPrintMachine5',
    key: 'costPrintMachine5',
    align: 'center',
    alignCell: 'center',
    editable: true,
  }, {
    title: <>
      {'เครื่องพิมพ์ตัด 3'}
      <DivColumnGram>
        <DivGram>
          {'<= 160 แกรม'}
        </DivGram>
        <DivGram>
          {'> 160 แกรม'}
        </DivGram>
      </DivColumnGram>
    </>,
    dataIndex: 'costPrintMachine3',
    key: 'costPrintMachine3',
    align: 'center',
    alignCell: 'center',
    editable: true,
  }])
  const [rows, setRows] = useState(data)
  const [editingKey, setEditingKey] = useState('')
  const [dataRowEditing, setDataRowEditing] = useState({})

  const isEditingRow = record => record.printingOperationCostId === editingKey

  useEffect(() => {
    setRows(data)
  }, [data])

  const renderColumn = () => {
    let tmp = [...columns]
    tmp = columns.map((column) => {
      let onCell = {
        onCell: record => ({
          record,
          dataIndex: column.dataIndex,
          alignCell: column.alignCell,
          title: column.title,
        }),
      }
      if (column.editable) {
        onCell = {
          onCell: record => ({
            record,
            dataIndex: column.dataIndex,
            alignCell: column.alignCell,
            title: column.title,
            editing: isEditingRow(record),
            onChangeCell: (value, dataIndex) => {
              setDataRowEditing({
                ...dataRowEditing,
                [dataIndex]: value,
              })
            },
          }),
        }
      }
      return {
        title: column.title,
        dataIndex: column.dataIndex,
        key: column.key,
        align: 'center',
        // width: '10%',
        ...onCell,
      }
    })
    tmp.push({
      title: 'คำสั่ง',
      dataIndex: 'action',
      key: 'action',
      width: '120px',
      align: 'center',
      onCell: () => ({
        alignCell: 'center',
      }),
      render: (text, record) => {
        const editable = isEditingRow(record)
        return editable ? (
          <Div>
            <Div>
              <ButtonAction
                text='ยืนยัน'
                inverse
                icon='fa fa-floppy-o'
                onClick={() => {
                  const result = [...rows]
                  result[record.printingOperationCostId] = { ...dataRowEditing }
                  setRows(result)
                  onSubmitEditRow(record.printingOperationCostId, dataRowEditing)
                  setDataRowEditing({})
                  setEditingKey('')
                }}
              />
            </Div>
          </Div>
        ) : (
          <ButtonAction
            disabled={editingKey !== ''}
            text='แก้ไข'
            inverse
            icon='fa fa-pencil-square-o'
            onClick={() => {
              setDataRowEditing(record)
              setEditingKey(record.printingOperationCostId)
            }}
          />
        )
      },
    })
    return tmp
  }

  return (
    <Div>
      <DivToolbar>
        <DivTitle>
          <Label>{title}</Label>
        </DivTitle>
      </DivToolbar>
      <Div>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          pagination={pagination}
          columns={renderColumn()}
          dataSource={rows}
        />
      </Div>
    </Div>
  )
}

export default PrintingTypeTable
