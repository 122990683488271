import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import { Link } from 'react-router-dom'
import { message } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import PATH from '../../../../constants/path'
import { ADD_GRAPHIC } from '../../../../constants/graphql/mutation'
import { theme } from '../../../../styles/_variables'
import GraphicForm from '../components/graphic-form'

const { color } = theme


const GraphicCreate = () => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    coverPhoto: [],
  })

  return (
        <Div display='flex' flexDirection='column'>
            <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
                <H4 bold color={color.primaryColor} margin='32px 0'>
                    <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                      <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                    </Link> {' > '}
                    <Link to='/package-setting/graphic' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                    <H4 bold color={color.primaryColor}>บริการด้านกราฟฟิก</H4>
                    </Link> {' > '} New
                </H4>
                <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                  <Link to='/package-setting/graphic' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                    <Button text='ยกเลิก' icon='fa fa-ban' ghost margin='0 16px 0 0' color={color.grey}/>
                  </Link>
                  <Mutation
                    mutation={ADD_GRAPHIC}
                  >
                    {
                      doAddGraphic => (
                        <Button
                          text='บันทึก'
                          icon='fa fa-save'
                          inverse
                          margin='0 16px 0 0'
                          onClick={() => {
                            setCallMutation(true)
                            if (formValues.name === '') {
                              message.warning('กรุณากรอกข้อมูลชื่อบริการด้านกราฟฟิก')
                              setCallMutation(false)
                              setIsValidate(false)
                              return
                            }

                            if (formValues.name.match(new RegExp(/^\s/)) !== null) {
                              message.warning('กรุณากรอกข้อมูลชื่อบริการด้านกราฟฟิกให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
                              setCallMutation(false)
                              setIsValidate(false)
                              return
                            }
                            const input = {
                              name: formValues.name,
                              description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
                              coverPhoto: formValues.coverPhoto,
                            }
                            doAddGraphic({
                              variables: {
                                input,
                              },
                            }).then(({ data }) => {
                              if (data.addGraphic) {
                                setCallMutation(false)
                                message.success(data.addGraphic.message)
                                setTimeout(() => {
                                  window.location = PATH.graphicSetting
                                }, 350)
                              } else {
                                setCallMutation(false)
                                if (data.addGraphic.message === 'ชื่อ บริการด้านกราฟฟิก นี้มีอยู่ในระบบแล้ว') {
                                  setIsValidate(false)
                                  setCallMutation(false)
                                }
                                message.error(data.addGraphic.message)
                              }
                            })
                          }}
                          disabled={callMutation}
                        />
                      )
                    }
                  </Mutation>
                </Div>
                </Div>
            </Div>
            <GraphicForm
              formValues={formValues}
              setFormValues={setFormValues}
              isValidate={isValidate}
              setIsValidate={setIsValidate}
              action={'create'}
            />
        </Div>
  )
}

export default GraphicCreate
