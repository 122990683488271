import styled, { css } from 'styled-components'
import { theme, media } from '../../../../../styles/_variables'


export const OptionLabel = styled.label`
display : block;
color: ${theme.color.secondaryColor};
text-align: center;
line-height: normal;
opacity: ${props => (props.disabled ? '0.5' : '1')};
> div{
    position: relative;
    aspect-ratio : 1 / 1;
    width: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    > div{
        position: absolute;
        aspect-ratio : 1 / 1;
        width: calc((100%) * 0.95);
        border-radius: 50%;
        top: 50%;
        left: 50%;
        margin: calc((100%) * 0.95 * -0.5) 0px 0px calc((100%) * 0.95 * -0.5);
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    }
    > p{
        font-size: ${theme.fonts.size.normal};
        position: absolute;
        margin: 0px;
        color: ${theme.color.secondaryColor};
    }
}
> input{ /* HIDE RADIO */
    visibility: hidden; /* Makes input not-clickable */
    position: absolute; /* Remove input from document flow */
}
> input + div{ /* DIV STYLES */
    cursor:pointer;
    border:2px solid transparent;
    background-color: ${props => (props.backgroundRed ? '#f99386' : 'none')};
}
> input:checked + div{ /* (RADIO CHECKED) DIV STYLES */
    border: 2px solid rgba(${theme.color.primaryColorRGB}, 1) ;
    opacity: ${props => (props.backgroundRed ? '1' : '1')};
    background-color: ${props => (props.backgroundRed ? '#FFFFFF' : 'none')};
    > div{
        box-shadow: none;
    }
}
> input:checked + div + p{
    font-weight: ${theme.fonts.style.semiBold}
    color: ${theme.color.secondaryColor};
}
> p{
    font-size: ${theme.fonts.size.small};
    color: ${props => (props.backgroundRed ? theme.color.textColor : theme.color.secondaryColor)};
    margin-bottom: 0;
}
}
`

export const OptionContentWrapper = styled.div`
display: flex;
width: 100%;
padding: 15px;
font-size: ${theme.fonts.size.subTitle};
color: ${theme.color.primaryColor}
text-align: center;
`

export const OptionText = styled.p`
font-weight: ${props => (props.checked ? theme.fonts.style.semiBold : 500)};
font-size: ${theme.fonts.size.normal};
margin-top: 4px;
`
export const CoverImage = styled.img`
    background-size: cover;
    border-radius: 50%;
    width: 100%;
`
export default OptionLabel
