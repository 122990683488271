import styled from 'styled-components'
import { Input as KonsysInput, Div } from 'konsys-ui'
import { Select as AntSelect } from 'antd'
import { theme, media } from '../../styles/_variables'

export const AddRow = styled(Div)`
    width: 100%;
    border: 1px ${theme.color.grey} dashed;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 40px;
`

export const Input = styled(KonsysInput)`
    border-radius: 0;
`

export const Select = styled(AntSelect)`
    .ant-select-selection {
        border-radius: 0;
    }
`

export const Link = styled.a`
  color: ${theme.color.primaryColor};
  &:hover {
    color: ${theme.color.secondaryColor};
  }
`

export const SelectWrapper = styled.div`
  .ant-select {
    margin: 8px 0 0;
  }
  .ant-select-selection {
    border: 1px solid ${props => props.statusColor} !important;
    border-radius: 2px;
    height: auto;
    background-color: transparent;
  }
  .ant-select-selection__rendered {
    margin: 0 16px;
  }
  .ant-select-selection-selected-value {
    font-size: 18px;
    color: ${props => props.statusColor};
  }
  .ant-select-arrow {
    color: ${props => props.statusColor};
  }
`

export const CheckboxLabel = styled.span`
  font-weight: ${theme.fonts.style.medium};
  font-size: ${theme.fonts.size.normal};
`

export const InputWrapper = styled.div`
  width: 100%;
  padding: 8px 16px;
  ${media.tablet`
    padding: 8px 15px;
  `}
`

export const EachInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  margin: ${props => props.margin};
  ${media.mobileM`
    flex-direction: column;
    align-items: flex-start;
  `}
`

export const InputDiv = styled(Div)`
  ${media.tablet`
    flex-basis: 160px;
  `}
  ${media.mobileM`
    width: 100%;
    margin: 10px 0 20px;
    flex-basis: 0;
  `}
`

export const NameInputDiv = styled(InputDiv)`
  ${media.tablet`
    flex-basis: 240px;
  `}
  ${media.mobileM`
    flex-basis: 0;
  `}
`

export const DateTimeInputWrapper = styled.div`
  display: flex;
  justify-content: ${props => (props.isStart ? 'flex-start' : 'flex-end')};
  width: ${props => props.width};
  column-gap: 8px;
  ${media.mobileM`
    width: 100%;
  `}
`

export const DateTimeInputContainer = styled.div`
  display: flex;
  flex-basis: 47%;
  flex-direction: row;
  justify-content: space-between;
  ${media.tablet`
    flex-basis: 240px;
  `}
  ${media.mobileM`
    flex-direction: column;
    align-items: flex-end;
    flex-basis: 100%;
  `}
`

export const ColorDiv = styled.div`
  display: flex;
  justify-content: ${props => props.reverse && 'flex-end'};
  background-color: ${props => (props.primaryColor ? theme.color.primaryColor : theme.color.paleGray)};
  width: ${props => props.width};
  padding: ${props => (props.small ? '6px 16px' : '8px 16px')};
  ${media.tablet`
    padding: 8px 16px;
  `}
`

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: ${props => props.margin};
  width: auto;
`

export default AddRow
