import React from 'react'
import { Link } from 'react-router-dom'
import {
  H3, Div, Button, Icon,
} from 'konsys-ui'

const NotFound = () => (
  <Div display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
    <Icon icon='fal fa-sad-cry'fontSize='120px'/>
    <H3 margin='40px 0 0 0'>ไม่พบหน้าที่ค้นหา</H3>
    <Link to='/'>
      <Button text='กลับสู่หน้าหลัก' margin='32px 0 0 0' icon="fal fa-arrow-circle-left" />
    </Link>
  </Div>
)

export default NotFound
