import styled from 'styled-components'
import { Button } from 'konsys-ui'
import { theme } from '../../../styles/_variables'

export const WidthContainer = styled.div`
  width: 60%;
  min-width: 300px;
`
export const Label = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`
export const FilePanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export const DivideText = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.size.subTitle};
  margin: 8px 0;
  color: ${theme.color.primaryColor};
  padding: 0px 16px;
`
export const PreviewImage = styled.img`
  width: 108px;
  height: 108px;
  // position: relative;
  border-radius: 1px;
`
export const FullWidthContainer = styled.div`
  margin-top: 32px;
  width: 100%;
`
export const PartSection = styled.div`
  background-color: ${theme.color.offWhite};
  padding: 18px;
  border-radius: 10px;
  max-width: 800px;
  margin-bottom: 12px;
`
export const PartLabel = styled.div`
  background-color: ${theme.color.primaryColor};
  color: ${theme.color.offWhite};
  padding: 4px 18px;
  border-radius: 20px;
  display: inline-block;
  width: fit-content;
  position: relative;
`
export const PartTitleLabel = styled.h4`
  font-weight: 700;
  font-size: 24px;
  margin: 8px 0;
  color: ${theme.color.primaryColor};
`

export const DivToolbarEditor = styled.div`
    width: fit-content;
    background-color: ${theme.color.offWhite};
    margin-bottom: 16px;
    border-radius: 3px;
`

export const DivContentAmountPart = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 0;
    width: auto;
`

export const DivImage = styled.div`
    width: 108px;
`

export const DivImageAction = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px
`

export const DeleteButton = styled(Button)`
  border: 1px solid ${theme.color.error}33;
  span, i {
    color: ${theme.color.error}88 !important;
  }
`

export const SummaryDivMobile = styled.div`
  width: 100%;
  padding: 8px 24px;
  position: relative;
  margin-top: 30px;
`

export default WidthContainer
