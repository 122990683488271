import React, { useState, useRef } from 'react'
import ImageGallery from 'react-image-gallery'
import Lightbox from 'react-images'

const PartGallery = ({
  image = [],
  isMobileXL,
}) => {
  const [openLightBox, setOpenLightBox] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const galleryElement = useRef()
  return (
    <React.Fragment>
      <ImageGallery
        ref={galleryElement}
        additionalClass='part-gallery'
        items={image.map(item => ({
          original: item.packagePartFileUri,
          thumbnail: item.packagePartFileUri,
          originalHeight: 'fit-content',
          originalWidth: 'fit-content',
          thumbnailHeight: !isMobileXL ? '38px' : '48px',
          thumbnailWidth: !isMobileXL ? '38px' : '48px',
          originalClass: 'image-part',
          thumbnailClass: 'image-part-thumbnail',
        }))}
        showNav={false}
        thumbnailPosition={isMobileXL ? 'left' : 'bottom'}
        showFullscreenButton={false}
        showPlayButton={false}
        disableThumbnailScroll={true}
        onClick={() => {
          setCurrentImage(galleryElement.current.state.currentIndex)
          setOpenLightBox(true)
        }}
      />
      <Lightbox
        images={image.map(v => (
          { src: v.packagePartFileUri }
        ))}
        currentImage={currentImage}
        isOpen={openLightBox}
        onClickNext={() => setCurrentImage(currentImage + 1)}
        onClickPrev={() => setCurrentImage(currentImage - 1)}
        onClose={() => setOpenLightBox(false)}
        backdropClosesModal={true}
      />
    </React.Fragment>
  )
}

export default PartGallery
