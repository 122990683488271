import React from 'react'
import { Link } from 'react-router-dom'
import { Popconfirm, message } from 'antd'
import {
  Div, H4, Button,
} from 'konsys-ui'
import { Query, Mutation } from 'react-apollo'
import {
  ButtonAction,
  SimpleTable, ActionGroupWrapper,
  Column, LabelHeaderColumn,
} from './styled'
import { theme } from '../../../styles/_variables'
import { LIST_PAPER } from '../../../constants/graphql/query'
import { DELETE_PAPER } from '../../../constants/graphql/mutation'
import PATH from '../../../constants/path'
import RecommendationSetting from '../recommendation-setting'
import BreadCrumb from '../../../components/common/breadcrumb'

const { color } = theme

const columns = [{
  title: <LabelHeaderColumn>ชื่อกระดาษ</LabelHeaderColumn>,
  dataIndex: 'name',
  render: text => <Column margin="0px 20px" align="left">{text}</Column>,
  align: 'center',
  width: '22%',
},
{
  title: <LabelHeaderColumn>ความหนากระดาษ (แกรม)</LabelHeaderColumn>,
  dataIndex: 'thinkness',
  render: text => <Column align={text === '-' ? 'center' : null}>{text}</Column>,
  align: 'center',
  width: '24%',
},
{
  title: <LabelHeaderColumn>วิธีการเคลือบผิวด้านหน้า</LabelHeaderColumn>,
  dataIndex: 'frontCoating',
  render: text => <Column align={text === '-' ? 'center' : null}>{text}</Column>,
  align: 'center',
  width: '22%',
},
{
  title: <LabelHeaderColumn>วิธีการเคลือบผิวด้านหลัง</LabelHeaderColumn>,
  dataIndex: 'backCoating',
  render: text => <Column align={text === '-' ? 'center' : null}>{text}</Column>,
  align: 'center',
  width: '22%',
},
{
  title: <LabelHeaderColumn>คำสั่ง</LabelHeaderColumn>,
  dataIndex: 'action',
  align: 'center',
  width: '120px',
},
]

const PaperSetting = ({
  history,
}) => {
  const ActionGroup = ({ onEdit, rowData }) => (
    <ActionGroupWrapper>
      <ButtonAction small inverse text='แก้ไข' icon='fa fa-pencil-square-o' onClick={onEdit} />
      <Mutation mutation={DELETE_PAPER}>
        {
          doDeletePaper => (
            <Popconfirm
              title='ยืนยันการรลบการประเภทกระดาษหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก'
              onConfirm={() => {
                doDeletePaper({
                  variables: {
                    id: parseInt(rowData.paperId, 10),
                  },
                }).then((res) => {
                  if (res.data.deletePaper) {
                    if (res.data.deletePaper.success) {
                      message.success(res.data.deletePaper.message)
                      if (res.data.deletePaper.packageError) {
                        message.warning(res.data.deletePaper.packageErrorMessage)
                      }
                      setTimeout(() => {
                        window.location = PATH.paperSetting
                      }, 1200)
                    } else {
                      message.error(res.data.deletePaper.message)
                    }
                  }
                })
              }}
              okText='ยืนยัน'
              cancelText='ยกเลิก'
            >
              <ButtonAction small text='ลบ' icon='fa fa-trash-o fa-lg' color={theme.color.error} />
            </Popconfirm>
          )
        }
      </Mutation>
    </ActionGroupWrapper>
  )
  return (
    <Div>
      <Div display='flex' flexDirection='row' justifyContent='space-between' margin='32px 0px 0px 0px' alignItems='center'>
          <Div display='flex' flexDirection='column'>
          <H4 bold color={color.primaryColor}>
              <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                <H4 bold color={color.primaryColor}>Catalog Settings</H4>
              </Link> {' > '} กระดาษ
          </H4>
          </Div>
          <Link to='/package-setting/paper-create' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
            <Button
              htmlType="submit"
              text='New'
              icon='fal fa-plus'
              inverse
            />
          </Link>
      </Div>
      <Query
        query={LIST_PAPER}
        variables={{
          limit: 10000,
          offset: 0,
        }}
        notifyOnNetworkStatusChange
        fetchPolicy='network-only'
      >
        {
          ({ loading, data, refetch }) => {
            let paperList = []
            let paperRemark = null
            if (!loading) {
              paperList = data.listPaper.paper.map((item) => {
                const thinkness = item.paperThickness.map(paper => paper.thickness)
                const dupThinkness = thinkness.filter((c, index) => (thinkness.indexOf(c) === index))
                return {
                  name: item.name,
                  backCoating: item.coatingBehind.map(itemCoating => itemCoating.name).join(', ') || '-',
                  frontCoating: item.coatingFront.map(itemCoating => itemCoating.name).join(', ') || '-',
                  thinkness: dupThinkness.join(', ') || '-',
                  action: <ActionGroup rowData={item} onEdit={() => history.push(`${PATH.paperDetail}/${item.paperId}`)}/>,
                }
              })
              paperRemark = data.listPaper.remark
            }
            return (
              <>
                <RecommendationSetting
                  moduleName='paper'
                  data={paperRemark}
                  refetch={() => refetch()}
                />
                <SimpleTable
                  loading={loading}
                  columns={columns}
                  dataSource={paperList && paperList}
                  pagination={false}
                />
              </>
            )
          }
        }
      </Query>
    </Div>
  )
}

export default PaperSetting
