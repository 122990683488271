import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Div, H4, Button, LoadingIcon,
} from 'konsys-ui'
import { message, Popconfirm } from 'antd'
import { Query, Mutation } from 'react-apollo'

import { theme } from '../../../../styles/_variables'
import PATH from '../../../../constants/path'
import { GET_GLUING_DETAIL } from '../../../../constants/graphql/query'
import { DELETE_GLUING, EDIT_GLUING } from '../../../../constants/graphql/mutation'
import GluingForm from '../components/gluing-form'
import BreadCrumb from '../../../../components/common/breadcrumb'


const { color } = theme


const GluingDetail = ({ match }) => {
  const [isValidate, setIsValidate] = useState(true)
  const [callMutation, setCallMutation] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    costMinimum: 0,
    headerGluing: [{
      title: 'จำนวนผลิต (ชิ้น)',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: 'number',
      editable: false,
      editColumn: false,
      alignCell: 'center',
    }],
    dataGluing: [],
  })
  return (
    <Query
      query={GET_GLUING_DETAIL}
      variables={{
        id: parseInt(match.params.id, 10),
      }}
      notifyOnNetworkStatusChange
      onCompleted={(data) => {
        const gluingData = data.getGluingDetail.gluing
        if (data.getGluingDetail.success) {
          const gluingDataColumn = gluingData.gluingCostColumnName.map(item => ({
            title: item.name,
            dataIndex: item.type,
            key: item.type,
            inputType: 'number',
            editable: true,
            editColumn: item.type !== 'column1',
            alignCell: 'center',
          }))
          setFormValues({
            ...formValues,
            name: gluingData.name,
            description: gluingData.description,
            headerGluing: [...formValues.headerGluing, ...gluingDataColumn],
            dataGluing: gluingData.gluingCost.map((item, index) => ({
              key: index,
              quantity: item.quantity,
              column1: item.column1,
              column2: item.column2,
              column3: item.column3,
              column4: item.column4,
              column5: item.column5,
            })),
            costMinimum: gluingData.minimumCost,
          })
        } else {
          message.error(data.getGluingDetail.message)
          setTimeout(() => {
            window.location = PATH.gluingSetting
          }, 1000)
        }
      }}
    >
      {
        ({ loading, data }) => {
          if (loading) return <Div margin='24px 0' textAlign='center'><LoadingIcon color={theme.color.grey} /></Div>
          const { gluingId } = data.getGluingDetail.gluing
          const gluingData = data.getGluingDetail.gluing
          return (
            <>
              <Div display='flex' flexDirection='column'>
                <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
                  <H4 bold color={color.primaryColor} margin='32px 0'>
                    <Link to='/package-setting' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                      <H4 bold color={color.primaryColor}>Catalog Settings</H4>
                    </Link> {' > '}
                    <Link to='/package-setting/gluing' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
                      <H4 bold color={color.primaryColor}>การติดกาว</H4>
                    </Link> {' > '} แก้ไขข้อมูล
                  </H4>
                  <Div display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
                    <Div display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                      <DeleteButton gluingId={gluingId} />
                      <SaveButton
                        gluingId={gluingId}
                        formValues={formValues}
                        callMutation={callMutation}
                        setCallMutation={setCallMutation}
                        setIsValidate={setIsValidate}
                      />
                    </Div>
                  </Div>
                </Div>
                {
                  data.getGluingDetail.gluing && <GluingForm
                    formValues={formValues}
                    setFormValues={setFormValues}
                    isValidate={isValidate}
                    setIsValidate={setIsValidate}
                    action={'detail'}
                  />
                }
              </Div>
            </>
          )
        }
      }
    </Query>
  )
}

const DeleteButton = ({ gluingId }) => (
  <Mutation mutation={DELETE_GLUING}>
    {
      doDeleteGluing => (
        <Popconfirm
          title="ยืนยันการลบการติดกาวหรือไม่ หากลบแล้วจะไม่สามารถเรียกคืนได้อีก"
          onConfirm={() => {
            doDeleteGluing({
              variables: {
                id: parseInt(gluingId, 10),
              },
            }).then((res) => {
              if (res.data.deleteGluing) {
                if (res.data.deleteGluing.success) {
                  message.success(res.data.deleteGluing.message)
                  setTimeout(() => {
                    window.location = PATH.gluingSetting
                  }, 350)
                } else {
                  message.error(res.data.deleteGluing.message)
                }
              }
            })
          }}
          okText='ยืนยัน'
          cancelText='ยกเลิก'
        >
          <Button text='ลบ' icon='fa fa-trash' ghost margin='0 16px 0 0' color={color.error}/>
        </Popconfirm>
      )
    }
  </Mutation>
)

const SaveButton = ({
  gluingId,
  formValues,
  callMutation,
  setCallMutation,
  setIsValidate,
}) => (
  <Mutation mutation={EDIT_GLUING}>
    {
      doEditGluing => (
        <Button
          text='บันทึก'
          icon='fa fa-save'
          inverse
          margin='0 16px 0 0'
          onClick={() => {
            setCallMutation(true)
            if (formValues.name === '') {
              message.warning('กรุณากรอกข้อมูลชื่อวิธีการติดกาว')
              setCallMutation(false)
              setIsValidate(false)
              return
            }

            if (formValues.name.match(new RegExp(/^\s/)) !== null) {
              message.warning('กรุณากรอกข้อมูลชื่อวิธีการติดกาวให้ถูกต้อง ไม่มีเว้นบรรทัดนำหน้า')
              setCallMutation(false)
              setIsValidate(false)
              return
            }
            const input = {
              gluingId,
              name: formValues.name,
              description: formValues.description ? formValues.description.replace(' ', '&nbsp;') : '',
              minimumCost: parseFloat(formValues.costMinimum),
              gluingCostColumnName: formValues.headerGluing.filter(column => /column*/.test(column.key)).map(column => ({
                gluingId,
                name: column.title,
                type: column.key,
              })),
              gluingCost: formValues.dataGluing.map(row => ({
                gluingId,
                quantity: parseInt(row.quantity, 10),
                column1: parseFloat(row.column1),
                column2: parseFloat(row.column2),
                column3: parseFloat(row.column3),
                column4: parseFloat(row.column4),
                column5: parseFloat(row.column5),
              })),
            }
            doEditGluing({
              variables: {
                input,
              },
            }).then(({ data }) => {
              if (data.editGluing) {
                setCallMutation(false)
                message.success(data.editGluing.message)
                setTimeout(() => {
                  window.location = PATH.gluingSetting
                }, 350)
              } else {
                setCallMutation(false)
                if (data.editGluing.message === 'ชื่อ วิธีการติดกาว นี้มีอยู่ในระบบแล้ว') {
                  setIsValidate(false)
                  setCallMutation(false)
                }
                message.error(data.editGluing.message)
              }
            })
          }}
          disabled={callMutation}
        />
      )
    }
  </Mutation>
)

export default GluingDetail
