import React, { useState, useEffect } from 'react'
import moment from 'moment'
import numeral from 'numeral'
import { Query, Mutation } from 'react-apollo'
import _ from 'lodash'
import {
  message, Row, Col, Modal, Checkbox, Popconfirm,
  Input as InputAnt,
} from 'antd'
import {
  SmallText, Div, Button, H5, H4, Icon, InputWithLabel, Loading, Input as InputKonsys,
} from 'konsys-ui'
import history from '../../history'
import { theme } from '../../styles/_variables'
import { status } from '../../pages/quotation-page/constants'
import { P } from '../../pages/quotation-page/styled'
import {
  AddRow, Input, Select, Link, SelectWrapper, CheckboxLabel,
  ColorDiv, InputWrapper, EachInputWrapper, InputDiv, NameInputDiv,
  DateTimeInputWrapper, DateTimeInputContainer, InputColumn,
} from './styled'
import * as quotationType from '../../constants/quotation-order-type'
import {
  GET_TAX_RATE, GET_ADMIN_NOTE, GET_NEXT_POSSIBLE, GET_PAYMENT_EVIDENCE,
  GET_SHIPPING_DETAIL, SHOW_HISTORY, SHOW_HISTORY_QUOTATION,
} from '../../constants/graphql/query'
import {
  CREATE_QUOTATION, ADD_ADMIN_NOTE, RESEND_QUOTATION_EMAIL, UPDATE_STATUS,
  EDIT_SHIPPED, SEND_SHIP_EMAIL, ADD_PAYMENT_EVIDENCE_FILES, CREATE_PAYMENT,
} from '../../constants/graphql/mutation'
import UploadFile from '../common/upload'

const { color } = theme
const { confirm } = Modal
const { TextArea } = InputAnt

export const CellText = props => (
  <P font='printAble4u'>{props.children}</P>
)

export const HeaderLabel = props => (
  <SmallText bold color={color.coolGrey} font='printAble4u' style={{ fontWeight: 700 }}>{props.children}</SmallText>
)

export const InputWithPlaceHolder = ({ handleChange }) => (
  <input placeholder='ค้นหา' onChange={e => handleChange(e.target.value)}/>
)

export const StatusFilterDropdown = ({
  statusAttr, handleChange, filter, disabled,
}) => {
  const [value, setValue] = useState(statusAttr)
  const arr = filter ? _.concat({ key: 'all', text: 'ทั้งหมด', color: '#1155CC' }, status) : status

  return (
    <Select
      disabled={disabled}
      dropdown
      onChange={(e) => {
        setValue(e)
        handleChange(e)
      }}
      defaultValue='ทั้งหมด'
      style={{
        width: '150px',
        color: _.find(arr, o => o.key === value).color,
      }}
    >
    {
      arr.map(v => <Select.Option key={v.key} value={v.key}>{v.text}</Select.Option>)
    }
    </Select>
  )
}

export const StatusChangeDropdown = ({
  statusAttr, handleChange, disabled,
}) => {
  const [value, setValue] = useState(statusAttr)

  return (
    <Select
      disabled={disabled}
      dropdown
      onChange={(e) => {
        setValue(e)
        handleChange(e)
      }}
      defaultValue={value}
      style={{
        width: '150px',
        color: _.find(status, o => o.key === value).color,
      }}
    >
    {
      status.map(v => <Select.Option key={v.key} value={v.key}>{v.text}</Select.Option>)
    }
    </Select>
  )
}

export const ActionSelector = ({ disabled, props }) => (
  <Select
    disabled={disabled}
    dropdown
    data={[
      { value: 'view', text: 'ดูรายละเอียด' },
      { value: 'edit', text: 'แก้ไข' },
    ]}
    buttonProps={{
      text: 'ดูรายละเอียด',
      ghost: true,
      color: color.primaryColor,
      small: true,
      width: '120px',
    }}
    onChange={(e) => {
      if (e.value === 'edit') {
        history.push(`/client/edit/${props.original.id}`)
      } else {
        history.push(`/client/view/${props.original.id}`)
      }
    }}
  />
)

export const Wrapper = props => (
  <Div
    display='flex'
    flexDirection='row'
    padding={props.padding || '40px 0'}
    justifyContent={props.center && 'center'}
    alignItems='flex-end'
    style={{ borderBottom: `1px solid ${color.paleGrey}`, overflow: props.overflow && 'auto' }}
  >
    {props.children}
  </Div>
)

export const ActionBar = ({
  loading, isAddMode, isViewMode, doAction, mutationVariables, props,
}) => (
  <Div
    position='fixed'
    height='72px'
    bottom='0'
    left='0'
    right='0'
    style={{ background: `${color.primaryColor}33` }}
    display='flex'
    justifyContent='flex-end'
    alignItems='center'
    padding='0 32px 0 0'
  >
    <Button
      loading={loading}
      disabled={loading}
      text={isAddMode ? 'สร้างใหม่' : isViewMode ? 'แก้ไข' : 'บันทึก'}
      icon={isAddMode ? 'fas fa-plus' : isViewMode ? 'fas fa-edit' : 'fas fa-save'}
      inverse
      onClick={() => {
        if (isViewMode) history.push(`/client/edit/${props.match.params.id}`)
        else {
          doAction({
            variables: isAddMode ? { ...mutationVariables } : { id: props.match.params.id, ...mutationVariables },
          }).then(({ data }) => {
            const resp = isAddMode ? data.createCustomer : data.updateCustomer

            if (resp.success) message.success(resp.message)
            else message.error(resp.message)
          })
        }
      }}
    />
    <Button margin='0 0 0 16px' text='Check state' />
  </Div>
)

// ---------------------- Created on Box-suit project ---------------------- //

export const CreateQuotation = ({
  quotationId,
  hideButton,
  getData,
  withHoldingTax = false,
  onChangeWithHoldingTax,
  isPreDefine,
  productItems,
}) => {
  const [productId, setProductId] = useState(1)
  const [disabledButton, setDisabledButton] = useState(false)
  const [products, setproducts] = useState(!isPreDefine ? [{
    id: 0,
    type: quotationType.ITEM,
    title: '',
    price: '',
    amount: '',
  }] : [...productItems])

  const [taxRate, settaxRate] = useState(undefined)
  const [withHoldingTaxRate, setwithholdingtaxRate] = useState(0)
  const [withholdingTax, setwithHoldingTax] = useState(withHoldingTax)
  const [date, setDate] = useState(moment().add(1, 'months').format('DD'))
  const [month, setMonth] = useState(moment().add(1, 'months').format('MM'))
  const [year, setYear] = useState(moment().add(1, 'months').format('YYYY'))
  const finalPrice = isPreDefine
    ? (
      _.sum(products.map(v => (v.type === quotationType.ITEM
        ? (parseFloat(v.price ? v.price : 0, 10) * v.amount)
        : parseFloat(v.price ? v.price : 0, 10))))
    )
    : (
      _.sum(products.map(v => (v.type === quotationType.ITEM
        ? (parseFloat(v.price ? v.price : 0, 10) * v.amount)
        : parseFloat(v.price ? v.price : 0, 10))))
    )
  // const finalPrice = _.sum(products.map(v => (_.isInteger(v.amount) ? v.amount * v.price : v.price)))
  const updateStateHandler = (id, value, field) => setproducts(products.map(pm => (pm.id === id ? { ...pm, [field]: value } : pm)))

  useEffect(() => {
    if (getData) {
      getData(products, parseFloat(taxRate, 10))
    }
  })

  useEffect(() => {
    setwithHoldingTax(withHoldingTax)
  }, [withHoldingTax])

  useEffect(() => {
    if (isPreDefine) {
      setproducts([...productItems])
    }
  }, [productItems])

  return (
    <Div display='flex' flexDirection='column'>
      <H4 bold margin='0 0 32px 0' color={color.tertiaryColor}>กรอกรายละเอียดเพื่อสร้าง Quotation</H4>
      <H5 bold margin='0 0 24px 0' color={color.tertiaryColor}>รายการสินค้าและบริการ</H5>
      {
        products.map((v, idx) => (
          <Row key={v.id} align='middle' justify='center' type='flex' gutter={16} style={{ margin: '0 -8px 16px -8px' }}>
            <Col span={6}>
              <Select
                // disabled={disabled}
                dropdown
                onChange={e => updateStateHandler(v.id, e, 'type')}
                style={{ width: '100%' }}
                defaultValue='ราคา ต่อ ชิ้น'
              >
                <Select.Option key={quotationType.ITEM} value={quotationType.ITEM}>ราคา ต่อ ชิ้น</Select.Option>
                <Select.Option key={quotationType.ORDER} value={quotationType.ORDER}>ราคา ต่อ คำสั่งพิมพ์</Select.Option>
              </Select>
            </Col>
            <Col span={7}>
              <TextArea
                style={{
                  borderRadius: '0px',
                  display: 'flex',
                  fontSize: '18px',
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
                width="100%"
                value={v.title}
                rows={1}
                placeholder='รายการ'
                onChange={e => updateStateHandler(v.id, e.target.value, 'title')}
              />
            </Col>
            <Col span={v.type === quotationType.ITEM ? 6 : 10}>
              <Input
                type='number'
                width='100%'
                placeholder={v.type === quotationType.ITEM ? 'ราคา/ชิ้น' : 'ราคารวม'}
                value={v.price}
                onKeyPress={(e) => {
                  if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
                    e.preventDefault()
                  }
                }}
                onChange={(e) => {
                  updateStateHandler(v.id, e.target.value, 'price')
                }}
              />
            </Col>
              {
                v.type === quotationType.ITEM
                  && <Col span={(isPreDefine && idx === 0 ? 5 : 4)}>
                      <Input value={v.amount} placeholder='จำนวนชิ้น' width='100%' onChange={(e) => {
                        if (RegExp('^[0-9]*$').test(e.target.value)) { // ^[0-9]*$
                          updateStateHandler(v.id, !_.isEmpty(e.target.value) ? parseInt(e.target.value, 10) : '', 'amount')
                        }
                      }}/>
                    </Col>
              }
              {
                isPreDefine && idx > 0 && <Col span={1}>
                  <Div
                    cursor='pointer'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      const newProducts = [...products]
                      _.remove(newProducts, (x, i) => x.id === v.id)
                      setproducts(newProducts)
                    }}>
                    <Icon fontSize={theme.fonts.size.extraNormal} color={theme.color.grey} icon='far fa-times' />
                  </Div>
                </Col>
              }
              {
                !isPreDefine && <Col span={1}>
                  <Div
                    cursor='pointer'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      const newProducts = [...products]
                      _.remove(newProducts, (x, i) => x.id === v.id)
                      setproducts(newProducts)
                    }}>
                    <Icon fontSize={theme.fonts.size.extraNormal} color={theme.color.grey} icon='far fa-times' />
                  </Div>
                </Col>
              }
          </Row>
        ))
      }
      <AddRow onClick={() => {
        setProductId(productId + 1)
        setproducts(
          [
            ...products,
            {
              id: productId,
              type: quotationType.ITEM, // ITEM, ORDER
              title: '',
              price: '',
              amount: '', // OPTIONAL Only for ITEM TYPE
            },
          ],
        )
      }}>
        <P color={color.lightGray} style={{ width: 'fit-content', alignItems: 'center', display: 'flex' }}>
          <Icon icon='fal fa-plus' color={color.lightGray} margin='0 8px'/>
          เพิ่มบริการ
        </P>
      </AddRow>
      <H5 bold margin='0 0 12px 0' color={color.tertiaryColor}>รายละเอียดอื่นๆ</H5>
      <Div style={{ margin: '0 0 12px 0' }}>
        <Query
          query={GET_TAX_RATE}
          fetchPolicy={'network-only'}
          notifyOnNetworkStatusChange
        >{({ data, loading, error }) => {
          if (loading) return <Loading />
          if (error) return <H4>error</H4>
          if (_.isUndefined(taxRate)) setwithholdingtaxRate(data.taxRate.withHoldingTaxRate)
          return (
            <Div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Checkbox
                onChange={(e) => {
                  setwithHoldingTax(e.target.checked)
                  if (onChangeWithHoldingTax) onChangeWithHoldingTax(e.target.checked)
                }}
                style={{ display: 'flex', alignItems: 'center' }}
                checked={withholdingTax}
              >
                {/* <CheckboxLabel>หักภาษี ณ ท่ีจ่าย {data.taxRate.withHoldingTaxRate} %</CheckboxLabel> */}
              </Checkbox>
              <span
                style={{
                  paddingLeft: 8,
                  fontSize: '20px',
                }}
              >
                หักภาษี ณ ท่ีจ่าย {data.taxRate.withHoldingTaxRate} %
              </span>
            </Div>
          )
        }}
        </Query>
      </Div>
      <Div display='flex' justifyContent='space-between'>
        <Div display='flex'>
          <Div margin='0 32px 0 0'>
            <SmallText display='block' margin='0 0 8px'>Expiry Date</SmallText>
            <Div display='flex' alignItems='center'>
              <Input
                placeholder='วัน'
                width='40px'
                value={date}
                onKeyPress={(e) => {
                  if (!(e.charCode >= 48 && e.charCode <= 57)) {
                    e.preventDefault()
                  }
                }}
                onChange={(e) => {
                  let dateData = parseInt(e.target.value, 10)
                  if (e.target.value === '') {
                    dateData = 0
                  }
                  setDate(dateData)
                }}
                onBlur={() => {
                  if (date <= 0 || date >= 31 || date === '') {
                    setDate(moment().add(1, 'months').format('DD'))
                  }
                }}
              />
              <P style={{ width: '7px' }} margin='0 8px'>/</P>
              <Input
                placeholder='เดือน'
                width='50px'
                value={month}
                onKeyPress={(e) => {
                  if (!(e.charCode >= 48 && e.charCode <= 57)) {
                    e.preventDefault()
                  }
                }}
                onChange={(e) => {
                  let dateData = parseInt(e.target.value, 10)
                  if (e.target.value === '') {
                    dateData = 0
                  }
                  setMonth(dateData)
                }}
                onBlur={() => {
                  if (month <= 0 || month >= 12 || month === '') {
                    setMonth(moment().add(1, 'months').format('MM'))
                  }
                }}
              />
              <P style={{ width: '7px' }} margin='0 8px'>/</P>
              <Input
                placeholder='ปี'
                width='60px'
                value={year}
                state={_.isEmpty(year) ? '' : year.length < 4 && 'error'}
                onKeyPress={(e) => {
                  if (!(e.charCode >= 48 && e.charCode <= 57)) {
                    e.preventDefault()
                  }
                }}
                onChange={(e) => {
                  if (e.target.value.length <= 4) {
                    setYear(e.target.value)
                  }
                }}
                onBlur={() => {
                  if (year.length < 4) {
                    setYear(moment().add(1, 'months').format('YYYY'))
                  }
                }}
              />
            </Div>
          </Div>
          <Query
            query={GET_TAX_RATE}
            fetchPolicy={'network-only'}
            notifyOnNetworkStatusChange
          >{({ data, loading, error }) => {
            if (loading) return <Loading />
            if (error) return <H4>error</H4>
            if (_.isUndefined(taxRate)) settaxRate(data.taxRate.taxRate)
            return (
              <Div>
                <SmallText display='block' margin='0 0 8px'>ภาษี</SmallText>
                <InputWithLabel
                  title={{ text: '%' }}
                  position='right'
                  width='150px'
                  input={{
                    defaultValue: data.taxRate.taxRate,
                    value: taxRate,
                    rectangle: true,
                    onKeyDown: (e) => {
                      if (RegExp('^[0-9]*$').test(e.key) || e.key === '.') {
                        settaxRate(`${taxRate}${e.key}`)
                      } else if ((e.key === 'Backspace' || e.key === 'Delete')) {
                        settaxRate(taxRate.length > 0 ? taxRate.slice(0, -1) : 0)
                      }
                    },
                  }}
                />
              </Div>
            )
          }}
          </Query>
        </Div>
        <Div display='flex' justifyContent='space-between' flexDirection='column' padding='0 0 8px 0'>
          <Div display='flex'>
            <SmallText textAlign='right' style={{ width: 150 }} margin='0 16px'>หักภาษี ณ ที่จ่าย</SmallText>
            <SmallText textAlign='right' style={{ width: 150 }} margin='0 16px'>ภาษีมูลค่าเพิ่ม</SmallText>
            <SmallText textAlign='right' style={{ width: 150 }}>ราคารวมทั้งหมด</SmallText>
          </Div>
          <Div display='flex'>
            <H5 textAlign='right' style={{ fontSize: '22px', width: 150 }} margin='0 16px'>{_.isNaN(finalPrice) ? 0 : withholdingTax ? `-${numeral(finalPrice * parseFloat(withHoldingTaxRate, 10) / 100).format('0,0.00')}` : `-${numeral(0).format('0,0.00')}`} บาท</H5>
            <H5 textAlign='right' style={{ fontSize: '22px', width: 150 }} margin='0 16px'>{_.isNaN(finalPrice) ? 0 : numeral(finalPrice * parseFloat(taxRate, 10) / 100).format('0,0.00')} บาท</H5>
            <H5 textAlign='right' style={{ fontSize: '22px', width: 150 }}>{_.isNaN(finalPrice) ? 0 : numeral(finalPrice + (finalPrice * parseFloat(withholdingTax ? taxRate - withHoldingTaxRate : taxRate, 10) / 100)).format('0,0.00')} บาท</H5>
          </Div>
        </Div>
      </Div>
      {
        !hideButton
        && <Mutation
          mutation={CREATE_QUOTATION}
        >
        {
          doCreateQuotation => (
            <Button
              style={{ alignSelf: 'center' }}
              inverse
              icon='fal fa-plus'
              text='สร้าง Quotation และส่งอีเมลแจ้งลูกค้า'
              disabled={disabledButton
                // || _.filter(products, v => (v.type === quotationType.ITEM ? !_.isEmpty(v.title) && v.amount > 0 && v.price : v.title && v.price)).length <= 0
                // || _.filter(products, v => (v.type === quotationType.ITEM ? _.isEmpty(v.title) || v.amount === '' || v.price === '' : _.isEmpty(v.title) || v.price === '')).length > 0
              }
              onClick={() => {
                setDisabledButton(true)
                const filterProduct = products
                // _.filter(products, v => (v.type === quotationType.ITEM ? !_.isEmpty(v.title) && v.amount > 0 && !_.isEmpty(v.price) : !_.isEmpty(v.title) && !_.isEmpty(v.price)))
                if (quotationId) {
                  doCreateQuotation({
                    variables: {
                      id: quotationId,
                      input: {
                        taxRate: parseFloat(taxRate, 10),
                        expiryDate: moment(moment(`${date}/${month}/${year}`, 'DD/MM/YYYY').toDate()).format(),
                        withHoldingTax: withholdingTax,
                        withHoldingTaxRate,
                        items: filterProduct.map(v => (
                          {
                            itemType: v.type,
                            itemName: v.title,
                            price: v.price !== '' && v.price !== null ? parseFloat(v.price, 10) : null,
                            quantity: v.amount !== '' && v.amount !== null ? parseInt(v.amount, 10) : null,
                          }
                        )),
                      },
                    },
                  }).then((resp) => {
                    if (!resp.loading) {
                      if (resp.data.createQuotation.success) {
                        message.success(resp.data.createQuotation.message)
                        setTimeout(() => {
                          window.location.reload()
                        }, 3000)
                      } else {
                        message.error(resp.data.createQuotation.message)
                        setDisabledButton(false)
                      }
                    }
                  })
                } else {
                  setDisabledButton(false)
                }
              }}
              margin='60px 0 0'
              width='fit-content'
            />
          )
        }
        </Mutation>
      }
    </Div>
  )
}

// ---------------------- Noted on Box-suit project ---------------------- //

export const NotesComponent = ({ quotationId, defaultValue, refetch }) => {
  const [inputTextArea, setInputTextAres] = useState(defaultValue)
  const [disabled, setDisabled] = useState(false)
  return (
    <Mutation
      mutation={ADD_ADMIN_NOTE}
    >
    {
      doCreateNotes => (
        <Div>
          <Div margin='0 0 32px 0' justifyContent='space-between' alignItems='center' display='flex'>
            <H4 display='block' bold color={color.tertiaryColor}>Notes</H4>
            <Button
              disabled={disabled}
              loading={disabled}
              onClick={() => {
                setDisabled(true)
                doCreateNotes({
                  variables: {
                    adminNote: inputTextArea,
                    id: quotationId,
                  },
                }).then(({ loading, data }) => {
                  setDisabled(false)
                  if (!loading && data) {
                    if (data.addAdminNote.success) {
                      message.success(data.addAdminNote.message)
                      setTimeout(() => {
                        refetch()
                      }, 3000)
                    } else {
                      message.error(data.addAdminNote.message)
                    }
                  }
                })
              }}
              text='อัพเดต Notes'
              ghost
              small
            />
          </Div>
          <H5 bold margin='0 0 24px 0' color={color.tertiaryColor}>รายละเอียดเพิ่มเติม (เห็นเฉพาะ admin ลูกค้าไม่สามารถเห็นข้อมูลตรงส่วนนี้ได้)</H5>
          <Div>
            <Input
              style={{
                border: 'none',
                width: '100%',
                padding: '8px 16px',
                fontSize: '22px',
              }}
              placeholder='รายละเอียด'
              width='100%'
              as='textarea'
              rows="3"
              value={inputTextArea}
              onChange={e => setInputTextAres(e.target.value)}
            />
          </Div>
        </Div>
      )
    }
    </Mutation>
  )
}

export const Notes = ({ quotationId }) => (
  <Query
    query={GET_ADMIN_NOTE}
    fetchPolicy='network-only'
    notifyOnNetworkStatusChange
    variables={{
      id: quotationId,
    }}
  >
  {
    ({ loading, data, refetch }) => {
      if (loading) return <Loading />
      return (
        <NotesComponent
          quotationId={quotationId}
          defaultValue={data ? data.showAdminNote : ''}
          refetch={() => refetch()}
        />
      )
    }
  }
  </Query>
)


// ---------------------- Quotation List on Box-suit project ---------------------- //

export const QuotationPDFList = ({
  margin, quotationId, showCreatNewButton, link,
}) => {
  const [disabledSendButton, setDisabledSendButton] = useState(false)
  const [showCreateNew, setShowCreateNew] = useState(false)
  const splitLink = link.split('/')
  return (
    <Div margin={margin}>
      <H4 margin='0 0 32px' display='block' bold color={theme.color.tertiaryColor}>Quotation</H4>
      <Div margin={setShowCreateNew ? '0 0 24px' : '0'} display='flex' alignItems='center' justifyContent='space-between'>
        <a href={link} target="_blank" rel="noopener noreferrer"><P style={{ width: 'auto' }}>{splitLink[splitLink.length - 1]} ( คลิกเพื่อดาวน์โหลด )</P></a>
        <Div display='flex'>
          <Mutation
            mutation={RESEND_QUOTATION_EMAIL}
          >
          {
            doResendEmail => (
              <Button
                ghost
                small
                color={theme.color.primaryColor}
                icon='fal fa-inbox-out'
                text='ส่งอีเมลให้ลูกค้า'
                disabled={disabledSendButton}
                loading={disabledSendButton}
                onClick={() => {
                  setDisabledSendButton(true)
                  doResendEmail({
                    variables: {
                      id: quotationId,
                    },
                  }).then((res) => {
                    setDisabledSendButton(false)
                    if (res.data.resendQuotationEmail) {
                      if (res.data.resendQuotationEmail.success) {
                        message.success(res.data.resendQuotationEmail.message)
                        setTimeout(() => {
                          window.location.reload()
                        }, 3000)
                      } else {
                        message.error(res.data.resendQuotationEmail.message)
                      }
                    }
                  })
                }}
              />
            )
          }
          </Mutation>
          {
            showCreatNewButton
            && <Button
              ghost
              margin='0 0 0 16px'
              small
              color={theme.color.primaryColor}
              icon='fal fa-plus'
              text='สร้าง quotation ใหม่'
              onClick={() => setShowCreateNew(!showCreateNew)}
            />
          }
        </Div>
      </Div>
      {
        showCreateNew
        && <CreateQuotation quotationId={quotationId}/>
      }
    </Div>
  )
}


// ---------------------- Payment Evidence on Box-suit project ---------------------- //
export const PaymentEvidence = ({ margin, quotationId }) => (
  <Div margin={margin}>
    <H4 margin='0 0 32px' display='block' bold color={theme.color.tertiaryColor}>หลักฐานการชำระเงิน</H4>
    <Query
      query={GET_PAYMENT_EVIDENCE}
      fetchPolicy='network-only'
      notifyOnNetworkStatusChange
      variables={{
        id: quotationId,
      }}
    >
    {
      ({ loading, data, refetch }) => {
        if (loading) return <Loading fullWidth center />
        if (!data || !data.showPayment) {
          return (
            <Div>
              <H5 color={theme.color.grey}>ยังไม่มี Payment</H5>
              <AddNewFilePaymentEvidence quotationId={quotationId} refetch={refetch} />
            </Div>
          )
        }
        return (
          <>
          <Div display='flex' alignItems='flex-start' justifyContent='space-between'>
            <Div>
              <P
                display='block'
                margin='0 0 16px'
              >ช่องทางชำระเงิน : <P style={{ textTransform: 'capitalize', display: 'inline' }}>{data.showPayment.paymentMethod === 'bank' ? 'ธนาคารกรุงศรี' : data.showPayment.paymentMethod}</P></P>
              <P style={{ width: 'auto' }}>ชื่อบัญชีผู้ชำระเงิน : {data.showPayment.name}</P>
              <P style={{ width: 'auto' }} margin='0 24px'>วันเวลาที่โอน : {moment(data.showPayment.dateTime).format('DD/MM/YYYY HH:mm')}</P>
              <P style={{ width: 'auto' }}>จำนวนเงิน : {numeral(data.showPayment.networth).format('0,0.00')} บาท</P>
            </Div>
            <Div>
            {
              data.showPayment.evidenceFiles.length > 0 && data.showPayment.evidenceFiles.map((v, i) => (
                <Link key={i} href={v.evidenceFileUri} target='blank'>คลิกเพื่อดาวน์โหลดหลักฐานไฟล์ที่ {parseInt(i, 10) + 1}{i !== (data.showPayment.evidenceFiles.length - 1) && ', '}</Link>
              ))
            }
            </Div>
          </Div>
          {/* <UploadFilePaymentEvidence quotationId={quotationId} refetch={refetch} /> */}
          <AddNewFilePaymentEvidence quotationId={quotationId} refetch={refetch} />
          </>
        )
      }
    }
    </Query>
  </Div>
)

// ---------------------- Status Dropdown on Box-suit project ---------------------- //

export const StatusDropdown = ({
  id, presentStatus, width, listPage,
}) => {
  const [disabledSelect, setDisabledSelect] = useState(false)
  const [values, setValue] = useState(_.filter(status, h => h.key === presentStatus)[0])
  return (
    <Query
      query={GET_NEXT_POSSIBLE}
      fetchPolicy='network-only'
      notifyOnNetworkStatusChange
      variables={{
        id: parseInt(id, 10),
        presentStatus,
      }}
    >
    {
      (res) => {
        if (res.loading || !res.data) return <Loading fullWidth center />
        if (res.error) return <H4>Something went wrong</H4>
        const statusValue = _.filter(status, v => v.key === presentStatus)[0]
        return (
          <Mutation
            mutation={UPDATE_STATUS}
          >
          {
            doUpdateStatus => (
              <SelectWrapper statusColor={statusValue.color}>
                <Select
                  defaultValue={_.filter(status, h => h.key === presentStatus)[0].text}
                  placeholder='คลิกเลือกเพื่อเปลี่ยน status'
                  // style={{ width: 185 }}
                  value={values.text}
                  style={{ width: !width ? 185 : width }}
                  disabled={disabledSelect || !res.data.showNextPossible}
                  showArrow={disabledSelect || res.data.showNextPossible}
                  loading={disabledSelect}
                  onSelect={(value) => {
                    const statusConfirmation = [
                      'quotationRequest',
                      'quotationReview',
                      'waitingForPayment',
                      'paymentVerifying',
                      'revisingPayment',
                      'paymentVerified',
                      'designing',
                      'artworkCreating',
                      'printing',
                      'shipped',
                      'refunding',
                      'refunded',
                      'paused',
                      'cancelled',
                    ]
                    if (statusConfirmation.includes(value)) {
                      confirm({
                        title: 'ต้องการเปลี่ยนสถานะใช่หรือไม่?',
                        okText: 'ใข่',
                        cancelText: 'ไม่ใข่',
                        onCancel() {
                          res.refetch()
                        },
                        onOk() {
                          setDisabledSelect(true)
                          doUpdateStatus({
                            variables: {
                              id: parseInt(id, 10),
                              status: value,
                            },
                          }).then((resp) => {
                            setDisabledSelect(false)
                            if (resp.data.updateStatus) {
                              if (resp.data.updateStatus.success) {
                                setValue(_.filter(status, h => h.key === value)[0])
                                message.success(resp.data.updateStatus.message)
                                setTimeout(() => {
                                  window.location.reload()
                                }, 500)
                              } else {
                                message.error(resp.data.updateStatus.message)
                              }
                            }
                          })
                        },
                      })
                    } else {
                      setDisabledSelect(true)
                      doUpdateStatus({
                        variables: {
                          id: parseInt(id, 10),
                          status: value,
                        },
                      }).then((resp) => {
                        setDisabledSelect(false)
                        if (resp.data.updateStatus) {
                          if (resp.data.updateStatus.success) {
                            message.success(resp.data.updateStatus.message)
                            setTimeout(() => {
                              window.location.reload()
                            }, 3000)
                          } else {
                            message.error(resp.data.updateStatus.message)
                          }
                        }
                      })
                    }
                  }}
                >
                {
                  listPage && res.data.showNextPossible && res.data.showNextPossible.map(v => (
                      <Select.Option key={v} value={v}>{_.filter(status, h => h.key === v)[0].text}</Select.Option>
                  ))
                }
                </Select>
              </SelectWrapper>
            )
          }
          </Mutation>
        )
      }
    }
    </Query>
  )
}


// ---------------------- Shipped on Box-suit project ---------------------- //
// BS811062052100012
const ShippedDataComponent = ({
  quotationId, margin, defaultData, isAbleToSendEmail,
}) => {
  const [deliverCompany, setDeliverCompany] = useState(defaultData ? defaultData.shipper : '')
  const [trackingNumber, setTrackingNumber] = useState(defaultData ? defaultData.trackingId : '')
  const [billingDate, setBillingDate] = useState(defaultData ? defaultData.billingDate : '')
  const [billingNo, setBillingNo] = useState(defaultData ? defaultData.billingNo : '')
  const [disabledSendButton, setDisabledSendButton] = useState(false)
  const [editable, setEditable] = useState(false)
  const setDefaultData = () => {
    setDeliverCompany(defaultData ? defaultData.shipper : '')
    setTrackingNumber(defaultData ? defaultData.trackingId : '')
    setBillingDate(defaultData ? defaultData.billingDate : '')
    setBillingNo(defaultData ? defaultData.billingNo : '')
  }
  return (
    <Div margin={margin}>
      <H4 margin='0 0 32px' display='block' bold color={theme.color.tertiaryColor}>ข้อมูลการจัดส่งและใบกำกับภาษี</H4>
      <Div display='flex' alignItems='flex-start' justifyContent='space-between'>
        <Div>
          <Div display='flex' alignItems='center'>
            <P style={{ width: 'auto' }}>ข้อมูลการจัดส่ง</P>
            <Input disabled={!editable} value={deliverCompany} margin='0 8px 0 16px' placeholder='ผู้จัดส่ง' onChange={e => setDeliverCompany(e.target.value)} />
            <Input disabled={!editable} value={trackingNumber} placeholder='หมายเลขพัสดุ' onChange={e => setTrackingNumber(e.target.value)} />
          </Div>
          <Div margin='8px 0 0' display='flex' alignItems='center'>
            <P style={{ width: 'auto' }}>ข้อมูล Billing</P>
            <Input disabled={!editable} value={billingNo} margin='0 8px 0 16px' placeholder='เลขที่ Billing' onChange={e => setBillingNo(e.target.value)} />
            <Input disabled={!editable} value={billingDate} placeholder='วันที่ Billing (19/06/2562)' onChange={e => setBillingDate(e.target.value)} />
          </Div>
        </Div>
        <Div display='flex' flexDirection='column' moreStyle={{ rowGap: '8px' }}>
          { editable ? <Div display='flex' moreStyle={{ columnGap: '2px' }}>
            <Button ghost small color={theme.color.lightGray} text='ยกเลิก'
              onClick={() => {
                setDefaultData()
                setEditable(false)
              }}
            />
            <Mutation
              mutation={EDIT_SHIPPED}
            >
              {
                doEditShipped => (
                  <Popconfirm
                    title='ต้องการบันทึกข้อมูลนี้ใช่หรือไม่'
                    okText='ใช่'
                    cancelText='ไม่ใช่'
                    onConfirm={() => {
                      doEditShipped({
                        variables: {
                          input: {
                            shipper: deliverCompany,
                            trackingId: trackingNumber,
                            billingNo,
                            billingDate,
                            id: quotationId,
                          },
                        },
                      }).then((res) => {
                        setEditable(false)
                        if (res.data.editShipped) {
                          if (res.data.editShipped.success) {
                            message.success(res.data.editShipped.message)
                          } else {
                            message.error(res.data.editShipped.message)
                          }
                        }
                      })
                    }}
                  >
                    <Button ghost small color={theme.color.primaryColor} text='บันทึก' />
                  </Popconfirm>
                )
              }
            </Mutation>
            </Div>
            : <Button
              ghost
              small
              color={theme.color.primaryColor}
              text='แก้ไขข้อมูล'
              disabled={disabledSendButton}
              onClick={() => { setEditable(true) }}
            />
          }
          {
            isAbleToSendEmail && <Mutation
              mutation={SEND_SHIP_EMAIL}
            >
            {
              doSendEmailShipping => (
                <Button
                  ghost
                  small
                  color={theme.color.primaryColor}
                  icon='fal fa-inbox-out'
                  text='ส่งอีเมลให้ลูกค้า'
                  disabled={disabledSendButton || editable}
                  loading={disabledSendButton}
                  onClick={() => {
                    setDisabledSendButton(true)
                    doSendEmailShipping({
                      variables: {
                        input: {
                          shipper: deliverCompany,
                          trackingId: trackingNumber,
                          billingNo,
                          billingDate,
                          id: quotationId,
                        },
                      },
                    }).then((res) => {
                      setDisabledSendButton(false)
                      if (res.data.sendShippedEmail) {
                        if (res.data.sendShippedEmail.success) {
                          message.success(res.data.sendShippedEmail.message)
                        } else {
                          message.error(res.data.sendShippedEmail.message)
                        }
                      }
                    })
                  }}
                />
              )
            }
            </Mutation>
          }
        </Div>
      </Div>
    </Div>
  )
}

const BillingDataComponent = ({
  quotationId, margin, defaultData,
}) => {
  const [billingDate, setBillingDate] = useState(defaultData ? defaultData.billingDate : '')
  const [billingNo, setBillingNo] = useState(defaultData ? defaultData.billingNo : '')
  const [disabledSendButton, setDisabledSendButton] = useState(false)
  const [editable, setEditable] = useState(false)
  const setDefaultData = () => {
    setBillingDate(defaultData ? defaultData.billingDate : '')
    setBillingNo(defaultData ? defaultData.billingNo : '')
  }
  return (
    <Div margin={margin}>
      <Div display='flex' alignContent='center' alignItems='flex-end' justifyContent='space-between'>
        <Div>
          <Div margin='8px 0 0' display='flex' alignItems='center'>
            <P style={{ width: 'auto' }}>ข้อมูล Billing</P>
            <Input disabled={!editable} value={billingNo} margin='0 8px 0 16px' placeholder='เลขที่ Billing' onChange={e => setBillingNo(e.target.value)} />
            <Input disabled={!editable} value={billingDate} placeholder='วันที่ Billing (19/06/2562)' onChange={e => setBillingDate(e.target.value)} />
          </Div>
        </Div>
        { editable ? <Div display='flex' moreStyle={{ columnGap: '2px' }}>
            <Button ghost small color={theme.color.lightGray} text='ยกเลิก'
              onClick={() => {
                setDefaultData()
                setEditable(false)
              }}
            />
            <Mutation
              mutation={EDIT_SHIPPED}
            >
              {
                doEditShipped => (
                  <Popconfirm
                    title='ต้องการบันทึกข้อมูลนี้ใช่หรือไม่'
                    okText='ใช่'
                    cancelText='ไม่ใช่'
                    onConfirm={() => {
                      doEditShipped({
                        variables: {
                          input: {
                            shipper: '',
                            trackingId: '',
                            billingNo,
                            billingDate,
                            id: quotationId,
                          },
                        },
                      }).then((res) => {
                        setEditable(false)
                        if (res.data.editShipped) {
                          if (res.data.editShipped.success) {
                            message.success(res.data.editShipped.message)
                          } else {
                            message.error(res.data.editShipped.message)
                          }
                        }
                      })
                    }}
                  >
                    <Button ghost small color={theme.color.primaryColor} text='บันทึก' />
                  </Popconfirm>
                )
              }
            </Mutation>
            </Div>
          : <Button
              ghost
              small
              color={theme.color.primaryColor}
              text='แก้ไขข้อมูล'
              disabled={disabledSendButton}
              onClick={() => { setEditable(true) }}
            />
          }
      </Div>
    </Div>
  )
}


export const ShippedData = ({ quotationId, margin, isAbleToSendEmail }) => (
  <Query
    query={GET_SHIPPING_DETAIL}
    fetchPolicy='network-only'
    notifyOnNetworkStatusChange
    variables={{ id: quotationId }}
  >
  {
    ({ loading, data }) => {
      if (loading) return <Loading />
      if (isAbleToSendEmail) {
        return <ShippedDataComponent
          quotationId={quotationId}
          margin={margin}
          defaultData={data.showShippingDetail.shipping}
          isAbleToSendEmail={isAbleToSendEmail}
        />
      }
      return <BillingDataComponent
        quotationId={quotationId}
        margin={margin}
        defaultData={data.showShippingDetail.shipping}
      />
    }
  }
  </Query>
)

export const HistoryLog = ({ id, margin }) => (
  <Query
    query={SHOW_HISTORY}
    fetchPolicy='network-only'
    notifyOnNetworkStatusChange
    variables={{ id }}
  >
  {
    (res) => {
      if (res.loading || !res.data) return null
      if (res.data.showStatusHistory.length <= 0) return null
      return (
        <Div margin={margin}>
          <H4 margin='0 0 32px' display='block' bold color={theme.color.tertiaryColor}>ประวัติสถานะ</H4>
          {
            res.data.showStatusHistory.map(v => (
              <Div margin='16px 0' key={v.dateTime} display='flex'>
                <P style={{ flexBasis: '70%' }}>{_.find(status, d => d.key === v.status).text}</P>
                <P>{v.dateTime}</P>
              </Div>
            ))
          }
        </Div>
      )
    }
  }
  </Query>
)

const UploadFilePaymentEvidence = ({ quotationId, refetch }) => {
  const [newFileList, setNewFileList] = useState([])
  const [disabledButton, setDisabledButton] = useState(false)
  return (
    <Mutation
      mutation={ADD_PAYMENT_EVIDENCE_FILES}
    >
    {
      doAddImage => (
        <Div>
          <UploadFile
            list={newFileList}
            id='uploadFileId'
            multiple
            onChange={(fileList) => {
              let upload = false
              const allowFileType = ['jpg', 'jpeg', 'png']
              for (let i = 0; i < fileList.length; i += 1) {
                const splitName = fileList[i].name.split('.')
                if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
                || (newFileList.length > 0 && !_.isUndefined(_.find(newFileList, d => d.name === fileList[i].name)))) {
                  upload = false
                  break
                } else {
                  upload = true
                }
              }
              if (upload) {
                const newUploadFileList = [...newFileList]
                Object.keys(fileList).forEach((v) => {
                  if (v !== 'length') {
                    newUploadFileList.push(fileList[v])
                  }
                })
                setNewFileList(newUploadFileList)
              } else {
                message.error('สามารถอัพโหลดได้เฉพาะไฟล์ jpg, jpeg, png และไม่สามารถอัพรูปซ้ำได้')
              }
            }}
            onRemoveFile={(fileName) => {
              const newUploadFileList = [...newFileList]
              _.remove(newUploadFileList, v => v.name === fileName)
              setNewFileList(newUploadFileList)
            }}
            fileType="image/*"
          />
          <Button disabled={newFileList.length <= 0 || disabledButton} inverse fullWidth text='อัพโหลดหลักฐานการชำระเงินใหม่' onClick={() => {
            setDisabledButton(true)
            doAddImage({
              variables: {
                quotationId,
                images: newFileList,
              },
            }).then((resp) => {
              setDisabledButton(false)
              if (resp.data.addPaymentEvidenceFiles) {
                if (resp.data.addPaymentEvidenceFiles.success) {
                  setNewFileList([])
                  message.success(resp.data.addPaymentEvidenceFiles.message)
                  setTimeout(() => {
                    refetch()
                  }, 3000)
                } else {
                  message.error(resp.data.addPaymentEvidenceFiles.message)
                }
              }
            })
          }} />
        </Div>
      )
    }
    </Mutation>
  )
}

const priceValidator = price => /^\d*\.?\d*$/.test(price)
const dateTimeValidator = datetime => /^\d{1,4}$/.test(datetime)

const InputWithBottomLabel = ({
  label, width, margin, onChangeAllVariables, keyValue, inputValue, limit, type,
}) => (
  <InputColumn margin={margin}>
    <Input
      width={width}
      value={inputValue}
      onChange={(e) => {
        if ((dateTimeValidator(e.target.value) && parseInt(e.target.value, 10) <= limit && parseInt(e.target.value, 10) >= 0) || e.target.value === '') {
          onChangeAllVariables(keyValue, e.target.value)
        }
      }}
      onBlur={() => {
        if (type === 'day' || type === 'month') {
          if (inputValue === '' || parseInt(inputValue, 10) <= 0) {
            onChangeAllVariables(keyValue, '1')
          }
        } else if (type === 'year' && (inputValue === '' || parseInt(inputValue, 10) <= 0)) {
          onChangeAllVariables(keyValue, moment().format('YYYY'))
        }
      }}
    />
    <SmallText>{label}</SmallText>
  </InputColumn>
)

const AddNewFilePaymentEvidence = ({ quotationId, refetch }) => {
  const [disabledButton, setDisabledButton] = useState(false)
  const [allVariables, setAllVariables] = useState({
    amount: '',
    date: '',
    month: '',
    year: '',
    hour: '',
    minute: '',
    name: '',
    evidenceFiles: [],
    paymentMethod: '',
  })
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 375)

  const screenSizeHandler = () => setIsMobileView(window.innerWidth <= 375)

  useEffect(() => {
    window.addEventListener('resize', screenSizeHandler)
    return () => {
      window.removeEventListener('resize', screenSizeHandler)
    }
  }, [])

  const onChangeAllVariables = (key, value) => {
    setAllVariables({
      ...allVariables,
      [key]: value,
    })
  }

  const onUploadFileList = (fileList, name) => {
    const newData = { ...allVariables }
    const newFileList = [...allVariables[name]]
    if (fileList.length >= 1) {
      Object.keys(fileList).forEach((v) => {
        if (v !== 'length') {
          newFileList.push(fileList[v])
        }
      })
    }
    newData[name] = newFileList
    onChangeAllVariables(name, newData[name])
  }

  const onRemoveFileList = (fileName, name) => {
    const newData = { ...allVariables }
    const newFileList = [...allVariables[name]]
    _.remove(newFileList, v => v.name === fileName)
    newData[name] = newFileList
    onChangeAllVariables(name, newData[name])
  }

  return (
    <Mutation
      mutation={CREATE_PAYMENT}
    >
    {
      doCreatePayment => (
        <Div moreStyle={{ marginTop: '16px' }}>
          <ColorDiv width='100%' primaryColor>
            <H5 color={theme.color.offWhite}>รายละเอียดการชำระเงิน</H5>
          </ColorDiv>
          <div>
            <InputWrapper>
              <EachInputWrapper>
                <Div flexBasis='50%' display='flex' alignItems='center'>
                  <P>ธนาคาร *</P>
                </Div>
                <InputDiv flexBasis='32%' textAlign='right'>
                  <Select
                    showSearch
                    style={{ width: isMobileView ? '100%' : '200px', fontSize: theme.fonts.size.normal }}
                    placeholder="กรุณาเลือกวิธีชำระเงิน"
                    optionFilterProp="children"
                    onChange={e => onChangeAllVariables('paymentMethod', e)}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Select.Option value="bank">ธนาคารกรุงศรี</Select.Option>
                    <Select.Option value="promptPay">PromptPay</Select.Option>
                  </Select>
                </InputDiv>
              </EachInputWrapper>
              <EachInputWrapper>
                <Div flexBasis='50%' display='flex' alignItems='center'>
                  <P>จำนวนเงิน (บาท) *</P>
                </Div>
                <InputDiv flexBasis='32%' textAlign='right'>
                  <Input width='100%' value={allVariables.amount} onChange={(e) => {
                    if (priceValidator(e.target.value)) {
                      onChangeAllVariables('amount', e.target.value)
                    }
                  }}/>
                </InputDiv>
              </EachInputWrapper>
              <EachInputWrapper>
                <Div flexBasis='40%' display='flex' alignItems='center' margin='0 0 10px'>
                  <P>วันที่ / เวลา *</P>
                </Div>
                <DateTimeInputContainer>
                  <DateTimeInputWrapper width='100%'>
                    <InputWithBottomLabel
                      width={isMobileView ? '100%' : '35px'}
                      label='วัน'
                      onChangeAllVariables={onChangeAllVariables}
                      keyValue='date'
                      inputValue={allVariables.date}
                      limit={31}
                      isMobileView={isMobileView}
                      type='day'
                    />
                    /
                    <InputWithBottomLabel
                      width={isMobileView ? '100%' : '35px'}
                      label='เดือน'
                      onChangeAllVariables={onChangeAllVariables}
                      keyValue='month'
                      inputValue={allVariables.month}
                      limit={12}
                      isMobileView={isMobileView}
                      type='month'
                    />
                    /
                    <InputWithBottomLabel
                      width={isMobileView ? '100%' : '52px'}
                      label='ปี'
                      onChangeAllVariables={onChangeAllVariables}
                      keyValue='year'
                      inputValue={allVariables.year}
                      limit={10000}
                      isMobileView={isMobileView}
                      type='year'
                    />
                    <InputWithBottomLabel
                      width={isMobileView ? '100%' : '35px'}
                      label='ชม.'
                      onChangeAllVariables={onChangeAllVariables}
                      keyValue='hour'
                      inputValue={allVariables.hour}
                      limit={24}
                      isMobileView={isMobileView}
                    />
                    :
                    <InputWithBottomLabel
                      width={isMobileView ? '100%' : '35px'}
                      label='นาที'
                      onChangeAllVariables={onChangeAllVariables}
                      keyValue='minute'
                      inputValue={allVariables.minute}
                      limit={59}
                      isMobileView={isMobileView}
                    />
                  </DateTimeInputWrapper>
                </DateTimeInputContainer>
              </EachInputWrapper>
              <EachInputWrapper>
                <Div flexBasis='40%' display='flex' alignItems='center'>
                  <P>ชื่อบัญชีผู้ชำระเงิน *</P>
                </Div>
                <NameInputDiv flexBasis='47%' textAlign='right' >
                  <Input width='100%' onChange={e => onChangeAllVariables('name', e.target.value)}/>
                </NameInputDiv>
              </EachInputWrapper>
            </InputWrapper>
            <UploadFile
              list={allVariables.evidenceFiles}
              id='uploadFileId'
              multiple
              onChange={(fileList) => {
                let upload = false
                const allowFileType = ['jpg', 'jpeg', 'png', 'pdf']
                for (let i = 0; i < fileList.length; i += 1) {
                  const splitName = fileList[i].name.split('.')
                  if (!_.includes(allowFileType, splitName[splitName.length - 1].toLowerCase())
                  || (allVariables.evidenceFiles.length > 0 && !_.isUndefined(_.find(allVariables.evidenceFiles, d => d.name === fileList[i].name)))) {
                    upload = false
                    break
                  } else {
                    upload = true
                  }
                }
                if (upload) {
                  onUploadFileList(fileList, 'evidenceFiles')
                } else {
                  message.error('สามารถอัพโหลดได้เฉพาะไฟล์ jpg, jpeg, png, pdf และไม่สามารถอัพรูปซ้ำได้')
                }
              }}
              onRemoveFile={fileName => onRemoveFileList(fileName, 'evidenceFiles')}
              fileType="image/*, .pdf"
            />
          </div>
          <Button
            disabled={allVariables.paymentMethod === ''
              || allVariables.amount === ''
              || allVariables.date === ''
              || allVariables.month === ''
              || allVariables.year === ''
              || allVariables.minute === ''
              || allVariables.hour === ''
              || allVariables.name === ''
              || _.isEmpty(allVariables.evidenceFiles)
              || disabledButton}
            inverse
            fullWidth
            text='อัพโหลดหลักฐานการชำระเงินใหม่'
            onClick={() => {
              setDisabledButton(true)
              doCreatePayment({
                variables: {
                  ...allVariables,
                  quotationId,
                  amount: parseFloat(allVariables.amount),
                },
              }).then((res) => {
                setDisabledButton(false)
                if (!res.loading && !_.isUndefined(res.data)) {
                  if (!res.data.createPayment.success) {
                    message.error(res.data.createPayment.message)
                  } else {
                    message.success(res.data.createPayment.message)
                    setTimeout(() => {
                      refetch()
                    }, 3000)
                  }
                }
              })
            }} />
        </Div>
      )
    }
    </Mutation>
  )
}

export const HistoryLogQuotation = ({ id, margin }) => (
  <Query
    query={SHOW_HISTORY_QUOTATION}
    fetchPolicy='network-only'
    notifyOnNetworkStatusChange
    variables={{ id }}
  >
  {
    (res) => {
      if (res.loading || !res.data) return null
      if (res.data.showHistoryQuotation.length <= 1) return null
      return (
        <Div margin={margin}>
          <H4 margin='0 0 32px' display='block' bold color={theme.color.tertiaryColor}>ประวัติการออกใบเสนอราคา</H4>
          {
            res.data.showHistoryQuotation.filter((value, index) => index !== 0).map((v) => {
              const splitLink = v.quotationPDF.split('/')
              return (
                <Div margin='16px 0' key={v.dateTime} display='flex'>
                  <a style={{ width: '100%' }} href={v.quotationPDF} target="_blank" rel="noopener noreferrer"><P>{splitLink[splitLink.length - 1]} ( คลิกเพื่อดาวน์โหลด )</P></a>
                  <P>{v.dateTime}</P>
                </Div>
              )
            })
          }
        </Div>
      )
    }
  }
  </Query>
)
