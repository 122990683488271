import styled from 'styled-components'
import { Divider as DividerAnt } from 'antd'
import { theme, media, minMedia } from '../../../../../styles/_variables'


export const DivHeadColumn = styled.div`
  display: grid;
  justify-content: center; 
  padding: ${props => (props.padding ? props.padding : '16px 16px 4px 16px')};
`

export const DivImage = styled.div`
  padding: 6px;
  background-color: ${theme.color.offWhite};
  border-radius: 30px;
  border: 1px solid ${theme.color.primaryColor};
`

export const ImgIcon = styled.img`
  width: 36px;
`

export const DivColumn = styled.div`
  background-color: rgb(255,255,255,0.97);
  width: 100%;
  min-width: 140px;
  border: ${theme.color.primaryColor} solid 1px;
  border-radius: 8px;
  padding: 12px 0px;
  ${media.tablet`
    width: 100%;
    min-width: 122px;
  `}
  ${media.mobileL`
    width: 100%;
    min-width: 108px;
  `}
  ${media.mobileM`
    width: 100%;
    min-width: 70px;
  `}
`

export const DivSpacing = styled.div`
  background-color: ${theme.color.offWhite};
  width: 10px;
  ${media.mobileL`
    width: 6px;
  `}
  ${media.mobileM`
    width: 4px;
  `}
`

export const Divider = styled(DividerAnt)`
  margin: 8px 0;
  background: ${theme.color.primaryColor};
  width: 70%;
  min-width: 70%;
`

export const DivCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center
`

export const DivQuantity = styled.div`
  width: 100%;
  padding: 0px 24px;
  color: ${theme.color.offWhite};
  background-color: ${theme.color.greenPrimary};
  font-size: ${theme.fonts.size.subTitle};
  text-align: center;
  font-weight: ${theme.fonts.style.medium};
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  box-shadow: 2px 2px 0px 1px rgb(${theme.color.primaryColorRGB}, 0.4) !important;
  margin-right: 50px !important;
  @media only screen and (min-width: 768px) {
    margin-top: 0px !important;
  }
  // @media only screen and (min-width: 767px) {
  //   margin-top: 10px !important;
  // }
  // @media only screen and (min-width: 496px) and (max-width: 570px) {
  //   font-size: 24px;
  // }
  // @media only screen and (min-width: 525px) and (max-width: 571px) {
  //   margin-top: 26px !important;
  // }
  // @media only screen and (min-width: 571px) and (max-width: 768px) {
  //   padding: 0px 10px 0px 16px !important;
  //   margin-top: 29px !important;
  // }
  ${media.laptopL`
    font-size: 28px;
    padding: 0px 16px;
    margin-right: 40px !important;
  `}
  ${media.tablet`
    width: 100%;
    margin-right: 22px !important;
    padding: 0px 12px 0px 18px;
    margin-top: 0px;
  `}
  ${media.mobileL`
    width: 100%;
    margin-right: 10px !important;
    padding: 0px 6px 0px 12px;
    margin-top: 0px;
  `}
  ${media.mobileM`
    width: 100%;
    margin-right: 6px !important;
    padding: 0px 6px 0px 12px;
    margin-top: 0px;
  `}
  ${media.mobileS`
    width: 100%;
    margin-right: 4px !important;
    padding: 0px 6px 0px 12px;
    margin-top: 0px;
  `}

  ${media.tablet`
    font-size: ${theme.fonts.size.normal};
  `}
  ${media.mobileL`
    font-size: ${theme.fonts.size.normal};
  `}
  ${media.mobileM`
    font-size: ${theme.fonts.size.small} !important;
  `}
  ${media.mobileS`
    font-size: ${theme.fonts.size.small} !important;
  `}
`

export const LabelColumn = styled.div`
  color: ${theme.color.primaryColor};
  font-size: ${theme.fonts.size.subTitle};
  text-align: center;
  font-weight: ${theme.fonts.style.medium};
  ${media.tablet`
    font-size: 22px;
  `}
  ${media.mobileL`
    font-size: 18px;
  `}
  ${media.mobileM`
    font-size: 18px;
  `}
  ${media.mobileS`
    font-size: ${theme.fonts.size.small};
  `}
`

export const ColumnDataQuantity = styled.div`
  div {
    padding: 2px;
    margin-bottom: 8px;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    ${media.mobileM`
      padding: 3.5px;
      margin-bottom: 6px;
    `}
  }
  div:nth-child(odd) {
    background-color: rgb(${theme.color.lightGreyRGB}, 0.2);
  }
  
  div:nth-child(even) {
    background-color: rgb(${theme.color.lightGreyRGB}, 0.5);
  }

  // @media only screen and (min-width: 424px) and (max-width: 525px) {
  //   margin-top: 6px !important;
  // }
  // @media only screen and (min-width: 571px) and (max-width: 768px) {
  //   margin-top: 9px !important;
  // }
  ${media.laptopL`
    margin-top: 3px;
  `}
  ${media.tablet`
    font-size: ${theme.fonts.size.small};
    margin-right: 8px;
    width: 100%;
    margin-top: 16.5px;
  `}
  ${media.mobileXL`
    font-size: ${theme.fonts.size.small};
    margin-right: 8px;
    width: 100%;
    margin-top: 17px;
  `}
  ${media.mobileL`
    font-size: ${theme.fonts.size.label};
    margin-right: 8px;
    width: 100%;
    margin-top: 17px;
  `}
  ${media.mobileM`
    margin-top: 20px;
  `}
  ${media.mobileS`
    margin-top: 10px;
  `}
`

export const RowCellQuantity = styled.div`
  display: flex;
  justify-content: center;
  
`

export const LabelQuantity = styled.p`
  color: ${theme.color.lightGrey};
  margin: 0px;
  font-size: ${theme.fonts.size.extraNormal};
  font-weight: ${theme.fonts.style.medium};
  width: 90px;
  margin-right: 18px;
  text-align: right;
  ${media.laptopL`
    width: 80px;
    margin-right: 0px;
  `}
  ${media.tablet`
    font-size: ${theme.fonts.size.small};
    margin-right: 8px;
    width: fit-content;
    width: -moz-fit-content;
    min-width: 60px;
    padding: 0px 0px 0px 12px;
    text-align: left;
  `}
  ${media.mobileL`
    font-size: ${theme.fonts.size.label};
    width: fit-content;
    width: -moz-fit-content;
    margin-right: 0px !important;
    padding: 0px 0px 0px 6px;
    min-width: 30px;
    text-align: left;
  `}
  ${media.mobileM`
    font-size: ${theme.fonts.size.label};
    width: fit-content;
    width: -moz-fit-content;
    margin-right: 0px !important;
    padding: 0px 0px 0px 6px;
    min-width: 30px;
    text-align: left;
  `}
`

export const ColumnDataManufacture = styled.div`
  div.row-cell-manufacture {
    padding: 4px 16px 0px 0px;
    margin-bottom: 8px;
    ${media.laptopL`
      padding: 4px 0px 0px 0px;
    `}
    ${media.tablet`
      margin-bottom: 8px;
      padding: 2px 2px 2px 2px;
    `}
    ${media.mobileL`
      margin-bottom: 8px;
    `}
    ${media.mobileM`
      margin-bottom: 6px;
    `}
  }
  div:nth-child(even) {
    background-color:rgb(${theme.color.lightGreyRGB}, 0.1);
  }
  ${media.tablet`
    font-size: ${theme.fonts.size.small};
    margin-right: 8px;
    width: 100%;
  `}
  ${media.mobileL`
    font-size: ${theme.fonts.size.label};
    margin-right: 8px;
    width: 100%;
  `}
`

export const RowCellManufacture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LabelManufactureNew = styled.p`
  color: ${props => (props.active ? theme.color.primaryColor : theme.color.lightBlack)};
  margin: 0px;
  font-size: ${theme.fonts.size.extraNormal};
  font-weight: ${theme.fonts.style.medium};
  width: 90px;
  margin-right: 18px;
  text-align: right;
  ${media.laptopL`
    width: 60px;
  `}
  ${media.tablet`
    font-size: ${theme.fonts.size.small};
    margin-right: 6px;
    width: fit-content;
    width: -moz-fit-content;
  `}
  ${media.mobileL`
    font-size: ${theme.fonts.size.label};
    margin-right: 4px;
    width: fit-content;
    width: -moz-fit-content;
  `}
  ${media.mobileM`
    font-size: ${theme.fonts.size.label};
    margin-right: 2px;
    width: fit-content;
    width: -moz-fit-content;
  `}
  ${media.mobileS`
    font-size: 14px;
    margin-right: 2px;
    width: fit-content;
    width: -moz-fit-content;
  `}
`

export const LabelUnit = styled.p`
  color: ${theme.color.lightGrey};
  margin: 0px;
  font-size: ${theme.fonts.size.normal};
  font-weight: ${theme.fonts.style.medium};
  width: 66px;
  text-align: right;
  ${media.laptopL`
    width: 48px;
  `}
  ${media.tablet`
    width: fit-content;
    width: -moz-fit-content;
    font-size: ${theme.fonts.size.small};
  `}
  ${media.mobileL`
    font-size: ${theme.fonts.size.label};
    width: fit-content;
    width: -moz-fit-content;
  `}
  ${media.mobileM`
    font-size: 12px;
    line-height: 24px;
    width: fit-content;
    width: -moz-fit-content;
  `}
  ${media.mobileS`
    font-size: 10px;
    line-height: 24px;
    width: fit-content;
    width: -moz-fit-content;
  `}
`

export const LabelManufactureOld = styled.p`
  color: ${props => (props.active ? theme.color.primaryColor : theme.color.lightBlack)};
  margin: 0px;
  font-size: ${theme.fonts.size.extraNormal};
  font-weight: ${theme.fonts.style.medium};
  width: 90px;
  margin-right: 18px;
  text-align: right;
  ${media.laptopL`
    width: 60px;
  `}
  ${media.tablet`
    font-size: ${theme.fonts.size.small};
    margin-right: 6px;
    width: fit-content;
    width: -moz-fit-content;
  `}
  ${media.mobileL`
    font-size: ${theme.fonts.size.label};
    margin-right: 4px;
    width: fit-content;
    width: -moz-fit-content;
  `}
  ${media.mobileM`
    font-size: ${theme.fonts.size.label};
    margin-right: 2px;
    width: fit-content;
    width: -moz-fit-content;
  `}
  ${media.mobileS`
    font-size: 14px;
    margin-right: 2px;
    width: fit-content;
    width: -moz-fit-content;
  `}
`

export default LabelManufactureOld
