import { css } from 'styled-components'

export const theme = {
  color: {
    primaryColor: '#6EC9BE',
    primaryColorRGB: '110, 201, 190',
    secondaryColor: '#34B2A3',
    secondaryColorRGB: '52, 178, 163',
    tertiaryColor: '#0D7669',
    fourthColor: '#4F8680',
    textColor: '#424242',
    textColorRGB: '66, 66, 66',
    offWhite: '#FFFFFF',
    success: '#3A7523',
    error: '#C90813',
    warning: '#F5A952',
    grey: '#A9A9A9',
    lightGrey: '#6A6C6A',
    lightGreyRGB: '208, 208, 208',
    lightBlack: '#2B2B2B',
    lightGray: '#979797',
    paleGray: '#C9C9C9',
    offGray: '#f6f5f5',
    greyNormal: '#787878',
    greenPrimary: '#34B2A5',
    // light: light,
    // lightGray: lightGray,
  },
  shadow: {
    selectShadow: '0 0 20px',
  },
  fonts: {
    family: {
      normal: 'printAble4u',
    },
    style: {
      extraLight: '200',
      light: '300',
      regular: '400',
      medium: '600',
      semiBold: '600',
      bold: '800',
    },
    size: {
      heading: '56px',
      subHeading: '48px',
      title: '40px',
      subTitle: '30px',
      extraNormal: '20px',
      normal: '18px',
      small: '16px',
      label: '14px',
    },
  },
  zIndex: {
    // the max z-index is 100, modal have it and is set in index.css
    dropdown: 2,
    menuList: 9,
  },
}

export const screenSize = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  mobileXL: 570,
  tabletS: 640,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
}

export const media = Object.keys(screenSize).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media only screen and (max-width: ${screenSize[label]}px) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export const minMedia = Object.keys(screenSize).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media only screen and (min-width: ${screenSize[label]}px) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export default theme
