
import styled from 'styled-components'
import {
  Div, H4, Button,
} from 'konsys-ui'
import { Divider as DividerAnt } from 'antd'
import { theme, media, minMedia } from '../../../../styles/_variables'


export const Box = styled.div`
   ${props => (!props.isFixedPosition ? `
      position: ${props.sticky ? 'absolute' : 'relative'};
      height: ${props.isTop180 ? '100%' : 'calc(100% - 200px)'};
      margin-top: ${props.sticky ? '20px' : '0px'};
      margin-bottom: ${!props.sticky ? '20px' : '0px'};
      margin-left: ${props.sticky ? '12px' : '0px'};
      top: ${props.isNotTop ? '0px' : props.isTop180 ? '180px' : '72px'};
      width: 100%;
      max-width: 280px;
      transition: all 0.2s linear;
      ${media.mobileL`
          top: 20px;
      `}
      ${minMedia.mobileL`
          width: 30%;
      `}
      ${media.mobileL`
          top: ${(props.isSummaryInProfile ? '0px' : '20px')};
          width: 100%;
      `}
    ` : `
      width: 100%;
  `)}
  ${media.tablet`
      max-width: ${props => (!props.isFixedPosition ? '' : props.isFullWidth ? '100%' : '40vw')};
  `}
  ${minMedia.laptop`
      max-width: ${props => (!props.isFixedPosition ? '' : props.isFullWidth ? '100%' : '30vw')};
      width: ${props => (!props.isFixedPosition ? '22vw' : props.isFullWidth ? '100%' : '30vw')};
  `}
  ${minMedia.laptopL`
      max-width: ${props => (!props.isFixedPosition ? '' : props.isFullWidth ? '100%' : '22vw')};
      width: ${props => (!props.isFixedPosition ? '100%' : props.isFullWidth ? '100%' : '22vw')};
  `}
  ${minMedia.desktop`
      max-width: ${props => (!props.isFixedPosition ? '' : props.isFullWidth ? '100%' : '25vw')};
      width: ${props => (!props.isFixedPosition ? '100%' : props.isFullWidth ? '100%' : '25vw')};
  `}
`

export const TitleBar = styled(Div)`
    border-bottom: 1px solid ${theme.color.grey};
    margin-bottom: 4px;
`

export const Title = styled(H4)`
    font-size: ${theme.fonts.size.extraNormal};
`

export const Label = styled.h4`
    font-size: ${theme.fonts.size.small};
    font-weight: ${theme.fonts.style.medium};
    margin-bottom: 0px !important;
    white-space: nowrap;
    margin-right: 8px;
    line-height: ${theme.fonts.size.small};
`

export const TextLabel = styled.span`
    color: #7d7c7c;
    font-size: ${theme.fonts.size.small};
    font-weight: ${theme.fonts.style.medium};
    margin-left: 8px;
    margin-right: 8px;
    white-space: nowrap;
    line-height: ${theme.fonts.size.small};
`
export const TextValue = styled.h4`
    color: ${theme.color.primaryColor};
    font-size: ${theme.fonts.size.small};
    font-weight: ${theme.fonts.style.medium};
    margin-bottom: 0px !important;
    text-align: right;
    word-break: break-word;
    line-height: ${theme.fonts.size.small};
`

export const TextUnit = styled.span`
    color: #7d7c7c;
    font-size: ${theme.fonts.size.small};
    font-weight: ${theme.fonts.style.medium};
    margin-left: 8px;
    white-space: nowrap;
    line-height: ${theme.fonts.size.small};
    ${media.tablet`
        font-size: ${theme.fonts.size.small} !important;
        font-weight: ${theme.fonts.style.medium} !important;
        line-height: ${theme.fonts.size.small} !important;
    `}
`

export const ButtonAction = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 12px;
    padding: 8px;
    width: 110px;
    height: 38px;
    box-shadow: 2px 2px 0px 1px rgb(${theme.color.primaryColorRGB}, 0.4) !important;
    font-size: ${theme.fonts.size.small} !important;
    font-weight: ${theme.fonts.style.medium};
    padding: 6px 12px;
    & > span {
      font-size: ${theme.fonts.size.small} !important;
    }
    > p {
      font-size: ${theme.fonts.size.small} !important;
    }
`

export const ButtonEdit = styled(Button)`
    background-color: ${theme.color.greenPrimary};
    border-color: ${theme.color.greenPrimary};
    border-radius: 16px;
    padding: 2px 12px;
    box-shadow: 2px 2px 0px 1px rgb(${theme.color.primaryColorRGB}, 0.4) !important;
`

export const SummaryBox = styled(Div)`
    max-height: 40vh;
    overflow-y: auto;
    padding-right: 6px;
    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        height: 4px;
        background: ${theme.color.primaryColor};
    }
    &::-webkit-scrollbar-track {
        background: ${theme.color.lightPaleGrey};
    }
    ${media.mobileL`
        max-height: 40vh;
        overflow-y: auto;
    `}
`

export const RowHeader = styled.div`
  display: block;
`

export const Row = styled.div`
  display: flex;
`

export const Header = styled.div`
  color: ${theme.color.secondaryColor};
  font-size: 20px;
  font-weight: ${theme.fonts.style.medium};
  line-height: 1;
  word-break: break-word;
`

export const SubHeader = styled.span`
  color: ${theme.color.primaryColor};
  font-size: 24px;
  font-weight: ${theme.fonts.style.medium};
  line-height: 1;
  margin: 0px;
  word-break: break-word;
`

export const Divider = styled(DividerAnt)`
  margin: 4px 0px 8px;
  background: ${theme.color.grey};
  width: 100%;
  min-width: 100%;
`

export const IconEdit = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-top: -4px;
`

export const SectionDiv = styled(Div)`
    margin-top: 8px;
`

export const ItemDiv = styled(Div)`
`

export const ContentDiv = styled(Div)`
    margin-top: 8px;
`
export default Box
